import { useEffect, useState } from 'react'

import {
  getAppointmentDurations,
} from '../../../../../services/Contracts/Utility/AppointmentTypesUtility'
import { Duration } from '../../Components/hooks/useAppointmentTypesList'

interface IUseHook {
  durations: Duration[];
}

export function useAppointmentDurations(): IUseHook {
  const [durations, setDurations] = useState<Duration[]>([])

  useEffect(() => {
    fetchParameters().then();
  }, []);

  const fetchParameters = async (): Promise<void> => {
    const { status, data } = await getAppointmentDurations()
    if (status) {
      setDurations(
        data.data.map((duration) => {
          return {
            value: duration.option_code,
            name: duration.option_value,
          }
        }),
      )
    }
  }

  return {
    durations
  };
}
