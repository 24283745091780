import { styled } from '@mui/material'

import { ButtonWrapper, ListItemWrapper } from '../../../../../components'

export const LigthButton = styled( ButtonWrapper )(( props ) => ({
  margin: '0 5px',
  textTransform: 'capitalize',
  borderRadius: '50px',
  backgroundColor: props.theme.palette.primary.main,
  color: props.theme.palette.background.paper,
  padding: '9px 22px',
  border: '1px solid ' + props.theme.palette.primary.main,
  '&:hover': {
    backgroundColor: props.theme.palette.background.paper,
    color: props.theme.palette.primary.main
  },
  '&.Mui-disabled': {
    backgroundColor: props.theme.palette.text.disabled,
    color: 'text.primary',
    border: 'none'
  }
}))

export const ListItemInfo = styled(ListItemWrapper)((props) => ({
  minHeight: '100px',
  background: props.theme.palette.background.paper,
  borderRadius: '47px',
  margin: '10px 0'
}))

export const ListItemTitle = styled( ListItemWrapper )(() => ({
  borderRadius: '40px',
  margin: '10px 0'
}))
