import { IUpdateOfficesRepository } from '../../domain/repository/UpdateOfficesRepository'
import {
  IModifiedDataOffice,
  IApiResponseModifiedDataOffice,
} from '../dtos/UpdateOffices'
import UpdateOfficesDataSource from '../http/dataSource/UpdateOfficesDataSource'

export class UpdateOfficesRepositoryImpl implements IUpdateOfficesRepository {
  dataSource: UpdateOfficesDataSource

  constructor(_datasource: UpdateOfficesDataSource) {
    this.dataSource = _datasource
  }

  updateOffices(
    office: IModifiedDataOffice,
  ): Promise<IApiResponseModifiedDataOffice> {
    return this.dataSource.updateOffices(office)
  }
}
