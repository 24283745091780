import React, { useState } from 'react'

import { CreateOffice } from './CreateOffice'
import { CreateSchedule } from './CreateSchedule'
import { useCreateOfficeStore } from '../../../../../../../contexts/NewOfficeState'

export const NewOfficeModal = (): JSX.Element => {
  const [step, setStep] = useState<number>(0)
  const { setOpenCreateOffice, openCreateOffice } = useCreateOfficeStore()
  return step === 0 ? (
    <CreateOffice
      open={openCreateOffice}
      handleClose={() => setOpenCreateOffice(false)}
      setStep={setStep}
    />
  ) : (
    <CreateSchedule
      setStep={setStep}
      open={openCreateOffice}
      handleClose={() => setOpenCreateOffice(false)}
    />
  )
}
