import { styled } from '@mui/material'

import { SelectWrapper } from '../wrapper'

export const SelectInput = styled(SelectWrapper)((props) => ({
  borderRadius: '24px',
  height: '50px',
  color: props.theme.palette.tertiary?.main,
  '@media (max-width: 1600px)': {
    fontSize: '11px',
    width: '190px',
  },
  '@media (min-width: 1601px)': {
    fontSize: '16px',
    width: '290px',
  },
  backgroundColor: props.theme.palette.background.default,
  '& fieldset': { border: 'none' },
}))
