import { AxiosError, AxiosResponse } from 'axios'

import { IApiResponsePatientInfo } from '../../../dtos/Patient'
import PatientInfoDataSource from '../../dataSource/PatientInfoDataSource'
import { http } from '../../Http'

export default class PatientInfoMapper implements PatientInfoDataSource {
  async getPatientInfo(idPatient: string): Promise<IApiResponsePatientInfo> {
    const data = await http
      .get<IApiResponsePatientInfo>(`/send_data/data_patient/${idPatient}`)
      .then((response: AxiosResponse) => {
        const { data } = response

        return data
      })
      .catch((error: AxiosError) => {
        const data: IApiResponsePatientInfo = {
          statusCode: error.response?.status as number,
          body: error.response?.statusText as string,
          headers: {
            'Access-Control-Allow-Headers': '',
            'Access-Control-Allow-Origin': '',
            'Access-Control-Allow-Methods': '',
          },
        }

        return data
      })

    return data
  }

  async getPatientInfoByCi(
    ciPatient: string,
    isOnlyInfo = true,
  ): Promise<IApiResponsePatientInfo> {
    try {
      const params: string = isOnlyInfo ? `?type=info` : ''
      const { data } = await http.get<IApiResponsePatientInfo>(
        `/send_data/data_patient_by_ci/${ciPatient}${params}`,
      )

      return data
    } catch (error: unknown) {
      return {
        statusCode: (error as AxiosError).response?.status as number,
        body: (error as AxiosError).response?.statusText as string,
        headers: {
          'Access-Control-Allow-Headers': '',
          'Access-Control-Allow-Origin': '',
          'Access-Control-Allow-Methods': '',
        },
      }
    }
  }
}
