import { IApiResponseVisibilityAppointmentTimeAWS } from '../../infrastructure/dtos/VisibilityAppointmentTime';
import { VisibilityAppointmentTimeRepository } from '../repository/VisibilityAppointmentTimeRepository';

export interface GetVisibilityAppointmentTimeUseCase {
  invoke: () => Promise<IApiResponseVisibilityAppointmentTimeAWS>

}

export class GetVisibilityAppointmentTime implements GetVisibilityAppointmentTimeUseCase {
  private objectUseCase: VisibilityAppointmentTimeRepository

  constructor( objectUseCase: VisibilityAppointmentTimeRepository ) {
    this.objectUseCase = objectUseCase;
  }

  async invoke(): Promise<IApiResponseVisibilityAppointmentTimeAWS> {
    return this.objectUseCase.getVisibilityAppointmentTime()
  }
}