import { AxiosError } from 'axios'

import { ICancelService } from '../../../../services/Contracts/Persistencia/CancelAppointmentService'
import { ICancelAppointmentResponse } from '../../../dtos/CancelAppointment'
import CancelAppointmentDataSource from '../../dataSource/CancelAppointmentDataSource'
import { http } from '../../Http'

export default class CancelAppointmentMapper
  implements CancelAppointmentDataSource
{
  async cancelAppointment(
    params: ICancelService,
  ): Promise<ICancelAppointmentResponse> {
    try {
      const { data } = await http.post<ICancelAppointmentResponse>(
        `/save_data/cancel_appointment_by_doctor/${params.appointmentId}`,
        {
          reason: params.reason,
        },
      )

      return data
    } catch (error: unknown) {
      return {
        statusCode: (error as AxiosError).response?.status as number,
        body: (error as AxiosError).response?.statusText as string,
        headers: {
          'Access-Control-Allow-Headers': '',
          'Access-Control-Allow-Origin': '',
          'Access-Control-Allow-Methods': '',
        },
      }
    }
  }
}
