import { Document, Page, Text, View, StyleSheet, Image } from '@react-pdf/renderer'

import dayjs from 'dayjs';

import helthyLogo from '../../assets/helthyLogo3.png'
import { IDoctorPrescription } from '../../infrastructure/dtos/DoctorInfo'
import { IPrescription } from '../../views/Dashboard/components/modals/RecipeModal/types'

const styles = StyleSheet?.create({
  page: {
    fontFamily: 'Helvetica',
    fontSize: 10,
    marginTop: 10,
  },
  divSection: {
    flexDirection: 'row',
  },
  section: {
    flexGrow: 1,
    paddingHorizontal: 40,
    backgroundColor: '#FFF',
    width: '500%'
  },
  sectionView: {
    marginBottom: 10,
  },
  header: {
    color: '#3850B2',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginTop: 10,
    marginBottom: 10
  },
  logoHeader: {
    width: '300px',
  },
  logo: {
    width: '100%',
    height: 'auto'
  },
  header2: {
    width: '400px',
  },
  recipeNumber: {
    fontSize: 16,
    fontWeight: 700,
    fontStyle: 'normal',
    letterSpacing: '0px',
    textAlign: 'left',
  },
  titleName: {
    fontWeight: 700,
    fontSize: 20,
    fontStyle: 'normal',
    letterSpacing: '0px',
    textAlign: 'left',
    lineHeight: '1.3',
  },
  titleDescription: {
    fontSize: 12,
    fontWeight: 400,
    fontStyle: 'normal',
    letterSpacing: '0px',
    textAlign: 'left',
  },
  title: {
    fontSize: 16,
    fontWeight: 'bold',
    marginBottom: 10,
  },
  subtitle: {
    fontSize: 14,
    marginBottom: 5,
  },
  content: {
    fontSize: 12,
  },
  viewPatient: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
  },
  subtitlePatient: {
    fontSize: 14,
  }
});

interface IRecipePdfProps {
  doctorAppointmentInfo: IDoctorPrescription
  prescription: IPrescription
  remarks: string
}

const Header = (
  {doctorAppointmentInfo}: {doctorAppointmentInfo: IDoctorPrescription}
): JSX.Element => <View style={styles.header}>
  <View style={styles.logoHeader}>
    <Image source={helthyLogo} style={styles.logo} />
  </View>
  <View style={styles.header2}>
    <Text style={styles.recipeNumber}>Receta No. {String(doctorAppointmentInfo.prescription.prescription_no).padStart(6, '0')}</Text>
    <Text style={styles.titleName} >Dr. {`${doctorAppointmentInfo.doctor.name} ${doctorAppointmentInfo.doctor.last_name}`}</Text>
    <Text style={styles.titleDescription}>{ doctorAppointmentInfo.doctor.specialties }</Text>
    <Text style={{
      ...styles.titleDescription,
      marginVertical: 10
    }}>Reg. ACESS: { doctorAppointmentInfo.doctor.msp_code }</Text>
    <Text style={styles.titleDescription}>{
      `${doctorAppointmentInfo.office_medical.name_medical_office}, ${doctorAppointmentInfo?.office_medical?.reference}`
    }</Text>
    <Text style={styles.titleDescription}>{
      doctorAppointmentInfo?.doctor?.phone && doctorAppointmentInfo?.office_medical?.office_phone ?
      `${doctorAppointmentInfo?.doctor?.phone} - ${doctorAppointmentInfo?.office_medical?.office_phone}` :
      doctorAppointmentInfo?.doctor?.phone || doctorAppointmentInfo?.office_medical?.office_phone || '-'
    }</Text>
  </View>
</View>

export const ReciPePdf = ({
  doctorAppointmentInfo, prescription, remarks
}: IRecipePdfProps): JSX.Element => {
  return (
    <Document>
      <Page size="A4" orientation='landscape' style={styles.page} wrap>

        {/* HEADER */}
        <View style={styles.divSection} fixed>
          <View style={styles.section}>
            <Header doctorAppointmentInfo={doctorAppointmentInfo}/>
            <View style={styles.viewPatient}>
              <Text style={styles.subtitlePatient}>Nombre:</Text>
              <Text style={styles.content}>{`\t\t\t\t ${doctorAppointmentInfo.patient.name} ${doctorAppointmentInfo.patient.last_name}`}</Text>
            </View>
            <View style={{
              ...styles.viewPatient,
              marginBottom: 40
            }}>
              <Text style={styles.subtitlePatient}>Fecha:</Text>
              <Text style={styles.content}>{'\t\t\t\t' + dayjs(new Date(Date.now())).format('DD/MM/YYYY')}</Text>
            </View>
          </View>
          <View style={styles.section}>
            <Header doctorAppointmentInfo={doctorAppointmentInfo}/>
            <View style={styles.viewPatient}>
              <Text style={styles.subtitlePatient}>Nombre:</Text>
              <Text style={styles.content}>{`\t\t\t\t ${doctorAppointmentInfo.patient.name} ${doctorAppointmentInfo.patient.last_name}`}</Text>
            </View>
            <View style={{
              ...styles.viewPatient,
              marginBottom: 40
            }}>
              <Text style={styles.subtitlePatient}>Fecha:</Text>
              <Text style={styles.content}>{'\t\t\t\t' + dayjs(new Date(Date.now())).format('DD/MM/YYYY')}</Text>
            </View>
          </View>
        </View>

        {/* DIAGNOSTICS */}

        <View style={styles.divSection}>
          <View style={styles.section}>
            <View style={{
              ...styles.sectionView,
              marginBottom: 40
            }}>
              <Text style={styles.title}>Diagnóstico</Text>
              {prescription.diagnostic.map((d, i) => 
                <Text style={styles.content} key={`diagnostic-${i}`} wrap>
                  {`${i+1}. ${d.code} ${d.description}`}
                </Text>
              )}
            </View>
          </View>
          <View style={styles.section} />
        </View>

        {/* RP AND INDICATIONS */}
        
        <View style={styles.divSection}>
          <View style={styles.section}>
            <View style={styles.sectionView}>
              <View style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                }}>
                <Text style={styles.title}>RP.</Text>
                <Text style={styles.title}>Cantidad</Text>
              </View>
              {prescription?.recipe.map((item, index) =>
                <View key={`recipe-${index}`} style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  marginBottom: 20
                }}>
                  <Text style={{...styles.content, width: '90%'}} wrap>{`${item.medicine} ${item.dose} ${item.presentation}`}</Text>
                  <Text style={styles.content}>{item.quantity}</Text>
                </View>
              )}
            </View>
          </View>
          <View style={styles.section}>
            <View style={styles.sectionView} >
              <Text style={styles.title}>Indicaciones</Text>
              {prescription?.recipe.map((item, index) =>
                <View key={`prescription-${index}`} style={styles.sectionView}>
                  <Text style={styles.content} wrap>{`${item.medicine} ${item.dose} ${item.presentation}`}</Text>
                  <Text style={styles.content} wrap>{item.prescription}</Text>
                </View>
              )}
            </View>
            <View style={styles.sectionView} wrap={false}>
              <Text style={styles.title}>Recomendaciones no Farmacológicas:</Text>
              <Text style={styles.content} wrap>{remarks}</Text>
            </View>
          </View>
        </View>

      </Page>
    </Document>
  );
};
