import {
  Close as CloseIcon,
  ErrorOutline as ErrorOutlineIcon,
} from '@mui/icons-material'

import {
  BoxCancelModal,
  CloseButton,
  FadeCancelModal,
  TypographyCancelModal,
  TypographyCancelModalText,
} from './CancelAppointmentStyles'
import { useCancelAppointment } from './hook/useCancelAppointment'
import {
  BackdropWrapper,
  GridWrapper,
  ModalWrapper,
} from '../../../../../components'
import { SecondaryMediumButton } from '../../../../../components/SecondaryMediumButton/SecondaryMediumButton'
import SubmitButton from '../../../../../components/SubmitButton'

import CircularProgressWrapper from '../../../../../components/wrapper/LoaderWrapper'

interface ICancelAppointmentsProps {
  handleClose: () => void
  open: boolean
  handleOpenCancelReason: () => void
}

export const CancelAppointmentModal = ({
  handleClose,
  open,
  handleOpenCancelReason,
}: ICancelAppointmentsProps): React.JSX.Element => {
  const {
    loading,
    message,
  } = useCancelAppointment(true)

  return (
    <ModalWrapper
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      data-testid="modal-cancel-appointment"
      open={open}
      onClose={handleClose}
      closeAfterTransition
      slots={{ backdrop: BackdropWrapper }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <FadeCancelModal in={open}>
        <BoxCancelModal width={{ xs: '90%', md: '600px' }}>
          <GridWrapper padding={5}>
            <CloseButton onClick={handleClose}>
              <CloseIcon />
            </CloseButton>
            <GridWrapper>
              <TypographyCancelModal
                id="transition-modal-title"
                variant="h6"
                data-testid="modal-cancel-appointment-title"
              >
                ¿Estás seguro que deseas cancelar esta cita?
              </TypographyCancelModal>
            </GridWrapper>
            <GridWrapper
              container
              display={'flex'}
              justifyContent={'center'}
              alignItems={'center'}
              rowGap={'23px'}
              mt={'25px'}
              paddingX={{ xs: '0px', sm: '15px' }}
            >
              {!loading ? (
                <>
                  <GridWrapper item xs={1}>
                    <ErrorOutlineIcon
                      sx={{ color: 'primary.dark', fontSize: '30px' }}
                    />
                  </GridWrapper>
                  <GridWrapper item xs={10}>
                    <TypographyCancelModalText>
                      {message}
                    </TypographyCancelModalText>
                  </GridWrapper>
                </>
              ) : (
                <GridWrapper item xs={12} textAlign={'center'}>
                  <CircularProgressWrapper />
                </GridWrapper>
              )}
            </GridWrapper>
            <GridWrapper
              container
              display={'flex'}
              justifyContent={'center'}
              flexDirection={'row'}
            >
              <SecondaryMediumButton
                id="keppAppointmentButton"
                data-testid="keppAppointmentButton"
                text={'Mantener'}
                type="button"
                variant="contained"
                sx={{ width: '35%', marginY: '45px', marginX: '15px' }}
                disabled={loading}
                onClick={handleClose}
                disableElevation
              />
              <SubmitButton
                id="cancelAppointmentButton"
                text={'Cancelar'}
                type="button"
                variant="contained"
                fullWidth
                onClick={handleOpenCancelReason}
                disabled={loading}
                sx={{ width: '35%', marginY: '45px', marginX: '15px' }}
                disableElevation
              />
            </GridWrapper>
          </GridWrapper>
        </BoxCancelModal>
      </FadeCancelModal>
    </ModalWrapper>
  )
}
