import dayjs from 'dayjs'

let eventGuid = 0
const todayStr = new Date().toISOString().replace(/T.*$/, '') // YYYY-MM-DD of today

export const INITIAL_EVENTS = [
  {
    id: createEventId(),
    title: 'All-day event',
    start: todayStr,
  },
  {
    id: createEventId(),
    title: 'Timed event',
    start: todayStr + 'T12:00:00',
    end: todayStr + 'T12:30:00',
  },
  {
    title: 'All Day Event very long title',
    allDay: true,
    id: createEventId(),
    start: new Date(2023, 4, 0),
    end: new Date(2023, 4, 1),
  },
  {
    id: createEventId(),
    title: 'Long Event',
    start: new Date(2023, 4, 7),
    end: new Date(2023, 4, 10),
  },

  {
    title: 'DTS STARTS',
    id: createEventId(),
    start: new Date(2016, 2, 13, 0, 0, 0),
    end: new Date(2016, 2, 20, 0, 0, 0),
  },

  {
    title: 'DTS ENDS',
    id: createEventId(),
    start: new Date(2016, 10, 6, 0, 0, 0),
    end: new Date(2016, 10, 13, 0, 0, 0),
  },

  {
    title: 'Some Event',
    id: createEventId(),
    start: new Date(2023, 4, 9, 0, 0, 0),
    end: new Date(2023, 4, 9, 0, 0, 0),
  },
  {
    title: 'Conference',
    id: createEventId(),
    start: new Date(2023, 4, 11),
    end: new Date(2023, 4, 13),
  },
  {
    title: 'Meeting',
    id: createEventId(),
    start: new Date(2023, 4, 12, 10, 30, 0, 0),
    end: new Date(2023, 4, 12, 12, 30, 0, 0),
  },
  {
    title: 'Lunch',
    id: createEventId(),
    start: new Date(2023, 4, 12, 12, 0, 0, 0),
    end: new Date(2023, 4, 12, 13, 0, 0, 0),
  },
  {
    title: 'Happy Hour',
    start: new Date(2023, 4, 12, 12, 0, 0, 0),
    end: new Date(2023, 4, 12, 13, 0, 0, 0),
    id: createEventId(),
  },
  {
    title: 'Meeting',
    id: createEventId(),
    start: new Date(2023, 4, 12, 14, 0, 0, 0),
    end: new Date(2023, 4, 12, 15, 0, 0, 0),
  },
  {
    title: 'Happy Hour',
    start: new Date(2023, 4, 17, 17, 0, 0, 0),
    end: new Date(2023, 4, 17, 17, 30, 0, 0),
    id: createEventId(),
  },
  {
    title: 'Dinner',
    id: createEventId(),
    start: new Date(2023, 4, 15, 20, 0, 0, 0),
    end: new Date(2023, 4, 15, 21, 0, 0, 0),
  },
  {
    title: 'Birthday Party',
    id: createEventId(),
    start: new Date(2023, 4, 13, 7, 0, 0),
    end: new Date(2023, 4, 13, 10, 30, 0),
  },
  {
    title: 'Birthday Party 2',
    id: createEventId(),
    start: new Date(2023, 4, 13, 7, 0, 0),
    end: new Date(2023, 4, 13, 10, 30, 0),
  },
  {
    title: 'Birthday Party 3',
    id: createEventId(),
    start: new Date(2023, 4, 13, 7, 0, 0),
    end: new Date(2023, 4, 13, 10, 30, 0),
  },
  {
    title: 'Late Night Event',
    id: createEventId(),
    start: new Date(2023, 4, 17, 19, 30, 0),
    end: new Date(2023, 4, 18, 2, 0, 0),
  },
  {
    title: 'Multi-day Event',
    id: createEventId(),
    start: new Date(2023, 4, 20, 19, 30, 0),
    end: new Date(2023, 4, 22, 2, 0, 0),
  },
]

export function createEventId(): string {
  eventGuid += 1
  return String(eventGuid)
}

export const greatherThan = (date1: string, date2: string): boolean =>
  dayjs(date1).isAfter(dayjs(date2))

export const lessThan = (date1: string, date2: string): boolean =>
  dayjs(date1).isBefore(dayjs(date2))

export const isEqual = (date1: string, date2: string): boolean =>
  dayjs(date1).isSame(dayjs(date2))

export const isValidDate = (date: string): boolean => dayjs(date).isValid()
