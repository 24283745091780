import { useEffect, useState } from 'react'

import { Severity } from '../../../../../contexts/AuthReducer'
import { ISpecialty } from '../../../../../infrastructure/dtos'
import { specialtiesUtility } from '../../../../../services/Contracts/Utility/SpecialtyUtility'

interface IUseHook {
  lists: ISpecialty[]
}

export function useSpecialties(
  handleAlert: (open: boolean, message: string, severity?: Severity) => void
): IUseHook {
  const [lists, setLists] = useState<ISpecialty[]>([])

  useEffect(() => {
    if (lists.length === 0) {
      fetchSpecialties()
    }
  }, [])

  const fetchSpecialties = async (): Promise<void> => {
    try {
      const { data, status } = await specialtiesUtility()
      if (status) {
        setLists(data?.specialties)
      } else {
        throw new Error('')
      }
    } catch (error: unknown) {
      handleAlert(true, 'Error al obtener especialidades', 'error')
    }
  }

  return {
    lists,
  }
}
