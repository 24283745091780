import CloseIcon from '@mui/icons-material/Close'
import React, { useEffect } from 'react'

import { useRecipeModal } from './hook'
import { Diagnostic } from './steps/Diagnostic'
import Prescription from './steps/Prescription'
import { Summary } from './steps/Summary'
import { BoxModal, CloseButton, ModalRecipe } from './styles'
import {
  BackdropWrapper,
  GridWrapper,
} from '../../../../../components'
import CircularProgressWrapper from '../../../../../components/wrapper/LoaderWrapper'
import { Appointment } from '../../../../../infrastructure/dtos/Appointments'

interface IRecipeModalProps {
  openModal: boolean
  handleCloseModal( isSent: boolean ): void
  appointmentSelected?: Appointment
}

enum STEPS {
  DIAGNOSTIC,
  RECIPE,
  SUMMARY
}

export function RecipeModal({
  handleCloseModal,
  openModal,
  appointmentSelected
}: IRecipeModalProps ): React.JSX.Element {
  const {
    stepper,
    doctorAppointmentInfo,
    prescription,
    isSubmit,
    isSent,
    onNext,
    onBack,
    fetchDoctorAndAppointmentInfoUtility,
    onChangeMspCode,
    onChangePrescription,
    onChangeIsSubmit
  } = useRecipeModal( appointmentSelected )

  useEffect(() => {
    fetchDoctorAndAppointmentInfoUtility()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <ModalRecipe
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      data-testid="recipe-modal"
      role="dialog"
      open={openModal}
      onClose={() => handleCloseModal( isSent )}
      closeAfterTransition
      slots={{ backdrop: BackdropWrapper }}
      slotProps={{
        backdrop: {
          timeout: 500
        }
      }}
    >
      <BoxModal>
        <CloseButton onClick={() => handleCloseModal( isSent )} data-testid="close-recipe-modal">
          <CloseIcon />
        </CloseButton>
        {stepper === STEPS.DIAGNOSTIC && (
          <>
            {doctorAppointmentInfo?.prescription?.prescription_no ? (
              <Diagnostic
                doctorAppointmentInfo={doctorAppointmentInfo}
                prescription={prescription}
                onNext={onNext}
                onChangeMspCode={onChangeMspCode}
                onChangePrescription={onChangePrescription}
              />
            ) : (
              <GridWrapper
                display={'grid'}
                justifyContent={'center'}
                alignItems={'center'}
              >
                <CircularProgressWrapper data-testid="spinner" />
              </GridWrapper>
            )}
          </>
        )}
        {stepper === STEPS.RECIPE && (
          <Prescription
            onNext={onNext}
            onChangePrescription={onChangePrescription}
            prescription={prescription}
            onBack={onBack}
          />
        )}
        {stepper === STEPS.SUMMARY &&
          <Summary
            onBack={onBack}
            prescription={prescription}
            doctorAppointmentInfo={doctorAppointmentInfo}
            onChangePrescription={onChangePrescription}
            onChangeIsSubmit={onChangeIsSubmit}
            isSubmit={isSubmit}
            isSent={isSent}
          />
        }
      </BoxModal>
    </ModalRecipe>
  )
}
