import { ButtonProps, Theme, TypographyProps, styled } from '@mui/material'

import {
  ButtonWrapper,
  TypographyWrapper
} from '../../../../../components/wrapper'

type ITheme = {
  theme?: Theme
}

export const ContentTitle = styled( TypographyWrapper )(() => ({
  left: '50%',
  fontFamily: 'Helvetica',
  fontSize: '16px',
  fontWeight: 'bold',
  fontStretch: 'normal',
  fontStyle: 'normal',
  lineHeight: 'normal',
  letterSpacing: 'normal',
  textAlign: 'center',
  color: '#000000'
}))

export const ModalTitle = styled( TypographyWrapper )(
  ( props: TypographyProps & ITheme ) => ({
    left: '50%',
    fontFamily: 'Helvetica',
    fontSize: '18px',
    fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    textAlign: 'center',
    color: props.theme?.palette?.primary.dark,
    marginBottom: '30px'
  })
)

export const ContentInfo = styled( TypographyWrapper )(() => ({
  left: '50%',
  fontFamily: 'Helvetica',
  fontSize: '18px',
  fontStretch: 'normal',
  fontStyle: 'normal',
  lineHeight: 'normal',
  letterSpacing: 'normal',
  textAlign: 'center',
  color: '#c7c6c5',
  marginTop: '10px',
  marginBottom: '20px'
}))

export const ButtonDelete = styled( ButtonWrapper )(
  ( props: ButtonProps & ITheme ) => ({
    borderRadius: '2.625rem',
    backgroundColor: props.theme?.palette?.primary.dark,
    fontSize: '1.125rem',
    height: '4.0625rem',
    width: '60%',
    textTransform: 'none',
    left: '20%'
  })
)
