import { styled } from '@mui/material/styles'

import { IconButtonWrapper } from '../wrapper'

export const ButtonBack = styled(IconButtonWrapper)(({theme}) => ({
  color: theme.palette.primary.dark,
  backgroundColor: theme.palette.common.white,
  height: '3rem',
  width: '3rem',
  boxShadow: '0px 0px 6px rgba(0, 0, 0, 0.08)',
  [theme.breakpoints.down('sm')]: {
    ':hover': {
      backgroundColor: theme.palette.common.white,
    }
  }
}))
