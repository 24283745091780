
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DatePicker, DatePickerProps } from '@mui/x-date-pickers/DatePicker'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import dayjs from 'dayjs'
import { FC } from 'react'
import 'dayjs/locale/es'

const DatePickerWrapper: FC<DatePickerProps<dayjs.Dayjs>> = ({ ...props }) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="es">
      <DatePicker {...props} format='DD-MM-YYYY' />
    </LocalizationProvider>
  )
}

export default DatePickerWrapper

