/* global google */
import { useJsApiLoader } from '@react-google-maps/api'
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react'

import { useNavigate } from 'react-router-dom'
import { v4 as uuidv4 } from 'uuid'

import {
  AccountContext,
  AuthContextType,
} from '../../../../../contexts/AccountContext'
import {
  ICareMedicalOffice,
  RegisterMedicalContext,
} from '../../../../../contexts/RegisterContext'
import { Geolocation } from '../../../../medical/Geolocation'

interface IUseRegisterConsultory {
  isLoaded: boolean
  map: google.maps.Map | null
  disabledButton: boolean
  zoom: number
  selected: boolean
  mapCenter: ICoordinates
  address: string
  consultingRoom: string
  ValidationFocus: boolean[]
  isItemModifying: boolean
  consultingFloor: string
  consultingOffice: string
  value: IPlaceType | null
  officeMedical: IOfficeMedical[]
  appointmentPrice: string
  markerPosition: google.maps.LatLng | null
  onBack: () => void
  onValidationFocus: (index: number) => void
  setValueEdition: Dispatch<SetStateAction<IPlaceType | null>>
  newOfficeMedical: () => void
  onSubmit: (event: { preventDefault: () => void }) => void
  addressEdition: (id: string, valor: IPlaceType | null) => void
  deleteItem: (value: IOfficeMedical) => void
  editItem: (office: IOfficeMedical, value: string, key: string) => void
  modifyItem: (value: IOfficeMedical) => void
  setConsultingRoom: Dispatch<SetStateAction<string>>
  setMarkerPosition: Dispatch<SetStateAction<google.maps.LatLng | null>>
  setSelected: Dispatch<SetStateAction<boolean>>
  setConsultingFloor: Dispatch<SetStateAction<string>>
  setConsultingOffice: Dispatch<SetStateAction<string>>
  setValue: Dispatch<SetStateAction<IPlaceType | null>>
  setZoom: Dispatch<SetStateAction<number>>
  setAddress: Dispatch<SetStateAction<string>>
  setMapCenter: Dispatch<SetStateAction<ICoordinates>>
  setAppointmentPrice: Dispatch<SetStateAction<string>>
  setMap: Dispatch<SetStateAction<google.maps.Map | null>>
}

export interface IOfficeMedical {
  address: string
  building_name: string
  building_floor: string
  office_number: string
  coordinates: {
    latitude: number
    longitude: number
  }
  consultationValue: string
  id: string
  state: boolean
  value: IPlaceType | null
}
export interface ICoordinates {
  lat: number
  lng: number
}

const center: ICoordinates = {
  lat: -0.0102496,
  lng: -78.4464668,
}

interface IMainTextMatchedSubstrings {
  offset: number
  length: number
}
interface IStructuredFormatting {
  main_text: string
  secondary_text: string
  main_text_matched_substrings?: readonly IMainTextMatchedSubstrings[]
}
export interface IPlaceType {
  description: string
  structured_formatting: IStructuredFormatting
}

type Libraries = (
  | 'drawing'
  | 'geometry'
  | 'localContext'
  | 'places'
  | 'visualization'
)[]

const libraries = ['places'] satisfies Libraries

export function useRegisterConsultory(): IUseRegisterConsultory {
  const { handleAlert } = useContext(AccountContext) as AuthContextType

  const { saveOfficeMedical, updateMedicalCare, RegisterMedical } = useContext(
    RegisterMedicalContext,
  )
  const [actionButton, setactionButton] = useState<boolean>(false)
  const { coordinates } = Geolocation(handleAlert)
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY as string,
    libraries,
  })
  const [map, setMap] = useState<google.maps.Map | null>(null)
  const [markerPosition, setMarkerPosition] =
    useState<google.maps.LatLng | null>(null)
  const [zoom, setZoom] = useState(5) // initial zoom
  const [address, setAddress] = useState<string>('')
  const [selected, setSelected] = useState<boolean>(false)
  const [mapCenter, setMapCenter] = useState<ICoordinates>(center)
  const [officeMedical, setOfficeMedical] = useState<IOfficeMedical[]>([])
  const [value, setValue] = useState<IPlaceType | null>(null)
  const [, setValueEdition] = useState<IPlaceType | null>(null)

  const navigate = useNavigate()

  const [consultingRoom, setConsultingRoom] = useState<string>('')
  const [consultingFloor, setConsultingFloor] = useState<string>('')
  const [consultingOffice, setConsultingOffice] = useState<string>('')
  const [appointmentPrice, setAppointmentPrice] = useState<string>('')
  const [ValidationFocus, setValidationFocus] = useState<boolean[]>([false])

  const updateContextsOnLeaveComponent = (): void => {
    saveOfficeMedical({ officeMedical: officeMedical })
  }

  const onSubmit = (event: { preventDefault: () => void }): void => {
    event.preventDefault()
    updateContextsOnLeaveComponent()
    navigate('/medico/register/profile2')
  }

  const geolocationPositionCenter = (): void => {
    const lat: number = coordinates.latitud
    const lng: number = coordinates.longitud

    setMapCenter({
      lat: lat,
      lng: lng,
    })
    setZoom(18)
    const latLng: google.maps.LatLng = new google.maps.LatLng(lat, lng)

    getAddressFromLatLng(latLng)
    setMarkerPosition(latLng)
    setSelected(true)
  }

  useEffect(() => {
    if (actionButton) {
      geolocationPositionCenter()
      setactionButton(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [coordinates])

  const deleteItem = (value: IOfficeMedical): void => {
    const newList: IOfficeMedical[] = officeMedical.filter(
      (item: IOfficeMedical) => item.id !== value.id,
    )

    const scheduleIndex = RegisterMedical.medicalCare.findIndex(
      (x) => x.id === value.id,
    )
    if (scheduleIndex >= 0) {
      const tempRegister: ICareMedicalOffice[] = RegisterMedical.medicalCare
      tempRegister.splice(scheduleIndex, 1)
      updateMedicalCare(tempRegister)
    }
    setOfficeMedical(newList)
  }

  const modifyItem = (value: IOfficeMedical): void => {
    const newList = officeMedical.map((item: IOfficeMedical) => {
      if (item.id === value.id) {
        const updatedItem: IOfficeMedical = {
          ...value,
          state: !item.state,
          coordinates:
            markerPosition?.lat() !== undefined &&
            markerPosition?.lng() !== undefined
              ? {
                  latitude: markerPosition?.lat(),
                  longitude: markerPosition?.lng(),
                }
              : item.coordinates,
        }

        return updatedItem
      }

      return item
    })

    setOfficeMedical(newList)
  }

  const editItem = (
    office: IOfficeMedical,
    value: string,
    key: string,
  ): void => {
    const newList = officeMedical.map((item: IOfficeMedical) => {
      if (item.id === office.id) {
        const updatedItem = {
          ...item,
          [key]: value,
        }

        return updatedItem
      }

      return item
    })

    setOfficeMedical(newList)
  }

  const addressEdition = (id: string, valor: IPlaceType | null): void => {
    const newList = officeMedical.map((item: IOfficeMedical) => {
      if (item.id === id) {
        const updatedItem = {
          ...item,
          value: valor,
          address: valor?.description || '',
        }

        return updatedItem
      }

      return item
    })

    setOfficeMedical(newList)
  }

  useEffect(() => {
    if (RegisterMedical?.officeMedical !== undefined) {
      setOfficeMedical(RegisterMedical?.officeMedical as IOfficeMedical[])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const newOfficeMedical = (): void => {
    let lat = 0
    let lng = 0

    if (
      markerPosition?.lat() !== undefined &&
      markerPosition?.lng() !== undefined
    ) {
      lat = markerPosition?.lat()
      lng = markerPosition?.lng()
    }
    const newOfficeMedical: IOfficeMedical = {
      address: address,
      building_name: consultingRoom,
      building_floor: consultingFloor,
      office_number: consultingOffice,
      coordinates: {
        latitude: lat,
        longitude: lng,
      },
      consultationValue: appointmentPrice,
      state: false,
      id: uuidv4(),
      value: value,
    }

    setOfficeMedical((prevOfficeMedical) => [
      ...prevOfficeMedical,
      newOfficeMedical,
    ])

    setConsultingRoom('')
    setAppointmentPrice('')
    setAddress('')
    setMap(null)
    setSelected(false)
    setMarkerPosition(null)
    setMapCenter(center)
    setValue(null)
    setConsultingFloor('')
    setConsultingOffice('')

    setValidationFocus([false])
  }

  const getAddressFromLatLng = useCallback(
    async (latLng: google.maps.LatLng): Promise<void> => {
      try {
        const geocoder: google.maps.Geocoder = new google.maps.Geocoder()

        await new Promise<google.maps.GeocoderResult[]>((resolve, reject) => {
          geocoder.geocode(
            { location: latLng },
            function (
              results: google.maps.GeocoderResult[] | null,
              status: google.maps.GeocoderStatus,
            ) {
              if (status === 'OK') {
                setAddress((results && results[0].formatted_address) || '')
              } else {
                reject(new Error(`Couldnt't find the location ${address}`))
              }
            },
          )
        })
      } catch (error: unknown) {
        handleAlert(true, 'Error al obtener la dirección', 'error')
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setAddress],
  )

  const onValidationFocus = (index: number): void => {
    const newValidationFocus: boolean[] = [...ValidationFocus]

    newValidationFocus[index] = true
    setValidationFocus(newValidationFocus)
  }

  const onBack = (): void => {
    updateContextsOnLeaveComponent()
    navigate('/medico/register/profile1')
  }

  const isItemModifying: boolean = officeMedical?.some((item) => item.state)
  const disabledButton: boolean = officeMedical.length === 0 || isItemModifying

  return {
    isLoaded,
    map,
    disabledButton,
    zoom,
    selected,
    mapCenter,
    address,
    consultingRoom,
    ValidationFocus,
    isItemModifying,
    consultingFloor,
    consultingOffice,
    value,
    officeMedical,
    appointmentPrice,
    markerPosition,
    setConsultingOffice,
    onBack,
    onValidationFocus,
    setValueEdition,
    newOfficeMedical,
    onSubmit,
    addressEdition,
    deleteItem,
    editItem,
    modifyItem,
    setConsultingRoom,
    setMarkerPosition,
    setSelected,
    setConsultingFloor,
    setValue,
    setZoom,
    setAddress,
    setMapCenter,
    setAppointmentPrice,
    setMap,
  }
}
