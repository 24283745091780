import { IGetMedicalExamsResponse } from '../../../dtos/MedicalExamsItem'
import IMedicalExamsDataSource from '../../dataSource/MedicalExamsDataSource'

export class MedicalExamsMapper implements IMedicalExamsDataSource {
  async getMedicalExams(patientId: string): Promise<IGetMedicalExamsResponse> {
    return {
      body: {
        message: `Success get medical exams for patientId: ${patientId}`,
        data: [
          {
            id: '1',
            date: '2024-09-01',
            exams: [
              {
                id: 'Anemia',
                name: 'Examen de anemia hemoglobina',
              },
              {
                id: 'Anemia',
                name: 'Examen gastrointestinal',
              },
            ],
          },
          {
            id: '2',
            date: '2023-11-01',
            exams: [],
          },
          {
            id: '3',
            date: '2021-09-01',
            exams: [],
          },
        ],
      },
      headers: {
        'Access-Control-Allow-Headers': '',
        'Access-Control-Allow-Origin': '',
        'Access-Control-Allow-Methods': '',
        'Access-Control-Allow-Credentials': false,
      },
      statusCode: 200,
    }
  }
}
