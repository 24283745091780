import {
  IUtilityNotificationLateAppointment,
} from '../../../infrastructure/dtos/ParametersNotification'
import { GetParametersNotificationLateAppointmentService } from '../Persistencia/ParametersNotificationService'

export async function GetParametersNotificationLateAppointmentUtility(): Promise<IUtilityNotificationLateAppointment> {
  try {
    const { data, status } =
      await GetParametersNotificationLateAppointmentService()

    if (
      status !== 1 ||
      data.statusCode !== 200
    ) {
      return {
        data: [],
        status: 0,
      }
    }

    return {
      data: data.body.notification_message,
      status: status,
    }
  } catch (error) {
    return {
      data: [],
      status: 0,
    }
  }
}
