import { AxiosError, AxiosResponse } from 'axios'

import {
  IReschedulingAppointment,
  IResponseReschedulingAppointment,
} from '../../../dtos/ReschedulingAppointment'
import ReschedulingAppointmentDataSource from '../../dataSource/ReschedulingAppointmentDataSource'
import { http } from '../../Http'

export default class ReschedulingAppointmentMapper
  implements ReschedulingAppointmentDataSource
{
  async saveReschedulingAppointment(
    dataReschedulingAppointment: IReschedulingAppointment,
  ): Promise<IResponseReschedulingAppointment> {
    const data = await http
      .post<IResponseReschedulingAppointment>(
        `save_data/rescheduling_appointment`,
        {
          body: dataReschedulingAppointment,
        },
      )
      .then((response: AxiosResponse) => {
        const { data } = response

        return data
      })
      .catch((error: AxiosError) => {
        const data = {
          statusCode: error.response?.status,
          body: error.response?.statusText,
          headers: {
            'Access-Control-Allow-Headers': '',
            'Access-Control-Allow-Origin': '',
            'Access-Control-Allow-Methods': '',
          },
        }

        return data
      })

    return data
  }
}
