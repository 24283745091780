import { IResponseService } from '../../../infrastructure/dtos'
import { ISendValidationIdDocument } from '../../../infrastructure/dtos/Validation'
import { ValidatorsIdDocumentService } from '../Persistencia/ValidatorsService'

export async function ValidatorsIdDocumentUtility(
  params: ISendValidationIdDocument,
): Promise<IResponseService<string>> {
  try {
    const { data, status } = await ValidatorsIdDocumentService(params)

    if (typeof data != 'string' && status !== 0) {
      return {
        data: data.body,
        status: data.statusCode !== 200 ? 0 : 1,
      }
    }

    return {
      data: 'Error Utility',
      status: 0,
    }
  } catch (error) {
    return {
      data: 'Error Utility',
      status: 0,
    }
  }
}
