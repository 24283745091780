import { useState } from 'react';

import { ICalendarEvent } from '../../../../components/Calendar/types';

interface IHoursOfNonAttendanceModalmHook {
  handleOpen: () => void;
  handleClose: () => void;
  open: boolean;
  onSubmitModalHoursOfNonAttendance: ( ) => Promise<void>;
  setDataModalHoursOfNonAttendance: React.Dispatch<
  React.SetStateAction<ICalendarEvent | undefined>
  >;
  dataModalHoursOfNonAttendance: ICalendarEvent | undefined;
}

export function useHoursOfNonAttendanceModal(): IHoursOfNonAttendanceModalmHook {
  const [open, setOpen] = useState<boolean>( false );
  const handleOpen = (): void => setOpen( true );
  const handleClose = (): void => setOpen( false );
  const onSubmitModalHoursOfNonAttendance = (): Promise<void> => new Promise(() => {
    setTimeout(() => {
      return Promise.resolve( true )
    }, 2000 )
  });
  const [dataModalHoursOfNonAttendance, setDataModalHoursOfNonAttendance] =
    useState<ICalendarEvent>();

  return {
    handleOpen,
    handleClose,
    open,
    onSubmitModalHoursOfNonAttendance,
    setDataModalHoursOfNonAttendance,
    dataModalHoursOfNonAttendance,
  };
}
