import React from 'react';

import {
  TypographyDoctorInfo,
  TypographyLabel,
  TypographyName,
  TypographyRecipeNumber,
} from './styles';
import helthyLogo from '../../../../../../assets/helthyLogo.png';
import { GridWrapper } from '../../../../../../components';
import FormTextInput from '../../../../../../components/FormInputText';
import { SecondaryMediumButton } from '../../../../../../components/SecondaryMediumButton/SecondaryMediumButton';
import { IDoctorPrescription } from '../../../../../../infrastructure/dtos/DoctorInfo';

interface IRecipeHeaderProps {
  doctorAppointmentInfo: IDoctorPrescription;
  code?: string;
  error?: boolean;
  message?: string;
  loading?: boolean
  saveDoctorCode?(event: { preventDefault: () => void }): Promise<void>;
  setCode?: React.Dispatch<React.SetStateAction<string>>;
}

const MAX_CORRELATIVE_DIGITS = 6;

export function RecipeHeader({
  doctorAppointmentInfo,
  code,
  error = false,
  message = '',
  loading,
  saveDoctorCode = undefined,
  setCode = undefined,
}: IRecipeHeaderProps): React.JSX.Element {
  const formatCorrelative: string = String(
    doctorAppointmentInfo?.prescription?.prescription_no,
  ).padStart(MAX_CORRELATIVE_DIGITS, '0');

  return (
    <GridWrapper
      width={'100%'}
      height={'auto'}
      display={'flex'}
      alignItems={'center'}
      container
      marginBottom={2}
      sx={{ color: 'primary.dark' }}
    >
      <GridWrapper item xs={12} sm={4}>
        <img src={helthyLogo} alt="Helthy logo" width={'90%'} height={'auto'} />
      </GridWrapper>
      <GridWrapper item xs={12} sm={8} data-testid="diagnostic-header">
        <TypographyRecipeNumber>
          Receta No. {formatCorrelative}
        </TypographyRecipeNumber>
        <TypographyName variant="h6">
          Dr.{' '}
          {doctorAppointmentInfo?.doctor?.name &&
          doctorAppointmentInfo?.doctor?.last_name
            ? `${doctorAppointmentInfo?.doctor?.name} ${doctorAppointmentInfo?.doctor?.last_name}`
            : '-'}
        </TypographyName>
        <TypographyLabel>
          {doctorAppointmentInfo?.doctor?.specialties}
        </TypographyLabel>
        <form data-testid="code-access" onSubmit={saveDoctorCode}>
          <GridWrapper container spacing={3} marginY={1}>
            <GridWrapper
              item
              xs={doctorAppointmentInfo?.doctor?.msp_code ? 6 : 3}
              display={'flex'}
              alignItems={'center'}
              sx={{
                '&.MuiGrid-item': {
                  paddingTop: '0px',
                },
              }}
            >
              <TypographyLabel>
                Reg. ACESS: {doctorAppointmentInfo?.doctor?.msp_code}
              </TypographyLabel>
            </GridWrapper>
            {!doctorAppointmentInfo?.doctor?.msp_code && (
              <>
                <GridWrapper
                  item
                  xs={4}
                  marginTop={0.5}
                  marginBottom={0.5}
                  sx={{
                    '& p': { marginBottom: 0 },
                  }}
                >
                  <FormTextInput
                    id={'doctor-msp-code'}
                    type={'text'}
                    placeholder={'12345678'}
                    fullWidth
                    name={'doctor-msp-code'}
                    value={code as string}
                    onChange={(event) => {
                      (setCode as React.Dispatch<React.SetStateAction<string>>)(
                        event.target.value,
                      );
                    }}
                    error={error}
                    helperText={message}
                  />
                </GridWrapper>
                <GridWrapper
                  item
                  xs={4}
                  alignItems={'center'}
                  justifyContent={'center'}
                >
                  <SecondaryMediumButton
                    id="save-code-button"
                    type="submit"
                    sx={{ minWidth: '110px' }}
                    text="Guardar"
                    disabled={(code as string)?.length < 3 || loading}
                  />
                </GridWrapper>
              </>
            )}
          </GridWrapper>
        </form>
        <TypographyDoctorInfo>
          {doctorAppointmentInfo?.office_medical?.name_medical_office || '-'}
          {doctorAppointmentInfo?.office_medical?.reference &&
            `, ${doctorAppointmentInfo?.office_medical?.reference}`}
        </TypographyDoctorInfo>
        <TypographyDoctorInfo>
          {doctorAppointmentInfo?.doctor?.phone &&
          doctorAppointmentInfo?.office_medical?.office_phone
            ? `${doctorAppointmentInfo?.doctor?.phone} - ${doctorAppointmentInfo?.office_medical?.office_phone}`
            : doctorAppointmentInfo?.doctor?.phone ||
              doctorAppointmentInfo?.office_medical?.office_phone ||
              '-'}
        </TypographyDoctorInfo>
        <TypographyDoctorInfo>
          {doctorAppointmentInfo?.doctor?.email || '-'}
        </TypographyDoctorInfo>
      </GridWrapper>
    </GridWrapper>
  );
}
