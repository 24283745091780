import { styled } from '@mui/material'

import { TextFieldWrapper } from '../../../../components'

export const TextFielDiagnostic = styled(TextFieldWrapper)(({ theme }) => ({
  '& .MuiInputLabel-root': {
    marginBotton: '20px',
  },
  '& .MuiInputBase-root': {
    borderRadius: '55px',
    backgroundColor: theme.palette.common.white,
    fontSize: '12px',
    boxShadow: '1px 1px 7px 7px rgba(0, 0, 0, 0.03)',
    color: theme.palette?.text.primary,
    fontFamily: 'Helvetica',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    height: '100%',
    '@media (min-width: 1600px)': {
      fontSize: '16px',
    },
  },
  '& fieldset': { border: 'none' },
  '&::placeholder': {
    marginLeft: '30px',
  },
}))
