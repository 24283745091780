
// tranform input value 30 to '30 minutos'


const labels = ['minuto', 'minutos', 'hora', 'horas']
export const transformMinutesNumToString = (value: number): string => {
  // check if value is a number int
  if (value < 0 || !Number.isInteger(value)) {
    return ''
  }
  if (value === 1) {
    return `${value} ${labels[0]}`
  } if (value > 1 && value < 60) {
    return `${value} ${labels[1]}`
  } if (value === 60) {
    return `${value / 60} ${labels[2]}`
  } if (value > 60) {
    return `${value / 60} ${labels[3]}`
  }
  return ''
}
