import { EventImpl } from '@fullcalendar/core/internal'
import { Dispatch, SetStateAction, useEffect, useState } from 'react'

import {
  IMedicalOffice,
  IOpeningHours,
  IScheduleHours,
} from '../../../infrastructure/dtos/Offices'
import { useHoursOfNonAttendanceModal } from '../../../views/Dashboard/CalendarForm/Hooks/useHoursOfNonAttendanceModal'

interface IUseCalendarHook {
  title: string
  open: boolean
  start: Date | null | undefined
  end: Date | null | undefined
  remove: EventImpl | undefined
  eventId: string
  allDay: boolean
  businessHours: IBusinessHours[]
  handleClose: () => void
  eventOpen: (data: IDataEvent, event?: EventImpl) => void
}

interface IBusinessHours {
  startTime: string
  endTime: string
  daysOfWeek: number[]
}

type days =
  | 'sunday'
  | 'monday'
  | 'tuesday'
  | 'wednesday'
  | 'thursday'
  | 'friday'
  | 'saturday'

const DaysOfWeek: { [key: string]: number } = {
  sunday: 0,
  monday: 1,
  tuesday: 2,
  wednesday: 3,
  thursday: 4,
  friday: 5,
  saturday: 6,
}

interface IDataEvent {
  groupId: string
  id: string
  title: string
  start: Date | null
  end: Date | null
  allDay: boolean
}

export function useCalendar(
  office: IMedicalOffice | undefined,
  handleOpenAppointmentDetailsModal: (value: boolean) => void,
  setAppointmentId: Dispatch<SetStateAction<string>>,
): IUseCalendarHook {
  const [title, setTitle] = useState<string>('')
  const [start, setStart] = useState<Date | null | undefined>()
  const [end, setEnd] = useState<Date | null | undefined>()
  const [remove, setRemove] = useState<EventImpl>()
  const [eventId, setEventId] = useState<string>('')
  const [allDay, setAllDay] = useState<boolean>(false)
  const [businessHours, setBusinessHours] = useState<IBusinessHours[]>([])

  const { handleClose, handleOpen, open } = useHoursOfNonAttendanceModal()

  const eventOpen = (data: IDataEvent, event?: EventImpl): void => {
    if (data.groupId === 'appointment') {
      setAppointmentId(data.id)
      handleOpenAppointmentDetailsModal(true)
      return
    }
    setTitle(data.title)
    setStart(data.start)
    setEnd(data.end)
    setRemove(event)
    setEventId(data.id)
    setAllDay(data.allDay)
    handleOpen()
  }

  const getOfficeSchedule = (): IBusinessHours[] => {
    const newBusinessHours: IBusinessHours[] = []
    if (office?.type_office == 'DOCTOR_CM') {
      office?.schedule_hours?.forEach((day_open: IScheduleHours) => {
        newBusinessHours.push({
          startTime: formatHour(day_open.hour_open as string),
          endTime: formatHour(day_open.hour_close as string),
          daysOfWeek: [DaysOfWeek[day_open.day]],
        })
      })
    } else if (office?.opening_hours) {
      Object.keys(office?.opening_hours as IOpeningHours)?.forEach(
        (day: string) => {
          newBusinessHours.push({
            startTime: formatHour(
              office?.opening_hours?.[day as days].hour_open as string,
            ),
            endTime: formatHour(
              office?.opening_hours[day as days].hour_close as string,
            ),
            daysOfWeek: [DaysOfWeek[day]],
          })
        },
      )
    }

    return newBusinessHours
  }

  const formatHour = (horaString: string): string => {
    const [hour, minutes] = horaString.split(':')
    const formattedHour = parseInt(hour, 10).toString().padStart(2, '0')
    const formattedMinutes = parseInt(minutes, 10).toString().padStart(2, '0')
    return `${formattedHour}:${formattedMinutes}`
  }

  useEffect(() => {
    setBusinessHours(getOfficeSchedule())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [office])

  return {
    title,
    start,
    end,
    remove,
    eventId,
    allDay,
    businessHours,
    open,
    handleClose,
    eventOpen,
  }
}
