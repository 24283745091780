// eslint-disable-next-line import/no-extraneous-dependencies
import { create } from 'zustand'

import { ICareMedicalOffice, IOfficeMedical } from './RegisterContext'

interface INewOfficeStore {
  openCreateOffice: boolean
  setOpenCreateOffice: (officeSelected: boolean) => void
  office: IOfficeMedical
  setOffice: (office: IOfficeMedical) => void
  clearOffice: () => void
  schedule: ICareMedicalOffice
  setSchedule: (schedule: ICareMedicalOffice) => void
  clearAll: () => void
}

const initialState = {
  coordinates: {},
  id: '',
} as IOfficeMedical

const initSchedule: ICareMedicalOffice = {
  daysMedicalCare: [
    {
      day: 'Lunes',
      from: '00h00',
      to: '00h00',
      active: false,
    },
    {
      day: 'Martes',
      from: '00h00',
      to: '00h00',
      active: false,
    },
    {
      day: 'Miércoles',
      from: '00h00',
      to: '00h00',
      active: false,
    },
    {
      day: 'Jueves',
      from: '00h00',
      to: '00h00',
      active: false,
    },
    {
      day: 'Viernes',
      from: '00h00',
      to: '00h00',
      active: false,
    },
    {
      day: 'Sábado',
      from: '00h00',
      to: '00h00',
      active: false,
    },
    {
      day: 'Domingo',
      from: '00h00',
      to: '00h00',
      active: false,
    },
  ],
  id: '',
  durationMedicalAppointment: '',
  reference: '',
  office_phone_number: '',
  visualisationAgenda: '',
  appointmentTypes: [],
}

export const useCreateOfficeStore = create<INewOfficeStore>((set) => ({
  openCreateOffice: false,
  setOpenCreateOffice: (openCreateOffice) => set((state) => ({...state,  openCreateOffice })),
  office: initialState,
  setOffice: (office: IOfficeMedical) => set((state) => ({ ...state,  office })),
  clearOffice: () => set((state) => ({ ...state,  office: initialState })),
  schedule: initSchedule,
  setSchedule: (schedule: ICareMedicalOffice) => set((state) => ({ ...state,  schedule })),
  clearAll: () => set((state) => ({ ...state,  office: initialState, schedule: initSchedule })),
}))
