import {
  IApiResponseCatalog,
  IResponseNotification,
  IResponseParameters,
} from '../../infrastructure/dtos/Catalog';
import { CatalogRepository } from '../repository/CatalogRepository';

export interface GetCatalogParametersUseCase {
  invoke: ( item: string ) => Promise<IApiResponseCatalog<IResponseParameters>>;
}

export interface GetCatalogNotificationUseCase {
  invoke: ( item: string ) => Promise<IApiResponseCatalog<IResponseNotification>>;
}

export class GetCatalogNotification implements GetCatalogNotificationUseCase {
  private objectUseCase: CatalogRepository;

  constructor( _objectUseCase: CatalogRepository ) {
    this.objectUseCase = _objectUseCase;
  }

  async invoke( item: string ): Promise<IApiResponseCatalog<IResponseNotification>> {
    return this.objectUseCase.getCatalogNotification( item );
  }
}

export class GetCatalogParameters implements GetCatalogParametersUseCase {
  private objectUseCase: CatalogRepository;

  constructor( _objectUseCase: CatalogRepository ) {
    this.objectUseCase = _objectUseCase;
  }

  async invoke( item: string ): Promise<IApiResponseCatalog<IResponseParameters>> {
    return this.objectUseCase.getCatalogParameters( item );
  }
}
