import { SxProps } from '@mui/material'
import React, { ChangeEvent, createRef } from 'react'

import { GridItem } from '../GridItem/GridItem'
import { SecondaryMediumButton } from '../SecondaryMediumButton/SecondaryMediumButton'
import { AvatarProfile } from '../StyledComponents/AvatarProfile'
import { LabelInput } from '../StyledComponents/LabelInput'
import { BoxWrapper, StackWrapper, TooltipWrapper } from '../wrapper'

interface UploadPhotoProps {
  id: string
  selectedImage: string
  title?: string
  tooltipText: string
  buttonText: string
  buttonType?: 'button' | 'reset' | 'submit' | undefined
  xs?: number
  sx?: SxProps
  onChange: ( event: ChangeEvent<HTMLInputElement> ) => void
}

const UploadPhoto = ({
  id,
  selectedImage,
  title = '',
  tooltipText,
  buttonText,
  buttonType,
  onChange
}: UploadPhotoProps ): React.JSX.Element => {
  const inputRef: React.RefObject<HTMLInputElement> =
    createRef<HTMLInputElement>()
  const handleClick = (): void => {
    inputRef.current?.click()
  }

  return (
    <>
      <LabelInput alignSelf={'left'}>{title}</LabelInput>
      <StackWrapper
        spacing={2}
        direction={{ xs: 'column', sm: 'row' }}
        display={'flex'}
        alignItems={'center'}
        justifyContent={'center'}
        alignSelf={'center'}
        width={{ xs: '100%', sm: '80%' }}
        id={'UploadPhotoStack'}
      >
        <GridItem xs={12} sm={6} width={'100%'}>
          <AvatarProfile src={selectedImage} role="img" id={'profileImage'}/>
        </GridItem>
        <GridItem xs={12} sm={6} width={{ xs: '60%', sm: '100%', lg: '40%' }}>
          <TooltipWrapper title={tooltipText} placement="top">
            <BoxWrapper width={'100%'}>
              <SecondaryMediumButton
                id={id}
                text={buttonText}
                type={buttonType}
                onClick={handleClick}
              >
                <input
                  hidden
                  accept="image/*"
                  multiple
                  type="file"
                  onChange={onChange}
                  ref={inputRef}
                />
              </SecondaryMediumButton>
            </BoxWrapper>
          </TooltipWrapper>
        </GridItem>
      </StackWrapper>
    </>
  )
}

export default UploadPhoto
