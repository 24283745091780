import { AxiosError } from 'axios'

import { IResponseApi } from '../../../dtos'
import { ISendValidationIdDocument } from '../../../dtos/Validation'
import ValidatorsDataSource from '../../dataSource/ValidatorsDataSource'
import { http } from '../../Http'

export default class ValidatorsMapper implements ValidatorsDataSource {
  async IdDocument(
    params: ISendValidationIdDocument,
  ): Promise<IResponseApi<string>> {
    try {
      const { data } = await http.get<IResponseApi<string>>(
        `/validate/document_id/DOCTOR?document_type=${params.document_type}&document=${params.document}&type=${params.type}`,
      )

      return data
    } catch (error: unknown) {
      return {
        statusCode: (error as AxiosError).response?.status as number,
        body: (error as AxiosError).message,
        headers: {
          'Access-Control-Allow-Headers': '',
          'Access-Control-Allow-Origin': '',
          'Access-Control-Allow-Methods': '',
        },
      }
    }
  }
}
