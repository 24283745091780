import {
  BodyCheckScheduleImpactRequest,
  CheckScheduleImpactUtilityResponse,
  IApiResponseCheckScheduleImpact, ParamsCheckModifyOfficeImpact,
} from '../../../infrastructure/dtos/CheckScheduleImpact'
import { checkModifyOfficeImpactService, checkScheduleImpactService } from '../Persistencia/ScheduleService'

export const checkScheduleImpactUtility = async (
  body: BodyCheckScheduleImpactRequest,
): Promise<CheckScheduleImpactUtilityResponse> => {
  try {
    const { data } = await checkScheduleImpactService(body)

    return {
      status:
        (data as IApiResponseCheckScheduleImpact).statusCode === 200 ? 1 : 0,
      data: (data as IApiResponseCheckScheduleImpact).body,
    }
  } catch (error: unknown) {
    return {
      status: 0,
      data: {
        message: (error as Error)?.message,
        data: {},
      },
    }
  }
}

export const checkModifyOfficeImpactUtility = async (
  params: ParamsCheckModifyOfficeImpact,
): Promise<CheckScheduleImpactUtilityResponse> => {
  try {
    const { data } = await checkModifyOfficeImpactService(params)

    return {
      status:
        (data as IApiResponseCheckScheduleImpact).statusCode === 200 ? 1 : 0,
      data: (data as IApiResponseCheckScheduleImpact).body,
    }
  } catch (error: unknown) {
    return {
      status: 0,
      data: {
        message: (error as Error)?.message,
        data: {},
      },
    }
  }
}
