import { Agreements } from '../../../domain/useCase/AgreementUseCase'
import { IApiResponseAgreement } from '../../../infrastructure/dtos/Agreements'
import { IApiResponseInvite } from '../../../infrastructure/dtos/AgreementsInvites'
import {
  IApiResponseReply,
  Reply,
} from '../../../infrastructure/dtos/ReplyInvites'
import AgreementsMapper from '../../../infrastructure/http/mappers/AgreementsMapper/AgreementsMapper'
import { AgreementRepositoryImpl } from '../../../infrastructure/RepositoryImpl/AgreementRepositoryImpl'

export interface IInviteService {
  status: number
  data: IApiResponseInvite | string
}

export interface IReplyService {
  status: number
  data: ResponseReply
}

export type ResponseReply = Omit<IApiResponseReply, 'headers'>

export interface IAgreementService {
  status: number
  data: IApiResponseAgreement | string
}

export async function InviteService(idDoctor: string): Promise<IInviteService> {
  try {
    const UseCaseInvite = new Agreements(
      new AgreementRepositoryImpl(new AgreementsMapper()),
    )

    return { status: 1, data: await UseCaseInvite.invoke(idDoctor) }
  } catch (error: unknown) {
    return { status: 0, data: error as string }
  }
}

export async function SendReplyService(params: Reply): Promise<IReplyService> {
  try {
    const useCaseReply = new Agreements(
      new AgreementRepositoryImpl(new AgreementsMapper()),
    )

    return {
      status: 1,
      data: await useCaseReply.sendReply(params),
    }
  } catch (error: unknown) {
    return {
      status: 0,
      data: {
        body: { message: (error as Error).message },
        statusCode: 500,
      },
    }
  }
}

export async function GetAgreementsService(
  idDoctor: string,
): Promise<IAgreementService> {
  try {
    const UseCaseAgreement = new Agreements(
      new AgreementRepositoryImpl(new AgreementsMapper()),
    )

    return {
      data: await UseCaseAgreement.getAgreements(idDoctor),
      status: 1,
    }
  } catch (error: unknown) {
    return {
      status: 0,
      data: (error as Error).message,
    }
  }
}
