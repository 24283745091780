import { styled } from '@mui/material'

import { SelectWrapper } from '../../../../../components'

export const SelectOffice = styled(SelectWrapper)((props) => ({
  width: '100%',
  borderRadius: '42px',
  backgroundColor: props.theme.palette.common.white,
  boxShadow: '1px 1px 7px 7px rgba(0, 0, 0, 0.03)',
  height: '50px',
  '@media (min-width: 1600px)': {
    height: '57px',
  },
  '& fieldset': { border: 'none' },
  '& .MuiSelect-select': {
    fontSize: '12px',
    '@media (min-width: 1600px)': {
      fontSize: '16px',
    },
  },
}))
