import { Autocomplete, styled } from '@mui/material'

import { FormControlWrapper, TextFieldWrapper } from '../wrapper'

export const AutocompleteStyled = styled(Autocomplete)(({ theme }) => ({
  border: 'none',
  minWidth: '90px',
  height: '50px',
  '@media (min-width: 1600px)': {
    height: '57px',
  },
  borderRadius: '55px',
  backgroundColor: theme.palette.common.white,
  fontSize: '12px',
  fontFamily: 'Helvetica',
  fontWeight: 'normal',
  fontStretch: 'normal',
  fontStyle: 'normal',
  lineHeight: 'normal',
  letterSpacing: 'normal',
  marginLeft: '5px',
  marginRight: '5px',
  boxShadow: '1px 1px 7px 7px rgba(0, 0, 0, 0.03)',
  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },
  '& input': {
    fontSize: '12px',
    '@media (min-width: 1600px)': {
      fontSize: '16px',
    },
  },
}))

export const FormControlStyled = styled(FormControlWrapper)(() => ({
  width: '100%',
}))

export const TextFieldAutocomplete = styled(TextFieldWrapper)(() => ({
  '& .MuiInputBase-root': {
    '& input': {
      textAlign: 'center',
    },
  },
}))
