import { AxiosError, AxiosResponse } from 'axios'

import { IApiResponseVisibilityIntervalsAWS } from '../../../dtos'
import IVisibilityIntervalsDataSource from '../../dataSource/VisibilityIntervalsDataSource'
import { http } from '../../Http'

export default class VisibilityIntervalsMapper
  implements IVisibilityIntervalsDataSource
{
  async getVisibilityIntervals(): Promise<IApiResponseVisibilityIntervalsAWS> {
    const data: IApiResponseVisibilityIntervalsAWS = await http
      .get<IApiResponseVisibilityIntervalsAWS>(
        `/send_data/visibility_intervals`,
      )
      .then((response: AxiosResponse) => {
        const { data } = response
        let dataBody = data.body

        if (typeof data.body === 'string') {
          dataBody = JSON.parse(data.body)
        }
        const dataR: IApiResponseVisibilityIntervalsAWS = {
          statusCode: data.statusCode,
          body: dataBody,
          headers: data.headers,
        }

        return dataR
      })
      .catch((error: AxiosError) => {
        const data: IApiResponseVisibilityIntervalsAWS = {
          statusCode: error.response?.status as number,
          body: error.response?.statusText as string,
          headers: {
            'Access-Control-Allow-Headers': '',
            'Access-Control-Allow-Origin': '',
            'Access-Control-Allow-Methods': '',
          },
        }

        return data
      })

    return data
  }
}
