import {
  IApiResponseCatalog,
  IResponseNotification,
  IResponseParameters,
  IServiceCatalog,
} from '../../../infrastructure/dtos/Catalog';
import {
  GetCatalogNotificationService,
  GetCatalogParametersService,
} from '../Persistencia/CatalogService';

export async function GetCatalogNotificationUtility(
  item: string
): Promise<IServiceCatalog<string>> {
  try {
    const {
      data,
      status,
    }: IServiceCatalog<IApiResponseCatalog<IResponseNotification>> =
      await GetCatalogNotificationService( item );

    const response = data as IApiResponseCatalog<IResponseNotification>

    return {
      data: typeof response.body != 'string' ? response.body.data : response.body,
      status: status === 1 && response.statusCode === 200 ? status : 0,
    };

  } catch ( error: unknown ) {
    return {
      status: 0,
      data: ((error as IServiceCatalog<IApiResponseCatalog<string>>).data as IApiResponseCatalog<string>).body,
    };
  }
}

export async function GetCatalogParametersUtility(
  item: string
): Promise<IServiceCatalog<string[]>> {
  try {
    const {
      data,
      status,
    }: IServiceCatalog<IApiResponseCatalog<IResponseParameters>> =
      await GetCatalogParametersService( item );
    
    const response = data as IApiResponseCatalog<IResponseParameters>

    return {
      data: typeof response.body != 'string' ? response.body.data : response.body,
      status: status === 1 && response.statusCode === 200 ? status : 0,
    };
  } catch ( error: unknown ) {
    return {
      status: 0,
      data: ((error as IServiceCatalog<IApiResponseCatalog<string>>).data as IApiResponseCatalog<string>).body,
    };
  }
}
