import { IClinicalPictureResponse, ISaveClinicalPictureBodyResponse, ISaveClinicalPictureServiceParams, ISaveClinicalPictureUtility } from '../../../infrastructure/dtos/ClinicalPicture'
import { ClinicalPictureService, IClinicalPictureService } from '../Persistencia/ClinicalPictureService'

export async function saveClinicalPictureUtility(
  params: ISaveClinicalPictureServiceParams,
): Promise<ISaveClinicalPictureUtility> {
  try {
    const { data, status }: IClinicalPictureService =
      await ClinicalPictureService(params)

    return {
      data: (data as IClinicalPictureResponse).body as ISaveClinicalPictureBodyResponse,
      status:
        (data as IClinicalPictureResponse).statusCode === 200 && status === 1
          ? status
          : 0,
    }
  } catch (error: unknown) {
    return {
      status: 0,
      data: ((error as IClinicalPictureService)?.data as IClinicalPictureResponse).body,
    }
  }
}
