import {
  IBodyPatientInfo,
  IApiResponsePatientInfo,
  IServicePatientInfo,
} from '../../../infrastructure/dtos/Patient'
import { GetPatientInfoByCiService } from '../Persistencia/PatientService'

export interface IGetPatientInfoUtility<T> {
  status: number
  data: string | T
}

export async function getPatientInfoByCiUtility(
  patientCi: string,
  isOnlyInfo?: boolean,
): Promise<IGetPatientInfoUtility<IBodyPatientInfo>> {
  try {
    const { data, status } = await GetPatientInfoByCiService(
      patientCi,
      isOnlyInfo,
    )

    return {
      data: (data as IApiResponsePatientInfo).body,
      status:
        (data as IApiResponsePatientInfo).statusCode === 200 && status === 1
          ? status
          : 0,
    }
  } catch (error: unknown) {
    return {
      status: 0,
      data: ((error as IServicePatientInfo).data as IApiResponsePatientInfo).body,
    }
  }
}
