import { Theme, TypographyProps, styled } from '@mui/material'

import {
  BoxWrapper,
  FadeWrapper,
  GridWrapper,
  IconButtonWrapper,
  TypographyWrapper,
} from '../../../../../components/wrapper'

type ITheme = {
  theme?: Theme
}

export const FadeModal = styled(FadeWrapper)(() => ({
  width: '48%',
  maxWidth: '588px',
  height: 'auto',
  overflowY: 'auto',
}))

export const BoxModal = styled(BoxWrapper)(({ theme }) => ({
  position: 'absolute',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '700px',
  minHeight: '500px',
  bgcolor: theme.palette.background.paper,
  background: '#fff',
  borderRadius: '30px',
  p: 4,
  boxShadow: '2px 2px 18px 14px rgba(0, 0, 0, 0.03)',
  padding: '40px 30px',
  marginTop: '28%',
}))

export const CloseButton = styled(IconButtonWrapper)((props) => ({
  position: 'absolute',
  right: 20,
  top: 20,
  color: props.theme.palette.primary.dark,
  marginBottom: '20px',
}))

export const ModalTitle = styled(TypographyWrapper)(
  (props: TypographyProps & ITheme) => ({
    left: '50%',
    fontFamily: 'Helvetica',
    fontSize: '18px',
    fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    textAlign: 'center',
    color: props.theme?.palette?.primary.dark,
    marginBottom: '30px',
  }),
)

export const TypographyModalTittle = styled(TypographyWrapper)(() => ({
  marginTop: '10px',
  fontFamily: 'Helvetica',
  fontSize: '15px',
  fontWeight: 'normal',
  fontStretch: 'normal',
  fontStyle: 'normal',
  lineHeight: 'normal',
  letterSpacing: 'normal',
  textAlign: 'left',
  color: '#000',
  marginBottom: '10px',
}))

export const BottonGrid = styled(GridWrapper)(() => ({
  marginTop: '20px',
}))
