import {
  IRecipePrescription,
  ISavePrescriptionProps,
  ISendPrescriptionProps,
} from '../../../infrastructure/dtos/Prescriptions'
import {
  getAppointmentsRecipesService,
  IGetAppointmentRecipesService,
  ISavePrescriptionService,
  ISendPrescriptionService,
  savePrescriptionService,
  sendAppointmentRecipeService,
} from '../Persistencia/PrescriptionsService'

export interface ISavePrescriptionUtility {
  status: number
  data: string
}

export interface IGetAppointmentRecipesUtility {
  status: number
  data: IRecipePrescription | string
}

export interface ISendPrescriptionUtility {
  status: number
  data: string
}

export async function savePrescriptionUtility(
  params: ISavePrescriptionProps,
): Promise<ISavePrescriptionUtility> {
  try {
    const { data, status }: ISavePrescriptionService =
      await savePrescriptionService(params)

    return {
      data: data.body.message,
      status: status === 1 && data.statusCode === 200 ? status : 0,
    }
  } catch (error: unknown) {
    return {
      status: 0,
      data: (error as ISavePrescriptionService)?.data.body.message,
    }
  }
}

export async function sendAppointmentRecipeUtility(
  body: ISendPrescriptionProps,
): Promise<ISendPrescriptionUtility> {
  try {
    const { data, status }: ISendPrescriptionService =
      await sendAppointmentRecipeService(body)
    return {
      data: data.body,
      status: data.statusCode === 200 && status ===1 ? status : 0,
    }
  } catch (error: unknown) {
    return {
      status: 0,
      data: (error as ISendPrescriptionService)?.data.body,
    }
  }
}

export async function getAppointmentRecipesUtility(
  appointmentId: string,
): Promise<IGetAppointmentRecipesUtility> {
  try {
    const { data }: IGetAppointmentRecipesService =
      await getAppointmentsRecipesService(appointmentId)
    return {
      data: data.body,
      status: data?.statusCode === 200 ? 1 : 0,
    }
  } catch (error: unknown) {
    return {
      status: 0,
      data: (error as IGetAppointmentRecipesService).data.body,
    }
  }
}
