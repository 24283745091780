import { AxiosError, AxiosResponse } from 'axios'

import { IApiResponseAgreement } from '../../../dtos/Agreements'
import { IApiResponseInvite } from '../../../dtos/AgreementsInvites'
import { Reply, IApiResponseReply } from '../../../dtos/ReplyInvites'
import AgreementsDataSource from '../../dataSource/AgreementsDataSource'
import { http } from '../../Http'

export default class AgreementsMapper implements AgreementsDataSource {
  async getAgreements(idDoctor: string): Promise<IApiResponseAgreement> {
    const data = await http
      .get<IApiResponseAgreement>(
        `send_data/cm_invitations_by_doctor/${idDoctor}`,
      )
      .then((response: AxiosResponse) => {
        const { data } = response
        return data
      })
      .catch((error: AxiosError) => {
        const data: IApiResponseAgreement = {
          body: error.response?.statusText as string,
          statusCode: error.response?.status as number,
          headers: {
            'Access-Control-Allow-Headers': '',
            'Access-Control-Allow-Origin': '',
            'Access-Control-Allow-Methods': '',
            'Access-Control-Allow-Credentials': false,
          },
        }

        return data
      })
    return data
  }

  async sendReply(params: Reply): Promise<IApiResponseReply> {
    try {
      const { data } = await http.put<IApiResponseReply>(
        'modified_data/reply_cm_invitation',
        params,
      )
      return data
    } catch (error: unknown) {
      return {
        statusCode: (error as AxiosError).response?.status as number,
        body: {
          message: (error as AxiosError).response?.statusText as string,
        },
        headers: {
          'Access-Control-Allow-Headers': '',
          'Access-Control-Allow-Origin': '',
          'Access-Control-Allow-Methods': '',
        },
      }
    }
  }

  async getInvites(idDoctor: string): Promise<IApiResponseInvite> {
    const data = await http
      .get<IApiResponseInvite>(`send_data/pending_cm_invitations/${idDoctor}`)
      .then((response: AxiosResponse) => {
        const { data } = response
        return data
      })
      .catch((error: AxiosError) => {
        const data: IApiResponseInvite = {
          body: error.response?.statusText as string,
          statusCode: error.response?.status as number,
          headers: {
            'Access-Control-Allow-Headers': '',
            'Access-Control-Allow-Origin': '',
            'Access-Control-Allow-Methods': '',
            'Access-Control-Allow-Credentials': false,
          },
        }

        return data
      })
    return data
  }
}
