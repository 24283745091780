import { AttendanceSelectedPatientRepository } from '../../domain/repository/AttendanceSelectedPatientRepository'
import { IAppointmentsByDoctorParams, PatientAppointmentsByDoctorResponse } from '../dtos/AttendanceSelectedPatient'
import AttendanceSelectedPatientDataSource from '../http/dataSource/AttendanceSelectedPatientDataSource'

export class AttendanceSelectedPatientRepositoryImpl
implements AttendanceSelectedPatientRepository
{
  dataSouerce: AttendanceSelectedPatientDataSource

  constructor( _dataSouerce: AttendanceSelectedPatientDataSource ) {
    this.dataSouerce = _dataSouerce
  }

  getPatientAppointments(
    params: IAppointmentsByDoctorParams
  ): Promise<PatientAppointmentsByDoctorResponse> {
    return this.dataSouerce.getPatientAppointments( params )
  }
}
