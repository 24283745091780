import {
  GetAppointmentRecipes,
  SavePrescription,
  SendAppointmentRecipe,
} from '../../../domain/useCase/PrescriptionsUseCase'
import {
  IApiPrescriptionInfo,
  IRecipePrescription,
  IResponseSavePrescription,
  IResponseSendPrescription,
  ISavePrescriptionProps,
  ISendPrescriptionProps,
} from '../../../infrastructure/dtos/Prescriptions'
import PrescriptionsMapper from '../../../infrastructure/http/mappers/PrescriptionsMapper/PrescriptionsMapper'
import { PrescriptionsRepositoryImpl } from '../../../infrastructure/RepositoryImpl/PrescriptionsRepositoryImpl'

export interface ISavePrescriptionService {
  status: number
  data: IResponseSavePrescription
}

export interface ISendPrescriptionService {
  status: number
  data: IResponseSendPrescription
}

export interface IGetAppointmentRecipesService {
  status: number
  data: IApiPrescriptionInfo<IRecipePrescription | string>
}

export async function savePrescriptionService(
  prescription: ISavePrescriptionProps,
): Promise<ISavePrescriptionService> {
  try {
    const UseCaseSavePrescription = new SavePrescription(
      new PrescriptionsRepositoryImpl(new PrescriptionsMapper()),
    )

    return {
      status: 1,
      data: await UseCaseSavePrescription.invoke(prescription),
    }
  } catch (error) {
    return {
      status: 0,
      data: error as IResponseSavePrescription,
    }
  }
}

export async function sendAppointmentRecipeService(
  body: ISendPrescriptionProps,
): Promise<ISendPrescriptionService> {
  try {
    const useCaseSendAppointmentRecipe = new SendAppointmentRecipe(
      new PrescriptionsRepositoryImpl(new PrescriptionsMapper()),
    )
    return {
      status: 1,
      data: await useCaseSendAppointmentRecipe.invoke(body),
    }
  } catch (error: unknown) {
    return {
      status: 0,
      data: error as IResponseSendPrescription,
    }
  }
}

export async function getAppointmentsRecipesService(
  appointmentId: string,
): Promise<IGetAppointmentRecipesService> {
  try {
    const useCaseGetAppointmentRecipes = new GetAppointmentRecipes(
      new PrescriptionsRepositoryImpl(new PrescriptionsMapper()),
    )
    return {
      status: 1,
      data: await useCaseGetAppointmentRecipes.invoke(appointmentId),
    }
  } catch (error) {
    return {
      status: 0,
      data: error as IApiPrescriptionInfo<IRecipePrescription | string>,
    }
  }
}
