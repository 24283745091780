import { AxiosError, AxiosResponse } from 'axios'

import {
  SaveAssistanceAppointment,
  IApiResponseSaveAssistanceAppointment,
  IApiResponseResetAssistanceAppointment,
} from '../../../dtos/Attendance'
import AttendanceDataSource from '../../dataSource/AttendanceDataSource'
import { http } from '../../Http'

export default class AttendanceMapper implements AttendanceDataSource {
  async saveAttendance(
    dataAttendance: SaveAssistanceAppointment,
  ): Promise<IApiResponseSaveAssistanceAppointment> {
    const data = await http
      .post<IApiResponseSaveAssistanceAppointment>(
        `save_data/save_assistance_appointment`,
        { body: dataAttendance },
      )
      .then((response: AxiosResponse) => {
        const { data } = response

        return data
      })
      .catch((error: AxiosError) => {
        const data = {
          statusCode: error.response?.status,
          body: error.response?.statusText,
          headers: {
            'Access-Control-Allow-Headers': '',
            'Access-Control-Allow-Origin': '',
            'Access-Control-Allow-Methods': '',
          },
        }

        return data
      })

    return data
  }

  async resetAttendance(
    appointmentId: string,
  ): Promise<IApiResponseResetAssistanceAppointment> {
    try {
      const { data } = await http.put<IApiResponseResetAssistanceAppointment>(
        'modified_data/reset_assistance',
        {
          appointment_id: appointmentId,
        },
      )
      return data
    } catch (error: unknown) {
      return {
        statusCode: (error as AxiosError)?.response?.status as number,
        body: {
          message: (error as AxiosError)?.response?.statusText as string,
        },
        headers: {
          'Access-Control-Allow-Headers': '',
          'Access-Control-Allow-Origin': '',
          'Access-Control-Allow-Methods': '',
          'Access-Control-Allow-Credentials': false,
        },
      }
    }
  }
}
