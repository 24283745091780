import { CatalogRepository } from '../../domain/repository/CatalogRepository';
import {
  IApiResponseCatalog,
  IResponseNotification,
  IResponseParameters,
} from '../dtos/Catalog';
import CatalogDataSource from '../http/dataSource/CatalogDataSource';

export class CatalogRepositoryImpl implements CatalogRepository {
  dataSource: CatalogDataSource;

  constructor( _DataSource: CatalogDataSource ) {
    this.dataSource = _DataSource;
  }

  async getCatalogNotification(
    item: string
  ): Promise<IApiResponseCatalog<IResponseNotification>> {
    return this.dataSource.getCatalogNotification( item );
  }

  async getCatalogParameters(
    item: string
  ): Promise<IApiResponseCatalog<IResponseParameters>> {
    return this.dataSource.getCatalogParameters( item );
  }
}
