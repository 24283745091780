import React, { useContext, useEffect, useState } from 'react'

import { SelectOffice } from './OfficeListStyle'
import { GridWrapper, MenuItemWrapper } from '../../../../../components'
import {
  AccountContext,
  AuthContextType,
} from '../../../../../contexts/AccountContext'
import {
  IApiResponseOffices,
  IMedicalOffice,
} from '../../../../../infrastructure/dtos/Offices'
import {
  IOfficeService,
  OfficesService,
} from '../../../../../services/Contracts/Persistencia/OfficesService'

interface IOfficeListProps {
  handleChangeOffice: (office: IMedicalOffice | undefined) => void
  office: string
  idDoctor: string
  filterOffices: string | 'ANY' | 'DOCTOR' | 'DOCTOR_CM'
  sm?: number
  disabled?: boolean
}

export const OfficeList = ({
  handleChangeOffice,
  office,
  idDoctor,
  sm = 12,
  disabled = false,
  filterOffices,
}: IOfficeListProps): React.JSX.Element => {
  const { handleAlert } = useContext(AccountContext) as AuthContextType
  const [list, setList] = useState<IMedicalOffice[]>([])

  useEffect(() => {
    const fetchOffices = async (): Promise<void> => {
      try {
        if (filterOffices) {
          const valores: IOfficeService = await OfficesService(
            idDoctor as string,
          )
          if ((valores.data as IApiResponseOffices)?.body) {
            const offices = (valores.data as IApiResponseOffices)?.body
              .medical_offices
            filterOffice(offices)
          }
        }
      } catch (error) {
        handleAlert(
          true,
          'Error al obtener el listado de consultorios',
          'error',
        )
      }
    }

    fetchOffices()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterOffices])

  const filterOffice = (offices: IMedicalOffice[]): void => {
    if (filterOffices === 'ANY') {
      setList(offices)
    } else if (filterOffices === 'DOCTOR_CM') {
      const cmOffices = offices.filter(
        (office) => office.type_office === 'DOCTOR_CM',
      )
      setList(cmOffices)
    } else {
      const privateOffices = offices.filter(
        (office) => office.type_office === 'DOCTOR',
      )
      setList(privateOffices)
    }
  }

  return (
    <GridWrapper container>
      <GridWrapper item xs={sm} sm={sm} data-testid="select-office">
        {list.length > 0 && (
          <SelectOffice
            name="SelectOffice"
            labelId="selectInputLabel"
            id="SelectOffice"
            value={office}
            onChange={(event) => {
              handleChangeOffice(
                list.find((item) => item.office_id === event.target.value),
              )
            }}
            disabled={disabled}
            defaultValue={''}
          >
            {list?.length > 0 &&
              list?.map((item: IMedicalOffice, index) => {
                return (
                  <MenuItemWrapper
                    key={index}
                    defaultValue={office}
                    value={item.office_id}
                  >
                    {item.reference_medical_office}
                  </MenuItemWrapper>
                )
              })}
          </SelectOffice>
        )}
      </GridWrapper>
    </GridWrapper>
  )
}
