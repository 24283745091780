import EditIcon from '@mui/icons-material/Edit'
import React, { ReactNode, useContext, useState } from 'react'

import { useNavigate } from 'react-router-dom'

import { NewOfficeModal } from './modals/CreateOffice'
import { EditOffice } from './modals/EditOffice/EditOffice'
import { useEditOffice } from './modals/EditOffice/useEditOffice'
import { LigthButton, ListItemInfo, ListItemTitle } from './styles'
import {
  GridWrapper,
  ListWrapper,
  TypographyWrapper,
} from '../../../../../components/wrapper'
import {
  AccountContext,
  AuthContextType,
} from '../../../../../contexts/AccountContext'
import { useDoctorInformationStore } from '../../../../../contexts/DoctorInformationState'
import { useCreateOfficeStore } from '../../../../../contexts/NewOfficeState'
import { useSelectOffice } from '../../../../../contexts/SelectOfficeState'

import { ParamsCheckModifyOfficeImpact } from '../../../../../infrastructure/dtos/CheckScheduleImpact'
import {
  IUpdateInfoOfficeParams,
  IMedicalOffice, IApiResponseOffices,
} from '../../../../../infrastructure/dtos/Offices'
import { IOfficeService, OfficesService } from '../../../../../services/Contracts/Persistencia/OfficesService'
import {
  UpdateOfficeStatusUtility,
  UpdateOfficeUtility,
} from '../../../../../services/Contracts/Utility/Offices'
import { checkModifyOfficeImpactUtility } from '../../../../../services/Contracts/Utility/ScheduleUtility'
import { ConfirmSchedule } from '../UpdateSchedule/components/ConfirmSchedule/ConfirmSchedule'

interface IGridHeader {
  title: string
  width: string
}

interface IDoctorOfficesProps {
  goToTab: (index: number) => void
}

function DoctorOffices({ goToTab }: IDoctorOfficesProps): JSX.Element {
  const { handleAlert } = useContext(AccountContext) as AuthContextType
  const { handleCloseEditOffice, handleOpenEditOffice, openEditOffice } =
    useEditOffice()

  const { openCreateOffice } = useCreateOfficeStore()

  const { setOfficeSelected, officeSelected } = useSelectOffice()
  const [indexToEdit, setIndexToEdit] = useState<number>(-1)
  const onOpenEditOfficeModal = (index: number): void => {
    setIndexToEdit(index)
    handleOpenEditOffice()
  }

  const selectToGoSchedule = (index: number): void => {
    const newOffices = officeSelected.map((office, i) => {
      if (i === index) {
        return { ...office, selected: true }
      }
      return { ...office, selected: false }
    })

    setOfficeSelected(newOffices)

    goToTab(2)
  }

  const [confirm, setConfirm] = useState<boolean>(false)

  const [showConfirmation, setShowConfirmation] = useState<boolean>(false)
  const [showConfirmationStatus, setShowConfirmationStatus] =
    useState<boolean>(false)
  const [hasImpact, setHasImpact] = useState<boolean>(false)
  const { doctorInformation } = useDoctorInformationStore()
  const navigate = useNavigate()

  const fetchUpdateOffice = async (
    params: IUpdateInfoOfficeParams,
  ): Promise<void> => {
    setConfirm(true)
    setShowConfirmation(false)
    const { data, status } = await UpdateOfficeUtility(params)

    handleAlert(true, data, status ? 'success' : 'error')
    handleCloseEditOffice()
    if (status) {
      const newInfo: IMedicalOffice[] = structuredClone(officeSelected)
      const {
        building_floor,
        building_name,
        consultation_value,
        coordinates_medical_office,
        name_medical_office,
        office_number,
      } = params

      newInfo[indexToEdit] = {
        ...newInfo[indexToEdit],
        building_floor,
        building_name,
        consultation_value: consultation_value.toString(),
        coordinates_medical_office: {
          latitude: coordinates_medical_office.latitude.toString(),
          longitude: coordinates_medical_office.longitude.toString(),
        },
        name_medical_office,
        office_number,
      }
      fetchOffices(indexToEdit).then()
      if (hasImpact) {
        setHasImpact(false)
        navigate('/dashboard/appointments')
      }
    }
    setConfirm(false)
  }

  const fetchOffices = async (index: number): Promise<void> => {
    const valores: IOfficeService = await OfficesService(
      doctorInformation?.user_id as string,
    )
    if ((valores.data as IApiResponseOffices)?.body) {
      // order list, in last position data with office_status = 'INACTIVE'
      const list = (valores.data as IApiResponseOffices)?.body.medical_offices
      const inactive = list.filter(
        (item: IMedicalOffice) => item.office_status === 'INACTIVE',
      )
      const active = list.filter(
        (item: IMedicalOffice) => item.office_status !== 'INACTIVE',
      )

      const newInfo: IMedicalOffice[] = structuredClone(active)
      newInfo[index] = {
        ...newInfo[index],
        selected: true,
      }
      setOfficeSelected([...newInfo, ...inactive])
    }
  }

  const fetchChangeStatus = async (
    currentStatus: string,
    index: number,
    impact = true,
  ): Promise<void> => {
    setIndexToEdit(index)
    const { status, data } = await UpdateOfficeStatusUtility({
      office_id: officeSelected[index]?.office_id,
      user_id: officeSelected[index]?.user_id,
      action:
        currentStatus === 'ACTIVE' || currentStatus === ''
          ? 'INACTIVE'
          : 'ACTIVE',
    })

    if (status) {
      const newInfo: IMedicalOffice[] = structuredClone(officeSelected)
      newInfo[index] = {
        ...newInfo[index],
        office_status:
          currentStatus === 'ACTIVE' || currentStatus === ''
            ? 'INACTIVE'
            : 'ACTIVE',
      }

      const inactive = newInfo.filter(
        (item: IMedicalOffice) => item.office_status === 'INACTIVE',
      )
      const active = newInfo.filter(
        (item: IMedicalOffice) => item.office_status !== 'INACTIVE',
      )
      const offices = [...active, ...inactive]

      offices[0].selected = true
      setOfficeSelected(offices)
      let redirect = hasImpact
      redirect = redirect && impact
      if (redirect) {
        setHasImpact(false)
        navigate('/dashboard/appointments')
      } else {
        setHasImpact(false)
      }
      const message =
        currentStatus !== 'INACTIVE'
          ? 'El consultorio fue deshabilitado con éxito'
          : 'El consultorio fue habilitado con éxito'
      handleAlert(true, message, 'success')
    } else {
      handleAlert(true, data.message, 'error')
    }
  }

  const checkModifyOfficeImpact = async (
    params: ParamsCheckModifyOfficeImpact,
  ): Promise<void> => {
    const { data } = await checkModifyOfficeImpactUtility(params)

    if (data.data.schedule_impact) {
      setHasImpact(true)
      setShowConfirmation(true)
    } else {
      setConfirm(true)
    }
  }

  const checkDisableOfficeImpact = async (
    params: ParamsCheckModifyOfficeImpact,
    index: number,
  ): Promise<void> => {
    setIndexToEdit(index)
    const { data } = await checkModifyOfficeImpactUtility(params)

    if (data.data.schedule_impact) {
      setHasImpact(true)
      setShowConfirmationStatus(true)
    } else {
      fetchChangeStatus(
        officeSelected[index]?.office_status as string,
        index,
        false,
      ).then()
    }
  }

  const gridHeader: IGridHeader[] = [
    { title: 'Edificio', width: '15%' },
    { title: 'Dirección', width: '20%' },
    { title: 'Piso', width: '10%' },
    { title: 'Consultorio', width: '10%' },
    { title: 'Valor', width: '10%' },
    { title: '', width: '25%' },
  ]

  const gridBody = (
    item: IMedicalOffice,
  ): {
    value: string | ReactNode
    width: string
  }[] => [
      { value: item.building_name, width: '15%' },
      { value: item.name_medical_office, width: '20%' },
      { value: item.building_floor, width: '10%' },
      { value: item.office_number, width: '10%' },
      { value: '$' + item.consultation_value, width: '10%' },
    ]

  return (
    <GridWrapper px={'4rem'} pt={2}>
      <GridWrapper>
        {officeSelected?.length > 0 ? (
          <GridWrapper>
            <ListWrapper>
              <ListItemTitle data-testid="offices">
                {gridHeader.map((item: IGridHeader, index: number) => (
                  <GridWrapper
                    item
                    textAlign={'center'}
                    width={item.width}
                    key={`GridHeader-${index}`}
                    sx={{
                      color: 'common.black',
                      fontSize: '14px',
                      '@media (min-width: 1600px)': {
                        fontSize: '16px',
                      },
                    }}
                  >
                    {item.title}
                  </GridWrapper>
                ))}
              </ListItemTitle>
              {officeSelected.map((item: IMedicalOffice, index: number) => (
                <ListItemInfo
                  key={item?.office_id}
                  data-testid="appointment-item-list"
                >
                  {gridBody(item).map((i, idx) => (
                    <GridWrapper
                      key={`GridHeader-${idx}`}
                      item
                      textAlign={'center'}
                      width={i.width}
                    >
                      <TypographyWrapper
                        sx={{
                          fontSize: '14px',
                          '@media (min-width: 1600px)': {
                            fontSize: '16px',
                          },
                        }}
                      >
                        {i.value}
                      </TypographyWrapper>
                    </GridWrapper>
                  ))}
                  <GridWrapper
                    sx={{ display: 'flex', marginLeft: 'auto', height: '55px' }}
                  >
                    <LigthButton
                      disabled={item.office_status === 'INACTIVE' || item.type_office === 'DOCTOR_CM'}
                      onClick={() => onOpenEditOfficeModal(index)}
                      data-testid={'modify-office-button'}
                    >
                      <EditIcon
                        sx={{
                          fontSize: '16px',
                        }}
                      />
                      Editar
                    </LigthButton>
                    <LigthButton
                      disabled={item.office_status === 'INACTIVE' || item.type_office === 'DOCTOR_CM'}
                      onClick={() => {
                        selectToGoSchedule(index)
                      }}
                      data-testid={'modify-office-button'}
                    >
                      Horarios
                    </LigthButton>
                    <LigthButton
                      disabled={
                        officeSelected.filter(
                          (office) =>
                            office.office_status === 'ACTIVE' ||
                            office.office_status === '',
                        ).length === 1 && item.office_status === 'ACTIVE' || item.type_office === 'DOCTOR_CM'
                      }
                      onClick={() => {
                        if (
                          item.office_status === 'ACTIVE' ||
                          item.office_status === ''
                        ) {
                          checkDisableOfficeImpact(
                            {
                              office_id: item.office_id,
                              user_id: item.user_id,
                            },
                            index,
                          ).then()
                        } else {
                          fetchChangeStatus(
                            item?.office_status as string,
                            index,
                          ).then()
                        }
                      }}
                      sx={{
                        backgroundColor: 'common.white',
                        color: 'primary.main',
                        width: '110px',
                      }}
                    >
                      {item.office_status === 'ACTIVE' ||
                        item.office_status === ''
                        ? 'Inhabilitar'
                        : 'Habilitar'}
                    </LigthButton>
                  </GridWrapper>
                </ListItemInfo>
              ))}
            </ListWrapper>
          </GridWrapper>
        ) : (
          <h1>qwerty</h1>
        )}
      </GridWrapper>
      {openCreateOffice && <NewOfficeModal />}
      {openEditOffice && (
        <EditOffice
          handleClose={handleCloseEditOffice}
          open={openEditOffice}
          fetchUpdateOffice={fetchUpdateOffice}
          office={officeSelected[indexToEdit]}
          handleModalImpactClose={() => {
            setHasImpact(false)
            setShowConfirmation(false)
          }}
          openModalImpact={showConfirmation}
          checkModifyOfficeImpact={checkModifyOfficeImpact}
          confirm={confirm}
        />
      )}
      <ConfirmSchedule
        handleClose={() => {
          setHasImpact(false)
          setShowConfirmationStatus(false)
        }}
        open={showConfirmationStatus}
        handleConfirm={() => {
          fetchChangeStatus(
            officeSelected[indexToEdit]?.office_status as string,
            indexToEdit,
          ).then()
        }}
        confirm={confirm}
        title={'¿Estás seguro que deseas inhabilitar este consultorio?'}
        subtitle={
          'Desde este momento dejarás de recibir citas en este consultorio. ' +
          'Recuerda reagendar o completar las citas ya agendadas en esta ubicación.'
        }
      />
    </GridWrapper>
  )
}

export default DoctorOffices
