import CircleIcon from '@mui/icons-material/Circle'
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked'
import dayjs from 'dayjs'
import { useState, useEffect } from 'react'

import React from 'react'

import ResponsiveTimePickers from './TimePickerClock'
import { TypographyLabelDay } from './TimeSelectorStyle'
import {
  CheckboxWrapper,
  FormControlLabelWrapper,
  FormGroupWrapper,
  GridWrapper,
} from '../../../../components'

import { IDaysMedicalCare } from '../../../../contexts/RegisterContext'

interface IPropsTimeSelector {
  dataConsultory: IDaysMedicalCare[]
  setDataConsultory: React.Dispatch<React.SetStateAction<IDaysMedicalCare[]>>
}

const TimeSelector = (props: IPropsTimeSelector): JSX.Element => {
  const { dataConsultory, setDataConsultory } = props

  const handleChangeBox = (index: number): void => {
    const newIsDataConsultory = [...dataConsultory]
    const dataDay: IDaysMedicalCare = newIsDataConsultory[index]

    newIsDataConsultory[index] = {
      active: !dataDay.active,
      day: dataDay.day,
      from: dataDay.from,
      to: dataDay.to,
    }
    setDataConsultory(newIsDataConsultory)
  }

  const [hoursFrom, setHoursFrom] = useState<string[]>([])

  useEffect(() => {
    if (hoursFrom.length === 0) {
      const options: string[] = []
      for (let i = 0; i < 24; i++) {
        for (let j = 0; j < 2; j++) {
          options.push(
            dayjs()
              .hour(i)
              .minute(j * 30)
              .format('HH[h]mm'),
          )
        }
      }
      setHoursFrom(options)
    }
  }, [hoursFrom.length])

  return (
    <FormGroupWrapper data-testid="time-selector-form-group">
      <GridWrapper container display={'flex'} direction={'row'} spacing={2}>
        {dataConsultory?.map((item: IDaysMedicalCare, index: number) => {
          return (
            <GridWrapper item xs={12} md={6} key={index}>
              <GridWrapper
                container
                display={'flex'}
                justifyContent={'center'}
                alignItems={'center'}
              >
                <GridWrapper item xs={4}>
                  <FormControlLabelWrapper
                    value="enabled"
                    control={
                      <CheckboxWrapper
                        icon={<RadioButtonUncheckedIcon />}
                        checkedIcon={<CircleIcon />}
                        checked={dataConsultory[index]?.active}
                        onChange={() => handleChangeBox(index)}
                        data-testid={`${item.day}`}
                      />
                    }
                    label={<TypographyLabelDay>{item.day}</TypographyLabelDay>}
                  />
                </GridWrapper>
                <GridWrapper
                  item
                  xs={4}
                  display={'flex'}
                  justifyContent={'center'}
                >
                  <ResponsiveTimePickers
                    dataConsultory={dataConsultory}
                    setDataConsultory={setDataConsultory}
                    hour={dataConsultory[index]?.from}
                    index={index}
                    type
                    checked={dataConsultory[index]?.active}
                    minTime={'2022-04-17T00:00'}
                    day={item.day}
                    hours={hoursFrom}
                  />
                </GridWrapper>

                <GridWrapper
                  item
                  xs={4}
                  display={'flex'}
                  justifyContent={'center'}
                >
                  <ResponsiveTimePickers
                    dataConsultory={dataConsultory}
                    setDataConsultory={setDataConsultory}
                    hour={dataConsultory[index]?.to}
                    index={index}
                    type={false}
                    checked={dataConsultory[index]?.active}
                    minTime={dataConsultory[index]?.from}
                    day={item.day}
                    hours={hoursFrom}
                  />
                </GridWrapper>
              </GridWrapper>
            </GridWrapper>
          )
        })}
      </GridWrapper>
    </FormGroupWrapper>
  )
}

export default TimeSelector
