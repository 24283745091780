import {
  GetDoctoInfo,
  GetDoctorAndAppointmentInfo,
  UpdateDoctorInfo,
} from '../../../domain/useCase/GetDoctorInfo'
import {
  IDoctorBody,
  IDoctorUpdateResponse,
  IDoctorPrescriptionProps,
  IRoot,
  IDoctorPrescriptionResponse,
} from '../../../infrastructure/dtos/DoctorInfo'
import DoctorInfoMapper from '../../../infrastructure/http/mappers/DoctorInfoMapper/DoctorInfoMapper'
import { DoctorInfoRepositoryImpl } from '../../../infrastructure/RepositoryImpl/DoctorInfoRepositoryImpl'

export interface IDoctorInfoService {
  status: number
  data: IRoot | string
}

export interface IDoctorAndAppointmentInfoService {
  status: number
  data: IDoctorPrescriptionResponse
}

export interface IUpdateDoctorInfoService {
  status: number
  data: IDoctorUpdateResponse | string
}
export async function DoctorInfoService(
  idDoctor: string,
): Promise<IDoctorInfoService> {
  try {
    const UseCaseDoctor = new GetDoctoInfo(
      new DoctorInfoRepositoryImpl(new DoctorInfoMapper()),
    )

    return {
      status: 1,
      data: await UseCaseDoctor.invoke(idDoctor),
    }
  } catch (error: unknown) {
    return {
      status: 0,
      data: error as string,
    }
  }
}

export async function doctorAndAppointmentInfoService(
  params: IDoctorPrescriptionProps,
): Promise<IDoctorAndAppointmentInfoService> {
  try {
    const UseCaseDoctor = new GetDoctorAndAppointmentInfo(
      new DoctorInfoRepositoryImpl(new DoctorInfoMapper()),
    )

    return {
      status: 1,
      data: await UseCaseDoctor.invoke(params),
    }
  } catch (error: unknown) {
    return {
      status: 0,
      data: error as IDoctorPrescriptionResponse,
    }
  }
}

export async function updateDoctorInfoService(
  params: IDoctorBody,
  idDoctor: string,
): Promise<IUpdateDoctorInfoService> {
  try {
    const UseCaseDoctor = new UpdateDoctorInfo(
      new DoctorInfoRepositoryImpl(new DoctorInfoMapper()),
    )

    return {
      status: 1,
      data: await UseCaseDoctor.invoke(params, idDoctor),
    }
  } catch (error: unknown) {
    return {
      status: 0,
      data: error as string,
    }
  }
}
