import { useTheme } from '@mui/material'
import React from 'react'

import { Duration, useAppointmentTypesList } from './hooks/useAppointmentTypesList'
import {
  AutocompleteStyled,
  FormControlStyled,
  TextFieldAutocomplete,
} from '../../../../components/Autocomplete/styles'
import TrayIcon from '../../../../components/icons/TrayIcon'
import { TextError } from '../../../../components/ModalDate/ModalDateStyles'
import { SecondaryMediumButton } from '../../../../components/SecondaryMediumButton/SecondaryMediumButton'
import { InputField } from '../../../../components/StyledComponents/InputField'
import ButtonWrapper from '../../../../components/wrapper/ButtonWrapper'
import GridWrapper from '../../../../components/wrapper/GridWrapper'

interface AppointmentType {
  id: number
  name: string
  erasable: boolean
  duration?: string
  duration_value?: number
}

export interface IAppointmentTypesListProps {
  typesList: AppointmentType[]
  setTypesList: React.Dispatch<React.SetStateAction<AppointmentType[]>>
  durationsDB: Duration[]
  typesDB: string[],
  disableByCM?: boolean
}

export const AppointmentTypesList = ({
  setTypesList,
  typesList,
  typesDB = [],
  durationsDB,
  disableByCM,
}: IAppointmentTypesListProps): JSX.Element => {

  const {
    handleModifyDurationInput,
    setType,
    setTypes,
    types,
    type,
    defaultTypes,
    typeDuration,
    setTypeDuration,
    handleModifyDurationItem,
    isDisabled,
  } = useAppointmentTypesList({ typesList, setTypesList, typesDB, durationsDB })

  // get theme

  const { palette } = useTheme()

  return (
    <div>
      <GridWrapper width={'100%'} display={'flex'}>
        <FormControlStyled
          sx={{
            marginBottom: '30px',
          }}
        >
          <AutocompleteStyled
            freeSolo
            id="appointment-types-list"
            data-testid={'appointment-types-list'}
            options={types}
            value={typesList?.length > 0 ? type : 'Cita de primera Vez'}
            aria-placeholder={'appointment-types'}
            disabled={disableByCM || typesList?.length === 0}
            renderInput={(params) => (
              <TextFieldAutocomplete
                {...params}
                variant="outlined"
                fullWidth
                data-testid={'types'}
                placeholder={'Tipo de cita'}
                disabled={typesList?.length === 0}
                sx={{
                  '& .MuiInputBase-input.MuiOutlinedInput-input.Mui-disabled': {
                    '-webkit-text-fill-color': palette.text.primary
                  },
                }}
              />
            )}
            onChange={(
              event: React.SyntheticEvent<Element, Event>,
              value: unknown,
            ) => {
              if (typeof value === 'string') {
                setType(value)
              }
            }}
            onInputChange={
              (event, newInputValue) => {
                if (newInputValue.length > 0) {
                  setTypes([...defaultTypes, newInputValue])
                } else {
                  setTypes(defaultTypes)
                }
                setType(newInputValue)
              }
            }
            sx={{
              marginLeft: '0'
            }}
          />
        </FormControlStyled>
        <FormControlStyled
          sx={{
            marginBottom: '30px',
          }}
        >
          <AutocompleteStyled
            id="duration-list"
            options={durationsDB.map((duration) => duration.name)}
            value={typeDuration}
            onChange={(
              event: React.SyntheticEvent<Element, Event>,
              value: unknown,
            ) => {
              handleModifyDurationInput(value)
            }}
            onInputChange={(
              event: React.SyntheticEvent<Element, Event>,
              value: string,
            ) => {
              handleModifyDurationInput(value)
            }}
            disabled={disableByCM || false}
            renderInput={(params) => {
              return (
                <TextFieldAutocomplete
                  {...params}
                  variant="outlined"
                  fullWidth
                  data-testid={'duration'}
                  placeholder={'Duración'}
                />
              )
            }}
          />
          {
            typesList?.length === 0 && !typeDuration && (
              <TextError sx={{
                marginLeft: '18px',
              }}>
                Campo obligatorio
              </TextError>
            )
          }
        </FormControlStyled>
        <SecondaryMediumButton
          text={typesList?.length > 0 ? 'Agregar otro' : 'Agregar'}
          disabled={isDisabled}
          sx={{
            width: '400px',
            '&:disabled': {
              backgroundColor: 'tertiary.light',
            },
          }}
          onClick={() => {
            setTypesList([
              ...typesList,
              {
                id: typesList.length + 1 + Math.random(),
                name: type,
                erasable: typesList.length > 0,
                duration: typeDuration,
              },
            ])
            setType('')
            setTypeDuration('')
          }}
        />
      </GridWrapper>
      {typesList?.map((type, index: number) => (
        <GridWrapper key={type.id} display={'flex'} marginBottom={'30px'} width={'100%'}>
          <FormControlStyled
            sx={{
              width: '300px',
              marginRight: '10px',
            }}
          >
            <InputField
              value={type.name}
              inputProps={{
                readOnly: !type.erasable,
              }}
              data-testid={`appointment-type-${index}`}
              onChange={(event) => {
                setTypesList(
                  typesList.map((t) =>
                    t.id === type.id ? { ...t, name: event.target.value } : t,
                  ),
                )
              }}
              sx={{
                width: '100%',
              }}
              disabled={disableByCM}
            />
            {
              !type.name && (
                <TextError sx={{
                  marginLeft: '15px',
                }}>
                  Campo obligatorio
                </TextError>
              )
            }
          </FormControlStyled>
          <FormControlStyled
            sx={{
              width: '200px',
            }}
          >
            <AutocompleteStyled
              id="duration-item"
              options={durationsDB.map((duration) => duration.name)}
              value={durationsDB.find((d) => d.name === type.duration) ? type.duration : ''}
              onChange={(
                event: React.SyntheticEvent<Element, Event>,
                value: unknown,
              ) => {
                handleModifyDurationItem(type, value)
              }}
              onInputChange={(
                event: React.SyntheticEvent<Element, Event>,
                value: string,
              ) => {
                handleModifyDurationItem(type, value)
              }}
              sx={{
                width: '200px',
              }}
              disabled={disableByCM || false}
              renderInput={(params) => {
                return (
                  <TextFieldAutocomplete
                    {...params}
                    variant="outlined"
                    fullWidth
                    data-testid={'duration-item'}
                    placeholder={'Duración de cita'}
                  />
                )
              }}
            />
            {
              !type.duration && (
                <TextError sx={{
                  marginLeft: '18px',
                }}>
                  Campo obligatorio
                </TextError>
              )
            }
          </FormControlStyled>
          {type.erasable && (
            <ButtonWrapper
              name={`Eliminar`}
              onClick={() => {
                // remove item from list with index of map
                typesList.splice(index, 1)
                setTypesList([...typesList])
              }}
              sx={{
                '&:hover': {
                  backgroundColor: 'transparent',
                },
              }}
              disabled={disableByCM}
            >
              <TrayIcon sx={{
                marginLeft: '10px',
                fontSize: '36px',
                fontWeight: '100',
                color: palette.text.primary,
                '&:hover': {
                  color: palette.primary.dark,
                }
              }} />
            </ButtonWrapper>
          )}
        </GridWrapper>
      ))}
    </div>
  )
}
