import { styled } from '@mui/material'

import { BoxWrapper, FadeWrapper, GridWrapper, IconButtonWrapper } from '../../../../../../../components'

export const CloseButton = styled( IconButtonWrapper )(( props ) => ({
  position: 'absolute',
  right: 20,
  top: 20,
  color: props.theme.palette.primary.dark,
  marginBottom: '20px'
}))

export const BackButton = styled( IconButtonWrapper )(( props ) => ({
  position: 'absolute',
  left: 12,
  top: 25,
  fontSize: '14px',
  color: props.theme.palette.primary.dark,
  textDecoration: 'underline',
  borderRadius: '20px',
  padding: '5px 15px'
}))

export const FadeModal = styled( FadeWrapper )(() => ({
  height: 'auto',
  overflowY: 'auto'
}))

export const BoxModal = styled( BoxWrapper )(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  minWidth: '400px',
  height: '400px',
  bgcolor: theme.palette.background.paper,
  background: 'white',
  borderRadius: '30px',
  p: 4,
  boxShadow: '2px 2px 18px 14px rgba(0, 0, 0, 0.03)'
}))

export const GridModal = styled( GridWrapper )(() => ({
  padding: '40px'
}))
