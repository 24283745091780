import { IApiResponseVisibilityIntervalsAWS } from '../../infrastructure/dtos/VisibilityIntervals';
import { VisibilityIntervalsRepository } from '../repository/VisibilityIntervalsRepository';

export interface GetVisibilityIntervalsUseCase {
  invoke: () => Promise<IApiResponseVisibilityIntervalsAWS>

}

export class GetVisibilityIntervals implements GetVisibilityIntervalsUseCase {
  private objectUseCase: VisibilityIntervalsRepository

  constructor( objectUseCase: VisibilityIntervalsRepository ) {
    this.objectUseCase = objectUseCase;
  }

  async invoke(): Promise<IApiResponseVisibilityIntervalsAWS> {
    return this.objectUseCase.getVisibilityIntervals()
  }
}