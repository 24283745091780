import { useJsApiLoader } from '@react-google-maps/api'

import React, { useEffect, useState } from 'react'

import { IMedicalOffice } from '../../../../../../../infrastructure/dtos/Offices'
import { ICoordinates } from '../../../../../../medical/ProfileMedical/RegisterConsultory/hooks/useRegisterConsultory'

declare type ReactStateType<T> = React.Dispatch<React.SetStateAction<T>>

export interface IUseEditOfficeHook {
  handleOpenEditOffice: () => void
  handleCloseEditOffice: () => void
  handleClickAutocomplete: () => void
  setMarkerPosition: ReactStateType<google.maps.LatLng | null>
  setZoom: ReactStateType<number>
  setAddress: ReactStateType<string>
  setMapCenter: ReactStateType<ICoordinates>
  setSelected: ReactStateType<boolean>
  setValue: ReactStateType<IPlaceType | null>
  setConsultingRoom: ReactStateType<string>
  setConsultingFloor: ReactStateType<string>
  setConsultingOffice: ReactStateType<string>
  handleSetAppointmentPrice: (value: string) => void
  setMap: ReactStateType<google.maps.Map | null>
  openEditOffice: boolean
  disabledButton: boolean
  address: string
  isLoaded: boolean
  selected: boolean
  value: IPlaceType | null
  map: google.maps.Map | null
  markerPosition: google.maps.LatLng | null
  zoom: number
  mapCenter: ICoordinates
  consultingRoom: string
  consultingFloor: string
  consultingOffice: string
  appointmentPrice: string
  MAX_LENGTH: number
}

type Libraries = (
  | 'drawing'
  | 'geometry'
  | 'localContext'
  | 'places'
  | 'visualization'
)[]

const libraries = ['places'] satisfies Libraries

interface IMainTextMatchedSubstrings {
  offset: number
  length: number
}
interface IStructuredFormatting {
  main_text: string
  secondary_text: string
  main_text_matched_substrings?: readonly IMainTextMatchedSubstrings[]
}

interface IPlaceType {
  description: string
  structured_formatting: IStructuredFormatting
}

const center: ICoordinates = {
  lat: -0.0102496,
  lng: -78.4464668,
}

export function useEditOffice(office?: IMedicalOffice): IUseEditOfficeHook {
  const [openEditOffice, setOpenEditOffice] = useState<boolean>(false)
  const [disabledButton, setDisabledButton] = useState<boolean>(false)
  const [MAX_LENGTH] = useState<number>(30)
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY as string,
    libraries,
  })

  const [map, setMap] = useState<google.maps.Map | null>(null)
  const [markerPosition, setMarkerPosition] =
    useState<google.maps.LatLng | null>(null)
  const [zoom, setZoom] = useState<number>(8)
  const [selected, setSelected] = useState<boolean>(false)
  const [address, setAddress] = useState<string>('')
  const [value, setValue] = useState<IPlaceType | null>(null)
  const [mapCenter, setMapCenter] = useState<ICoordinates>(center)

  const [consultingRoom, setConsultingRoom] = useState<string>('')
  const [consultingFloor, setConsultingFloor] = useState<string>('')
  const [consultingOffice, setConsultingOffice] = useState<string>('')
  const [appointmentPrice, setAppointmentPrice] = useState<string>('')

  const handleOpenEditOffice = (): void => setOpenEditOffice(true)
  const handleCloseEditOffice = (): void => setOpenEditOffice(false)

  const handleClickAutocomplete = (): void => {
    setAddress('')
  }
  const handleSetAppointmentPrice = (value: string): void => {
    setAppointmentPrice(
      value.length > MAX_LENGTH + 1 ? appointmentPrice : value,
    )
  }

  useEffect(() => {
    if (office) {
      setConsultingRoom(office?.building_name)
      setConsultingFloor(office?.building_floor)
      setConsultingOffice(office?.office_number)
      setAppointmentPrice(office?.consultation_value)
      setAddress(office?.name_medical_office)
      setValue({
        description: office?.name_medical_office,
        structured_formatting: {
          main_text: '',
          secondary_text: '',
        },
      })
      setMarkerPosition(
        new google.maps.LatLng(
          Number(office?.coordinates_medical_office.latitude),
          Number(office?.coordinates_medical_office.longitude),
        ),
      )
      setSelected(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (selected && !!value) {
      setMapCenter({
        lat: markerPosition?.lat() as number,
        lng: markerPosition?.lng() as number,
      })
      setZoom(12)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected])

  useEffect(() => {
    setDisabledButton(
      !(
        Boolean(consultingRoom) &&
        Boolean(consultingFloor) &&
        Boolean(consultingOffice) &&
        Boolean(value?.description) &&
        Boolean(appointmentPrice) &&
        consultingRoom.length <= MAX_LENGTH &&
        consultingFloor.length <= MAX_LENGTH &&
        consultingOffice.length <= MAX_LENGTH &&
        appointmentPrice.length <= MAX_LENGTH &&
        (office?.building_floor !== consultingFloor ||
          office?.building_name !== consultingRoom ||
          office?.office_number !== consultingOffice ||
          office?.consultation_value !== appointmentPrice ||
          office?.name_medical_office !== value?.description)
      ),
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    consultingRoom,
    consultingFloor,
    consultingOffice,
    appointmentPrice,
    value,
  ])

  return {
    handleOpenEditOffice,
    handleCloseEditOffice,
    handleClickAutocomplete,
    setMarkerPosition,
    setZoom,
    setAddress,
    setMapCenter,
    setSelected,
    setValue,
    setMap,
    setConsultingRoom,
    setConsultingFloor,
    setConsultingOffice,
    handleSetAppointmentPrice,
    openEditOffice,
    disabledButton,
    address,
    isLoaded,
    selected,
    value,
    map,
    markerPosition,
    zoom,
    mapCenter,
    consultingRoom,
    consultingFloor,
    consultingOffice,
    appointmentPrice,
    MAX_LENGTH,
  }
}
