import { Typography, TypographyProps } from '@mui/material'
import React from 'react'

const TypographyWrapper = React.forwardRef<HTMLDivElement, TypographyProps>(
  ({ children, ...props }, ref) => (
    <Typography {...props} ref={ref}>
      {children}
    </Typography>
  ),
)

export default TypographyWrapper
