import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import CloseIcon from '@mui/icons-material/Close'
import Backdrop from '@mui/material/Backdrop'
import React from 'react'

import { useCreateSchedule } from './useCreateSchedule'
import { ModalWrapper } from '../../../../../../../components'

import FormTextInput from '../../../../../../../components/FormInputText'
import { List } from '../../../../../../../components/List/List'
import { TypographyModal } from '../../../../../../../components/ModalComponent'
import SelectButtonList from '../../../../../../../components/SelectButtonList/SelectButtonList'
import { LabelInput } from '../../../../../../../components/StyledComponents/LabelInput'
import SubmitButton from '../../../../../../../components/SubmitButton'
import GridWrapper from '../../../../../../../components/wrapper/GridWrapper'
import { useCreateOfficeStore } from '../../../../../../../contexts/NewOfficeState'
import { AppointmentTypesList } from '../../../../../../medical/ProfileMedical/Components/AppointmentTypesList'
import TimeSelector from '../../../../../../medical/ProfileMedical/Components/TimeSelector'
import text from '../../../../../../medical/ProfileMedical/ProfileMedicalScheduleSetting/ProfileMedicalScheduleSettings.json'
import {
  GridForm,
} from '../../../../../../medical/ProfileMedical/ProfileMedicalScheduleSetting/ProfileMedicalScheduleSettingStyle'
import {
  BackButton,
  BoxModal,
  CloseButton,
  GridModal,
} from '../EditOffice/EditOfficeStyles'

interface ICreateScheduleProps {
  setStep: React.Dispatch<React.SetStateAction<number>>
  open: boolean
  handleClose: () => void
}

export const CreateSchedule = ({
  setStep,
  open,
  handleClose,
}: ICreateScheduleProps): JSX.Element => {
  const {
    validatePhoneNumber,
    validatePhoneNumberText,
    phoneNumber,
    onChange,
    amountRanges,
    isExtraSmallSize,
    selectedExperience,
    dataConsultory,
    setDataConsultory,
    disabledButton,
    office,
    fetchSaveNewOffice,
    typesDB,
    durationsDB,
    typesList,
    setTypesList
  } = useCreateSchedule({ handleClose })
  const {  clearAll } = useCreateOfficeStore()

  return (
    <ModalWrapper
      aria-labelledby="modal-edit-office-title"
      aria-describedby="modal-edit-office-description"
      open={open}
      onClose={() => {
        clearAll()
        handleClose()
      }}
      role="modal"
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
      sx={{
        overflowY: 'auto',
      }}
    >
      <BoxModal
        width={{ xs: '90%', md: '60%' }}
        sx={{
          width: '60%',
          paddingTop: '10px',
          borderRadius: '30px',
          height: 'auto',
          top: '0',
          left: '20%',
          transform: 'none',
          marginTop: '50px',
          paddingBottom: '0px',
          background: 'none',
          boxShadow: 'none',
        }}
      >
        <GridModal
          sx={{
            background: 'white',
            marginBottom: '30px',
            padding: '40px',
            borderRadius: '30px',
            boxShadow: '2px 2px 18px 14px rgba(0, 0, 0, 0.03)',
          }}
        >
          <TypographyModal id="modal-edit-office-title" variant="h6">
            Configurar horario
          </TypographyModal>
          <BackButton
          data-testid="back-button"
          onClick={() => setStep(0)}> <ChevronLeftIcon />{ 'Regresar'}</BackButton>
          <CloseButton
            data-testid="close-create-schedule"
            onClick={() => {
              clearAll()
              handleClose()
            }}
          >
            <CloseIcon />
          </CloseButton>
          <GridForm
            item
            xs={12}
            sx={{
              width: '100%',
            }}
          >
            <form
              onSubmit={fetchSaveNewOffice}
              data-testid="profile-form3"
              style={{ width: '100%' }}
            >
              <GridWrapper container sx={{ mt: 2 }} spacing={2}>
                <GridWrapper item xs={12} sm={6}>
                  <FormTextInput
                    text={text.office_phone_number}
                    placeholder={text.office_phone_number_placeholder}
                    id="office_phone"
                    name="office_phone"
                    label={'Teléfono'}
                    type="text"
                    fullWidth
                    value={phoneNumber}
                    onChange={(event) =>
                      onChange(event.target.value, 'phoneNumber')
                    }
                    sx={{
                      '& label': {
                        color: 'info.main',
                      }
                    }}
                    sxLabel={{
                      fontWeight: 'bold',
                      marginLeft: '1.5rem',
                      marginBottom: '15px',
                    }}
                    error={validatePhoneNumber}
                    helperText={validatePhoneNumberText}
                  />
                </GridWrapper>
                <GridWrapper item xs={12} sx={{ mt: 2 }}>
                  <LabelInput>{text.show_agenda_text}</LabelInput>
                  {isExtraSmallSize ? (
                    <List
                      id="selectVisualizationAgenda"
                      lists={amountRanges}
                      onClick={(event: string) =>
                        onChange(event, 'selectedExperience')
                      }
                      value={selectedExperience}
                      minWidth="100%"
                    />
                  ) : (
                    <SelectButtonList
                      buttonWidth={4}
                      style={{ width: '100%', justifyContent: 'space-around',}}
                      amountRanges={amountRanges}
                      onAmountRangeChange={(value: string) =>
                        onChange(value, 'selectedExperience')
                      }
                      selectedAmountRange={selectedExperience}
                    />
                  )}
                </GridWrapper>

                <GridWrapper item xs={12} sx={{ mt: 2 }}>
                  <LabelInput>
                    {`${text.schedule_agenda_types} ${office?.reference}?`}
                  </LabelInput>

                  <AppointmentTypesList
                    typesList={typesList}
                    setTypesList={setTypesList}
                    durationsDB={durationsDB}
                    typesDB={typesDB}
                  />
                </GridWrapper>

                <GridWrapper item xs={12} mt={2}>
                  <LabelInput>
                    {`${text.schedule_agenda_text} ${office?.reference}?`}
                  </LabelInput>
                  <TimeSelector
                    dataConsultory={dataConsultory}
                    setDataConsultory={setDataConsultory}
                  />
                </GridWrapper>

                <GridWrapper item xs={12} my={2}>
                  <SubmitButton
                    data-testid="begin-button"
                    id="button"
                    type="submit"
                    variant="contained"
                    fullWidth
                    text={'Finalizar'}
                    disabled={disabledButton}
                  />
                </GridWrapper>
              </GridWrapper>
            </form>
          </GridForm>
        </GridModal>
      </BoxModal>
    </ModalWrapper>
  )
}
