import { IApiResponseAgreement } from '../../infrastructure/dtos/Agreements'
import { IApiResponseInvite } from '../../infrastructure/dtos/AgreementsInvites'
import {
  IApiResponseReply,
  Reply,
} from '../../infrastructure/dtos/ReplyInvites'
import { IAgreementRepository } from '../repository/AgreementRepository'

export interface IAgreementUseCase {
  invoke: (idDoctor: string) => Promise<IApiResponseInvite>
  sendReply: (params: Reply) => Promise<IApiResponseReply>
  getAgreements: (idDoctor: string) => Promise<IApiResponseAgreement>
}

export class Agreements implements IAgreementUseCase {
  private objectUseCase: IAgreementRepository

  constructor(_objectUseCase: IAgreementRepository) {
    this.objectUseCase = _objectUseCase
  }

  async getAgreements(idDoctor: string): Promise<IApiResponseAgreement> {
    return this.objectUseCase.getAgreements(idDoctor)
  }

  async sendReply(params: Reply): Promise<IApiResponseReply> {
    return this.objectUseCase.sendReply(params)
  }

  async invoke(idDoctor: string): Promise<IApiResponseInvite> {
    return this.objectUseCase.getInvites(idDoctor)
  }
}
