import { GetSpecialties } from '../../../domain'
import { PostSpecialties } from '../../../domain/useCase/PostSpecialties'
import { IApiResponseSpecialties } from '../../../infrastructure/dtos'
import SpecialtiesMapper from '../../../infrastructure/http/mappers/SpecialtiesMapper/SpecialtiesMapper'
import { SpecialtiesRepositoryImpl } from '../../../infrastructure/RepositoryImpl/SpecialtiesRepositoryImpl'

export interface ISpecialtiesService {
  status: number
  data: IApiResponseSpecialties | string
}

export async function SpecialtiesService(): Promise<ISpecialtiesService> {
  try {
    const UseCaseRegistro = new GetSpecialties(
      new SpecialtiesRepositoryImpl(new SpecialtiesMapper()),
    )

    return {
      status: 1,
      data: await UseCaseRegistro.invoke(),
    }
  } catch (error: unknown) {
    return {
      status: 0,
      data: (error as Error).message,
    }
  }
}

export async function PostSpecialtiesService(
  specialties: string,
): Promise<ISpecialtiesService> {
  try {
    const UseCaseApi = new PostSpecialties(
      new SpecialtiesRepositoryImpl(new SpecialtiesMapper()),
    )

    return {
      status: 1,
      data: await UseCaseApi.invoke(specialties),
    }
  } catch (error) {
    return {
      status: 0,
      data: (error as Error).message,
    }
  }
}
