import { Grid, GridProps } from '@mui/material'
import React from 'react'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const GridWrapper = React.forwardRef<any, GridProps>(
  ({ children, ...props }, ref) => (
    <Grid {...props} ref={ref} children={children} />
  ),
)

export default GridWrapper
