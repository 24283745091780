import { INewDateRepository } from '../../domain/repository/NewDateRepository'
import {
  IApiResponseSaveNewDate,
  IApiResponseSaveOverbooking,
  ISaveNewDate,
} from '../dtos/NewDate'
import NewDateDataSource from '../http/dataSource/NewDateDataSource'

export class NewDateRepositoryImpl implements INewDateRepository {
  dataSource: NewDateDataSource

  constructor(_datasource: NewDateDataSource) {
    this.dataSource = _datasource
  }

  async postNewDate(
    dataNewDate: ISaveNewDate,
  ): Promise<IApiResponseSaveNewDate> {
    return this.dataSource.postNewDate(dataNewDate)
  }

  postNewOverbookingAppointment(
    appointmentData: ISaveNewDate,
  ): Promise<IApiResponseSaveOverbooking> {
    return this.dataSource.postNewOverbookingAppointment(appointmentData)
  }
}
