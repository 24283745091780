import {
  GetCatalogNotification,
  GetCatalogParameters,
} from '../../../domain/useCase/CatalogUseCase'
import {
  IApiResponseCatalog,
  IResponseNotification,
  IResponseParameters,
  IServiceCatalog,
} from '../../../infrastructure/dtos/Catalog'
import CatalogMapper from '../../../infrastructure/http/mappers/CatalogMapper/CatalogMapper'
import { CatalogRepositoryImpl } from '../../../infrastructure/RepositoryImpl/CatalogRepositoryImpl'

export async function GetCatalogNotificationService(
  item: string,
): Promise<IServiceCatalog<IApiResponseCatalog<IResponseNotification>>> {
  try {
    const UseCaseCatalog: GetCatalogNotification = new GetCatalogNotification(
      new CatalogRepositoryImpl(new CatalogMapper()),
    )

    return {
      status: 1,
      data: await UseCaseCatalog.invoke(item),
    }
  } catch (error: unknown) {
    return {
      status: 0,
      data: 'Error Service',
    }
  }
}

export async function GetCatalogParametersService(
  item: string,
): Promise<IServiceCatalog<IApiResponseCatalog<IResponseParameters>>> {
  try {
    const UseCaseCatalog: GetCatalogParameters = new GetCatalogParameters(
      new CatalogRepositoryImpl(new CatalogMapper()),
    )

    return {
      status: 1,
      data: await UseCaseCatalog.invoke(item),
    }
  } catch (error: unknown) {
    return {
      status: 0,
      data: 'Error Service',
    }
  }
}
