import { useAuth0 } from '@auth0/auth0-react'
import { useContext, useEffect, useState } from 'react'

import { useNavigate } from 'react-router-dom'

import { useDoctorInformationStore } from '../../contexts/DoctorInformationState'
import { RegisterMedicalContext } from '../../contexts/RegisterContext'
import { useSelectOffice } from '../../contexts/SelectOfficeState'
import { IDoctorInformation, IRoot } from '../../infrastructure/dtos/DoctorInfo'
import {
  DoctorInfoService,
  IDoctorInfoService,
} from '../Contracts/Persistencia/DoctorInfoService'

interface IUseSessionHook {
  fetchDoctorInfo: () => Promise<void>
  isAuthenticated: boolean
  isLoadingPage: boolean
  isLoading: boolean
}

export function useSession(): IUseSessionHook {
  const { isAuthenticated, user, isLoading } = useAuth0()

  const [isLoadingPage, setIsLoadingPage] = useState<boolean>(false)
  const [urlPage, setUrlPage] = useState<string>('/')
  const { setDoctorInformation } = useDoctorInformationStore()
  const { officeSelected } = useSelectOffice()
  const { savePhysicianProfile } = useContext(RegisterMedicalContext)

  const navigate = useNavigate()
  const fetchDoctorInfo = async (): Promise<void> => {
    const userAuth0 = user?.sub as string
    const emailAuth0 = user?.email as string
    const userId = userAuth0.split('|')
    const userBody: IDoctorInfoService = await DoctorInfoService(userId[1])
    if (Object.keys((userBody.data as IRoot)?.body)?.length > 0) {
      if (
        (urlPage === '/dashboard/profile' ||
          urlPage.includes('/dashboard/appointments/data/0/')) &&
        officeSelected.length === 0
      ) {
        navigate('/dashboard/index')
      }
      const data_doctor: IDoctorInformation = (userBody.data as IRoot)
        ?.body as IDoctorInformation
      setDoctorInformation({
        doctor_ci: data_doctor?.doctor_ci,
        doctor_phone: data_doctor?.doctor_phone,
        document_type: data_doctor?.document_type,
        description_doctor: data_doctor?.description_doctor,
        email_doctor: data_doctor?.email_doctor,
        code_msp: data_doctor?.code_msp,
        created_at: data_doctor?.created_at,
        gender: data_doctor?.gender,
        last_name_doctor: data_doctor?.last_name_doctor,
        name_doctor: data_doctor?.name_doctor,
        people_attended: data_doctor?.people_attended,
        photo_doctor: data_doctor?.photo_doctor,
        prescription_no: data_doctor?.prescription_no,
        rating: data_doctor?.rating,
        speciality_doctor: data_doctor?.speciality_doctor,
        university_doctor: data_doctor?.university_doctor,
        user_id: data_doctor?.user_id,
        years_experience_doctor: data_doctor?.years_experience_doctor,
        paid: data_doctor?.paid,
        medical_skills: data_doctor?.medical_skills,
        complete_register: data_doctor?.complete_register,
        type_invitation: data_doctor?.type_invitation,
      })
    } else {
      const userEmail: IDoctorInfoService = await DoctorInfoService(emailAuth0)
      if (Object.keys((userEmail.data as IRoot)?.body)?.length > 0) {
        const data_doctor: IDoctorInformation = (userEmail.data as IRoot)
          ?.body as IDoctorInformation
        if (data_doctor.complete_register === false) {
          savePhysicianProfile({
            description: '',
            image: '',
            lastName: data_doctor?.last_name_doctor ?? '',
            name: data_doctor?.name_doctor ?? '',
            phone: data_doctor?.doctor_phone ?? '',
            idNumber: data_doctor?.doctor_ci ?? '',
            idType: 'Cédula o RUC',
            gender: '',
          })
          setDoctorInformation({
            doctor_ci: data_doctor?.doctor_ci,
            doctor_phone: data_doctor?.doctor_phone,
            document_type: data_doctor?.document_type,
            description_doctor: data_doctor?.description_doctor,
            email_doctor: data_doctor?.email_doctor,
            code_msp: data_doctor?.code_msp,
            created_at: data_doctor?.created_at,
            gender: data_doctor?.gender,
            last_name_doctor: data_doctor?.last_name_doctor,
            name_doctor: data_doctor?.name_doctor,
            people_attended: data_doctor?.people_attended,
            photo_doctor: data_doctor?.photo_doctor,
            prescription_no: data_doctor?.prescription_no,
            rating: data_doctor?.rating,
            speciality_doctor: data_doctor?.speciality_doctor,
            university_doctor: data_doctor?.university_doctor,
            user_id: data_doctor?.user_id,
            years_experience_doctor: data_doctor?.years_experience_doctor,
            paid: data_doctor?.paid,
            medical_skills: data_doctor?.medical_skills,
            complete_register: data_doctor?.complete_register,
            type_invitation: data_doctor?.type_invitation,
          })
        }
      }
      navigate('/medico/register/profile')
    }
  }

  useEffect(() => {
    const currentUrl = window.location.pathname
    if (currentUrl.includes('/dashboard')) {
      setUrlPage(currentUrl)
      setIsLoadingPage(true)
    }
    if (!isLoading) {
      if (isAuthenticated) {
        fetchDoctorInfo()
      }
    }
    if (currentUrl.includes('/dashboard')) {
      setIsLoadingPage(false)
      navigate(currentUrl)
    }
  }, [isLoading, isAuthenticated])
  return {
    fetchDoctorInfo,
    isAuthenticated,
    isLoadingPage,
    isLoading,
  }
}
