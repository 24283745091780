import {
  IClinicalPictureResponse,
  ISaveClinicalPictureServiceParams,
} from '../../infrastructure/dtos/ClinicalPicture'
import { IClinicalPictureRepository } from '../repository/ClinicalPictureRepository'

export interface IClinicalPictureUseCase {
  invoke: (
    params: ISaveClinicalPictureServiceParams,
  ) => Promise<IClinicalPictureResponse>
}

export class ClinicalPicture implements IClinicalPictureUseCase {
  private objectUseCase: IClinicalPictureRepository

  constructor(_objectUseCase: IClinicalPictureRepository) {
    this.objectUseCase = _objectUseCase
  }

  async invoke(
    params: ISaveClinicalPictureServiceParams,
  ): Promise<IClinicalPictureResponse> {
    return this.objectUseCase.saveClinicalPicture(params)
  }
}
