import React, { FunctionComponent } from 'react'

import { FormControlSelect } from '../List/ListStyle'
import { LabelInputError } from '../StyledComponents/LabelInputError'
import { MenuItemWrapper, SelectWrapper } from '../wrapper'

interface IComponentProps {
  id: string
  value: string
  onClick: (value: string, key: string) => void
  lists: string[]
  minWidth: string
  error?: boolean
  textError?: string
  disabled?: boolean
  onBlur?: () => void
  width?: string
}

export const ListOfficeSelect: FunctionComponent<IComponentProps> = ({
  lists,
  id,
  value,
  onClick,
  minWidth,
  error,
  textError,
  disabled,
  onBlur,
  width
}) => {
  return (
    <>
      <FormControlSelect
        style={{ minWidth: minWidth, width: width }}
        data-testid={id}
        error={error}
      >
        <SelectWrapper
          name={id}
          labelId={id}
          id={id}
          value={value}
          onChange={(event, child) => {
            const newEvent = (event.target.value as string).substring(1)
            onClick(
              newEvent,
              (
                child as {
                  key: string
                }
              ).key as string,
            )
          }}
          defaultValue=""
          onBlur={onBlur}
          disabled={disabled}
          data-testid={`${id}-select`}
          SelectDisplayProps={{
            role: 'combobox',
          }}
          MenuProps={{
            PaperProps: {
              role: 'tab',
            },
          }}
        >
          {lists?.map((item: string, index: number) => {
            return (
              <MenuItemWrapper key={`specialist${index}`} value={index + item}>
                {item}
              </MenuItemWrapper>
            )
          })}
        </SelectWrapper>
      </FormControlSelect>
      {error && (
        <LabelInputError alignSelf={'flex-start'} id={`${id}-error`}>
          {textError}
        </LabelInputError>
      )}
    </>
  )
}
