import { styled } from '@mui/material'

import { ButtonWrapper, TypographyWrapper } from '../../../../../../components'
import { InputCurrencyStyled } from '../../../../../../components/StyledComponents/InputCurrencyStyled'
import { TextFielRegister } from '../../../../../../utils/RegisterMedicalFormStyles'

interface ITextFielRegisterStyledProps {
  state: string
}

export const TextFielRegisterStyled = styled(
  TextFielRegister,
)<ITextFielRegisterStyledProps>(({ theme, state }) => ({
  height: state === 'false' ? '35px' : 'auto',
  marginBottom: state === 'false' ? '0px' : '10px',
  '& .MuiInputBase-root': {
    backgroundColor:
      state === 'false'
        ? theme.palette.common.white
        : theme.palette.background.default,
    height: state === 'false' ? '25px' : 'auto',
  },
  margin: '10px 0',
}))

export const CustomInputCurrency = styled(InputCurrencyStyled)(({ theme }) => ({
  height: 'auto',
  paddingTop: '0px',
  backgroundColor: theme.palette.background.default,
  marginBottom: '10px',
}))

export const DisabledText = styled(TypographyWrapper)(() => ({
  color: 'rgba(0, 0, 0, 0.12)',
  textAlign: 'left',
  paddingLeft: '15px',
  fontSize: '1.1rem',
  position: 'relative',
  zIndex: 1,
  top: '5px',
  marginBottom: '10px',
}))

export const CustomButtonSelect = styled(ButtonWrapper)(({ theme }) => ({
  borderRadius: '42px',
  fontSize: '13px',
  textTransform: 'none',
  width: '200px',
  height: '30px',
  margin: '10px',
  marginBottom: 2,
  marginTop: 2,
  ':hover': {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.main,
  },
}))
