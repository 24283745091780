import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react'

import {
  AccountContext,
  AuthContextType,
} from '../../../../../../contexts/AccountContext'
import { Severity } from '../../../../../../contexts/AuthReducer'
import {useDoctorInformationStore} from '../../../../../../contexts/DoctorInformationState';
import {
  IDoctorBody,
  IDoctorUpdateBodyResponse,
} from '../../../../../../infrastructure/dtos/DoctorInfo'
import { ISpecialty } from '../../../../../../infrastructure/dtos/Specialties'
import { updateDoctorInfoUtility } from '../../../../../../services/Contracts/Utility/DoctorInfoUtility'
import { validatePhoneNumber } from '../../../../../../utils/validatePhoneNumber'
import { useSpecialties } from '../../../../../medical/ProfileMedical/ProfileMedicalForm1/Hooks/useSpecialties'

export interface IDataDoctor {
  codeMsp: string
  name: string
  lastName: string
  about: string
  phone: string
  doctorCi: string
  academyPlace: string
  experience: string
  photo?: string
}

interface IDoctorInformationHook {
  specialties: ISpecialty[]
  selectedImage: string
  setSelectedImage: Dispatch<SetStateAction<string>>
  specialty: string
  setSpecialty: Dispatch<SetStateAction<string>>
  dataDoctor: IDataDoctor
  setDataDoctor: Dispatch<SetStateAction<IDataDoctor>>
  updateDoctorInfo: () => Promise<void>
  isDisabled: boolean
  error: boolean
  message: string
  handleAlert: (open: boolean, message: string, severity?: Severity | undefined) => void
  skills: string[]
  setSkills: Dispatch<SetStateAction<string[]>>
}

export function useDoctorInformation(): IDoctorInformationHook {
  const {doctorInformation, setDoctorInformation}=useDoctorInformationStore()
  const { handleAlert } = useContext(
    AccountContext,
  ) as AuthContextType
  const idDoctor: string = doctorInformation?.user_id as string
  const { lists: specialties } = useSpecialties(handleAlert)
  const [selectedImage, setSelectedImage] = useState<string>(doctorInformation?.photo_doctor as string)
  const [specialty, setSpecialty] = useState<string>(doctorInformation?.speciality_doctor as string)
  const [dataDoctor, setDataDoctor] = useState<IDataDoctor>({
    codeMsp: doctorInformation?.code_msp || '',
    name: doctorInformation?.name_doctor as string,
    lastName: doctorInformation?.last_name_doctor as string,
    about: doctorInformation?.description_doctor as string,
    academyPlace: doctorInformation?.university_doctor as string,
    phone: doctorInformation?.doctor_phone as string,
    doctorCi: doctorInformation?.doctor_ci as string,
    experience: doctorInformation?.years_experience_doctor as string,
    photo: doctorInformation?.photo_doctor as string,
  })

  const [skills, setSkills] = useState<string[]>(doctorInformation?.medical_skills as string[])

  const [isDisabled, setIsDisabled] = useState(true)

  const [error, setError] = useState<boolean>(false)
  const [message, setMessage] = useState<string>('')

  const areMedicalSkillsEqual = (): boolean => {
    const prevSkills = doctorInformation?.medical_skills || [];
    const currentSkills = skills || [];

    if (prevSkills.length !== currentSkills.length) {
      return false;
    }

    return prevSkills.every((skill: string, index: number) => skill === currentSkills[index]);
  }

  useEffect(() => {
    if (
      dataDoctor?.name?.length !== 0 &&
      dataDoctor?.lastName?.length !== 0 &&
      dataDoctor?.about?.length !== 0 &&
      dataDoctor?.academyPlace?.length !== 0 &&
      dataDoctor?.phone?.length !== 0 &&
      !areMedicalSkillsEqual() ||
      validatePhoneNumber(dataDoctor?.phone) &&
      (dataDoctor?.name !== doctorInformation?.name_doctor ||
        dataDoctor?.lastName !== doctorInformation?.last_name_doctor ||
        dataDoctor?.about !== doctorInformation?.description_doctor ||
        selectedImage !== doctorInformation?.photo_doctor ||
        dataDoctor?.phone !== doctorInformation?.doctor_phone ||
        specialty !== doctorInformation?.speciality_doctor ||
        dataDoctor?.experience !== doctorInformation?.years_experience_doctor ||
        dataDoctor?.academyPlace !== doctorInformation?.university_doctor ||
        (dataDoctor?.codeMsp !== doctorInformation?.code_msp &&
          dataDoctor?.codeMsp?.length > 3))
    ) {
      setIsDisabled(false)
    } else {
      setIsDisabled(true)
    }
  }, [
    selectedImage,
    dataDoctor?.name,
    dataDoctor?.lastName,
    doctorInformation?.name_doctor,
    doctorInformation?.last_name_doctor,
    dataDoctor?.about,
    doctorInformation?.description_doctor,
    doctorInformation?.photo_doctor,
    dataDoctor?.phone,
    doctorInformation?.doctor_phone,
    dataDoctor?.academyPlace,
    doctorInformation?.speciality_doctor,
    doctorInformation?.university_doctor,
    specialty,
    dataDoctor,
    doctorInformation?.code_msp,
    doctorInformation?.years_experience_doctor,
    dataDoctor?.experience,
    skills,
    doctorInformation?.medical_skills
  ])

  const updateDoctorInfo = async (): Promise<void> => {
    setError(false)
    setMessage('')
    try {
      const DoctorInfo: IDoctorBody = {
        code_msp: dataDoctor.codeMsp,
        description_doctor: dataDoctor.about,
        doctor_ci: dataDoctor.doctorCi,
        doctor_phone: dataDoctor.phone,
        last_name_doctor: dataDoctor.lastName,
        name_doctor: dataDoctor.name,
        photo_doctor: selectedImage,
        speciality_doctor: specialty,
        years_experience_doctor: dataDoctor.experience,
        medical_skills: skills,
      }

      if (selectedImage?.includes('https://')) {
        DoctorInfo.photo_doctor = ''
      }
      const { data, status } = await updateDoctorInfoUtility(
        DoctorInfo,
        idDoctor,
      )

      if (status === 0 && (data as IDoctorUpdateBodyResponse).msp_error) {
        setError(true)
        setMessage((data as IDoctorUpdateBodyResponse).message as string)

        return
      }
      if (status) {
        setDoctorInformation({
          ...doctorInformation,
          description_doctor: dataDoctor.about,
          doctor_ci: dataDoctor.doctorCi,
          code_msp: dataDoctor.codeMsp,
          doctor_phone: dataDoctor.phone,
          last_name_doctor: dataDoctor.lastName,
          name_doctor: dataDoctor.name,
          photo_doctor: selectedImage,
          speciality_doctor: specialty,
          years_experience_doctor: dataDoctor.experience,
          medical_skills: skills,
        })

      }
      handleAlert(
        true,
        (data as IDoctorUpdateBodyResponse)?.data ||
          ((data as IDoctorUpdateBodyResponse)?.message as string),
        status ? 'success' : 'warning',
      )
    } catch (error: unknown) {
      handleAlert(true, 'Error al actualizar información del doctor', 'error')
    }
  }

  return {
    specialties,
    selectedImage,
    setSelectedImage,
    specialty,
    setSpecialty,
    dataDoctor,
    setDataDoctor,
    updateDoctorInfo,
    handleAlert,
    isDisabled,
    error,
    message,
    skills,
    setSkills,
  }
}
