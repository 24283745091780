import { AddedOffice } from './components/AddedOffices/AddedOffice'
import AutocompleteGoogleMaps from './components/MapComponents/AutocompleteGoogle'
import { MapsGoogle } from './components/MapComponents/MapsGoogle'
import {
  IOfficeMedical,
  useRegisterConsultory,
} from './hooks/useRegisterConsultory'
import text from './RegisterConsultory.json'
import { GridItems, StyledForm } from './RegisterConsultoryFormStyle'
import { BackButton } from '../../../../components/BackButton/BackButton'
import FormTextInput from '../../../../components/FormInputText'
import { GridFullScreen } from '../../../../components/GridFullScreen/GridFullScreen'
import { Loading } from '../../../../components/Loading/Loading'
import { GridPartialScreen } from '../../../../components/StyledComponents/GridPartialScreen'
import { InputCurrencyStyled } from '../../../../components/StyledComponents/InputCurrencyStyled'
import { LabelInput } from '../../../../components/StyledComponents/LabelInput'
import { Title } from '../../../../components/StyledComponents/Title'
import SubmitButton from '../../../../components/SubmitButton'
import { GridWrapper, InputAdornmentWrapper } from '../../../../components/wrapper'
import { ButtonCorreo } from '../../../../utils/RegisterMedicalFormStyles'
import { validateInput } from '../../../../utils/validateInput'

export const RegisterConsultory = (): React.JSX.Element => {
  const {
    isLoaded,
    onBack,
    onSubmit,
    address,
    selected,
    setValueEdition,
    onValidationFocus,
    consultingRoom,
    setConsultingRoom,
    ValidationFocus,
    isItemModifying,
    setMarkerPosition,
    setSelected,
    consultingFloor,
    setConsultingFloor,
    consultingOffice,
    setConsultingOffice,
    value,
    setValue,
    map,
    zoom,
    setZoom,
    officeMedical,
    setAddress,
    setMapCenter,
    appointmentPrice,
    setAppointmentPrice,
    disabledButton,
    newOfficeMedical,
    addressEdition,
    mapCenter,
    deleteItem,
    modifyItem,
    editItem,
    setMap,
    markerPosition,
  } = useRegisterConsultory()

  return isLoaded ? (
    <GridFullScreen id="RegisterConsultoryForm">
      <GridPartialScreen container flexDirection={'column'}>
        <BackButton id="backButton" text="Regresar" onClick={onBack} />
        <Title>{text.create_profile}</Title>
        <StyledForm onSubmit={onSubmit} data-testid="consultory-form">
          <GridWrapper container>
            <GridWrapper item xs={12} sx={{ mt: 2 }}>
              <GridWrapper
                container
                spacing={2}
                sx={{ marginBottom: 4 }}
                direction={{ xs: 'column-reverse', md: 'row' }}
              >
                <GridWrapper item xs={12} md={5} mt={{ xs: 2, md: 0 }}>
                  <GridWrapper container>
                    <GridWrapper item xs={12} marginBottom={3}>
                      <LabelInput>
                        {text.where_you_see_your_patients}
                      </LabelInput>
                      <AutocompleteGoogleMaps
                        address={address}
                        setMarkerPosition={setMarkerPosition}
                        setZoom={setZoom}
                        setAddress={setAddress}
                        setMapCenter={setMapCenter}
                        isLoaded={isLoaded}
                        setSelected={setSelected}
                        selected={selected}
                        value={value}
                        setValue={setValue}
                        isDisabled={isItemModifying}
                      />
                    </GridWrapper>
                    <GridWrapper item xs={12} marginBottom={3}>
                      <FormTextInput
                        id="consultingRoom"
                        type="text"
                        placeholder={text.building}
                        fullWidth
                        label={text.building}
                        name="consultingRoom"
                        value={consultingRoom}
                        onChange={(event) =>
                          setConsultingRoom(event.target.value)
                        }
                        onBlur={() => onValidationFocus(0)}
                        error={
                          !validateInput(consultingRoom) &&
                          consultingRoom.length === 0 &&
                          ValidationFocus[0]
                        }
                        helperText={
                          !validateInput(consultingRoom) &&
                          consultingRoom.length === 0 &&
                          ValidationFocus[0]
                            ? 'Campo requerido.'
                            : ''
                        }
                        disabled={isItemModifying}
                      />
                    </GridWrapper>
                    <GridWrapper item xs={12}>
                      <GridWrapper container spacing={2} marginBottom={2}>
                        <GridWrapper item xs={6}>
                          <FormTextInput
                            id="consultingFloor"
                            type="text"
                            placeholder={text.floor}
                            label={text.floor}
                            fullWidth
                            name="consultingFloor"
                            value={consultingFloor}
                            onChange={(event) =>
                              setConsultingFloor(event.target.value)
                            }
                            onBlur={() => onValidationFocus(1)}
                            error={
                              !validateInput(consultingFloor) &&
                              consultingFloor.length === 0 &&
                              ValidationFocus[1]
                            }
                            helperText={
                              !validateInput(consultingFloor) &&
                              consultingFloor.length === 0 &&
                              ValidationFocus[1]
                                ? 'Campo requerido.'
                                : ''
                            }
                            disabled={isItemModifying}
                          />
                        </GridWrapper>
                        <GridWrapper item xs={6}>
                          <FormTextInput
                            id="consultingOffice"
                            type="text"
                            placeholder={text.office}
                            fullWidth
                            label={text.office}
                            name="consultingOffice"
                            value={consultingOffice}
                            onChange={(event) =>
                              setConsultingOffice(event.target.value)
                            }
                            onBlur={() => onValidationFocus(2)}
                            error={
                              !validateInput(consultingOffice) &&
                              consultingOffice.length === 0 &&
                              ValidationFocus[2]
                            }
                            helperText={
                              !validateInput(consultingOffice) &&
                              consultingOffice.length === 0 &&
                              ValidationFocus[2]
                                ? 'Campo requerido.'
                                : ''
                            }
                            disabled={isItemModifying}
                          />
                        </GridWrapper>
                      </GridWrapper>
                    </GridWrapper>
                    <GridWrapper item xs={12} marginTop={2}>
                      <LabelInput>
                        {text.how_much_consultation_costs}
                      </LabelInput>
                      <InputCurrencyStyled
                        placeholder={
                          text.how_much_consultation_costs_placeholder
                        }
                        id="appointment_price"
                        name="appointment_price"
                        type="number"
                        fullWidth
                        value={appointmentPrice}
                        onChange={(event) =>
                          setAppointmentPrice(event.target.value)
                        }
                        startAdornment={
                          <InputAdornmentWrapper position="start">
                            $
                          </InputAdornmentWrapper>
                        }
                        disabled={isItemModifying}
                      />
                    </GridWrapper>
                    <GridWrapper item xs={12} sx={{ mb: 4, mt: 2 }}>
                      <ButtonCorreo
                        id="add"
                        variant="contained"
                        role="button"
                        sx={{ width: '45%' }}
                        onClick={newOfficeMedical}
                        disabled={
                          (!validateInput(consultingRoom) &&
                            consultingRoom.length === 0) ||
                          appointmentPrice.length === 0 ||
                          !value ||
                          isItemModifying ||
                          (!validateInput(consultingFloor) &&
                            consultingFloor.length === 0) ||
                          (!validateInput(consultingOffice) &&
                            consultingOffice.length === 0)
                        }
                      >
                        Agregar
                      </ButtonCorreo>
                    </GridWrapper>
                  </GridWrapper>
                </GridWrapper>

                <GridWrapper item xs={12} md={7}>
                  <MapsGoogle
                    map={map}
                    markerPosition={markerPosition}
                    zoom={zoom}
                    address={address}
                    mapCenter={mapCenter}
                    setMap={setMap}
                    setMarkerPosition={setMarkerPosition}
                    setZoom={setZoom}
                    setAddress={setAddress}
                    setMapCenter={setMapCenter}
                    isLoaded={isLoaded}
                    setSelected={setSelected}
                  />
                </GridWrapper>
              </GridWrapper>
            </GridWrapper>
            <GridWrapper item xs={12} sm={8} md={5}>
              {officeMedical?.map((item: IOfficeMedical, index: number) => {
                return (
                  <GridItems key={index}>
                    <AddedOffice
                      address={address}
                      isLoaded={isLoaded}
                      item={item}
                      selected={selected}
                      officeMedical={officeMedical}
                      isItemModifying={isItemModifying}
                      addressEdition={addressEdition}
                      setAddress={setAddress}
                      setMapCenter={setMapCenter}
                      setMarkerPosition={setMarkerPosition}
                      setSelected={setSelected}
                      setValueEdition={setValueEdition}
                      setZoom={setZoom}
                      editItem={editItem}
                      modifyItem={modifyItem}
                      deleteItem={deleteItem}
                    />
                  </GridItems>
                )
              })}
            </GridWrapper>
            <GridWrapper item xs={12} sx={{ my: 2 }}>
              <SubmitButton
                id="button"
                type="submit"
                variant="contained"
                fullWidth
                text="Siguiente"
                data-testid="ok-button"
                disabled={disabledButton}
              />
            </GridWrapper>
          </GridWrapper>
        </StyledForm>
      </GridPartialScreen>
    </GridFullScreen>
  ) : (
    <Loading />
  )
}

export default RegisterConsultory
