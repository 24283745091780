import { Theme, useMediaQuery, useTheme } from '@mui/material'
import dayjs from 'dayjs'
import LocaleData from 'dayjs/plugin/localeData'
import weekday from 'dayjs/plugin/weekday'
import React, { useContext, useEffect, useState } from 'react'

import 'dayjs/locale/es'
import 'dayjs/locale/en'
import {
  AccountContext,
  AuthContextType,
} from '../../../../../../../contexts/AccountContext'
import { useDoctorInformationStore } from '../../../../../../../contexts/DoctorInformationState'
import { useCreateOfficeStore } from '../../../../../../../contexts/NewOfficeState'
import { IDaysMedicalCare } from '../../../../../../../contexts/RegisterContext'
import { useSelectOffice } from '../../../../../../../contexts/SelectOfficeState'
import {
  BodySaveOfficeRequest,
  IApiResponseOffices,
  IMedicalOffice,
  OpeningHoursType,
} from '../../../../../../../infrastructure/dtos/Offices'
import {
  IOfficeService,
  OfficesService,
} from '../../../../../../../services/Contracts/Persistencia/OfficesService'
import { SaveNewOfficeUtility } from '../../../../../../../services/Contracts/Utility/Offices'
import { formatHourToValidate } from '../../../../../../../utils/FormatHour'
import { useForm } from '../../../../../../../utils/useForm'
import { validateOfficePhoneNumber } from '../../../../../../../utils/validatePhoneNumber'
import { Duration } from '../../../../../../medical/ProfileMedical/Components/hooks/useAppointmentTypesList'
import {
  useAppointmentDurations
} from '../../../../../../medical/ProfileMedical/ProfileMedicalScheduleSetting/Hooks/useAppointmentDurations'
import {
  useAppointmentReasons
} from '../../../../../../medical/ProfileMedical/ProfileMedicalScheduleSetting/Hooks/useAppointmentReasons'
import {
  AppointmentType
} from '../../../../../../medical/ProfileMedical/ProfileMedicalScheduleSetting/Hooks/useProfileMedicalScheduleSetting'
import { useVisibilityAppointmentTime } from '../../../../../../medical/ProfileMedical/ProfileMedicalScheduleSetting/Hooks/useVisibilityAppointmentTime'
import { useVisibilityIntervals } from '../../../../../../medical/ProfileMedical/ProfileMedicalScheduleSetting/Hooks/useVisibilityIntervals'
import text from '../../../../../../medical/ProfileMedical/ProfileMedicalScheduleSetting/ProfileMedicalScheduleSettings.json'

dayjs.extend(LocaleData)
dayjs.extend(weekday)

interface IDataOffice {
  reference: string
  id: string
}

export interface IUseProfileMedicalScheduleSettingHook {
  amountRanges: string[]
  lists: string[]
  selectedExperience: string
  selectTime: string
  phoneNumber: string
  office: IDataOffice
  dataConsultory: IDaysMedicalCare[]
  disabledButton?: boolean
  validatePhoneNumber: boolean
  validatePhoneNumberText: string
  isExtraSmallSize: boolean
  onChange: (
    value: string,
    field: 'selectedExperience' | 'selectTime' | 'phoneNumber',
  ) => void
  setDataConsultory: React.Dispatch<React.SetStateAction<IDaysMedicalCare[]>>
  fetchSaveNewOffice: (event: { preventDefault: () => void }) => Promise<void>
  typesList: AppointmentType[]
  setTypesList: React.Dispatch<React.SetStateAction<AppointmentType[]>>
  durationsDB: Duration[]
  typesDB: string[]
}

const initialTypesList: AppointmentType[] = [
]


interface IUseCreateScheduleProps {
  handleClose: () => void
}

export function useCreateSchedule({
  handleClose,
}: IUseCreateScheduleProps): IUseProfileMedicalScheduleSettingHook {
  const {
    office: officeStore,
    schedule,
    setSchedule,
    clearAll,
  } = useCreateOfficeStore()
  const { doctorInformation } = useDoctorInformationStore()
  const { setOfficeSelected } = useSelectOffice()
  const { handleAlert } = useContext(AccountContext) as AuthContextType
  const theme: Theme = useTheme()
  const isExtraSmallSize = !useMediaQuery(theme.breakpoints.up('sm'))
  const { amountRanges } = useVisibilityIntervals(handleAlert)
  const { lists } = useVisibilityAppointmentTime(handleAlert)
  const { types: typesDB } = useAppointmentReasons()
  const { durations: durationsDB } = useAppointmentDurations()

  const [office] = useState<IDataOffice>({
    reference: `${officeStore?.building_name}, ${officeStore.building_floor}, ${officeStore.office_number}`,
    id: officeStore?.id,
  })

  const { selectedExperience, selectTime, phoneNumber, onChange } = useForm({
    selectedExperience: schedule?.visualisationAgenda || '1 mes',
    selectTime: schedule?.durationMedicalAppointment || '15 minutos',
    phoneNumber: schedule?.office_phone_number || '',
  })
  const [typesList, setTypesList] =
    useState<AppointmentType[]>(schedule.appointmentTypes?.length > 0 ? schedule.appointmentTypes :  initialTypesList)

  const [dataConsultory, setDataConsultory] = useState<IDaysMedicalCare[]>(
    schedule?.daysMedicalCare,
  )
  const [disabledButton, setDisabledButton] = useState<boolean>(true)

  const fetchOffices = async (): Promise<void> => {
    const valores: IOfficeService = await OfficesService(
      doctorInformation?.user_id as string,
    )
    if ((valores.data as IApiResponseOffices)?.body) {
      // order list, in last position data with office_status = 'INACTIVE'
      const list = (valores.data as IApiResponseOffices)?.body.medical_offices
      const inactive = list.filter(
        (item: IMedicalOffice) => item.office_status === 'INACTIVE',
      )
      const active = list.filter(
        (item: IMedicalOffice) => item.office_status !== 'INACTIVE',
      )
      const offices = [...active, ...inactive]

      offices[0].selected = true

      setOfficeSelected([...active, ...inactive])
    }
  }

  const fetchSaveNewOffice = async (event: {
    preventDefault: () => void
  }): Promise<void> => {
    event.preventDefault()
    setDisabledButton(true)
    const convertDaysMedicalCare: OpeningHoursType = {}
    const months = schedule?.visualisationAgenda?.split(' ')[0]
    const convertToDays = (Number(months) * 30).toString()

    schedule.daysMedicalCare.forEach((item) => {
      if (item.active) {
        // item.day is equal ej: 'Lunes' translate to 'monday'
        dayjs.locale('es')
        const dayNumber = dayjs()
          .localeData()
          .weekdays()
          .indexOf(item.day.toLowerCase())
        dayjs.locale('en')
        const convertDayNameToEng = dayjs()
          .localeData()
          .weekdays()
          [dayNumber].toLowerCase()
        convertDaysMedicalCare[convertDayNameToEng] = {
          hour_open: item.from.replace('h', ':'),
          hour_close: item.to.replace('h', ':'),
        }
      }
    })

    const body: BodySaveOfficeRequest = {
      building_floor: officeStore?.building_floor,
      building_name: officeStore?.building_name,
      office_phone: schedule?.office_phone_number,
      consultation_time_minutes: schedule?.durationMedicalAppointment,
      consultation_value: officeStore?.consultationValue,
      coordinates_medical_office: officeStore?.coordinates,
      name_medical_office: officeStore?.address,
      opening_hours: convertDaysMedicalCare,
      visibility_medical_office_days: convertToDays,
      user_id: doctorInformation?.user_id as string,
      office_number: officeStore?.office_number,
      appointment_types: typesList
    }

    const { status, data } = await SaveNewOfficeUtility(body)
    if (status) {
      handleAlert(true, data.message, 'success')
      await fetchOffices()
      clearAll()
      handleClose()
    } else {
      handleAlert(true, data.message, 'error')
    }
    setDisabledButton(false)
  }

  const onChangeDaysMedicalCare = (): void => {
    if (selectTime !== undefined && selectedExperience !== undefined) {
      setSchedule({
        office_phone_number: phoneNumber,
        daysMedicalCare: dataConsultory,
        durationMedicalAppointment: selectTime,
        reference: office.reference,
        visualisationAgenda: selectedExperience,
        id: office.id,
        appointmentTypes: typesList,
      })
    }
  }

  const validatePhoneNumber: boolean = (!validateOfficePhoneNumber(phoneNumber) || isNaN(Number(phoneNumber))) && phoneNumber !== ''
  const validatePhoneNumberText: string = validatePhoneNumber
    ? text.validate_phone_number_text
    : ''

  const validateTypeList = (): boolean => {
    if (typesList.length === 0) {
      return true
    }
    // validate if all types have duration and have name
    return !typesList?.every((type) => type.duration && type.name)
  }

  useEffect(() => {
    setSchedule({
      office_phone_number: phoneNumber,
      daysMedicalCare: dataConsultory,
      durationMedicalAppointment: selectTime,
      reference: office.reference,
      visualisationAgenda: selectedExperience,
      id: office.id,
      appointmentTypes: typesList,
    })

    if (
      validatePhoneNumber ||
      !selectTime ||
      !selectedExperience ||
      validateDaysMedicalCare() ||
      validateTypeList()
    ) {
      setDisabledButton(true)
    } else {
      setDisabledButton(false)
    }
  }, [selectTime, phoneNumber, selectedExperience, typesList])

  useEffect(() => {
    onChangeDaysMedicalCare()
    setDisabledButton(validateDaysMedicalCare() || validateTypeList())
    // eslint-disable-next-line
  }, [dataConsultory])

  const validateDaysMedicalCare = (): boolean => {
    const filteredDays: IDaysMedicalCare[] | undefined = dataConsultory.filter(
      (day) => day.active,
    )
    const isDisabled = !(
      filteredDays &&
      filteredDays?.every((day) => {
        const hourFrom: string = formatHourToValidate(day.from)
        const hourTo: string = formatHourToValidate(day.to)
        return (
          day?.active &&
          dayjs(`${dayjs().format('YYYY-MM-DD')}T${hourFrom}`).isBefore(
            dayjs(`${dayjs().format('YYYY-MM-DD')}T${hourTo}`),
          )
        )
      })
    )

    return filteredDays?.length === 0 || isDisabled
  }

  return {
    amountRanges,
    lists,
    selectedExperience,
    selectTime,
    phoneNumber,
    office,
    dataConsultory,
    disabledButton,
    validatePhoneNumber,
    validatePhoneNumberText,
    isExtraSmallSize,
    onChange,
    setDataConsultory,
    fetchSaveNewOffice,
    typesDB,
    durationsDB,
    typesList,
    setTypesList
  }
}
