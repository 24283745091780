import { styled } from '@mui/material'

import { GridWrapper } from '../../../../components'

export const GridForm = styled(GridWrapper)(({ theme }) => ({
  width: '70%',
  marginTop: '2%',
  [theme.breakpoints.down('sm')]: {
    width: '90%',
  },
}))

export const GridOfficeName = styled(GridWrapper)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  height: '58.88px',
  backgroundColor: theme.palette.tertiary?.light,
  padding: '16.5px 14px',
  borderRadius: '3.4375rem',
  fontSize: '1.125rem',
  color: theme.palette.text.primary,
  fontFamily: 'Helvetica',
  fontWeight: 'normal',
  fontStretch: 'normal',
  fontStyle: 'normal',
  lineHeight: 'normal',
  letterSpacing: 'normal',
}))
