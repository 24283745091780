import dayjs from 'dayjs'
import { BaseSyntheticEvent, useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import { AccountContext, AuthContextType } from '../../../../../../../contexts/AccountContext'
import { Appointment, IAppointmentDetails } from '../../../../../../../infrastructure/dtos/Appointments'
import { ISaveClinicalPictureBodyResponse } from '../../../../../../../infrastructure/dtos/ClinicalPicture'
import { ICodeCie10 } from '../../../../../../../infrastructure/dtos/SearchCie10'
import { saveClinicalPictureUtility } from '../../../../../../../services/Contracts/Utility/ClinicalPictureUtility'
import { useDiagnostic } from '../../../../../components/DiagnosticBody/DiagnosticBodyHook'
import { useRecipeModal } from '../../../../../components/modals/RecipeModal/hook'
import { usePatientRecipesModal } from '../PatientRecipesModal/hook'

interface IUsePatientPictureMedical {
  openRecipeModal: boolean
  loadingAppointmentDetails: boolean
  isDisabledSecondary: boolean
  fetchMessage: string
  MAX_LENGTH: number
  isLEThanMaxValue: boolean
  isDisabledPrimary: boolean
  diagnostics: ICodeCie10[]
  appointmentDetails: IAppointmentDetails
  diagnosticSelected?: ICodeCie10
  onChangeDiagnosticSelected: (option: ICodeCie10) => void
  onAddDiagnostics: (newDiagnostic: ICodeCie10) => void
  onDeleteDiagnostics: (index: number) => void
  handleOpenRecipeModal: () => void
  handlePictureClinic: () => Promise<void>
  handleMessageOnChange: (ev: BaseSyntheticEvent) => void
  onCloseRecipeModal: () => void
}

export const usePatientPictureMedical = (appointmentInfo?: Appointment): IUsePatientPictureMedical => {
  const { handleAlert } = useContext(AccountContext) as AuthContextType
  const currentDate = dayjs(new Date())
  const [MAX_LENGTH] = useState<number>(500)
  const { idAppointment } = useParams()
  const {
    diagnostics,
    diagnosticSelected,
    onChangeDiagnosticSelected,
    onAddDiagnostics,
    onDeleteDiagnostics,
    onSetDiagnostics,
  } = useDiagnostic()
  const { openRecipeModal, handleOpenRecipeModal, handleCloseRecipeModal } =
    useRecipeModal()

  const {
    appointmentDetails,
    loadingAppointmentDetails,
    fetchAppointmentDetailsUtility,
  } = usePatientRecipesModal()

  const [fetchMessage, setFetchMessage] = useState<string>('')
  const [disabledRecip, setDisabledRecip] = useState<boolean>(true)

  const handlePictureClinic = async (): Promise<void> => {
    try {
      const { data, status } = await saveClinicalPictureUtility({
        appointment_id: idAppointment as string,
        clinical_picture: fetchMessage,
        medical_diagnostic: diagnostics.map((item: ICodeCie10) => ({
          objectID: item.id_cie10,
        })),
      })

      if (
        status &&
        (data as ISaveClinicalPictureBodyResponse).message
      ) {
        handleAlert(
          true,
          (data as ISaveClinicalPictureBodyResponse).message,
          'success',
        )
        setDisabledRecip(false)
        fetchAppointmentDetailsUtility(idAppointment as string)
      } else {
        handleAlert(
          true,
          ((data as ISaveClinicalPictureBodyResponse).message as string) || (data as string),
          'error',
        )
      }
    } catch (error: unknown) {
      handleAlert(true, 'Error al guardar el cuadro clínico del paciente', 'error')
    }
  }

  const handleMessageOnChange = (ev: BaseSyntheticEvent): void => {
    if (currentDate.diff(appointmentInfo?.appointment_date, 'day') === 0) {
      setFetchMessage(ev.target.value)
      setDisabledRecip(true)
    }
  }

  const onCloseRecipeModal = (): void => {
    handleCloseRecipeModal()
  }

  const isLEThanMaxValue: boolean = fetchMessage.length <= MAX_LENGTH

  const isDiagnosticChanged = (): boolean =>
    JSON.stringify(appointmentDetails.patient_diagnostic) !==
    JSON.stringify(diagnostics)

  let isDisabledPrimary = true
  let isDisabledSecondary = true
  if (diagnostics.length === 0 || fetchMessage === '') {
    isDisabledPrimary = true
  } else if (
    appointmentDetails.clinical_picture === fetchMessage &&
    !isDiagnosticChanged()
  ) {
    isDisabledPrimary = true
  } else if (!isLEThanMaxValue) {
    isDisabledPrimary = true
  } else {
    isDisabledPrimary = false
  }

  if (appointmentDetails.clinical_picture === '' && disabledRecip) {
    isDisabledSecondary = true
  } else if (!isDisabledPrimary || fetchMessage === '') {
    isDisabledSecondary = true
  } else if (currentDate.diff(appointmentInfo?.appointment_date, 'day') !== 0) {
    isDisabledSecondary = true
  } else if (diagnostics.length === 0) {
    isDisabledSecondary = true
  } else {
    isDisabledSecondary = !appointmentDetails.clinical_picture && disabledRecip
  }

  useEffect(() => {
    if (
      appointmentDetails.diagnostic.length === 0 &&
      appointmentDetails.documents.length === 0
    ) {
      fetchAppointmentDetailsUtility(idAppointment as string)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (
      appointmentDetails.clinical_picture !== '' &&
      appointmentDetails.clinical_picture
    ) {
      setDisabledRecip(false)
      setFetchMessage(appointmentDetails.clinical_picture as string)
    }
  }, [appointmentDetails.clinical_picture])

  useEffect(() => {
    if (appointmentDetails.patient_diagnostic.length > 0) {
      onSetDiagnostics(appointmentDetails.patient_diagnostic)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appointmentDetails.patient_diagnostic])

  return {
    diagnosticSelected,
    openRecipeModal,
    loadingAppointmentDetails,
    isDisabledSecondary,
    fetchMessage,
    MAX_LENGTH,
    isLEThanMaxValue,
    isDisabledPrimary,
    diagnostics,
    appointmentDetails,
    onChangeDiagnosticSelected,
    onAddDiagnostics,
    onDeleteDiagnostics,
    handleOpenRecipeModal,
    handlePictureClinic,
    handleMessageOnChange,
    onCloseRecipeModal,
  }
}
