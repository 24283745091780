import { debounce } from '@mui/material'
import React, {
  FormEvent,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'

import { useNavigate } from 'react-router-dom'

import {
  IErrorValidationIdDocument,
  useValidationComponent,
} from './ProfileMedicalHook'
import { useDoctorInformationStore } from '../../../../../contexts/DoctorInformationState'
import { RegisterMedicalContext } from '../../../../../contexts/RegisterContext'
import { IBodyGenders } from '../../../../../infrastructure/dtos/Genders'
import { GendersUtility } from '../../../../../services/Contracts/Utility/GendersUtility'

import {
  validateInput,
  validateNumberInput,
} from '../../../../../utils/validateInput'
import { validatePhoneNumber } from '../../../../../utils/validatePhoneNumber'
import containerText from '../ProfileMedicalFormText.json'

type SetterState<T> = React.Dispatch<React.SetStateAction<T>>

interface IUseProfileMedicalForm {
  genders: string[]
  idTypes: string[]
  selectedImage: string
  setSelectedImage: SetterState<string>
  disabledOption: boolean
  onSubmit: (event: FormEvent) => void
  onValidationFocus: (index: number) => void
  validateIdType: boolean
  validateIdNumber: boolean
  validatorIdDocument: IErrorValidationIdDocument
  textErrorIdNumber: () => string
  validateName: boolean
  validateLastName: boolean
  validateGender: boolean
  validatePhone: boolean
  validateDescription: boolean
  validatorErrorTextArea: string
  isValid: boolean
  fieldRegister: IFieldRegister
  setFieldRegister: SetterState<IFieldRegister>
}
interface IFieldRegister {
  name: string
  lastName: string
  description: string
  phone: string
  idType: string
  gender: string
  idNumber: string
}
export const useProfileMedicalForm = (): IUseProfileMedicalForm => {
  const { doctorInformation } = useDoctorInformationStore()
  const { fetchValidationIdDocument, validatorIdDocument } =
    useValidationComponent()
  const { savePhysicianProfile, RegisterMedical } = useContext(
    RegisterMedicalContext,
  )
  const navigate = useNavigate()

  const [disabledOption, setDisabledOption] = useState<boolean>(false)
  const [genders, setGenders] = useState<string[]>([''])
  const [idTypes] = useState<string[]>(['Cédula o RUC', 'Pasaporte'])
  const [fieldRegister, setFieldRegister] = useState<IFieldRegister>({
    idType: 'Cédula o RUC',
    description: '',
    gender: '',
    idNumber: '',
    lastName: '',
    name: '',
    phone: '',
  })
  const [selectedImage, setSelectedImage] = useState<string>('')
  const [ValidationFocus, setValidationFocus] = useState<boolean[]>([
    false,
    false,
    false,
    false,
    false,
    false,
    false,
  ])

  const fetchGenders = async (): Promise<void> => {
    const { data, status } = await GendersUtility()
    if (status) {
      setGenders((data as IBodyGenders).data as string[])
    }
  }
  const requiredLength = 1000

  const validLength: boolean =
    fieldRegister.description.length >= requiredLength

  const onSubmit = (event: FormEvent): void => {
    event.preventDefault()
    savePhysicianProfile({
      description: fieldRegister.description,
      image: selectedImage,
      lastName: fieldRegister.lastName,
      name: fieldRegister.name,
      phone: fieldRegister.phone,
      idNumber: fieldRegister.idNumber,
      idType: fieldRegister.idType,
      gender: fieldRegister.gender,
    })
    navigate('/medico/register/profile1')
  }

  const textErrorIdNumber = (): string => {
    if (!validateIdErrorText()) {
      return validatorIdDocument.message
    }
    return validateIdErrorText()
  }
  const onValidationFocus = (index: number): void => {
    const newValidationFocus: boolean[] = [...ValidationFocus]
    newValidationFocus[index] = true
    setValidationFocus(newValidationFocus)
  }
  const validatorTextArea = (): string => {
    let textError = ''
    if (
      !validateInput(fieldRegister.description) &&
      fieldRegister.description.length === 0 &&
      ValidationFocus[6]
    ) {
      textError = containerText.required_field
    } else if (validLength) {
      textError = containerText.description_length
    }
    return textError
  }

  const validateIdErrorText = (): string => {
    let idErrorText = ''
    if (fieldRegister.idNumber.length === 0 && ValidationFocus[5]) {
      idErrorText = containerText.required_field
      return idErrorText
    }
    switch (fieldRegister.idType) {
      case 'Cédula':
        if (!validateIdNumberCI) {
          idErrorText = containerText.id_number_length
        } else {
          idErrorText = ''
        }
        break
      case 'Pasaporte':
        if (!validateIdNumberPassport) {
          idErrorText = containerText.passport_length
        } else {
          idErrorText = ''
        }
        break
      default:
        idErrorText = ''
    }
    return idErrorText
  }
  const validatorErrorTextArea: string = validatorTextArea()

  const validateName: boolean =
    !validateInput(fieldRegister.name) &&
    fieldRegister.name.length === 0 &&
    ValidationFocus[0]

  const validateLastName: boolean =
    !validateInput(fieldRegister.lastName) &&
    fieldRegister.lastName.length === 0 &&
    ValidationFocus[1]

  const validateGender: boolean =
    !validateInput(fieldRegister.gender) &&
    fieldRegister.gender.length === 0 &&
    ValidationFocus[2]

  const validatePhone: boolean =
    !validateInput(fieldRegister.phone) &&
    fieldRegister.phone.length === 0 &&
    ValidationFocus[3]

  const validateIdType: boolean =
    !validateInput(fieldRegister.idType) &&
    fieldRegister.idType.length === 0 &&
    ValidationFocus[4]

  const validateIdNumberCI: boolean =
    !ValidationFocus[5] ||
    (validateNumberInput(fieldRegister.idNumber) &&
      (fieldRegister.idNumber.length === 10 ||
        fieldRegister.idNumber.length === 13))
  const validateIdNumberPassport: boolean =
    !ValidationFocus[5] ||
    (validateInput(fieldRegister.idNumber) &&
      fieldRegister.idNumber.length >= 3)
  const validateIdNumber: boolean =
    fieldRegister.idType === 'Cédula'
      ? !validateIdNumberCI
      : !validateIdNumberPassport

  const validateDescription: boolean =
    (!validateInput(fieldRegister.description) &&
      fieldRegister.description.length === 0 &&
      ValidationFocus[6]) ||
    validLength

  const disabledButton = (): boolean => {
    if (!validatePhoneNumber(fieldRegister.phone)) {
      return true
    }
    return (
      validatorIdDocument.status ||
      fieldRegister.name.length === 0 ||
      fieldRegister.lastName.length === 0 ||
      fieldRegister.gender.length === 0 ||
      fieldRegister.phone.length === 0 ||
      fieldRegister.idType.length === 0 ||
      fieldRegister.idNumber.length === 0 ||
      fieldRegister.description.length === 0 ||
      validateIdNumber
    )
  }

  const isValid: boolean = disabledButton()
  const searchDelayed = useMemo(
    () => debounce(fetchValidationIdDocument, 1000),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  )
  useEffect(() => {
    fetchGenders()
  }, [])
  useEffect(() => {
    if (doctorInformation?.name_doctor !== undefined) {
      setDisabledOption(true)
    }
  }, [doctorInformation])

  useEffect(() => {
    if (RegisterMedical?.name !== undefined) {
      setFieldRegister({
        ...fieldRegister,
        name: RegisterMedical.name ?? '',
        lastName: RegisterMedical.lastName ?? '',
        description: RegisterMedical.description ?? '',
        phone: RegisterMedical.phone ?? '',
        idNumber: RegisterMedical.idNumber ?? '',
        idType: RegisterMedical.idType ?? 'Cédula o RUC',
        gender: RegisterMedical.gender ?? '',
      })
    }
    if (RegisterMedical?.image !== undefined) {
      setSelectedImage(RegisterMedical?.image)
    }
  }, [
    RegisterMedical,
    // RegisterMedical?.description,
    // RegisterMedical?.idNumber,
    // RegisterMedical?.phone,
    // RegisterMedical?.idType,
    // RegisterMedical?.gender,
    // RegisterMedical?.lastName,
    // RegisterMedical?.name,
    // RegisterMedical?.image,
    // setFieldRegister,
    // setSelectedImage,
  ])
  useEffect(() => {
    const document_type: 'CI' | 'PASSPORT' =
      fieldRegister.idType === 'Cédula' ? 'CI' : 'PASSPORT'
    if (
      (document_type === 'CI' &&
        (fieldRegister.idNumber.length === 10 ||
          fieldRegister.idNumber.length === 13)) ||
      (document_type === 'PASSPORT' && fieldRegister.idNumber.length > 2)
    ) {
      searchDelayed({
        document: fieldRegister.idNumber,
        document_type: document_type,
        type: 'REGISTER',
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fieldRegister.idNumber, fieldRegister.idType])
  return {
    genders,
    idTypes,
    selectedImage,
    setSelectedImage,
    disabledOption,
    onSubmit,
    onValidationFocus,
    validateIdType,
    validateIdNumber,
    validatorIdDocument,
    textErrorIdNumber,
    validateName,
    validateLastName,
    validateGender,
    validatePhone,
    validateDescription,
    validatorErrorTextArea,
    isValid,
    fieldRegister,
    setFieldRegister,
  }
}
