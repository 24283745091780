import { AttendanceRepository } from '../../domain/repository/AttendanceRepository'
import {
  SaveAssistanceAppointment,
  IApiResponseSaveAssistanceAppointment, IApiResponseResetAssistanceAppointment
} from '../dtos/Attendance'
import AttendanceDataSource from '../http/dataSource/AttendanceDataSource'

export class AttendanceRepositoryImpl implements AttendanceRepository {
  dataSource: AttendanceDataSource

  constructor( _datasource: AttendanceDataSource ) {
    this.dataSource = _datasource
  }

  saveAttendance(
    dataAttendance: SaveAssistanceAppointment
  ): Promise<IApiResponseSaveAssistanceAppointment> {
    return this.dataSource.saveAttendance( dataAttendance )
  }

  resetAttendance(appointmentId: string): Promise<IApiResponseResetAssistanceAppointment> {
    return this.dataSource.resetAttendance( appointmentId )
  }
}
