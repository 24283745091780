import { AxiosError } from 'axios'

import { IApiResponseSearchCie10 } from '../../../dtos/SearchCie10'
import SearchCie10DataSource from '../../dataSource/SearchCie10DataSource'
import { http } from '../../Http'

export default class SearchCie10Mapper implements SearchCie10DataSource {
  async getSearchCie10(query: string): Promise<IApiResponseSearchCie10> {
    try {
      const { data } = await http.get<IApiResponseSearchCie10>(
        `send_data/search_cie10?query=${query}`,
      )

      return data
    } catch (error: unknown) {
      const data: IApiResponseSearchCie10 = {
        statusCode: (error as AxiosError).response?.status as number,
        body: [],
        headers: {
          'Access-Control-Allow-Headers': '',
          'Access-Control-Allow-Origin': '',
          'Access-Control-Allow-Methods': '',
        },
      }

      return data
    }
  }
}
