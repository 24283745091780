import { ChangeEvent, useContext, useEffect, useState } from 'react'

import {
  AccountContext,
  AuthContextType
} from '../../../../../contexts/AccountContext'
import {useDoctorInformationStore} from '../../../../../contexts/DoctorInformationState';
import { IQualificationList, IResponseQualificationList } from '../../../../../infrastructure/dtos/qualification'
import { GetQualificationListDoctorUtility, PutQualificationStatusUtility } from '../../../../../services/Contracts/Utility/QualificationUtility'

interface IPagination {
  page: number
  limit: number
  totalPages: number
}

interface IChunckedQualificationList {
  message: string;
  data: IQualificationList[][];
}

interface IUseQualifications {
  search: string
  qualificationList: IChunckedQualificationList
  list: string[]
  loading: boolean
  pagination: IPagination
  handleChangePage( event: ChangeEvent<unknown>, value: number ): void
  handleChange: ( event: ChangeEvent<HTMLInputElement> ) => void
  handleClear: () => void
  handleClick: (item: IQualificationList, page: number, index: number) => Promise<void>
}

export const useQualifications = (): IUseQualifications => {
  const {doctorInformation}=useDoctorInformationStore()
  const [search, setSearch] = useState<string>( '' )
  const { handleAlert } = useContext( AccountContext ) as AuthContextType
  const [qualificationList, setQualificationList] =
    useState<IChunckedQualificationList>({
      data: [],
      message: ''
    })
  const [list] = useState<string[]>(['Todos'])
  const [loading, setLoading] = useState<boolean>( false )
  const [pagination, setPagination] = useState<IPagination>({
    page: 0,
    limit: 10,
    totalPages: 1
  })
  const arrStatus: string[] = ['VISIBLE', 'NOT_VISIBLE']

  const handleChange = ( event: ChangeEvent<HTMLInputElement> ): void => {
    setSearch( event.target.value )
  }
  const handleClear = (): void => setSearch( '' )

  const handleChangePage = (
    event: ChangeEvent<unknown>,
    value: number
  ): void => setPagination({ ...pagination, page: value })

  const fetchGetQualificationListDoctorUtility = async (): Promise<void> => {
    try {
      setLoading( true )
      const { data } = await GetQualificationListDoctorUtility( doctorInformation?.user_id as string )
      const resData: IResponseQualificationList = data as IResponseQualificationList
      
      // pagination and batch logic
      const dataToChunk: IQualificationList[][] = []

      if ( resData.data.length ) {
        setPagination({
          ...pagination,
          page: 1,
          totalPages: resData.data.length > pagination.limit ? Math.ceil( resData.data.length / pagination.limit ) : 1
        })
        for ( let i = 0; i < resData.data.length; i += pagination.limit ) {
          const chunk: IQualificationList[] = resData.data.slice( i, i + pagination.limit )

          dataToChunk.push( chunk )
        }
      }

      setQualificationList({
        ...resData,
        data: dataToChunk
      })
      setLoading( false )
    } catch ( error: unknown ) {
      handleAlert(true, 'Error al obtener las calificaciones', 'error')
    }
  }

  const handleClick = async (
    item: IQualificationList,
    page: number,
    index: number,
  ): Promise<void> => {
    try {
      const statusSelected: string | undefined = arrStatus.find(
        (s: string) => item.qualification_status !== s,
      )
      const { data, status } = await PutQualificationStatusUtility({
        appointment_id: item.appointment_id,
        doctor_id: item.doctor_id,
        qualification_status: statusSelected as string,
      })

      if (status === 1) {
        const newList: IQualificationList[][] = structuredClone(
          qualificationList.data,
        )

        newList[page - 1][index].qualification_status = statusSelected as string
        setQualificationList({ ...qualificationList, data: newList })
        handleAlert(true, data, 'success')
      }
    } catch (error: unknown) {
      handleAlert(true, 'Hubo un error al obtener las horas disponibles', 'error')
    }
  }

  useEffect(() => {
    fetchGetQualificationListDoctorUtility()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return {
    search,
    qualificationList,
    list,
    loading,
    pagination,
    handleClick,
    handleChangePage,
    handleChange,
    handleClear
  }
}
