import { AxiosError } from 'axios'

import {
  IAppointmentsByDoctorParams,
  PatientAppointmentsByDoctorResponse,
} from '../../../dtos/AttendanceSelectedPatient'
import AttendanceSelectedPatientDataSource from '../../dataSource/AttendanceSelectedPatientDataSource'
import { http } from '../../Http'

export default class AttendanceSelectedPatientMapper
  implements AttendanceSelectedPatientDataSource
{
  async getPatientAppointments({
    idDoctor,
    patient,
    date = undefined,
  }: IAppointmentsByDoctorParams): Promise<PatientAppointmentsByDoctorResponse> {
    try {
      let url = `send_data/patient_appointments_by_doctor/${idDoctor}/${patient}`

      if (date) {
        url += `?date=${date}`
      }
      const { data } = await http.get<PatientAppointmentsByDoctorResponse>(url)

      return data
    } catch (error: unknown) {
      const data: PatientAppointmentsByDoctorResponse = {
        statusCode: (error as AxiosError).response?.status as number,
        body: { message: (error as AxiosError).response?.statusText as string },
        headers: {
          'Access-Control-Allow-Headers': '',
          'Access-Control-Allow-Origin': '',
          'Access-Control-Allow-Methods': '',
          'Access-Control-Allow-Credentials': false,
        },
      }

      return data
    }
  }
}
