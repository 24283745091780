import Dialog, { DialogProps } from '@mui/material/Dialog'
import React from 'react'

interface IProps {
  children: React.JSX.Element[]
}

const DialogWrapper: React.FC<IProps & DialogProps> = ({
  children,
  ...props
}) => <Dialog {...props}>{children}</Dialog>

export default DialogWrapper
