import { GetOffices, UpdateOffices, UpdateOfficesStatus } from '../../../domain/useCase/GetOffices'
import { SaveNewOffice } from '../../../domain/useCase/OfficeUseCase'
import { IResponseService } from '../../../infrastructure/dtos'
import {
  BodySaveOfficeRequest,
  IApiResponseOffices, IApiResponseSaveOffice,
  IUpdateInfoOfficeParams,
  IUpdateInfoOfficeResponse,
} from '../../../infrastructure/dtos/Offices'
import {
  BodyModifyStatusOfficeRequest,
  IApiResponseModifyStatusOffice,
} from '../../../infrastructure/dtos/UpdateOffices'
import OfficesMapper from '../../../infrastructure/http/mappers/OfficesMapper/OfficesMapper'
import { OfficesRepositoryImpl } from '../../../infrastructure/RepositoryImpl/OfficesRepositoryImpl'

export interface IUpdateOfficeService {
  status: number
  data: IUpdateInfoOfficeResponse | string
}

export interface IOfficeService {
  status: number
  data: IApiResponseOffices | string
}

export async function OfficesService(
  idDoctor: string,
): Promise<IOfficeService> {
  try {
    const UseCaseRegistro = new GetOffices(
      new OfficesRepositoryImpl(new OfficesMapper()),
    )

    return {
      status: 1,
      data: await UseCaseRegistro.invoke(idDoctor),
    }
  } catch (error: unknown) {
    return {
      status: 0,
      data: (error as Error).message,
    }
  }
}

export async function UpdateOfficeService(
  params: IUpdateInfoOfficeParams,
): Promise<IUpdateOfficeService> {
  try {
    const UseCaseRegistro = new UpdateOffices(
      new OfficesRepositoryImpl(new OfficesMapper()),
    )

    return {
      status: 1,
      data: await UseCaseRegistro.invoke(params),
    }
  } catch (error: unknown) {
    return {
      status: 0,
      data: (error as Error).message,
    }
  }
}

export async function UpdateOfficeStatusService(
  body: BodyModifyStatusOfficeRequest
): Promise<IResponseService<IApiResponseModifyStatusOffice>> {
  try {
    const useCase: UpdateOfficesStatus = new UpdateOfficesStatus(
      new OfficesRepositoryImpl(new OfficesMapper()),
    )

    return {
      status: 1,
      data: await useCase.invoke(body),
    }
  } catch (error: unknown) {
    return {
      status: 0,
      data: (error as Error).message,
    }
  }
}


export async function SaveNewOfficeService(
  body: BodySaveOfficeRequest
): Promise<IResponseService<IApiResponseSaveOffice>> {
  try {
    const useCase: SaveNewOffice = new SaveNewOffice(
      new OfficesRepositoryImpl(new OfficesMapper()),
    )
    return {
      status: 1,
      data: await useCase.invoke(body),
    }
  } catch (error: unknown) {
    return {
      status: 0,
      data: (error as Error).message,
    }
  }
}
