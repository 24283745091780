import { GridProps } from '@mui/material'
import { Theme, styled } from '@mui/material/styles'

import { GridWrapper } from '../../../../components'

interface ICalendarMenuOptionsProps {
  left: number
  top: number
  theme?: Theme
}

export const CalendarMenuOptions = styled(GridWrapper)(
  (props: GridProps & ICalendarMenuOptionsProps) => ({
    position: 'absolute',
    left: props.left,
    top: props.top,
    background: '#fff',
    border: '0px solid',
    borderRadius: '5px',
    width: '200px',
    minHeight: '60px',
    zIndex: '2',
    margin: '0px',
    padding: '5px 0px',
    boxShadow: '0px 0px 5px 0px rgba(0,0,0,0.15)',
    '& .MuiListItemButton-root': {
      padding: '0px 15px',
    },
    '& .MuiListItemIcon-root': {
      minWidth: 0,
      marginRight: 12,
    },
    '& .MuiSvgIcon-root': {
      fontSize: 20,
      color: props.theme?.palette?.primary.dark,
    },
    '& .MuiTypography-root': {
      fontSize: '0.9rem',
      color: props.theme?.palette?.primary.dark,
    },
  }),
)
