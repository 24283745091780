import { Paper, PaperProps } from '@mui/material'
import React from 'react'

type PaperWrapperProps = Omit<PaperProps, 'ref'> &
  React.RefAttributes<HTMLDivElement>

const PaperWrapper: React.ForwardRefExoticComponent<PaperWrapperProps> =
  React.forwardRef<HTMLDivElement, PaperProps>((props, ref) => (
    <Paper {...props} ref={ref} />
  ))

export default PaperWrapper
