import { CalendarRepository } from '../../domain/repository/CalendarRepository'
import {
  IApiResponseGetAvailableHours,
  IApiResponseGetEvents,
  IApiResponseSaveCalendarEvent,
  ICalendarEvent,
  IParamsGetCalendar
} from '../dtos/Calendar'
import CalendarDataSource from '../http/dataSource/CalendarDataSource'

export class CalendarRepositoryImpl implements CalendarRepository {
  dataSource: CalendarDataSource

  constructor( _datasource: CalendarDataSource ) {
    this.dataSource = _datasource
  }

  async postAddCalendarEvent(
    event: ICalendarEvent
  ): Promise<IApiResponseSaveCalendarEvent> {
    return this.dataSource.postAddCalendarEvent( event )
  }

  async getEventsByMonth(
    params: IParamsGetCalendar
  ): Promise<IApiResponseGetEvents> {
    return this.dataSource.getEventsByMonth( params )
  }

  async getAvailableHoursInADay(
    params: IParamsGetCalendar
  ): Promise<IApiResponseGetAvailableHours> {
    return this.dataSource.getAvailableHoursInADay( params )
  }

  deleteNonAttendanceHours(
    office_id: string,
    idInterval: string
  ): Promise<IApiResponseSaveCalendarEvent> {
    return this.dataSource.deleteNonAttendanceHours( office_id, idInterval )
  }
}
