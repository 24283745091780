import { ExpandLess, ExpandMore } from '@mui/icons-material'
import { SxProps } from '@mui/material'
import dayjs from 'dayjs'
import React from 'react'
import { useNavigate } from 'react-router-dom'

import {
  ButtonsListStyled,
  ListActionButtons,
  ListActionStyled,
  ListPaper,
} from './AppointmentOptionsStyle'
import {
  CollapseWrapper,
  DividerWrapper,
  GridWrapper,
  ListItemButtonWrapper,
  ListItemTextWrapper,
  ListWrapper,
} from '../../../../components'
import { SecondaryMediumButton } from '../../../../components/SecondaryMediumButton/SecondaryMediumButton'
import SubmitButton from '../../../../components/SubmitButton'
import ClickAwayListenerWrapper from '../../../../components/wrapper/ClickAwayListenerWrapper'

import { useAppointmentStore } from '../../../../contexts/AppointmentState';
import {
  Appointment,
  IAppointmentInfo,
  IAppointmentsByDate,
} from '../../../../infrastructure/dtos/Appointments'

interface IAppointmentOptionsProps {
  item: Appointment | IAppointmentInfo
  list?: IAppointmentsByDate[]
  dateIndex?: number
  appointmentIndex?: number
  currentDate: dayjs.Dayjs
  percentWidth?: string[]
  actionsStyle?: SxProps
  handleOpenNotifyDelay(eventId: string): void
  handleOpenRecipeModal(): void
  handleOpenReschedule(): void
  hamdleChangeAppointment(data: Appointment): void
  handleChangeList(data: IAppointmentsByDate[]): void
  handleSetAppointmentInfo?(appointment: IAppointmentInfo): void
  handleOpenNextAppointment(): void
  handleOpenCancelAppointment: (value: boolean) => void
  setAppointment?: (data: Appointment) => void
}

const Actions: string[] = ['Datos', 'Empezar', 'Receta', 'Pedido', 'Certificado', 'Cancelar']

export function AppointmentOptions({
  item,
  list,
  dateIndex = 0,
  appointmentIndex = 0,
  currentDate,
  percentWidth = ['16%', '16%', '14%'],
  actionsStyle = {},
  handleOpenNotifyDelay,
  handleOpenRecipeModal,
  handleOpenReschedule,
  hamdleChangeAppointment,
  handleChangeList,
  handleOpenNextAppointment,
  handleSetAppointmentInfo,
  handleOpenCancelAppointment,
  setAppointment,
}: IAppointmentOptionsProps): React.JSX.Element {
  const { setAppointmentSelected, appointmentSelected } = useAppointmentStore()
  const navigate = useNavigate()
  const handleClickAction = (action: string | null): void => {
    switch (action) {
      case 'Receta':
        hamdleChangeAppointment(item)
        handleShowActions(!item?.showActions)
        handleOpenRecipeModal()
        break

      case 'Datos':
        if (setAppointment) {
          setAppointment(item)
        }
        setAppointmentSelected({ ...appointmentSelected, ...item })
        hamdleChangeAppointment(item)
        handleShowActions(!item?.showActions)
        navigate(
          `/dashboard/appointments/data/0/${item?.patient_id_number}/${item.appointment_id}/${item?.patient_id || ''}`,
          { state: item },
        )
        break

      case 'Cancelar':
        hamdleChangeAppointment(item)
        handleShowActions(!item?.showActions)
        handleOpenCancelAppointment(true)
        break

      case 'Empezar':
        if (setAppointment) {
          setAppointment(item)
        }
        setAppointmentSelected({ ...appointmentSelected, ...item })
        hamdleChangeAppointment(item)
        handleShowActions(!item?.showActions)
        navigate(
          `/dashboard/appointments/data/1/${item?.patient_id_number}/${item.appointment_id
          }/${item?.patient_id || ''}`,
        )
        break

      default:
        break
    }
  }

  const handleShowActions = (value: boolean): void => {
    if (list) {
      const newList: IAppointmentsByDate[] = [
        ...(list as IAppointmentsByDate[]),
      ]

      newList.map((date, dateIdx) => {
        date.appointments.map((apnmt: Appointment, apnmtIdx: number) => {
          apnmt.showActions =
            appointmentIndex === apnmtIdx && dateIdx === dateIndex
              ? value
              : false

          return apnmt
        })

        return date
      })
      handleChangeList(newList)
    } else {
      const newItem: IAppointmentInfo = { ...(item as IAppointmentInfo) }

      newItem.showActions = value
      handleSetAppointmentInfo?.(newItem)
    }
  }

  const handleClickAway = (): void => {
    if (list) {
      const newList: IAppointmentsByDate[] = [
        ...(list as IAppointmentsByDate[]),
      ]

        ; (
          newList[dateIndex].appointments[appointmentIndex] as Appointment
        ).showActions = false
      handleChangeList(newList)
    } else {
      const newItem: IAppointmentInfo = { ...(item as IAppointmentInfo) }

      newItem.showActions = false
      handleSetAppointmentInfo?.(newItem)
    }
  }

  const handleOpenRescheduleModal = (): void => {
    hamdleChangeAppointment(item)
    handleOpenReschedule()
  }

  const handleOpenNextAppointmentModal = (): void => {
    hamdleChangeAppointment(item)
    handleOpenNextAppointment()
  }

  const submitButtonText = (): string =>
    !dayjs(`${item?.appointment_date}T${item?.hour_from}`).isAfter(currentDate)
      ? 'Próxima cita'
      : 'Reagendar'

  const submitButtonId = (): string =>
    !dayjs(`${item.appointment_date}T${item.hour_from}`).isAfter(currentDate)
      ? 'next-date-button'
      : 'reschedule-button'

  const submitButtonAction = (): void => {
    if (
      dayjs(`${item?.appointment_date}T${item?.hour_from}`).isAfter(currentDate)
    ) {
      handleOpenRescheduleModal()
    }

    if (
      dayjs(`${item?.appointment_date}T${item?.hour_from}`).isBefore(
        currentDate,
      )
    ) {
      handleOpenNextAppointmentModal()
    }
  }

  const submitButtonDisabled = (): boolean => {
    if (
      dayjs(`${item?.appointment_date}T${item?.hour_from}`).isAfter(currentDate)
    ) {
      return item?.status?.id === 1 || item.assistance !== 'PENDING'
    }
    return item?.status?.id === 1 || item.assistance === 'NOT_ASSISTED'
  }

  const secondaryMediumButtonDisabled = (): boolean =>
    dayjs(`${item?.appointment_date}T${item?.hour_from}`)
      .subtract(5, 'minute')
      .isBefore(currentDate) || item?.status.id === 1

  const showActionOption = (action: string): boolean => {
    return (
      (!dayjs(`${item?.appointment_date}T${item?.hour_from}`).isAfter(
        currentDate,
      ) &&
        ['Cancelar'].includes(action)) ||
      (action === 'Cancelar' && item?.assistance !== 'PENDING') ||
      (action === 'Facturar' && item?.assistance === 'NOT_ASSISTED') ||
      (item?.status.id === 1 && !['Datos'].includes(action)) ||
      (!dayjs(currentDate).isSame(item?.appointment_date, 'day') &&
        action === 'Empezar') ||
      (!dayjs(currentDate).isSame(item?.appointment_date, 'day') &&
        !dayjs(currentDate).isAfter(item?.appointment_date, 'day') &&
        action === 'Receta')
    )
  }

  const filteredOptions: string[] = Actions.filter(
    (action) => !showActionOption(action),
  )

  const actionButtonSx = (i: number): SxProps =>
    i === filteredOptions.length - 1
      ? {
        borderBottomLeftRadius: '25px',
        borderBottomRightRadius: '25px',
      }
      : {}

  return (
    <>
      <GridWrapper item textAlign={'center'} width={percentWidth[0]}>
        <SubmitButton
          id={submitButtonId()}
          data-testid={submitButtonId()}
          variant="contained"
          fullWidth={false}
          type="submit"
          disabled={submitButtonDisabled()}
          text={submitButtonText()}
          onClick={submitButtonAction}
          sx={{
            '&.Mui-disabled': {
              backgroundColor: 'tertiary.light',
              color: 'info.main',
            },
            width: '90%',
            '@media (max-width: 1600px)': {
              fontSize: '12px',
            },
            '@media (min-width: 1601px)': {
              fontSize: '14px',
            },
          }}
        />
      </GridWrapper>

      <GridWrapper item textAlign={'center'} width={percentWidth[1]}>
        <SecondaryMediumButton
          id="notify-delay-button"
          data-testid="notify-delay-button"
          text="Notificar atraso"
          type="submit"
          fullWidth={false}
          onClick={() => handleOpenNotifyDelay(item?.appointment_id)}
          disabled={secondaryMediumButtonDisabled()}
          sx={{
            // add style only when is disable
            '&.Mui-disabled': {
              backgroundColor: 'tertiary.light',
              color: 'info.main',
            },
            width: '90%',
            '@media (max-width: 1600px)': {
              fontSize: '12px',
            },
            '@media (min-width: 1601px)': {
              fontSize: '14px',
            },
          }}
        />
      </GridWrapper>
      <GridWrapper
        item
        textAlign={'center'}
        width={percentWidth[2]}
        display={'flex'}
        justifyContent={'center'}
        flexGrow={'initial'}
      >
        <ListActionStyled sx={actionsStyle}>
          <ListActionButtons>
            <ListItemButtonWrapper
              onClick={() => handleShowActions(!item?.showActions)}
              sx={{
                height: '55px',
                borderRadius: '32px',
                zIndex: 0,
                backgroundColor: '#faf9fd',
              }}
              data-testid="actions-button"
            >
              <ListItemTextWrapper
                primary="Acciones"
                sx={{
                  span: {
                    '@media (max-width: 1600px)': {
                      fontSize: '12px',
                    },
                    '@media (min-width: 1601px)': {
                      fontSize: '16px',
                    },
                  },
                }}
              />
              {item?.showActions ? <ExpandLess /> : <ExpandMore />}
            </ListItemButtonWrapper>
            <CollapseWrapper in={item?.showActions} unmountOnExit timeout={150}>
              <ClickAwayListenerWrapper onClickAway={handleClickAway}>
                <ListPaper>
                  <ListWrapper sx={{ color: '#000', zIndex: 100 }}>
                    {filteredOptions.map((action: string, i: number) => {
                      return (
                        <GridWrapper
                          key={`action-${dateIndex}-${appointmentIndex}-${i}`}
                          data-testid="actions-option"
                        >
                          <DividerWrapper variant="middle" flexItem />
                          <ButtonsListStyled
                            onClick={() => handleClickAction(action)}
                            data-testid={`${action}-action-button`}
                            sx={actionButtonSx(i)}
                          >
                            {action}
                          </ButtonsListStyled>
                        </GridWrapper>
                      )
                    })}
                  </ListWrapper>
                </ListPaper>
              </ClickAwayListenerWrapper>
            </CollapseWrapper>
          </ListActionButtons>
        </ListActionStyled>
      </GridWrapper>
    </>
  )
}
