import { PrescriptionsRepository } from '../../domain'
import { IPrescriptionsDataSource } from '../../infrastructure'
import {
  IApiPrescriptionInfo,
  IRecipePrescription,
  IResponseSavePrescription,
  IResponseSendPrescription,
  ISavePrescriptionProps,
  ISendPrescriptionProps,
} from '../dtos/Prescriptions'

export class PrescriptionsRepositoryImpl implements PrescriptionsRepository {
  dataSource: IPrescriptionsDataSource

  constructor(_datasource: IPrescriptionsDataSource) {
    this.dataSource = _datasource
  }

  async savePrescription(
    prescription: ISavePrescriptionProps,
  ): Promise<IResponseSavePrescription> {
    return this.dataSource.savePrescription(prescription)
  }

  async getAppointmentRecipes(
    appointmentId: string,
  ): Promise<IApiPrescriptionInfo<IRecipePrescription | string>> {
    return this.dataSource.getAppointmentRecipes(appointmentId)
  }

  async sendAppointmentRecipe(
    body: ISendPrescriptionProps,
  ): Promise<IResponseSendPrescription> {
    return this.dataSource.sendAppointmentRecipe(body)
  }
}
