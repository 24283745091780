import { AxiosError, AxiosResponse } from 'axios'

import {
  IDoctorBody,
  IDoctorUpdateResponse,
  IDoctorPrescriptionProps,
  IRoot,
  IDoctorPrescriptionResponse,
} from '../../../dtos/DoctorInfo'
import DoctorInfoDataSource from '../../dataSource/DoctorInfoDataSource'
import { http } from '../../Http'

export default class DoctorInfoMapper implements DoctorInfoDataSource {
  async updateDoctorInfo(
    params: IDoctorBody,
    idDoctor: string,
  ): Promise<IDoctorUpdateResponse> {
    try {
      const { data } = await http.put<IDoctorUpdateResponse>(
        `modified_data/doctor/${idDoctor}`,
        { ...params },
      )

      return data
    } catch (error: unknown) {
      const data: IDoctorUpdateResponse = {
        statusCode: (error as AxiosError).response?.status as number,
        body: (error as AxiosError).response?.statusText as string,
        headers: {
          'Access-Control-Allow-Headers': '',
          'Access-Control-Allow-Origin': '',
          'Access-Control-Allow-Methods': '',
        },
      }

      return data
    }
  }

  async getDoctorInfo(idDoctor: string): Promise<IRoot> {
    const data = await http
      .get<IRoot>(`/send_data/data_medical/${idDoctor}`)
      .then((response: AxiosResponse) => {
        const { data } = response

        return data
      })
      .catch((error: AxiosError) => {
        const data: IRoot = {
          statusCode: error.response?.status as number,
          body: error.response?.statusText as string,
          headers: {
            'Access-Control-Allow-Headers': '',
            'Access-Control-Allow-Origin': '',
            'Access-Control-Allow-Methods': '',
            'Access-Control-Allow-Credentials': false,
          },
        }

        return data
      })

    return data
  }

  async getDoctorAndAppointmentInfo({
    appointmentId,
    officeId,
  }: IDoctorPrescriptionProps): Promise<IDoctorPrescriptionResponse> {
    try {
      const data: AxiosResponse = await http.get(
        `/send_data/doctor_of_prescription/${appointmentId}/${officeId}`,
      )

      return data.data
    } catch (error: unknown) {
      return {
        statusCode: (error as AxiosError).response?.status as number,
        body: {
          message: (error as AxiosError).response?.statusText as string,
          data: {},
        },
        headers: {
          'Access-Control-Allow-Headers': '',
          'Access-Control-Allow-Origin': '',
          'Access-Control-Allow-Methods': '',
        },
      }
    }
  }
}
