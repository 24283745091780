import CloseIcon from '@mui/icons-material/Close'
import Backdrop from '@mui/material/Backdrop'
import React, { useEffect } from 'react'

import { useCreateOffice } from './useCreateOffice'
import {
  GridWrapper,
  InputAdornmentWrapper,
  ModalWrapper,
} from '../../../../../../../components'
import FormTextInput from '../../../../../../../components/FormInputText'
import { GridItem } from '../../../../../../../components/GridItem/GridItem'
import { TypographyModal } from '../../../../../../../components/ModalComponent'
import { InputCurrencyStyled } from '../../../../../../../components/StyledComponents/InputCurrencyStyled'
import { LabelInput } from '../../../../../../../components/StyledComponents/LabelInput'
import { LabelInputError } from '../../../../../../../components/StyledComponents/LabelInputError'
import SubmitButton from '../../../../../../../components/SubmitButton'
import { useCreateOfficeStore } from '../../../../../../../contexts/NewOfficeState'
import { validateInput } from '../../../../../../../utils/validateInput'
import AutocompleteGoogleMaps from '../../../../../../medical/ProfileMedical/RegisterConsultory/components/MapComponents/AutocompleteGoogle'
import { MapsGoogle } from '../../../../../../medical/ProfileMedical/RegisterConsultory/components/MapComponents/MapsGoogle'
import {
  BoxModal,
  CloseButton,
  FadeModal,
  GridModal,
} from '../EditOffice/EditOfficeStyles'

interface ICreateOfficeProps {
  open: boolean
  handleClose: () => void
  setStep: React.Dispatch<React.SetStateAction<number>>
}

export const CreateOffice = ({
  open,
  handleClose,
  setStep,
}: ICreateOfficeProps): JSX.Element => {
  const {
    address,
    setMarkerPosition,
    setAddress,
    setZoom,
    setMap,
    setConsultingFloor,
    setConsultingRoom,
    setMapCenter,
    consultingFloor,
    map,
    consultingOffice,
    consultingRoom,
    markerPosition,
    MAX_LENGTH,
    zoom,
    appointmentPrice,
    selected,
    value,
    isLoaded,
    setConsultingOffice,
    mapCenter,
    disabledButton,
    setSelected,
    setValue,
    handleClickAutocomplete,
    handleSetAppointmentPrice,
  } = useCreateOffice()

  const { setOffice, clearAll } = useCreateOfficeStore()

  useEffect(() => {
    if (!address) {
      setValue(null)
    }
  }, [address])

  return (
    <ModalWrapper
      aria-labelledby="modal-edit-office-title"
      aria-describedby="modal-edit-office-description"
      open={open}
      role="modal"
      onClose={() => {
        clearAll()
        handleClose()
      }}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <FadeModal in={open}>
        <BoxModal width={{ xs: '90%', md: '75%' }}>
          <GridModal>
            <TypographyModal id="modal-edit-office-title" variant="h6">
              Crear Consultorio
            </TypographyModal>
            <CloseButton
              data-testid={'close-create-office'}
              onClick={() => {
                clearAll()
                handleClose()
              }}
            >
              <CloseIcon />
            </CloseButton>
            <GridWrapper
              container
              mt={3}
              display={'flex'}
              justifyContent={'center'}
              flexDirection={{ xs: 'column-reverse', md: 'row' }}
            >
              <GridItem xs={12} md={6} padding={'10px'}>
                <GridWrapper container rowGap={3} marginRight={'25px'}>
                  <GridItem
                    xs={12}
                    flexDirection={'column'}
                    alignItems={'start'}
                  >
                    <LabelInput sx={{ marginLeft: '10px' }}>
                      ¿Dónde atiendes a tus pacientes?
                    </LabelInput>
                    {isLoaded && (
                      <AutocompleteGoogleMaps
                        address={address}
                        setMarkerPosition={setMarkerPosition}
                        setZoom={setZoom}
                        setAddress={setAddress}
                        setMapCenter={setMapCenter}
                        isLoaded={isLoaded}
                        setSelected={setSelected}
                        selected={selected}
                        value={value}
                        setValue={setValue}
                        onClick={handleClickAutocomplete}
                      />
                    )}
                    {address?.length === 0 && (
                      <LabelInputError
                        sx={{ marginLeft: '10px' }}
                        id="address-helper-text"
                      >
                        Campo requerido.
                      </LabelInputError>
                    )
                    }
                  </GridItem>
                  <GridItem xs={12}>
                    <FormTextInput
                      id="consultingRoom"
                      type="text"
                      placeholder="Edificio"
                      fullWidth
                      label="Edificio"
                      name="consultingRoom"
                      value={consultingRoom}
                      onChange={(event) =>
                        setConsultingRoom(event.target.value)
                      }
                      error={
                        (!validateInput(consultingRoom) &&
                          consultingRoom?.length === 0) ||
                        consultingRoom?.length > MAX_LENGTH
                      }
                      helperText={
                        !validateInput(consultingRoom) &&
                        consultingRoom?.length === 0
                          ? 'Campo requerido.'
                          : consultingRoom?.length > MAX_LENGTH
                            ? `Máximo número de caracteres ${MAX_LENGTH}`
                            : ''
                      }
                      inputProps={{ maxLength: MAX_LENGTH + 1 }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={6} pr={0.1}>
                    <FormTextInput
                      id="consultingFloor"
                      type="text"
                      placeholder="Piso"
                      label="Piso"
                      fullWidth
                      name="consultingFloor"
                      value={consultingFloor}
                      onChange={(event) =>
                        setConsultingFloor(event.target.value)
                      }
                      error={
                        (!validateInput(consultingFloor) &&
                          consultingFloor?.length === 0) ||
                        consultingFloor?.length > MAX_LENGTH
                      }
                      helperText={
                        !validateInput(consultingFloor) &&
                        consultingFloor?.length === 0
                          ? 'Campo requerido.'
                          : consultingFloor?.length > MAX_LENGTH
                            ? `Máximo número de caracteres ${MAX_LENGTH}`
                            : ''
                      }
                      inputProps={{ maxLength: MAX_LENGTH + 1 }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={6} pl={0.5}>
                    <FormTextInput
                      id="consultingOffice"
                      type="text"
                      placeholder="Consultorio"
                      fullWidth
                      label="Consultorio"
                      name="consultingOffice"
                      value={consultingOffice}
                      onChange={(event) =>
                        setConsultingOffice(event.target.value)
                      }
                      error={
                        (!validateInput(consultingOffice) &&
                          consultingOffice?.length === 0) ||
                        consultingOffice?.length > MAX_LENGTH
                      }
                      helperText={
                        !validateInput(consultingOffice) &&
                        consultingOffice?.length === 0
                          ? 'Campo requerido.'
                          : consultingOffice?.length > MAX_LENGTH
                            ? `Máximo número de caracteres ${MAX_LENGTH}`
                            : ''
                      }
                      inputProps={{ maxLength: MAX_LENGTH + 1 }}
                    />
                  </GridItem>
                  <GridItem
                    xs={12}
                    flexDirection={'column'}
                    alignItems={'start'}
                  >
                    <LabelInput sx={{ marginLeft: '10px' }}>
                      ¿Cuánto cuesta tu consulta?
                    </LabelInput>
                    <InputCurrencyStyled
                      placeholder="Ingresa el monto"
                      id="appointment_price"
                      name="appointment_price"
                      type="number"
                      fullWidth
                      value={appointmentPrice}
                      onChange={(event) =>
                        handleSetAppointmentPrice(event.target.value)
                      }
                      startAdornment={
                        <InputAdornmentWrapper position="start">
                          $
                        </InputAdornmentWrapper>
                      }
                      error={
                        appointmentPrice?.length === 0 ||
                        appointmentPrice?.length > MAX_LENGTH
                      }
                      inputProps={{ maxLength: MAX_LENGTH + 1 }}
                    />
                    {appointmentPrice?.length === 0 ? (
                      <LabelInputError
                        sx={{ marginLeft: '10px' }}
                        id="appointment_price-helper-text"
                      >
                        Campo requerido.
                      </LabelInputError>
                    ) : appointmentPrice?.length > MAX_LENGTH ? (
                      <LabelInputError
                        sx={{ marginLeft: '10px' }}
                        id="appointment_price-helper-text"
                      >
                        {`Máximo número de caracteres ${MAX_LENGTH}`}
                      </LabelInputError>
                    ) : null}
                  </GridItem>
                </GridWrapper>
              </GridItem>
              <GridItem xs={12} md={6}>
                <MapsGoogle
                  map={map}
                  markerPosition={markerPosition}
                  zoom={zoom}
                  address={address}
                  mapCenter={mapCenter}
                  setMap={setMap}
                  setMarkerPosition={setMarkerPosition}
                  setZoom={setZoom}
                  setAddress={setAddress}
                  setMapCenter={setMapCenter}
                  isLoaded={isLoaded}
                  setSelected={setSelected}
                />
              </GridItem>
            </GridWrapper>
            <GridWrapper container mt={3}>
              <GridItem xs={12} data-testid="grid-send-update-office">
                <SubmitButton
                  data-testid={'send-update-office'}
                  id={'send-update-office'}
                  variant={'contained'}
                  fullWidth={false}
                  type={'button'}
                  text={'Avanza a configurar tus horarios'}
                  sx={{ width: '290px' }}
                  disabled={disabledButton}
                  onClick={() => {
                    setStep(1)
                    setOffice({
                      building_floor: consultingFloor,
                      office_number: consultingOffice,
                      consultationValue: appointmentPrice,
                      address: address,
                      coordinates: {
                        latitude: markerPosition?.lat() as number,
                        longitude: markerPosition?.lng() as number,
                      },
                      building_name: consultingRoom,
                      id: '1',
                      value: value,
                    })
                  }}
                />
              </GridItem>
            </GridWrapper>
          </GridModal>
        </BoxModal>
      </FadeModal>
    </ModalWrapper>
  )
}
