import { styled } from '@mui/material/styles'

import { FormControlWrapper } from '../wrapper'

interface IFormControlProps {
  error?: boolean
}

export const FormControlSelect = styled(FormControlWrapper)<IFormControlProps>(
  ({ theme, error }) => ({
    minWidth: '70%',
    borderRadius: '52px',
    height: '50px',
    '@media (min-width: 1600px)': {
      height: '57px',
    },
    backgroundColor: theme.palette.common.white,
    border: error ? `1px solid ${theme.palette.error.main}` : 'none',
    boxShadow: '1px 1px 7px 7px rgba(0, 0, 0, 0.03)',
    '& fieldset': { border: 'none' },
    '& .MuiSelect-select': {
      marginLeft: '8px',
      fontSize: '12px',
      '@media (min-width: 1600px)': {
        fontSize: '16px',
      },
    },
  }),
)
