import { useEffect, useState } from 'react'

import { IMedication, IPrescription, IUnits } from '../../types'

type ReactType<T> = React.Dispatch<React.SetStateAction<T>>
interface IUsePrescriptionProps {
  prescription?: IPrescription
  onChangePrescription(value: IPrescription): void
  onNext(): void
  onBack(): void
}

interface IUsePrescriptionHook {
  quantity: string
  medication: string
  dose: string
  presentation: string
  prescriptions: string
  recipes: IMedication[]
  indexToEdit: number
  disable: boolean
  addButtonText: string
  Units: IUnits[]
  mu: string
  addQuantity(): void
  substractQuantity(): void
  setQuantity: ReactType<string>
  setMedication: ReactType<string>
  setDose: ReactType<string>
  setPresentation: ReactType<string>
  setPrescriptions: ReactType<string>
  setDisable: ReactType<boolean>
  setMu: ReactType<string>
  addList: (event: { preventDefault: () => void }) => void
  handleNext: () => void
  handleBack: () => void
  handleEdit: (index: number) => void
  deleteRecipe: (index: number) => void
  handleSaveEditPrescription: (data: IMedication) => void
}

export function usePrescription({
  prescription,
  onChangePrescription,
  onBack,
  onNext,
}: IUsePrescriptionProps): IUsePrescriptionHook {
  const [disable, setDisable] = useState<boolean>(false)
  const [quantity, setQuantity] = useState<string>('0')
  const [medication, setMedication] = useState<string>('')
  const [dose, setDose] = useState<string>('')
  const [Units] = useState<IUnits[]>([
    { value: 'mg', text: 'miligramo' },
    { value: 'g', text: 'gramo' },
    { value: 'Kg', text: 'kilogramo' },
    { value: 'got', text: 'gota' },
    { value: 'L', text: 'litro' },
    { value: 'mL', text: 'mililitro' },
    { value: 'cc', text: 'centímetro cúbico' },
    { value: 'oz', text: 'onza' },
    { value: 'cda', text: 'cucharada' },
  ])
  const [mu, setMu] = useState<string>('')
  const [presentation, setPresentation] = useState<string>('')
  const [prescriptions, setPrescriptions] = useState<string>('')
  const [recipes, setRecipes] = useState<IMedication[]>([])
  const [indexToEdit, setIndexToEdit] = useState<number>(-1)

  const addQuantity = (): void => setQuantity(String(Number(quantity) + 1))
  const substractQuantity = (): void =>
    setQuantity(
      !quantity || Number(quantity) - 1 < 0
        ? quantity
        : String(Number(quantity) - 1),
    )

  const addList = (event: { preventDefault: () => void }): void => {
    event.preventDefault()
    setRecipes((previousLists) => [
      {
        id: Math.random(),
        medicine: medication,
        quantity: Number(quantity),
        dose: `${dose} ${mu}`,
        presentation,
        prescription: prescriptions,
      },
      ...previousLists,
    ])

    setMedication('')
    setDose('')
    setMu('')
    setPresentation('')
    setPrescriptions('')
    setQuantity('0')
  }

  const handleNext = (): void => {
    onChangePrescription({
      ...(prescription as IPrescription),
      recipe: recipes,
    })
    onNext()
  }

  const handleBack = (): void => {
    onChangePrescription({
      ...(prescription as IPrescription),
      recipe: recipes,
    })
    onBack()
  }

  useEffect(() => {
    if (prescription && prescription?.recipe) {
      setRecipes(prescription?.recipe)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleEdit = (index: number): void => {
    setIndexToEdit(index)
    setDisable(true)
  }

  const deleteRecipe = (index: number): void => {
    const newRecipes: IMedication[] = recipes
    newRecipes.splice(index, 1)
    setRecipes([...newRecipes])
  }

  const handleSaveEditPrescription = (data: IMedication): void => {
    const newRecipes: IMedication[] = recipes
    newRecipes[indexToEdit] = data
    setRecipes(newRecipes)
    setIndexToEdit(-1)
  }

  const addButtonText: string = recipes.length > 0 ? 'Agregar otra' : 'Agregar'

  return {
    quantity,
    medication,
    dose,
    presentation,
    prescriptions,
    recipes,
    indexToEdit,
    disable,
    addButtonText,
    mu,
    Units,
    addQuantity,
    substractQuantity,
    setQuantity,
    setMedication,
    setDose,
    setPresentation,
    setPrescriptions,
    setDisable,
    setMu,
    addList,
    handleNext,
    handleBack,
    handleEdit,
    deleteRecipe,
    handleSaveEditPrescription,
  }
}
