import {
  GetAppointment,
  GetAppointmentDetails,
  GetAppointmentInfo,
  GetAppointmentsByDoctorId,
  SaveNextAppointment,
} from '../../../domain/useCase/AppointmentUseCase'
import {
  IApiResponseAppointment,
  IAppointmentDetails,
  IAppointmentInfoResponse,
  IAppointmentsByDoctorIdParams,
  IAppointmentsByDoctorIdResponse,
  IGetAppointmentProps,
  IResponseAppointment,
  ISaveNextAppointment,
  ISaveNextAppointmentParams,
} from '../../../infrastructure/dtos/Appointments'
import AppointmentMapper from '../../../infrastructure/http/mappers/AppointmentMapper/AppointmentMapper'
import { AppointmentRepositoryImpl } from '../../../infrastructure/RepositoryImpl/AppointmentRepositoryImpl'

export interface IGetAppointmentDetailsService {
  status: number
  data: IApiResponseAppointment<IAppointmentDetails | string>
}

export interface IGetAppointmentInfoService {
  status: number
  data: IApiResponseAppointment<IAppointmentInfoResponse | string>
}

export interface ISaveNextAppointmentService {
  status: number
  data: IApiResponseAppointment<ISaveNextAppointment | string>
}

export interface IAppointmentService {
  status: number
  data: IApiResponseAppointment<IResponseAppointment | string>
}

export interface IGetAppointmentsByDoctorIdService {
  status: number
  data: IApiResponseAppointment<IAppointmentsByDoctorIdResponse>
}

export async function AppointmentService(
  params: IGetAppointmentProps,
): Promise<IAppointmentService> {
  try {
    const UseCaseRegistro = new GetAppointment(
      new AppointmentRepositoryImpl(new AppointmentMapper()),
    )

    return {
      status: 1,
      data: await UseCaseRegistro.invoke(params),
    }
  } catch (error: unknown) {
    return {
      status: 0,
      data: error as IApiResponseAppointment<string | IResponseAppointment>,
    }
  }
}

export async function getAppointmentDetailsService(
  appointmentId: string,
): Promise<IGetAppointmentDetailsService> {
  try {
    const UseCaseRegistro = new GetAppointmentDetails(
      new AppointmentRepositoryImpl(new AppointmentMapper()),
    )

    return {
      status: 1,
      data: await UseCaseRegistro.invoke(appointmentId),
    }
  } catch (error: unknown) {
    return {
      status: 0,
      data: error as IApiResponseAppointment<IAppointmentDetails | string>,
    }
  }
}

export async function getAppointmentInfoService(
  appointmentId: string,
): Promise<IGetAppointmentInfoService> {
  try {
    const UseCaseRegistro = new GetAppointmentInfo(
      new AppointmentRepositoryImpl(new AppointmentMapper()),
    )

    return {
      status: 1,
      data: await UseCaseRegistro.invoke(appointmentId),
    }
  } catch (error: unknown) {
    return {
      status: 0,
      data: error as IApiResponseAppointment<IAppointmentInfoResponse | string>,
    }
  }
}

export async function saveNextAppointmentService(
  params: ISaveNextAppointmentParams,
): Promise<ISaveNextAppointmentService> {
  try {
    const UseCaseRegistro = new SaveNextAppointment(
      new AppointmentRepositoryImpl(new AppointmentMapper()),
    )

    return {
      status: 1,
      data: await UseCaseRegistro.invoke(params),
    }
  } catch (error: unknown) {
    return {
      status: 0,
      data: error as IApiResponseAppointment<ISaveNextAppointment | string>,
    }
  }
}

export async function getAppointmentsByDoctorIdService(
  params: IAppointmentsByDoctorIdParams,
): Promise<IGetAppointmentsByDoctorIdService> {
  const UseCaseAppointmentsByDoctor = new GetAppointmentsByDoctorId(
    new AppointmentRepositoryImpl(new AppointmentMapper()),
  )

  return {
    status: 1,
    data: await UseCaseAppointmentsByDoctor.invoke(params),
  }
}
