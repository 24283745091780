import { AxiosError } from 'axios'

import { IApiResponseGetSkills } from '../../../dtos/Skills'
import { ISkillsDataSource } from '../../dataSource/SkillsDataSource'
import { http } from '../../Http'

export class SkillsMapper implements ISkillsDataSource {
  async getSkills(query: string): Promise<IApiResponseGetSkills> {
    try {
      const { data } = await http.get<IApiResponseGetSkills>(
        `send_data/skills_medical?query=${query}`,
      )
      return data
    } catch (error: unknown) {
      return {
        statusCode: (error as AxiosError)?.response?.status as number,
        body: {
          message: (error as AxiosError)?.response?.statusText as string,
          data: [],
        },
        headers: {
          'Access-Control-Allow-Headers': '',
          'Access-Control-Allow-Origin': '',
          'Access-Control-Allow-Methods': '',
        },
      }
    }
  }
}
