import { styled } from '@mui/material'

import { GridWrapper } from '../../../../components'

export const StyledForm = styled('form')(({ theme }) => ({
  width: '70%',
  [theme.breakpoints.down('lg')]: {
    width: '80%',
  },
  [theme.breakpoints.down('sm')]: {
    width: '90%',
  },
}))

export const GridItems = styled(GridWrapper)(({ theme }) => ({
  padding: 20,
  marginBottom: 20,
  marginTop: 20,
  background: theme.palette.common.white,
  borderRadius: '40px',
}))
