import { IApiResponseGetAppointmentDurations } from '../../infrastructure/dtos/AppointmentDurations'
import { IApiResponseGetAppointmentReasons } from '../../infrastructure/dtos/AppointmentReasons'
import { AppointmentsTypesRepository } from '../repository/AppointmentsTypesRepository'


export interface GetAppointmentDurationsUseCase {
  invoke(): Promise<IApiResponseGetAppointmentDurations>
}

export interface GetAppointmentReasonsUseCase {
  invoke(): Promise<IApiResponseGetAppointmentReasons>
}

export class GetAppointmentDurations implements GetAppointmentDurationsUseCase {
  private objectUseCase: AppointmentsTypesRepository

  constructor(_objectUseCase: AppointmentsTypesRepository) {
    this.objectUseCase = _objectUseCase
  }

  invoke(): Promise<IApiResponseGetAppointmentDurations> {
    return this.objectUseCase.getAppointmentDurations()
  }
}


export class GetAppointmentReasons implements GetAppointmentReasonsUseCase {
  private objectUseCase: AppointmentsTypesRepository

  constructor(_objectUseCase: AppointmentsTypesRepository) {
    this.objectUseCase = _objectUseCase
  }

  invoke(): Promise<IApiResponseGetAppointmentReasons> {
    return this.objectUseCase.getAppointmentReasons()
  }
}
