import { Box, BoxProps } from '@mui/material'
import React from 'react'

type BoxWrapperProps = Omit<BoxProps, 'ref'> & React.RefAttributes<HTMLElement>

const BoxWrapper: React.ForwardRefExoticComponent<BoxWrapperProps> =
  React.forwardRef<HTMLElement, BoxProps>((props, ref) => (
    <Box {...props} ref={ref} />
  ))

export default BoxWrapper
