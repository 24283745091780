import React, { useState } from 'react'
import { Route, Routes } from 'react-router-dom'

import Sidebar from '../components/Sidebar/Sidebar'
import { Appointment } from '../infrastructure/dtos/Appointments'
import Agreements from '../views/Dashboard/Agreements/Agreements'
import Appointments from '../views/Dashboard/Appointments/Appointments'
import { PatientData } from '../views/Dashboard/Appointments/Components/PatientData/PatientData'
import CalendarForm from '../views/Dashboard/CalendarForm/CalendarForm'
import { Profile } from '../views/Dashboard/Profile/Profile'

const SidebarLayout = (): JSX.Element => {
  const [appointmentInfo, setAppointmentInfo] = useState<
    Appointment | undefined
  >(undefined)
  const setAppointmentData = (data: Appointment): void => {
    setAppointmentInfo(data)
  }

  return (
    <>
      <Sidebar>
        <Routes>
          <Route path="index" element={<CalendarForm />} />
          <Route path="appointments">
            <Route
              path=""
              element={<Appointments setAppointment={setAppointmentData} />}
            />
            <Route
              path="data/:idScreen/:idNumber/:idAppointment/:idPatient?"
              element={<PatientData appointment={appointmentInfo} />}
            />
          </Route>
          <Route path="profile" element={<Profile />} />
          <Route path="agreements" element={<Agreements />} />
        </Routes>
      </Sidebar>
    </>
  )
}

export default SidebarLayout
