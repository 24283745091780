import { ISaveCodeRepository } from '../../domain/repository/SaveCodeRepository'
import { IApiResponseSaveCodeDoctor, ISaveCodeDoctor } from '../dtos/SaveCode'
import SaveCodeDataSource from '../http/dataSource/SaveCodeDataSource'

export class SaveCodeRepositoryImpl implements ISaveCodeRepository {
  dataSource: SaveCodeDataSource

  constructor(_datasource: SaveCodeDataSource) {
    this.dataSource = _datasource
  }

  async saveCode(
    dataSaveCode: ISaveCodeDoctor,
  ): Promise<IApiResponseSaveCodeDoctor> {
    return this.dataSource.saveCode(dataSaveCode)
  }
}
