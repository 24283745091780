import { styled } from '@mui/material';

import { BoxWrapper, CardContentWrapper, CardWrapper, TypographyWrapper } from '../../../../../components/wrapper';

export const CardAddAgreements = styled(CardWrapper)(() => ({
  height: '80px',
  background: 'white',
  borderRadius: '40px',
  marginBottom: '30px',
  boxShadow: '0px 3px 5px 3px rgba(0, 0, 0, 0.06)'
}))

export const CardContentAddAgreements = styled(CardContentWrapper)(() => ({
  display: 'flex',
  alignItems: 'center',
  padding: '12px 50px 10px 50px'
}))

export const TypographyAddAgreements = styled(TypographyWrapper)(() => ({
  '@media (max-width: 1600px)': {
    fontSize: '12px',
  },
  '@media (min-width: 1601px)': {
    fontSize: '16px',
  },
}))

export const BoxAgreements = styled(BoxWrapper)(() => ({
  display: 'flex',
  justifyContent: 'right',
  marginLeft: 'auto'
}))