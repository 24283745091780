import { useMediaQuery, useTheme } from '@mui/material'
import { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { useSpecialties } from './useSpecialties'
import { useYearsExperience } from './useYearsExperience'
import { AccountContext, AuthContextType } from '../../../../../contexts/AccountContext'
import { RegisterMedicalContext } from '../../../../../contexts/RegisterContext'
import { ISpecialty } from '../../../../../infrastructure/dtos'
import { useForm } from '../../../../../utils/useForm'
import { validateInput } from '../../../../../utils/validateInput'

interface IUseProfileMedicalForm1 {
  lists: ISpecialty[]
  amountRanges: string[]
  skills: string[]
  studies: string
  isExtraSmallSize: boolean
  errorTextArea: boolean
  selectedExperience: string
  specialty: string
  onChange: (value: string, field: 'selectedExperience') => void
  onSubmit: (event: {
    preventDefault: () => void;
  }) => void
  onBack: () => void
  setValidationFocus: React.Dispatch<React.SetStateAction<boolean>>
  setStudies: React.Dispatch<React.SetStateAction<string>>
  setSpecialty: React.Dispatch<React.SetStateAction<string>>
  setSkills: React.Dispatch<React.SetStateAction<string[]>>
}

export const useProfileMedicalForm1 = (): IUseProfileMedicalForm1 => {
  const { saveDoctorExperience, RegisterMedical } = useContext(
    RegisterMedicalContext,
  )
  const { handleAlert } = useContext(AccountContext) as AuthContextType
  const { lists } = useSpecialties(handleAlert)
  const { amountRanges } = useYearsExperience(handleAlert)
  const navigate = useNavigate()
  const [isExperienceListFilled, setIsExperienceListFilled] = useState<boolean>(false)
  const [studies, setStudies] = useState<string>('')
  const [specialty, setSpecialty] = useState<string>('')
  const [ValidationFocus, setValidationFocus] = useState<boolean>(false)
  const [skills, setSkills] = useState<string[]>([])

  const { selectedExperience, onChange } = useForm({
    selectedExperience: '',
  })

  const theme = useTheme()
  const isExtraSmallSize = useMediaQuery(theme.breakpoints.down('sm'))

  useEffect(() => {
    if (!isExperienceListFilled && amountRanges?.length > 0) {
      if (RegisterMedical?.studies !== undefined) {
        setStudies(RegisterMedical?.studies)
      }
      if (Boolean(RegisterMedical?.experience)) {
        onChange(RegisterMedical?.experience, 'selectedExperience')
      } else if (amountRanges && amountRanges?.length !== 0) {
        onChange(amountRanges[0], 'selectedExperience')
      }
      if (RegisterMedical?.specialty !== undefined) {
        setSpecialty(RegisterMedical?.specialty)
      }
      if (RegisterMedical?.skills !== undefined) {
        setSkills(RegisterMedical?.skills)
      }
      setIsExperienceListFilled(true)
    }
  }, [amountRanges])

  const updateContexts = (): void => {
    saveDoctorExperience({
      experience: selectedExperience,
      specialty: specialty,
      studies: studies,
      skills: skills,
    })
  }

  const onSubmit = (event: { preventDefault: () => void }): void => {
    event.preventDefault()
    updateContexts()
    navigate('/medico/register/registerConsultory')
  }

  const onBack = (): void => {
    updateContexts()
    navigate('/medico/register/profile')
  }

  const errorTextArea: boolean =
    !validateInput(studies) && studies?.length === 0 && ValidationFocus

  return {
    errorTextArea,
    isExtraSmallSize,
    lists,
    amountRanges,
    studies,
    selectedExperience,
    specialty,
    skills,
    setSkills,
    setSpecialty,
    onChange,
    setStudies,
    onBack,
    onSubmit,
    setValidationFocus,
  }
}
