import React from 'react'

import { AutocompleteStyled, FormControlStyled, TextFieldAutocomplete } from './HourInputStyle'

interface IHourInputProps {
  setValue: (valueData: string) => void
  day: string
  type: boolean,
  disabled: boolean,
  hour: string,
  hours: string[]
}

export default function HourInput(props: IHourInputProps): JSX.Element {
  const { disabled, type, hour, hours, day, setValue } = props;

  const dataTestIdText = (): string => type ? `${day}-from` : `${day}-to`

  return (
    <FormControlStyled>
      <AutocompleteStyled
        forcePopupIcon={false}
        value={hour || null}
        onChange={(event: React.SyntheticEvent<Element, Event>, value: unknown) => {
          setValue(value as string)
        }}
        options={hours}
        disabled={disabled}
        disableClearable
        renderInput={(params) => {
          return (
            <TextFieldAutocomplete
              {...params}
              variant="outlined"
              fullWidth
              data-testid={dataTestIdText()}
            />
          )
        }}
      />
    </FormControlStyled>
  );
}

