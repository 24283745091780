import { Tooltip, tooltipClasses, TooltipProps } from '@mui/material'
import { styled } from '@mui/material/styles'

export const DiagnosticTooltip = styled(
  ({ className, children, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }}>
      {children}
    </Tooltip>
  ),
)(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.tertiary.light,
    borderRadius: '30px',
    color: theme.palette.text.primary,
    fontSize: 11,
    '@media (min-width: 1600px)': {
      fontSize: 14,
    },
    padding: '10px 15px',
    height: '10%',
    width: '100%',
    flexShrink: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}))
