import { styled } from '@mui/material'

import {
  BoxWrapper,
  FadeWrapper,
  GridWrapper,
  TypographyWrapper
} from '../../../../../../../components/wrapper'

export const FadeModal = styled( FadeWrapper )(() => ({
  width: '35%',
  height: 'auto',
  overflowY: 'auto'
}))

export const BoxModal = styled( BoxWrapper )(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '400px',
  height: '400px',
  bgcolor: theme.palette.background.paper,
  background: 'white',
  borderRadius: '30px',
  p: 4,
  boxShadow: '2px 2px 18px 14px rgba(0, 0, 0, 0.03)'
}))

export const GridModal = styled( GridWrapper )(() => ({
  padding: '60px'
}))

export const TypographyModal = styled( TypographyWrapper )(( props ) => ({
  left: '50%',
  fontFamily: 'Helvetica',
  fontSize: '15px',
  fontWeight: 'bold',
  fontStretch: 'normal',
  fontStyle: 'normal',
  lineHeight: 'normal',
  letterSpacing: 'normal',
  textAlign: 'center',
  color: props.theme.palette.primary.dark
}))
