import { useContext, useEffect, useState } from 'react'

import { AccountContext, AuthContextType } from '../../../../../../contexts/AccountContext'
import { GetCatalogNotificationUtility } from '../../../../../../services/Contracts/Utility/CatalogUtility'

interface IUseCancelAppointment {
  message: string
  loading: boolean
  openCancelAppointmentModal: boolean
  handleOpenCancelAppointment: ( value: boolean ) => void
}

export const useCancelAppointment = (isFetched = false): IUseCancelAppointment => {
  const { handleAlert } = useContext(AccountContext) as AuthContextType
  const [message, setMessage] = useState<string>('')
  const [loading, setLoading] = useState<boolean>(false)
  const [openCancelAppointmentModal, setOpenCancelAppointmentModal] = useState<boolean>( false )

  const handleOpenCancelAppointment = ( value: boolean ): void => setOpenCancelAppointmentModal( value )

  const fetchCatalogNotificationUtility = async (): Promise<void> => {
    try {
      setLoading(true)
      const { data } = await GetCatalogNotificationUtility(
        'NotificationCancelledAppointmentMedical',
      )

      setMessage(data)
      setLoading(false)
    } catch (error: unknown) {
      setLoading(false)
      handleAlert(true, 'Error al obtener el mensaje de alerta', 'error')
    }
  }

  useEffect(() => {
    if (isFetched) {
      fetchCatalogNotificationUtility()
    }
  }, [])

  return {
    message,
    loading,
    openCancelAppointmentModal,
    handleOpenCancelAppointment,
  }
}
