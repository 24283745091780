import { OfficesRepository } from '../../domain'
import {
  BodySaveOfficeRequest,
  IApiResponseOffices,
  IApiResponseSaveOffice,
  IUpdateInfoOfficeParams,
  IUpdateInfoOfficeResponse,
} from '../dtos/Offices'
import { BodyModifyStatusOfficeRequest, IApiResponseModifyStatusOffice } from '../dtos/UpdateOffices'
import OfficesDataSource from '../http/dataSource/OfficesDataSource'

export class OfficesRepositoryImpl implements OfficesRepository {
  dataSource: OfficesDataSource

  constructor( _datasource: OfficesDataSource ) {
    this.dataSource = _datasource
  }

  async getOffices( idDoctor: string ): Promise<IApiResponseOffices> {
    return this.dataSource.getOffices( idDoctor )
  }

  async updateOffice( params: IUpdateInfoOfficeParams ): Promise<IUpdateInfoOfficeResponse> {
    return this.dataSource.updateOffice( params )
  }

  async updateOfficeStatus(body: BodyModifyStatusOfficeRequest): Promise<IApiResponseModifyStatusOffice> {
    return this.dataSource.updateOfficeStatus(body)
  }

  async saveNewOffice(body: BodySaveOfficeRequest): Promise<IApiResponseSaveOffice> {
    return this.dataSource.saveNewOffice(body)
  }
}
