import {
  Close as CloseIcon,
} from '@mui/icons-material'

import { BoxConfirmSchedule, CloseButton, FadeConfirmSchedule, TypographyConfirmSchedule } from './ConfirmScheduleStyle'
import {
  BackdropWrapper,
  GridWrapper,
  ModalWrapper,
  TypographyWrapper,
  WarningIcon,
} from '../../../../../../../components'
import SubmitButton from '../../../../../../../components/SubmitButton'

interface IConfirmScheduleProps {
  open: boolean
  confirm: boolean
  handleClose: () => void
  handleConfirm: () => void
  title: string
  subtitle: string
}

export const ConfirmSchedule = ({
  open, confirm, handleClose, handleConfirm, title, subtitle
}: IConfirmScheduleProps): JSX.Element => {
  return (
    <ModalWrapper
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      data-testid="modal-confirm-schedule"
      open={open}
      onClose={handleClose}
      closeAfterTransition
      slots={{ backdrop: BackdropWrapper }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <FadeConfirmSchedule in={open}>
        <BoxConfirmSchedule width={{ xs: '90%', md: '600px' }}>
          <GridWrapper padding={5}>
            <CloseButton onClick={handleClose}>
              <CloseIcon />
            </CloseButton>
            <GridWrapper sx={{paddingTop: '45px'}}>
              <TypographyConfirmSchedule
                id="confirm-schedule-title"
                variant="h6"
                data-testid="modal-confirm-schedule-title"
              >
                {title}
              </TypographyConfirmSchedule>
            </GridWrapper>
            <GridWrapper
              container
              display={'flex'}
              justifyContent={'center'}
              flexDirection={'row'}
              marginTop={'25px'}
            >
              <GridWrapper
                display={'flex'}
                justifyContent={'start'}
              >
              <WarningIcon sx={{
                color: 'primary.dark',
                fontSize: '28px',
                marginRight: '5px',
              }}/>
              <TypographyWrapper sx={{
                fontSize: '14px',
              }}>
                {subtitle}
              </TypographyWrapper>
              </GridWrapper>
              <SubmitButton
                id="confirmScheduleButton"
                text={'Confirmar y reagendar citas'}
                type="button"
                variant="contained"
                fullWidth
                onClick={handleConfirm}
                disabled={confirm}
                sx={{ width: '55%', marginY: '45px', marginX: '15px' }}
                disableElevation
              />
            </GridWrapper>
          </GridWrapper>
        </BoxConfirmSchedule>
      </FadeConfirmSchedule>
    </ModalWrapper>
  )
}

