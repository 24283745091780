import { IdDocumentValidators } from '../../../domain/useCase/ValidatorsUseCase'
import { IResponseApi, IResponseService } from '../../../infrastructure/dtos'
import { ISendValidationIdDocument } from '../../../infrastructure/dtos/Validation'
import ValidatorsMapper from '../../../infrastructure/http/mappers/ValidatorsMapper/ValidatorsMapper'
import { ValidatorsRepositoryImpl } from '../../../infrastructure/RepositoryImpl/ValidatorsRepositoryImpl'

export async function ValidatorsIdDocumentService(
  params: ISendValidationIdDocument,
): Promise<IResponseService<IResponseApi<string>>> {
  try {
    const useCase = new IdDocumentValidators(
      new ValidatorsRepositoryImpl(new ValidatorsMapper()),
    )

    return {
      status: 1,
      data: await useCase.invoke(params),
    }
  } catch (error: unknown) {
    return {
      status: 0,
      data: 'Error Service.',
    }
  }
}
