import {
  IApiResponseResetAssistanceAppointment,
  IApiResponseSaveAssistanceAppointment,
  SaveAssistanceAppointment
} from '../../infrastructure/dtos/Attendance'
import { AttendanceRepository } from '../repository/AttendanceRepository'

export interface AttendanceUseCase {
  invoke: (
    dataAttendance: SaveAssistanceAppointment
  ) => Promise<IApiResponseSaveAssistanceAppointment>
}

export interface ResetAttendanceUseCase {
  invoke: (
    appointmentId: string
  ) => Promise<IApiResponseResetAssistanceAppointment>
}

export class Attendance implements AttendanceUseCase {
  private objectUseCase: AttendanceRepository

  constructor( _objectUseCase: AttendanceRepository ) {
    this.objectUseCase = _objectUseCase
  }

  invoke(
    dataAttendance: SaveAssistanceAppointment
  ): Promise<IApiResponseSaveAssistanceAppointment> {
    return this.objectUseCase.saveAttendance( dataAttendance )
  }
}

export class ResetAttendance implements ResetAttendanceUseCase {
  private objectUseCase: AttendanceRepository

  constructor( _objectUseCase: AttendanceRepository ) {
    this.objectUseCase = _objectUseCase
  }

  invoke(
    appointmentId: string
  ): Promise<IApiResponseResetAssistanceAppointment> {
    return this.objectUseCase.resetAttendance( appointmentId )
  }
}
