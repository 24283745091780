import dayjs from 'dayjs'

import React, { useContext, useEffect, useState } from 'react'

import { GridWrapperModal } from './styles'
import { DividerWrapper, GridWrapper } from '../../../../../../../components'
import FormTextInput from '../../../../../../../components/FormInputText'

import SubmitButton from '../../../../../../../components/SubmitButton'
import {
  AccountContext,
  AuthContextType,
} from '../../../../../../../contexts/AccountContext'
import {useDoctorInformationStore} from '../../../../../../../contexts/DoctorInformationState';
import { IDoctorPrescription } from '../../../../../../../infrastructure/dtos/DoctorInfo'
import { SaveCodeUtility } from '../../../../../../../services/Contracts/Utility/SaveCodeUtility'
import { DiagnosticBody } from '../../../../DiagnosticBody/DiagnosticBody'
import { useDiagnostic } from '../../../../DiagnosticBody/DiagnosticBodyHook'
import { RecipeHeader } from '../../components/RecipeHeader'
import { IPrescription } from '../../types'

interface IDiagnosticProps {
  doctorAppointmentInfo: IDoctorPrescription
  prescription?: IPrescription
  onNext(): void
  onChangeMspCode(code: string): void
  onChangePrescription(value: IPrescription): void
}

export function Diagnostic({
  doctorAppointmentInfo,
  prescription,
  onNext,
  onChangeMspCode,
  onChangePrescription,
}: IDiagnosticProps): React.JSX.Element {
  const { handleAlert } = useContext(
    AccountContext,
  ) as AuthContextType
  const {doctorInformation, setDoctorInformation}=useDoctorInformationStore()

  const idDoctor: string = doctorInformation?.user_id as string
  const [code, setCode] = useState<string>('')
  const [loadingSaveCodeMsp, setLoadingSaveCodeMsp] = useState<boolean>(false)
  const [error, setError] = useState<boolean>(false)
  const [message, setMessage] = useState<string>('')

  const {
    diagnostics,
    diagnosticSelected,
    onChangeDiagnosticSelected,
    onAddDiagnostics,
    onDeleteDiagnostics,
    onSetDiagnostics,
  } = useDiagnostic()

  const saveDoctorCode = async (event: {
    preventDefault: () => void
  }): Promise<void> => {
    event.preventDefault()
    setLoadingSaveCodeMsp(true)
    setError(false)
    setMessage('')
    try {
      const { data, status } = await SaveCodeUtility({
        code_doctor: code,
        user_id: idDoctor,
      })

      if (status === 0) {
        setError(true)
        setMessage(data)
      } else {
        setDoctorInformation({
          ...doctorInformation,
          code_msp: code,
        })
        onChangeMspCode(code)
      }
      setLoadingSaveCodeMsp(false)
    } catch (error) {
      setLoadingSaveCodeMsp(false)
      handleAlert(true, 'Error al guardar el código MSP', 'error')
    }
  }

  const handleNext = (): void => {
    onChangePrescription({
      ...(prescription as IPrescription),
      diagnostic: diagnostics,
    })
    onNext()
  }

  useEffect(() => {
    if (prescription && prescription?.diagnostic) {
      onSetDiagnostics(prescription?.diagnostic)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <GridWrapper>
      <RecipeHeader
        doctorAppointmentInfo={doctorAppointmentInfo}
        code={code}
        error={error}
        message={message}
        saveDoctorCode={saveDoctorCode}
        setCode={setCode}
        loading={loadingSaveCodeMsp}
      />
      {doctorAppointmentInfo?.doctor?.msp_code && (
        <GridWrapper data-testid="diagnostic-form">
          <DividerWrapper variant="fullWidth" flexItem />
          <GridWrapperModal>
            <GridWrapper container marginTop={3} marginBottom={3}>
              <GridWrapper item xs={6}>
                <FormTextInput
                  id="patient-name"
                  type="text"
                  text="Nombre del paciente"
                  placeholder=""
                  fullWidth={false}
                  name={'patient-name'}
                  value={`${doctorAppointmentInfo?.patient?.name} ${doctorAppointmentInfo?.patient?.last_name}`}
                  disabled
                  sx={{
                    '& .MuiInputBase-root': {
                      color: 'text.primary',
                      width: '300px',
                      backgroundColor: 'info.light',
                      boxShadow: 0,
                    },
                  }}
                />
              </GridWrapper>
              <GridWrapper item xs={4}>
                <FormTextInput
                  id="appointment-date"
                  type="text"
                  text="Fecha"
                  placeholder=""
                  fullWidth={false}
                  name="appointment-date"
                  value={dayjs(new Date(Date.now())).format('DD/MM/YYYY')}
                  disabled
                  sx={{
                    '& .MuiInputBase-root': {
                      color: 'text.primary',
                      width: '300px',
                      backgroundColor: 'info.light',
                      boxShadow: 0,
                    },
                  }}
                />
              </GridWrapper>
            </GridWrapper>
            <DiagnosticBody
              diagnostics={diagnostics}
              diagnosticSelected={diagnosticSelected}
              onAddDiagnostics={onAddDiagnostics}
              onDeleteDiagnostics={onDeleteDiagnostics}
              onChangeDiagnosticSelected={onChangeDiagnosticSelected}
            />
          </GridWrapperModal>
          <GridWrapperModal
            marginTop={10}
            display={'flex'}
            justifyContent={'center'}
          >
            <SubmitButton
              id={''}
              variant={'contained'}
              data-testid="next-button"
              fullWidth
              type={'submit'}
              text={'Siguiente'}
              sx={{ width: '30%', borderRadius: '32px' }}
              onClick={handleNext}
              disabled={diagnostics && diagnostics.length === 0}
            />
          </GridWrapperModal>
        </GridWrapper>
      )}
    </GridWrapper>
  )
}
