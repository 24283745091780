import React, { ReactElement, ReactNode } from 'react'

import {
  GridWrapper,
  ListItemWrapper,
} from '../../../../../../../../components'
import { DiagnosticTooltip } from '../../../../../../../../components/DiagnosticTooltip/DiagnosticTooltip'
import { MedicalExamsItem } from '../../../../../../../../infrastructure/dtos/MedicalExamsItem'

interface Props {
  medicalExam: MedicalExamsItem
  gridBody: {
    value: string | ReactNode
    width: number
  }[]
}

export const MedicalExamItem = ({
  medicalExam,
  gridBody,
}: Props): ReactElement => {
  return (
    <ListItemWrapper
      sx={{
        height: '80px',
        background: 'white',
        borderRadius: '40px',
        margin: '10px 0',
        boxShadow: '6px 6px 8px 7px rgba(0, 0, 0, 0.04)',
        fontSize: '12px',
        '@media (min-width: 1600px)': {
          fontSize: '16px',
        },
      }}
      data-testid="patient-record-item-list"
    >
      <GridWrapper
        container
        display={'flex'}
        justifyContent={'center'}
        alignItems={'center'}
        sx={{
          color: 'common.black',
        }}
      >
        {gridBody.map((i, idx) => (
          <DiagnosticTooltip
            title={
              idx === 1 && Array.isArray(medicalExam.exams)
                ? medicalExam.exams.map((item) => `${item.name}`).join(', ')
                : ''
            }
            placement="top"
            data-testid={idx === 1 ? 'datos-tooltip' : null}
            key={`gridBody-${idx}`}
          >
            <GridWrapper
              key={`GridHeader-${idx}`}
              item
              textAlign={'center'}
              xs={i.width}
              id={medicalExam.id}
              paddingX={1}
              sx={
                idx === 1 && Array.isArray(medicalExam.exams)
                  ? {
                      cursor: 'pointer',
                    }
                  : {}
              }
            >
              {i.value}
            </GridWrapper>
          </DiagnosticTooltip>
        ))}
      </GridWrapper>
    </ListItemWrapper>
  )
}
