import { PostMedicalRegister } from '../../../domain/useCase/PostMedicalRegister'
import {
  IApiResponseSaveRegisterMedical,
  ISaveRegisterMedical,
} from '../../../infrastructure/dtos/MedicalRegister'
import MedicalRegisterMapper from '../../../infrastructure/http/mappers/MedicalRegisterMapper/MedicalRegisterMapper'
import { MedicalRegisterRepositoryImpl } from '../../../infrastructure/RepositoryImpl/MedicalRegisterRepositoryImpl'

export interface IPostMedicalRegisterService {
  status: number
  data: IApiResponseSaveRegisterMedical | string
}

export async function PostMedicalRegisterService(
  dataMedical: ISaveRegisterMedical,
): Promise<IPostMedicalRegisterService> {
  try {
    const UseCaseApi: PostMedicalRegister = new PostMedicalRegister(
      new MedicalRegisterRepositoryImpl(new MedicalRegisterMapper()),
    )

    return {
      status: 1,
      data: await UseCaseApi.invoke(dataMedical),
    }
  } catch (error: unknown) {
    return {
      status: 0,
      data: (error as Error).message,
    }
  }
}
