import {
  Attendance,
  ResetAttendance,
} from '../../../domain/useCase/AttendanceUseCase'
import {
  IApiResponseSaveAssistanceAppointment,
  SaveAssistanceAppointment,
} from '../../../infrastructure/dtos/Attendance'

import { IServiceResetAssistance } from '../../../infrastructure/dtos/Attendance'
import AttendanceMapper from '../../../infrastructure/http/mappers/AttendanceMapper/AttendanceMapper'
import { AttendanceRepositoryImpl } from '../../../infrastructure/RepositoryImpl/AttendanceRepositoryImpl'

interface ISaveAssistanceAppointment {
  status: number
  data: IApiResponseSaveAssistanceAppointment | string
}

export async function AttendanceService(
  dataAttendance: SaveAssistanceAppointment,
): Promise<ISaveAssistanceAppointment> {
  try {
    const UseCaseApi: Attendance = new Attendance(
      new AttendanceRepositoryImpl(new AttendanceMapper()),
    )

    return {
      status: 1,
      data: await UseCaseApi.invoke(dataAttendance),
    }
  } catch (error: unknown) {
    return {
      status: 0,
      data: (error as Error).message,
    }
  }
}

export async function ResetAttendanceService(
  appointmentId: string,
): Promise<IServiceResetAssistance> {
  try {
    const UseCaseApi: ResetAttendance = new ResetAttendance(
      new AttendanceRepositoryImpl(new AttendanceMapper()),
    )

    return {
      status: 1,
      data: await UseCaseApi.invoke(appointmentId),
    }
  } catch (error: unknown) {
    return {
      status: 0,
      data: {
        statusCode: 500,
        body: {
          message: 'Internal Server Error',
        },
        headers: {
          'Access-Control-Allow-Headers': '',
          'Access-Control-Allow-Origin': '',
          'Access-Control-Allow-Methods': '',
        },
      },
    }
  }
}
