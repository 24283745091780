// Componente numero 1

import { SxProps } from '@mui/material'
import React from 'react'

import { InputField } from '../StyledComponents/InputField'
import { LabelInput } from '../StyledComponents/LabelInput'

interface IInputProps {
  maxLength: number
}

export interface InputText {
  id: string
  type: string
  fullWidth?: boolean
  name: string
  value: string
  placeholder?: string
  label?: string
  text?: string
  onChange?: (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => void
  error?: boolean
  onBlur?: () => void
  helperText?: string
  disabled?: boolean
  sx?: SxProps
  inputProps?: IInputProps
  required?: boolean
  sxLabel?: SxProps
}

const FormTextInput = ({
  id,
  type,
  placeholder,
  label,
  fullWidth = false,
  name,
  value,
  text,
  onChange,
  error,
  onBlur,
  helperText,
  disabled = false,
  sx,
  required,
  inputProps,
  sxLabel = {},
}: InputText ): React.JSX.Element => {
  return (
    <>
      {text && <LabelInput sx={{
        marginLeft: 0,
        fontWeight: 'normal',
        fontSize: '12px',
        '@media (min-width: 1600px)': {
          fontSize: '16px',
        },
        ...sxLabel
      }}>{text}</LabelInput>}


      <InputField
        id={id}
        type={type}
        label={label}
        placeholder={placeholder}
        fullWidth={fullWidth}
        name={name}
        value={value}
        onChange={onChange}
        error={error}
        onBlur={onBlur}
        helperText={helperText}
        disabled={disabled}
        sx={sx}
        inputProps={inputProps}
        required={required}
      />
    </>
  )
}

export default FormTextInput
