import { SkillsRepository } from '../../domain/repository/SkillsRepository'
import { IApiResponseGetSkills } from '../dtos/Skills'
import { ISkillsDataSource } from '../http/dataSource/SkillsDataSource'

export class SkillsRepositoryImpl implements SkillsRepository {
  dataSource: ISkillsDataSource

  constructor(_datasource: ISkillsDataSource) {
    this.dataSource = _datasource
  }

  getSkills(query: string): Promise<IApiResponseGetSkills> {
    return this.dataSource.getSkills(query)
  }
}
