import { IApiSendNotificationResponseService } from '../../infrastructure/dtos/Notifications'
import { NotificationsRepository } from '../repository/Notifications'

export interface SendDelayNotificationUseCase {
  invoke: (
    user_id: string,
    office_id: string,
    appointment_id: string,
    message: string,
  ) => Promise<IApiSendNotificationResponseService>
}

export class SendDelayNotification implements SendDelayNotificationUseCase {
  private objectUseCase: NotificationsRepository

  constructor(_objectUseCase: NotificationsRepository) {
    this.objectUseCase = _objectUseCase
  }

  async invoke(
    user_id: string,
    office_id: string,
    appointment_id: string,
    message: string,
  ): Promise<IApiSendNotificationResponseService> {
    return this.objectUseCase.sendDelayNotification(
      user_id,
      office_id,
      appointment_id,
      message,
    )
  }
}
