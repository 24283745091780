import { UpdateOffices } from '../../../domain/useCase/UpdateOfficesUseCase'
import {
  IApiResponseModifiedDataOffice,
  IModifiedDataOffice,
} from '../../../infrastructure/dtos/UpdateOffices'
import { UpdateOfficesRepositoryImpl } from './../../../infrastructure/RepositoryImpl/UpdateOfficesRepositoryImpl'
import UpdateOfficesMapper from '../../../infrastructure/http/mappers/UpdateOfficesMapper/UpdateOfficesMapper'

export interface ISavePrescriptionService {
  status: number
  data: IApiResponseModifiedDataOffice
}

export async function UpdateOfficesService(
  office: IModifiedDataOffice,
): Promise<ISavePrescriptionService> {
  try {
    const UseCaseOffices: UpdateOffices = new UpdateOffices(
      new UpdateOfficesRepositoryImpl(new UpdateOfficesMapper()),
    )

    return {
      status: 1,
      data: await UseCaseOffices.invoke(office),
    }
  } catch (error: unknown) {
    return {
      status: 0,
      data: error as IApiResponseModifiedDataOffice,
    }
  }
}
