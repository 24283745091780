import {
  GetAppointmentDurationsUtilityResponse,
  IApiResponseGetAppointmentDurations,
} from '../../../infrastructure/dtos/AppointmentDurations'
import {
  GetAppointmentReasonsUtilityResponse,
  IApiResponseGetAppointmentReasons,
} from '../../../infrastructure/dtos/AppointmentReasons'
import { getAppointmentDurationsService, getAppointmentReasonsService } from '../Persistencia/AppointmentTypesService'


export const getAppointmentDurations = async (): Promise<GetAppointmentDurationsUtilityResponse> => {
  try {
    const { data } = await getAppointmentDurationsService()

    return {
      status: (data as IApiResponseGetAppointmentDurations).statusCode === 200 ? 1 : 0,
      data: (data as IApiResponseGetAppointmentDurations).body,
    }
  } catch (error: unknown) {
    return {
      status: 0,
      data: {
        message: (error as Error)?.message,
        data: [],
      },
    }
  }
}

export const getAppointmentReasons = async (): Promise<GetAppointmentReasonsUtilityResponse> => {
  try {
    const { data } = await getAppointmentReasonsService()

    return {
      status: (data as IApiResponseGetAppointmentReasons).statusCode === 200 ? 1 : 0,
      data: (data as IApiResponseGetAppointmentReasons).body,
    }
  } catch (error: unknown) {
    return {
      status: 0,
      data: {
        message: (error as Error)?.message,
        data: [],
      },
    }
  }
}
