import { FC, useContext, useState } from 'react'

import { ButtonCopy, TextFieldCopy } from './ClipboardStyle'
import { AccountContext, AuthContextType } from '../../contexts/AccountContext'

interface Props {
  text: string
}

const CopyToClipboardButton: FC<Props> = ({ text }) => {
  const { handleAlert } = useContext(AccountContext) as AuthContextType
  const [inputText] = useState<string>(
    `${process.env.REACT_APP_PATIENT_URL as string}${text}`,
  )

  const handleCopy = async (): Promise<void> => {
    handleAlert(true, 'Link copiado con éxito', 'success')
    try {
      await navigator.clipboard.writeText(inputText)
    } catch (err: unknown) {
      handleAlert(true, 'Error al copiar enlace', 'error')
    }
  }

  return (
    <>
      <TextFieldCopy
        disabled
        value={inputText}
        data-testid="input-text-to-copy"
        sx={{
          paddingLeft: '12px',
          '@media (max-width: 1600px)': {
            width: '170px',
          },
          '@media (min-width: 1601px)': {
            width: '320px',
          },
        }}
      />
      <ButtonCopy onClick={handleCopy} data-testid="button-copy">
        Copiar
      </ButtonCopy>
    </>
  )
}

export default CopyToClipboardButton
