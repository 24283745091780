import {
  IAppointmentDetails,
  IAppointmentInfoResponse,
  IAppointmentsByDoctorIdParams,
  IAppointmentsByDoctorIdResponse,
  ISaveNextAppointment,
  ISaveNextAppointmentParams,
} from '../../../infrastructure/dtos/Appointments'
import {
  IGetAppointmentDetailsService,
  IGetAppointmentInfoService,
  IGetAppointmentsByDoctorIdService,
  ISaveNextAppointmentService,
  getAppointmentDetailsService,
  getAppointmentInfoService,
  getAppointmentsByDoctorIdService,
  saveNextAppointmentService,
} from '../Persistencia/AppointmentService'

export interface IGetAppointmUtility<T> {
  status: number
  data: string | T
}

export async function getAppointmentDetailsUtility(
  appointmentId: string,
): Promise<IGetAppointmUtility<IAppointmentDetails>> {
  try {
    const { data, status }: IGetAppointmentDetailsService =
      await getAppointmentDetailsService(appointmentId)

    return {
      data: data.body,
      status: data.statusCode === 200 && status === 1 ? status : 0,
    }
  } catch (error: unknown) {
    return {
      status: 0,
      data: (error as IGetAppointmentDetailsService)?.data.body,
    }
  }
}

export async function getAppointmentInfoUtility(
  appointmentId: string,
): Promise<IGetAppointmUtility<IAppointmentInfoResponse>> {
  try {
    const { data, status }: IGetAppointmentInfoService =
      await getAppointmentInfoService(appointmentId)

    return {
      data: data.body,
      status: data.statusCode === 200 && status === 1 ? status : 0,
    }
  } catch (error: unknown) {
    return {
      status: 0,
      data: (error as IGetAppointmentInfoService)?.data.body,
    }
  }
}

export async function saveNextAppointmentUtility(
  params: ISaveNextAppointmentParams,
): Promise<IGetAppointmUtility<ISaveNextAppointment>> {
  try {
    const { data, status }: ISaveNextAppointmentService =
      await saveNextAppointmentService(params)

    return {
      data: data.body,
      status: data.statusCode === 200 && status === 1 ? status : 0,
    }
  } catch (error: unknown) {
    return {
      status: 0,
      data: (error as ISaveNextAppointmentService)?.data.body,
    }
  }
}

export async function getAppointmentsByDoctorIdUtility(
  params: IAppointmentsByDoctorIdParams,
): Promise<IGetAppointmUtility<IAppointmentsByDoctorIdResponse>> {
  try {
    const { data, status } = await getAppointmentsByDoctorIdService(params)

    return {
      data: data.body as IAppointmentsByDoctorIdResponse,
      status: data.statusCode === 200 && status === 1 ? status : 0,
    }
  } catch (error: unknown) {
    return {
      status: 0,
      data: (error as IGetAppointmentsByDoctorIdService).data.body as IAppointmentsByDoctorIdResponse,
    }
  }
}
