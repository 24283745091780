import { IGendersRepository } from '../../domain/repository/GendersRepository'
import { IGendersResponse } from '../dtos/Genders'
import GendersDataSource from '../http/dataSource/GendersDataSource'

export class GendersRepositoryImpl implements IGendersRepository {
  dataSource: GendersDataSource

  constructor(_dataSource: GendersDataSource) {
    this.dataSource = _dataSource
  }

  async getGenders(): Promise<IGendersResponse> {
    return this.dataSource.getGenders()
  }
}
