import { NotificationsRepository } from '../../domain/repository/Notifications';
import { IApiSendNotificationResponseService } from '../dtos/Notifications';
import NotificationsDataSource from '../http/dataSource/NotificationsDataSource';


export class NotificationsRepositoryImpl implements NotificationsRepository {
  dataSource: NotificationsDataSource;

  constructor( _datasource: NotificationsDataSource ) {
    this.dataSource = _datasource;
  }

  async sendDelayNotification(
    user_id: string,
    office_id: string,
    appointment_id: string,
    message: string
  ): Promise<IApiSendNotificationResponseService> {
    return this.dataSource.sendDelayNotification( user_id, office_id, appointment_id, message )
  }
}
