import { AxiosResponse } from 'axios'

import { IApiResponseParametersNotificationLateAppointment } from '../../../dtos/ParametersNotification'
import ParametersNotificationDataSource from '../../dataSource/ParametersNotificationDataSource'
import { http } from '../../Http'

export default class ParametersNotificationMapper
  implements ParametersNotificationDataSource
{
  async getParametersNotificationLateAppointment(): Promise<IApiResponseParametersNotificationLateAppointment> {
    const data = await http
      .get<IApiResponseParametersNotificationLateAppointment>(
        `send_data/notification_messages`,
      )
      .then((response: AxiosResponse) => {
        const { data } = response

        return data
      })
      .catch((error) => {
        const data: IApiResponseParametersNotificationLateAppointment = {
          statusCode: error.response.status,
          body: { notification_message: [] },
          headers: {
            'Access-Control-Allow-Headers': '',
            'Access-Control-Allow-Origin': '',
            'Access-Control-Allow-Methods': '',
          },
        }

        return data
      })

    return data
  }
}
