import { styled } from '@mui/material/styles'

import { ButtonWrapper, TextFieldWrapper } from '../wrapper'

export const TextFieldCopy = styled( TextFieldWrapper )(( props ) => ({
  '& .MuiInputLabel-root': {},
  '& .MuiInputBase-root': {
    backgroundColor: props.theme.palette.background.default,
    '@media (max-width: 1600px)': {
      fontSize: '11px',
    },
    '@media (min-width: 1601px)': {
      fontSize: '16px',
    },
    color: props.theme.palette?.text.primary,
    fontFamily: 'Helvetica',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    height: '40px',
  },
  '& fieldset': { borderColor: 'red', border: 'none' },
  '&::placeholder': {
    marginLeft: '1.875rem'
  }
}))

export const ButtonCopy = styled( ButtonWrapper )(( props ) => ({
  position: 'relative',
  bottom: '3px',
  '@media (max-width: 1600px)': {
    width: '80px',
    fontSize: '11px',
  },
  '@media (min-width: 1601px)': {
    fontSize: '16px',
    width: '100px',

  },
  fontWeight: 'bold',
  right: '60px',
  backgroundColor: props.theme.palette.common.white,
  borderRadius: '24px',
  height: '50px',
  textTransform: 'capitalize',
  color: props.theme.palette.primary.main,
  padding: '0 15px',
  transform: 'translateY(2.5px)',
  '&:hover': {
    backgroundColor: props.theme.palette.primary.main,
    color: props.theme.palette.common.white,
  },
  boxShadow: '1px 1px 7px 7px rgba(0, 0, 0, 0.06)'
}))
