import { VisibilityIntervalsRepository } from '../../domain/repository/VisibilityIntervalsRepository'
import { IApiResponseVisibilityIntervalsAWS } from '../dtos/VisibilityIntervals'
import IVisibilityIntervalsDataSource from '../http/dataSource/VisibilityIntervalsDataSource'

export class VisibilityIntervalsRepositoryImpl
  implements VisibilityIntervalsRepository
{
  dataSource: IVisibilityIntervalsDataSource

  constructor(_datasource: IVisibilityIntervalsDataSource) {
    this.dataSource = _datasource
  }

  async getVisibilityIntervals(): Promise<IApiResponseVisibilityIntervalsAWS> {
    return this.dataSource.getVisibilityIntervals()
  }
}
