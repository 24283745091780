import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import dayjs from 'dayjs'
import { ReactNode, useContext, useEffect, useMemo, useState } from 'react'
import {
  Location,
  NavigateFunction,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom'

import SearchForm from './components/SearchForm/SearchForm'
import { SidebarData, SidebarBottonList, ItemsInterface } from './SidebarData'
import {
  AppBarSide,
  CircularButton,
  ControlBar,
  Logo,
  NavButton,
  PlayIcon,
  ShareBoutton,
  SidebarBottonItemList,
  SidebarItemList,
  SidebarListItemText,
  SidebarShareIcon,
} from './SidebarStyle'
import BrandLogo from '../../assets/helthyLogo.png'
import { useAppointmentStore } from '../../contexts/AppointmentState';
import { CalendarContext } from '../../contexts/CalendarContext'
import { useDoctorInformationStore } from '../../contexts/DoctorInformationState'
import { useFiltersStore } from '../../contexts/FiltersState';
import { useSelectOffice } from '../../contexts/SelectOfficeState'
import { IMedicalOffice } from '../../infrastructure/dtos/Offices'
import CopyToClipboardButton from '../CopyToClipboard/CopyToClipboardButton'
import { ModalDate } from '../ModalDate'
import SelectOffice from '../SelectOffice'
import {
  BoxWrapper,
  CssBaselineWrapper,
  DrawerWrapper,
  ListItemTextWrapper,
  ListWrapper,
  StackWrapper,
  ToolbarWrapper,
  TypographyWrapper,
} from '../wrapper'

interface ISidebarProps {
  children?: ReactNode
}
const drawerWidth = 260

const Sidebar = ({ children }: ISidebarProps): JSX.Element => {
  const { filtersSelected, setFiltersSelected } = useFiltersStore()
  const params = useParams()
  const [currentPath, setCurrentPath] = useState<string>('')
  const { getEvents, currentCalendarDateToShow } = useContext(CalendarContext)
  const { appointmentSelected } = useAppointmentStore()
  const { officeSelected } = useSelectOffice()
  const { doctorInformation } = useDoctorInformationStore()
  const [bottomItem, setBottomItem] = useState<number>(0)
  const [currentTitle, setCurrentTitle] = useState<string>('Calendario')
  const location: Location = useLocation()
  const navigate: NavigateFunction = useNavigate()
  const currentDate = dayjs(new Date())
  const disabledStartAttention = !dayjs(currentDate).isSame(
    appointmentSelected?.appointment_date,
    'day',
  )

  const patientPath = useMemo(() => {
    const userId = doctorInformation?.user_id

    return `medical-consultation/${userId}`
  }, [doctorInformation?.user_id])

  const onBack = (): void => {
    navigate('/dashboard/appointments')
  }

  const handleStartAttention = (): void => {
    const ids: string = params['*']?.substring(20) as string

    navigate(`/dashboard/appointments/data/1/${ids}`)
  }

  const handleNavigation = (path: string): void => {
    if (currentPath === '/dashboard/appointments') {
      setFiltersSelected({
        ...filtersSelected,
        isSubmitted: true,
      })
    }
    if (currentPath === '/dashboard/index') {
      const office = (officeSelected as IMedicalOffice[])?.find(
        (ofc: IMedicalOffice) => ofc.selected,
      )
      getEvents(
        (currentCalendarDateToShow as dayjs.Dayjs).format('YYYY-MM-DD'),
        (office as IMedicalOffice)?.office_id,
        doctorInformation?.user_id as string,
      )
    }
    navigate(path, {
      state: {
        from: currentPath,
      },
    })
  }

  useEffect(() => {
    const index: number = SidebarData.findIndex(
      (x) => x.path === location.pathname,
    )

    if (index >= 0) {
      setCurrentTitle(SidebarData[index].title)
    } else if (location.pathname.includes('/dashboard/appointments/data/')) {
      setCurrentTitle('Datos')
    }
    setCurrentPath(location.pathname)
    window.scrollTo(0, 0)
  }, [location.pathname])

  return (
    <BoxWrapper sx={{ display: 'flex' }}>
      <CssBaselineWrapper />
      <AppBarSide
        position="fixed"
        sx={{ width: `calc(100% - ${drawerWidth}px)`, ml: `${drawerWidth}px` }}
      >
        <ControlBar>
          {currentTitle === 'Datos' ? (
            <CircularButton onClick={onBack} sx={{ height: '60px' }}>
              <ChevronLeftIcon sx={{ fontSize: '35px' }} />
            </CircularButton>
          ) : null}
          <TypographyWrapper variant="h6" noWrap id="title">
            {currentTitle}
          </TypographyWrapper>
          <CopyToClipboardButton text={patientPath} />
          <>
            <StackWrapper
              spacing={2}
              direction="row"
              sx={{ marginLeft: 'auto', height: '50px' }}
              alignItems={'center'}
              alignContent={'center'}
            >
              {currentTitle !== 'Perfil' ? (
                <>
                  {currentTitle !== 'Datos' && currentTitle !== 'Mi Red' ? (
                    <>
                      <SelectOffice />
                      {currentTitle === 'Citas' ? <SearchForm /> : null}
                    </>
                  ) : null}
                  {currentTitle !== 'Citas' &&
                    currentTitle !== 'Calendario' &&
                    currentTitle !== 'Pacientes' &&
                    currentTitle !== 'Mi Red' ? (
                    <NavButton
                      onClick={handleStartAttention}
                      disabled={disabledStartAttention}
                      data-testid="begin attention"
                    >
                      <PlayIcon />
                      Empezar atención
                    </NavButton>
                  ) : null}
                  <ModalDate />
                </>
              ) : null}
            </StackWrapper>
          </>
        </ControlBar>
      </AppBarSide>
      <DrawerWrapper
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
            backgroundColor: 'primary.main',
            borderRadius: '0 53px 53px 0',
          },
        }}
        variant="permanent"
        anchor="left"
      >
        <Logo>
          <img src={BrandLogo} width="200" height="auto" alt="Brand logo" />
        </Logo>
        <ShareBoutton>
          <SidebarShareIcon />
          <ListItemTextWrapper>Compartir mi Link</ListItemTextWrapper>
        </ShareBoutton>
        <SidebarItemList>
          {SidebarData.map((item: ItemsInterface) => (
            <ListWrapper key={item.id}>
              <SidebarBottonItemList
                key={`sidebarOption-${item.id}`}
                data-testid={`sidebarOption-${item.title}`}
                onClick={() => {
                  handleNavigation(item.path)
                }}
                selected={location.pathname === item.path}
              >
                {item.icon}
                <SidebarListItemText primary={item.title} />
              </SidebarBottonItemList>
            </ListWrapper>
          ))}
        </SidebarItemList>
        <SidebarItemList sx={{ marginTop: 'auto' }}>
          {SidebarBottonList.map((item) => (
            <ListWrapper key={item.id}>
              <SidebarBottonItemList
                key={`sidebarOption2-${item.id}`}
                selected={item.id === bottomItem}
                onClick={() => setBottomItem(item.id)}
              >
                {item.icon}
                <SidebarListItemText
                  primary={item.title}
                  onClick={() => navigate(item.path)}
                />
              </SidebarBottonItemList>
            </ListWrapper>
          ))}
        </SidebarItemList>
      </DrawerWrapper>
      <BoxWrapper
        // component="main"
        sx={{ flexGrow: 1, bgcolor: 'background.default', pt: 5 }}
      >
        <ToolbarWrapper />
        {children}
      </BoxWrapper>
    </BoxWrapper>
  )
}

export default Sidebar
