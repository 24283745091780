import {
  IDoctorBody,
  IDoctorUpdateResponse,
  IDoctorPrescriptionProps,
  IRoot,
  IDoctorPrescriptionResponse,
} from '../../infrastructure/dtos/DoctorInfo'
import { IDoctorInfoRepository } from '../repository/DoctorInfoRepository'

export interface IGetDoctorInfoUseCase {
  invoke: (idDoctor: string) => Promise<IRoot>
}

export interface IGetDoctorAndAppointmentInfoUseCase {
  invoke: (params: IDoctorPrescriptionProps) => Promise<IDoctorPrescriptionResponse>
}

export interface IUpdateDoctorInfoUseCase {
  invoke: (
    params: IDoctorBody,
    idDoctor: string,
  ) => Promise<IDoctorUpdateResponse>
}

export class UpdateDoctorInfo implements IUpdateDoctorInfoUseCase {
  private objectUseCase: IDoctorInfoRepository

  constructor(_objectUseCase: IDoctorInfoRepository) {
    this.objectUseCase = _objectUseCase
  }

  async invoke(
    params: IDoctorBody,
    idDoctor: string,
  ): Promise<IDoctorUpdateResponse> {
    return this.objectUseCase.updateDoctorInfo(params, idDoctor)
  }
}

export class GetDoctoInfo implements IGetDoctorInfoUseCase {
  private objectUseCase: IDoctorInfoRepository

  constructor(_objectUseCase: IDoctorInfoRepository) {
    this.objectUseCase = _objectUseCase
  }

  async invoke(idDoctor: string): Promise<IRoot> {
    return this.objectUseCase.getDoctorInfo(idDoctor)
  }
}

export class GetDoctorAndAppointmentInfo
  implements IGetDoctorAndAppointmentInfoUseCase
{
  private objectUseCase: IDoctorInfoRepository

  constructor(_objectUseCase: IDoctorInfoRepository) {
    this.objectUseCase = _objectUseCase
  }

  async invoke(params: IDoctorPrescriptionProps): Promise<IDoctorPrescriptionResponse> {
    return this.objectUseCase.getDoctorAndAppointmentInfo(params)
  }
}
