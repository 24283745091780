import { ICancelAppointmentBodyResponse, ICancelAppointmentResponse } from '../../../infrastructure/dtos/CancelAppointment'
import { CancelAppointmentService, ICancelAppointmentService, ICancelService } from '../Persistencia/CancelAppointmentService'

export interface ICancelAppointmentUtility {
  status: number
  data: ICancelAppointmentBodyResponse | string
}

export async function cancelAppointmentUtility(
  params: ICancelService,
): Promise<ICancelAppointmentUtility> {
  try {
    const { data, status }: ICancelAppointmentService =
      await CancelAppointmentService(params)

    return {
      data: (data as ICancelAppointmentResponse).body,
      status: (data as ICancelAppointmentResponse).statusCode === 200 && status === 1 ? status : 0,
    }
  } catch (error: unknown) {
    return {
      status: 0,
      data: ((error as ICancelAppointmentService)?.data as ICancelAppointmentResponse).body,
    }
  }
}