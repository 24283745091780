import { Close } from '@mui/icons-material'
import { Backdrop } from '@mui/material'
import dayjs from 'dayjs'
import React, { useContext, useEffect, useState } from 'react'

import {
  BoxAppointmentDetails,
  CloseButton,
  FadeAppointmentDetails,
  GridModal,
  TypographyTitle,
  TypographyTitleModal
} from './AppointmentDetailsModalStyle'
import { GridWrapper, ModalWrapper } from '../../../../../components'
import { AccountContext, AuthContextType } from '../../../../../contexts/AccountContext'
import {
  Appointment,
  IAppointmentInfo,
  IAppointmentInfoResponse
} from '../../../../../infrastructure/dtos/Appointments'
import { getAppointmentInfoUtility } from '../../../../../services/Contracts/Utility/AppointmentUtility'
import { AppointmentOptions } from '../../../components/AppointmentOptions/AppointmentOptions'

interface IAppointmentDetailsModalProps {
  openAppointmentDetailsModal: boolean
  appointmentId: string
  handleOpenAppointmentDetailsModal( value: boolean ): void
  hamdleChangeAppointment( data: Appointment ): void
  handleOpenNotifyDelay( eventId: string ): void
  handleOpenRecipeModal(): void
  handleOpenReschedule(): void
  handleOpenNextAppointment(): void
  handleOpenCancelAppointment(): void
}

export function AppointmentDetailsModal({
  openAppointmentDetailsModal,
  appointmentId,
  handleOpenAppointmentDetailsModal,
  hamdleChangeAppointment,
  handleOpenNotifyDelay,
  handleOpenRecipeModal,
  handleOpenReschedule,
  handleOpenNextAppointment,
  handleOpenCancelAppointment,
}: IAppointmentDetailsModalProps ): React.JSX.Element {
  const { handleAlert } = useContext(AccountContext) as AuthContextType
  const [appointmentInfo, setAppointmentInfo] = useState<IAppointmentInfo>()
  const [currentDate, setCurrentDate] = useState<dayjs.Dayjs>( dayjs( new Date()))

  const handleSetAppointmentInfo = ( appointment: IAppointmentInfo ): void =>
    setAppointmentInfo( appointment )

  const fetchAppointmentInfoUtility = async (
    appointmentId: string
  ): Promise<void> => {
    try {
      const { data } = await getAppointmentInfoUtility(appointmentId)
      handleSetAppointmentInfo((data as IAppointmentInfoResponse)?.appointment)
    } catch (error: unknown) {

      handleAlert(true, 'Error al obtener información de la cita', 'error')
    }
  }

  const gridInfo = ( info?: IAppointmentInfo ): Record<string, string>[] => [
    {
      Nombre:
        info?.patient_name && info?.patient_last_name
          ? `${info?.patient_name} ${info?.patient_last_name}`
          : '--',
      Día: info?.day || '--'
    },
    { Teléfono: info?.phone || '--', Hora: info?.hour_from || '--' },
    { Email: info?.email || '--', Consultorio: info?.office_reference || '--' },
    { Referencia: info?.reference || '--', Comentarios: info?.comment || '--' }
  ]

  useEffect(() => {
    // Listen for changes to the current date and update the button's disabled state
    const interval = setInterval(() => {
      setCurrentDate( dayjs( new Date()))
    }, 1000 )

    return () => {
      // Clear the interval when the component unmounts
      clearInterval( interval )
    }
  }, [currentDate])

  useEffect(() => {
    fetchAppointmentInfoUtility( appointmentId )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <ModalWrapper
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={openAppointmentDetailsModal}
      onClose={() => handleOpenAppointmentDetailsModal( false )}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500
        }
      }}
      sx={{
        height: 'auto',
        overflowY: 'auto'
      }}
    >
      <FadeAppointmentDetails in={openAppointmentDetailsModal}>
        <BoxAppointmentDetails>
          <GridModal>
            <TypographyTitleModal>Detalles de cita</TypographyTitleModal>
            <CloseButton
              data-testid="close-button"
              onClick={() => handleOpenAppointmentDetailsModal( false )}
            >
              <Close />
            </CloseButton>
            <GridWrapper container padding={3} paddingBottom={0}>
              {gridInfo( appointmentInfo ).map(
                ( item: Record<string, string>, index: number ) => (
                  <GridWrapper
                    container
                    marginY={2}
                    marginX={0.5}
                    key={`info-${index}`}
                  >
                    <GridWrapper
                      item
                      xs={6}
                      paddingX={1}
                      sx={{ overflow: 'hidden' }}
                    >
                      <TypographyTitle>{Object.keys( item )[0]}</TypographyTitle>
                      {Object.values( item )[0]}
                    </GridWrapper>
                    <GridWrapper
                      item
                      xs={6}
                      paddingX={1}
                      sx={{ overflow: 'hidden' }}
                    >
                      <TypographyTitle>{Object.keys( item )[1]}</TypographyTitle>
                      {Object.values( item )[1]}
                    </GridWrapper>
                  </GridWrapper>
                )
              )}
            </GridWrapper>

            {appointmentInfo && (
              <GridWrapper
                container
                display={'flex'}
                position={'relative'}
                justifyContent={'center'}
              >
                <AppointmentOptions
                  item={appointmentInfo as IAppointmentInfo}
                  currentDate={currentDate}
                  percentWidth={['32%', '32%', '32%']}
                  actionsStyle={{
                    width: '32%',
                    position: 'absolute'
                  }}
                  hamdleChangeAppointment={hamdleChangeAppointment}
                  handleChangeList={() => {}}
                  handleOpenNotifyDelay={handleOpenNotifyDelay}
                  handleOpenRecipeModal={handleOpenRecipeModal}
                  handleOpenReschedule={handleOpenReschedule}
                  handleSetAppointmentInfo={handleSetAppointmentInfo}
                  handleOpenNextAppointment={handleOpenNextAppointment}
                  handleOpenCancelAppointment={handleOpenCancelAppointment}
                />
              </GridWrapper>
            )}
          </GridModal>
        </BoxAppointmentDetails>
      </FadeAppointmentDetails>
    </ModalWrapper>
  )
}
