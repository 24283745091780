import React from 'react'

import {
  CalendarMonth,
  ExitToApp,
  HorizontalSplit,
  PersonOutline,
  Timeline,
  ShieldOutlined
} from './Icons'

export interface ItemsInterface {
  id: number
  title: string
  path: string
  icon: React.JSX.Element
}

export const SidebarData: ItemsInterface[] = [
  {
    id: 1,
    title: 'Citas',
    path: '/dashboard/appointments',
    icon: <HorizontalSplit />
  },
  {
    id: 2,
    title: 'Perfil',
    path: '/dashboard/profile',
    icon: <PersonOutline />
  },
  {
    id: 3,
    title: 'Calendario',
    path: '/dashboard/index',
    icon: <CalendarMonth />
  },
  {
    id: 4,
    title: 'Mi Red',
    path: '/dashboard/agreements',
    icon: <ShieldOutlined />
  },
]

export const SidebarBottonList: ItemsInterface[] = [
  {
    id: 1,
    title: 'Promoción',
    path: '/promotion',
    icon: <Timeline />
  },
  {
    id: 2,
    title: 'Cerrar Sesión',
    path: '/logout',
    icon: <ExitToApp />
  }
]
