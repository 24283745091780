import { CheckModifyOfficeImpact, CheckScheduleImpact } from '../../../domain/useCase/ScheduleUseCase'
import { IResponseService } from '../../../infrastructure/dtos'
import {
  BodyCheckScheduleImpactRequest,
  IApiResponseCheckScheduleImpact, ParamsCheckModifyOfficeImpact,
} from '../../../infrastructure/dtos/CheckScheduleImpact'
import { ScheduleMapper } from '../../../infrastructure/http/mappers/ScheduleMapper/ScheduleMapper'
import { ScheduleRepositoryImpl } from '../../../infrastructure/RepositoryImpl/ScheduleRepositoryImpl'

export async function checkScheduleImpactService(
  body: BodyCheckScheduleImpactRequest,
): Promise<IResponseService<IApiResponseCheckScheduleImpact>> {
  try {
    const useCaseApi: CheckScheduleImpact = new CheckScheduleImpact(
      new ScheduleRepositoryImpl(new ScheduleMapper()),
    )
    return {
      status: 1,
      data: await useCaseApi.invoke(body),
    }
  } catch (error: unknown) {
    return {
      status: 0,
      data: {
        statusCode: 500,
        body: {
          message: 'Internal server error',
          data: {},
        },
        headers: {
          'Access-Control-Allow-Headers': '',
          'Access-Control-Allow-Origin': '',
          'Access-Control-Allow-Methods': '',
        },
      },
    }
  }
}

export async function checkModifyOfficeImpactService(
  params: ParamsCheckModifyOfficeImpact,
): Promise<IResponseService<IApiResponseCheckScheduleImpact>> {
  try {
    const useCaseApi: CheckModifyOfficeImpact = new CheckModifyOfficeImpact(
      new ScheduleRepositoryImpl(new ScheduleMapper()),
    )
    return {
      status: 1,
      data: await useCaseApi.invoke(params),
    }
  } catch (error: unknown) {
    return {
      status: 0,
      data: {
        statusCode: 500,
        body: {
          message: 'Internal server error',
          data: {},
        },
        headers: {
          'Access-Control-Allow-Headers': '',
          'Access-Control-Allow-Origin': '',
          'Access-Control-Allow-Methods': '',
        },
      },
    }
  }
}
