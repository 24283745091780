import dayjs from 'dayjs'
import { ChangeEvent, useEffect, useState } from 'react'

import { useFiltersStore } from '../../../../../contexts/FiltersState'

export interface IUseSearchForm {
  handleChange: (event: ChangeEvent<HTMLInputElement>) => void
  handleBlur: (event: React.FocusEvent<HTMLInputElement>) => void
  handleClear: () => void
  handleKeyPress: (event: React.KeyboardEvent<HTMLInputElement>) => void
  handleDateRangeChange: (value: [Date, Date] | null) => void
  range: [Date, Date] | null | undefined
  search: string
}

export function useSearchForm(): IUseSearchForm {
  const { filtersSelected, setFiltersSelected } = useFiltersStore()
  const [search, setSearch] = useState<string>('')
  const [range, setRange] = useState<[Date, Date] | null>()

  const handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setSearch(event.target.value)
  }
  const handleClear = (): void => {
    setSearch('')
    setFiltersSelected({
      ...filtersSelected,
      name_or_ci: '',
      isSubmitted: true,
    })
  }

  const handleDateRangeChange = (value: [Date, Date] | null): void => {
    setRange(value)
    setFiltersSelected({
      ...filtersSelected,
      date_from: value ? dayjs(value[0]).format('YYYY-MM-DD') : '',
      date_to: value ? dayjs(value[1]).format('YYYY-MM-DD') : '',
      name_or_ci: search,
      isSubmitted: true,
    })
  }

  const handleKeyPress = (
    event: React.KeyboardEvent<HTMLInputElement>,
  ): void => {
    if (event.key === 'Enter') {
      if (filtersSelected?.name_or_ci !== search) {
        setFiltersSelected({
          ...filtersSelected,
          name_or_ci: search,
          isSubmitted: true,
        })
      }
    }
  }

  const handleBlur = (event: React.FocusEvent<HTMLInputElement>): void => {
    if (
      (event.target.value !== '' && filtersSelected?.name_or_ci !== search) ||
      (event.target.value === '' && filtersSelected?.name_or_ci !== '')
    ) {
      setFiltersSelected({
        ...filtersSelected,
        name_or_ci: search,
        isSubmitted: true,
      })
    }
  }
  useEffect(() => {
    let dates: string[] = ['', '']

    if (range) {
      dates = range.map((r: Date) => dayjs(r).format('YYYY-MM-DD'))
    }
    setFiltersSelected({
      ...filtersSelected,
      date_from: dates[0],
      date_to: dates[1],
      isSubmitted: true,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [range])

  useEffect(() => {
    if (filtersSelected) {
      setSearch(filtersSelected?.name_or_ci as string)
      setRange(
        filtersSelected.date_from && filtersSelected.date_to
          ? [
              new Date(filtersSelected.date_from),
              new Date(filtersSelected.date_to),
            ]
          : null,
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return {
    handleChange,
    handleBlur,
    handleClear,
    handleDateRangeChange,
    handleKeyPress,
    range,
    search,
  }
}
