import { CalendarToday, CancelOutlined } from '@mui/icons-material'
import { ClickAwayListener, Paper } from '@mui/material'
import React from 'react'

import {
  ListItemButtonWrapper,
  ListItemIconWrapper,
  ListItemTextWrapper,
  ListItemWrapper,
  ListWrapper
} from '../../../../components'
import { CalendarMenuOptions } from '../Styles/MenuCalendarStyles'

interface IProps {
  toolbarPosition: {
    left: number
    top: number
  }
  handleClose: () => void
  handleOpenNonAttendanceModal: () => void
  handleOpenNewAppointmentModal: () => void
}

export default function MenuCalendar({
  toolbarPosition,
  handleClose,
  handleOpenNonAttendanceModal,
  handleOpenNewAppointmentModal,
}: IProps): JSX.Element {
  return (
    <div>
      <ClickAwayListener onClickAway={handleClose}>
        <Paper>
          <CalendarMenuOptions
            left={toolbarPosition.left}
            top={toolbarPosition.top}
          >
            <ListWrapper>
              <ListItemWrapper onClick={handleOpenNewAppointmentModal}>
                <ListItemButtonWrapper>
                  <ListItemIconWrapper>
                    <CalendarToday />
                  </ListItemIconWrapper>
                  <ListItemTextWrapper primary="Crear nueva cita" />
                </ListItemButtonWrapper>
              </ListItemWrapper>

              <ListItemWrapper onClick={handleOpenNonAttendanceModal}>
                <ListItemButtonWrapper>
                  <ListItemIconWrapper>
                    <CancelOutlined />
                  </ListItemIconWrapper>
                  <ListItemTextWrapper primary="Marcar como no disponible" />
                </ListItemButtonWrapper>
              </ListItemWrapper>
            </ListWrapper>
          </CalendarMenuOptions>
        </Paper>
      </ClickAwayListener>
    </div>
  )
}
