import CircleIcon from '@mui/icons-material/Circle'
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked'
import { CheckboxProps } from '@mui/material'

import { TypographyLabel } from './CheckStyles'
import { CheckboxWrapper, FormControlLabelWrapper } from '../wrapper'

type ICheckProps = CheckboxProps & { label?: string }

export const Check = (props: ICheckProps): JSX.Element => {
  const { label } = props 
  return (
    <FormControlLabelWrapper
      value="enabled"
      sx={{width: '100%'}}
      control={
        <CheckboxWrapper
          icon={<RadioButtonUncheckedIcon />}
          checkedIcon={<CircleIcon />}
          {...props}
        />
      }
      slotProps={{
        typography: {
          style: {
            width: '100%'
          }
        }
      }}
      label={<TypographyLabel>{label || ''}</TypographyLabel>}
    />
  )
}
