import { useEffect, useState } from 'react'

import { GetParametersNotificationLateAppointmentUtility } from '../../../../../../services/Contracts/Utility/ParametersNotificationUtility'

interface IUseNotifyDelay {
  optionsList: string[]
  option: string
  customOption: string
  error: boolean
  MAX_LENGTH: number
  loadingData: boolean
  handleChangeOption: (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement> | string) => void
}

export const useNotifyDelay = (): IUseNotifyDelay => {
  const [optionsList, setOptionsList] = useState<string[]>([])
  const [option, setOption] = useState<string>('')
  const [customOption, setCustomOption] = useState<string>('')
  const [error, setError] = useState<boolean>(false)
  const [loadingData, setLoadingData] = useState<boolean>(true)
  const [MAX_LENGTH] = useState<number>(100)

  const fetchParametersNotificationLateAppointmentUtility =
    async (): Promise<void> => {
      setLoadingData(true)
      const { data } = await GetParametersNotificationLateAppointmentUtility()
      setOptionsList(data)
      setLoadingData(false)
    }

  const handleChangeOption = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement> | string,
  ): void => {
    if (typeof event === 'string') {
      setOption(event)
      setCustomOption('')
    } else {
      setError(event.target.value.length > MAX_LENGTH)
      setCustomOption(event.target.value)
      setOption('')
    }
  }

  useEffect(() => {
    fetchParametersNotificationLateAppointmentUtility()
  }, [])

  return {
    optionsList,
    customOption,
    error,
    option,
    loadingData,
    MAX_LENGTH,
    handleChangeOption,
  }
}
