import { CalendarToday } from '@mui/icons-material'
import { styled } from '@mui/material/styles'

import {
  BoxWrapper,
  ButtonWrapper,
  DatePickerWrapper,
  GridWrapper, IconButtonWrapper,
  ModalWrapper,
} from '../wrapper'
import SelectWrapper from '../wrapper/SelectWrapper'

export const ModalDate = styled( ModalWrapper )(() => ({
  display: 'grid',
  placeItems: 'center',
  overflowY:'auto',
  height:'auto',
}))

export const BoxModal = styled( BoxWrapper )(({theme}) => ({
  width: '60%',
  backgroundColor: theme.palette.common.white,
  border: `2px solid ${theme.palette.common.white}`,
  padding: '40px',
  marginTop: '20px',
  marginBottom: '100px',
  borderRadius: '30px',
  [theme.breakpoints.down( 'md' )]: {
    width: '90%',
  }
}))

export const GridModalContainerItem = styled( GridWrapper )(() => ({
  display: 'flex',
  flexDirection: 'row',
  height: '100%',
  marginTop: '30px'
}))

export const GridStyledItem = styled( GridWrapper )(({ theme }) => ({
  textAlign: 'left',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'center',
  paddingLeft: '10px',
  paddingRight: '10px',
  marginTop: '10px',
  [theme.breakpoints.down( 'sm' )]: {
    paddingLeft: '0px',
    paddingRight: '0px',
    marginTop: '20px',
  }
}))

export const ButtonModal = styled(ButtonWrapper)((props) => ({
  textTransform: 'capitalize',
  height: '50px',
  borderRadius: '24px',
  fontSize: '11px',
  backgroundColor: props.theme.palette.primary.main,
  color: props.theme.palette.common.white,
  padding: '7px 12px',
  border: '1px solid ' + props.theme.palette.primary.main,
  '&:hover': {
    backgroundColor: props.theme.palette.common.white,
    color: props.theme.palette.primary.main
  },
  '@media (max-width: 1600px)': {
    fontSize: '11px',
    width: '110px',
  },
  '@media (min-width: 1601px)': {
    fontSize: '15px',
  }
}))

export const CalendarDate = styled(CalendarToday)(() => ({
  marginRight: '10px',
  fontSize: '14px',
}))

export const DateTimeModal = styled( BoxWrapper )(({ theme }) => ({
  width: '100%',
  '& .MuiInputLabel-root': {},
  '& .MuiInputBase-root': {
    borderRadius: '3.4375rem',
    backgroundColor: theme.palette.common.white,
    fontSize: '1.1rem',
    color: theme.palette?.text.primary,
    fontFamily: 'Helvetica',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    boxShadow: '1px 1px 7px 7px rgba(0, 0, 0, 0.03)',
    width: '100%',
  },
  '& fieldset': { borderColor: theme.palette.common.white },
  '&::placeholder': {
    marginLeft: '1.875rem'
  },
  '& .MuiInputBase-root.Mui-disabled': {
    '& > fieldset': {
      borderColor: theme.palette.common.white,
    }
  }
}))

export const DatePickerModal = styled( DatePickerWrapper )(() => ({
  width: '100%',
  '& input': {
    fontSize: '12px',
    '@media (min-width: 1600px)': {
      fontSize: '16px',
    }
  }
}))

export const TextError = styled('p')(({ theme }) => ({
  color: theme.palette.primary.main,
  fontSize: '0.8rem',
  fontWeight: 'normal',
  fontStretch: 'normal',
  fontStyle: 'normal',
  lineHeight: 'normal',
  letterSpacing: 'normal',
  paddingTop: '10px',
}))


export const CloseButtonModal = styled(IconButtonWrapper)((props) => ({
  color: props.theme.palette.primary.dark,
  position: 'absolute',
  right: 0,
  top: -15,
}))

export const SelectTypesList = styled( SelectWrapper )((props ) => ({
  width: '100%',
  borderRadius: '42px',
  backgroundColor: props.theme.palette.common.white,
  boxShadow: '1px 1px 7px 7px rgba(0, 0, 0, 0.03)',
  height: '50px',
  '@media (min-width: 1600px)': {
    height: '57px',
  },
  '& fieldset': { border: 'none' },
  '& .MuiSelect-select': {
    fontSize: '12px',
    '@media (min-width: 1600px)': {
      fontSize: '16px',
    },
  },
}));
