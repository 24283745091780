import { SxProps } from '@mui/material'

import { ButtonSelectList } from '../../utils/RegisterMedicalFormStyles'
import { GridWrapper } from '../wrapper'

type AmountRangeProps = {
  amountRanges: string[]
  selectedAmountRange: string
  onAmountRangeChange: (option: string) => void
  style: SxProps
  disabled?: boolean
  buttonWidth?: number
}

const SelectButtonList = ({
  amountRanges,
  onAmountRangeChange,
  selectedAmountRange,
  style,
  disabled = false,
  buttonWidth = 3,
}: AmountRangeProps): JSX.Element => {
  const val = (item: string): string => {
    return 'b' + item.split(' ').join('')
  }

  return (
    <GridWrapper
      sx={style}
      container
      spacing={2}
      display={'flex'}
      alignItems="center"
      justifyContent="space-around"
    >
      {amountRanges?.map((item: string, index: number) => {
        return (
          <GridWrapper item xs={12} sm={6} md={4} lg={buttonWidth} key={index}>
            <ButtonSelectList
              id={val(item)}
              data-select={selectedAmountRange === item}
              sx={{
                backgroundColor:
                  selectedAmountRange === item
                    ? 'primary.main'
                    : 'background.paper',
                color: selectedAmountRange === item ? 'white' : 'text.primary',
                '&.Mui-disabled': {
                  boxShadow: '1px 1px 7px 7px rgba(0, 0, 0, 0.03)',
                  backgroundColor:
                    selectedAmountRange === item ? 'text.disabled' : 'white',
                  color:
                    selectedAmountRange === item
                      ? 'common.white'
                      : 'text.primary',
                },
              }}
              variant="contained"
              role="button"
              value={selectedAmountRange}
              onClick={() => onAmountRangeChange(item)}
              disabled={disabled}
              disableElevation
            >
              {item}
            </ButtonSelectList>
          </GridWrapper>
        )
      })}
    </GridWrapper>
  )
}

export default SelectButtonList
