import { IApiResponseSaveRegisterMedical, ISaveRegisterMedical } from '../../../infrastructure/dtos/MedicalRegister';
import { IPostMedicalRegisterService, PostMedicalRegisterService } from '../Persistencia/MedicalRegisterService';

export interface IMedicalRegisterDataBody {
  message: string,
  user_id: string
}

export interface IMedicalRegisterData {
  status: number
  data: string | IMedicalRegisterDataBody
}
export async function PostMedicalRegisterUtility(
  dataMedical: ISaveRegisterMedical
): Promise<IMedicalRegisterData> {
  try {
    const { status, data } = await PostMedicalRegisterService( dataMedical );
    const responseData: IApiResponseSaveRegisterMedical = data as IApiResponseSaveRegisterMedical

    if ( status !== 1 || responseData.statusCode !== 200 ) {
      return {
        data: responseData.body,
        status: 0,
      };
    }

    return {
      data: {
        message: responseData.body,
        user_id: responseData.user_id as string
      },
      status: status,
    };
  } catch ( error: unknown ) {
    return {
      // data: 'Ups! Hubo complicaciones intrente mas tarde',
      data: ((error as IPostMedicalRegisterService).data as IApiResponseSaveRegisterMedical).body,
      status: 0,
    };
  }
}
