// Componente numero 3

import { ButtonProps } from '@mui/material'

import { PrimaryButton } from '../StyledComponents/PrimaryButton'

type ISubmitButtonProps = ButtonProps & { text: string }

const SubmitButton = (props: ISubmitButtonProps): JSX.Element => {
  const { text } = props
  return (
    <PrimaryButton
      role="button"
      datatype="loginButton"
      { ...props }
    >
      {text}
    </PrimaryButton>
  )
}

export default SubmitButton
