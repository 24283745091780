import {
  CheckCircleOutline,
  HighlightOffOutlined,
  RemoveCircleOutline,
} from '@mui/icons-material'
import dayjs from 'dayjs'
import { ReactNode, useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { DateRangePicker } from 'rsuite'
import { DisabledDateFunction } from 'rsuite/esm/DateRangePicker'

import SubmitButton from '../../../../../../../../components/SubmitButton'
import {
  AccountContext,
  AuthContextType,
} from '../../../../../../../../contexts/AccountContext'
import { useDoctorInformationStore } from '../../../../../../../../contexts/DoctorInformationState'
import { useSelectOffice } from '../../../../../../../../contexts/SelectOfficeState'
import {
  IAppointmentInfo,
  IAppointmentInfoResponse,
} from '../../../../../../../../infrastructure/dtos/Appointments'
import {
  IAppointment,
  IAppointmentsByDoctorParams,
  IAppointmentsByDoctorResponse,
  PatientDiagnosticElement,
} from '../../../../../../../../infrastructure/dtos/AttendanceSelectedPatient'
import { IMedicalOffice } from '../../../../../../../../infrastructure/dtos/Offices'
import { IBodyPatientInfo } from '../../../../../../../../infrastructure/dtos/Patient'
import { AttendanceSelectedPatientService } from '../../../../../../../../services/Contracts/Persistencia/AttendanceSelectedPatientService'
import { getAppointmentInfoUtility } from '../../../../../../../../services/Contracts/Utility/AppointmentUtility'
import { getPatientInfoByCiUtility } from '../../../../../../../../services/Contracts/Utility/PatientInfoUtility'
import { useAppointments } from '../../../../../Hooks/useAppointments'
import { usePatientRecipesModal } from '../../PatientRecipesModal/hook'

interface IUseMedicalRecord {
  handleDateRangeChange: (value: [Date, Date] | null) => void
  afterToday: (() => DisabledDateFunction) | undefined
  gridInfoBody: {
    value: string
    title: string
  }[]
  gridBody: (dates: IAppointment) => {
    value: string | ReactNode
    width: number
  }[]
  gridHeader: {
    title: string
    width: number
  }[]
  list: IAppointment[]
  loadingPatient: boolean
  openPatientRecipesModal: boolean
  handleChangeOpenPatientRecipesModal: (value: boolean) => void
  appointmentSelected: IAppointment
  loadingList: boolean
  fetchMessage: string
  patient: IBodyPatientInfo | undefined
  nonRegistered: IAppointmentInfo | undefined
  filterRange: [Date, Date] | null | undefined
}

export const AssistanceIcos: { [key: string]: ReactNode } = {
  ATTENDED: <CheckCircleOutline sx={{ color: 'lightgreen' }} />,
  NOT_ASSISTED: <HighlightOffOutlined sx={{ color: 'red' }} />,
  PENDING: <RemoveCircleOutline sx={{ color: 'primary.dark' }} />,
}

const { afterToday } = DateRangePicker

function useMedicalRecord(): IUseMedicalRecord {
  const { doctorInformation } = useDoctorInformationStore()
  const { handleAlert } = useContext(AccountContext) as AuthContextType

  const { officeSelected } = useSelectOffice()

  const idDoctor = doctorInformation?.user_id as string

  const { idAppointment, idNumber } = useParams()

  const [filterRange, setFilterRange] = useState<[Date, Date] | null>()
  const {
    openPatientRecipesModal,
    appointmentSelected,
    onOpenPatientRecipesModal,
    handleChangeOpenPatientRecipesModal,
  } = usePatientRecipesModal()

  const { loadingList, handleChangeLoadingList } = useAppointments()
  const [fetchMessage, setFetchMessage] = useState<string>('')
  const [selectedOffice, setSelectedOffice] = useState<
    IMedicalOffice | undefined
  >(undefined)
  const [list, setList] = useState<IAppointment[]>([])
  const [loadingPatient, setLoadingPatient] = useState<boolean>(false)
  const [reference, setReference] = useState<string>('')
  const [patient, setPatient] = useState<IBodyPatientInfo>()

  const [nonRegistered, setNonRegistered] = useState<IAppointmentInfo>()

  useEffect(() => {
    if (officeSelected) {
      setSelectedOffice(
        (officeSelected as IMedicalOffice[]).find(
          (ofc: IMedicalOffice) => ofc.selected,
        ),
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [officeSelected])

  const fetchApointment = async (
    range: string | null = null,
  ): Promise<void> => {
    handleChangeLoadingList(true)
    setFetchMessage('')
    try {
      const params: IAppointmentsByDoctorParams = range
        ? {
            idDoctor,
            patient: idNumber as string,
            date: range,
          }
        : {
            idDoctor,
            patient: idNumber as string,
          }

      if (selectedOffice !== undefined) {
        const { data, status } = await AttendanceSelectedPatientService(params)
        const responseData: IAppointmentsByDoctorResponse =
          data as IAppointmentsByDoctorResponse

        if (!status || responseData.appointments?.length === 0) {
          setList([])
          setFetchMessage((responseData?.message as string) || (data as string))
          handleChangeLoadingList(false)

          return
        }
        handleChangeLoadingList(false)
        setList(responseData.appointments as IAppointment[])
      }
    } catch (error: unknown) {
      handleAlert(true, 'Error al obtener listado de citas', 'error')
    }
  }

  const fetchPatient = async (): Promise<void> => {
    try {
      setLoadingPatient(true)
      const { data, status } = await getPatientInfoByCiUtility(
        idNumber as string,
        false,
      )
      setLoadingPatient(false)

      if (status === 1) {
        setPatient(data as IBodyPatientInfo)
      }
      const { data: appointment } = await getAppointmentInfoUtility(
        idAppointment as string,
      )
      setReference(
        (
          (appointment as IAppointmentInfoResponse)
            ?.appointment as IAppointmentInfo
        )?.reference,
      )
      if (status === 0) {
        setNonRegistered((appointment as IAppointmentInfoResponse)?.appointment)
      }
    } catch (error: unknown) {
      setLoadingPatient(false)
      handleAlert(true, 'Error al obtener datos del paciente', 'error')
    }
  }

  useEffect(() => {
    fetchApointment(
      filterRange
        ? filterRange?.map((r: Date) => dayjs(r).format('DD/MM/YYYY')).join('-')
        : null,
    )
    if (!patient) {
      fetchPatient()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOffice, filterRange])

  const gridHeader: {
    title: string
    width: number
  }[] = [
    { title: 'Asistencia', width: 1 },
    { title: 'Fecha', width: 3 },
    { title: 'Diagnóstico', width: 5 },
    { title: '', width: 3 },
  ]

  const gridBody = (
    dates: IAppointment,
  ): {
    value: string | ReactNode
    width: number
  }[] => [
    {
      value: AssistanceIcos[dates.assistance],
      width: 1,
    },
    { value: dates.appointment_date, width: 3 },
    {
      value:
        Array.isArray(dates.patient_diagnostic) &&
        dates.patient_diagnostic.length > 0
          ? (dates.patient_diagnostic as PatientDiagnosticElement[])
              .map((item) => `${item.cie_code} ${item.diagnostic}`)
              .join(', ')
              .slice(0, 30) + '...'
          : '',
      width: 5,
    },
    {
      value: (
        <SubmitButton
          id="datails-button"
          data-testid="datails-button"
          disableElevation
          variant="contained"
          fullWidth={false}
          type="button"
          text={'Detalles'}
          onClick={() => onOpenPatientRecipesModal(dates)}
          disabled={dates.assistance === 'NOT_ASSISTED'}
          sx={{
            fontSize: '12px',
            '@media (min-width: 1600px)': {
              fontSize: '16px',
            },
            '&:disabled': {
              backgroundColor: 'tertiary.light',
              flexShrink: 0,
              color: 'info.main',
            },
          }}
        />
      ),
      width: 3,
    },
  ]

  const gridInfoBody: {
    value: string
    title: string
  }[] = [
    {
      title: 'Número de cédula',
      value: patient?.age
        ? (patient?.identification_patient as string)
        : (nonRegistered?.patient_id_number as string),
    },
    {
      title: 'Número de teléfono',
      value: patient?.age
        ? (patient?.number_phone_patient as string)
        : (nonRegistered?.phone as string),
    },
    {
      title: 'Fecha de nacimiento',
      value: patient?.age
        ? dayjs(patient?.date_birth).format('DD/MM/YYYY')
        : '-',
    },
    {
      title: 'Correo electrónico',
      value: patient?.age
        ? (patient?.email_patient as string)
        : (nonRegistered?.email as string),
    },
    {
      title: 'Registrado desde',
      value: patient?.age ? (patient?.created_since as string) : '-',
    },
    {
      title: 'Referido por',
      value: reference,
    },
  ]

  const handleDateRangeChange = (value: [Date, Date] | null): void => {
    setFilterRange(value)
  }
  return {
    handleDateRangeChange,
    afterToday,
    gridInfoBody,
    gridBody,
    gridHeader,
    list,
    handleChangeOpenPatientRecipesModal,
    loadingPatient,
    openPatientRecipesModal,
    appointmentSelected,
    loadingList,
    fetchMessage,
    patient,
    nonRegistered,
    filterRange,
  }
}

export default useMedicalRecord
