import { ReactNode } from 'react'

import { BoxWrapper } from '../wrapper'

interface TabPanelProps {
  children?: ReactNode
  index: number
  value: number
}


export function CustomTabPanel( props: TabPanelProps ): JSX.Element {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <BoxWrapper sx={{ p: 3 }}>
          {children}
        </BoxWrapper>
      )}
    </div>
  )
}
