import { IApiResponseSearchCie10 } from '../../infrastructure/dtos/SearchCie10';
import { SearchCie10Repository } from '../repository/SearchCie10Repository';

export interface GetSearchCie10UseCase {
  invoke: ( query: string ) => Promise<IApiResponseSearchCie10>;
}

export class GetSearchCie10 implements GetSearchCie10UseCase {
  private objectUseCase: SearchCie10Repository;

  constructor( _objectUseCase: SearchCie10Repository ) {
    this.objectUseCase = _objectUseCase;
  }

  async invoke( query: string ): Promise<IApiResponseSearchCie10> {
    return this.objectUseCase.getSearchCie10( query );
  }
}
