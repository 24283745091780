import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import { Theme, useMediaQuery, useTheme } from '@mui/material'
import { FunctionComponent } from 'react'

import { StackButton } from './BackButtonStyle'
import { ButtonBack } from '../StyledComponents/ButtonBack'
import { LinkTab } from '../StyledComponents/LinkTab'

interface IComponentProps {
  id: string
  text?: string
  onClick?: () => void
  disabled?: boolean
}
export const BackButton: FunctionComponent<IComponentProps> = ({
  text,
  id,
  onClick,
  disabled = false,
}) => {
  const theme: Theme = useTheme()
  const isExtraSmallSize = !useMediaQuery(theme.breakpoints.up('sm'))
  const buttonPosition: number = isExtraSmallSize ? 35 : 140
  return (
    <StackButton
      spacing={2}
      direction="row"
      alignItems="center"
      justifyContent="left"
      buttonposition={buttonPosition}
    >
      <ButtonBack onClick={onClick} disabled={disabled} role="button">
        <ChevronLeftIcon />
      </ButtonBack>
      {!isExtraSmallSize && (
        <LinkTab
          onClick={onClick}
          color="inherit"
          id={id}
          aria-disabled={disabled}
          role="link"
        >
          {text}
        </LinkTab>
      )}
    </StackButton>
  )
}
