import React, { FunctionComponent } from 'react';

import { GridGeneric } from './LoadingStyle';
import CircularProgressWrapper from '../wrapper/LoaderWrapper';



interface IComponentProps { }

export const Loading: FunctionComponent<IComponentProps> = () => {
  return (
    <GridGeneric>
      <CircularProgressWrapper size='3em' />
    </GridGeneric>
  )
};