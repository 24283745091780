import {
  GetPatientInfo,
  GetPatientInfoByCi,
} from '../../../domain/useCase/GetPatientInfo'
import { IServicePatientInfo } from '../../../infrastructure/dtos/Patient'
import PatientInfoMapper from '../../../infrastructure/http/mappers/PatientInfoMapper/PatientMapper'
import { PatientInfoRepositoryImpl } from '../../../infrastructure/RepositoryImpl/PatientInfoRepositoryImpl'

export async function GetPatientInfoService(
  idPatient: string,
): Promise<IServicePatientInfo> {
  try {
    const UseCasePatient = new GetPatientInfo(
      new PatientInfoRepositoryImpl(new PatientInfoMapper()),
    )

    return {
      status: 1,
      data: await UseCasePatient.invoke(idPatient),
    }
  } catch (error: unknown) {
    return {
      status: 0,
      data: 'Error Service',
    }
  }
}

export async function GetPatientInfoByCiService(
  ciPatient: string,
  isOnlyInfo?: boolean,
): Promise<IServicePatientInfo> {
  try {
    const UseCasePatient: GetPatientInfoByCi = new GetPatientInfoByCi(
      new PatientInfoRepositoryImpl(new PatientInfoMapper()),
    )

    return {
      status: 1,
      data: await UseCasePatient.invoke(ciPatient, isOnlyInfo),
    }
  } catch (error: unknown) {
    return {
      status: 0,
      data: 'Error Service',
    }
  }
}
