import { ICancelAppointmentResponse } from '../../infrastructure/dtos/CancelAppointment'
import { ICancelService } from '../../services/Contracts/Persistencia/CancelAppointmentService'
import { ICancelAppointmentRepository } from '../repository/CancelAppointmentRepository'

export interface ICancelAppointmentUseCase {
  invoke: (params: ICancelService) => Promise<ICancelAppointmentResponse>
}

export class CancelAppointment implements ICancelAppointmentUseCase {
  private objectUseCase: ICancelAppointmentRepository

  constructor(_objectUseCase: ICancelAppointmentRepository) {
    this.objectUseCase = _objectUseCase
  }

  async invoke(params: ICancelService): Promise<ICancelAppointmentResponse> {
    return this.objectUseCase.cancelAppointment(params)
  }
}
