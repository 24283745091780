import axios from 'axios'

const http = axios.create({
  baseURL: process.env.REACT_APP_API,
})

// http.interceptors.request.use(function (config: any) {
//   return new Promise((resolve, reject) => {});
// });
export { http }
