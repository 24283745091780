import { GetVisibilityAppointmentTimeService } from '../Persistencia/VisibilityAppointmentTimeService';

interface IVisibilityAppointmentTimeData {
  status: number,
  data: string[]
}
export const GetVisibilityAppointmentTimeUtility = async (): Promise<IVisibilityAppointmentTimeData> => {
  try {
    const { status, data } = await GetVisibilityAppointmentTimeService();

    if (
      status !== 1 || 
            data.statusCode !== 200 || 
            typeof data.body === 'string' ) {
      return {
        data: [],
        status: 0
      };
    }

    return {
      data: data.body.data,
      status: status
    };
  } catch ( error: unknown ) {
    return {
      data: [],
      status: 0
    };
  }
}