import {
  IApiResponseModifiedDataOffice,
  IModifiedDataOffice,
} from '../../infrastructure/dtos/UpdateOffices'
import { IUpdateOfficesRepository } from '../repository/UpdateOfficesRepository'

export interface IUpdateOfficesUseCase {
  invoke: (
    office: IModifiedDataOffice,
  ) => Promise<IApiResponseModifiedDataOffice>
}

export class UpdateOffices implements IUpdateOfficesUseCase {
  private objectUseCase: IUpdateOfficesRepository

  constructor(_objectUseCase: IUpdateOfficesRepository) {
    this.objectUseCase = _objectUseCase
  }

  async invoke(
    office: IModifiedDataOffice,
  ): Promise<IApiResponseModifiedDataOffice> {
    return this.objectUseCase.updateOffices(office)
  }
}
