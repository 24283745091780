import { IApiResponseVisibilityAppointmentTimeAWS } from '../dtos/VisibilityAppointmentTime'
import IVisibilityAppointmentTimeDataSource from '../http/dataSource/VisibilityAppointmentTimeDataSource'
import VisibilityAppointmentTimeRepository from '../http/dataSource/VisibilityAppointmentTimeDataSource'

export class VisibilityAppointmentTimeRepositoryImpl
  implements VisibilityAppointmentTimeRepository
{
  dataSource: IVisibilityAppointmentTimeDataSource

  constructor(_datasource: IVisibilityAppointmentTimeDataSource) {
    this.dataSource = _datasource
  }

  async getVisibilityAppointmentTime(): Promise<IApiResponseVisibilityAppointmentTimeAWS> {
    return this.dataSource.getVisibilityAppointmentTime()
  }
}
