import React from 'react'
import { ChangeEvent } from 'react'

import { useProfileMedicalForm } from './Hooks/useProfileMedicalForm'
import { StyledForm } from './ProfileMedicalFormStyle'
import containerText from './ProfileMedicalFormText.json'
import FormTextInput from '../../../../components/FormInputText'
import FormTextArea from '../../../../components/FormTextArea'
import { GridFullScreen } from '../../../../components/GridFullScreen/GridFullScreen'
import { List } from '../../../../components/List/List'
import { GridPartialScreen } from '../../../../components/StyledComponents/GridPartialScreen'
import { LabelInput } from '../../../../components/StyledComponents/LabelInput'
import { Title } from '../../../../components/StyledComponents/Title'
import SubmitButton from '../../../../components/SubmitButton'
import UploadPhoto from '../../../../components/UploadPhoto'
import GridWrapper from '../../../../components/wrapper/GridWrapper'
import StackWrapper from '../../../../components/wrapper/StackWrapper'
import { requiredField } from '../../../../utils/requiredField'
import { handleImageUpload } from '../../../../utils/uploadImage'
import { validatePhoneNumber } from '../../../../utils/validatePhoneNumber'
import { TypographyWrapperValidationEmailFalse } from '../../../Auth/RegisterAuth/RegistermedicalStyles'

const ProfileMedicalForm = (): React.JSX.Element => {
  const {
    genders,
    idTypes,
    selectedImage,
    setSelectedImage,
    disabledOption,
    onSubmit,
    onValidationFocus,
    validateIdType,
    validateIdNumber,
    validatorIdDocument,
    textErrorIdNumber,
    validateName,
    validateLastName,
    validateGender,
    validatePhone,
    validateDescription,
    validatorErrorTextArea,
    isValid,
    fieldRegister,
    setFieldRegister,
  } = useProfileMedicalForm()
  return (
    <GridFullScreen id="ProfileMedicalForm">
      <GridPartialScreen container flexDirection={'column'}>
        <Title>{containerText.create_your_profile}</Title>
        <StyledForm onSubmit={onSubmit} data-testid="profile-form">
          <GridWrapper item xs={12} mt={4}>
            <StackWrapper
              spacing={2}
              direction={{ xs: 'column', sm: 'row' }}
              mb={4}
            >
              <GridWrapper item xs={12} sm={6}>
                <LabelInput>{containerText.id_type}</LabelInput>
                <List
                  id="selectIdType"
                  lists={idTypes}
                  onClick={(event: string) =>
                    setFieldRegister({ ...fieldRegister, idType: event })
                  }
                  onBlur={() => onValidationFocus(4)}
                  value={fieldRegister.idType}
                  minWidth="100%"
                  error={validateIdType}
                  textError={requiredField(validateIdType)}
                  disabled={disabledOption}
                />
              </GridWrapper>

              <GridWrapper item xs={12} sm={6}>
                <FormTextInput
                  text={containerText.set_id_number}
                  placeholder={containerText.set_id_number_placeholder}
                  id="idNumber"
                  name="idNumber"
                  type="text"
                  fullWidth
                  value={fieldRegister.idNumber}
                  onChange={(
                    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
                  ) =>
                    setFieldRegister({
                      ...fieldRegister,
                      idNumber: event.target.value,
                    })
                  }
                  onBlur={() => onValidationFocus(5)}
                  error={validateIdNumber || validatorIdDocument.status}
                  helperText={textErrorIdNumber()}
                  disabled={disabledOption}
                />
              </GridWrapper>
            </StackWrapper>
          </GridWrapper>

          <GridWrapper item xs={12} mt={2}>
            <StackWrapper
              spacing={2}
              direction={{ xs: 'column', sm: 'row' }}
              mb={4}
            >
              <GridWrapper item xs={12} sm={6}>
                <FormTextInput
                  text={containerText.whats_your_name}
                  placeholder={containerText.whats_your_name_placeholder}
                  id="name"
                  type="text"
                  fullWidth
                  value={fieldRegister.name}
                  name="name"
                  onBlur={() => onValidationFocus(0)}
                  onChange={(
                    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
                  ) =>
                    setFieldRegister({
                      ...fieldRegister,
                      name: event.target.value,
                    })
                  }
                  error={validateName}
                  helperText={requiredField(validateName)}
                  disabled={disabledOption}
                />
              </GridWrapper>
              <GridWrapper item xs={12} sm={6}>
                <FormTextInput
                  text={containerText.whats_your_last_name}
                  placeholder={containerText.whats_your_last_name_placeholder}
                  id="lastName"
                  name="lastName"
                  type="text"
                  fullWidth
                  value={fieldRegister.lastName}
                  onChange={(
                    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
                  ) =>
                    setFieldRegister({
                      ...fieldRegister,
                      lastName: event.target.value,
                    })
                  }
                  onBlur={() => onValidationFocus(1)}
                  error={validateLastName}
                  helperText={requiredField(validateLastName)}
                  disabled={disabledOption}
                />
              </GridWrapper>
            </StackWrapper>
          </GridWrapper>

          <GridWrapper item xs={12}>
            <StackWrapper
              spacing={2}
              direction={{ xs: 'column', sm: 'row' }}
              mb={4}
            >
              <GridWrapper item xs={12} sm={6}>
                <LabelInput>{containerText.gender}</LabelInput>
                <List
                  id="selectGender"
                  lists={genders}
                  onClick={(event: string) =>
                    setFieldRegister({
                      ...fieldRegister,
                      gender: event,
                    })
                  }
                  value={fieldRegister.gender}
                  onBlur={() => onValidationFocus(2)}
                  minWidth="100%"
                  error={validateGender}
                  textError={requiredField(validateGender)}
                />
              </GridWrapper>
              <GridWrapper item xs={12} sm={6}>
                <FormTextInput
                  text={containerText.whats_your_phone}
                  placeholder={containerText.whats_your_phone_planceholder}
                  id="phone"
                  type="tel"
                  fullWidth
                  value={fieldRegister.phone}
                  name="phone"
                  onChange={(
                    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
                  ) =>
                    setFieldRegister({
                      ...fieldRegister,
                      phone: event.target.value,
                    })
                  }
                  onBlur={() => onValidationFocus(3)}
                  error={validatePhone}
                  helperText={requiredField(validatePhone)}
                  disabled={disabledOption}
                />
                {!validatePhoneNumber(fieldRegister.phone) &&
                  fieldRegister.phone.length > 0 && (
                    <TypographyWrapperValidationEmailFalse>
                      {containerText.phone_valid_message}
                    </TypographyWrapperValidationEmailFalse>
                  )}
              </GridWrapper>
            </StackWrapper>
          </GridWrapper>

          <GridWrapper item xs={12}>
            <FormTextArea
              text={containerText.about_you}
              placeholder={containerText.about_you_placeholder}
              id="description"
              name="description"
              type="text"
              value={fieldRegister.description}
              onChange={(
                event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
              ) =>
                setFieldRegister({
                  ...fieldRegister,
                  description: event.target.value,
                })
              }
              fullWidth
              multiline
              rows={3}
              inputProps={{ maxLength: 1000 }}
              onBlur={() => onValidationFocus(6)}
              error={validateDescription}
              helperText={validatorErrorTextArea}
            />
          </GridWrapper>

          <GridWrapper item xs={12} my={4}>
            <UploadPhoto
              id="UploadPhotoId"
              selectedImage={selectedImage}
              title={containerText.upload_photo}
              tooltipText={containerText.upload_photo_tooltip}
              buttonText={containerText.upload_photo_button}
              buttonType="button"
              onChange={(event: ChangeEvent<HTMLInputElement>) =>
                handleImageUpload(event, setSelectedImage)
              }
              data-Testid="upload-file"
            />
          </GridWrapper>

          <GridWrapper item xs={12} my={2}>
            <SubmitButton
              id="button"
              type="submit"
              variant="contained"
              fullWidth
              text={containerText.next_button}
              data-testid="ok-button"
              disabled={isValid}
            />
          </GridWrapper>
        </StyledForm>
      </GridPartialScreen>
    </GridFullScreen>
  )
}
export default ProfileMedicalForm
