import {
  Visibility as VisibilityIcon,
  VisibilityOff as VisibilityOffIcon,
} from '@mui/icons-material'
import ClearIcon from '@mui/icons-material/Clear'
import { IconButton, InputAdornment } from '@mui/material'
import { useState } from 'react'
import { CustomProvider, DateRangePicker } from 'rsuite'
import esES from 'rsuite/locales/es_ES'

import {
  ControlBar,
  DateRangeInput,
  IconSearch,
  SearchInput,
  SelectInput,
  ProgressRate,
  CardQualification,
  AvatarQualification,
  HiddenCommentButton,
} from './styles'
import { useQualifications } from './useQualifications'
import { GridItem } from '../../../../../components/GridItem/GridItem'
import {
  CardActionsWrapper,
  CardContentWrapper,
  GridWrapper,
  MenuItemWrapper,
  PaginationWrapper,
  StackWrapper,
  TypographyWrapper,
} from '../../../../../components/wrapper'
import CircularProgressWrapper from '../../../../../components/wrapper/LoaderWrapper'
import { IQualificationList } from '../../../../../infrastructure/dtos/qualification'

const QualificationParametersLabels: { [key: string]: string } = {
  attention: 'Atención',
  punctuality: 'Puntualidad',
}

const progressMaxValue = 5

function Qualifications(): JSX.Element {
  const {
    search,
    qualificationList,
    list,
    loading,
    pagination,
    handleChangePage,
    handleChange,
    handleClear,
    handleClick,
  } = useQualifications()


  const [filterRange, setFilterRange] = useState<[Date, Date] | null>()

  const handleDateRangeChange = (value: [Date, Date] | null): void => {
    setFilterRange(value)
  }

  return !loading ? (
    <GridWrapper>
      <ControlBar role="toolbar">
        <StackWrapper spacing={2} direction="row" sx={{ marginLeft: 'auto' }}>
          <SelectInput
            name="selectInput"
            labelId="selectInputLabel"
            id="selectInput"
            role="combobox"
            defaultValue={list[0]}
          >
            {list.map((item: string, index: number) => {
              return (
                <MenuItemWrapper
                  data-testid={`item-${index}`}
                  key={index}
                  value={item}
                >
                  {item}
                </MenuItemWrapper>
              )
            })}
          </SelectInput>
          <DateRangeInput role="calendarSearch">
            <CustomProvider locale={esES}>
              <DateRangePicker
                value={filterRange}
                id={'filter-range'}
                format="dd/MM/yyyy"
                size="sm"
                showOneCalendar
                ranges={[]}
                placeholder="dd/mm - dd/mm"
                appearance="subtle"
                style={{
                  width: '100%',
                  borderRadius: '52px',
                  backgroundColor: 'white',
                  padding: '6px 2px',
                  height: '42px',
                }}
                onChange={handleDateRangeChange}
              />
            </CustomProvider>
          </DateRangeInput>

          <SearchInput
            id="filter-text"
            value={search}
            onChange={handleChange}
            placeholder="Buscar"
            role="textbox"
            name="Buscar"
            sx={{}}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <IconSearch />
                </InputAdornment>
              ),
              endAdornment: search && (
                <InputAdornment position="end">
                  <IconButton onClick={handleClear}>
                    <ClearIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </StackWrapper>
      </ControlBar>
      <GridWrapper container spacing={2} data-testid="qualification-container">
        {qualificationList?.data.length > 0 &&
          qualificationList?.data[pagination.page - 1].map(
            (item: IQualificationList, index: number) => (
              <GridItem
                md={12}
                lg={6}
                key={`qualification-${index}`}
                data-testid="qualification-item"
              >
                <CardQualification
                  data-testid={'qualifications'}
                  variant="outlined"
                >
                  <CardContentWrapper>
                    <GridWrapper
                      container
                      spacing={2}
                      display={'flex'}
                      justifyContent={'space-around'}
                    >
                      <GridItem
                        sm={12}
                        md={2}
                        flexDirection={'column'}
                        justifyContent={'flex-start'}
                      >
                        <AvatarQualification src={item.photo_patient} />
                        <TypographyWrapper
                          sx={{
                            fontSize: '16px',
                            fontWeight: '400',
                          }}
                        >
                          {item.name_patient}
                        </TypographyWrapper>
                        <TypographyWrapper
                          data-testid="comment-date"
                          sx={{
                            fontSize: '12px',
                            fontWeight: '400',
                            color: 'text.primary',
                          }}
                        >
                          {item.created_at}
                        </TypographyWrapper>
                        <TypographyWrapper
                          sx={{
                            fontSize: '28px',
                            fontWeight: '700',
                          }}
                        >
                          {item.qualification_appointment}
                        </TypographyWrapper>
                      </GridItem>
                      <GridItem
                        sm={12}
                        md={6}
                        flexDirection={'column'}
                        justifyContent={'flex-start'}
                      >
                        {Object.keys(item.qualification_parameters).map(
                          (rate: string, idx: number) => (
                            <GridWrapper
                              container
                              key={`rates-${idx}`}
                              display={'flex'}
                              direction={'row'}
                              justifyContent={'space-between'}
                            >
                              <GridItem xs={4} justifyContent={'flex-start'}>
                                <TypographyWrapper
                                  sx={{
                                    fontSize: '14px',
                                    fontWeight: '400',
                                  }}
                                >
                                  {QualificationParametersLabels[rate]}
                                </TypographyWrapper>
                              </GridItem>
                              {(rate === 'attention' ||
                                rate === 'punctuality') && (
                                <>
                                  <GridItem xs={5}>
                                    <ProgressRate
                                      value={
                                        (item.qualification_parameters[rate] *
                                          100) /
                                        progressMaxValue
                                      }
                                      variant="determinate"
                                    />
                                  </GridItem>
                                  <GridItem xs={2}>
                                    <TypographyWrapper
                                      sx={{
                                        fontSize: '13px',
                                      }}
                                    >
                                      {item.qualification_parameters[rate]}
                                    </TypographyWrapper>
                                  </GridItem>
                                </>
                              )}
                            </GridWrapper>
                          ),
                        )}
                      </GridItem>
                      <GridItem
                        sm={12}
                        md={3}
                        textAlign={'left'}
                        alignItems={'flex-start'}
                      >
                        <TypographyWrapper fontStyle={'italic'}>
                          {item.comment_patient || 'Sin comentarios'}
                        </TypographyWrapper>
                      </GridItem>
                    </GridWrapper>
                  </CardContentWrapper>
                  <CardActionsWrapper>
                    <GridWrapper
                      container
                      display={'flex'}
                      justifyContent={'center'}
                    >
                      <GridItem xs={12}>
                        <HiddenCommentButton
                          status={item.qualification_status}
                          variant={'outlined'}
                          startIcon={
                            ['NOT_VISIBLE'].includes(
                              item.qualification_status,
                            ) ? (
                              <VisibilityIcon />
                            ) : (
                              <VisibilityOffIcon />
                            )
                          }
                          onClick={() =>
                            handleClick(item, pagination.page, index)
                          }
                          data-testid="show-hide-comment"
                          disabled={item.comment_patient.length === 0}
                        >
                          {['NOT_VISIBLE'].includes(item.qualification_status)
                            ? 'Mostrar Comentario'
                            : 'Ocultar Comentario'}
                        </HiddenCommentButton>
                      </GridItem>
                    </GridWrapper>
                  </CardActionsWrapper>
                </CardQualification>
              </GridItem>
            ),
          )}
        {qualificationList?.data.length === 0 && (
          <GridItem mt={4} xs={12}>
            <TypographyWrapper>{qualificationList.message}</TypographyWrapper>
          </GridItem>
        )}
        <GridItem xs={12} margin={2}>
          <PaginationWrapper
            count={pagination.totalPages}
            page={pagination.page}
            onChange={handleChangePage}
            data-testid="paginator"
          />
        </GridItem>
      </GridWrapper>
    </GridWrapper>
  ) : (
    <GridWrapper display={'flex'} justifyContent={'center'} mt={5}>
      <CircularProgressWrapper data-testid="loading-spinner" />
    </GridWrapper>
  )
}

export default Qualifications
