import { GetAppointmentDurations, GetAppointmentReasons } from '../../../domain/useCase/AppointmentTypesUseCase'
import { IResponseService } from '../../../infrastructure/dtos'
import { IApiResponseGetAppointmentDurations } from '../../../infrastructure/dtos/AppointmentDurations'
import { IApiResponseGetAppointmentReasons } from '../../../infrastructure/dtos/AppointmentReasons'
import {
  AppointmentTypesMapper
} from '../../../infrastructure/http/mappers/AppointmentTypesMapper/AppointmentTypesMapper'
import { AppointmentTypesRepositoryImpl } from '../../../infrastructure/RepositoryImpl/AppointmentTypesRepositoryImpl'


export async function getAppointmentDurationsService(): Promise<IResponseService<IApiResponseGetAppointmentDurations>> {
  try {
    const useCaseApi: GetAppointmentDurations = new GetAppointmentDurations(
      new AppointmentTypesRepositoryImpl(new AppointmentTypesMapper())
    )
    return {
      status: 1,
      data: await useCaseApi.invoke(),
    }
  } catch (error: unknown) {
    return {
      status: 0,
      data: {
        statusCode: 500,
        body: {
          message: 'Internal Server Error',
          data: [],
        },
        headers: {
          'Access-Control-Allow-Headers': '',
          'Access-Control-Allow-Origin': '',
          'Access-Control-Allow-Methods': '',
        },
      },
    }
  }
}

export async function getAppointmentReasonsService(): Promise<IResponseService<IApiResponseGetAppointmentReasons>> {
  try {
    const useCaseApi: GetAppointmentReasons = new GetAppointmentReasons(
      new AppointmentTypesRepositoryImpl(new AppointmentTypesMapper())
    )
    return {
      status: 1,
      data: await useCaseApi.invoke(),
    }
  } catch (error: unknown) {
    return {
      status: 0,
      data: {
        statusCode: 500,
        body: {
          message: 'Internal Server Error',
          data: [],
        },
        headers: {
          'Access-Control-Allow-Headers': '',
          'Access-Control-Allow-Origin': '',
          'Access-Control-Allow-Methods': '',
        },
      },
    }
  }
}
