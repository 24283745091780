import {
  BoxAgreements,
  CardAddAgreements,
  CardContentAddAgreements,
  TypographyAddAgreements,
} from './AddAgreementStyle'
import SubmitButton from '../../../../../components/SubmitButton'
import { Daum } from '../../../../../infrastructure/dtos/AgreementsInvites'

export interface CardData {
  data: Daum
  handleSubmit: (reply: boolean, data: Daum) => Promise<void>
}

function AddAgreement({ data, handleSubmit }: CardData): JSX.Element {
  const name_agreement =
    data.type === 'CM' ? 'El centro médico' : 'La aseguradora'
  return (
    <>
      <CardAddAgreements data-testid="card-add-agreements">
        <CardContentAddAgreements>
          <TypographyAddAgreements>
            {`${name_agreement} ${data.name_agreement} te ha invitado a agregar tus turnos con ellos en tu agenda. `}
            <a href="#">Más información</a>
          </TypographyAddAgreements>
          <BoxAgreements>
            <SubmitButton
              text="Aceptar"
              variant="contained"
              onClick={() => handleSubmit(true, data)}
              sx={{
                '@media (max-width: 1600px)': {
                  fontSize: '12px',
                  width: '120px',
                },
                '@media (min-width: 1601px)': {
                  fontSize: '16px',
                  width: '180px',
                },
                marginRight: '15px',
              }}
            />
            <SubmitButton
              text="Rechazar"
              variant="contained"
              onClick={() => handleSubmit(false, data)}
              sx={{
                '@media (max-width: 1600px)': {
                  fontSize: '12px',
                  width: '120px',
                },
                '@media (min-width: 1601px)': {
                  fontSize: '16px',
                  width: '180px',
                },
              }}
            />
          </BoxAgreements>
        </CardContentAddAgreements>
      </CardAddAgreements>
    </>
  )
}

export default AddAgreement
