import { AxiosError } from 'axios'

import {
  BodySaveOfficeRequest,
  IApiResponseOffices,
  IApiResponseSaveOffice,
  IUpdateInfoOfficeParams,
  IUpdateInfoOfficeResponse,
} from '../../../dtos/Offices'
import { BodyModifyStatusOfficeRequest, IApiResponseModifyStatusOffice } from '../../../dtos/UpdateOffices'
import OfficesDataSource from '../../dataSource/OfficesDataSource'
import { http } from '../../Http'

export default class OfficesMapper implements OfficesDataSource {
  async getOffices( idDoctor: string ): Promise<IApiResponseOffices> {
    const data = await http
      .get<IApiResponseOffices>( `send_data/offices/${idDoctor}` )
      .then(( response ) => {
        const { data } = response

        return data
      })
      .catch(( error ) => {
        const data = {
          statusCode: error.response.status,
          body: error.response.body,
          headers: {
            'Access-Control-Allow-Headers': '',
            'Access-Control-Allow-Origin': '',
            'Access-Control-Allow-Methods': ''
          }
        }

        return data
      })

    return data
  }

  async updateOffice( params: IUpdateInfoOfficeParams ): Promise<IUpdateInfoOfficeResponse> {
    try {
      const { data } = await http.put<IUpdateInfoOfficeResponse>( `modified_data/info_office`, {
        body: params
      })

      return data
    } catch ( error: unknown ) {
      return {
        statusCode: ( error as AxiosError )?.response?.status as number,
        body: ( error as AxiosError )?.response?.statusText as string,
        headers: {
          'Access-Control-Allow-Headers': '',
          'Access-Control-Allow-Origin': '',
          'Access-Control-Allow-Methods': ''
        }
      }
    }
  }

  async updateOfficeStatus(body: BodyModifyStatusOfficeRequest): Promise<IApiResponseModifyStatusOffice> {
    try {
      const { data } = await http.put<IApiResponseModifyStatusOffice>(`modified_data/office_status`, body)

      return data
    } catch (e: unknown) {
      return {
        statusCode: (e as AxiosError)?.response?.status as number,
        body: {
          message: (e as AxiosError)?.response?.statusText as string,
        },
        headers: {
          'Access-Control-Allow-Headers': '',
          'Access-Control-Allow-Origin': '',
          'Access-Control-Allow-Methods': '',
        },
      }
    }
  }

  async saveNewOffice(body: BodySaveOfficeRequest): Promise<IApiResponseSaveOffice> {
    try {
      const { data } = await http.post<IApiResponseSaveOffice>(`save_data/new_office`, body)
      return data
    } catch (e: unknown) {
      return {
        statusCode: (e as AxiosError)?.response?.status as number,
        body: {
          message: (e as AxiosError)?.response?.statusText as string,
        },
        headers: {
          'Access-Control-Allow-Headers': '',
          'Access-Control-Allow-Origin': '',
          'Access-Control-Allow-Methods': '',
        },
      }
    }
  }
}
