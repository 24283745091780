import { IResponseApi } from '../../infrastructure/dtos';
import { ISendValidationIdDocument } from '../../infrastructure/dtos/Validation';
import { ValidatorsRepository } from '../repository/ValidatorsRepository';

export interface ValidatorsUseCase {
  invoke: ( params: ISendValidationIdDocument ) => Promise<IResponseApi<string>>;
}

export class IdDocumentValidators implements ValidatorsUseCase {
  private objectUseCase: ValidatorsRepository;

  constructor( _objectUseCase: ValidatorsRepository ) {
    this.objectUseCase = _objectUseCase;
  }

  async invoke( params: ISendValidationIdDocument ): Promise<IResponseApi<string>> {
    return this.objectUseCase.IdDocument( params );
  }
}
