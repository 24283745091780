import { GridWrapper } from '../../../../../components/wrapper';
import CircularProgressWrapper from '../../../../../components/wrapper/LoaderWrapper';
import { Daum, IBodyInvite } from '../../../../../infrastructure/dtos/AgreementsInvites';
import AddAgreement from '../AddAgreement/AddAgreement';

interface IInviteList {
  inviteList: IBodyInvite
  loadingInvitelist: boolean
  handleSubmit: (reply: boolean, data: Daum) => Promise<void>
}

function InviteList({ handleSubmit, inviteList, loadingInvitelist }: IInviteList): JSX.Element {

  return (
    <>
      {loadingInvitelist ? (
        <GridWrapper
          sx={{
            paddingTop: '50px',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <CircularProgressWrapper
            size={'3rem'}
            data-testid="loading-spinner"
          />
        </GridWrapper>
      ) : (
        <>
          {inviteList.data.length > 0 && inviteList.data.map((item, index) => (
            <AddAgreement data={item} key={index} handleSubmit={handleSubmit} />
          ))}
        </>
      )}
    </>
  )
}

export default InviteList