import {
  IApiResponseSaveCodeDoctor,
  ISaveCodeDoctor,
} from '../../../infrastructure/dtos/SaveCode'
import { ISaveCodeService, SaveCodeService } from '../Persistencia/SaveCodeService'

interface ISaveCodeData {
  status: number
  data: string
}

export async function SaveCodeUtility(
  dataSaveCode: ISaveCodeDoctor,
): Promise<ISaveCodeData> {
  try {
    const { data, status } = await SaveCodeService(dataSaveCode)

    return {
      data: (data as IApiResponseSaveCodeDoctor).body as string,
      status: status === 1 && (data as IApiResponseSaveCodeDoctor).statusCode === 200
        ? status
        : 0,
    }
  } catch (error) {
    return {
      data: ((error as ISaveCodeService).data as IApiResponseSaveCodeDoctor).body as string,
      status: 0,
    }
  }
}
