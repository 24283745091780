import { getSkillsUtility } from '../../../../../services/Contracts/Utility/SkillsUtility'


interface IUseSkills {
  fetchSkills: () => Promise<void>
}

export interface IUseSkillsProps {
  query: string
  setOptions: (options: string[]) => void
  skill: string
}
export const useSkills = ({
  query,
  setOptions,
  skill,
}: IUseSkillsProps): IUseSkills => {
  const fetchSkills = async (): Promise<void> => {
    if (query?.length > 0 && skill !== query) {
      const response = await getSkillsUtility(query)
      const fetchedSkills = response?.data?.data?.map(
        (skill) => skill.skill_name,
      )
      if (fetchedSkills?.length > 0) {
        setOptions([...fetchedSkills, query])
      } else {
        setOptions([query])
      }
      return
    }
    setOptions([])
  }

  return {
    fetchSkills,
  }
}
