import { debounce } from '@mui/material'
import { useEffect, useMemo, useState } from 'react'

import { ICodeCie10 } from '../../../../infrastructure/dtos/SearchCie10'
import { GetSearchCie10Utility } from '../../../../services/Contracts/Utility/SearchCie10Utility'

interface IUseDiagnosticHook {
  diagnostics: ICodeCie10[]
  diagnosticSelected?: ICodeCie10
  diagnosticOptions: ICodeCie10[]
  loadDiagnosticOptions: boolean
  openDiagnosticOptions: boolean
  onChangeDiagnosticSelected(option: ICodeCie10): void
  onChangeDiagnosticInput(value: string): void
  onAddDiagnostics(newDiagnostic: ICodeCie10): void
  onDeleteDiagnostics(index: number): void
  onOpenDiagnosticOptions(value: boolean): void
  onSetDiagnostics(diagnostics: ICodeCie10[]): void
}
export function useDiagnostic(): IUseDiagnosticHook {
  const [diagnostics, setDiagnostics] = useState<ICodeCie10[]>([])
  const [diagnosticSelected, setDiagnosticSelected] = useState<ICodeCie10>()
  const [diagnosticInput, setDiagnosticInput] = useState<string>('')
  const [diagnosticOptions, setDiagnosticOptions] = useState<ICodeCie10[]>([])
  const [loadDiagnosticOptions, setLoadDiagnosticOptions] =
    useState<boolean>(false)
  const [openDiagnosticOptions, setOpenDiagnosticOptions] =
    useState<boolean>(false)

  const onOpenDiagnosticOptions = (value: boolean): void =>
    setOpenDiagnosticOptions(value)
  const onChangeDiagnosticSelected = (option: ICodeCie10): void =>
    setDiagnosticSelected(option)
  const onChangeDiagnosticInput = (value: string): void =>
    setDiagnosticInput(value)

  const fetchSearchCie10 = async (value: string): Promise<void> => {
    if (value) {
      setLoadDiagnosticOptions(true)
      const { data, status } = await GetSearchCie10Utility(value)
      if (status) {
        setDiagnosticOptions(data)
      }
      setLoadDiagnosticOptions(false)
    }
  }

  const onAddDiagnostics = (newDiagnostic: ICodeCie10): void => {
    const diagnosticsList: ICodeCie10[] = [...diagnostics]
    diagnosticsList.push(newDiagnostic)
    setDiagnostics(diagnosticsList)
    setDiagnosticSelected(undefined)
    setDiagnosticOptions([])
  }

  const onSetDiagnostics = (diagnostics: ICodeCie10[]): void => {
    setDiagnostics(diagnostics)
  }

  const onDeleteDiagnostics = (index: number): void => {
    const diagnosticsList: ICodeCie10[] = [...diagnostics]

    diagnosticsList.splice(index, 1)
    setDiagnostics(diagnosticsList)
  }

  const searchDelayed = useMemo(
    () => debounce(fetchSearchCie10, 600),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  )

  useEffect(() => {
    searchDelayed(diagnosticInput)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [diagnosticInput])

  useEffect(() => {
    if (!openDiagnosticOptions) {
      setDiagnosticOptions([])
    }
  }, [openDiagnosticOptions])

  return {
    diagnostics,
    diagnosticSelected,
    diagnosticOptions,
    loadDiagnosticOptions,
    openDiagnosticOptions,
    onOpenDiagnosticOptions,
    onChangeDiagnosticSelected,
    onChangeDiagnosticInput,
    onAddDiagnostics,
    onDeleteDiagnostics,
    onSetDiagnostics,
  }
}
