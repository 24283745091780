
import { create } from 'zustand'

import {IDoctorInformation} from '../infrastructure/dtos/DoctorInfo'


interface IDoctorInformationStore{
    doctorInformation: IDoctorInformation
    setDoctorInformation: (doctorInformation: IDoctorInformation) => void

}
export const useDoctorInformationStore = create<IDoctorInformationStore>((set) => ({
    doctorInformation: {} as IDoctorInformation,
    setDoctorInformation: (doctorInformation: IDoctorInformation) => set({ doctorInformation }),
}))
