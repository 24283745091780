import { GetVisibilityIntervals } from '../../../domain/useCase/GetVisibilityIntervals'
import { IApiResponseVisibilityIntervalsAWS } from '../../../infrastructure/dtos'
import VisibilityIntervalsMapper from '../../../infrastructure/http/mappers/VisibilityIntervalsMapper/VisibilityIntervalsMapper'
import { VisibilityIntervalsRepositoryImpl } from '../../../infrastructure/RepositoryImpl/VisibilityIntervalsRepositoryImpl'

interface IVisibilityIntervalsData {
  status: number
  data: IApiResponseVisibilityIntervalsAWS
}

export async function GetVisibilityIntervalsService(): Promise<IVisibilityIntervalsData> {
  try {
    const UseCaseRegistro: GetVisibilityIntervals = new GetVisibilityIntervals(
      new VisibilityIntervalsRepositoryImpl(new VisibilityIntervalsMapper()),
    )
    return {
      status: 1,
      data: await UseCaseRegistro.invoke(),
    }
  } catch (error: unknown) {
    return {
      status: 0,
      data: {
        statusCode: 503,
        body: (error as Error).message,
        headers: '',
      },
    }
  }
}
