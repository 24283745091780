import { Autocomplete } from '@mui/material'
import { Fragment } from 'react'

import { useDiagnostic } from './DiagnosticBodyHook'
import { TextFielDiagnostic } from './DiagnosticBodyStyle'
import { ChipWrapper, GridWrapper } from '../../../../components'
import { SecondaryMediumButton } from '../../../../components/SecondaryMediumButton/SecondaryMediumButton'
import { LabelInput } from '../../../../components/StyledComponents/LabelInput'
import CircularProgressWrapper from '../../../../components/wrapper/LoaderWrapper'
import { ICodeCie10 } from '../../../../infrastructure/dtos/SearchCie10'

interface IDiagnosticBodyProps {
  diagnostics: ICodeCie10[]
  diagnosticSelected?: ICodeCie10
  onAddDiagnostics: (newDiagnostic: ICodeCie10) => void
  onDeleteDiagnostics: (index: number) => void
  onChangeDiagnosticSelected: (option: ICodeCie10) => void
}

export const DiagnosticBody = ({
  diagnostics,
  diagnosticSelected,
  onAddDiagnostics,
  onDeleteDiagnostics,
  onChangeDiagnosticSelected,
}: IDiagnosticBodyProps): JSX.Element => {
  const {
    diagnosticOptions,
    loadDiagnosticOptions,
    openDiagnosticOptions,
    onOpenDiagnosticOptions,
    onChangeDiagnosticInput,
  } = useDiagnostic()

  const addText: string = diagnostics.length > 0 ? 'Agregar otra' : 'Agregar'

  return (
    <GridWrapper>
      <GridWrapper container spacing={4}>
        <GridWrapper item xs={8}>
          <LabelInput sx={{ fontWeight: 'normal', marginLeft: 0 }}>
            Diagnóstico
          </LabelInput>
          <Autocomplete
            value={diagnosticSelected || null}
            id="diagnostic-autocomplete"
            filterOptions={(x) => x}
            open={openDiagnosticOptions}
            options={diagnosticOptions}
            getOptionLabel={(opt: ICodeCie10) =>
              `${opt?.code} - ${opt?.description}`
            }
            onOpen={() => {
              onOpenDiagnosticOptions(true)
            }}
            onClose={() => {
              onOpenDiagnosticOptions(false)
            }}
            onChange={(event, diagnostic) =>
              onChangeDiagnosticSelected(diagnostic as ICodeCie10)
            }
            isOptionEqualToValue={(option: ICodeCie10, value: ICodeCie10) => {
              return option?.code === value?.code
            }}
            includeInputInList={false}
            onInputChange={(event, value) => onChangeDiagnosticInput(value)}
            renderInput={(params) => (
              <TextFielDiagnostic
                {...params}
                sx={{ height: '55px' }}
                placeholder="CÓDIGO CIE-10"
                variant="outlined"
                fullWidth
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <Fragment>
                      {loadDiagnosticOptions ? (
                        <CircularProgressWrapper
                          sx={{ color: 'primary', marginBottom: '6px' }}
                          size={20}
                        />
                      ) : null}
                    </Fragment>
                  ),
                }}
              />
            )}
          />
        </GridWrapper>
        <GridWrapper
          item
          xs={4}
          display={'flex'}
          alignItems={'flex-end'}
          justifyContent={'center'}
        >
          <SecondaryMediumButton
            id={'add-new-diagnostic'}
            type={'button'}
            disableElevation
            sx={{
              minWidth: '130px',
              fontSize: '12px',
              '@media (min-width: 1600px)': {
                fontSize: '16px',
              },
              '&.Mui-disabled': {
                backgroundColor: 'info.main',
                color: 'common.white',
              },
            }}
            text={addText}
            disabled={!diagnosticSelected}
            onClick={() => onAddDiagnostics(diagnosticSelected as ICodeCie10)}
          />
        </GridWrapper>
      </GridWrapper>
      {diagnostics.length > 0 && (
        <GridWrapper container marginTop={3} data-testid="diagnostic-items">
          {diagnostics.map((d, i) => (
            <ChipWrapper
              sx={{ marginRight: 1, marginBottom: 1 }}
              label={`${d?.code} - ${d?.description}`}
              onDelete={() => onDeleteDiagnostics(i)}
              key={`chip-${i}-${d?.code}`}
            />
          ))}
        </GridWrapper>
      )}
    </GridWrapper>
  )
}
