import { createContext, ReactNode, useReducer } from 'react'

import { authReducer, AuthState, Severity } from './AuthReducer'
import CustomSnackbar from '../components/Snackbar'

interface Props {
  children?: ReactNode
}

export type AuthContextType = {
  errorMessage: string
  removeError: () => void
  handleAlert(open: boolean, message: string, severity?: Severity): void
}

const authInitialState: AuthState = {
  errorMessage: '',
  snack: {
    open: false,
    message: '',
    severity: undefined,
  },
}

const AccountContext = createContext<AuthContextType | null | boolean>(null);
const AuthProvider = ({ children }: Props): JSX.Element => {
  const [state, dispatch] = useReducer(authReducer, authInitialState);

  const handleAlert = (
    open: boolean,
    message: string,
    severity?: Severity,
  ): void =>
    dispatch({
      type: 'snack',
      payload: {
        open,
        message,
        severity,
      },
    })


  const removeError = (): void => {
    dispatch({ type: 'removeError' });
  };

  return (
    <AccountContext.Provider
      value={{
        ...state,
        handleAlert,
        removeError,
      }}
    >
      {children}
      <CustomSnackbar
        handleAlert={handleAlert}
        openAlert={state.snack.open}
        message={state.snack.message}
        severity={state.snack.severity}
      />
    </AccountContext.Provider>
  )
}

export { AuthProvider, AccountContext }
