import { Share } from '@mui/icons-material'
import PlayCircleOutlineOutlinedIcon from '@mui/icons-material/PlayCircleOutlineOutlined'
import { styled } from '@mui/material/styles'

import {
  AppBarWrapper,
  ButtonWrapper,
  ListItemButtonWrapper,
  ListItemTextWrapper,
  ListWrapper,
  ToolbarWrapper,
} from '../wrapper'

export const Logo = styled(ToolbarWrapper)(() => ({
  margin: '40px 0 15px 0',
  display: 'flex',
  justifyContent: 'center',
}))

export const ShareBoutton = styled(ButtonWrapper)(({ theme }) => ({
  borderRadius: '20px',
  backgroundColor: theme.palette.common.white,
  '&:hover': {
    backgroundColor: '#efefef',
  },
  fontSize: '1.125rem',
  height: '4.0625rem',
  width: '215px',
  textTransform: 'none',
  marginLeft: '20px',
}))

export const SidebarItemList = styled(ListWrapper)(() => ({
  margin: '15px 0 15px 0',
}))

export const SidebarBottonItemList = styled(ListItemButtonWrapper)(({ theme }) => ({
  color: theme.palette.common.white,
  paddingLeft: '30px',
  marginBottom: '10px',
  '&:hover': {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.primary.dark,
    borderRadius: '50px 0 0 50px',
  },
  '&.Mui-selected': {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.primary.dark,
    borderRadius: '50px 0 0 50px',
    '&:hover': {
      backgroundColor: theme.palette.common.white,
      color: theme.palette.primary.dark,
      borderRadius: '50px 0 0 50px',
    },
  },
}))

export const SidebarListItemText = styled(ListItemTextWrapper)(() => ({
  marginLeft: '20px',
}))

export const SidebarShareIcon = styled(Share)(() => ({
  margin: '0 10px',
}))

/** ***************** Toolbar **********************/

export const ControlBar = styled(ToolbarWrapper)((props) => ({
  height: '100px',
  backgroundColor: '#ffffff',
  color: props.theme.palette.primary.dark,
}))

export const AppBarSide = styled(AppBarWrapper)(() => ({
  boxShadow:
    '0px 2px 0px -1px rgba(0,0,0,0.2), 0px 4px 0px 0px rgba(0,0,0,0.14), 0px 1px 0px 0px rgba(0,0,0,0.12)',
}))

export const CircularButton = styled(ButtonWrapper)((props) => ({
  borderRadius: '50%', // Hacer que el botón sea completamente redondo
  backgroundColor: props.theme.palette.common.white,
  color: '#3850b2',
  textTransform: 'none',
  marginRight: '20px',
  '&:hover': {
    backgroundColor: props.theme.palette.primary.main,
    color: props.theme.palette.background.paper,
  },
  filter: 'drop-shadow(0px 0px 6px rgba(0, 0, 0, 0.08))',
}))

export const NavButton = styled(ButtonWrapper)(({ theme }) => ({
  margin: '0 5px',
  height: '50px',
  width: '160px',
  textTransform: 'capitalize',
  borderRadius: '50px',
  backgroundColor: theme.palette.primary.dark,
  color: theme.palette.common.white,
  padding: '7px 12px',
  fontSize: '11px',
  '@media (min-width: 1601px)': {
    fontSize: '15px',
    width: '200px',
  },
  '&:hover': {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.background.paper,
  },
  '&.Mui-disabled': {
    backgroundColor: theme.palette.text.disabled,
    color: '#a3a3a3',
  },
}))

export const PlayIcon = styled(PlayCircleOutlineOutlinedIcon)(() => ({
  marginRight: '10px',
  fontSize: '18px',
}))
