  import dayjs from 'dayjs'
import { useContext } from 'react'

import NewDate from './components/ModalDatePicker/NewDate'
import { ButtonModal, CalendarDate } from './ModalDateStyles'
import { CalendarContext } from '../../contexts/CalendarContext'
import { useDoctorInformationStore } from '../../contexts/DoctorInformationState'
import { useSelectOffice } from '../../contexts/SelectOfficeState'
import { IMedicalOffice } from '../../infrastructure/dtos/Offices'
import { useHoursOfNonAttendanceModal } from '../../views/Dashboard/CalendarForm/Hooks/useHoursOfNonAttendanceModal'

export const ModalDate = (): JSX.Element => {
  const { doctorInformation } = useDoctorInformationStore()
  const { handleClose, handleOpen, open } = useHoursOfNonAttendanceModal()
  const { getEvents, currentCalendarDateToShow } = useContext(CalendarContext)
  const { officeSelected } = useSelectOffice()

  const handleUpdateCalendar = (officeId: string): void => {
    if (
      currentCalendarDateToShow
      && officeSelected?.some(
        (of: IMedicalOffice) => of.office_id === officeId && of.selected,
      )
    ) {
      getEvents(
        (currentCalendarDateToShow as dayjs.Dayjs).format('YYYY-MM-DD'),
        officeId,
        doctorInformation?.user_id as string,
      )
    }
  }

  return (
    <>
      <ButtonModal
        type="button"
        onClick={handleOpen}
        data-testid="modal-button"
      >
        <CalendarDate />
        Nueva Cita
      </ButtonModal>
      {open && (
        <NewDate
          handleClose={handleClose}
          open={open}
          handleUpdateCalendar={handleUpdateCalendar}
        />
      )}
    </>
  )
}
