import CloseIcon from '@mui/icons-material/Close'
import Backdrop from '@mui/material/Backdrop'

import { useNotifyDelay } from './hooks/useNotifyDelay'
import { GridWrapper } from '../../../../../components'
import FormTextInput from '../../../../../components/FormInputText'
import {
  BoxModal,
  FadeModal,
  GridModal,
  ModalComponent,
  TypographyModal,
} from '../../../../../components/ModalComponent'
import { CloseButtonModal } from '../../../../../components/ModalDate/ModalDateStyles'
import { SecondaryMediumButton } from '../../../../../components/SecondaryMediumButton/SecondaryMediumButton'
import SubmitButton from '../../../../../components/SubmitButton'
import CircularProgressWrapper from '../../../../../components/wrapper/LoaderWrapper'

interface INotifyDelayProps {
  handleSendNotification: (message: string, officeId: string) => void
  handleClose: () => void
  open: boolean
  officeId: string
  loading: boolean
}


export const NotifyDelay = ({
  handleSendNotification,
  handleClose,
  open,
  officeId,
  loading,
}: INotifyDelayProps): React.JSX.Element => {
  
  const {
    MAX_LENGTH,
    customOption,
    error,
    option,
    optionsList,
    loadingData,
    handleChangeOption,
  } = useNotifyDelay()
  return (
    <ModalComponent
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={handleClose}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <FadeModal in={open}>
        <BoxModal width={{ xs: '90%', md: '600px' }}>
          <GridModal>
            <TypographyModal id="transition-modal-title" variant="h6">
              Notifica a tu paciente que estás atrasado
            </TypographyModal>
            <CloseButtonModal
              onClick={handleClose}
              data-testid="close-button"
              sx={{
                right: 30,
                top: 40,
              }}
            >
              <CloseIcon />
            </CloseButtonModal>
            <GridWrapper gap={3} py={5}>
              {!loadingData && optionsList &&
                optionsList.map((opt: string, index: number) => (
                  <SecondaryMediumButton
                    aria-selected={option === opt}
                    key={index}
                    text={opt}
                    id={opt}
                    disabled={Boolean(customOption)}
                    fullWidth
                    onClick={() => handleChangeOption(opt)}
                    type={'button'}
                    variant={option === opt ? 'contained' : 'outlined'}
                    sx={
                      option === opt
                        ? {
                            backgroundColor: 'primary.main',
                            '&:hover': {
                              backgroundColor: 'primary.main',
                              color: 'white',
                            },
                            boxShadow: 0,
                          }
                        : {
                            backgroundColor: 'info.light',
                            border: 'none',
                            marginY: '10px',
                            color: 'text.primary',
                            fontWeight: 'normal',
                            '&:hover': {
                              backgroundColor: 'primary.main',
                              border: 'none',
                              color: 'common.white',
                              fontWeight: 'normal',
                            },
                            boxShadow: 0,
                          }
                    }
                  />
                ))}
              {loadingData && <GridWrapper display={'flex'} justifyContent={'center'}>
                <CircularProgressWrapper />
              </GridWrapper>}
              <FormTextInput
                id={''}
                type={'text'}
                placeholder={'Escribe tu propio mensaje'}
                inputProps={{ maxLength: MAX_LENGTH + 1 }}
                name={'custom-message'}
                value={customOption}
                onChange={handleChangeOption}
                sx={{
                  pt: '24px',
                  width: '100%',
                }}
                error={error}
                helperText={error ? `Máximo número de caracteres ${MAX_LENGTH}` : ''}
              />
            </GridWrapper>
            <GridWrapper display={'flex'} justifyContent={'center'}>
              <SubmitButton
                data-testid={'send-delay-notify'}
                id={'send-delay-notify'}
                variant={'contained'}
                fullWidth={false}
                type={'button'}
                text={'Enviar'}
                sx={{ width: '180px' }}
                disabled={(!option && !customOption) || error || loadingData || loading}
                onClick={() =>
                  handleSendNotification(
                    customOption ? customOption : option,
                    officeId,
                  )
                }
              />
            </GridWrapper>
          </GridModal>
        </BoxModal>
      </FadeModal>
    </ModalComponent>
  )
}
