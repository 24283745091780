import { FormControl, FormHelperText } from '@mui/material'
import React from 'react'

import useSelectOfficeHook from './hooks/useSelectOfficeHook'
import { SelectInput } from './SelectOfficeStyles'

import { IMedicalOffice } from '../../infrastructure/dtos/Offices'

import { MenuItemWrapper } from '../wrapper'

const SelectOffice = (): React.JSX.Element => {
  const { error, handleChangeOffice, isInactive, message, office, list } =
    useSelectOfficeHook()

  return (
    <FormControl error={error} data-testid="selectInput">
      <SelectInput
        name="selectInput"
        labelId="selectInputLabel"
        id="selectInput"
        value={office}
        onChange={handleChangeOffice}
        sx={{
          color: isInactive ? 'text.primary' : '',
        }}
      >
        {list.map((item: IMedicalOffice, index) => {
          return (
            <MenuItemWrapper
              data-testid={`item-${index}`}
              key={index}
              value={item.office_id}
              sx={{
                color: item.office_status != 'INACTIVE' ? 'common.black' : '',
                '@media (max-width: 1600px)': {
                  fontSize: '11px',
                },
                '@media (min-width: 1601px)': {
                  fontSize: '16px',
                },
              }}
            >
              {item.reference_medical_office}
            </MenuItemWrapper>
          )
        })}
      </SelectInput>
      {error && <FormHelperText>{message}</FormHelperText>}
    </FormControl>
  )
}

export default SelectOffice
