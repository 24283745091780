import { styled } from '@mui/material';

import { TabWrapper } from '../wrapper';

export const StyledTab = styled( TabWrapper )(({ theme }) => ({
  textTransform: 'capitalize',
  color: theme.palette.primary.main,
  fontSize: '0.9rem',
  '&.Mui-selected': {
    color: theme.palette.primary.dark,
    textDecoration: 'underline',
    fontWeight: 'bold'
  }
}))
