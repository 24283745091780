import CloseIcon from '@mui/icons-material/Close'
import { Backdrop } from '@mui/material'
import React, { useContext, useState } from 'react'

import { CloseButton, GridAttendance } from './styles'
import {
  BoxModal,
  FadeModal,
  GridModal,
  ModalComponent,
  TypographyModal,
} from '../../../../../components/ModalComponent'
import SubmitButton from '../../../../../components/SubmitButton'
import { AccountContext, AuthContextType } from '../../../../../contexts/AccountContext'
import { Appointment } from '../../../../../infrastructure/dtos/Appointments'
import { AttendanceService } from '../../../../../services/Contracts/Persistencia/AttendanceService'

interface IAttendanceProps {
  handleClose: () => void
  open: boolean
  appointmentSelected?: Appointment
  handleAfterSubmit: () => void
}

function Attendance({
  handleClose,
  open,
  appointmentSelected,
  handleAfterSubmit,
}: IAttendanceProps): React.JSX.Element {

  const { handleAlert } = useContext(AccountContext) as AuthContextType
  const [loading, setLoading] = useState<boolean>(false)

  const handleSubmit = async (assistance: boolean): Promise<void> => {
    try {
      setLoading(true)
      await AttendanceService({
        appointment_id: appointmentSelected?.appointment_id,
        assistance: assistance,
      })
      setLoading(false)
      handleAfterSubmit()
    } catch (error: unknown) {
      handleAlert(true, 'Error al actualizar asistencia', 'error')
    }
  }

  return (
    <ModalComponent
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      data-testid="modal-attendance"
      open={open}
      onClose={handleClose}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <FadeModal in={open}>
        <BoxModal>
          <GridModal>
            <CloseButton onClick={handleClose}>
              <CloseIcon />
            </CloseButton>
            <TypographyModal id="transition-modal-title" variant="h6">
              ¿El paciente asistió a la consulta?
            </TypographyModal>
            <GridAttendance>
              <SubmitButton
                fullWidth
                id="attended"
                data-testid="attended"
                text="Si, asistió"
                type="button"
                variant="contained"
                disabled={loading}
                onClick={() => handleSubmit(true)}
              />
              <SubmitButton
                id="didNotAttend"
                data-testid="didNotAttend"
                text="No, no asistió"
                type="button"
                variant="contained"
                fullWidth
                sx={{ backgroundColor: 'primary.main' }}
                disabled={loading}
                onClick={() => handleSubmit(false)}
              />
            </GridAttendance>
          </GridModal>
        </BoxModal>
      </FadeModal>
    </ModalComponent>
  )
}

export default Attendance
