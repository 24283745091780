import AddIcon from '@mui/icons-material/Add'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined'
import React, { SyntheticEvent, useState } from 'react'

import { DoctorInformation } from './components/DoctorInformation/DoctorInformation'
import { useDoctorInformation } from './components/DoctorInformation/hooks/useDoctorInformation'
import DoctorOffices from './components/DoctorOffices/DoctorOffices'
import Qualifications from './components/Qualifications/Qualifications'
import { ConfirmSchedule } from './components/UpdateSchedule/components/ConfirmSchedule/ConfirmSchedule'
import { useUpdateSchedule } from './components/UpdateSchedule/hooks/useUpdateSchedule'
import { UpdateSchedule } from './components/UpdateSchedule/UpdateSchedule'
import { SaveButton } from './styles'
import { BoxWrapper, GridWrapper, TabsWrapper } from '../../../components'
import { CustomTabPanel } from '../../../components/CustomTabPanel/CustomTabPanel'
import { StyledTab } from '../../../components/StyledComponents/StyledTab'
import { TabBox } from '../../../components/StyledComponents/TabBox'
import { useCreateOfficeStore } from '../../../contexts/NewOfficeState'

function a11yProps(index: number): {[key: string]: string} {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}

export function Profile(): React.JSX.Element {
  const [value, setValue] = useState<number>(0)

  const {
    dataOffice,
    onchangeOfficeMedical,
    dataOfficeObject,
    office,
    phoneNumber,
    setPhoneNumber,
    visibilityIntervals,
    appointmentTimes,
    days,
    selectedShowAgenda,
    selectedAppointmentDuration,
    showConfirmation,
    confirm,
    setShowConfirmation,
    setConfirm,
    setSelectedShowAgenda,
    setSelectedAppointmentDuration,
    UpdateOffice,
    validOfficeScheduleForm,
    setDays,
    resetDays,
    setHasRedirect,
    setTypesList,
    typesList,
    validOfficeAppointmentTypesForm,
    typesDB,
    durationsDB,
    loading
  } = useUpdateSchedule()

  const {
    dataDoctor,
    selectedImage,
    setDataDoctor,
    setSelectedImage,
    setSpecialty,
    specialties,
    specialty,
    updateDoctorInfo,
    handleAlert,
    isDisabled,
    error,
    message,
    setSkills,
    skills
  } = useDoctorInformation()
  const { setOpenCreateOffice } =  useCreateOfficeStore()

  const handleChange = (event: SyntheticEvent, newValue: number): void => {
    setValue(newValue)
  }

  const handleOnClick = (): void => {
    if (value === 0) {
      updateDoctorInfo()
    }

    if (value === 2) {
      UpdateOffice()
    }
  }

  const handleDisabled =
    value === 0
      ? isDisabled
      : value === 2
        ? (!validOfficeScheduleForm() && !validOfficeAppointmentTypesForm()) || loading
        : undefined

  const goToTab = (tab: number): void => {
    setValue(tab)
  }

  return (
    <BoxWrapper sx={{ width: '100%' }}>
      <TabBox sx={{ backgroundColor: 'tertiary.light' }}>
        <TabsWrapper
          value={value}
          onChange={handleChange}
          aria-label="Profile tab"
          TabIndicatorProps={{ style: { backgroundColor: 'transparent' } }}
        >
          <StyledTab
            label="Mi información"
            {...a11yProps(0)}
            role="tab"
            onClick={resetDays}
          />
          <StyledTab
            label="Mis consultorios"
            {...a11yProps(1)}
            role="tab"
            onClick={resetDays}
          />
          <StyledTab
            label="Mis horarios"
            {...a11yProps(2)}
            role="tab"
            onClick={resetDays}
          />
          <StyledTab
            label="Mis calificaciones"
            {...a11yProps(3)}
            role="tab"
            onClick={resetDays}
          />
        </TabsWrapper>
        <GridWrapper sx={{ marginLeft: 'auto' }}>
          {value === 1 ? (
            <SaveButton
              onClick={() => setOpenCreateOffice(true)
              }
            >
              <AddIcon />
              Agregar otro
            </SaveButton>
          ) : null}
          {value !== 3 && value !== 1 && (
            <SaveButton
              onClick={handleOnClick}
              disabled={handleDisabled}
              data-testid={'profile-save-button'}
            >
              <LockOutlinedIcon />
              Guardar
            </SaveButton>
          )}
        </GridWrapper>
      </TabBox>
      <CustomTabPanel value={value} index={0}>
        <DoctorInformation
          dataDoctor={dataDoctor}
          selectedImage={selectedImage}
          setDataDoctor={setDataDoctor}
          setSelectedImage={setSelectedImage}
          setSpecialty={setSpecialty}
          handleAlert={handleAlert}
          specialties={specialties}
          specialty={specialty}
          error={error}
          message={message}
          skills={skills}
          setSkills={setSkills}
        />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <DoctorOffices goToTab={goToTab}/>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={2}>
        <UpdateSchedule
          appointmentTimes={appointmentTimes}
          dataOffice={dataOffice}
          dataOfficeObject={dataOfficeObject}
          days={days}
          office={office}
          onchangeOfficeMedical={onchangeOfficeMedical}
          phoneNumber={phoneNumber}
          selectedAppointmentDuration={selectedAppointmentDuration}
          selectedShowAgenda={selectedShowAgenda}
          setPhoneNumber={setPhoneNumber}
          setSelectedAppointmentDuration={setSelectedAppointmentDuration}
          setSelectedShowAgenda={setSelectedShowAgenda}
          setDays={setDays}
          visibilityIntervals={visibilityIntervals}
          setTypesList={setTypesList}
          typesList={typesList}
          typesDB={typesDB}
          durationsDB={durationsDB}
        />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={3}>
        <Qualifications />
      </CustomTabPanel>
      <ConfirmSchedule
        handleClose={() => setShowConfirmation(false)}
        open={showConfirmation}
        handleConfirm={() => {
          setHasRedirect(true)
          setConfirm(true)
        }}
        confirm={confirm}
        title={'¿Estás seguro que deseas modificar el horario del consultorio?'}
        subtitle={
          'Desde este momento dejarás de recibir citas en el horario modificado. ' +
          'Recuerda reagendar o completar las citas ya agendadas en este consultorio.'
        }
      />
    </BoxWrapper>
  )
}
