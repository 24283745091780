import { QualificationRepository } from '../../domain/repository/QualificationRepository'
import {
  IApiResponseQualification,
  IResponseQualificationList,
  ISendQualificationStatus,
} from '../dtos/qualification'
import { IQualificationDataSource } from '../http/dataSource/QualificationDataSource'

export class QualificationRepositoryImpl implements QualificationRepository {
  dataSource: IQualificationDataSource

  constructor(_DataSource: IQualificationDataSource) {
    this.dataSource = _DataSource
  }

  async putQualificationStatus(
    params: ISendQualificationStatus,
  ): Promise<IApiResponseQualification<string>> {
    return this.dataSource.putQualificationStatus(params)
  }

  async getQualificationListDoctor(
    doctor_id: string,
  ): Promise<IApiResponseQualification<IResponseQualificationList>> {
    return this.dataSource.getQualificationListDoctor(doctor_id)
  }
}
