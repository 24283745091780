export const validateInput = ( name: string ): boolean => {
  const regex = /^[a-zA-Z0-9]+$/

  return regex.test( name )
}

export const validateNumberInput = ( name: string ): boolean => {
  const regex = /^[0-9]+$/

  return regex.test( name )
}
