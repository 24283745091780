import { SpecialtiesRepository } from '../../domain';
import { IApiResponseSpecialties } from '../dtos';
import SpecialtiesDataSource from '../http/dataSource/SpecialtiesDataSource';

export class SpecialtiesRepositoryImpl implements SpecialtiesRepository {
  dataSource: SpecialtiesDataSource;

  constructor( _datasource: SpecialtiesDataSource ) {
    this.dataSource = _datasource;
  }

  async postSpecialties( specialties: string ): Promise<IApiResponseSpecialties> {
    return this.dataSource.postSpecialties( specialties );
  }

  async getSpecialties(): Promise<IApiResponseSpecialties> {
    return this.dataSource.getSpecialties();
  }
}
