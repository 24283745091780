import { AxiosError } from 'axios'

import {
  BodyCheckScheduleImpactRequest,
  IApiResponseCheckScheduleImpact, ParamsCheckModifyOfficeImpact,
} from '../../../dtos/CheckScheduleImpact'
import { IScheduleDataSource } from '../../dataSource/ScheduleDataSource'
import { http } from '../../Http'


export class ScheduleMapper implements IScheduleDataSource {
  async checkScheduleImpact(
    body: BodyCheckScheduleImpactRequest,
  ): Promise<IApiResponseCheckScheduleImpact> {
    try {
      const { data } = await http.post<IApiResponseCheckScheduleImpact>(
        'save_data/check_schedule_impact',
        body,
      )
      return data
    } catch (error: unknown) {
      return {
        statusCode: (error as AxiosError)?.response?.status as number,
        body: {
          message: (error as AxiosError)?.response?.statusText as string,
          data: {},
        },
        headers: {
          'Access-Control-Allow-Headers': '',
          'Access-Control-Allow-Origin': '',
          'Access-Control-Allow-Methods': '',
        },
      }
    }
  }

  async checkModifyOfficeImpact(
    { office_id, user_id }: ParamsCheckModifyOfficeImpact,
  ): Promise<IApiResponseCheckScheduleImpact> {
    try {
      const { data } = await http.get<IApiResponseCheckScheduleImpact>(
        `send_data/check_modify_office_impact/${office_id}/${user_id}`
      )
      return data
    } catch (error: unknown) {
      return {
        statusCode: (error as AxiosError)?.response?.status as number,
        body: {
          message: (error as AxiosError)?.response?.statusText as string,
          data: {},
        },
        headers: {
          'Access-Control-Allow-Headers': '',
          'Access-Control-Allow-Origin': '',
          'Access-Control-Allow-Methods': '',
        },
      }
    }
  }
}
