import { AxiosError } from 'axios'

import {
  IApiResponseQualification,
  IResponseQualificationList,
  ISendQualificationStatus,
} from '../../../dtos/qualification'
import { IQualificationDataSource } from '../../dataSource/QualificationDataSource'
import { http } from '../../Http'

export default class QualificationMapper implements IQualificationDataSource {
  async putQualificationStatus(
    params: ISendQualificationStatus,
  ): Promise<IApiResponseQualification<string>> {
    try {
      const { data } = await http.put<IApiResponseQualification<string>>(
        `modified_data/qualification_status`,
        {
          body: params,
        },
      )

      return data
    } catch (error: unknown) {
      return {
        statusCode: (error as AxiosError)?.response?.status as number,
        body: (error as AxiosError)?.response?.statusText as string,
        headers: {
          'Access-Control-Allow-Headers': '',
          'Access-Control-Allow-Origin': '',
          'Access-Control-Allow-Methods': '',
        },
      }
    }
  }

  async getQualificationListDoctor(
    doctor_id: string,
  ): Promise<IApiResponseQualification<IResponseQualificationList>> {
    try {
      const { data } = await http.get<
        IApiResponseQualification<IResponseQualificationList>
      >(`send_data/qualification_list_doctor/${doctor_id}`)

      return data
    } catch (error: unknown) {
      return {
        statusCode: (error as AxiosError)?.response?.status as number,
        body: (error as AxiosError)?.response?.statusText as string,
        headers: {
          'Access-Control-Allow-Headers': '',
          'Access-Control-Allow-Origin': '',
          'Access-Control-Allow-Methods': '',
        },
      }
    }
  }
}
