import { IApiResponseYearsExperience, IYearsExperience } from '../../../infrastructure/dtos'
import { YearsExperienceService } from '../Persistencia/YearsExperienceService'

export interface IYearsExperienceUtility {
  status: number
  data: string[]
}

export const yearsExperienceUtility = async (): Promise<IYearsExperienceUtility> => {
  try {
    const { data } = await YearsExperienceService()

    const status = (data as IApiResponseYearsExperience).statusCode === 200 ? 1 : 0

    return {
      status,
      data: status
        ? JSON.parse(((data as IApiResponseYearsExperience).body as IYearsExperience).yearsExperience)
        : []
    }
  } catch (error: unknown) {
    return {
      status: 0,
      data: [],
    }
  }
}
