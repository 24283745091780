import { ReactElement } from 'react'

import { CustomProvider, DateRangePicker } from 'rsuite'
import esES from 'rsuite/locales/es_ES'

import { MedicalExamItem } from './components/MedicalExamsItem'
import { useMedicalExams } from './hooks/useMedicalExams'
import {
  GridWrapper,
  ListWrapper,
  TypographyWrapper,
} from '../../../../../../../components'
import { DateRangeInput } from '../../../../../../../components/Sidebar/components/SearchForm/styles'
import { Title } from '../../../../../../../components/StyledComponents/Title'
import CircularProgressWrapper from '../../../../../../../components/wrapper/LoaderWrapper'

import { MedicalExamsItem } from '../../../../../../../infrastructure/dtos/MedicalExamsItem'
import useMedicalRecord from '../MedicalRecord/hooks/useMedicalRecord'
import { PatientInfo } from '../PatientInfo/PatientInfo'

export default function MedicalExams(): ReactElement {
  const {
    fetchMessage,
    gridInfoBody,
    afterToday,
    loadingPatient,
    patient,
    nonRegistered,
    filterRange,
  } = useMedicalRecord()

  const { medicalExams, grid, gridHeader, loadingList } = useMedicalExams()

  return (
    <GridWrapper container px={2} pt={2}>
      <PatientInfo
        patient={patient}
        loadingPatient={loadingPatient}
        gridInfoBody={gridInfoBody}
        nonRegistered={nonRegistered}
      />
      <GridWrapper item xs={12} md={7} px={2}>
        {loadingList || loadingPatient ? (
          <GridWrapper
            sx={{
              paddingTop: '50px',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <CircularProgressWrapper
              id="loading-spinner"
              size={'3rem'}
              data-testid="loading-spinner"
            />
          </GridWrapper>
        ) : (
          <GridWrapper>
            <GridWrapper container>
              <GridWrapper item xs={6} display={'flex'} alignItems={'center'}>
                <Title sx={{ marginY: 0 }} data-testid="title-date">
                  Historial de exámenes médicos
                </Title>
              </GridWrapper>
              <GridWrapper
                item
                xs={6}
                display={'flex'}
                alignItems={'center'}
                justifyContent={'right'}
              >
                <DateRangeInput>
                  <CustomProvider locale={esES}>
                    <DateRangePicker
                      id={'filter-range'}
                      value={filterRange}
                      placeholder="dd/mm - dd/mm"
                      format="dd/MM/yyyy"
                      appearance="subtle"
                      size="sm"
                      showOneCalendar
                      ranges={[]}
                      shouldDisableDate={afterToday?.()}
                      style={{
                        width: '100%',
                        borderRadius: '52px',
                        backgroundColor: 'white',
                        padding: '8px 2px',
                      }}
                    />
                  </CustomProvider>
                </DateRangeInput>
              </GridWrapper>
            </GridWrapper>
            {medicalExams?.length > 0 && (
              <GridWrapper
                container
                display={'flex'}
                justifyContent={'center'}
                alignItems={'center'}
                px={'16px'}
              >
                {gridHeader.map((item, index) => (
                  <GridWrapper
                    item
                    textAlign={'center'}
                    xs={item.width}
                    key={`GridHeader-${index}`}
                    sx={{
                      color: 'common.black',
                    }}
                  >
                    <TypographyWrapper
                      variant="body2"
                      sx={{
                        fontSize: '12px',
                        '@media (min-width: 1600px)': {
                          fontSize: '16px',
                        },
                      }}
                    >
                      {item.title}
                    </TypographyWrapper>
                  </GridWrapper>
                ))}
              </GridWrapper>
            )}
            {medicalExams?.length > 0 ? (
              medicalExams?.map(
                (exam: MedicalExamsItem, datesIndex: number) => {
                  return (
                    <ListWrapper
                      key={`dates-${datesIndex}`}
                      data-testid={'history-list'}
                    >
                      <MedicalExamItem
                        medicalExam={exam}
                        gridBody={grid(exam)}
                      />
                    </ListWrapper>
                  )
                },
              )
            ) : (
              <GridWrapper mt={3}>{fetchMessage}</GridWrapper>
            )}
          </GridWrapper>
        )}
      </GridWrapper>
    </GridWrapper>
  )
}
