import CloseIcon from '@mui/icons-material/Close'
import { Backdrop } from '@mui/material'

import { useCancelReason } from './hooks/useCancelReason'
import { BoxModal, FadeModal, GridModal, TypographyModal } from './styles'
import FormTextInput from '../../../../../../../components/FormInputText'
import { CloseButtonModal } from '../../../../../../../components/ModalDate/ModalDateStyles'
import { SecondaryMediumButton } from '../../../../../../../components/SecondaryMediumButton/SecondaryMediumButton'
import SubmitButton from '../../../../../../../components/SubmitButton'
import {
  GridWrapper,
  ModalWrapper,
} from '../../../../../../../components/wrapper'
import { Appointment } from '../../../../../../../infrastructure/dtos/Appointments'

interface ICancelReasonProps {
  handleClose: () => void
  open: boolean
  appointment?: Appointment
  onCloseCancelReason: () => void
}

function CancelReason({
  handleClose,
  open,
  appointment,
  onCloseCancelReason,
}: ICancelReasonProps): React.JSX.Element {
  const {
    customOption,
    optionsList,
    option,
    error,
    MAX_LENGTH,
    loading,
    handleChangeOption,
    handleConfirmCancel,
  } = useCancelReason({
    appointment, isFetched: true, handleClose, onCloseCancelReason,
  })

  return (
    <ModalWrapper
      aria-labelledby="modal-cancel-reasons-title"
      aria-describedby="modal-cancel-reasons-description"
      open={open}
      role="dialog"
      onClose={handleClose}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <FadeModal in={open}>
        <BoxModal width={{ xs: '90%', md: '600px' }}>
          <GridModal>
            <TypographyModal id="modal-cancel-reasons-title" variant="h6">
              Coméntanos el motivo por el cual quieres cancelar esta cita
            </TypographyModal>
            <CloseButtonModal
              onClick={handleClose}
              data-testid="close-button"
              sx={{
                right: 30,
                top: 40,
              }}
            >
              <CloseIcon />
            </CloseButtonModal>
            <GridWrapper
              gap={3}
              py={5}
              id="modal-cancel-reasons-description"
            >
              {optionsList.map((opt: string, index: number) => (
                <SecondaryMediumButton
                  key={index}
                  text={opt}
                  id={`cancel-option-${index}`}
                  data-testid={`cancel-option-${index}`}
                  disabled={Boolean(customOption)}
                  fullWidth
                  onClick={() => handleChangeOption(opt)}
                  type={'button'}
                  variant={option === opt ? 'contained' : 'outlined'}
                  sx={
                    option === opt
                      ? {
                        backgroundColor: 'primary.main',
                        '&:hover': {
                          backgroundColor: 'primary.main',
                          color: 'white',
                        },
                        boxShadow: 0,
                      }
                      : {
                        backgroundColor: 'info.light',
                        border: 'none',
                        marginY: '10px',
                        color: 'text.primary',
                        fontWeight: 'normal',
                        '&:hover': {
                          backgroundColor: 'primary.main',
                          border: 'none',
                          color: 'common.white',
                          fontWeight: 'normal',
                        },
                        boxShadow: 0,
                      }
                  }
                />
              ))}
              <GridWrapper
                display={'flex'}
                justifyContent={'center'}
                flexDirection={'column'}
                mt={'20px'}
              >
                <FormTextInput
                  id={`custom-cancel-option`}
                  type={'text'}
                  placeholder={'Escribe tu propio mensaje'}
                  fullWidth
                  inputProps={{ maxLength: MAX_LENGTH + 1 }}
                  name={'custom-message'}
                  value={customOption}
                  onChange={handleChangeOption}
                  error={error}
                  helperText={
                    error ? `Máximo número de caracteres ${MAX_LENGTH}` : ''
                  }
                  sx={{
                    width: '100%',
                  }}
                />
              </GridWrapper>
            </GridWrapper>
            <GridWrapper
              display={'flex'}
              justifyContent={'center'}
              data-testid={'button-grid-wrapper'}
            >
              <SubmitButton
                data-testid={'send-cancel-reason'}
                id={'send-cancel-reason'}
                variant={'contained'}
                fullWidth={false}
                type={'button'}
                text={'Enviar'}
                sx={{ width: '180px' }}
                onClick={handleConfirmCancel}
                disabled={(!option && !customOption) || loading || error}
              />
            </GridWrapper>
          </GridModal>
        </BoxModal>
      </FadeModal>
    </ModalWrapper>
  )
}

export default CancelReason
