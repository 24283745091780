import { useEffect, useState } from 'react'

import {
   getAppointmentReasons,
} from '../../../../../services/Contracts/Utility/AppointmentTypesUtility'

interface IUseHook {
  types: string[];
}

export function useAppointmentReasons(): IUseHook {
  const [types, setTypes] = useState<string[]>([])

  useEffect(() => {
    fetchParameters().then();
  }, []);

  const fetchParameters = async (): Promise<void> => {
    const { status: statusReasons, data: dataReasons } =
      await getAppointmentReasons()
    if (statusReasons) {
      setTypes(dataReasons.data)
    }
  }

  return {
    types
  };
}
