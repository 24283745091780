import {
  IApiResponseAgreement,
  IBodyAgreements,
} from '../../../infrastructure/dtos/Agreements'
import {
  IApiResponseInvite,
  IBodyInvite,
} from '../../../infrastructure/dtos/AgreementsInvites'
import { Reply } from '../../../infrastructure/dtos/ReplyInvites'
import {
  GetAgreementsService,
  IAgreementService,
  IInviteService,
  InviteService,
  SendReplyService,
} from '../Persistencia/AgreementService'

export interface IAgreementUtility {
  status: number
  data: string | IBodyInvite
}

export interface IReplyUtility {
  status: number
  data: string
}

export interface IGetAgreementUtility {
  status: number
  data: string | IBodyAgreements
}

export async function InviteUtility(
  idDoctor: string,
): Promise<IAgreementUtility> {
  try {
    const { data, status }: IInviteService = await InviteService(idDoctor)

    return {
      data:
        typeof data === 'string'
          ? data
          : ((data as IApiResponseInvite).body as string | IBodyInvite),
      status:
        (data as IApiResponseInvite).statusCode === 200 && status === 1
          ? status
          : 0,
    }
  } catch (error: unknown) {
    return {
      status: 0,
      data: ((error as IInviteService).data as IApiResponseInvite)
        .body as string,
    }
  }
}

export async function ReplyUtility(params: Reply): Promise<IReplyUtility> {
  try {
    const { data, status } = await SendReplyService(params)
    return {
      data: data.body.message,
      status: status !== 1 || data.statusCode !== 200 ? 0 : status,
    }
  } catch (error: unknown) {
    return {
      data: (error as Error).message,
      status: 0,
    }
  }
}

export async function GetAgreementUtility(
  idDoctor: string,
): Promise<IGetAgreementUtility> {
  try {
    const { data, status } = await GetAgreementsService(idDoctor)

    return {
      data:
        typeof data === 'string'
          ? data
          : ((data as IApiResponseAgreement).body as string | IBodyAgreements),
      status:
        (data as IApiResponseAgreement).statusCode === 200 && status === 1
          ? status
          : 0,
    }
  } catch (error: unknown) {
    return {
      status: 0,
      data: ((error as IAgreementService).data as IApiResponseAgreement)
        .body as string,
    }
  }
}
