import { useState } from 'react'

interface IRescheduleProps {
  openReschedule: boolean
  handleOpenReschedule: () => void
  handleCloseReschedule: () => void
}

export function useReschedule(): IRescheduleProps {
  const [openReschedule, setOpenReschedule] = useState<boolean>(false)

  const handleOpenReschedule = (): void => {
    setOpenReschedule(true)
  }

  const handleCloseReschedule = (): void => setOpenReschedule(false)

  return {
    openReschedule,
    handleOpenReschedule,
    handleCloseReschedule,
  }
}
