import { useEffect, useState } from 'react'

import { Severity } from '../../../../../contexts/AuthReducer'
import { yearsExperienceUtility } from '../../../../../services/Contracts/Utility/YearsExperienceUtility'

interface IUseHook {
  amountRanges: string[]
}

export function useYearsExperience(
  handleAlert: (open: boolean, message: string, severity?: Severity) => void,
): IUseHook {
  const [amountRanges, setAmountRanges] = useState<string[]>([])

  useEffect(() => {
    if (amountRanges.length === 0) {
      fetchYearsExperience()
    }
  }, [])

  const fetchYearsExperience = async (): Promise<void> => {
    try {
      const { data, status} = await yearsExperienceUtility()

      if (status) {
        setAmountRanges(data)
      } else {
        throw new Error('')
      }
    } catch (error) {
      handleAlert(
        true,
        'Error al obtener listado de años de experiencia.',
        'error',
      )
    }
  }

  return {
    amountRanges,
  }
}
