import { IGendersResponse } from '../../infrastructure/dtos/Genders'
import { IGendersRepository } from '../repository/GendersRepository'

export interface IGendersUseCase {
  invoke: () => Promise<IGendersResponse>
}

export class GetGenders implements IGendersUseCase {
  private objectUseCase: IGendersRepository

  constructor(_objectUseCase: IGendersRepository) {
    this.objectUseCase = _objectUseCase
  }

  async invoke(): Promise<IGendersResponse> {
    return this.objectUseCase.getGenders()
  }
}
