import { AppointmentRepository } from '../../domain/repository/AppointmentRepository'
import {
  IApiResponseAppointment,
  IAppointmentDetails,
  IAppointmentInfoResponse,
  IAppointmentsByDoctorIdParams,
  IAppointmentsByDoctorIdResponse,
  IGetAppointmentProps,
  IResponseAppointment,
  ISaveNextAppointment,
  ISaveNextAppointmentParams,
} from '../dtos/Appointments'
import AppointmentDataSource from '../http/dataSource/AppointmentDataSource'

export class AppointmentRepositoryImpl implements AppointmentRepository {
  dataSource: AppointmentDataSource

  constructor(_datasource: AppointmentDataSource) {
    this.dataSource = _datasource
  }

  async getAppointment(
    params: IGetAppointmentProps,
  ): Promise<IApiResponseAppointment<IResponseAppointment>> {
    return this.dataSource.getAppointment(params)
  }

  async getAppointmentDetails(
    appointmentId: string,
  ): Promise<IApiResponseAppointment<IAppointmentDetails>> {
    return this.dataSource.getAppointmentDetails(appointmentId)
  }

  async getAppointmentInfo(
    appointmentId: string,
  ): Promise<IApiResponseAppointment<IAppointmentInfoResponse>> {
    return this.dataSource.getAppointmentInfo(appointmentId)
  }

  async saveNextAppointment(
    params: ISaveNextAppointmentParams,
  ): Promise<IApiResponseAppointment<ISaveNextAppointment>> {
    return this.dataSource.saveNextAppointment(params)
  }

  async getAppointmentsByDoctorId(
    params: IAppointmentsByDoctorIdParams
  ): Promise<IApiResponseAppointment<IAppointmentsByDoctorIdResponse>> {
    return this.dataSource.getAppointmentsByDoctorId(params)
  }
}
