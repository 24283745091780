import React, { ReactNode, createContext, useState } from 'react'

import { IPlaceType } from '../views/medical/ProfileMedical/RegisterConsultory/hooks/useRegisterConsultory'

export interface IOfficeMedical {
  address: string
  building_name: string
  building_floor: string
  office_number: string
  coordinates: {
    latitude: number
    longitude: number
  }
  consultationValue: string
  id: string
  state?: boolean
  value?: IPlaceType | null
}

export interface ICoordinates {
  latitude: number
  longitude: number
}
export interface IDaysMedicalCare {
  day: string
  from: string
  to: string
  active: boolean
  edit?: boolean
}
interface AppointmentType {
  id: number
  name: string
  erasable: boolean
  duration?: string
}

export interface ICareMedicalOffice {
  reference: string
  durationMedicalAppointment: string
  appointmentTypes: AppointmentType[]
  visualisationAgenda: string
  daysMedicalCare: IDaysMedicalCare[]
  id: string
  office_phone_number: string
}

export interface IRegisterMedicalState {
  name: string
  lastName: string
  description: string
  image: string
  studies: string
  phone: string
  idNumber: string
  idType: string
  gender: string
  experience: string
  specialty: string
  officeMedical: IOfficeMedical[]
  visualisationAgenda: string
  medicalCare: ICareMedicalOffice[]
  skills: string[]
}

interface IInfoScreen1 {
  name: string
  lastName: string
  description: string
  phone: string
  idNumber: string
  idType: string
  image: string
  gender: string
}

interface IInfoScreen2 {
  studies: string
  experience: string
  specialty: string
  skills: string[]
}

interface IInfoScreen3 {
  officeMedical: IOfficeMedical[]
}

interface IProps {
  children: ReactNode
}

export const RegisterMedicalInitState: IRegisterMedicalState = {
  name: '',
  lastName: '',
  description: '',
  phone: '',
  idNumber: '',
  idType: '',
  gender: '',
  image: '',
  studies: '',
  experience: '',
  specialty: '',
  officeMedical: [],
  visualisationAgenda: '',
  medicalCare: [],
  skills: [],
}

type RegisterMedicalContextProps = {
  RegisterMedical: IRegisterMedicalState
  savePhysicianProfile: (dataMedicalRegister: IInfoScreen1) => void
  saveDoctorExperience: (dataMedicalRegister: IInfoScreen2) => void
  saveOfficeMedical: (dataMedicalRegister: IInfoScreen3) => void
  saveOfficeMedicalScreen: (dataMedicalRegister: ICareMedicalOffice) => void
  updateMedicalCare: (updatedMedicalCare: ICareMedicalOffice[]) => void
}

export const RegisterMedicalContext = createContext(
  {} as RegisterMedicalContextProps,
)

export const RegisterMedicalProvider = ({ children }: IProps): JSX.Element => {
  const [RegisterMedical, setRegisterMedical] = useState(
    RegisterMedicalInitState,
  )

  const savePhysicianProfile = (dataMedicalRegister: IInfoScreen1): void => {
    setRegisterMedical({
      ...RegisterMedical,
      name: dataMedicalRegister.name,
      lastName: dataMedicalRegister.lastName,
      description: dataMedicalRegister.description,
      image: dataMedicalRegister.image,
      phone: dataMedicalRegister.phone,
      idNumber: dataMedicalRegister.idNumber,
      gender: dataMedicalRegister.gender,
      idType: dataMedicalRegister.idType,
    })
  }

  const saveDoctorExperience = (dataMedicalRegister: IInfoScreen2): void => {
    setRegisterMedical({
      ...RegisterMedical,
      studies: dataMedicalRegister.studies,
      experience: dataMedicalRegister.experience,
      specialty: dataMedicalRegister.specialty,
      skills: dataMedicalRegister.skills,
    })
  }

  const saveOfficeMedical = (dataMedicalRegister: IInfoScreen3): void => {
    setRegisterMedical({
      ...RegisterMedical,
      officeMedical: dataMedicalRegister.officeMedical,
    })
  }

  const saveOfficeMedicalScreen = (
    dataMedicalRegister: ICareMedicalOffice,
  ): void => {
    // valido si existe
    const searchReference = dataMedicalRegister.id
    const careMedicalOfficeIndex = RegisterMedical.medicalCare.findIndex(
      (care) => care.id === searchReference,
    )

    // si existe actualizo
    if (careMedicalOfficeIndex !== -1) {
      const updatedMedicalCare = RegisterMedical.medicalCare.map(
        (care, index) =>
          index === careMedicalOfficeIndex ? dataMedicalRegister : care,
      )

      setRegisterMedical({
        ...RegisterMedical,
        medicalCare: updatedMedicalCare,
      })
    }

    // si no existe creo
    if (careMedicalOfficeIndex === -1) {
      const updatedMedicalCare =
        RegisterMedical.medicalCare.concat(dataMedicalRegister)

      setRegisterMedical({
        ...RegisterMedical,
        medicalCare: updatedMedicalCare,
      })
    }
  }

  const updateMedicalCare = (
    updatedMedicalCare: ICareMedicalOffice[],
  ): void => {
    setRegisterMedical({
      ...RegisterMedical,
      medicalCare: updatedMedicalCare,
    })
  }

  return (
    <RegisterMedicalContext.Provider
      value={{
        RegisterMedical: RegisterMedical,
        savePhysicianProfile: savePhysicianProfile,
        saveDoctorExperience: saveDoctorExperience,
        saveOfficeMedical: saveOfficeMedical,
        saveOfficeMedicalScreen: saveOfficeMedicalScreen,
        updateMedicalCare,
      }}
    >
      {children}
    </RegisterMedicalContext.Provider>
  )
}
