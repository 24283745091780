import {
  IServiceSearchCie10,
  IUtilitySearchCie10,
} from '../../../infrastructure/dtos/SearchCie10'
import { GetSearchCie10Service } from '../Persistencia/SearchCie10Service'

export async function GetSearchCie10Utility(
  query: string,
): Promise<IUtilitySearchCie10> {
  try {
    const value_appointment: IServiceSearchCie10 =
      await GetSearchCie10Service(query)

    if (
      value_appointment.status !== 1 ||
      value_appointment.data.statusCode !== 200
    ) {
      return {
        data: [],
        status: 0,
      }
    }

    return {
      data: value_appointment.data.body,
      status: value_appointment.status,
    }
  } catch (error) {
    return {
      data: [],
      status: 0,
    }
  }
}
