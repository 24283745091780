import { styled } from '@mui/material'

import {
  GridWrapper,
  IconButtonWrapper
} from '../../../../../components/wrapper'

export const CloseButton = styled( IconButtonWrapper )(( props ) => ({
  position: 'absolute',
  right: 20,
  top: 20,
  color: props.theme.palette.primary.dark,
  marginBottom: '20px'
}))

export const GridAttendance = styled( GridWrapper )(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  gap: '23px',
  marginTop: '30px'
}))
