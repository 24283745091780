import { IApiResponseSpecialties, ISpecialties } from '../../../infrastructure/dtos'
import { SpecialtiesService } from '../Persistencia/SpecialtiesService'

export interface ISpecialtiesUtility {
  status: number
  data: ISpecialties
}

export const specialtiesUtility = async (): Promise<ISpecialtiesUtility> => {
  try {
    const { data } = await SpecialtiesService()

    return {
      status: (data as IApiResponseSpecialties).statusCode === 200 ? 1 : 0,
      data: (data as IApiResponseSpecialties).body,
    }
  } catch (error: unknown) {
    return {
      status: 0,
      data: {
        specialties: [],
      },
    }
  }
}
