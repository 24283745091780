import { styled } from '@mui/material'

import { StackWrapper } from '../wrapper'

interface IGridBackButton {
  buttonposition: number
}

export const StackButton = styled(StackWrapper)<IGridBackButton>(
  ({ buttonposition }) => ({
    position: 'absolute',
    left: 20,
    top: buttonposition,
  }),
)
