import { CalendarInfoRepository } from '../../domain/repository/CalendarInfoRepository'
import { IResponseGetCalendarEvent } from '../dtos/CalendarInfo'
import CalendarInfoDataSource from '../http/dataSource/CalendarInfoDataSource'

export class CalendarInfoRepositoryImpl implements CalendarInfoRepository {
  dataSource: CalendarInfoDataSource

  constructor( _datasource: CalendarInfoDataSource ) {
    this.dataSource = _datasource
  }

  async getCalendarInfo(
    idDoctor: string,
    idOffice: string,
    duration: number,
    officeType: string,
  ): Promise<IResponseGetCalendarEvent> {
    return this.dataSource.getCalendarInfo( idDoctor, idOffice, duration, officeType )
  }
}
