import { AxiosError } from 'axios'

import {
  IClinicalPictureResponse,
  ISaveClinicalPictureServiceParams,
} from '../../../dtos/ClinicalPicture'
import ClinicalPictureDataSource from '../../dataSource/ClinicalPictureDataSource'
import { http } from '../../Http'

export default class ClinicalPictureMapper
  implements ClinicalPictureDataSource
{
  async saveClinicalPicture(
    params: ISaveClinicalPictureServiceParams,
  ): Promise<IClinicalPictureResponse> {
    try {
      const { data } = await http.put<IClinicalPictureResponse>(
        '/modified_data/clinical_picture',
        params,
      )

      return data
    } catch (error: unknown) {
      return {
        statusCode: (error as AxiosError).response?.status as number,
        body: (error as AxiosError).response?.statusText as string,
        headers: {
          'Access-Control-Allow-Headers': '',
          'Access-Control-Allow-Origin': '',
          'Access-Control-Allow-Methods': '',
        },
      }
    }
  }
}
