import { MedicalRegisterRepository } from '../../domain/repository/MedicalRegisterRepository';
import {
  IApiResponseSaveRegisterMedical,
  ISaveRegisterMedical,
} from '../dtos/MedicalRegister';
import MedicalRegisterDataSource from '../http/dataSource/MedicalRegisterDataSource';

export class MedicalRegisterRepositoryImpl
implements MedicalRegisterRepository
{
  dataSource: MedicalRegisterDataSource;

  constructor( _datasource: MedicalRegisterDataSource ) {
    this.dataSource = _datasource;
  }

  async postMedicalRegister(
    dataMedical: ISaveRegisterMedical
  ): Promise<IApiResponseSaveRegisterMedical> {
    return this.dataSource.postMedicalRegister( dataMedical );
  }
}
