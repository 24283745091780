import { Checkbox, CheckboxProps } from '@mui/material'
import React from 'react'

type CheckboxWrapperProps = Omit<CheckboxProps, 'ref'> &
  React.RefAttributes<HTMLButtonElement>

const CheckboxWrapper: React.ForwardRefExoticComponent<CheckboxWrapperProps> =
  React.forwardRef<HTMLButtonElement, CheckboxProps>((props, ref) => (
    <Checkbox {...props} ref={ref} />
  ))

export default CheckboxWrapper
