import { IApiResponseYearsExperience } from '../../infrastructure/dtos/YearsExperience';
import { YearsExperienceRepository } from '../repository/YearsExperienceRepository';


export interface GetYearsExperienceUseCase {
  invoke: () => Promise<IApiResponseYearsExperience>
}

export class GetYearsExperience implements GetYearsExperienceUseCase {
  private objectUseCase: YearsExperienceRepository

  constructor( _objectUseCase: YearsExperienceRepository ) {
    this.objectUseCase = _objectUseCase;
  }

  async invoke(): Promise<IApiResponseYearsExperience> {
    return this.objectUseCase.getYearsExperience()
  }
}