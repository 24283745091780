import React, { Dispatch, useContext, useEffect, useState } from 'react'

import { useNavigate } from 'react-router-dom'

import {
  AccountContext,
  AuthContextType,
} from '../../../../../../contexts/AccountContext'
import { useDoctorInformationStore } from '../../../../../../contexts/DoctorInformationState'
import { IDaysMedicalCare } from '../../../../../../contexts/RegisterContext'
import { useSelectOffice } from '../../../../../../contexts/SelectOfficeState'
import {
  IAppointmentTypes,
  IMedicalOffice,
  OpeningHoursType,
} from '../../../../../../infrastructure/dtos/Offices'
import {
  IDayOpening,
  IModifiedDataOffice,
} from '../../../../../../infrastructure/dtos/UpdateOffices'
import { checkScheduleImpactUtility } from '../../../../../../services/Contracts/Utility/ScheduleUtility'
import { UpdateOfficesUtility } from '../../../../../../services/Contracts/Utility/UpdateOfficesUtility'
import { transformMinutesNumToString } from '../../../../../../utils/tranformMinutesNumToString'
import { validateOfficePhoneNumber } from '../../../../../../utils/validatePhoneNumber'
import { Duration } from '../../../../../medical/ProfileMedical/Components/hooks/useAppointmentTypesList'
import { useAppointmentDurations } from '../../../../../medical/ProfileMedical/ProfileMedicalScheduleSetting/Hooks/useAppointmentDurations'
import { useAppointmentReasons } from '../../../../../medical/ProfileMedical/ProfileMedicalScheduleSetting/Hooks/useAppointmentReasons'
import { AppointmentType } from '../../../../../medical/ProfileMedical/ProfileMedicalScheduleSetting/Hooks/useProfileMedicalScheduleSetting'
import { useVisibilityAppointmentTime } from '../../../../../medical/ProfileMedical/ProfileMedicalScheduleSetting/Hooks/useVisibilityAppointmentTime'
import { useVisibilityIntervals } from '../../../../../medical/ProfileMedical/ProfileMedicalScheduleSetting/Hooks/useVisibilityIntervals'

interface IUpdateScheduleHook {
  dataOffice: string[]
  dataOfficeObject: IDataOffice[]
  onchangeOfficeMedical(value: string, id: string): void
  office: IDataOffice
  phoneNumber: string
  setPhoneNumber: Dispatch<React.SetStateAction<string>>
  visibilityIntervals: string[]
  appointmentTimes: string[]
  days: { [key: string]: IDaysMedicalCare }
  selectedShowAgenda: string
  selectedAppointmentDuration: string
  showConfirmation: boolean
  confirm: boolean
  setShowConfirmation: Dispatch<React.SetStateAction<boolean>>
  setConfirm: React.Dispatch<React.SetStateAction<boolean>>
  setDays: React.Dispatch<
    React.SetStateAction<{
      [key: string]: IDaysMedicalCare
    }>
  >
  setSelectedShowAgenda: Dispatch<React.SetStateAction<string>>
  setSelectedAppointmentDuration: Dispatch<React.SetStateAction<string>>
  UpdateOffice(): Promise<void>
  validOfficeScheduleForm(): boolean
  resetDays(): void
  setHasRedirect: Dispatch<React.SetStateAction<boolean>>
  typesList: AppointmentType[]
  setTypesList: Dispatch<React.SetStateAction<AppointmentType[]>>
  validOfficeAppointmentTypesForm(): boolean
  durationsDB: Duration[]
  typesDB: string[]
  loading: boolean
}

export interface IDataOffice {
  reference: string
  id: string
}

const monthDaysCounter: { [key: string]: string } = {
  '30': '1 mes',
  '60': '2 meses',
  '90': '3 meses',
}

export const daysToWeek: { [key: string]: IDaysMedicalCare } = {
  monday: {
    day: 'lunes',
    active: false,
    from: '',
    to: '',
  },
  tuesday: {
    day: 'martes',
    active: false,
    from: '',
    to: '',
  },
  wednesday: {
    day: 'miércoles',
    active: false,
    from: '',
    to: '',
  },
  thursday: {
    day: 'jueves',
    active: false,
    from: '',
    to: '',
  },
  friday: {
    day: 'viernes',
    active: false,
    from: '',
    to: '',
  },
  saturday: {
    day: 'sábado',
    active: false,
    from: '',
    to: '',
  },
  sunday: {
    day: 'domingo',
    active: false,
    from: '',
    to: '',
  },
}

export function useUpdateSchedule(): IUpdateScheduleHook {
  const [loading, setLoading] = useState(false)
  const { officeSelected, setOfficeSelected } = useSelectOffice()
  const { doctorInformation } = useDoctorInformationStore()
  const { handleAlert } = useContext(AccountContext) as AuthContextType
  const { amountRanges: visibilityIntervals } =
    useVisibilityIntervals(handleAlert)
  const { types: typesDB } = useAppointmentReasons()
  const { durations: durationsDB } = useAppointmentDurations()
  const { lists: appointmentTimes } = useVisibilityAppointmentTime(handleAlert)
  const [days, setDays] = useState<{ [key: string]: IDaysMedicalCare }>({})
  const [prevDays, setPrevDays] = useState<{ [key: string]: IDaysMedicalCare }>(
    {},
  )
  const [office, setOffice] = useState<IDataOffice>({
    reference: officeSelected
      ? officeSelected[0]?.reference_medical_office
      : '',
    id: officeSelected ? officeSelected[0]?.office_id : '',
  })
  const [validSchedule, setValidSchedule] = useState<boolean>(true)
  const [hasRedirect, setHasRedirect] = useState<boolean>(false)

  const [phoneNumber, setPhoneNumber] = useState<string>('')
  const [selectedShowAgenda, setSelectedShowAgenda] = useState<string>('')
  const [selectedAppointmentDuration, setSelectedAppointmentDuration] =
    useState<string>('')

  const [showConfirmation, setShowConfirmation] = useState<boolean>(false)
  const [confirm, setConfirm] = useState<boolean>(false)

  const [typesList, setTypesList] = useState<AppointmentType[]>([])
  const [prevTypesList, setPrevTypesList] = useState<AppointmentType[]>([])

  const [validList, setValidList] = useState<boolean>(true)

  const navigate = useNavigate()
  const onchangeOfficeMedical = (value: string, id: string): void => {
    setOffice({
      reference: value,
      id: id,
    })
  }

  const isScheduleChange = (): boolean =>
    JSON.stringify(days) !== JSON.stringify(prevDays)

  const isTypeListChange = (): boolean =>
    JSON.stringify(typesList) !== JSON.stringify(prevTypesList)

  const convertMonthToDays = (monthStr: string): string => {
    const month = monthStr.split(' ')[0]
    return (Number(month) * 30).toString()
  }

  const transformScheduleToSend = (): IDayOpening[] => {
    return Object.keys(days).map((day: string) => {
      return {
        hour_close: days[day].to.replace('h', ':'),
        hour_open: days[day].from.replace('h', ':'),
        name: day,
      }
    })
  }

  const transformAppointmentTypesToComponent = (
    types: IAppointmentTypes[],
  ): AppointmentType[] => {
    return types?.map((type, index: number) => {
      return {
        id: index + 1,
        name: type.name,
        duration_value: type.duration,
        duration: transformMinutesNumToString(type.duration),
        erasable: index !== 0,
      }
    })
  }

  const handleUpdateOffice = async (
    dataToSend: IModifiedDataOffice,
  ): Promise<void> => {
    try {
      setLoading(true)
      const { data, status } = await UpdateOfficesUtility(dataToSend)
      setPrevDays(days)
      setPrevTypesList(typesList)
      setShowConfirmation(false)
      setConfirm(false)
      setLoading(false)
      const officeIndex: number = officeSelected?.findIndex(
        (of) => of.office_id === office.id,
      )

      let newOfficeSelected = structuredClone(officeSelected)

      newOfficeSelected = newOfficeSelected.map((office) => {
        return {
          ...office,
          selected: false,
        }
      })

      newOfficeSelected[officeIndex] = {
        ...newOfficeSelected[officeIndex],
        office_phone: phoneNumber,
        selected: true,
        visibility_medical_office_days: convertMonthToDays(selectedShowAgenda),
        opening_hours: transformScheduleToSend().reduce(
          (acc: { [key: string]: IDayOpening }, day: IDayOpening) => {
            acc[day?.name] = {
              name: day.name,
              hour_open: day.hour_open,
              hour_close: day.hour_close,
            }
            return acc
          },
          {},
        ),
        appointment_types: typesList.map((type: AppointmentType) => {
          return {
            name: type.name,
            duration: type.duration_value as number,
          }
        }),
      }

      setOfficeSelected(newOfficeSelected)

      handleAlert(true, data, status ? 'success' : 'warning')

      if (hasRedirect) {
        navigate('/dashboard/appointments')
      }
      setHasRedirect(false)
    } catch (error: unknown) {
      setLoading(false)
      throw new Error(`handle error: ${error}`)
    }
  }

  const validateTypeList = (): void => {
    if (typesList?.length === 0) {
      setValidList(true)
      return
    }
    // validate if all types have duration and have name
    setValidList(!typesList?.every((type) => type.duration && type.name))
  }

  const validateSchedule = (): void => {
    setValidSchedule(true)
    Object.keys(days).forEach((day) => {
      if (days[day].from >= days[day].to) {
        setValidSchedule(false)
      }
    })
  }

  const resetDays = (): void => {
    if (isScheduleChange()) {
      const daysToModify: { [key: string]: IDaysMedicalCare } =
        structuredClone(prevDays)
      Object.keys(daysToModify).forEach((day) => {
        daysToModify[day].edit = false
      })
      setDays(daysToModify)
    }
  }

  const UpdateOffice = async (): Promise<void> => {
    let dataToSend: IModifiedDataOffice
    try {
      dataToSend = {
        office_id: office.id,
        office_phone: phoneNumber,
        visibility_medical_office_days: selectedShowAgenda,
        user_id: doctorInformation?.user_id as string,
        type_visibility: 'm',
      }

      if (isScheduleChange()) {
        const schedule = transformScheduleToSend()
        const { data } = await checkScheduleImpactUtility({
          office_id: office.id,
          user_id: doctorInformation?.user_id as string,
          new_schedule: schedule,
        })

        if (data.data.schedule_impact) {
          setShowConfirmation(true)
          return
        }

        dataToSend = {
          ...dataToSend,
          day_opening: schedule,
        }
      }
      if (isTypeListChange()) {
        dataToSend = {
          ...dataToSend,
          appointment_types: typesList.map((type: AppointmentType) => {
            return {
              name: type.name,
              duration: type.duration_value as number,
            }
          }),
        }
      }
      await handleUpdateOffice(dataToSend)
    } catch (error: unknown) {
      handleAlert(true, 'Error al actualizar los datos de la oficina', 'error')
    }
  }

  const dataOfficeObject: IDataOffice[] = officeSelected
    ?.filter((item: IMedicalOffice) => item.office_status !== 'INACTIVE')
    .map((item: IMedicalOffice) => {
      return {
        reference: item.reference_medical_office,
        id: item.office_id,
      }
    }) as IDataOffice[]

  const dataOffice: string[] = officeSelected
    ?.filter((item: IMedicalOffice) => item.office_status !== 'INACTIVE')
    .map((item: IMedicalOffice) => item.reference_medical_office)

  const formatHourToShow = (hourString: string): string => {
    const [hour, minutes] = hourString.split(':')
    const formattedHour = parseInt(hour, 10).toString().padStart(2, '0')
    const formattedMinutes = parseInt(minutes, 10).toString().padStart(2, '0')
    return `${formattedHour}h${formattedMinutes}`
  }

  const validOfficeScheduleForm = (): boolean => {
    if (officeSelected) {
      let officeIndex: number = officeSelected?.findIndex(
        (of) => of.office_id === office.id,
      )

      if (officeIndex === -1) {
        officeIndex = 0
      }

      return (
        (isScheduleChange() && validSchedule) ||
        selectedShowAgenda !==
        monthDaysCounter[
        officeSelected[officeIndex].visibility_medical_office_days
        ] ||
        (phoneNumber !== officeSelected[officeIndex].office_phone &&
          validateOfficePhoneNumber(phoneNumber))
      )
    }
    return false
  }

  const validOfficeAppointmentTypesForm = (): boolean => {
    if (officeSelected) {
      let officeIndex: number = officeSelected?.findIndex(
        (of) => of.office_id === office.id,
      )

      if (officeIndex === -1) {
        officeIndex = 0
      }

      return (
        (isTypeListChange() ||
          (phoneNumber !== officeSelected[officeIndex].office_phone &&
            validateOfficePhoneNumber(phoneNumber))) &&
        !validList
      )
    }
    return false
  }

  useEffect(() => {
    if (officeSelected.length > 0) {
      let officeIndex: number = officeSelected?.findIndex(
        (of) => of.office_id === office.id,
      )

      if (officeIndex === -1) {
        officeIndex = 0
      }

      setSelectedAppointmentDuration(
        officeSelected[officeIndex]?.consultation_time_minutes,
      )
      setSelectedShowAgenda(
        monthDaysCounter[
        officeSelected[officeIndex]?.visibility_medical_office_days
        ],
      )
      setTypesList(
        transformAppointmentTypesToComponent(
          officeSelected[officeIndex]?.appointment_types as IAppointmentTypes[],
        ),
      )
      setPrevTypesList(
        transformAppointmentTypesToComponent(
          officeSelected[officeIndex]?.appointment_types as IAppointmentTypes[],
        ),
      )
      setPhoneNumber(officeSelected[officeIndex]?.office_phone as string)
      const data: { [key: string]: IDaysMedicalCare } = Object.keys(
        daysToWeek,
      ).reduce((acc: { [key: string]: IDaysMedicalCare }, day: string) => {
        const opening_hours_data: OpeningHoursType = officeSelected[officeIndex]
          ?.opening_hours as OpeningHoursType
        if (
          !opening_hours_data[day]?.hour_open &&
          !opening_hours_data[day]?.hour_close
        ) {
          return acc
        }

        acc[day] = {
          ...daysToWeek[day],
          from: formatHourToShow(opening_hours_data[day].hour_open),
          to: formatHourToShow(opening_hours_data[day].hour_close),
          edit: false,
        }
        return acc
      }, {})

      setDays(data)
      setPrevDays(data)
    }
  }, [office])

  useEffect(() => {
    if (confirm) {
      handleUpdateOffice({
        office_id: office.id,
        office_phone: phoneNumber,
        visibility_medical_office_days: selectedShowAgenda,
        user_id: doctorInformation?.user_id as string,
        day_opening: transformScheduleToSend(),
        type_visibility: 'm',
      })
    }
  }, [confirm])

  useEffect(() => {
    validateSchedule()
    validateTypeList()
  }, [days, typesList])
  return {
    dataOffice,
    office,
    dataOfficeObject,
    phoneNumber,
    visibilityIntervals,
    appointmentTimes,
    days,
    selectedShowAgenda,
    selectedAppointmentDuration,
    showConfirmation,
    confirm,
    setShowConfirmation,
    setConfirm,
    setDays,
    UpdateOffice,
    setSelectedAppointmentDuration,
    setSelectedShowAgenda,
    onchangeOfficeMedical,
    setPhoneNumber,
    validOfficeScheduleForm,
    resetDays,
    setHasRedirect,
    setTypesList,
    typesList,
    validOfficeAppointmentTypesForm,
    typesDB,
    durationsDB,
    loading,
  }
}
