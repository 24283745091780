import { ICancelAppointmentRepository } from '../../domain/repository/CancelAppointmentRepository'
import { ICancelService } from '../../services/Contracts/Persistencia/CancelAppointmentService'
import { ICancelAppointmentResponse } from '../dtos/CancelAppointment'
import CancelAppointmentDataSource from '../http/dataSource/CancelAppointmentDataSource'

export class CancelAppointmentRepositoryImpl
  implements ICancelAppointmentRepository
{
  dataSource: CancelAppointmentDataSource

  constructor(_datasource: CancelAppointmentDataSource) {
    this.dataSource = _datasource
  }

  cancelAppointment(
    dataCancelAppointment: ICancelService,
  ): Promise<ICancelAppointmentResponse> {
    return this.dataSource.cancelAppointment(dataCancelAppointment)
  }
}
