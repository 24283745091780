import { RoomOutlined } from '@mui/icons-material'
import { Autocomplete } from '@mui/material'
import { styled } from '@mui/material/styles'

import { TextFieldWrapper, TypographyWrapper } from '../../../../../../components'
import BoxWrapper from '../../../../../../components/wrapper/BoxWrapper'
import GridWrapper from '../../../../../../components/wrapper/GridWrapper'

export const TextFielRegister = styled( TextFieldWrapper )(( props ) => ({
  '& .MuiInputLabel-root': {
    marginBotton: '20px'
  },
  '& .MuiInputBase-root': {
    borderRadius: '55px',
    backgroundColor: props.theme.palette.common.white,
    fontSize: '20px',
    color: props.theme.palette?.text.primary,
    fontFamily: 'Helvetica',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    height: '100%'
  },
  '& fieldset': { border: props.theme.palette.common.white },
  '&::placeholder': {
    marginLeft: '30px'
  }
}))
export const GridGeneral = styled( GridWrapper )(() => ({
  alignItems: 'center'
}))

export const GridItem = styled( GridWrapper )(() => ({
  display: 'flex',
  width: 44
}))

export const GridrespuestaItem = styled( GridWrapper )(() => ({
  width: 'calc(100% - 44px)',
  wordWrap: 'break-word'
}))

interface IParts {
  text: string
  highlight: string
}
export const BoxResults = styled(BoxWrapper)<IParts>(({highlight}) => ({
  fontWeight: highlight === 'true' ? 'bold' : 'regular'
}))

export const TypographyName = styled( TypographyWrapper )(() => ({
  fontFamily: 'Helvetica',
  fontWeight: 'normal',
  fontStretch: 'normal',
  fontStyle: 'normal',
  lineHeight: 'normal',
  letterSpacing: 'normal'
}))

export const AutocompleteStyled = styled(Autocomplete)(({theme}) => ({
  border: 'none',
  width: '100%',
  height: '55px',
  borderRadius: '55px',
  backgroundColor: theme.palette.common.white,
  fontSize: '20px',
  fontFamily: 'Helvetica',
  fontWeight: 'normal',
  fontStretch: 'normal',
  fontStyle: 'normal',
  lineHeight: 'normal',
  letterSpacing: 'normal',
  boxShadow: '1px 1px 7px 7px rgba(0, 0, 0, 0.03)',
}))

export const MarkerStyled = styled(RoomOutlined)(({ theme }) => ({
  width: '56px',
  height: '55px',
  color: theme.palette.primary.dark,
  transform: 'translateZ(0) translate(-50%, -100%)'
}))
