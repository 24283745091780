import {
  IApiResponseSaveRegisterMedical,
  ISaveRegisterMedical,
} from '../../infrastructure/dtos/MedicalRegister';
import { MedicalRegisterRepository } from '../repository/MedicalRegisterRepository';

export interface PostMedicalRegisterUseCase {
  invoke: (
    dataMedical: ISaveRegisterMedical
  ) => Promise<IApiResponseSaveRegisterMedical>;
}

export class PostMedicalRegister implements PostMedicalRegisterUseCase {
  private objectUseCase: MedicalRegisterRepository;

  constructor( _objectUseCase: MedicalRegisterRepository ) {
    this.objectUseCase = _objectUseCase;
  }

  async invoke(
    dataMedical: ISaveRegisterMedical
  ): Promise<IApiResponseSaveRegisterMedical> {
    return this.objectUseCase.postMedicalRegister( dataMedical );
  }
}
