import { styled } from '@mui/material'

import { TextFieldWrapper } from '../wrapper'

export const TextAreaField = styled(TextFieldWrapper)(({theme}) => ({
  '& .MuiInputBase-root': {
    borderRadius: '47px',
    backgroundColor: theme.palette.common.white,
    fontSize: '12px',
    color: theme.palette?.text.primary,
    fontFamily: 'Helvetica',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    padding: '25px 40px',
    boxShadow: '1px 1px 7px 7px rgba(0, 0, 0, 0.03)',
    '@media (min-width: 1600px)': {
      fontSize: '16px',
    },
  },
  '& fieldset': { borderColor: theme.palette.common.white },
  '&::placeholder': {
    marginLeft: '1.875rem'
  },
  '& .MuiInputBase-root.Mui-disabled': {
    '& > fieldset': {
      borderColor: theme.palette.common.white
    }
  }
}))
