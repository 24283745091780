import {
  CancelAppointment,
  ICancelAppointmentUseCase,
} from '../../../domain/useCase/CancelAppointmentUseCase'
import { ICancelAppointmentResponse } from '../../../infrastructure/dtos/CancelAppointment'
import CancelAppointmentMapper from '../../../infrastructure/http/mappers/CancelAppointmentMapper/CancelAppointmentMapper'
import { CancelAppointmentRepositoryImpl } from '../../../infrastructure/RepositoryImpl/CancelAppointmentRepositoryImpl'

export interface ICancelAppointmentService {
  status: number
  data: ICancelAppointmentResponse | string
}

export interface ICancelService {
  reason: string
  appointmentId: string
}

export async function CancelAppointmentService(
  params: ICancelService,
): Promise<ICancelAppointmentService> {
  try {
    const UseCaseApi: ICancelAppointmentUseCase = new CancelAppointment(
      new CancelAppointmentRepositoryImpl(new CancelAppointmentMapper()),
    )

    return {
      status: 1,
      data: await UseCaseApi.invoke(params),
    }
  } catch (e: unknown) {
    return {
      status: 0,
      data: e as string,
    }
  }
}
