import { IGetMedicalExamsResponse } from '../../infrastructure/dtos/MedicalExamsItem'
import { IMedicalExamsRepository } from '../repository/MedicalExamsRepository'

export interface IMedicalExamsUseCase {
  getMedicalExams(patientId: string): Promise<IGetMedicalExamsResponse>
}

export class MedicalExams implements IMedicalExamsUseCase {
  private objectUseCase: IMedicalExamsRepository

  constructor(_objectUseCase: IMedicalExamsRepository) {
    this.objectUseCase = _objectUseCase
  }

  async getMedicalExams(patientId: string): Promise<IGetMedicalExamsResponse> {
    return this.objectUseCase.getMedicalExams(patientId)
  }
}
