import {
  IApiResponseSaveNewDate,
  IApiResponseSaveOverbooking,
  ISaveNewDate,
} from '../../infrastructure/dtos/NewDate'
import { INewDateRepository } from '../repository/NewDateRepository'

export interface INewDateUseCase {
  invoke: (dataNewDate: ISaveNewDate) => Promise<IApiResponseSaveNewDate>
}

export interface INewOverbookingAppointmentUseCase {
  invoke: (dataNewDate: ISaveNewDate) => Promise<IApiResponseSaveOverbooking>
}

export class PostNewDate implements INewDateUseCase {
  private objectUseCase: INewDateRepository

  constructor(_objectUseCase: INewDateRepository) {
    this.objectUseCase = _objectUseCase
  }

  async invoke(dataNewDate: ISaveNewDate): Promise<IApiResponseSaveNewDate> {
    return this.objectUseCase.postNewDate(dataNewDate)
  }
}

export class PostNewOverbookingAppointment
  implements INewOverbookingAppointmentUseCase
{
  private objectUseCase: INewDateRepository

  constructor(_objectUseCase: INewDateRepository) {
    this.objectUseCase = _objectUseCase
  }

  async invoke(
    dataNewDate: ISaveNewDate,
  ): Promise<IApiResponseSaveOverbooking> {
    return this.objectUseCase.postNewOverbookingAppointment(dataNewDate)
  }
}
