import { GetParametersNotificationLateAppointment } from '../../../domain/useCase/GetParametersNotificationLateAppointment'
import { IServiceNotificationLateAppointment } from '../../../infrastructure/dtos/ParametersNotification'
import ParametersNotificationMapper from '../../../infrastructure/http/mappers/ParametersNotificationMapper/ParametersNotificationMapper'
import { ParametersNotificationRepositoryImpl } from '../../../infrastructure/RepositoryImpl/ParametersNotificationRepositoryImpl'

export async function GetParametersNotificationLateAppointmentService(): Promise<IServiceNotificationLateAppointment> {
  try {
    const DataUseCase: GetParametersNotificationLateAppointment =
      new GetParametersNotificationLateAppointment(
        new ParametersNotificationRepositoryImpl(
          new ParametersNotificationMapper(),
        ),
      )

    return {
      status: 1,
      data: await DataUseCase.invoke(),
    }
  } catch (error) {
    return {
      status: 0,
      data: {
        body: { notification_message: [] },
        headers: {
          'Access-Control-Allow-Headers': '',
          'Access-Control-Allow-Methods': '',
          'Access-Control-Allow-Origin': '',
          'Access-Control-Allow-Credentials': true,
        },
        statusCode: 500,
      },
    }
  }
}
