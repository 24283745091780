import 'dayjs/locale/es'
import { EventImpl } from '@fullcalendar/core/internal'
import { DeleteOutline } from '@mui/icons-material'
import { Backdrop } from '@mui/material'
import dayjs from 'dayjs'
import React, { useContext } from 'react'

import {
  ButtonDelete,
  ContentInfo,
  ContentTitle,
  ModalTitle,
} from './RemoveEventCalendarStyles'
import { GridWrapper } from '../../../../../components'
import {
  AccountContext,
  AuthContextType,
} from '../../../../../contexts/AccountContext'
import { deleteNonAttendanceHours } from '../../../../../services/Contracts/Persistencia/Calendar'
import {
  BoxModal,
  FadeModal,
  GridModal,
  GridModalContainerItem,
  ModalComponent,
} from '../../Styles/HoursOfNonAttendanceModalStyles'

interface IRemoveEvents {
  handleClose: () => void
  open: boolean
  title: string
  start: Date | null | undefined
  end: Date | null | undefined
  remove: EventImpl | undefined
  officeId: string
  idInterval: string
  allDay: boolean
}

const RemoveEventCalendar = ({
  handleClose,
  open,
  title,
  start,
  end,
  remove,
  officeId,
  idInterval,
  allDay,
}: IRemoveEvents): React.JSX.Element => {
  const { handleAlert } = useContext(AccountContext) as AuthContextType
  const showEventHours: string = allDay
    ? 'Evento de todo el día'
    : `Desde ${dayjs(start).format('hh:mm A')} - Hasta ${dayjs(end).format(
        'hh:mm A',
      )}`

  const handleRomove = async (): Promise<void> => {
    const { body, status } = await deleteNonAttendanceHours(
      officeId,
      idInterval,
    )

    if (status) {
      handleAlert(true, body as string, 'success')
      remove?.remove()
      handleClose()
    } else {
      handleAlert(true, body as string, 'error')
    }
  }

  return (
    <>
      <ModalComponent
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <FadeModal in={open}>
          <BoxModal>
            <GridModal>
              <form data-testid="modal-calendar">
                <ModalTitle id="transition-modal-title" variant="h6">
                  Detalles del evento
                </ModalTitle>
                <GridWrapper>
                  <ContentTitle>Nombre</ContentTitle>
                  <ContentInfo data-testid="content-name">{title}</ContentInfo>
                  <ContentTitle>Día</ContentTitle>
                  <ContentInfo data-testid="content-day">
                    {dayjs(start).locale('es').format('ddd DD MMM YYYY')}
                  </ContentInfo>
                  <ContentTitle>Hora</ContentTitle>
                  <ContentInfo data-testid="content-hour">
                    {showEventHours}
                  </ContentInfo>
                </GridWrapper>

                <GridModalContainerItem sx={{ marginTop: '80px' }}>
                  <ButtonDelete
                    data-testid="delete-button"
                    type="button"
                    variant="contained"
                    fullWidth
                    onClick={() => {
                      handleRomove()
                    }}
                  >
                    <DeleteOutline />
                    Eliminar
                  </ButtonDelete>
                </GridModalContainerItem>
              </form>
            </GridModal>
          </BoxModal>
        </FadeModal>
      </ModalComponent>
    </>
  )
}

export default RemoveEventCalendar
