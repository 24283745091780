import {
  RemoveCircleOutline,
  CheckCircleOutline,
  HighlightOffOutlined,
} from '@mui/icons-material'

import dayjs from 'dayjs'
import React, {
  ReactNode,
  useEffect,
  useRef,
  useState,
} from 'react'

import Attendance from './Components/AttendanceModal'
import { useAppointments } from './Hooks/useAppointments'
import { useAttendance } from './Hooks/useAttendance'
import { useNotifyDelayHook } from './Hooks/useNotifyDelay'
import { useReschedule } from './Hooks/useReschedule'
import {
  GridWrapper,
  IconButtonWrapper,
  ListItemWrapper,
  ListWrapper,
  TooltipWrapper,
} from '../../../components'
import { Advert } from '../../../components/Notification/Advert/Advert'
import { Title } from '../../../components/StyledComponents/Title'
import CircularProgressWrapper from '../../../components/wrapper/LoaderWrapper'
import { useFiltersStore } from '../../../contexts/FiltersState';
import { useSelectOffice } from '../../../contexts/SelectOfficeState'
import {
  Appointment,
  IApiResponseAppointment,
  IAppointmentsByDate,
  IGetAppointmentProps,
  IResponseAppointment,
} from '../../../infrastructure/dtos/Appointments'
import { IMedicalOffice } from '../../../infrastructure/dtos/Offices'
import { AppointmentService } from '../../../services/Contracts/Persistencia/AppointmentService'
import { AppointmentOptions } from '../components/AppointmentOptions/AppointmentOptions'
import { AppointmentOptionsModals } from '../components/modals/AppointmentOptionsModals'
import { useCancelAppointment } from '../components/modals/CancelAppointment/hook/useCancelAppointment'
import { useCancelReason } from '../components/modals/CancelAppointment/modals/CancelReason/hooks/useCancelReason'
import { useNextAppointment } from '../components/modals/NextAppointment/NextAppointmentHook'
import { useRecipeModal } from '../components/modals/RecipeModal/hook'
import { ResetAppointmentModal } from '../components/modals/ResetAssistance/ResetAppointment';

interface IAppointmentParams {
  setAppointment: (data: Appointment) => void
}

const StatusColor: { [key: number]: string } = {
  1: 'red',
  2: 'lightgreen',
  3: 'inherit',
}

const Appointments = ({
  setAppointment,
}: IAppointmentParams): React.JSX.Element => {
  const {
    handleSendNotification,
    handleOpenNotifyDelay,
    handleCloseNotifyDelay,
    handleCloseAlert,
    openNotifyDelay,
    message,
    openAlert,
    loading,
  } = useNotifyDelayHook()
  const { filtersSelected } = useFiltersStore()
  const { officeSelected } = useSelectOffice()

  const { openAttendance, handleCloseAttendance, handleOpenAttendance } =
    useAttendance()

  const { openRecipeModal, handleOpenRecipeModal, handleCloseRecipeModal } =
    useRecipeModal()

  const { handleCloseReschedule, handleOpenReschedule, openReschedule } =
    useReschedule()

  const { handleOpenCancelAppointment, openCancelAppointmentModal } =
    useCancelAppointment()

  const { handleOpenCancelReason, openCancelReasonModal } = useCancelReason()

  const {
    handleCloseNextAppointment,
    handleOpenNextAppointment,
    openNextAppointment,
  } = useNextAppointment({})
  const {
    handleChangeList,
    handleChangeLoadingList,
    loadingList,
    list,
    appointment,
    hamdleChangeAppointment,
  } = useAppointments()
  const itemRef = useRef<HTMLDivElement>(null)
  const [currentDate, setCurrentDate] = useState<dayjs.Dayjs>(dayjs(new Date()))
  const [fetchMessage, setFetchMessage] = useState<string>('')

  const [openResetAssistance, setOpenResetAssistance] = useState<boolean>(false)

  const [selectedOffice, setSelectedOffice] = useState<
    IMedicalOffice | undefined
  >(undefined)
  const [filters, setFilters] = useState<IGetAppointmentProps>({
    officeId: '',
    date_from: '',
    date_to: '',
    name_or_ci: '',
    isSubmitted: false,
  })

  useEffect(() => {
    if (itemRef.current) {
      window.scrollTo({
        top: itemRef.current.getBoundingClientRect().y - 200,
        behavior: 'smooth',
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemRef.current])

  useEffect(() => {
    if (officeSelected) {
      setSelectedOffice(
        (officeSelected as IMedicalOffice[]).find(
          (ofc: IMedicalOffice) => ofc.selected,
        ),
      )
    }
    if (filtersSelected) {
      setFilters(filtersSelected)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filtersSelected])

  useEffect(() => {
    if (filters.isSubmitted) {
      fetchApointment()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters])

  useEffect(() => {
    // Listen for changes to the current date and update the button's disabled state
    const interval = setInterval(() => {
      setCurrentDate(dayjs(new Date()))
    }, 1000)

    return () => {
      // Clear the interval when the component unmounts
      clearInterval(interval)
    }
  }, [currentDate])

  const setAppointmentData = (data: Appointment): void => {
    setAppointment(data)
  }

  const fetchApointment = async (): Promise<void> => {
    handleChangeLoadingList(true)
    setFetchMessage('')
    try {
      if (selectedOffice !== undefined) {
        const payload: IGetAppointmentProps = {
          ...filters,
          officeId: selectedOffice.office_id,
        }
        const { data } = await AppointmentService(payload)
        if (data.statusCode !== 200) {
          handleChangeList([])
          setFetchMessage(
            (data?.body as IResponseAppointment).message,
          )
          handleChangeLoadingList(false)

          return
        }
        const result: IAppointmentsByDate[] = (
          (data as IApiResponseAppointment<string | IResponseAppointment>)
            ?.body as IResponseAppointment
        )?.data.past_appointments
          .concat(
            (
              (data as IApiResponseAppointment<string | IResponseAppointment>)
                ?.body as IResponseAppointment
            )?.data.current_appointments,
          )
          .map((item: IAppointmentsByDate) => {
            item.appointments.map((item: Appointment) => {
              if (!item.showActions) {
                item.showActions = false
              }

              return item
            })

            return item
          })
        handleChangeList(result)
        handleChangeLoadingList(false)
      }
      handleChangeLoadingList(false)
    } catch (error) {
      handleChangeLoadingList(false)
    }
  }

  const handleOpenAttendanceModal = (item: Appointment): void => {
    hamdleChangeAppointment(item)
    handleOpenAttendance()
  }

  const handleOpenResetAssistance = (item: Appointment): void => {
    hamdleChangeAppointment(item)
    setOpenResetAssistance(true)
  }

  const onCloseResetAssistance = (): void => {
    fetchApointment()
    setOpenResetAssistance(false)
  }

  const onCloseRecipeModal = (isSent: boolean): void => {
    if (isSent) {
      fetchApointment()
    }
    handleCloseRecipeModal()
  }

  const onCloseCancelReason = (): void => {
    fetchApointment()
    handleOpenCancelReason(false)
    handleOpenCancelAppointment(false)
  }

  const onCloseAttendanceModal = (): void => {
    fetchApointment()
    handleCloseAttendance()
  }

  const ocCloseReschedulingModal = (): void => {
    fetchApointment()
    handleCloseReschedule()
  }

  const onCloseNextAppointment = (): void => {
    fetchApointment()
    handleCloseNextAppointment()
  }

  useEffect(() => {
    fetchApointment()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOffice])

  const isAssistanceAvailable = (item: Appointment): boolean =>
    currentDate.format('YYYY-MM-DD') !== item.appointment_date ||
    item.status.id === 1

  const isIconDisabled = (item: Appointment): boolean =>
    dayjs(item.appointment_date).isAfter(currentDate.endOf('day'))

  const AssistanceIcons = (item: Appointment): ReactNode => {
    const AssistanceIcons: { [key: string]: ReactNode } = {
      ATTENDED: (
        <CheckCircleOutline
          sx={{ color: isIconDisabled(item) ? 'text.disabled' : 'lightgreen' }}
          id={'attended-icon'}
        />
      ),
      NOT_ASSISTED: (
        <HighlightOffOutlined
          sx={{ color: isIconDisabled(item) ? 'text.disabled' : 'red' }}
          id={'not-assisted-icon'}
        />
      ),
      PENDING: (
        <RemoveCircleOutline
          sx={{
            color: isIconDisabled(item) ? 'text.disabled' : 'primary.dark',
          }}
          id={'pending-icon'}
        />
      ),
    }

    return AssistanceIcons[item.assistance]
  }

  const gridHeader: {
    title: string
    width: string
  }[] = [
      { title: 'Asistencia', width: '9%' },
      { title: 'Hora', width: '10%' },
      { title: 'Nombre', width: '15%' },
      { title: 'Lugar', width: '15%' },
      { title: 'Estado', width: '10%' },
      { title: '', width: '44%' },
    ]

  const gridBody = (
    item: Appointment,
  ): {
    value: string | ReactNode
    width: string
  }[] => [
      {
        value: AssistanceIcons(item),
        width: '9%',
      },
      { value: item.hour_from, width: '10%' },
      { value: `${item.patient_name} ${item.patient_last_name}`, width: '15%' },
      { value: item.reference_medical_office, width: '15%' },
      { value: item.status.name, width: '10%' },
    ]

  return (
    <GridWrapper px={'4rem'} pt={2} data-testid="appointments-component">
      {loadingList ? (
        <GridWrapper
          sx={{
            paddingTop: '50px',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <CircularProgressWrapper
            size={'3rem'}
            data-testid="loading-spinner"
          />
        </GridWrapper>
      ) : (
        <GridWrapper>
          {list?.length > 0 ? (
            list?.map((dates, dateIndex) => (
              <GridWrapper key={`dates-${dateIndex}`}>
                <Title
                  sx={{ marginBottom: 0 }}
                  data-testid="title-date"
                  ref={dates?.date.includes('Hoy') ? itemRef : null}
                >
                  {dates?.date}
                </Title>
                <ListWrapper>
                  <ListItemWrapper
                    style={{
                      borderRadius: '40px',
                      margin: '10px 0',
                    }}
                    data-testid="appointment"
                  >
                    {gridHeader.map((item, index) => (
                      <GridWrapper
                        item
                        textAlign={'center'}
                        width={item.width}
                        key={`GridHeader-${index}`}
                        sx={{
                          color: 'tertiary.main',
                        }}
                      >
                        {item.title}
                      </GridWrapper>
                    ))}
                  </ListItemWrapper>
                  {dates?.appointments.length > 0 ? (
                    dates?.appointments.map(
                      (item: Appointment, appointmentIndex: number) => (
                        <ListItemWrapper
                          key={item.appointment_id}
                          style={{
                            height: '80px',
                            background: 'white',
                            borderRadius: '40px',
                            marginBottom: '30px',
                            boxShadow: '0px 3px 5px 3px rgba(0, 0, 0, 0.06)',
                          }}
                          data-testid="appointment-item-list"
                        >
                          {gridBody(item).map((i, idx) => (
                            <GridWrapper
                              key={`GridHeader-${idx}`}
                              item
                              textAlign={'center'}
                              width={i.width}
                              color={
                                idx < 4
                                  ? item.status.id === 1
                                    ? 'info.main'
                                    : 'black'
                                  : StatusColor[item.status.id]
                              }
                              id={
                                idx === 1
                                  ? `${dates.date}-${appointmentIndex}`
                                  : undefined
                              }
                              sx={{
                                '@media (max-width: 1600px)': {
                                  fontSize: '12px',
                                },
                                '@media (min-width: 1601px)': {
                                  fontSize: '16px',
                                },
                              }}
                            >
                              {idx === 0 ? (
                                isIconDisabled(item) ? (
                                  <TooltipWrapper
                                    title="La asistencia se marca el día de la cita"
                                    placement="top"
                                  >
                                    <GridWrapper>
                                      <IconButtonWrapper
                                        onClick={() => {
                                          item.assistance === 'PENDING' ?
                                            handleOpenAttendanceModal(item) :
                                            handleOpenResetAssistance(item)
                                        }
                                        }
                                        disabled={isAssistanceAvailable(item)}
                                      >
                                        {i.value}
                                      </IconButtonWrapper>
                                    </GridWrapper>
                                  </TooltipWrapper>
                                ) : (
                                  <IconButtonWrapper
                                    onClick={() => {
                                      item.assistance === 'PENDING' ?
                                        handleOpenAttendanceModal(item) :
                                        handleOpenResetAssistance(item)
                                    }

                                    }
                                    disabled={isAssistanceAvailable(item)}
                                  >
                                    {i.value}
                                  </IconButtonWrapper>
                                )
                              ) : (
                                i.value
                              )}
                            </GridWrapper>
                          ))}
                          <AppointmentOptions
                            item={item}
                            data-testid={'appointment-options'}
                            appointmentIndex={appointmentIndex}
                            dateIndex={dateIndex}
                            currentDate={currentDate}
                            list={list}
                            actionsStyle={{
                              position: 'absolute',
                              top: 12,
                              '@media (max-width: 1600px)': {
                                fontSize: '12px',
                                width: '125px',
                              },
                              '@media (min-width: 1601px) and (max-width: 1980px)':
                              {
                                fontSize: '14px',
                                width: '180px',
                              },
                              '@media (min-width: 1981px)': {
                                fontSize: '16px',
                                width: '300px',
                              },
                            }}
                            hamdleChangeAppointment={hamdleChangeAppointment}
                            handleChangeList={handleChangeList}
                            handleOpenNotifyDelay={handleOpenNotifyDelay}
                            handleOpenRecipeModal={handleOpenRecipeModal}
                            handleOpenReschedule={handleOpenReschedule}
                            handleOpenNextAppointment={
                              handleOpenNextAppointment
                            }
                            handleOpenCancelAppointment={
                              handleOpenCancelAppointment
                            }
                            setAppointment={setAppointmentData}
                          />
                        </ListItemWrapper>
                      ),
                    )
                  ) : (
                    <p>Sin datos registrados</p>
                  )}
                </ListWrapper>
              </GridWrapper>
            ))
          ) : (
            <GridWrapper data-testid="no-data-message">
              {fetchMessage}
            </GridWrapper>
          )}
        </GridWrapper>
      )}
      <AppointmentOptionsModals
        openRecipeModal={openRecipeModal}
        openReschedule={openReschedule}
        openNotifyDelay={openNotifyDelay}
        loadingNotifyDelay={loading}
        officeId={selectedOffice?.office_id as string}
        appointment={appointment}
        handleSendNotification={handleSendNotification}
        handleCloseNotifyDelay={handleCloseNotifyDelay}
        onCloseRecipeModal={onCloseRecipeModal}
        handleCloseReschedule={handleCloseReschedule}
        handleSendRescheduleAfterSubmit={ocCloseReschedulingModal}
        handleCloseNextAppointment={handleCloseNextAppointment}
        handleSendNextAppointmentAfterSubmit={onCloseNextAppointment}
        openNextAppointment={openNextAppointment}
        handleOpenCancelAppointment={handleOpenCancelAppointment}
        openCancelAppointmentModal={openCancelAppointmentModal}
        handleOpenCancelReason={handleOpenCancelReason}
        openCancelReasonModal={openCancelReasonModal}
        onCloseCancelReason={onCloseCancelReason}
      />
      <Advert
        message={message.message}
        onClose={() => handleCloseAlert()}
        open={openAlert}
        title={message.title}
      />
      {openAttendance && (
        <Attendance
          handleClose={handleCloseAttendance}
          handleAfterSubmit={onCloseAttendanceModal}
          open={openAttendance}
          appointmentSelected={appointment}
        />
      )}
      {
        openResetAssistance && (
          <ResetAppointmentModal
            handleClose={
              () => setOpenResetAssistance(false)
            }
            handleAfterSubmit={onCloseResetAssistance}
            open={openResetAssistance}
            appointmentId={appointment?.appointment_id as string}
          />
        )
      }
    </GridWrapper>
  )
}

export default Appointments
