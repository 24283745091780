import { useState } from 'react'

interface IUseAppointmentDetailsModalHook {
  openAppointmentDetailsModal: boolean
  handleOpenAppointmentDetailsModal( value: boolean ): void
}

export function useAppointmentDetailsModal(): IUseAppointmentDetailsModalHook {
  const [openAppointmentDetailsModal, setOpenAppointmentDetailsModal] = useState<boolean>( false )

  const handleOpenAppointmentDetailsModal = ( value: boolean ): void => setOpenAppointmentDetailsModal( value )
  
  return {
    openAppointmentDetailsModal,
    handleOpenAppointmentDetailsModal,
  }
}