import { useState } from 'react';

import {useDoctorInformationStore} from '../../../../contexts/DoctorInformationState';
import { sendDelayNotificationUtility } from '../../../../services/Contracts/Utility/NotificationsUtility';

interface IMessage {
  title: string;
  message: string;
}

interface IUseNotifyDelayHook {
  handleSendNotification: ( message: string, officeId: string ) => Promise<void>
  handleOpenNotifyDelay: ( eventId: string ) => void
  handleCloseNotifyDelay: () => void
  handleCloseAlert: () => void
  openNotifyDelay: boolean
  selectedEvent: string
  message: IMessage
  openAlert: boolean
  loading: boolean
}

export function useNotifyDelayHook(): IUseNotifyDelayHook {
  const {doctorInformation}=useDoctorInformationStore()
  const [loading, setLoading] = useState<boolean>( false )
  const [openNotifyDelay, setOpenNotifyDelay] = useState<boolean>( false )
  const [openAlert, setOpenAlert] = useState<boolean>( false );
  const [selectedEvent, setSelectedEvent] = useState<string>( '' )
  const [message, setMessage] = useState<IMessage>({
    message: '',
    title: 'Aviso',
  });
  
  const handleSendNotification = async ( message: string, officeId: string ): Promise<void> => {
    try {
      setLoading( true )
      const data_user_id:string=doctorInformation?.user_id as string
      const result = await sendDelayNotificationUtility(data_user_id , officeId, selectedEvent, message )

      setOpenAlert( true )
      setMessage({
        title: 'AVISO',
        message: result.data,
      });
      setLoading( false )
      handleCloseNotifyDelay()
    } catch ( error ) {
      setLoading( false )
    }
  }

  const handleOpenNotifyDelay = ( eventId: string ): void => {
    setSelectedEvent( eventId )
    setOpenNotifyDelay( true )
  };
  
  const handleCloseNotifyDelay = (): void => setOpenNotifyDelay( false );

  const handleCloseAlert = (): void => setOpenAlert( false )

  return {
    handleSendNotification,
    handleOpenNotifyDelay,
    handleCloseNotifyDelay,
    handleCloseAlert,
    openNotifyDelay,
    selectedEvent,
    message,
    openAlert,
    loading
  };
}
