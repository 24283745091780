
import { create } from 'zustand'

import {Appointment, IAppointmentInfo} from '../infrastructure/dtos/Appointments';




interface IAppointmentStore{
    appointmentSelected: Appointment | IAppointmentInfo
    setAppointmentSelected: (appointmentSelected: Appointment | IAppointmentInfo) => void
}

export const useAppointmentStore = create<IAppointmentStore>((set) => ({
    appointmentSelected: {} as Appointment | IAppointmentInfo,
    setAppointmentSelected: (appointmentSelected: Appointment | IAppointmentInfo) => set({ appointmentSelected }),
}))
