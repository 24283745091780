import { Checkbox } from '@mui/material'
import { styled } from '@mui/material/styles'

import { TypographyWrapper } from '../../../../components'

export const TypographyLabelDay = styled(TypographyWrapper)(({theme}) => ({
  color: theme.palette.common.black,
  fontSize: '1rem',
  fontFamily: 'Helvetica',
  fontWeight: 'normal',
  fontStretch: 'normal',
  fontStyle: 'normal',
  lineHeight: 'normal',
  letterSpacing: 'normal',
  width: '80px',
}))

export const CircularCheckbox = styled( Checkbox )(({ theme }) => ({
  color: theme.palette.primary.main,
  '& .MuiSvgIcon-root': {
    borderRadius: '50%',
    width: '1.5em',
    height: '1.5em',
    backgroundColor: 'transparent',
    '$checked&': {
      backgroundColor: theme.palette.primary.main,
      '&:before': {
        display: 'block',
        width: '0.5em',
        height: '0.5em',
        borderRadius: '50%',
        margin: 'auto',
        content: '""',
        backgroundColor: theme.palette.common.white
      }
    }
  },
  '&:focus-visible .MuiSvgIcon-root': {
    outline: 'none'
  }
}))
