import { useContext, useEffect, useState } from 'react'

import { IPrescription } from './types'
import {
  AccountContext,
  AuthContextType
} from '../../../../../contexts/AccountContext'
import { Appointment } from '../../../../../infrastructure/dtos/Appointments'
import {
  IDoctorDataPrescription,
  IDoctorPrescription
} from '../../../../../infrastructure/dtos/DoctorInfo'
import { ISavePrescriptionProps } from '../../../../../infrastructure/dtos/Prescriptions'
import { doctorAndAppointmentInfoUtility } from '../../../../../services/Contracts/Utility/DoctorInfoUtility'
import { savePrescriptionUtility } from '../../../../../services/Contracts/Utility/PrescriptionsUtility'

export const initDoctorAppointmentInfo: IDoctorPrescription = {
  office_medical: {
    office_id: '',
    name_medical_office: '',
    reference: '',
    office_phone: ''
  },
  doctor: {
    name: '',
    last_name: '',
    email: '',
    phone: '',
    msp_code: '',
    specialties: ''
  },
  prescription: {
    prescription_no: 0
  },
  appointment: {
    date: ''
  },
  patient: {
    name: '',
    last_name: ''
  },
  patient_diagnostic: []
}
interface IUseRecipeModalHook {
  openRecipeModal: boolean
  stepper: number
  doctorAppointmentInfo: IDoctorPrescription
  prescription: IPrescription
  isSubmit: boolean
  isSent: boolean
  onNext(): void
  onBack(): void
  handleOpenRecipeModal(): void
  handleCloseRecipeModal(): void
  fetchDoctorAndAppointmentInfoUtility( item?: Appointment ): Promise<void>
  onChangePrescription( value: IPrescription ): void
  onChangeMspCode( code: string ): void
  onChangeIsSubmit( value: boolean ): void
}

export function useRecipeModal(
  appointmentSelected?: Appointment
): IUseRecipeModalHook {
  const { handleAlert } = useContext(AccountContext) as AuthContextType
  const [prescription, setPrescription] = useState<IPrescription>({
    remarks: '',
    diagnostic: [],
    recipe: [],
    prescription_file: null
  })
  const [openRecipeModal, setOpenRecipeModal] = useState<boolean>( false )
  const [isSubmit, setIsSubmit] = useState<boolean>( false )
  const [isSent, setIsSent] = useState<boolean>( false )
  const [stepper, setStepper] = useState<number>( 0 )
  const [doctorAppointmentInfo, setDoctorAppointmentInfo] =
    useState<IDoctorPrescription>( initDoctorAppointmentInfo )

  const onNext = (): void => setStepper( stepper + 1 )
  const onBack = (): void => setStepper( stepper - 1 )
  const handleOpenRecipeModal = (): void => setOpenRecipeModal( true )
  const handleCloseRecipeModal = (): void => setOpenRecipeModal( false )
  const onChangeIsSubmit = ( value: boolean ): void => setIsSubmit( value )

  const fetchDoctorAndAppointmentInfoUtility = async (): Promise<void> => {
    const { appointment_id, office_id } = appointmentSelected as Appointment

    try {
      if ( appointment_id && office_id ) {
        const { data, status } = await doctorAndAppointmentInfoUtility({
          appointmentId: appointment_id,
          officeId: office_id
        })
        if (status) {
          setDoctorAppointmentInfo(data as IDoctorPrescription)
          setPrescription({
            ...prescription,
            diagnostic: (data as IDoctorPrescription).patient_diagnostic
          })
        }
      }
    } catch ( error ) {
      handleAlert(true, 'Error al obtener los datos de la cita', 'error')
    }
  }

  const onChangePrescription = ( value: IPrescription ): void =>
    setPrescription( value )

  const onChangeMspCode = ( code: string ): void => {
    const doctor: IDoctorDataPrescription =
      doctorAppointmentInfo.doctor as IDoctorDataPrescription

    doctor.msp_code = code

    setDoctorAppointmentInfo({
      ...( doctorAppointmentInfo as IDoctorPrescription ),
      doctor
    })
  }

  const fetchSavePrescription = async (): Promise<void> => {
    const { diagnostic, recipe, remarks, prescription_file } = prescription
    const { appointment_id } = appointmentSelected as Appointment || ''
    const doctor_id: string = appointmentSelected?.user_id as string
    const doctor_type: string = appointmentSelected?.doctor_type || ''
    const {
      office_medical: { office_id },
      prescription: { prescription_no }
    } = doctorAppointmentInfo
    const dataToSend: ISavePrescriptionProps = {
      appointment_id,
      doctor_id,
      doctor_type,
      office_id: office_id as string,
      medical_diagnostic: diagnostic.map((item) => ({
        objectID: item.id_cie10
      })),
      prescription_items: recipe.map(( item ) => ({
        dosage: item.dose,
        medicament: item.medicine,
        prescription: item.prescription,
        quantity: item.quantity,
        presentation: item.presentation
      })),
      prescription_no: prescription_no,
      remarks,
      prescription_file: prescription_file?.toString().split('base64,')[1] as string
    }

    try {
      const { status, data } = await savePrescriptionUtility(dataToSend)
      if (status) {
        handleAlert(true, data, 'success')
        setIsSent(true)
      }
      onChangeIsSubmit( false )
    } catch ( error ) {}
  }

  useEffect(() => {
    if ( isSubmit ) {fetchSavePrescription()}
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prescription])

  return {
    openRecipeModal,
    stepper,
    doctorAppointmentInfo,
    prescription,
    isSubmit,
    isSent,
    onNext,
    onBack,
    handleOpenRecipeModal,
    handleCloseRecipeModal,
    fetchDoctorAndAppointmentInfoUtility,
    onChangePrescription,
    onChangeMspCode,
    onChangeIsSubmit
  }
}
