import {useContext, useState} from 'react';

import {AccountContext, AuthContextType} from '../../../../../../contexts/AccountContext';
import {ResetAttendanceUtility} from '../../../../../../services/Contracts/Utility/AttendanceUtility';

interface IUseResetAppointmentHook {
  loading: boolean
  fetchResetAssistance: () => Promise<void>
}

interface IResetAppointmentsProps {
  handleAfterSubmit: () => void
  handleClose: () => void
  appointmentId: string
}

export const useResetAppointment = ({
  handleAfterSubmit,
  handleClose,
  appointmentId,
}: IResetAppointmentsProps): IUseResetAppointmentHook => {
  const { handleAlert } = useContext(AccountContext) as AuthContextType
  const [loading, setLoading] = useState<boolean>(false)

  const fetchResetAssistance = async (): Promise<void> => {
    try {
      setLoading(true)
      const { data, status } = await ResetAttendanceUtility(appointmentId)
      if (status) {
        handleAlert(true, data.message, 'success')
        handleAfterSubmit()
      } else {
        handleAlert(true, data.message, 'error')
        handleAfterSubmit()
      }
      setLoading(false)
    } catch (error: unknown) {
      setLoading(false)
      handleAlert(
        true,
        'Ocurrió un error al intentar eliminar la marcación de la cita',
        'error',
      )
      handleClose()
    }
  }

  return {
    loading,
    fetchResetAssistance,

  }
}
