import { IAppointmentsByDoctorParams, PatientAppointmentsByDoctorResponse } from '../../infrastructure/dtos/AttendanceSelectedPatient'
import { AttendanceSelectedPatientRepository } from '../repository/AttendanceSelectedPatientRepository'

export interface GetAttendanceSelectedPatientUseCase {
  invoke: (
    params: IAppointmentsByDoctorParams
  ) => Promise<PatientAppointmentsByDoctorResponse>
}

export class GetPatientAppointment
implements GetAttendanceSelectedPatientUseCase
{
  private objectUseCase: AttendanceSelectedPatientRepository

  constructor( _objectUseCase: AttendanceSelectedPatientRepository ) {
    this.objectUseCase = _objectUseCase
  }

  invoke(
    params: IAppointmentsByDoctorParams
  ): Promise<PatientAppointmentsByDoctorResponse> {
    return this.objectUseCase.getPatientAppointments( params )
  }
}
