import {BodyResetAssistanceAppointmentResponse, IServiceResetAssistance} from '../../../infrastructure/dtos/Attendance';
import {ResetAttendanceService} from '../Persistencia/AttendanceService';

interface AttendanceUtilityResponse {
  status: number
  data: BodyResetAssistanceAppointmentResponse
}

export const ResetAttendanceUtility = async (appointment_id: string): Promise<AttendanceUtilityResponse>  => {
  try {
    const { data } = await ResetAttendanceService(appointment_id)

    return {
      status: data.statusCode === 200 ? 1 : 0,
      data: data.body
    }
  } catch (error: unknown) {
    return {
      status: 0,
      data: {
        message: (error as IServiceResetAssistance)?.data.body.message
      }
    }
  }
}
