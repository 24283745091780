import { CustomProvider, DateRangePicker } from 'rsuite'
import esES from 'rsuite/locales/es_ES'

import useMedicalRecord from './hooks/useMedicalRecord'

import {
  GridWrapper,
  IconButtonWrapper,
  ListItemWrapper,
  ListWrapper,
  TypographyWrapper,
} from '../../../../../../../components'
import { DiagnosticTooltip } from '../../../../../../../components/DiagnosticTooltip/DiagnosticTooltip'
import { DateRangeInput } from '../../../../../../../components/Sidebar/components/SearchForm/styles'
import { Title } from '../../../../../../../components/StyledComponents/Title'
import CircularProgressWrapper from '../../../../../../../components/wrapper/LoaderWrapper'

import {
  IAppointment,
  PatientDiagnosticElement,
} from '../../../../../../../infrastructure/dtos/AttendanceSelectedPatient'
import '../../../../../../../index.css'
import { PatientInfo } from '../PatientInfo/PatientInfo'
import { PatientRecipesModal } from '../PatientRecipesModal'

export default function MedicalRecord(): JSX.Element {
  const {
    appointmentSelected,
    fetchMessage,
    gridBody,
    gridHeader,
    gridInfoBody,
    handleChangeOpenPatientRecipesModal,
    handleDateRangeChange,
    list,
    afterToday,
    loadingList,
    loadingPatient,
    openPatientRecipesModal,
    patient,
    nonRegistered,
    filterRange,
  } = useMedicalRecord()

  return (
    <GridWrapper container px={2} pt={2}>
      <PatientInfo
        patient={patient}
        loadingPatient={loadingPatient}
        gridInfoBody={gridInfoBody}
        nonRegistered={nonRegistered}
      />
      <GridWrapper item xs={12} md={7} px={2}>
        {loadingList ? (
          <GridWrapper
            sx={{
              paddingTop: '50px',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <CircularProgressWrapper
              id="loading-spinner"
              size={'3rem'}
              data-testid="loading-spinner"
            />
          </GridWrapper>
        ) : (
          <GridWrapper>
            <GridWrapper container>
              <GridWrapper item xs={6} display={'flex'} alignItems={'center'}>
                <Title sx={{ marginY: 0 }} data-testid="title-date">
                  Historial de atenciones
                </Title>
              </GridWrapper>
              <GridWrapper
                item
                xs={6}
                display={'flex'}
                alignItems={'center'}
                justifyContent={'right'}
              >
                <DateRangeInput>
                  <CustomProvider locale={esES}>
                    <DateRangePicker
                      id={'filter-range'}
                      value={filterRange}
                      placeholder="dd/mm - dd/mm"
                      format="dd/MM/yyyy"
                      appearance="subtle"
                      size="sm"
                      showOneCalendar
                      ranges={[]}
                      shouldDisableDate={afterToday?.()}
                      onChange={(value: [Date, Date] | null) =>
                        handleDateRangeChange(value)
                      }
                      style={{
                        width: '100%',
                        borderRadius: '52px',
                        backgroundColor: 'white',
                        padding: '8px 2px',
                      }}
                    />
                  </CustomProvider>
                </DateRangeInput>
              </GridWrapper>
            </GridWrapper>
            {list?.length > 0 && (
              <GridWrapper
                container
                display={'flex'}
                justifyContent={'center'}
                alignItems={'center'}
                px={'16px'}
              >
                {gridHeader.map((item, index) => (
                  <GridWrapper
                    item
                    textAlign={'center'}
                    xs={item.width}
                    key={`GridHeader-${index}`}
                    sx={{
                      color: 'common.black',
                    }}
                  >
                    <TypographyWrapper
                      variant="body2"
                      sx={{
                        fontSize: '12px',
                        '@media (min-width: 1600px)': {
                          fontSize: '16px',
                        },
                      }}
                    >
                      {item.title}
                    </TypographyWrapper>
                  </GridWrapper>
                ))}
              </GridWrapper>
            )}
            {list?.length > 0 ? (
              list?.map((dates: IAppointment, datesIndex: number) => {
                return (
                  <ListWrapper
                    key={`dates-${datesIndex}`}
                    data-testid={'history-list'}
                  >
                    <ListItemWrapper
                      sx={{
                        height: '80px',
                        background: 'white',
                        borderRadius: '40px',
                        margin: '10px 0',
                        boxShadow: '6px 6px 8px 7px rgba(0, 0, 0, 0.04)',
                        fontSize: '12px',
                        '@media (min-width: 1600px)': {
                          fontSize: '16px',
                        },
                      }}
                      data-testid="patient-record-item-list"
                    >
                      <GridWrapper
                        container
                        display={'flex'}
                        justifyContent={'center'}
                        alignItems={'center'}
                        sx={{
                          color: 'common.black',
                        }}
                      >
                        {gridBody(dates).map((i, idx) => (
                          <DiagnosticTooltip
                            title={
                              idx === 2 &&
                              Array.isArray(dates.patient_diagnostic)
                                ? (
                                    dates.patient_diagnostic as PatientDiagnosticElement[]
                                  )
                                    .map(
                                      (item) =>
                                        `${item.cie_code} ${item.diagnostic}`,
                                    )
                                    .join(', ')
                                : ''
                            }
                            placement="top"
                            data-testid={idx === 2 ? 'datos-tooltip' : null}
                            key={`gridBody-${idx}`}
                          >
                            <GridWrapper
                              key={`GridHeader-${idx}`}
                              item
                              textAlign={'center'}
                              xs={i.width}
                              id={
                                idx === 1
                                  ? `${dates.appointment_date}-${datesIndex}`
                                  : undefined
                              }
                              paddingX={1}
                              sx={
                                idx === 2 &&
                                Array.isArray(dates.patient_diagnostic)
                                  ? {
                                      cursor: 'pointer',
                                    }
                                  : {}
                              }
                            >
                              {idx === 0 ? (
                                <IconButtonWrapper disabled>
                                  {i.value}
                                </IconButtonWrapper>
                              ) : (
                                i.value || '-'
                              )}
                            </GridWrapper>
                          </DiagnosticTooltip>
                        ))}
                      </GridWrapper>
                    </ListItemWrapper>
                  </ListWrapper>
                )
              })
            ) : (
              <GridWrapper mt={3}>{fetchMessage}</GridWrapper>
            )}
          </GridWrapper>
        )}
        {openPatientRecipesModal && (
          <PatientRecipesModal
            handleChangeOpenPatientRecipesModal={
              handleChangeOpenPatientRecipesModal
            }
            open={openPatientRecipesModal}
            appointmentSelected={appointmentSelected}
          />
        )}
      </GridWrapper>
    </GridWrapper>
  )
}
