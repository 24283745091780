import { Auth0Provider } from '@auth0/auth0-react'
import {
  autocompleteClasses,
  Box,
  createTheme,
  ThemeProvider,
} from '@mui/material'
import dayjs from 'dayjs'
import isBetween from 'dayjs/plugin/isBetween'
import React, { ReactNode } from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'

import './index.css'
import { AppRouter } from './AppRouter'
import { AuthProvider } from './contexts/AccountContext'
import { CalendarProvider } from './contexts/CalendarContext'
import { RegisterMedicalProvider } from './contexts/RegisterContext'
import reportWebVitals from './reportWebVitals'

dayjs.extend(isBetween)

interface IProps {
  children: ReactNode
}

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

const RegisterMedicalState = ({ children }: IProps): JSX.Element => {
  return <RegisterMedicalProvider>{children}</RegisterMedicalProvider>
}

const CalendarState = ({ children }: IProps): JSX.Element => {
  return <CalendarProvider>{children}</CalendarProvider>
}

declare module '@mui/material/styles' {
  interface Palette {
    tertiary: Palette['primary']
  }

  interface PaletteOptions {
    tertiary?: PaletteOptions['primary']
  }
}

const theme = createTheme({
  palette: {
    primary: {
      main: '#6191FE',
      dark: '#3850B2',
    },
    background: {
      default: '#FAFAFA',
      paper: 'white',
    },
    text: {
      primary: '#7f7f7f',
      disabled: 'rgba(0, 0, 0, 0.12)',
    },
    tertiary: {
      main: '#000',
      light: '#F4F4FC',
    },
    info: {
      main: '#b7bfd4',
      light: '#FAF9FD',
    },
  },
  components: {
    MuiAlert: {
      styleOverrides: {
        standard: {
          alignItems: 'center',
          minWidth: '400px',
          height: '60px',
          fontSize: '18px',
          fontWeight: 500,
          lineHeight: 'normal',
          borderRadius: '19px',
        },
        standardSuccess: {
          backgroundColor: '#DEF4D3',
          color: '#75C44C',
        },
      },
    },
    MuiAutocomplete: {
      defaultProps: {
        renderOption: (props, option, state, ownerState) => (
          <Box
            sx={{
              borderRadius: '8px',
              [`&.${autocompleteClasses.option}`]: {
                fontSize: '12px',
                '@media (min-width: 1600px)': {
                  fontSize: '16px',
                },
              },
            }}
            component="li"
            {...props}
          >
            {ownerState.getOptionLabel(option)}
          </Box>
        ),
      },
    },
  },
})
const domain = process.env.REACT_APP_AUTH0_DOMAIN as string
const clientId = process.env.REACT_APP_AUTH_CLIENT_ID as string
root.render(
  <React.StrictMode>
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      authorizationParams={{
        redirect_uri: window.location.origin,
      }}
    >
      <BrowserRouter>
        <ThemeProvider theme={theme}>
          <AuthProvider>
            <CalendarState>
              <RegisterMedicalState>
                <AppRouter />
              </RegisterMedicalState>
            </CalendarState>
          </AuthProvider>
        </ThemeProvider>
      </BrowserRouter>
    </Auth0Provider>
  </React.StrictMode>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
