import { AxiosError } from 'axios'

import { IApiSendNotificationResponseService } from '../../../dtos/Notifications'
import NotificationsDataSource from '../../dataSource/NotificationsDataSource'
import { http } from '../../Http'

export default class NotificationsMapper implements NotificationsDataSource {
  async sendDelayNotification(
    user_id: string,
    office_id: string,
    appointment_id: string,
    message: string,
  ): Promise<IApiSendNotificationResponseService> {
    try {
      const { data } = await http.get<IApiSendNotificationResponseService>(
        `notification/late_appointment/${user_id}/${office_id}/${appointment_id}/${message}`,
      )

      return data
    } catch (error: unknown) {
      const data: IApiSendNotificationResponseService = {
        statusCode: (error as AxiosError).response?.status as number,
        body: (error as AxiosError).response?.statusText as string,
        headers: {
          'Access-Control-Allow-Headers': '',
          'Access-Control-Allow-Origin': '',
          'Access-Control-Allow-Methods': '',
        },
      }

      return data
    }
  }
}
