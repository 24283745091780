import React from 'react'

import { CancelAppointmentModal } from './CancelAppointment/CancelAppointment'
import CancelReason from './CancelAppointment/modals/CancelReason/CancelReason'
import NextAppointment from './NextAppointment/NextAppointment'
import { NotifyDelay } from './NotifyDelay/NotifyDelay'
import { RecipeModal } from './RecipeModal'
import RescheduleModal from './RescheduleModal/RescheduleModal'
import { GridWrapper } from '../../../../components'
import { Appointment } from '../../../../infrastructure/dtos/Appointments'

interface IAppointmentOptionsModalsProps {
  openRecipeModal: boolean
  openReschedule: boolean
  openNotifyDelay: boolean
  loadingNotifyDelay: boolean
  officeId: string
  appointment?: Appointment
  openNextAppointment: boolean
  openCancelAppointmentModal: boolean
  openCancelReasonModal: boolean
  handleSendNotification: (message: string, officeId: string) => void
  handleCloseNotifyDelay: () => void
  onCloseRecipeModal: (isSent: boolean) => void
  handleCloseReschedule: () => void
  handleSendRescheduleAfterSubmit: () => void
  handleCloseNextAppointment: () => void
  handleSendNextAppointmentAfterSubmit: () => void
  handleOpenCancelAppointment: (value: boolean) => void
  handleOpenCancelReason: (value: boolean) => void
  onCloseCancelReason: () => void
}

export function AppointmentOptionsModals({
  openRecipeModal,
  openReschedule,
  openNextAppointment,
  openNotifyDelay,
  loadingNotifyDelay,
  officeId,
  appointment,
  openCancelAppointmentModal,
  openCancelReasonModal,
  handleSendNotification,
  handleCloseNotifyDelay,
  onCloseRecipeModal,
  handleCloseReschedule,
  handleCloseNextAppointment,
  handleSendRescheduleAfterSubmit,
  handleSendNextAppointmentAfterSubmit,
  handleOpenCancelAppointment,
  handleOpenCancelReason,
  onCloseCancelReason
}: IAppointmentOptionsModalsProps): React.JSX.Element {
  return (
    <GridWrapper>
      {openRecipeModal && (
        <RecipeModal
          openModal={openRecipeModal}
          handleCloseModal={onCloseRecipeModal}
          appointmentSelected={appointment}
        />
      )}
      {openReschedule && (
        <RescheduleModal
          handleClose={handleCloseReschedule}
          open={openReschedule}
          officeId={officeId}
          appointmentId={appointment?.appointment_id as string}
          handleSendRescheduleAfterSubmit={handleSendRescheduleAfterSubmit}
        />
      )}
      {openNotifyDelay && (
        <NotifyDelay
          handleSendNotification={handleSendNotification}
          handleClose={handleCloseNotifyDelay}
          open={openNotifyDelay}
          officeId={officeId}
          loading={loadingNotifyDelay}
        />
      )}
      {openNextAppointment && (
        <NextAppointment
          appointment={appointment}
          handleClose={handleCloseNextAppointment}
          handleSendNextAppointmentAfterSubmit={
            handleSendNextAppointmentAfterSubmit
          }
          officeId={officeId}
          open={openNextAppointment}
        />
      )}
      {openCancelAppointmentModal && (
        <CancelAppointmentModal
          handleClose={() => handleOpenCancelAppointment(false)}
          open={openCancelAppointmentModal}
          handleOpenCancelReason={() => handleOpenCancelReason(true)}
        />
      )}
      {openCancelReasonModal && (
        <CancelReason
          handleClose={() => handleOpenCancelReason(false)}
          open={openCancelReasonModal}
          appointment={appointment}
          onCloseCancelReason={onCloseCancelReason}
        />
      )}
    </GridWrapper>
  )
}
