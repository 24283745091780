import { AxiosError } from 'axios'

import {
  IModifiedDataOffice,
  IApiResponseModifiedDataOffice,
} from '../../../dtos/UpdateOffices'
import UpdateOfficesDataSource from '../../dataSource/UpdateOfficesDataSource'
import { http } from '../../Http'

export default class UpdateOfficesMapper implements UpdateOfficesDataSource {
  async updateOffices(
    office: IModifiedDataOffice,
  ): Promise<IApiResponseModifiedDataOffice> {
    try {
      const { data } = await http.put<IApiResponseModifiedDataOffice>(
        `modified_data/office`,
        { body: office },
      )

      return data
    } catch (error: unknown) {
      const data: IApiResponseModifiedDataOffice = {
        statusCode: (error as AxiosError).response?.status as number,
        body: (error as AxiosError).response?.statusText as string,
        headers: {
          'Access-Control-Allow-Headers': '',
          'Access-Control-Allow-Origin': '',
          'Access-Control-Allow-Methods': '',
        },
      }

      return data
    }
  }
}
