import {
  CustomContentGenerator,
  DatesSetArg,
  EventClickArg,
  EventContentArg,
} from '@fullcalendar/core'
import esLocale from '@fullcalendar/core/locales/es'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'
import listPlugin from '@fullcalendar/list' // para listado  listDay, listWeek, listMonth, and listYear. https://fullcalendar.io/docs/list-view
import FullCalendar from '@fullcalendar/react'
import timeGridPlugin from '@fullcalendar/timegrid'
import React, { Dispatch, useContext } from 'react'

import './Styles/styles.css'
import { useCalendar } from './hooks/useCalendar'
import { GridBody } from './Styles/CalendarStyles'
import { CalendarContext } from '../../contexts/CalendarContext'
import { IMedicalOffice } from '../../infrastructure/dtos/Offices'
import RemoveEventCalendar from '../../views/Dashboard/CalendarForm/Components/RemoveEventCalendar'

interface IProps {
  office?: IMedicalOffice
  userId: string
  openAppointmentDetailsModal: boolean
  handleOpenAppointmentDetailsModal(value: boolean): void
  setAppointmentId: Dispatch<React.SetStateAction<string>>
}

export const CustomCalendar = ({
  office,
  userId,
  handleOpenAppointmentDetailsModal,
  setAppointmentId,
}: IProps): JSX.Element => {
  const {
    handleDateSelect,
    handleEvents,
    handleChangeView,
    apiEvents: events,
    state,
  } = useContext(CalendarContext)

  const {
    title,
    start,
    end,
    remove,
    eventId,
    allDay,
    businessHours,
    eventOpen,
    handleClose,
    open,
  } = useCalendar(office, handleOpenAppointmentDetailsModal, setAppointmentId)

  return (
    <GridBody className="demo-app" data-testid="calendar">
      <GridBody className="demo-app-main">
        <FullCalendar
          plugins={[
            dayGridPlugin,
            timeGridPlugin,
            interactionPlugin,
            listPlugin,
          ]}
          headerToolbar={{
            left: 'prev today next timeGridDay timeGridWeek dayGridMonth listMonth',
            right: 'title',
          }}
          titleFormat={{ month: 'long', year: 'numeric' }} // day: 'numeric', year: 'numeric',
          initialView="timeGridWeek"
          scrollTimeReset={false}
          scrollTime={'06:00'}
          nowIndicator
          editable
          locale={esLocale}
          selectable
          selectMirror
          dayMaxEvents
          // droppable={false}
          datesSet={(args: DatesSetArg) =>
            handleChangeView(args, office?.office_id as string, userId)
          }
          weekends={state?.weekendsVisible}
          // initialEvents={INITIAL_EVENTS} // alternatively, use the `events` setting to fetch from a feed
          events={events}
          select={handleDateSelect}
          eventContent={renderEventContent} // custom render function
          eventClick={({ event }: EventClickArg) => eventOpen(
            {
              groupId: event.groupId,
              allDay: event.allDay,
              start: event.start,
              end: event.end,
              id: event.id,
              title: event.title,
            },
            event
          )}
          eventsSet={handleEvents} // called after events are initialized/added/changed/removed
          aspectRatio={2.23}
          expandRows
          handleWindowResize
          fixedWeekCount={false}
          eventStartEditable={false}
          eventDurationEditable={false}
          // dayHeaderFormat={{ weekday: 'long' }} // https://fullcalendar.io/docs/dayHeaderFormat
          slotDuration={'00:30:00'} // https://fullcalendar.io/docs/slotDuration
          firstDay={1} // https://fullcalendar.io/docs/firstDay
          // Style event
          eventColor="#31cacd" // https://fullcalendar.io/docs/eventColor
          // eventBackgroundColor='red'
          eventBorderColor="blue"
          eventTextColor="white"
          eventTimeFormat={{
            hour: '2-digit',
            minute: '2-digit',
            meridiem: false,
          }} // https://fullcalendar.io/docs/eventTimeFormat
          displayEventEnd // https://fullcalendar.io/docs/displayEventEnd
          // nextDayThreshold="09:00:00" // https://fullcalendar.io/docs/nextDayThreshold
          eventDisplay="block" // https://fullcalendar.io/docs/eventDisplay
          // dragScroll={false}
          // slotMinTime={'08:00:00'}
          slotLabelInterval={'01:00'}
          slotLabelFormat={{ hour: '2-digit', minute: '2-digit' }}
          // hiddenDays={[1, 2]}//Excluir dias de la semana
          views={{
            timeGridDay: {
              // name of view
              // titleFormat: { year: 'numeric', month: 'long', day: 'numeric' },
              dayHeaderFormat: {
                day: 'numeric',
                weekday: 'long',
              },
              // aspectRatio: 2.23
            },
            timeGridWeek: {
              dayHeaderFormat: {
                day: 'numeric',
                weekday: 'long',
              },
            },
          }}
          businessHours={businessHours}
        />

        <RemoveEventCalendar
          handleClose={handleClose}
          open={open}
          remove={remove}
          title={title}
          start={start}
          end={end}
          idInterval={eventId}
          allDay={allDay}
          officeId={office?.office_id as string}
        />
      </GridBody>
    </GridBody>
  )
}
// a custom render function

// how could set calendar background color in fullcalendar reference to business hours?
function renderEventContent(
  eventInfo: EventContentArg,
): CustomContentGenerator<EventContentArg> {
  return (
    <div key={eventInfo.event.id} data-testid={'events'}>
      <b>{eventInfo.timeText}</b>&nbsp;
      <i>{eventInfo.event.title}</i>
    </div>
  )
}
