import {
  BodySaveOfficeRequest,
  BodySaveOfficeResponse,
  IApiResponseSaveOffice,
  IUpdateInfoOfficeParams,
  IUpdateInfoOfficeResponse,
} from '../../../infrastructure/dtos/Offices'
import {
  BodyModifyStatusOfficeRequest,
  BodyModifyStatusOfficeResponse,
  IApiResponseModifyStatusOffice,
} from '../../../infrastructure/dtos/UpdateOffices'
import {
  SaveNewOfficeService,
  UpdateOfficeService,
  UpdateOfficeStatusService,
} from '../Persistencia/OfficesService'

export interface IUpdateOfficeUtility {
  status: number
  data: string
}

export interface IUpdateOfficeStatusUtility {
  status: number
  data: BodyModifyStatusOfficeResponse
}

export interface ISaveNewOfficeUtility {
  status: number
  data: BodySaveOfficeResponse
}

export async function UpdateOfficeUtility(
  params: IUpdateInfoOfficeParams,
): Promise<IUpdateOfficeUtility> {
  try {
    const { data, status } = await UpdateOfficeService(params)

    return {
      data: (data as IUpdateInfoOfficeResponse).body,
      status:
        status !== 1 || (data as IUpdateInfoOfficeResponse).statusCode !== 200
          ? 0
          : 1,
    }
  } catch (error: unknown) {
    return {
      data: (error as Error).message,
      status: 0,
    }
  }
}

export async function UpdateOfficeStatusUtility(
  body: BodyModifyStatusOfficeRequest,
): Promise<IUpdateOfficeStatusUtility> {
  try {
    const { data } = await UpdateOfficeStatusService(body)

    return {
      status:
        (data as IApiResponseModifyStatusOffice).statusCode !== 200 ? 0 : 1,
      data: (data as IApiResponseModifyStatusOffice).body,
    }
  } catch (error: unknown) {
    return {
      data: {
        message: (error as Error).message,
      },
      status: 0,
    }
  }
}

export async function SaveNewOfficeUtility(
  body: BodySaveOfficeRequest,
): Promise<ISaveNewOfficeUtility> {
  try {
    const { data } = await SaveNewOfficeService(body)

    return {
      status: (data as IApiResponseSaveOffice).statusCode !== 200 ? 0 : 1,
      data: (data as IApiResponseSaveOffice).body,
    }
  } catch (error: unknown) {
    return {
      status: 0,
      data: {
        message: (error as Error).message,
      },
    }
  }
}
