import { IApiResponsePatientInfo } from '../../infrastructure/dtos/Patient'
import { PatientInfoRepository } from '../repository/PatientInfoRepository'

export interface GetPatientInfoUseCase {
  invoke: ( idPatient: string ) => Promise<IApiResponsePatientInfo>
}

export interface GetPatientInfoByCiUseCase {
  invoke: ( ciPatient: string, isOnlyInfo?: boolean ) => Promise<IApiResponsePatientInfo>
}

export class GetPatientInfo implements GetPatientInfoUseCase {
  private objectUseCase: PatientInfoRepository

  constructor( _objectUseCase: PatientInfoRepository ) {
    this.objectUseCase = _objectUseCase
  }

  async invoke( idPatient: string ): Promise<IApiResponsePatientInfo> {
    return this.objectUseCase.getPatientInfo( idPatient )
  }
}

export class GetPatientInfoByCi implements GetPatientInfoByCiUseCase {
  private objectUseCase: PatientInfoRepository

  constructor( _objectUseCase: PatientInfoRepository ) {
    this.objectUseCase = _objectUseCase
  }

  async invoke( ciPatient: string, isOnlyInfo?: boolean ): Promise<IApiResponsePatientInfo> {
    return this.objectUseCase.getPatientInfoByCi( ciPatient, isOnlyInfo )
  }
}
