import { styled } from '@mui/material';

import { TypographyWrapper } from '../wrapper';

export const LabelInputError = styled( TypographyWrapper )(
  ({ theme }) => ({
    color: theme.palette.error.main,
    fontSize: '.75rem',
    fontFamily: 'Roboto, Helvetica ,Arial, sans-serif',
    fontWeight: '400',
    fontStretch: 'normal',
    lineHeight: 'normal1.66',
    letterSpacing: '0.03333em',
    marginTop: '3px',
    marginLeft: '14px',
    marginRight: '14px',
  })
);
