import {
  IApiResponseSaveRegisterMedical,
  ISaveRegisterMedical,
} from '../../../dtos/MedicalRegister'
import MedicalRegisterDataSource from '../../dataSource/MedicalRegisterDataSource'
import { http } from '../../Http'

export default class MedicalRegisterMapper
  implements MedicalRegisterDataSource
{
  async postMedicalRegister(
    dataMedical: ISaveRegisterMedical,
  ): Promise<IApiResponseSaveRegisterMedical> {
    const data = await http
      .post<IApiResponseSaveRegisterMedical>(`/medical/register`, {
        body: dataMedical,
      })
      .then((response) => {
        const { data } = response

        return data
      })
      .catch((error) => {
        const data = {
          statusCode: error.response.status,
          body: error.response.body,
          headers: {
            'Access-Control-Allow-Headers': '',
            'Access-Control-Allow-Origin': '',
            'Access-Control-Allow-Methods': '',
          },
        }

        return data
      })

    return data
  }
}
