import {
  IApiResponseGetAvailableHours,
  IApiResponseGetEvents,
  IApiResponseSaveCalendarEvent,
  ICalendarEvent,
  IParamsGetCalendar,
} from '../../infrastructure/dtos/Calendar'
import { CalendarRepository } from '../repository/CalendarRepository'

export interface IPostCalendarUseCase {
  invoke(event: ICalendarEvent): Promise<IApiResponseSaveCalendarEvent>
}

export interface IGetCalendarUseCase {
  invoke(params: IParamsGetCalendar): Promise<IApiResponseGetEvents>
}

export interface IGetAvailableHoursInADayUseCase {
  invoke(params: IParamsGetCalendar): Promise<IApiResponseGetAvailableHours>
}

export interface IDeleteNonAttendanceHoursUseCase {
  invoke(
    office_id: string,
    idInterval: string,
  ): Promise<IApiResponseSaveCalendarEvent>
}

export class PostCalendarEvent implements IPostCalendarUseCase {
  private objectUseCase: CalendarRepository

  constructor(_objectUseCase: CalendarRepository) {
    this.objectUseCase = _objectUseCase
  }

  async invoke(event: ICalendarEvent): Promise<IApiResponseSaveCalendarEvent> {
    return this.objectUseCase.postAddCalendarEvent(event)
  }
}

export class DeleteNonAttendanceHours
  implements IDeleteNonAttendanceHoursUseCase
{
  private objectUseCase: CalendarRepository

  constructor(_objectUseCase: CalendarRepository) {
    this.objectUseCase = _objectUseCase
  }

  async invoke(
    office_id: string,
    idInterval: string,
  ): Promise<IApiResponseSaveCalendarEvent> {
    return this.objectUseCase.deleteNonAttendanceHours(office_id, idInterval)
  }
}

export class GetCalendarEvent implements IGetCalendarUseCase {
  private objectUseCase: CalendarRepository

  constructor(_objectUseCase: CalendarRepository) {
    this.objectUseCase = _objectUseCase
  }

  async invoke(params: IParamsGetCalendar): Promise<IApiResponseGetEvents> {
    return this.objectUseCase.getEventsByMonth(params)
  }
}

export class GetAvailableHoursInADay
  implements IGetAvailableHoursInADayUseCase
{
  private objectUseCase: CalendarRepository

  constructor(_objectUseCase: CalendarRepository) {
    this.objectUseCase = _objectUseCase
  }

  async invoke(
    params: IParamsGetCalendar,
  ): Promise<IApiResponseGetAvailableHours> {
    return this.objectUseCase.getAvailableHoursInADay(params)
  }
}
