import { ReactNode } from 'react'
import { Navigate, RouteObject, useRoutes } from 'react-router-dom'

import { AuthLayout } from './layouts'
import { useSession } from './services/Session/sessionHook'
import { LoginPageForm } from './views/Auth/LoginAuth/LoginPageForm'
import Dashboard from './views/Dashboard/Dashboard'
import LoadingPage from './views/loadingPage'
import ProfileMedicalForm from './views/medical/ProfileMedical/ProfileMedicalForm/ProfileMedicalForm'
import ProfileMedicalForm1 from './views/medical/ProfileMedical/ProfileMedicalForm1/ProfileMedicalForm1'
import { ProfileMedicalScheduleSetting } from './views/medical/ProfileMedical/ProfileMedicalScheduleSetting/ProfileMedicalScheduleSetting'
import RegisterConsultory from './views/medical/ProfileMedical/RegisterConsultory/RegisterConsultoryForm'
import { Status } from './views/medical/Status'

const AuthRoutes: RouteObject[] = [
  {
    path: '/medico',
    element: <AuthLayout />,
    children: [
      {
        path: 'register',
        children: [
          {
            index: true,
            element: <Navigate to="/medico/register/profile" replace />,
          },
          { path: 'profile', element: <ProfileMedicalForm /> },
          { path: 'profile1', element: <ProfileMedicalForm1 /> },
          { path: 'registerConsultory', element: <RegisterConsultory /> },
          { path: 'profile2', element: <ProfileMedicalScheduleSetting /> },
        ],
      },
    ],
  },
  { path: 'dashboard/*', element: <Dashboard /> },
  { path: 'status', element: <Status /> },
  { path: 'logout', element: <Status /> },
  { path: '/*', element: <Navigate to="/dashboard/index" replace /> },
]
const LoadingRoutes: RouteObject[] = [
  {
    path: '/loading',
    element: <LoadingPage />,
  },
  { path: '/*', element: <LoadingPage /> },
]

const PublicRoutes: RouteObject[] = [
  {
    path: '/',
    element: <AuthLayout />,
    children: [{ index: true, element: <LoginPageForm /> }],
  },
  {
    path: '/*',
    element: (
      <Navigate to="/" replace state={{ url: window.location.pathname }} />
    ),
  },
]

export const AppRouter = (): React.ReactElement<
  ReactNode,
  string | React.JSXElementConstructor<ReactNode>
> | null => {
  const { isAuthenticated, isLoadingPage, isLoading } = useSession()
  let routes
  if (isLoading) {
    routes = LoadingRoutes
  } else if (!isAuthenticated) {
    routes = PublicRoutes
  } else {
    routes = isLoadingPage ? LoadingRoutes : AuthRoutes
  }

  return useRoutes(routes)
}
