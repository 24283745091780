import { AxiosError } from 'axios'

import {
  IApiResponseAppointment,
  IAppointmentDetails,
  IAppointmentInfoResponse,
  IAppointmentsByDoctorIdParams,
  IAppointmentsByDoctorIdResponse,
  IGetAppointmentProps,
  IResponseAppointment,
  ISaveNextAppointment,
  ISaveNextAppointmentParams,
} from '../../../dtos/Appointments'
import AppointmentDataSource from '../../dataSource/AppointmentDataSource'
import { http } from '../../Http'

export default class AppointmentMapper implements AppointmentDataSource {
  async getAppointment({
    officeId,
    date_from,
    date_to,
    name_or_ci,
  }: IGetAppointmentProps): Promise<
    IApiResponseAppointment<IResponseAppointment>
  > {
    try {
      let query = ''

      if (date_from && date_to) {
        query += `date_from=${date_from}&date_to=${date_to}&`
      }
      if (name_or_ci) {
        query += `name_or_ci=${name_or_ci}`
      }
      const { data } = await http.get<
        IApiResponseAppointment<IResponseAppointment>
      >(`send_data/appointments/${officeId}?${query}`)

      return data
    } catch (error: unknown) {
      const data: IApiResponseAppointment<IResponseAppointment> = {
        statusCode: (error as AxiosError).response?.status as number,
        body: (error as AxiosError).response?.statusText as string,
        headers: {
          'Access-Control-Allow-Headers': '',
          'Access-Control-Allow-Origin': '',
          'Access-Control-Allow-Methods': '',
          'Access-Control-Allow-Credentials': false,
        },
      }

      return data
    }
  }

  async getAppointmentDetails(
    appointmentId: string,
  ): Promise<IApiResponseAppointment<IAppointmentDetails>> {
    try {
      const { data } = await http.get<
        IApiResponseAppointment<IAppointmentDetails>
      >(`send_data/send_appointment_details/${appointmentId}`)

      return data
    } catch (error: unknown) {
      return {
        statusCode: (error as AxiosError)?.response?.status as number,
        body: (error as AxiosError)?.response?.statusText as string,
        headers: {
          'Access-Control-Allow-Headers': '',
          'Access-Control-Allow-Origin': '',
          'Access-Control-Allow-Methods': '',
          'Access-Control-Allow-Credentials': false,
        },
      }
    }
  }

  async getAppointmentInfo(
    appointmentId: string,
  ): Promise<IApiResponseAppointment<IAppointmentInfoResponse>> {
    try {
      const { data } = await http.get<
        IApiResponseAppointment<IAppointmentInfoResponse>
      >(`send_data/appointment_info/${appointmentId}`)

      return data
    } catch (error: unknown) {
      return {
        statusCode: (error as AxiosError)?.response?.status as number,
        body: (error as AxiosError)?.response?.statusText as string,
        headers: {
          'Access-Control-Allow-Headers': '',
          'Access-Control-Allow-Origin': '',
          'Access-Control-Allow-Methods': '',
          'Access-Control-Allow-Credentials': false,
        },
      }
    }
  }

  async saveNextAppointment(
    params: ISaveNextAppointmentParams,
  ): Promise<IApiResponseAppointment<ISaveNextAppointment>> {
    try {
      const { data } = await http.post<
        IApiResponseAppointment<ISaveNextAppointment>
      >(`save_data/next_appointment`, params)

      return data
    } catch (error: unknown) {
      return {
        statusCode: (error as AxiosError)?.response?.status as number,
        body: (error as AxiosError)?.response?.statusText as string,
        headers: {
          'Access-Control-Allow-Headers': '',
          'Access-Control-Allow-Origin': '',
          'Access-Control-Allow-Methods': '',
          'Access-Control-Allow-Credentials': false,
        },
      }
    }
  }

  async getAppointmentsByDoctorId({
    doctor_id, date,
  }: IAppointmentsByDoctorIdParams): Promise<IApiResponseAppointment<IAppointmentsByDoctorIdResponse>> {
    try {
      const { data } = await http.get<
        IApiResponseAppointment<IAppointmentsByDoctorIdResponse>
      >(`send_data/appointments_by_doctor_id/${doctor_id}/${date}`)
      return data
    } catch (error) {
      return {
        statusCode: (error as AxiosError)?.response?.status as number,
        body: {
          message: (error as AxiosError)?.response?.statusText as string,
          data: [],
        },
        headers: {
          'Access-Control-Allow-Headers': '',
          'Access-Control-Allow-Origin': '',
          'Access-Control-Allow-Methods': '',
          'Access-Control-Allow-Credentials': false,
        },
      }
    }
  }
}
