import { useAuth0 } from '@auth0/auth0-react'
import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

import containerText from './../../medical/ProfileMedical/ProfileMedicalForm/ProfileMedicalFormText.json'

export const LoginPageForm = (): JSX.Element => {
  const { loginWithRedirect, isAuthenticated, isLoading, error } = useAuth0()
  const location = useLocation()
  useEffect(() => {
    if (!isLoading) {
      if (!isAuthenticated) {
        if (!error) {
          localStorage.setItem('url', location.state?.url)
          loginWithRedirect()
        }
      }
    }
  }, [isLoading])
  return <>{error && <>{containerText.send_email_verification}</>}</>
}
