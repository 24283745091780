import { styled } from '@mui/material'

import { BoxWrapper, FadeWrapper, IconButtonWrapper, TypographyWrapper } from '../../../../../../../components'

export const FadeConfirmSchedule = styled( FadeWrapper )(() => ({
  height: 'auto',
  overflowY: 'auto'
}))

export const BoxConfirmSchedule = styled( BoxWrapper )(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: theme.palette.background.paper,
  background: theme.palette.common.white,
  borderRadius: '30px',
  p: 4,
  boxShadow: '2px 2px 18px 14px rgba(0, 0, 0, 0.03)'
}))

export const CloseButton = styled( IconButtonWrapper )(({ theme }) => ({
  position: 'absolute',
  right: 15,
  top: 20,
  color: theme.palette.primary.dark,
  marginBottom: '20px'
}))

export const TypographyConfirmSchedule = styled( TypographyWrapper )(({ theme }) => ({
  color: theme.palette.primary.dark,
  textAlign: 'center',
  fontFamily: 'Helvetica',
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: '700',
  lineHeight: 'normal',
}))
