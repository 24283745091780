import { pdf } from '@react-pdf/renderer'
import { useContext, useState } from 'react'

import { TypographyWrapper } from '../../../../../../../components'
import { ReciPePdf } from '../../../../../../../components/RecipePdf/RecipePdf'
import {
  AccountContext,
  AuthContextType,
} from '../../../../../../../contexts/AccountContext'
import { IDoctorPrescription } from '../../../../../../../infrastructure/dtos/DoctorInfo'
import { ICodeCie10 } from '../../../../../../../infrastructure/dtos/SearchCie10'
import { sendAppointmentRecipeUtility } from '../../../../../../../services/Contracts/Utility/PrescriptionsUtility'
import { fileToBase64 } from '../../../../../../../utils/fileToBase64'
import { IPrescription, IResendPrescription } from '../../types'

interface IUseSummaryProps {
  onChangePrescription?: (value: IPrescription) => void
  onChangeIsSubmit?: (value: boolean) => void
  onBack?: () => void
  prescription: IPrescription
  doctorAppointmentInfo: IDoctorPrescription
  resendData?: IResendPrescription
}

interface IUseSummary {
  handleSubmit: () => void
  handleBack: () => void
  printPdf: () => void
  handleResend: () => void
  handleDownload: () => void
  setRemarks: React.Dispatch<React.SetStateAction<string>>
  items: JSX.Element[]
  remarks: string
}

export const useSummary = ({
  onChangePrescription,
  onChangeIsSubmit,
  onBack,
  prescription,
  doctorAppointmentInfo,
  resendData,
}: IUseSummaryProps): IUseSummary => {
  const { remarks: remark } = prescription as IPrescription
  const [remarks, setRemarks] = useState<string>(remark)
  const [blob, setBlob] = useState<Blob>()
  const { handleAlert } = useContext(AccountContext) as AuthContextType

  const handleResend = async (): Promise<void> => {
    try {
      const prevPdf = pdf(
        <ReciPePdf
          doctorAppointmentInfo={doctorAppointmentInfo}
          prescription={prescription}
          remarks={remarks}
        />,
      )

      const blob: Blob = await prevPdf.toBlob()
      setBlob(blob)
      const blobed: string | ArrayBuffer | null = await fileToBase64(blob)
      const data = {
        appointment_id: resendData?.appointment_id as string,
        prescription_file: blobed?.toString().split('base64,')[1] as string,
        user_id: resendData?.user_id as string,
        prescription_id: resendData?.prescription_id as string,
      }
      const result = await sendAppointmentRecipeUtility(data)
      if (result.status) {
        handleAlert(true, 'Se ha enviado la receta exitosamente', 'success')
      } else {
        handleAlert(true, 'Ha ocurrido un error al enviar la receta', 'error')
      }
    } catch (error: unknown) {
      handleAlert(true, 'Ha ocurrido un error al enviar la receta', 'error')
    }
  }

  const handleDownload = async (): Promise<void> => {
    try {
      const prevPdf = pdf(
        <ReciPePdf
          doctorAppointmentInfo={doctorAppointmentInfo}
          prescription={prescription}
          remarks={prescription.remarks}
        />,
      )
      const blobLocal: Blob = await prevPdf.toBlob()
      const url: string = URL.createObjectURL(blobLocal)
      window.open(url, '_blank')
    } catch (error: unknown) {
      handleAlert(true, 'Error al generar el archivo pdf', 'error')
    }
  }

  const handleSubmit = async (): Promise<void> => {
    try {
      const prevPdf = pdf(
        <ReciPePdf
          doctorAppointmentInfo={doctorAppointmentInfo}
          prescription={prescription}
          remarks={remarks}
        />,
      )

      const blob: Blob = await prevPdf.toBlob()
      setBlob(blob)
      const blobed: string | ArrayBuffer | null = await fileToBase64(blob)
      onChangePrescription?.({
        ...prescription,
        remarks,
        prescription_file: blobed,
      })
      onChangeIsSubmit?.(true)
    } catch (error: unknown) {
      handleAlert(true, 'Ha ocurrido un error al enviar la receta', 'error')
    }
  }

  const handleBack = (): void => {
    onChangePrescription?.({
      ...prescription,
      remarks,
    })
    if (onBack) {
      onBack()
    }
  }

  const printPdf = (): void => {
    const url: string = URL.createObjectURL(blob as Blob)
    window.open(url, '_blank')
  }

  const items: JSX.Element[] = prescription?.diagnostic?.map(
    (item: ICodeCie10, index: number) => {
      return (
        <TypographyWrapper key={`diagnostic-${index}`}>
          {`${item?.code} - ${item?.description}`}
        </TypographyWrapper>
      )
    },
  )

  return {
    handleBack,
    handleSubmit,
    printPdf,
    handleDownload,
    handleResend,
    setRemarks,
    items,
    remarks,
  }
}
