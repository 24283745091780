import { styled } from '@mui/material/styles'

import {
  ButtonWrapper,
  TextFieldWrapper,
  TypographyWrapper,
} from '../components'
import GridWrapper from '../components/wrapper/GridWrapper'

export const ButtonLogin = styled(ButtonWrapper)((props) => ({
  borderRadius: '2.625rem',
  backgroundColor: props.theme.palette.primary.dark,
  fontSize: '1.125rem',
  height: '4.0625rem',
  textTransform: 'none',
}))

export const ButtonCorreo = styled(ButtonWrapper)((props) => ({
  width: '95%',
  borderRadius: '2.625rem',
  backgroundColor: props.theme.palette.primary.main,
  fontSize: '0.75rem',
  height: '45px',
  textTransform: 'none',
}))

export const TextFielRegister = styled(TextFieldWrapper)((props) => ({
  '& .MuiInputLabel-root': {},
  '& .MuiInputBase-root': {
    borderRadius: '3.4375rem',
    backgroundColor: props.theme.palette?.common.white,
    fontSize: '1.125rem',
    color: props.theme.palette?.text.primary,
    fontFamily: 'Helvetica',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    height: '3.4375rem',
  },
  '& fieldset': { borderColor: props.theme.palette?.common.white },
  '&::placeholder': {
    marginLeft: '1.875rem',
  },
  '& .MuiInputBase-root.Mui-disabled': {
    '& > fieldset': {
      borderColor: props.theme.palette?.common.white,
    },
  },
}))

export const TextFielRegisterArea = styled(TextFieldWrapper)((props) => ({
  '& .MuiInputLabel-root': {},
  '& .MuiInputBase-root': {
    borderRadius: '3.4375rem',
    backgroundColor: props.theme.palette?.common.white,
    fontSize: '1.125rem',
    color: props.theme.palette?.text.primary,
    fontFamily: 'Helvetica',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    height: '7.4375rem',
  },
  '& fieldset': { borderColor: props.theme.palette?.common.white },
  '&::placeholder': {
    marginLeft: '1.875rem',
  },
  '& .MuiInputBase-root.Mui-disabled': {
    '& > fieldset': {
      borderColor: props.theme.palette?.common.white,
    },
  },
}))

export const TypographyWrapperAuth = styled(TypographyWrapper)((props) => ({
  color: props.theme.palette.primary.dark,
  fontSize: '20px',
  fontFamily: 'Helvetica',
  fontWeight: 'normal',
  fontStretch: 'normal',
  fontStyle: 'normal',
  lineHeight: 'normal',
  letterSpacing: 'normal',
  textAlign: 'center',
}))

export const ButtonSelectList = styled(ButtonWrapper)((props) => ({
  borderRadius: '42px',
  fontSize: '13px',
  color: props.theme.palette.primary.dark,
  textTransform: 'none',
  width: '100%',
  height: '60px',
  marginTop: '10px',
  marginBottom: '10px',
  ':hover': {
    color: props.theme.palette.common.white,
  },
  boxShadow: '1px 1px 7px 7px rgba(0, 0, 0, 0.03)',
}))

export const TypographyTituloBanner = styled(TypographyWrapper)(() => ({
  mb: 1,
  color: 'white',
  fontSize: '62px',
}))

export const GridBanner = styled(GridWrapper)((props) => ({
  width: '100%',
  backgroundColor: props.theme.palette.primary.main,
  padding: '60px 835px 70px 836px',
  textAlign: 'center',
}))

export const GridBody = styled(GridWrapper)((props) => ({
  backgroundColor: props.theme.palette.background.default,
}))

export const GridBodyContaniner = styled(GridWrapper)((props) => ({
  width: '57.6875rem',
  backgroundColor: props.theme.palette.background.default,
  padding: 3,
  borderRadius: 2,
}))

export const LoginContentsStyle = styled(GridWrapper)(() => ({
  display: 'flex',
  justifyContent: 'center',
}))

export const RedirectLoginContentStyle = styled(GridWrapper)(() => ({
  display: 'flex',
  justifyContent: 'center',
  marginTop: '0.625rem',
}))

export const TypographyLabelContentsStyle = styled(GridWrapper)(() => ({
  display: 'flex',
  justifyContent: 'center',
}))

export const TypographyLabel = styled(TypographyWrapper)(() => ({
  color: ' #000',
  fontSize: '1.25rem',
  fontFamily: 'Helvetica',
  fontWeight: 'normal',
  fontStretch: 'normal',
  fontStyle: 'normal',
  lineHeight: 'normal',
  letterSpacing: 'normal',
}))
