import { styled } from '@mui/material'

import {
  GridWrapper,
  TextFieldWrapper
} from '../../../../../components/wrapper'

export const TextFielModal = styled( TextFieldWrapper )(( props ) => ({
  '& .MuiInputLabel-root': {},
  '& .MuiInputBase-root': {
    marginTop: '10px',
    borderRadius: '3.4375rem',
    backgroundColor: '#fff',
    fontSize: '12px',
    color: props.theme.palette?.text.primary,
    fontFamily: 'Helvetica',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    height: '3.3rem',
    boxShadow: '1px 1px 7px 7px rgba(0, 0, 0, 0.03)',
    width: '100%',
    '@media (min-width: 1600px)': {
      fontSize: '16px',
    }
  },
  '& fieldset': { borderColor: 'white' },
  '&::placeholder': {
    marginLeft: '1.875rem'
  },
  '& .MuiInputBase-root.Mui-disabled': {
    '& > fieldset': {
      borderColor: 'white'
    }
  }
}))

export const ModalPad = styled( GridWrapper )(() => ({
  padding: '10px 30px'
}))
