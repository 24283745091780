import {
  IDoctorUpdateBodyResponse,
  IDoctorBody,
  IDoctorUpdateResponse,
  IDoctorPrescription,
  IDoctorPrescriptionProps,
  IRoot,
  IDoctorInformation,
  IDoctorPrescriptionResponse,
} from '../../../infrastructure/dtos/DoctorInfo'
import {
  doctorAndAppointmentInfoService,
  DoctorInfoService,
  IDoctorAndAppointmentInfoService,
  IDoctorInfoService,
  IUpdateDoctorInfoService,
  updateDoctorInfoService,
} from '../Persistencia/DoctorInfoService'

export interface IDoctorAndAppointmentInfoUtility {
  status: number
  data: IDoctorPrescription | string
}

export interface IUpdateDoctorInfoUtility {
  status: number
  data: string | IDoctorUpdateBodyResponse
}

export interface IDoctorInfoUtility {
  status: number
  data: string | IDoctorInformation
}

export async function DoctorInfoUtility(
  idDoctor: string,
): Promise<IDoctorInfoUtility> {
  try {
    const { data, status }: IDoctorInfoService =
      await DoctorInfoService(idDoctor)

    return {
      data:
        typeof data === 'string'
          ? data
          : ((data as IRoot).body as IDoctorInformation),
      status: (data as IRoot).statusCode === 200 && status === 1 ? status : 0,
    }
  } catch (error: unknown) {
    return {
      status: 0,
      data: ((error as IDoctorAndAppointmentInfoService)?.data as IRoot).body as string,
    }
  }
}

export async function doctorAndAppointmentInfoUtility(
  params: IDoctorPrescriptionProps,
): Promise<IDoctorAndAppointmentInfoUtility> {
  try {
    const { data, status }: IDoctorAndAppointmentInfoService =
      await doctorAndAppointmentInfoService(params)

    return {
      data: (data as IDoctorPrescriptionResponse).body.data as IDoctorPrescription,
      status: (data as IDoctorPrescriptionResponse).statusCode === 200 && status === 1 ? status : 0,
    }
  } catch (error: unknown) {
    return {
      status: 0,
      data: ((error as IDoctorAndAppointmentInfoService)?.data as IRoot).body as string,
    }
  }
}

export async function updateDoctorInfoUtility(
  params: IDoctorBody,
  idDoctor: string,
): Promise<IUpdateDoctorInfoUtility> {
  try {
    const { data, status }: IUpdateDoctorInfoService =
      await updateDoctorInfoService(params, idDoctor)

    return {
      data:
        ((data as IDoctorUpdateResponse).body as IDoctorUpdateBodyResponse | string),
      status:
        (data as IDoctorUpdateResponse).statusCode === 200 && status === 1
          ? status
          : 0,
    }
  } catch (error: unknown) {
    return {
      status: 0,
      data: ((error as IUpdateDoctorInfoService).data as IDoctorUpdateResponse).body,
    }
  }
}
