import { styled } from '@mui/material'

import TypographyWrapper from '../wrapper/TypographyWrapper'

export const SecondLabelInput = styled(TypographyWrapper)(({theme}) => ({
  alignSelf: 'start',
  textAlign: 'left',
  color: theme.palette.common.black,
  fontSize: '12px',
  fontFamily: 'Helvetica',
  fontWeight: 'normal',
  fontStretch: 'normal',
  fontStyle: 'normal',
  lineHeight: 'normal',
  letterSpacing: 'normal',
  marginBottom: '0.6875rem',
  marginLeft: '0',
  '@media (min-width: 1600px)': {
    fontSize: '16px',
  },
}))
