import { IMedicalExamsRepository } from '../../domain/repository/MedicalExamsRepository'
import { IGetMedicalExamsResponse } from '../dtos/MedicalExamsItem'
import IMedicalExamsDataSource from '../http/dataSource/MedicalExamsDataSource'

export class MedicalExamsRepositoryImpl implements IMedicalExamsRepository {
  private dataSource: IMedicalExamsDataSource

  constructor(dataSource: IMedicalExamsDataSource) {
    this.dataSource = dataSource
  }

  async getMedicalExams(patientId: string): Promise<IGetMedicalExamsResponse> {
    return this.dataSource.getMedicalExams(patientId)
  }
}
