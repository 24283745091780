/* eslint-disable @typescript-eslint/no-explicit-any */
import { Tab, TabProps } from '@mui/material'
import React from 'react'

const TabWrapper: React.ForwardRefExoticComponent<
  Omit<TabProps, 'ref'> & React.RefAttributes<any>
> = React.forwardRef<any, TabProps>((props, ref) => (
  <Tab {...props} ref={ref} />
))

export default TabWrapper
