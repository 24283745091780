import { styled } from '@mui/material'

import { ListItemButtonWrapper, ListWrapper } from '../../../../components'
import PaperWrapper from '../../../../components/wrapper/PaperWrapper'

export const ButtonsListStyled = styled( ListItemButtonWrapper )(() => ({
  backgroundColor: 'rgb(249,248,252)',
  ':hover': {
    backgroundColor: '#E0E0E0'
  }
}))

export const ListActionStyled = styled( ListWrapper )(({ theme }) => ({
  padding: '0 8px',
  color: theme.palette.primary.dark,
  '& ul': {
    paddingTop: '2px',
    paddingBottom: '2px'
  }
}))

export const ListActionButtons = styled( ListWrapper )(() => ({
  width: '100%',
  borderRadius: '32px',
  boxShadow: '1px 4px 8px 6px rgba(0,0,0, 0.028846)',
  opacity: 1,
  backgroundColor: 'rgba(249,248,252, 1)'
}))

export const ListPaper = styled( PaperWrapper )(() => ({
  color: '#000',
  borderRadius: '32px',
  boxShadow: '1px 4px 8px 6px rgba(0,0,0, 0.028846)',
  position: 'relative',
  zIndex: 100
}))
