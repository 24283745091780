import {
  IApiResponseOffices,
  IUpdateInfoOfficeParams,
  IUpdateInfoOfficeResponse,
} from '../../infrastructure/dtos/Offices'
import { BodyModifyStatusOfficeRequest, IApiResponseModifyStatusOffice } from '../../infrastructure/dtos/UpdateOffices'
import { OfficesRepository } from '../repository/OfficesRepository'

export interface IGetOfficesUseCase {
  invoke: (idDoctor: string) => Promise<IApiResponseOffices>
}

export interface IUpdateOfficesUseCase {
  invoke: (
    params: IUpdateInfoOfficeParams,
  ) => Promise<IUpdateInfoOfficeResponse>
}

export interface IUpdateOfficesStatusUseCase {
  invoke: (body: BodyModifyStatusOfficeRequest) => Promise<IApiResponseModifyStatusOffice>
}

export class GetOffices implements IGetOfficesUseCase {
  private objectUseCase: OfficesRepository

  constructor(_objectUseCase: OfficesRepository) {
    this.objectUseCase = _objectUseCase
  }

  async invoke(idDoctor: string): Promise<IApiResponseOffices> {
    return this.objectUseCase.getOffices(idDoctor)
  }
}

export class UpdateOffices implements IUpdateOfficesUseCase {
  private objectUseCase: OfficesRepository

  constructor(_objectUseCase: OfficesRepository) {
    this.objectUseCase = _objectUseCase
  }

  async invoke(
    params: IUpdateInfoOfficeParams,
  ): Promise<IUpdateInfoOfficeResponse> {
    return this.objectUseCase.updateOffice(params)
  }
}

export class UpdateOfficesStatus implements IUpdateOfficesStatusUseCase {
  private objectUseCase: OfficesRepository

  constructor(_objectUseCase: OfficesRepository) {
    this.objectUseCase = _objectUseCase
  }

  async invoke(body: BodyModifyStatusOfficeRequest): Promise<IApiResponseModifyStatusOffice> {
    return this.objectUseCase.updateOfficeStatus(body)
  }
}
