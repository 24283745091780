import {
  PostNewDate,
  PostNewOverbookingAppointment,
} from '../../../domain/useCase/NewDateUseCase'
import {
  IApiResponseSaveNewDate,
  ISaveNewDate,
  IApiResponseSaveOverbooking,
} from '../../../infrastructure/dtos/NewDate'
import NewDateMapper from '../../../infrastructure/http/mappers/NewDateMapper/NewDateMapper'
import { NewDateRepositoryImpl } from '../../../infrastructure/RepositoryImpl/NewDateRepositoryImpl'

export interface IServiceSaveOverbooking {
  status: number
  data: IApiResponseSaveOverbooking | string
}

export interface IServiceSaveNewDate {
  status: number
  data: IApiResponseSaveNewDate | string
}

export async function NewDateService(
  dataNewDate: ISaveNewDate,
): Promise<IServiceSaveNewDate> {
  try {
    const UseCaseApi: PostNewDate = new PostNewDate(
      new NewDateRepositoryImpl(new NewDateMapper()),
    )

    return {
      status: 1,
      data: await UseCaseApi.invoke(dataNewDate),
    }
  } catch (error: unknown) {
    return {
      status: 0,
      data: (error as Error).message,
    }
  }
}

export async function NewOverbookingAppointmentService(
  dataNewDate: ISaveNewDate,
): Promise<IServiceSaveOverbooking> {
  try {
    const UseCaseApi: PostNewOverbookingAppointment =
      new PostNewOverbookingAppointment(
        new NewDateRepositoryImpl(new NewDateMapper()),
      )
    return {
      status: 1,
      data: await UseCaseApi.invoke(dataNewDate),
    }
  } catch (error: unknown) {
    return {
      status: 0,
      data: (error as Error).message,
    }
  }
}
