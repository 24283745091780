import { Tabs, TabsProps } from '@mui/material'
import React from 'react'

const TabsWrapper: React.ForwardRefExoticComponent<
  Omit<TabsProps, 'ref'> & React.RefAttributes<HTMLDivElement>
> = React.forwardRef<HTMLDivElement, TabsProps>((props, ref) => (
  <Tabs {...props} ref={ref} />
))

export default TabsWrapper
