import { SaveCode } from '../../../domain/useCase/SaveCodeUseCase'
import {
  IApiResponseSaveCodeDoctor,
  ISaveCodeDoctor,
} from '../../../infrastructure/dtos/SaveCode'
import SaveCodeMapper from '../../../infrastructure/http/mappers/SaveCodeMapper/SaveCodeMapper'
import { SaveCodeRepositoryImpl } from '../../../infrastructure/RepositoryImpl/SaveCodeRepositoryImpl'

export interface ISaveCodeService {
  status: number
  data: IApiResponseSaveCodeDoctor | string
}

export async function SaveCodeService(
  dataSaveCode: ISaveCodeDoctor,
): Promise<ISaveCodeService> {
  try {
    const UseCaseApi: SaveCode = new SaveCode(
      new SaveCodeRepositoryImpl(new SaveCodeMapper()),
    )

    return {
      status: 1,
      data: await UseCaseApi.invoke(dataSaveCode),
    }
  } catch (error) {
    return {
      status: 0,
      data: error as string,
    }
  }
}
