import { styled } from '@mui/material';

import { OutlinedInputWrapper } from '../wrapper';

export const InputCurrencyStyled = styled( OutlinedInputWrapper )(( props ) => ({
  borderRadius: '3.4375rem',
  backgroundColor: props.theme.palette.common.white,
  fontSize: '1.125rem',
  color: props.theme.palette?.text.primary,
  fontFamily: 'Helvetica',
  fontWeight: 'normal',
  fontStretch: 'normal',
  fontStyle: 'normal',
  lineHeight: 'normal',
  letterSpacing: 'normal',
  height: '3.4375rem',
  boxShadow: '1px 1px 7px 7px rgba(0, 0, 0, 0.03)',
  '& fieldset': { borderColor: props.theme.palette.common.white, },
  '& input[type=number]': {
    'MozAppearance': 'textfield'
  },
  '& input[type=number]::-webkit-outer-spin-button': {
    'WebkitAppearance': 'none',
    margin: 0
  },
  '& input[type=number]::-webkit-inner-spin-button': {
    'WebkitAppearance': 'none',
    margin: 0
  }
}))