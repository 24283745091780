import { GetGenders } from '../../../domain/useCase/GetGenders'
import { IGendersResponse } from '../../../infrastructure/dtos/Genders'
import GendersMapper from '../../../infrastructure/http/mappers/GendersMapper/GenderMapper'
import { GendersRepositoryImpl } from '../../../infrastructure/RepositoryImpl/GendersRepositoryImpl'

export interface IServiceGenders {
  status: number
  data: IGendersResponse | string
}

export async function GetGendersService(): Promise<IServiceGenders> {
  try {
    const UseCaseGenders = new GetGenders(
      new GendersRepositoryImpl(new GendersMapper()),
    )

    return {
      status: 1,
      data: await UseCaseGenders.invoke(),
    }
  } catch (error: unknown) {
    return {
      status: 0,
      data: error as string,
    }
  }
}
