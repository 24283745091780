import {
  IApiResponseQualification,
  IResponseQualificationList,
  ISendQualificationStatus,
  IServiceQualification,
} from '../../../infrastructure/dtos/qualification';
import {
  GetQualificationListDoctorService,
  PutQualificationStatusService,
} from '../Persistencia/QualificationService';

export async function GetQualificationListDoctorUtility(
  doctor_id: string
): Promise<IServiceQualification<IResponseQualificationList>> {
  try {
    const {
      data,
      status,
    }: IServiceQualification<
    IApiResponseQualification<IResponseQualificationList>
    > = await GetQualificationListDoctorService( doctor_id );

    return {
      data: (data as IApiResponseQualification<IResponseQualificationList>).body,
      status:
        status === 1 && (data as IApiResponseQualification<IResponseQualificationList>).statusCode === 200
          ? status
          : 0,
    };

  } catch ( error: unknown ) {
    return {
      status: 0,
      data: (
        (error as IServiceQualification<IApiResponseQualification<IResponseQualificationList>>)
          .data as IApiResponseQualification<IResponseQualificationList>).body
    };
  }
}

export async function PutQualificationStatusUtility(
  params: ISendQualificationStatus
): Promise<IServiceQualification<string>> {
  try {
    const {
      data,
      status,
    }: IServiceQualification<IApiResponseQualification<string>> =
      await PutQualificationStatusService( params );

    return {
      data: (data as IApiResponseQualification<string>).body,
      status: status === 1 && (data as IApiResponseQualification<string>).statusCode === 200 ? status : 0,
    }

  } catch ( error: unknown ) {
    return {
      status: 0,
      data: (
        (error as IServiceQualification<IApiResponseQualification<string>>)
          .data as IApiResponseQualification<string>
      ).body,
    };
  }
}
