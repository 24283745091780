import {
  CalendarApi,
  DateSelectArg,
  DatesSetArg,
  EventApi,
} from '@fullcalendar/core'
import dayjs from 'dayjs'
import { useState, createContext, ReactNode } from 'react'

import { EventCalendar, ICalendarEvent } from '../components/Calendar/types'
import {
  IDataEvents,
  IEvent,
  IResponseCalendarEvent,
} from '../infrastructure/dtos/Calendar'
import { GetCalendarEvents } from '../services/Contracts/Persistencia/Calendar'

export interface CalendarContextProps {
  selectedDate: DateSelectArg | null
  toolbarPosition: {
    left: number
    top: number
  } | null
  state: IState
  apiEvents: EventCalendar[]
  currentCalendarDateToShow: dayjs.Dayjs | null
  getEvents: (
    dateSearch: string,
    officeId: string,
    userId: string,
  ) => Promise<void>
  handleDateSelect: (selectInfo: DateSelectArg) => void
  handleSaveSelectedDate: (data: ICalendarEvent) => void
  // handleEventClick: ( clickInfo: EventClickArg ) => void
  handleEvents: (events: EventApi[]) => void
  handleChangeView: (arg: DatesSetArg, officeId: string, userId: string) => void
  handleClose: () => void
}
interface IState {
  weekendsVisible: boolean
  currentEvents: EventApi[]
}

interface IProps {
  children: ReactNode
}

export const CalendarContext = createContext<CalendarContextProps>(
  {} as CalendarContextProps,
)

export const CalendarProvider = ({ children }: IProps): JSX.Element => {
  const [selectedDate, setSelectedDate] = useState<DateSelectArg | null>(null)
  const [toolbarPosition, setToolbarPosition] = useState<{
    left: number
    top: number
  } | null>(null)
  const [state, setState] = useState<IState>({
    weekendsVisible: true,
    currentEvents: [],
  })
  const [apiEvents, setApiEvents] = useState<EventCalendar[]>([])
  const [currentMonth, setCurrentMonth] = useState<string>('')
  const [currentCalendarDateToShow, setCurrentCalendarDateToShow] =
    useState<dayjs.Dayjs | null>(null)

  const handleDateSelect = (selectInfo: DateSelectArg): void => {
    setSelectedDate(selectInfo)
    setToolbarPosition({
      left:
        selectInfo.jsEvent?.pageX === undefined ? 0 : selectInfo.jsEvent.pageX,
      top:
        selectInfo.jsEvent?.pageY === undefined ? 0 : selectInfo.jsEvent.pageY,
    })
    const calendarApi: CalendarApi = selectInfo.view.calendar

    calendarApi.unselect() // clear date selection
  }

  const handleSaveSelectedDate = (data: ICalendarEvent): void => {
    const calendar = selectedDate?.view.calendar

    calendar?.addEvent({
      allDay: data.all_day,
      title: data.name,
      start: data.rango[0].hour_from,
      end: data.rango[data.rango.length - 1].hour_to,
    })
    handleClose()
  }

  // const handleEventClick = ( clickInfo: EventClickArg ): void => {}

  const handleChangeView = (
    arg: DatesSetArg,
    officeId: string,
    userId: string,
  ): void => {
    if (
      !Boolean(currentMonth) ||
      (arg.view.title !== currentMonth &&
        arg.view.title.split(' - ').length === 1)
    ) {
      setCurrentMonth(arg.view.title)
      setCurrentCalendarDateToShow(dayjs(arg.view.currentStart))
      getEvents(
        dayjs(arg.view.currentStart).format('YYYY-MM-DD'),
        officeId,
        userId,
      )
    }
  }

  const getEvents = async (
    dateSearch: string,
    officeId = '',
    userId: string,
  ): Promise<void> => {
    if (!officeId) {
      return
    }
    const data: IResponseCalendarEvent = await GetCalendarEvents({
      date_search: dateSearch,
      office_id: officeId,
      user_id: userId,
      duration: 30,
      officeType: 'OFFICE',
    })
    const days: IDataEvents[] = (data.body as IEvent)?.data_events
    let events: EventCalendar[] = []

    if (days) {
      events = days.reduce((acc: EventCalendar[], day: IDataEvents) => {
        if (day.events.hours_of_non_attendance.length > 0) {
          const hoursOfNonAttendance: EventCalendar[] =
            day.events.hours_of_non_attendance.map((hona) => ({
              id: hona.interval_id,
              start: `${hona.non_available_date}T${hona.hour_from}`,
              end: `${hona.non_available_date}T${hona.hour_to}`,
              allDay: hona.non_available_all_day,
              title: hona.non_available_name,
              groupId: 'nonAttendance',
              backgroundColor: '#9FBCFF',
              borderColor: '#9FBCFF',
            }))

          acc.push(...hoursOfNonAttendance)
        }
        if (day.events.medical_appointments.length > 0) {
          const medicalAppointments: EventCalendar[] =
            day.events.medical_appointments.map((ma) => ({
              id: ma.appointment_id,
              start: `${ma.appointment_date}T${ma.hour_from}`,
              end: `${ma.appointment_date}T${ma.hour_to}`,
              title: `${ma.patient_name} ${ma.patient_last_name}`,
              groupId: 'appointment',
              backgroundColor: '#75C44C',
              borderColor: '#75C44C',
            }))

          acc.push(...medicalAppointments)
        }

        return acc
      }, [])
    }
    setApiEvents(events)
  }

  const handleEvents = (events: EventApi[]): void => {
    setState({
      ...state,
      currentEvents: events,
    })
  }

  const handleClose = (): void => {
    setToolbarPosition(null)
  }

  return (
    <CalendarContext.Provider
      value={{
        selectedDate,
        toolbarPosition,
        state,
        apiEvents,
        currentCalendarDateToShow,
        getEvents,
        handleDateSelect,
        handleSaveSelectedDate,
        // handleEventClick,
        handleEvents,
        handleChangeView,
        handleClose,
      }}
    >
      {children}
    </CalendarContext.Provider>
  )
}
