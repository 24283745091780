import { IClinicalPictureRepository } from '../../domain/repository/ClinicalPictureRepository'
import {
  IClinicalPictureResponse,
  ISaveClinicalPictureServiceParams,
} from '../dtos/ClinicalPicture'
import ClinicalPictureDataSource from '../http/dataSource/ClinicalPictureDataSource'

export class ClinicalPictureRepositoryImpl
  implements IClinicalPictureRepository
{
  dataSource: ClinicalPictureDataSource

  constructor(_datasource: ClinicalPictureDataSource) {
    this.dataSource = _datasource
  }

  saveClinicalPicture(
    dataClinicalPicture: ISaveClinicalPictureServiceParams,
  ): Promise<IClinicalPictureResponse> {
    return this.dataSource.saveClinicalPicture(dataClinicalPicture)
  }
}
