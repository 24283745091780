import { useEffect, useState } from 'react'

import { Severity } from '../../contexts/AuthReducer'

interface ICoordinates {
  latitud: number
  longitud: number
}
interface ICoordinatesType {
  coordinates: ICoordinates
  geolocationPosition: () => void
}

export const Geolocation = (
  handleAlert: (open: boolean, message: string, severity?: Severity) => void,
): ICoordinatesType => {
  // tener cargado las coordenadas
  const [coordinates, setCoordinates] = useState<ICoordinates>({
    latitud: -2.1528576,
    longitud: -79.9244288,
  })
  // traer datos desde la api para retornarlas al constructor
  const options = {
    enableHighAccuracy: true,
    timeout: 5000,
    maximumAge: 0,
  }

  function success(pos: GeolocationPosition): void {
    const crd = pos.coords

    setCoordinates({
      latitud: crd.latitude,
      longitud: crd.longitude,
    })
  }
  function errors(err: GeolocationPositionError): void {
    handleAlert(true, `ERROR(${err.code}): ${err.message}`, 'error')
  }
  const geolocationPosition = (): void => {
    if (navigator.geolocation) {
      navigator.permissions
        .query({ name: 'geolocation' })
        .then(function (result) {
          if (result.state === 'granted') {
            // If granted then you can directly call your function here
            navigator.geolocation.getCurrentPosition(success, errors, options)
          } else if (result.state === 'prompt') {
            // If prompt then the user will be asked to give permission
            navigator.geolocation.getCurrentPosition(success, errors, options)
          } else if (result.state === 'denied') {
            // If denied then you have to show instructions to enable location
          }
        })
    } else {
      handleAlert(
        true,
        'Geolocation is not supported by this browser.',
        'error',
      )
    }
  }

  useEffect(() => {
    geolocationPosition()
  }, [])

  return {
    coordinates,
    geolocationPosition,
  }
}
