import { IDoctorInfoRepository } from '../../domain/repository/DoctorInfoRepository'
import {
  IDoctorBody,
  IDoctorUpdateResponse,
  IDoctorPrescriptionProps,
  IRoot,
  IDoctorPrescriptionResponse,
} from '../dtos/DoctorInfo'
import DoctorInfoDataSource from '../http/dataSource/DoctorInfoDataSource'

export class DoctorInfoRepositoryImpl implements IDoctorInfoRepository {
  dataSource: DoctorInfoDataSource

  constructor(_datasource: DoctorInfoDataSource) {
    this.dataSource = _datasource
  }

  async updateDoctorInfo(
    params: IDoctorBody,
    idDoctor: string,
  ): Promise<IDoctorUpdateResponse> {
    return this.dataSource.updateDoctorInfo(params, idDoctor)
  }

  async getDoctorInfo(idDoctor: string): Promise<IRoot> {
    return this.dataSource.getDoctorInfo(idDoctor)
  }

  async getDoctorAndAppointmentInfo(
    params: IDoctorPrescriptionProps,
  ): Promise<IDoctorPrescriptionResponse> {
    return this.dataSource.getDoctorAndAppointmentInfo(params)
  }
}
