import { IMessageResponse, ISendNotificationService, ISendNotificationUtility } from '../../../infrastructure/dtos/Notifications';
import { sendDelayNotificationService } from '../Persistencia/NotificationsService';

export async function sendDelayNotificationUtility(
  user_id: string,
  office_id: string,
  appointment_id: string,
  message: string
): Promise<ISendNotificationUtility> {
  try {
    const { data, status } =
      await sendDelayNotificationService( user_id, office_id, appointment_id, message );

    return {
      data: (data.body as IMessageResponse).message,
      status: data.statusCode !== 200 || status !== 1 ? 0 : status,
    };
  } catch ( error ) {
    return {
      data: ( error as ISendNotificationService ).data.body as string,
      status: 0,
    };
  }
}
