import { styled } from '@mui/material'

import { GridWrapper } from '../wrapper'

export const DisableTextInput = styled(GridWrapper)((props) => ({
  display: 'grid',
  gridTemplateColumns: 'repeat(1fr)',
  gridGap: '10px',
  backgroundColor: props.theme?.palette?.info?.light,
  borderRadius: '39px',
  padding: '20px',
  width: '100%',
  '&:hover': {
    cursor: 'not-allowed',
  },
  '& p': {
    fontSize: '12px',
    '@media (min-width: 1600px)': {
      fontSize: '16px',
    },
  }
}))
