import { styled } from '@mui/material';

export const StyledForm = styled('form')(({theme}) => ({
  width: '60%',
  [theme.breakpoints.down('md')]: {
    width: '80%',
  },
  [theme.breakpoints.down('sm')]: {
    width: '90%',
  }
}))