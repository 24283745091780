import { useInviteList } from './components/AddAgreement/hooks/useInviteList'
import AgreementList from './components/AgreementList/AgreementList'
import InviteList from './components/InviteList/InviteList'
import { GridWrapper } from '../../../components'

function Agreements(): JSX.Element {
  const {
    gridBody,
    gridHeader,
    handleSubmit,
    inviteList,
    list,
    loadingInvitelist,
    loadingList,
    textColor
  } = useInviteList()

  return (
    <GridWrapper px={'4rem'} pt={2} data-testid="agreements-component">
      <InviteList handleSubmit={handleSubmit} inviteList={inviteList} loadingInvitelist={loadingInvitelist} />
      <AgreementList
        gridBody={gridBody}
        gridHeader={gridHeader}
        list={list}
        loadingList={loadingList}
        StatusColor={textColor}
      />
    </GridWrapper>
  )
}

export default Agreements