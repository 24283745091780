import { AxiosError } from 'axios'

import {
  IApiResponseGetAvailableHours,
  IApiResponseGetEvents,
  IApiResponseSaveCalendarEvent,
  ICalendarEvent,
  IParamsGetCalendar,
} from '../../../dtos/Calendar'
import CalendarDataSource from '../../dataSource/CalendarDataSource'
import { http } from '../../Http'

export default class CalendarMapper implements CalendarDataSource {
  async postAddCalendarEvent(
    event: ICalendarEvent,
  ): Promise<IApiResponseSaveCalendarEvent> {
    try {
      const { data }: { data: IApiResponseSaveCalendarEvent } =
        await http.post<IApiResponseSaveCalendarEvent>(
          `/save_data/save_non_attendance_hours`,
          {
            body: event,
          },
        )

      return data
    } catch (error: unknown) {
      const data = {
        statusCode: (error as AxiosError).response?.status as number,
        body: (error as AxiosError).response?.data as string,
        headers: {
          'Access-Control-Allow-Headers': '',
          'Access-Control-Allow-Origin': '',
          'Access-Control-Allow-Methods': '',
        },
      }

      return data
    }
  }

  async getEventsByMonth({
    date_search,
    office_id,
    user_id,
  }: IParamsGetCalendar): Promise<IApiResponseGetEvents> {
    try {
      const { data } = await http.get<IApiResponseGetEvents>(
        `/send_data/events_medical/${date_search}/${office_id}/${user_id}`,
      )

      return data
    } catch (error: unknown) {
      const data = {
        statusCode: (error as AxiosError).response?.status as number,
        body: (error as AxiosError).response?.data as string,
        headers: {
          'Access-Control-Allow-Headers': '',
          'Access-Control-Allow-Origin': '',
          'Access-Control-Allow-Methods': '',
        },
      }

      return data
    }
  }

  async getAvailableHoursInADay({
    date_search,
    office_id,
    user_id,
    duration,
    officeType
  }: IParamsGetCalendar): Promise<IApiResponseGetAvailableHours> {
    try {
      const getEcuadorTime = (): string => {
        const options: Intl.DateTimeFormatOptions = {
          hour: '2-digit',
          minute: '2-digit',
          hour12: false,
          timeZone: 'America/Guayaquil',
        }

        return new Date().toLocaleTimeString('en-US', options)
      }
      const hour_search = getEcuadorTime()

      const { data } = await http.get<IApiResponseGetAvailableHours>(
        `/send_data/schedule_availability/${date_search}/${office_id}/${user_id}/${hour_search}/${duration}/${officeType}`,
      )

      return data
    } catch (error: unknown) {
      const data = {
        statusCode: (error as AxiosError).response?.status as number,
        body: (error as AxiosError).response?.data as string,
        headers: {
          'Access-Control-Allow-Headers': '',
          'Access-Control-Allow-Origin': '',
          'Access-Control-Allow-Methods': '',
        },
      }

      return data
    }
  }

  // deleted/non_attendance_hours/{office_id}/{interval_id}
  async deleteNonAttendanceHours(
    office_id: string,
    idInterval: string,
  ): Promise<IApiResponseSaveCalendarEvent> {
    try {
      const { data } = await http.put<IApiResponseGetEvents>(
        `/deleted/non_attendance_hours/${office_id}/${idInterval}`,
      )

      return data
    } catch (error: unknown) {
      const data = {
        statusCode: (error as AxiosError).response?.status as number,
        body: (error as AxiosError).response?.data as string,
        headers: {
          'Access-Control-Allow-Headers': '',
          'Access-Control-Allow-Origin': '',
          'Access-Control-Allow-Methods': '',
        },
      }

      return data
    }
  }
}
