import dayjs from 'dayjs'

import { ICalendarData } from '../../../../infrastructure/dtos/CalendarInfo'
import { DatePickerModal } from '../../ModalDateStyles'
interface IDatePicker {
  onChangeDate: ( value: dayjs.Dayjs | null ) => void
  value: dayjs.Dayjs | null
  daysAvailability: ICalendarData
  disabled?: boolean
}

const ModalDatePicker = ({
  onChangeDate,
  value,
  daysAvailability,
  disabled
}: IDatePicker): JSX.Element => {
  return (
    <DatePickerModal
      shouldDisableDate={( day: dayjs.Dayjs ): boolean =>
        daysAvailability && !daysAvailability[dayjs( day ).format( 'YYYY-MM-DD' )]
      }
      disablePast
      onChange={onChangeDate}
      value={value}
      disabled={disabled}
    />
  )
}

export default ModalDatePicker
