import {
  ClinicalPicture,
  IClinicalPictureUseCase,
} from '../../../domain/useCase/ClinicalPictureUseCase'
import {
  IClinicalPictureResponse,
  ISaveClinicalPictureServiceParams,
} from '../../../infrastructure/dtos/ClinicalPicture'
import ClinicalPictureMapper from '../../../infrastructure/http/mappers/ClinicalPictureMapper/ClinicalPictureMapper'
import { ClinicalPictureRepositoryImpl } from '../../../infrastructure/RepositoryImpl/ClinicalPictureRepositoryImpl'

export interface IClinicalPictureService {
  status: number
  data: IClinicalPictureResponse | string
}

export async function ClinicalPictureService(
  params: ISaveClinicalPictureServiceParams,
): Promise<IClinicalPictureService> {
  try {
    const UseCaseApi: IClinicalPictureUseCase = new ClinicalPicture(
      new ClinicalPictureRepositoryImpl(new ClinicalPictureMapper()),
    )

    return {
      status: 1,
      data: await UseCaseApi.invoke(params),
    }
  } catch (e: unknown) {
    return {
      status: 0,
      data: e as string,
    }
  }
}
