import {
  IApiPrescriptionInfo, IRecipePrescription,
  IResponseSavePrescription, IResponseSendPrescription,
  ISavePrescriptionProps, ISendPrescriptionProps
} from '../../infrastructure/dtos/Prescriptions'
import { PrescriptionsRepository } from '../repository/PrescriptionsRepository'

export interface SavePrescriptionUseCase {
  invoke: ( prescription: ISavePrescriptionProps ) => Promise<IResponseSavePrescription>
}

export interface GetAppointmentRecipesUseCase {
  invoke: (appointmentId: string) => Promise<IApiPrescriptionInfo<IRecipePrescription | string>>
}

export interface SendAppointmentRecipeUseCase {
  invoke: (body: ISendPrescriptionProps) => Promise<IResponseSendPrescription>
}

export class SavePrescription implements SavePrescriptionUseCase {
  private objectUseCase: PrescriptionsRepository

  constructor( _objectUseCase: PrescriptionsRepository ) {
    this.objectUseCase = _objectUseCase
  }

  async invoke( prescription: ISavePrescriptionProps ): Promise<IResponseSavePrescription> {
    return this.objectUseCase.savePrescription( prescription )
  }
}

export class SendAppointmentRecipe implements SendAppointmentRecipeUseCase {
  private objectUseCase: PrescriptionsRepository
  
  constructor(_objectUseCase: PrescriptionsRepository) {
    this.objectUseCase = _objectUseCase
  }

  async invoke(body: ISendPrescriptionProps): Promise<IResponseSendPrescription> {
    return this.objectUseCase.sendAppointmentRecipe(body)
  }
}

export class GetAppointmentRecipes implements GetAppointmentRecipesUseCase {
  private objectUseCase: PrescriptionsRepository
  
  constructor(_objectUseCase: PrescriptionsRepository) {
    this.objectUseCase = _objectUseCase
  }

  async invoke(appointmentId: string): Promise<IApiPrescriptionInfo<IRecipePrescription | string>> {
    return this.objectUseCase.getAppointmentRecipes(appointmentId)
  }
}
