import { debounce } from '@mui/material'
import { useEffect, useMemo, useState } from 'react'

import { IPropsAutocompleteGoogleMap } from './AutocompleteGoogle'
import { ICoordinates, IPlaceType } from '../../hooks/useRegisterConsultory'

interface IUseAutocompleteGoogle {
  options: readonly IPlaceType[]
  onChangeAutocomplete: (value: unknown) => void
}

export const useAutocompleteGoogle = ({
  address,
  isLoaded,
  selected,
  setSelected,
  setAddress,
  setMapCenter,
  setMarkerPosition,
  setZoom,
  value,
  setValue,
  id,
  editingValueEdition,
}: IPropsAutocompleteGoogleMap): IUseAutocompleteGoogle => {


  const [options, setOptions] = useState<readonly IPlaceType[]>([])

  const autocompleteService: {
    current: google.maps.places.AutocompleteService | null
  } = {
    current: isLoaded ? new google.maps.places.AutocompleteService() : null
  }
  

  // const [autocompleteService, setAutocompleteService] = useState<{
  //   current: google.maps.places.AutocompleteService | null
  // }>({ current: null })

  const onLoadAutocomplete = (
    request: { input: string },
    callback: (results?: readonly IPlaceType[]) => void,
  ): void => {
    (
      autocompleteService.current as google.maps.places.AutocompleteService
    ).getPlacePredictions(
      {
        ...request,
        componentRestrictions: { country: 'ec' },
      },
      callback as (
        a: google.maps.places.AutocompletePrediction[] | null,
        b: google.maps.places.PlacesServiceStatus,
      ) => void,
    )
  }
  

  const fetch = useMemo(
    () => debounce(onLoadAutocomplete, 400),
    [],
  )

  useEffect(() => {
    let active = true
    if (isLoaded) {
      if (!autocompleteService.current && window.google) {
        autocompleteService.current = new google.maps.places.AutocompleteService()
      }
      if (!autocompleteService.current) {
        return undefined
      }

      if (address === '' || address?.length <= 3) {
        setOptions(value ? [value] : [])
        return undefined
      }

      fetch({ input: address }, (results?: readonly IPlaceType[]) => {
        if (results && results.length > 0 && selected) {
          setValue(results[0])
          setSelected(false)
        }
        if (!results && selected) {
          setValue({
            description: address,
            structured_formatting: {
              main_text: '',
              secondary_text: '',
              main_text_matched_substrings: undefined,
            },
          })
          setSelected(false)
        }

        if (active) {
          let newOptions: readonly IPlaceType[] = []

          if (value) {
            newOptions = [value]
          }

          if (results) {
            newOptions = [...newOptions, ...results]
          }
          setOptions(newOptions)
        }
      })
    }
    return () => {
      active = false
    }

  }, [value, address, fetch])

  const handleSelect = async (place: IPlaceType | null): Promise<void> => {
    try {
      if (!place) {
        setValue(place)

        return
      }
      const geocoder = new google.maps.Geocoder()

      await new Promise<google.maps.GeocoderResult[]>((resolve, reject) => {
        geocoder.geocode(
          { address: place.description },
          function (results, status) {
            if (status === 'OK') {
              if (results && results.length > 0) {
                const lat = results[0].geometry.location.lat()
                const lng = results[0].geometry.location.lng()
                const newCenter: ICoordinates = {
                  lat: lat,
                  lng: lng,
                }

                setMapCenter(newCenter)
                setZoom(20)
                setMarkerPosition(results[0].geometry.location!)
                setAddress(place.description)
              }
            } else {
              reject(
                new Error(`Couldnt't find the location ${place.description}`),
              )
            }
          },
        )
      })
    } catch (error) {}
  }


  const onChangeAutocomplete = (value: unknown): void => {
    const newValue = value as IPlaceType | null
    setOptions(newValue ? [newValue, ...options] : options)
    setValue(newValue)
    handleSelect(newValue)
    if (id) {
      editingValueEdition?.(id, newValue)
    }
  }

  return {
    options,
    onChangeAutocomplete,
  }
}

