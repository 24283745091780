import { useState } from 'react'

interface IUseForm<T> {
  form: T,
  onChange: (value: string, field: keyof T) => void
  onChangeAllState: (value: T) => void
}

export const useForm = <T extends object>( initState: T ): T & IUseForm<T> => {
    
  const [state, setState] = useState<T>( initState );

  const onChange = ( value: string, field: keyof T ): void => {
    setState({
      ...state,
      [field]: value
    });
  }

  const onChangeAllState = ( value: T ): void => {
    setState( value )
  }

  return {
    ...state,
    form: state,
    onChange,
    onChangeAllState,
  }

}