import { RescheduleAppointment } from '../../../domain/useCase/dataReschedulingAppointmentUseCase'
import { IReschedulingAppointment } from '../../../infrastructure/dtos/ReschedulingAppointment'
import { ReschedulingAppointmentRepositoryImpl } from './../../../infrastructure/RepositoryImpl/ReschedulingAppointmentRepositoryImpl'
import ReschedulingAppointmentMapper from '../../../infrastructure/http/mappers/ReschedulingAppointmentMapper/ReschedulingAppointmentMapper'

interface IReschedulingAppointmentService {
  data: string
  status: number
}

export async function ReschedulingAppointmentService(
  dataReschedulingAppointment: IReschedulingAppointment,
): Promise<IReschedulingAppointmentService> {
  try {
    const UseCaseApi: RescheduleAppointment = new RescheduleAppointment(
      new ReschedulingAppointmentRepositoryImpl(
        new ReschedulingAppointmentMapper(),
      ),
    )
    const { statusCode, body } = await UseCaseApi.invoke(
      dataReschedulingAppointment,
    )

    return {
      status: statusCode === 200 ? 1 : 0,
      data: body,
    }
  } catch (error: unknown) {
    return {
      status: 0,
      data: (error as Error).message,
    }
  }
}
