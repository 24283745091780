import { AxiosError, AxiosResponse } from 'axios'

import { IResponseGetCalendarEvent } from '../../../dtos/CalendarInfo'
import CalendarInfoDataSource from '../../dataSource/CalendarInfoDataSource'
import { http } from '../../Http'

export default class CalendarInfoMapper implements CalendarInfoDataSource {
  async getCalendarInfo(
    idDoctor: string,
    idOffice: string,
    duration: number,
    officeType: string,
  ): Promise<IResponseGetCalendarEvent> {
    const data = await http
      .get<IResponseGetCalendarEvent>(
        `/send_data/schedule_availability_month/${idOffice}/${idDoctor}/0/${duration}/${officeType}`,
      )
      .then((response: AxiosResponse) => {
        const { data } = response

        return data
      })
      .catch((error: AxiosError) => {
        const data = {
          statusCode: error.response?.status,
          body: error.response?.statusText,
          headers: {
            'Access-Control-Allow-Headers': '',
            'Access-Control-Allow-Origin': '',
            'Access-Control-Allow-Methods': '',
            'Access-Control-Allow-Credentials': '',
          },
        }

        return data
      })

    return data
  }
}
