import {
  IApiResponseQualification,
  IResponseQualificationList,
  ISendQualificationStatus,
} from '../../infrastructure/dtos/qualification';
import { QualificationRepository } from '../repository/QualificationRepository';

export interface GetQualificationListDoctorUseCase {
  invoke: (
    query: string
  ) => Promise<IApiResponseQualification<IResponseQualificationList>>;
}

export interface PutQualificationStatusUseCase {
  invoke: (
    params: ISendQualificationStatus
  ) => Promise<IApiResponseQualification<string>>;
}

export class GetQualificationListDoctor
implements GetQualificationListDoctorUseCase
{
  private objectUseCase: QualificationRepository;

  constructor( _objectUseCase: QualificationRepository ) {
    this.objectUseCase = _objectUseCase;
  }

  async invoke( query: string ): Promise<IApiResponseQualification<IResponseQualificationList>> {
    return this.objectUseCase.getQualificationListDoctor( query );
  }
}

export class PutQualificationStatus implements PutQualificationStatusUseCase {
  private objectUseCase: QualificationRepository;

  constructor( _objectUseCase: QualificationRepository ) {
    this.objectUseCase = _objectUseCase;
  }

  async invoke( params: ISendQualificationStatus ): Promise<IApiResponseQualification<string>>{
    return this.objectUseCase.putQualificationStatus( params );
  }
}
