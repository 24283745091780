import { styled } from '@mui/material'

import {
  BoxWrapper,
  IconButtonWrapper,
  ModalWrapper,
} from '../../../../../components'

export const ModalRecipe = styled(ModalWrapper)(() => ({
  display: 'grid',
  placeItems: 'center',
  overflowY: 'auto',
  height: 'auto',
}))

export const BoxModal = styled(BoxWrapper)(({ theme }) => ({
  position: 'absolute',
  top: '2%',
  padding: '40px 30px',
  width: '60%',
  maxWidth: '950px',
  backgroundColor: theme.palette.background.paper,
  border: `2px solid ${theme.palette.common.white}`,
  borderRadius: '30px',
  [theme.breakpoints.down('lg')]: {
    width: '80%',
  },
  [theme.breakpoints.down('md')]: {
    width: '95%',
    padding: '30px 20px',
  },
}))

export const CloseButton = styled(IconButtonWrapper)((props) => ({
  position: 'absolute',
  right: 20,
  top: 20,
  color: props.theme.palette.primary.dark,
  marginBottom: '20px',
}))
