import { AxiosError, AxiosResponse } from 'axios'

import { IApiResponseYearsExperience } from '../../../dtos'
import IYearsExperienceDataSource from '../../dataSource/YearsExperienceDataSource'
import { http } from '../../Http'

export default class YearsExperienceMapper
  implements IYearsExperienceDataSource
{
  async getYearsExperience(): Promise<IApiResponseYearsExperience> {
    const data = await http
      .get<IApiResponseYearsExperience>(`/send_data/years_experience`)
      .then((response: AxiosResponse) => {
        const { data } = response

        return data
      })
      .catch((error: AxiosError) => {
        const data = {
          statusCode: error.response?.status,
          body: error.response?.statusText,
          headers: {
            'Access-Control-Allow-Headers': '',
            'Access-Control-Allow-Origin': '',
            'Access-Control-Allow-Methods': '',
          },
        }

        return data
      })

    return data
  }
}
