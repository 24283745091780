import CloseIcon from '@mui/icons-material/Close'
import { Switch } from '@mui/material'

import React, { ChangeEvent } from 'react'

import { useModalDatePicker } from './hook/useModalDatePicker'
import newDateText from './NewDateText.json'
import { ICalendarData } from '../../../../infrastructure/dtos/CalendarInfo'

import { requiredField } from '../../../../utils/requiredField'
import { ModalTitle } from '../../../../views/Dashboard/CalendarForm/Components/RemoveEventCalendar/RemoveEventCalendarStyles'
import { OfficeList } from '../../../../views/Patient/ScheduleAppointment/components/OfficesList/OfficesList'
import AutocompleteComponent from '../../../Autocomplete'
import FormTextInput from '../../../FormInputText'
import { List } from '../../../List/List'
import { SecondLabelInput } from '../../../StyledComponents/SecondLabelInput'
import SubmitButton from '../../../SubmitButton'
import { GridWrapper, MenuItemWrapper } from '../../../wrapper'

import {
  BoxModal,
  CloseButtonModal,
  DateTimeModal,
  GridModalContainerItem,
  GridStyledItem,
  ModalDate,
  TextError,
  SelectTypesList,
} from '../../ModalDateStyles'

import { CustomAppointmentType } from '../CustomAppointmentType/CustomAppointmentType'

import ModalDatePicker from '.'

interface IModalDate {
  handleClose: () => void
  open: boolean
  handleUpdateCalendar: (officeId: string) => void
}

const NewDate = ({
  handleClose,
  open,
  handleUpdateCalendar,
}: IModalDate): JSX.Element => {
  const {
    able,
    availableHours,
    daysAvailability,
    error,
    handleChangeDate,
    handleChangeHoursData,
    handleChangeOffice,
    idTypes,
    isDisabled,
    isFoundPatient,
    message,
    onValidationFocus,
    overbookingHours,
    saveNewDate,
    setIdType,
    switchOverbooking,
    textErrorIdNumber,
    validateComment,
    validateEmailField,
    validateEmailText,
    validateIdType,
    validateLastName,
    validateName,
    validatePhone,
    validatePhoneText,
    idType,
    idNumber,
    setIdNumber,
    validateIdNumber,
    validatorIdDocument,
    name,
    setName,
    lastName,
    setLastName,
    mobile,
    setMobile,
    isAppointmentOverbooking,
    hourFrom,
    appointmentOverbookingTo,
    setAppointmentOverbookingTo,
    appointmentOverbookingFrom,
    errorInOverbookingHours,
    setAppointmentOverbookingFrom,
    idDoctor,
    selectedDate,
    selectedOffice,
    email,
    setEmail,
    comment,
    setComment,
    appointmentType,
    setAppointmentType,
    appointmentTypeList,
    isCustomType,
    customType,
    setCustomType,
    customDuration,
    setCustomDuration,
    loading,
  } = useModalDatePicker(handleUpdateCalendar, handleClose)

  return (
    <ModalDate
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <BoxModal>
        <form data-testid="modal-new-date" onSubmit={saveNewDate}>
          <GridWrapper
            display={'flex'}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              position: 'relative',
            }}
          >
            <GridWrapper>
              <ModalTitle id="transition-modal-title" variant="h6">
                {newDateText.create_new_appointment}
              </ModalTitle>
            </GridWrapper>
            <GridWrapper>
              <CloseButtonModal
                onClick={handleClose}
                data-testid="close-button"
              >
                <CloseIcon />
              </CloseButtonModal>
            </GridWrapper>
          </GridWrapper>
          <GridModalContainerItem container>
            <GridStyledItem item xs={12} sm={6} md={4}>
              <SecondLabelInput id="modal-id-type">
                {newDateText.document_type}
              </SecondLabelInput>
              <List
                id="selectIdType"
                lists={idTypes}
                onClick={(event: string) => setIdType(event)}
                onBlur={() => onValidationFocus(0)}
                value={idType}
                minWidth="100%"
                error={validateIdType}
                textError={requiredField(validateIdType)}
                data-testid="select-id-type" // Add this line
              />
            </GridStyledItem>
            <GridStyledItem item xs={12} sm={6} md={8}>
              <SecondLabelInput id="modal-id-number">
                {newDateText.document_number}
              </SecondLabelInput>
              <FormTextInput
                fullWidth
                type="text"
                id="idNumber"
                name="idNumber"
                value={idNumber}
                onChange={(
                  event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
                ) => setIdNumber(event.target.value)}
                onBlur={() => onValidationFocus(1)}
                error={validateIdNumber || validatorIdDocument.status}
                helperText={textErrorIdNumber()}
                data-testid="id-number" // Add this line
              />
            </GridStyledItem>
          </GridModalContainerItem>

          <GridModalContainerItem container>
            <GridStyledItem item xs={12} md={4}>
              <SecondLabelInput id="modal-name">
                {newDateText.name}
              </SecondLabelInput>
              <FormTextInput
                fullWidth
                type="text"
                id="name"
                name="name"
                value={name}
                onChange={(
                  event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
                ) => setName(event.target.value)}
                onBlur={() => onValidationFocus(2)}
                required
                error={validateName}
                helperText={requiredField(validateName)}
                disabled={isFoundPatient}
              />
            </GridStyledItem>
            <GridStyledItem item xs={12} md={4}>
              <SecondLabelInput id="modal-lastName">
                {newDateText.last_name}
              </SecondLabelInput>
              <FormTextInput
                fullWidth
                type="text"
                id="lastName"
                name="lastName"
                value={lastName}
                onChange={(
                  event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
                ) => setLastName(event.target.value)}
                onBlur={() => onValidationFocus(3)}
                required
                error={validateLastName}
                helperText={requiredField(validateLastName)}
                disabled={isFoundPatient}
              />
            </GridStyledItem>
            <GridStyledItem item xs={12} md={4}>
              <SecondLabelInput id="modal-phone">
                {newDateText.phone}
              </SecondLabelInput>
              <FormTextInput
                fullWidth
                type="tel"
                id="mobile"
                name="mobile"
                value={mobile}
                onChange={(
                  event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
                ) => setMobile(event.target.value)}
                onBlur={() => onValidationFocus(4)}
                required
                error={validatePhone}
                helperText={validatePhoneText()}
                disabled={isFoundPatient}
              />
            </GridStyledItem>
          </GridModalContainerItem>

          <GridModalContainerItem container>
            <GridStyledItem item xs={12} sm={6} md={4}>
              <SecondLabelInput id="modal-email">
                {newDateText.email}
              </SecondLabelInput>
              <FormTextInput
                fullWidth
                id="email"
                name="email"
                type="email"
                value={email}
                onChange={(
                  event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
                ) => setEmail(event.target.value)}
                onBlur={() => onValidationFocus(5)}
                error={validateEmailField}
                helperText={validateEmailText()}
                disabled={isFoundPatient}
              />
            </GridStyledItem>

            <GridStyledItem item xs={12} md={4}>
              <SecondLabelInput id="office-field">
                {newDateText.select_attendance_office}
              </SecondLabelInput>
              <OfficeList
                handleChangeOffice={handleChangeOffice}
                office={selectedOffice}
                idDoctor={idDoctor}
                filterOffices={'ANY'}
              />
            </GridStyledItem>

            <GridStyledItem item xs={12} md={4}>
              <SecondLabelInput id="modal-comment">
                {newDateText.appointment_type_label}
              </SecondLabelInput>
              <SelectTypesList
                disabled={isAppointmentOverbooking}
                value={appointmentType}
                onChange={(event) => {
                  setAppointmentType(event.target.value as string)
                }}
                defaultValue=""
                data-testid={'appointment-type-select'}
                SelectDisplayProps={{
                  role: 'combobox',
                }}
                MenuProps={{
                  PaperProps: {
                    role: 'tab',
                  },
                }}
                placeholder={'Tipo de cita'}
              >
                {appointmentTypeList.map((item: string, index: number) => {
                  return (
                    <MenuItemWrapper key={`specialist${index}`} value={item}>
                      {item}
                    </MenuItemWrapper>
                  )
                })}
              </SelectTypesList>
            </GridStyledItem>
          </GridModalContainerItem>
          {isCustomType && (
            <>
              <SecondLabelInput
                id="modal-date"
                sx={{
                  marginTop: '30px',
                  marginBottom: '0',
                  marginLeft: '10px',
                }}
              >
                {newDateText.custom_appointment_type}
              </SecondLabelInput>
              <CustomAppointmentType
                customType={customType}
                setCustomType={setCustomType}
                duration={customDuration}
                setDuration={setCustomDuration}
              />
            </>
          )}
          <GridModalContainerItem container>
            <GridStyledItem item xs={12} md={4}>
              <SecondLabelInput id="modal-date">
                {newDateText.date}
              </SecondLabelInput>
              <DateTimeModal>
                <ModalDatePicker
                  onChangeDate={handleChangeDate}
                  value={selectedDate}
                  daysAvailability={
                    !isAppointmentOverbooking
                      ? (daysAvailability as ICalendarData)
                      : (null as unknown as ICalendarData)
                  }
                  disabled={able || appointmentType === '' || loading}
                />
              </DateTimeModal>
            </GridStyledItem>
            {isAppointmentOverbooking ? (
              <>
                <GridStyledItem item xs={12} md={2}>
                  <SecondLabelInput
                    sx={{
                      alignSelf: 'start',
                      marginLeft: 0,
                      fontWeight: 'normal',
                    }}
                    id="modal-hour"
                  >
                    {newDateText.overbooking_hour_from}
                  </SecondLabelInput>
                  <AutocompleteComponent
                    id="hour_from_list"
                    label=""
                    list={overbookingHours.map((hour) => hour.label)}
                    value={appointmentOverbookingFrom}
                    setValue={(value: string) => {
                      if (
                        overbookingHours
                          .map((hour) => hour.label)
                          .includes(value) ||
                        value.length < 5
                      ) {
                        setAppointmentOverbookingFrom(value)
                      }
                    }}
                    disabled={false}
                    placeholder={'hh:mm'}
                    datatestid={'hour_from_list'}
                  />
                  <TextError>{errorInOverbookingHours}</TextError>
                </GridStyledItem>
                <GridStyledItem item xs={12} md={2}>
                  <SecondLabelInput
                    sx={{
                      alignSelf: 'start',
                      marginLeft: 0,
                      fontWeight: 'normal',
                    }}
                    id="modal-hour"
                  >
                    {newDateText.overbooking_hour_to}
                  </SecondLabelInput>
                  <AutocompleteComponent
                    id="hour_to_list"
                    label=""
                    list={overbookingHours.map((hour) => hour.label)}
                    value={appointmentOverbookingTo}
                    setValue={(value: string) => {
                      if (
                        overbookingHours
                          .map((hour) => hour.label)
                          .includes(value) ||
                        value.length < 5
                      ) {
                        setAppointmentOverbookingTo(value)
                      }
                    }}
                    placeholder={'hh:mm'}
                    disabled={false}
                    datatestid={'hour_to_list'}
                  />
                </GridStyledItem>
              </>
            ) : (
              <GridStyledItem item xs={12} md={4}>
                <SecondLabelInput
                  sx={{
                    alignSelf: 'start',
                    marginLeft: 0,
                  }}
                  id="modal-hour"
                >
                  {newDateText.hour}
                </SecondLabelInput>
                <List
                  id="selectIdType"
                  lists={availableHours as string[]}
                  onClick={handleChangeHoursData}
                  value={hourFrom?.format('HH:mm') as string}
                  minWidth="100%"
                  error={error}
                  textError={message}
                  disabled={loading}
                />
              </GridStyledItem>
            )}
            <GridStyledItem item xs={12} md={4}>
              <SecondLabelInput id="modal-comment">
                {newDateText.appointment_reason}
              </SecondLabelInput>
              <FormTextInput
                id="comment"
                type="text"
                fullWidth
                name="comment"
                required
                value={comment}
                onChange={(
                  event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
                ) => setComment(event.target.value)}
                onBlur={() => onValidationFocus(6)}
                error={validateComment}
                helperText={requiredField(validateComment)}
              />
            </GridStyledItem>
          </GridModalContainerItem>
          {selectedOffice && (
            <GridModalContainerItem>
              <GridStyledItem
                item
                xs={12}
                md={4}
                sx={{ display: 'flex', flexDirection: 'row' }}
              >
                <Switch
                  checked={isAppointmentOverbooking}
                  onChange={switchOverbooking}
                />{' '}
                <p>{newDateText.appointment_overbooking_label}</p>
              </GridStyledItem>
            </GridModalContainerItem>
          )}
          <GridModalContainerItem
            sx={{
              width: '200px',
              margin: '40px auto 0 auto',
            }}
          >
            <SubmitButton
              data-testid="create-button"
              id="button"
              type="submit"
              variant="contained"
              fullWidth
              text={newDateText.create}
              disabled={isDisabled}
              disableElevation
            />
          </GridModalContainerItem>
        </form>
      </BoxModal>
    </ModalDate>
  )
}

export default NewDate
