import { Close, VerticalAlignBottom } from '@mui/icons-material'
import CloseIcon from '@mui/icons-material/Close'
import { Backdrop } from '@mui/material'

import { useEffect, useState } from 'react'

import { usePatientRecipesModal } from './hook'
import {
  BoxPatientRecipeModal,
  CloseButton,
  FadePatientRecipeModal,
  TypographyModal,
  TypographyTitleModal,
} from './styles'
import {
  ButtonWrapper,
  GridWrapper,
  ModalWrapper,
} from '../../../../../../../components'
import { GridItem } from '../../../../../../../components/GridItem/GridItem'
import { GridModal } from '../../../../../../../components/ModalComponent'
import SubmitButton from '../../../../../../../components/SubmitButton'
import CircularProgressWrapper from '../../../../../../../components/wrapper/LoaderWrapper'
import { useDoctorInformationStore } from '../../../../../../../contexts/DoctorInformationState'
import { IDocument } from '../../../../../../../infrastructure/dtos/Appointments'
import { IAppointment } from '../../../../../../../infrastructure/dtos/AttendanceSelectedPatient'
import { IDoctorPrescription } from '../../../../../../../infrastructure/dtos/DoctorInfo'
import { Summary } from '../../../../../components/modals/RecipeModal/steps/Summary'
import { useSummary } from '../../../../../components/modals/RecipeModal/steps/Summary/useSummary'
import { BoxModal } from '../../../../../components/modals/RecipeModal/styles'
import { IPrescription } from '../../../../../components/modals/RecipeModal/types'

interface IPatientRecipesModalProps {
  handleChangeOpenPatientRecipesModal: (value: boolean) => void
  open: boolean
  appointmentSelected: IAppointment
}

export function PatientRecipesModal({
  handleChangeOpenPatientRecipesModal,
  open,
  appointmentSelected,
}: IPatientRecipesModalProps): JSX.Element {
  const [showModalPrescription, setShowModalPrescription] =
    useState<boolean>(false)
  const [documentSelected, setDocumentSelected] = useState<string>('')
  const {
    appointmentDetails,
    loadingAppointmentDetails,
    fetchAppointmentDetailsUtility,
    fetchPrescriptionUtility,
    appointmentRecipes,
    getOnlyPrescription,
    loadingAppointmentRecipes,
  } = usePatientRecipesModal()

  const [prescription, setPrescription] = useState<IPrescription>(
    {} as IPrescription,
  )
  const [doctorInfo, setDoctorInfo] = useState<IDoctorPrescription>(
    {} as IDoctorPrescription,
  )
  const [isDownload, setIsDownload] = useState<boolean>(false)
  const { doctorInformation } = useDoctorInformationStore()

  const { handleDownload } = useSummary({
    prescription,
    doctorAppointmentInfo: doctorInfo,
  })

  const downloadPdf = (document: IDocument): void => {
    buildData(document, true)
    setIsDownload(true)
  }

  const buildData = (document: IDocument, isDownload: boolean): void => {
    setPrescription({
      recipe: getOnlyPrescription(document.id)?.prescription_items.map(
        (item) => {
          return {
            medicine: item.medicament,
            quantity: Number(item.quantity),
            dose: item.dosage,
            presentation: item.presentation,
            prescription: item.prescription,
          }
        },
      ),
      remarks: getOnlyPrescription(document.id)?.remarks,
      prescription_file: 'str',
      diagnostic: appointmentDetails.patient_diagnostic,
      created_at: getOnlyPrescription(document.id)?.created_at,
    })
    setDoctorInfo({
      prescription: {
        prescription_no: Number(
          getOnlyPrescription(document.id)?.prescription_no,
        ),
      },
      patient: {
        name: appointmentRecipes?.patient?.name,
        last_name: appointmentRecipes.patient?.last_name,
      },
      doctor: {
        email: appointmentRecipes.doctor?.email_doctor,
        last_name: appointmentRecipes.doctor?.last_name_doctor,
        name: appointmentRecipes.doctor?.name_doctor,
        phone: appointmentRecipes.doctor?.doctor_phone,
        msp_code: appointmentRecipes.doctor?.code_msp,
        specialties: appointmentRecipes.doctor?.speciality_doctor,
      },
      patient_diagnostic: appointmentRecipes?.diagnostics?.map((item) => {
        return {
          code: item.cie_code,
          id_cie10: item.objectID,
          description: item.diagnostic,
        }
      }),
      appointment: {
        date: appointmentRecipes.appointment_date,
      },
      office_medical: {
        name_medical_office: appointmentRecipes.office.name_medical_office,
        office_phone: appointmentRecipes.office.office_phone,
        reference: appointmentRecipes.office.reference_medical_office,
      },
    })
    if (isDownload) {
      handleDownload()
    } else {
      setDocumentSelected(document.id)
      setShowModalPrescription(true)
    }
  }

  useEffect(() => {
    if (appointmentSelected && !isDownload && !showModalPrescription) {
      fetchAppointmentDetailsUtility(appointmentSelected.appointment_id)
      fetchPrescriptionUtility(appointmentSelected.appointment_id)
    }
    if (isDownload) {
      handleDownload()
      setIsDownload(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appointmentSelected, prescription])
  return (
    <ModalWrapper
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      data-testid="modal-appointment-details"
      open={open}
      onClose={() => handleChangeOpenPatientRecipesModal(false)}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
      sx={{
        display: 'grid',
        placeItems: 'center',
        overflowY: 'auto',
        height: 'auto',
      }}
    >
      <FadePatientRecipeModal in={open}>
        {showModalPrescription ? (
          <BoxModal>
            {' '}
            <CloseButton
              onClick={() => {
                setShowModalPrescription(false)
              }}
              data-testid="close-recipe-modal"
            >
              <CloseIcon />
            </CloseButton>
            <Summary
              prescription={prescription}
              doctorAppointmentInfo={doctorInfo}
              isView
              resendData={{
                appointment_id: appointmentSelected?.appointment_id,
                prescription_id: documentSelected,
                user_id: doctorInformation?.user_id as string,
              }}
              setShowModal={setShowModalPrescription}
            />{' '}
          </BoxModal>
        ) : (
          <BoxPatientRecipeModal>
            <GridModal>
              {!loadingAppointmentDetails && !loadingAppointmentRecipes ? (
                <GridWrapper>
                  <CloseButton
                    onClick={() => handleChangeOpenPatientRecipesModal(false)}
                    data-testid="close-button"
                  >
                    <Close />
                  </CloseButton>
                  <TypographyModal id="transition-modal-title" variant="h6">
                    Detalles cita {appointmentSelected.appointment_date}
                  </TypographyModal>
                  <GridWrapper container marginTop={5} columns={8} spacing={4}>
                    <GridWrapper item xs={4}>
                      <TypographyTitleModal>
                        Cuadro clínico
                      </TypographyTitleModal>
                      <GridWrapper
                        paddingX={2}
                        paddingY={1}
                        marginY={1}
                        borderRadius={8}
                        minHeight={90}
                        sx={{ backgroundColor: '#F4F4FC' }}
                      >
                        {appointmentDetails?.clinical_picture}
                      </GridWrapper>
                    </GridWrapper>
                    <GridWrapper item xs={4}>
                      <TypographyTitleModal>Diagnóstico</TypographyTitleModal>
                      <GridWrapper
                        paddingX={2}
                        paddingY={1}
                        marginY={1}
                        borderRadius={8}
                        minHeight={90}
                        sx={{ backgroundColor: '#F4F4FC' }}
                      >
                        {appointmentDetails?.patient_diagnostic.map(
                          (diagnostic) => {
                            return (
                              <GridWrapper
                                key={`diagnostic-${diagnostic.code}`}
                              >
                                {diagnostic.code} {diagnostic.description}
                              </GridWrapper>
                            )
                          },
                        )}
                      </GridWrapper>
                    </GridWrapper>

                    {appointmentDetails?.documents?.length > 0 && (
                      <TypographyTitleModal marginTop={5}>
                        Documentos
                      </TypographyTitleModal>
                    )}

                    {appointmentDetails?.documents?.map((document) => (
                      <GridWrapper
                        container
                        marginTop={2}
                        display={'flex'}
                        justifyContent={'space-between'}
                        key={`document-${document.id}`}
                        data-testid={`prescription-list`}
                      >
                        <GridItem
                          xs={6}
                          width={100}
                          height={'auto'}
                          paddingX={1}
                          paddingY={1}
                          marginX={1}
                          borderRadius={8}
                          sx={{ backgroundColor: '#F4F4FC' }}
                        >
                          {document.name}
                        </GridItem>
                        <GridItem xs={3}>
                          <SubmitButton
                            fullWidth={false}
                            id="document-details"
                            data-testid="document-details"
                            text="Ver"
                            type="button"
                            variant="contained"
                            onClick={() => buildData(document, false)}
                            sx={{
                              width: '130px',
                            }}
                          />
                        </GridItem>
                        <GridItem xs={2}>
                          <ButtonWrapper
                            variant="text"
                            startIcon={<VerticalAlignBottom />}
                            disableRipple
                            sx={{
                              color: 'primary.dark',
                              textTransform: 'initial',
                              ':hover': {
                                textDecoration: 'underline',
                              },
                            }}
                            onClick={() => downloadPdf(document)}
                          >
                            Descargar
                          </ButtonWrapper>
                        </GridItem>
                      </GridWrapper>
                    ))}
                  </GridWrapper>
                </GridWrapper>
              ) : (
                <GridWrapper display={'flex'} justifyContent={'center'}>
                  <CircularProgressWrapper />
                </GridWrapper>
              )}
            </GridModal>
          </BoxPatientRecipeModal>
        )}
      </FadePatientRecipeModal>
    </ModalWrapper>
  )
}
