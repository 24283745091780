import React, { Dispatch, useEffect } from 'react'

import { Schedule } from './components/Schedule/Schedule'
import SheduleCM from './components/ScheduleCM/SheduleCM'
import { IDataOffice } from './hooks/useUpdateSchedule'
import { GridWrapper } from '../../../../../components'
import FormTextInput from '../../../../../components/FormInputText'
import { ListOfficeSelect } from '../../../../../components/ListOfficeSelect/ListOfficeSelect'
import SelectButtonList from '../../../../../components/SelectButtonList/SelectButtonList'
import { SecondLabelInput } from '../../../../../components/StyledComponents/SecondLabelInput'
import { IDaysMedicalCare } from '../../../../../contexts/RegisterContext'
import { useSelectOffice } from '../../../../../contexts/SelectOfficeState'
import { IScheduleHours } from '../../../../../infrastructure/dtos/Offices'
import { validateOfficePhoneNumber } from '../../../../../utils/validatePhoneNumber'
import { AppointmentTypesList } from '../../../../medical/ProfileMedical/Components/AppointmentTypesList'
import { Duration } from '../../../../medical/ProfileMedical/Components/hooks/useAppointmentTypesList'
import {
  AppointmentType
} from '../../../../medical/ProfileMedical/ProfileMedicalScheduleSetting/Hooks/useProfileMedicalScheduleSetting'

export interface IUpdateScheduleProps {
  dataOffice: string[]
  dataOfficeObject: IDataOffice[]
  onchangeOfficeMedical(value: string, id: string): void
  office: IDataOffice
  phoneNumber: string
  setPhoneNumber: Dispatch<React.SetStateAction<string>>
  visibilityIntervals: string[]
  appointmentTimes: string[]
  days: { [key: string]: IDaysMedicalCare }
  selectedShowAgenda: string
  selectedAppointmentDuration: string
  setSelectedShowAgenda: Dispatch<React.SetStateAction<string>>
  setSelectedAppointmentDuration: Dispatch<React.SetStateAction<string>>
  setDays: React.Dispatch<React.SetStateAction<{
    [key: string]: IDaysMedicalCare;
  }>>
  typesList: AppointmentType[]
  setTypesList: Dispatch<React.SetStateAction<AppointmentType[]>>
  durationsDB: Duration[]
  typesDB: string[]
}

export function UpdateSchedule({
  dataOffice,
  dataOfficeObject,
  days,
  office,
  onchangeOfficeMedical,
  phoneNumber,
  selectedShowAgenda,
  setPhoneNumber,
  setSelectedShowAgenda,
  visibilityIntervals,
  setDays,
  typesList,
  setTypesList,
  durationsDB,
  typesDB
}: IUpdateScheduleProps): React.JSX.Element {

  const { officeSelected } = useSelectOffice()

  const officeStore = officeSelected.filter((office) => office.selected)[0]

  const index = officeSelected.findIndex((off) => office.id === off.office_id)

  useEffect(() => {
    onchangeOfficeMedical(
      officeStore?.reference_medical_office,
      officeStore?.office_id,
    )
  }, [officeStore])

  return (
    <GridWrapper>
      <GridWrapper container padding={4}>
        <GridWrapper item sm={12} md={4}>
          <GridWrapper container padding={3}>
            <GridWrapper item xs={12}>
              <SecondLabelInput sx={{ ml: 0 }}>
                Selecciona el consultorio para empezar a configurar tu
                disponibilidad
              </SecondLabelInput>
              <ListOfficeSelect
                id="selectOffice"
                lists={dataOffice}
                onClick={(event: string, key) => {
                  const id = key.charAt(key.length - 1)
                  onchangeOfficeMedical(
                    event,
                    dataOfficeObject[Number(id)].id,
                  )
                }}
                value={index + office.reference}
                minWidth="100%"
                width="100%"
              />
            </GridWrapper>
            <GridWrapper item xs={12} mt={5}>
              <SecondLabelInput sx={{ ml: 0 }}>
                Número de teléfono de este consultorio
              </SecondLabelInput>
              <FormTextInput
                placeholder="ingresa el número de teléfono"
                id="office_phone"
                name="office_phone"
                type="text"
                fullWidth
                value={phoneNumber}
                onChange={(event) => setPhoneNumber(event.target.value)}
                error={
                  !validateOfficePhoneNumber(phoneNumber) &&
                  phoneNumber?.length > 0
                }
                helperText={
                  !validateOfficePhoneNumber(phoneNumber) &&
                    phoneNumber?.length > 0 ?
                    'Por favor, ingresa un número teléfono válido' :
                    ''
                }
              />
            </GridWrapper>
          </GridWrapper>
        </GridWrapper>
        <GridWrapper item sm={12} md={8}>
          <GridWrapper container padding={3}>
            <GridWrapper item xs={12}>
              <SecondLabelInput sx={{ ml: 0 }}>
                ¿Por cuánto tiempo quieres mostrar tu agenda a los pacientes?
              </SecondLabelInput>
              <SelectButtonList
                style={{ width: '100%' }}
                amountRanges={visibilityIntervals}
                onAmountRangeChange={(value: string) =>
                  setSelectedShowAgenda(value)
                }
                selectedAmountRange={selectedShowAgenda}
                disabled={officeSelected[index]?.type_office === 'DOCTOR_CM'}
              />
            </GridWrapper>
            <GridWrapper item xs={12} mt={5}>
              <SecondLabelInput sx={{ ml: 0 }}>
                {`Crea tipo de citas y el tiempo de duración de cada una en ${office?.reference}`}
              </SecondLabelInput>
              <AppointmentTypesList
                typesList={typesList}
                setTypesList={setTypesList}
                durationsDB={durationsDB}
                typesDB={typesDB}
                disableByCM={officeSelected[index]?.type_office === 'DOCTOR_CM'}
              />
            </GridWrapper>
            <GridWrapper item xs={12} mt={5}>
              {officeSelected[index]?.schedule_hours?.length ?
                <SheduleCM
                  office={office}
                  officeSelected={officeSelected[index].schedule_hours as IScheduleHours[]}
                /> :
                <Schedule
                  days={days}
                  office={office}
                  setDays={setDays}
                />

              }
            </GridWrapper>
          </GridWrapper>
        </GridWrapper>
      </GridWrapper>
    </GridWrapper>
  )
}
