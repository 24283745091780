import LocationOnIcon from '@mui/icons-material/LocationOn'
import parse from 'autosuggest-highlight/parse'

import {
  AutocompleteStyled,
  BoxResults,
  GridGeneral,
  GridItem,
  GridrespuestaItem,
  TextFielRegister,
  TypographyName,
} from './AutocompleteGoogleStyle'
import { useAutocompleteGoogle } from './useAutocompleteGoogle'
import { ICoordinates } from '../../hooks/useRegisterConsultory'

interface IMainTextMatchedSubstrings {
  offset: number
  length: number
}
interface IStructuredFormatting {
  main_text: string
  secondary_text: string
  main_text_matched_substrings?: readonly IMainTextMatchedSubstrings[]
}
interface IPlaceType {
  description: string
  structured_formatting: IStructuredFormatting
}
export interface IPropsAutocompleteGoogleMap {
  address: string
  setMarkerPosition: React.Dispatch<
    React.SetStateAction<google.maps.LatLng | null>
  >
  setZoom: React.Dispatch<React.SetStateAction<number>>
  setAddress: React.Dispatch<React.SetStateAction<string>>
  setMapCenter: React.Dispatch<React.SetStateAction<ICoordinates>>
  isLoaded: boolean
  setSelected: React.Dispatch<React.SetStateAction<boolean>>
  selected: boolean
  isDisabled?: boolean
  value: IPlaceType | null
  setValue: React.Dispatch<React.SetStateAction<IPlaceType | null>>
  id?: string
  editingValueEdition?: (id: string, valor: IPlaceType | null) => void
  onClick?: () => void
}
const AutocompleteGoogleMaps = (props: IPropsAutocompleteGoogleMap): JSX.Element => {
  const {
    options,
    onChangeAutocomplete
  } = useAutocompleteGoogle(props)
  const {
    isLoaded,
    setAddress,
    isDisabled = false,
    value,
    onClick,
    address
  } = props

  const getOptionLabel = (option: unknown): string =>
    typeof option === 'string' ? option : (option as IPlaceType)?.description

  return isLoaded ? (
    <>
      <AutocompleteStyled
        data-testid="google-map-autocomplete"
        id="google-map-autocomplete"
        getOptionLabel={getOptionLabel}
        options={options}
        autoComplete
        includeInputInList
        filterSelectedOptions
        filterOptions={(x) => x}
        value={value}
        noOptionsText="Sin resultados"
        onChange={(event, value: unknown) => onChangeAutocomplete(value)}
        onInputChange={(event, newInputValue) => {
          setAddress(newInputValue)
        }}
        onFocus={onClick}
        disabled={isDisabled}
        renderInput={(params) => (
          <TextFielRegister
            {...params}
            sx={{ height: '55px' }}
            label="Calle principal y secundaria"
            variant="outlined"
            fullWidth
            InputProps={{
              ...params.InputProps,
              endAdornment: <p />,
            }}
            error={address?.length === 0}
          />
        )}
        renderOption={(props, option) => {
          const matches =
            (option as IPlaceType).structured_formatting
              .main_text_matched_substrings || []

          const parts = parse(
            (option as IPlaceType).structured_formatting.main_text,
            matches.map((match: IMainTextMatchedSubstrings) => [
              match.offset,
              match.offset + (match?.length || 0),
            ]),
          )

          return (
            <li
              data-testid={props.id}
              { ...props }
            >
              <GridGeneral container data-testid="google-map-data-container">
                <GridItem item>
                  <LocationOnIcon sx={{ color: 'text.secondary' }} />
                </GridItem>
                <GridrespuestaItem item>
                  {parts.map(
                    (
                      part: { text: string; highlight: boolean },
                      index: number,
                    ) => (
                      <BoxResults
                        key={`part-${index}`}
                        text={part.text}
                        highlight={String(part.highlight)}
                      >
                        {part.text}
                      </BoxResults>
                    ),
                  )}
                  <TypographyName variant="body2" color="text.secondary">
                    {
                      (option as IPlaceType)?.structured_formatting
                        .secondary_text
                    }
                  </TypographyName>
                </GridrespuestaItem>
              </GridGeneral>
            </li>
          )
        }}
      />
    </>
  ) : (
    <div>Cargando Autocomplete...</div>
  )
}
export default AutocompleteGoogleMaps
