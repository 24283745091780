import { AxiosError, AxiosResponse } from 'axios'

import { IApiResponseSpecialties } from '../../../dtos'
import SpecialtiesDataSource from '../../dataSource/SpecialtiesDataSource'
import { http } from '../../Http'

export default class SpecialtiesMapper implements SpecialtiesDataSource {
  async postSpecialties(specialties: string): Promise<IApiResponseSpecialties> {
    const data = await http
      .post<IApiResponseSpecialties>(`/save_data/specialties `, {
        specialties: specialties,
      })
      .then((response: AxiosResponse) => {
        const { data } = response

        return data
      })
      .catch((error: AxiosError) => {
        const data = {
          statusCode: error.response?.status,
          body: error.response?.statusText,
          headers: {
            'Access-Control-Allow-Headers': '',
            'Access-Control-Allow-Origin': '',
            'Access-Control-Allow-Methods': '',
          },
        }

        return data
      })

    return data
  }

  async getSpecialties(): Promise<IApiResponseSpecialties> {
    const data = await http
      .get<IApiResponseSpecialties>(`/send_data/specialties`)
      .then((response: AxiosResponse) => {
        const { data } = response

        return data
      })
      .catch((error: AxiosError) => {
        const data = {
          statusCode: error.response?.status,
          body: error.response?.statusText,
          headers: {
            'Access-Control-Allow-Headers': '',
            'Access-Control-Allow-Origin': '',
            'Access-Control-Allow-Methods': '',
          },
        }

        return data
      })

    return data
  }
}
