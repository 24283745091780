import {
  IBodyGenders,
  IGendersResponse,
} from '../../../infrastructure/dtos/Genders'
import { GetGendersService, IServiceGenders } from '../Persistencia/GendersService'

export interface IGendersUtility {
  status: number
  data: IBodyGenders | string
}

export async function GendersUtility(): Promise<IGendersUtility> {
  try {
    const { data, status } = await GetGendersService()

    if (status !== 1 || (data as IGendersResponse).statusCode !== 200) {
      return {
        data: ((data as IGendersResponse).body as IBodyGenders)
          .message as string,
        status: 0,
      }
    }

    return {
      data: (data as IGendersResponse).body,
      status: status,
    }
  } catch (error: unknown) {
    return {
      // data: 'Ups! Tengo inconvenientes en este momento, por favor intenta más tarde.',
      data: ((error as IServiceGenders).data as IGendersResponse).body as string,
      status: 0,
    }
  }
}
