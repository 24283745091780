import { FunctionComponent, ReactNode } from 'react'

import { StackWrapper } from '../wrapper'

interface IGridFullScreenProps {
  children: ReactNode
  id: string
}

export const GridFullScreen: FunctionComponent<IGridFullScreenProps> = ({
  children,
  id,
}) => {
  return (
    <StackWrapper
      spacing={2}
      id={id}
      width={'100%'}
      height={'auto'}
      display={'flex'}
      justifyContent={'center'}
      mt={'2%'}
    >
      {children}
    </StackWrapper>
  )
}
