import React from 'react'

import { Check } from '../../../../../../../components/Check/Check'
import { GridItem } from '../../../../../../../components/GridItem/GridItem'
import { List } from '../../../../../../../components/List/List'
import { LabelInput } from '../../../../../../../components/StyledComponents/LabelInput'
import { SecondLabelInput } from '../../../../../../../components/StyledComponents/SecondLabelInput'
import { ButtonWrapper, GridWrapper } from '../../../../../../../components/wrapper'
import { IScheduleHours } from '../../../../../../../infrastructure/dtos/Offices'
import { IDataOffice } from '../../hooks/useUpdateSchedule'
import HourInput from '../HourInput/HourInput'

interface IScheduleProps {
  office: IDataOffice
  officeSelected: IScheduleHours[]
}

const SheduleCM = ({ office, officeSelected }: IScheduleProps): JSX.Element => {
  return (
    <>
      <SecondLabelInput sx={{ ml: 0 }}>
        {`¿Qué días y en qué horario quieres atender en ${office?.reference}?`}
      </SecondLabelInput>
      <GridWrapper container>
        <GridWrapper item xs={12} marginY={2}>
          <GridWrapper
            container
            display={'flex'}
          >
            <GridItem item xs={6} justifyContent={'flex-start'}>
              <Check
                label='Seleccionar todos'
                disabled
              />
            </GridItem>
            <GridWrapper
              item
              xs={4}
              display={'flex'}
              justifyContent={'center'}
              mx={1}
            >
              <ButtonWrapper
                variant='outlined'
                sx={{
                  textTransform: 'none',
                  borderRadius: '30px'
                }}
                disabled
              >
                Eliminar selección
              </ButtonWrapper>
            </GridWrapper>
            <GridWrapper
              item
              xs={2}
              display={'flex'}
              justifyContent={'center'}
              mx={1}
            />
          </GridWrapper>
        </GridWrapper>
        <GridWrapper item xs={12} marginTop={2}>
          <GridWrapper
            container
            display={'flex'}
            justifyContent={'space-between'}
          >
            <GridWrapper item xs={6} />
            <GridWrapper
              item
              xs={2}
              display={'flex'}
              justifyContent={'center'}
              mx={1}
            >
              <LabelInput sx={{ ml: 0, fontWeight: 'normal' }}>
                Desde
              </LabelInput>
            </GridWrapper>
            <GridWrapper
              item
              xs={2}
              display={'flex'}
              justifyContent={'center'}
              mx={1}
            >
              <LabelInput sx={{ ml: 0, fontWeight: 'normal' }}>
                Hasta
              </LabelInput>
            </GridWrapper>
          </GridWrapper>
        </GridWrapper>
        {officeSelected.map((item: IScheduleHours, idx) => (
          <GridWrapper item xs={12} key={idx} marginY={2} marginTop={idx === 0 ? 0 : 2}>
            <GridWrapper
              container
              display={'flex'}
              justifyContent={'space-between'}
            >
              <GridItem item xs={1}>
                <Check
                  data-testid={`check`}
                  disabled
                />
              </GridItem>
              <GridWrapper item xs={5}>
                <List
                  id={`day`}
                  lists={[item.day]}
                  value={item.day}
                  disabled
                  minWidth="100%"
                  onClick={() => { }}
                />
              </GridWrapper>
              <GridWrapper
                item
                xs={2}
                display={'flex'}
                justifyContent={'center'}
                mx={1}
              >
                <HourInput
                  hour={item.hour_open}
                  day={item.day}
                  disabled
                  hours={[item.hour_open]}
                  type
                  setValue={() => ''}
                />
              </GridWrapper>
              <GridWrapper
                item
                xs={2}
                display={'flex'}
                justifyContent={'center'}
                mx={1}
              >
                <HourInput
                  hour={item.hour_close}
                  day={item.day}
                  disabled
                  hours={[item.hour_close]}
                  type
                  setValue={() => ''}
                />
              </GridWrapper>
            </GridWrapper>
          </GridWrapper>
        ))}
      </GridWrapper>
    </>
  )
}

export default SheduleCM