import { DateSelectArg } from '@fullcalendar/core'
import CircleIcon from '@mui/icons-material/Circle'
import CloseIcon from '@mui/icons-material/Close'
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked'
import { Checkbox } from '@mui/material'
import Backdrop from '@mui/material/Backdrop'
import {
  DateValidationError,
  TimeField,
  TimeValidationError,
} from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import dayjs from 'dayjs'

import React, { useContext, useEffect, useState } from 'react'

import {
  ICalendarEvent,
  IHours,
} from '../../../../../components/Calendar/types'
import { CloseButtonModal } from '../../../../../components/ModalDate/ModalDateStyles'
import DatePickerWrapper from '../../../../../components/wrapper/DatePickerWrapper'
import {
  AccountContext,
  AuthContextType,
} from '../../../../../contexts/AccountContext'
import { useDoctorInformationStore } from '../../../../../contexts/DoctorInformationState'
import { Appointment, IResponseAppointment } from '../../../../../infrastructure/dtos/Appointments'
import {
  BodyAvailableHours,
  IAvailableHours,
  IResponseAvailableHours,
} from '../../../../../infrastructure/dtos/Calendar'
import {
  ICalendarData,
  IResponseGetCalendarEvent,
} from '../../../../../infrastructure/dtos/CalendarInfo'
import { IMedicalOffice } from '../../../../../infrastructure/dtos/Offices'
import { AppointmentService } from '../../../../../services/Contracts/Persistencia/AppointmentService'
import {
  PostAddCalendarEvent,
  getAvailableHoursInADay,
} from '../../../../../services/Contracts/Persistencia/Calendar'
import { GetDaysAvailabilityInAMonth } from '../../../../../services/Contracts/Persistencia/CalendarInfoService'

import { sleep } from '../../../../../utils/Sleep'
import { greatherThan, isEqual, isValidDate } from '../../event-utils'
import {
  BoxModal,
  BoxTimeModal,
  ButtonRangoModal,
  ButtonSubmitModal,
  FadeModal,
  GridModal,
  GridModalContainerItem,
  GridModalItem,
  GridModalItemRadio,
  GridModalItemTexto,
  ModalComponent,
  TextFielNameModal,
  TypographyModal,
  TypographyTittle,
} from '../../Styles/HoursOfNonAttendanceModalStyles'

interface IProps {
  handleClose: () => void
  handleSaveSelectedDate: (data: ICalendarEvent) => void
  open: boolean
  selectedDate: DateSelectArg | null
  selectedOffice: IMedicalOffice | undefined
}

type ErrorType = {
  name: { error: boolean }
  date_from: { error: boolean; message: string }
  date_to: { error: boolean; message: string }
  rango: ErrorHourType[]
}

type ErrorHourType = {
  [key: string]: { error: boolean; message: string }
}

type formattedHours = { from: dayjs.Dayjs | null; to: dayjs.Dayjs | null }

const DEFAULT_DURATION = 15

export default function HoursOfNonAttendanceModal({
  handleClose,
  open,
  selectedDate,
  handleSaveSelectedDate,
  selectedOffice,
}: IProps): React.JSX.Element {
  const { doctorInformation } = useDoctorInformationStore()

  const { handleAlert } = useContext(AccountContext) as AuthContextType
  const [disabledButton, setDisabledButton] = useState<boolean>(false)
  const [daysAvailability, setDaysAvailability] = useState<ICalendarData>({
    '': false,
  })
  const [unavailableHours, setUnavailableHours] = useState<formattedHours[]>([])
  const [errors, setErrors] = useState<ErrorType>({
    name: { error: true },
    date_from: { error: false, message: '' },
    date_to: { error: false, message: '' },
    rango: [
      {
        hour_from: { error: false, message: '' },
        hour_to: { error: false, message: '' },
      },
    ],
  })
  const [state, setState] = useState<ICalendarEvent>({
    id: '',
    name: '',
    date_from: '',
    date_to: '',
    all_day: false,
    rango: [
      {
        hour_from: '',
        hour_to: '',
      },
    ],
    office_id: (selectedOffice as IMedicalOffice)?.office_id as string,
  })

  const fetchDaysAvailabilityInAMonth = async (): Promise<
    ICalendarData | undefined
  > => {
    try {
      const { data } = await GetDaysAvailabilityInAMonth(
        doctorInformation?.user_id as string,
        state.office_id,
        DEFAULT_DURATION,
        'OFFICE'
      )

      return (data as IResponseGetCalendarEvent).body
    } catch (error: unknown) {
      handleAlert(true, 'Error al obtener los días disponibles', 'error')
    }
  }

  const fetchAvailableHoursInADay = async (): Promise<void> => {
    try {
      const data: IResponseAvailableHours = await getAvailableHoursInADay({
        user_id: doctorInformation?.user_id as string,
        date_search: state.date_from as string,
        office_id: state.office_id,
        duration: DEFAULT_DURATION,
        officeType: 'OFFICE'
      })

      getUnavailableRangeHours((data?.body as BodyAvailableHours).data)
    } catch (error: unknown) {
      handleAlert(true, 'Error al obtener las horas disponibles', 'error')
    }
  }

  const getUnavailableRangeHours = (availableHours: IAvailableHours): void => {
    const allHours: string[] = availableHours?.intervals_hours
    const hours: string[] = availableHours?.intervals_appointment
    const unavailableHoursRange: formattedHours[] = [
      {
        from: null,
        to: null,
      },
    ]

    for (let i = 0; i < allHours?.length; i++) {
      if (hours.includes(allHours[i])) {
        if (
          unavailableHoursRange[
            unavailableHoursRange.length - 1
          ].from?.isValid() &&
          !unavailableHoursRange[unavailableHoursRange.length - 1].to?.isValid()
        ) {
          unavailableHoursRange[unavailableHoursRange.length - 1].to = dayjs(
            `${state.date_from}T${allHours[i]}`,
          )
        }
        continue
      }
      if (
        !unavailableHoursRange[unavailableHoursRange.length - 1].from?.isValid()
      ) {
        unavailableHoursRange[unavailableHoursRange.length - 1].from = dayjs(
          `${state.date_from}T${allHours[i]}`,
        )
      } else if (
        unavailableHoursRange[
          unavailableHoursRange.length - 1
        ].from?.isValid() &&
        unavailableHoursRange[unavailableHoursRange.length - 1].to?.isValid() &&
        i < allHours.length - 1
      ) {
        unavailableHoursRange.push({
          from: dayjs(`${state.date_from}T${allHours[i]}`),
          to: null,
        })
      }
    }
    setUnavailableHours(unavailableHoursRange)
  }

  const doesRangeOverlap = async (range: IHours, index: number): Promise<boolean> => {
    const dateFrom = dayjs(state.date_from as string)
    const dateTo = dayjs(state.date_to as string)

    if (!dateFrom.isValid() || !dateTo.isValid()) {
      return false
    }

    const { data } = await AppointmentService({
      date_from: dateFrom.format('YYYY-MM-DD'),
      date_to: dateTo.format('YYYY-MM-DD'),
      officeId: selectedOffice?.office_id as string,
    })

    const appointments = (data.body as unknown as IResponseAppointment)?.data
      ?.current_appointments ?? []

    for (
      let date = dateFrom;
      date.isBefore(dateTo.add(1, 'day'));
      date = date.add(1, 'day')
    ) {
      // Agrega la fecha formateada al arreglo
      const dateFormatted = date.format('YYYY-MM-DD')
      // Filtra las citas que coincidan con la fecha
      const appointmentsByDate = appointments.filter((appointment) =>
        appointment.date.split(' ')[1]
          ? appointment.date.split(' ')[1]
          : appointment.date.split(' ')[0] === dateFormatted,
      )

      // Si no hay citas para la fecha, continua con la siguiente iteración
      if (appointmentsByDate.length === 0) {
        continue
      }

      const appointmentsFiltered = appointmentsByDate[0].appointments?.filter(
        (appointment: Appointment) => appointment?.status?.name !== 'Cancelada',
      )

      // Si hay citas para la fecha, verifica si el rango se superpone con alguna cita
      for (const appointment of appointmentsFiltered) {
        // appointment.hour_from is "16:30"
        const hourFrom = dayjs(`${dateFormatted}T${appointment.hour_from}`)
        // appointment.hour_to is "17:00"
        const hourTo = dayjs(`${dateFormatted}T${appointment.hour_to}`)
        const newStart = dayjs(range.hour_from).format('YYYY-MM-DDTHH:mm:00')
        const newEnd = dayjs(range.hour_to).format('YYYY-MM-DDTHH:mm:00')

        if (
          (hourFrom.isBefore(newStart) && hourTo.isAfter(newStart)) ||
          (hourFrom.isBefore(newEnd) && hourTo.isAfter(newEnd)) ||
          (hourFrom.isSame(newStart) && hourTo.isSame(newEnd)) ||
          (hourFrom.isAfter(newStart) && hourTo.isBefore(newEnd)) ||
          (hourFrom.isAfter(newStart) && hourTo.isSame(newEnd)) ||
          (hourFrom.isSame(newStart) && hourTo.isBefore(newEnd))
        ) {
          setErrorsStatus('hour_to', true, 'Este día tiene citas agendadas', index)
          return true
        }
      }
    }

    setErrorsStatus('hour_to', false, '', index)

    return false
  }

  const validateRangeHours = (): void => {
    state.rango.forEach((hour: IHours, index: number) => {
      if (
        greatherThan(hour?.hour_from as string, hour?.hour_to as string) ||
        isEqual(hour?.hour_from as string, hour?.hour_to as string)
      ) {
        setErrorsStatus(
          'hour_to',
          true,
          'Esta hora debe ser mayor o igual a la hora desde',
          index,
        )

        return
      }
      doesRangeOverlap(hour, index).then()
    })
  }

  useEffect(() => {
    const range: IHours = {
      hour_from: `${state.date_from}T00:00`,
      hour_to: `${state.date_to}T11:59`,
    }

    if (
      state.all_day &&
      !isEqual(range.hour_from as string, range.hour_to as string)
    ) {
      doesRangeOverlap(range, 0).then()
    } else if (!state.all_day) {
      const range = {
        hour_from: dayjs(state.date_from as string).format(
          'YYYY-MM-DDTHH:mm:00',
        ),
        hour_to: dayjs(state.date_from as string).format('YYYY-MM-DDTHH:mm:00'),
      }
      doesRangeOverlap(range, 0).then()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.all_day])

  useEffect(() => {
    fetchDaysAvailabilityInAMonth().then((result: ICalendarData | undefined) =>
      setDaysAvailability(result as ICalendarData),
    )
    setState({
      ...state,
      date_from: selectedDate?.startStr.split('T')[0],
      date_to: selectedDate?.allDay
        ? dayjs(selectedDate?.endStr).subtract(1, 'day').format('YYYY-MM-DD')
        : selectedDate?.endStr.split('T')[0],
      rango: [
        {
          hour_from: dayjs(selectedDate?.startStr).format(
            'YYYY-MM-DDTHH:mm:00',
          ),
          hour_to: dayjs(selectedDate?.startStr)
            .add(
              Number(selectedOffice?.consultation_time_minutes.split(' ')[0]),
              'minutes',
            )
            .format('YYYY-MM-DDTHH:mm:00'),
        },
      ],
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    validateRangeHours()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(state.rango)])

  useEffect(() => {
    validateRangeHours()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [unavailableHours])

  useEffect(() => {
    if (
      !isValidDate(state.date_from as string) ||
      !isValidDate(state.date_to as string)
    ) {
      return
    }
    // validate dates
    if (greatherThan(state.date_from as string, state.date_to as string)) {
      setErrors({
        ...errors,
        date_to: {
          error: true,
          message: 'Esta fecha debe ser mayor o igual a la fecha desde',
        },
      })

      return
    }
    setErrors({
      ...errors,
      date_to: {
        error: false,
        message: '',
      },
    })

    if (state.date_from === state.date_to) {
      fetchAvailableHoursInADay()
    } else {
      setUnavailableHours([])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.date_from, state.date_to])

  const setErrorsStatus = <T extends ErrorHourType[]>(
    field: string,
    error: boolean,
    message: string,
    index?: number | undefined,
  ): void => {
    let value: T

    if (index !== undefined) {
      value = errors.rango as T
      value[index][field].error = error
      value[index][field].message = message
    } else {
      value = { [field]: { error, message } } as unknown as T
    }
    setErrors({ ...errors, ...value })
  }

  const handleAddRange = (): void => {
    const errorsRange: ErrorHourType[] = errors.rango

    errorsRange.push({
      hour_from: { error: false, message: '' },
      hour_to: { error: false, message: '' },
    })
    setErrors({ ...errors, rango: errorsRange })
    const ranges: IHours[] = state.rango

    ranges.push({
      hour_from: dayjs(state.date_from).format('YYYY-MM-DDTHH:mm:00'),
      hour_to: dayjs(state.date_from)
        .add(
          Number(selectedOffice?.consultation_time_minutes.split(' ')[0]),
          'minute',
        )
        .format('YYYY-MM-DDTHH:mm:00'),
    })
    setState({ ...state, rango: ranges })
  }

  const handleChangeAllDay = (checked: boolean): void => {
    setState({
      ...state,
      all_day: checked,
    })
  }

  const handleChangeHours = (
    event: dayjs.Dayjs | null,
    key: 'hour_from' | 'hour_to',
    index: number,
  ): void => {
    const ranges: IHours[] = state.rango

    ranges[index][key] = event?.format('YYYY-MM-DDTHH:mm:00')
    setState({ ...state, rango: ranges })
  }

  const handleChangeDate = (event: dayjs.Dayjs | null, key: string): void => {
    const value: string | undefined = dayjs(event?.toDate()).format(
      'YYYY-MM-DD',
    )

    setState({ ...state, [key]: value })
  }

  const handleSubmit = (e: { preventDefault: () => void }): void => {
    e.preventDefault()
    fetchPostCalendarEvent()
  }

  const fetchPostCalendarEvent = async (): Promise<void> => {
    setDisabledButton(true)
    try {
      const copyRange: IHours[] = structuredClone(state.rango) // deep clone
      const formattedRanges: IHours[] = copyRange.map((r) => {
        r.hour_from = dayjs(r.hour_from).format('HH:mm')
        r.hour_to = dayjs(r.hour_to).format('HH:mm')

        return r
      })
      const { body, status } = await PostAddCalendarEvent({
        ...state,
        rango: formattedRanges,
        user_id: doctorInformation?.user_id as string,
      })
      if (status) {
        await sleep(3000)
      }
      handleAlert(
        true,
        status
          ? (body as string)
          : 'Hubo un error al guardar el horario de no atención.',
        status ? 'success' : 'error',
      )
      setDisabledButton(false)
    } catch (error) {
      setDisabledButton(false)
      handleAlert(
        true,
        'Hubo un error al guardar el horario de no atención.',
        'error',
      )
    }
    handleSaveSelectedDate(state)
    handleClose()
  }

  const handleTimeError = (
    error: TimeValidationError,
    key: 'hour_from' | 'hour_to',
    index: number,
  ): void => {
    const message: string = error ? 'Seleccione una hora válida' : ''

    setErrorsStatus(key, Boolean(error), message, index)
  }

  const handleDateError = (
    error: DateValidationError,
    key: 'date_from' | 'date_to',
  ): void => {
    const message: string = error ? 'Seleccione una fecha valida' : ''

    setErrorsStatus(key, Boolean(error), message)
  }

  const handleDisableDay = (day: dayjs.Dayjs): boolean => {
    return !(daysAvailability as ICalendarData)[dayjs(day).format('YYYY-MM-DD')]
  }

  return (
    <ModalComponent
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={handleClose}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <FadeModal in={open}>
        <BoxModal>
          <GridModal item xs={12}>
            <CloseButtonModal
              onClick={handleClose}
              data-testid="close-button"
              sx={{
                right: 10,
                top: 5,
              }}
            >
              <CloseIcon />
            </CloseButtonModal>
            <form onSubmit={handleSubmit} data-testid="modal-form1">
              <TypographyModal id="transition-modal-title" variant="h6">
                Indica tus tiempos no disponible
              </TypographyModal>
              <TypographyTittle id="transition-modal-description">
                Nombre
              </TypographyTittle>
              <TextFielNameModal
                placeholder="Nombre del evento"
                data-testid="name-input"
                type="text"
                fullWidth
                value={state.name}
                name="name"
                onChange={(event) =>
                  setState({ ...state, name: event.target.value })
                }
                disabled={disabledButton}
                required
                error={!state.name}
                helperText={!state.name ? 'Campo requerido' : ''}
              />
              <GridModalContainerItem>
                <GridModalItem>
                  <TypographyTittle id="transition-modal-description">
                    Fecha desde
                  </TypographyTittle>
                  <BoxTimeModal>
                    <DatePickerWrapper
                      value={dayjs(state.date_from)}
                      disablePast
                      onChange={(event: dayjs.Dayjs | null) =>
                        handleChangeDate(event, 'date_from')
                      }
                      shouldDisableDate={(day: dayjs.Dayjs) =>
                        handleDisableDay(day)
                      }
                      onError={(error: DateValidationError) =>
                        handleDateError(error, 'date_from')
                      }
                      slotProps={{
                        textField: {
                          helperText: errors.date_from.message,
                          error: errors.date_from.error,
                        },
                      }}
                      disabled={disabledButton}
                    />
                  </BoxTimeModal>
                </GridModalItem>
                <GridModalItem>
                  <TypographyTittle id="transition-modal-description">
                    Fecha hasta
                  </TypographyTittle>
                  <BoxTimeModal>
                    <DatePickerWrapper
                      value={dayjs(state.date_to)}
                      disablePast
                      onChange={(event: dayjs.Dayjs | null) =>
                        handleChangeDate(event, 'date_to')
                      }
                      shouldDisableDate={(day: dayjs.Dayjs) =>
                        handleDisableDay(day)
                      }
                      onError={(error: DateValidationError) =>
                        handleDateError(error, 'date_to')
                      }
                      disabled={disabledButton}
                      slotProps={{
                        textField: {
                          helperText: errors.date_to.message,
                          error: errors.date_to.error,
                        },
                      }}
                    />
                  </BoxTimeModal>
                </GridModalItem>
              </GridModalContainerItem>
              {state.rango.map((item: IHours, index: number) => (
                <GridModalContainerItem key={index}>
                  <GridModalItem>
                    <TypographyTittle id="transition-modal-description">
                      Hora desde
                    </TypographyTittle>
                    <BoxTimeModal>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <TimeField
                          disabled={state.all_day || disabledButton}
                          value={dayjs(item.hour_from)}
                          onChange={(event: dayjs.Dayjs | null) =>
                            handleChangeHours(event, 'hour_from', index)
                          }
                          onError={(error: TimeValidationError) =>
                            handleTimeError(error, 'hour_from', index)
                          }
                          slotProps={{
                            textField: {
                              helperText:
                                errors.rango[index]['hour_from'].message,
                              error: errors.rango[index]['hour_from'].error,
                            },
                          }}
                        />
                      </LocalizationProvider>
                    </BoxTimeModal>
                  </GridModalItem>
                  <GridModalItem>
                    <TypographyTittle id="transition-modal-description">
                      Hora hasta
                    </TypographyTittle>
                    <BoxTimeModal>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <TimeField
                          disabled={state.all_day || disabledButton}
                          value={dayjs(item.hour_to)}
                          onChange={(event: dayjs.Dayjs | null) =>
                            handleChangeHours(event, 'hour_to', index)
                          }
                          onError={(error: TimeValidationError) =>
                            handleTimeError(error, 'hour_to', index)
                          }
                          slotProps={{
                            textField: {
                              helperText:
                                errors.rango[index]['hour_to'].message,
                              error: errors.rango[index]['hour_to'].error,
                            },
                          }}
                        />
                      </LocalizationProvider>
                    </BoxTimeModal>
                  </GridModalItem>
                </GridModalContainerItem>
              ))}
              <GridModalContainerItem>
                <GridModalItemRadio>
                  <Checkbox
                    icon={<RadioButtonUncheckedIcon />}
                    checkedIcon={<CircleIcon />}
                    checked={state.all_day}
                    disabled={disabledButton}
                    onChange={(
                      event: React.ChangeEvent<HTMLInputElement>,
                      checked: boolean,
                    ) => handleChangeAllDay(checked)}
                  />
                </GridModalItemRadio>
                <GridModalItemTexto>
                  <TypographyTittle id="transition-modal-description">
                    Aplicar todo el día
                  </TypographyTittle>
                </GridModalItemTexto>
                <GridModalItem
                  style={{
                    justifyContent: 'flex-end',
                    display: 'flex',
                    marginRight: '5px',
                  }}
                >
                  <ButtonRangoModal
                    variant="contained"
                    role="button"
                    sx={{ width: '80%' }}
                    onClick={handleAddRange}
                    disabled={state.all_day || disabledButton}
                  >
                    + Agregar rango
                  </ButtonRangoModal>
                </GridModalItem>
              </GridModalContainerItem>
              <GridModalContainerItem>
                <ButtonSubmitModal
                  type="submit"
                  variant="contained"
                  data-testid="ok-button"
                  fullWidth
                  disabled={
                    disabledButton ||
                    !state.name ||
                    errors.date_from.error ||
                    errors.date_to.error ||
                    (!state.all_day &&
                      errors.rango.some(
                        (r: ErrorHourType) =>
                          r.hour_from.error === true ||
                          r.hour_to.error === true,
                      ))
                  }
                >
                  Marcar como ausente
                </ButtonSubmitModal>
              </GridModalContainerItem>
            </form>
          </GridModal>
        </BoxModal>
      </FadeModal>
    </ModalComponent>
  )
}
