import {
  GetQualificationListDoctor,
  PutQualificationStatus,
} from '../../../domain/useCase/QualificationUseCase'
import {
  IApiResponseQualification,
  IResponseQualificationList,
  ISendQualificationStatus,
  IServiceQualification,
} from '../../../infrastructure/dtos/qualification'
import QualificationMapper from '../../../infrastructure/http/mappers/QualificationMapper/QualificationMapper'
import { QualificationRepositoryImpl } from '../../../infrastructure/RepositoryImpl/QualificationRepositoryImpl'

export async function GetQualificationListDoctorService(
  doctor_id: string,
): Promise<
  IServiceQualification<IApiResponseQualification<IResponseQualificationList>>
> {
  try {
    const UseCaseQualification: GetQualificationListDoctor =
      new GetQualificationListDoctor(
        new QualificationRepositoryImpl(new QualificationMapper()),
      )

    return {
      status: 1,
      data: await UseCaseQualification.invoke(doctor_id),
    }
  } catch (error: unknown) {
    return {
      status: 0,
      data: 'Error Service',
    }
  }
}

export async function PutQualificationStatusService(
  params: ISendQualificationStatus,
): Promise<IServiceQualification<IApiResponseQualification<string>>> {
  try {
    const UseCaseQualification: PutQualificationStatus =
      new PutQualificationStatus(
        new QualificationRepositoryImpl(new QualificationMapper()),
      )

    return {
      status: 1,
      data: await UseCaseQualification.invoke(params),
    }
  } catch (error: unknown) {
    return {
      status: 0,
      data: 'Error Service',
    }
  }
}
