import { IReschedulingAppointmentRepository } from '../../domain/repository/ReschedulingAppointmentRepository'
import {
  IReschedulingAppointment,
  IResponseReschedulingAppointment,
} from '../dtos/ReschedulingAppointment'
import ReschedulingAppointmentDataSource from '../http/dataSource/ReschedulingAppointmentDataSource'

export class ReschedulingAppointmentRepositoryImpl
  implements IReschedulingAppointmentRepository
{
  dataSource: ReschedulingAppointmentDataSource

  constructor(_datasource: ReschedulingAppointmentDataSource) {
    this.dataSource = _datasource
  }

  saveReschedulingAppointment(
    dataReschedulingAppointment: IReschedulingAppointment,
  ): Promise<IResponseReschedulingAppointment> {
    return this.dataSource.saveReschedulingAppointment(
      dataReschedulingAppointment,
    )
  }
}
