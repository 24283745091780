import { MedicalExamsItem } from '../../../infrastructure/dtos/MedicalExamsItem'
import { getMedicalExamsService } from '../Persistencia/MedicalExamsService'

interface IGetMedicalExamsUtility {
  status: number
  data: {
    message: string
    data: MedicalExamsItem[]
  }
}

export const getMedicalExamsUtility = async (
  patientId: string,
): Promise<IGetMedicalExamsUtility> => {
  try {
    const { status, data } = await getMedicalExamsService(patientId)
    return {
      status: status && data.statusCode === 200 ? 1 : 0,
      data: {
        message: data.body.message,
        data: data.body.data,
      },
    }
  } catch (error: unknown) {
    return {
      status: 0,
      data: {
        message: (error as Error).message,
        data: [],
      },
    }
  }
}
