import { useEffect, useState } from 'react'

import { useSkills } from './hooks/useSkills'
import { ChipWrapper, GridWrapper } from '../../../../components'
import {
  AutocompleteStyled,
  FormControlStyled,
  TextFieldAutocomplete,
} from '../../../../components/Autocomplete/styles'

interface IProps {
  skills: string[]
  setSkills: (skills: string[]) => void
}

export const SkillsAutocomplete = ({
  skills,
  setSkills,
}: IProps): JSX.Element => {
  const [query, setQuery] = useState<string>('')

  const [skill, setSkill] = useState<string>('')
  const [options, setOptions] = useState<string[]>([])

  const { fetchSkills } = useSkills({ query, setOptions, skill })

  useEffect(() => {
    if (skill.length > 0) {
      const allSkills = skills ? [...skills, skill] : [skill]
      const uniqueSkills = allSkills.filter(
        (skill, index) => allSkills.indexOf(skill) === index,
      )
      setSkills(uniqueSkills)
      setSkill('')
      setOptions([])
      setQuery('')
    }
  }, [skill])

  useEffect(() => {
    if (query.length > 0) {
      fetchSkills().then()
    }
  }, [query])

  return (
    <GridWrapper width={'100%'}>
      <FormControlStyled
        sx={{
          marginBottom: '30px',
        }}
      >
        <AutocompleteStyled
          freeSolo
          id="component-appointment-list"
          options={options}
          value={skill}
          aria-placeholder={'Habilidades'}
          disabled={false}
          renderInput={(params) => (
            <TextFieldAutocomplete
              {...params}
              variant="outlined"
              fullWidth
              data-testid={'skill'}
            />
          )}
          onChange={(
            event: React.SyntheticEvent<Element, Event>,
            value: unknown,
          ) => {
            if (typeof value === 'string') {
              setSkill(value)
            }
            setOptions([])
          }}
          onInputChange={
            // add new skill to options only if the autocomplete is empty
            (event, newInputValue) => {
              if (newInputValue.length > 0) {
                setQuery(newInputValue)
              }
            }
          }
        />
      </FormControlStyled>
      {skills?.map((skill: string) => (
        <ChipWrapper
          sx={{
            marginRight: '10px',
            marginBottom: '5px',
            backgroundColor: 'tertiary.light',
            color: 'text.primary',
            fontSize: '11px',
            '& .MuiChip-deleteIcon': {
              color: 'text.disabled',
              fontSize: '16px',
            },
          }}
          key={skill}
          label={skill}
          onDelete={() => setSkills(skills.filter((s: string) => s !== skill))}
        />
      ))}
    </GridWrapper>
  )
}
