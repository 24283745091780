import React from 'react';

import AutocompleteComponent from '../../../../components/Autocomplete';
import { IDaysMedicalCare } from '../../../../contexts/RegisterContext';

interface ITimePickerProps {
  dataConsultory: IDaysMedicalCare[],
  setDataConsultory: React.Dispatch<React.SetStateAction<IDaysMedicalCare[]>>
  index: number,
  day: string
  type: boolean,
  checked: boolean,
  hour: string,
  minTime: string,
  hours: string[]
}

export default function ResponsiveTimePickers(props: ITimePickerProps): JSX.Element {
  const { checked, type, index, dataConsultory, hour, hours, setDataConsultory, day } = props;

  const onChangeTimePicker = (value: string): void => {
    const newIsDataConsultory: IDaysMedicalCare[] = [...dataConsultory]
    const dataDay: IDaysMedicalCare = newIsDataConsultory[index];

    if ( type ) {
      newIsDataConsultory[index] = {
        active: dataDay.active,
        day: dataDay.day,
        from: value,
        to: dataDay.to,
      }
    } else {
      newIsDataConsultory[index] = {
        active: dataDay.active,
        day: dataDay.day,
        from: dataDay.from,
        to: value,
      }
    }
    setDataConsultory( newIsDataConsultory )
  }

  const dataTestIdText = (): string => type ? `${day}-from` : `${day}-to`

  return (
    <AutocompleteComponent
      id="component-appointment-list"
      label=""
      list={hours}
      setValue={onChangeTimePicker}
      value={hour}
      disabled={!checked}
      datatestid={dataTestIdText()}
    />
  );
}

