import { IApiResponseParametersNotificationLateAppointment } from '../../infrastructure/dtos/ParametersNotification';
import { ParametersNotificationRepository } from '../repository/ParametersNotificationRepository';

export interface GetParametersNotificationLateAppointmentUseCase {
  invoke: () => Promise<IApiResponseParametersNotificationLateAppointment>
}

export class GetParametersNotificationLateAppointment
implements GetParametersNotificationLateAppointmentUseCase
{
  private objectUseCase: ParametersNotificationRepository;

  constructor( _objectUseCase: ParametersNotificationRepository ) {
    this.objectUseCase = _objectUseCase
  }

  async invoke(): Promise<IApiResponseParametersNotificationLateAppointment> {
    return this.objectUseCase.getParametersNotificationLateAppointment()
  }
}
