import { useEffect, useState } from 'react'


import { AppointmentType } from '../../ProfileMedicalScheduleSetting/Hooks/useProfileMedicalScheduleSetting'

interface IAppointmentTypesList {
  setTypes: React.Dispatch<React.SetStateAction<string[]>>
  types: string[]
  type: string
  setType: React.Dispatch<React.SetStateAction<string>>
  defaultTypes: string[]
  durations: Duration[]
  typeDuration: string
  setTypeDuration: React.Dispatch<React.SetStateAction<string>>
  isDisabled: boolean
  handleModifyDurationInput: (value: unknown) => void
  handleModifyDurationItem: (type: AppointmentType, value: unknown) => void
}

export interface Duration {
  name: string
  value: number
}

interface IAppointmentTypesListProps {
  typesList: AppointmentType[]
  setTypesList: React.Dispatch<React.SetStateAction<AppointmentType[]>>
  durationsDB: Duration[]
  typesDB: string[]
}

export const useAppointmentTypesList = ({
  typesList,
  setTypesList,
  typesDB,
  durationsDB,
}: IAppointmentTypesListProps): IAppointmentTypesList => {

  const [typeDuration, setTypeDuration] = useState('')
  const [type, setType] = useState<string>('' )


  const [types, setTypes] = useState<string[]>(typesDB)
  const [durations] = useState<Duration[]>(durationsDB)
  const [defaultTypes] = useState<string[]>(typesDB)

  const [isDisabled, setIsDisabled] = useState(false)

  const handleModifyDurationItem = (
    type: AppointmentType,
    value: unknown,
  ): void => {
    const duration = durationsDB.find((d) => d.name === value)
    if (duration) {
      setTypesList(
        typesList.map((t) =>
          t.id === type.id
            ? {
                ...t,
                duration: value as string,
                duration_value: duration.value,
              }
            : t,
        ),
      )
    } else {
      setTypesList(
        typesList.map((t) => (t.id === type.id ? { ...t, duration: '' } : t)),
      )
    }
  }

  const handleModifyDurationInput = (value: unknown): void => {
    const duration = durationsDB.find((d) => d.name === value)
    if (duration) {
      setTypeDuration(value as string)
    } else {
      setTypeDuration('')
    }
  }

  useEffect(() => {
    if (!type || !typeDuration) {
      setIsDisabled(true)
    } else {
      setIsDisabled(false)
    }
  }, [type, typeDuration])

  return {
    setTypes,
    types,
    type,
    setType,
    isDisabled,
    handleModifyDurationInput,
    handleModifyDurationItem,
    defaultTypes,
    typeDuration,
    setTypeDuration,
    durations
  }
}
