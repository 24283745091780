import React, { ReactNode } from 'react'

import { GridWrapper, ListItemWrapper, ListWrapper } from '../../../../../components/wrapper'
import CircularProgressWrapper from '../../../../../components/wrapper/LoaderWrapper'
import { Agreement } from '../../../../../infrastructure/dtos/Agreements'

interface IAgreementList {
  loadingList: boolean
  list: Agreement[]
  gridHeader: {
    title: string;
    width: string;
  }[]
  gridBody: (item: Agreement) => {
    value: string | ReactNode;
    width: string;
  }[]
  StatusColor: (item: Agreement, index: number, value: string) => string
}

const AgreementList = ({ gridBody, gridHeader, loadingList, list, StatusColor }: IAgreementList): JSX.Element => {

  return (
    <GridWrapper px={'4rem'} pt={2} data-testid="appointments-component">
      {loadingList ? (
        <GridWrapper
          sx={{
            paddingTop: '50px',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <CircularProgressWrapper
            size={'3rem'}
            data-testid="loading-spinner"
          />
        </GridWrapper>
      ) : (
        <GridWrapper>
          <ListWrapper>
            {list?.length > 0 &&
              <ListItemWrapper
                style={{
                  borderRadius: '40px',
                  margin: '10px 0',
                }}
                data-testid="appointment"
              >
                {gridHeader.map((item, index) => (
                  <GridWrapper
                    item
                    textAlign={'center'}
                    width={item.width}
                    key={`GridHeader-${index}`}
                    sx={{
                      color: 'tertiary.main',
                    }}
                  >
                    {item.title}
                  </GridWrapper>
                ))}
              </ListItemWrapper>
            }
            {list?.length > 0 && list?.map((dates, index) => (

              <ListItemWrapper
                key={index}
                style={{
                  height: '80px',
                  background: 'white',
                  borderRadius: '40px',
                  marginBottom: '30px',
                  boxShadow: '0px 3px 5px 3px rgba(0, 0, 0, 0.06)',
                }}
                data-testid="appointment-item-list"
              >
                {gridBody(dates).map((i, idx) => (
                  <GridWrapper
                    key={`GridHeader-${idx}`}
                    item
                    textAlign={'center'}
                    width={i.width}
                    sx={{
                      color: StatusColor(dates, idx, i.value as string),
                      // color: 'InactiveCaptionText',
                      '@media (max-width: 1600px)': {
                        fontSize: '12px',
                      },
                      '@media (min-width: 1601px)': {
                        fontSize: '16px',
                      },
                    }}
                  >
                    {i.value}
                  </GridWrapper>
                ))}
              </ListItemWrapper>
            ))}
          </ListWrapper>
        </GridWrapper>
      )}
    </GridWrapper>
  )
}

export default AgreementList