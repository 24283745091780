import { Select, SelectProps } from '@mui/material'
import React from 'react'
// delete variant from SelectProps

type SelectWrapperProps = Omit<SelectProps, 'variant'>



const SelectWrapper: React.FC<SelectWrapperProps> = ({  ...props }) => (
  <Select {...props} />
)

export default SelectWrapper
