import { BodySaveOfficeRequest, IApiResponseSaveOffice } from '../../infrastructure/dtos/Offices'
import { OfficesRepository } from '../repository/OfficesRepository'

export interface SaveNewOfficeUseCase {
  invoke(body: BodySaveOfficeRequest): Promise<IApiResponseSaveOffice>
}


export class SaveNewOffice implements SaveNewOfficeUseCase {
  private objectUseCase: OfficesRepository

  constructor(_objectUseCase: OfficesRepository) {
    this.objectUseCase = _objectUseCase
  }

  invoke(body: BodySaveOfficeRequest): Promise<IApiResponseSaveOffice> {
    return this.objectUseCase.saveNewOffice(body)
  }
}
