import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import EditIcon from '@mui/icons-material/Edit'
import SaveIcon from '@mui/icons-material/Save'
import React from 'react'

import {
  CustomButtonSelect,
  CustomInputCurrency,
  DisabledText,
  TextFielRegisterStyled,
} from './AddedOfficesStyle'
import {
  GridWrapper,
  InputAdornmentWrapper,
  StackWrapper,
} from '../../../../../../components'
import { currencyFormat } from '../../../../../../utils/CurrencyFormat'

import {
  ICoordinates,
  IOfficeMedical,
  IPlaceType,
} from '../../hooks/useRegisterConsultory'
import AutocompleteGoogleMaps from '../MapComponents/AutocompleteGoogle'

type setStateMethod<T> = React.Dispatch<React.SetStateAction<T>>

interface IAddedOfficeProps {
  address: string
  selected: boolean
  isLoaded: boolean
  item: IOfficeMedical
  isItemModifying: boolean
  officeMedical: IOfficeMedical[]
  setZoom: setStateMethod<number>
  setMarkerPosition: setStateMethod<google.maps.LatLng | null>
  setAddress: setStateMethod<string>
  setMapCenter: setStateMethod<ICoordinates>
  setSelected: setStateMethod<boolean>
  setValueEdition: setStateMethod<IPlaceType | null>
  addressEdition: (id: string, valor: IPlaceType | null) => void
  editItem: (office: IOfficeMedical, value: string, key: string) => void
  deleteItem: (value: IOfficeMedical) => void
  modifyItem: (value: IOfficeMedical) => void
}

export const AddedOffice = ({
  address,
  selected,
  isLoaded,
  item,
  isItemModifying,
  officeMedical,
  setMarkerPosition,
  setZoom,
  setAddress,
  setMapCenter,
  setSelected,
  setValueEdition,
  addressEdition,
  editItem,
  deleteItem,
  modifyItem,
}: IAddedOfficeProps): JSX.Element => {
  return (
    <>
      <AutocompleteGoogleMaps
        address={address}
        setMarkerPosition={setMarkerPosition}
        setZoom={setZoom}
        setAddress={setAddress}
        setMapCenter={setMapCenter}
        isLoaded={isLoaded}
        setSelected={setSelected}
        selected={selected}
        isDisabled={!item.state}
        value={item.value}
        setValue={setValueEdition}
        editingValueEdition={addressEdition}
        id={item.id}
      />
      {item.state ? (
        <>
          <TextFielRegisterStyled
            state={String(item.state)}
            placeholder="Edificio"
            label="Edificio"
            disabled={!item.state}
            type="text"
            value={item.building_name}
            onChange={(event) =>
              editItem(item, event.target.value, 'building_name')
            }
            fullWidth
            multiline
            maxRows={4}
          />
          <GridWrapper container spacing={2}>
            <GridWrapper item xs={6}>
              <TextFielRegisterStyled
                state={String(item.state)}
                placeholder="Piso"
                label="Piso"
                disabled={!item.state}
                type="text"
                value={item.building_floor}
                onChange={(event) =>
                  editItem(item, event.target.value, 'building_floor')
                }
                fullWidth
                multiline
                maxRows={4}
              />
            </GridWrapper>
            <GridWrapper item xs={6}>
              <TextFielRegisterStyled
                state={String(item.state)}
                placeholder="Consultorio"
                label="Consultorio"
                disabled={!item.state}
                type="text"
                value={item.office_number}
                onChange={(event) =>
                  editItem(item, event.target.value, 'office_number')
                }
                fullWidth
                multiline
                maxRows={4}
              />
            </GridWrapper>
          </GridWrapper>
        </>
      ) : (
        <DisabledText data-testid="address-disabled-text">
          {`Edif. ${item?.building_name}, Consultorio ${item?.office_number}`}
        </DisabledText>
      )}
      {item.state ? (
        <CustomInputCurrency
          placeholder="Ingresa el monto"
          id="appointment_price"
          name="appointment_price"
          type="number"
          fullWidth
          value={item.consultationValue}
          disabled={!item.state}
          onChange={(event) =>
            editItem(item, event.target.value, 'consultationValue')
          }
          startAdornment={
            <InputAdornmentWrapper position="start">$</InputAdornmentWrapper>
          }
        />
      ) : (
        <DisabledText data-testid="value-disabled-text">
          $ {currencyFormat(item?.consultationValue)}
        </DisabledText>
      )}

      <StackWrapper
        spacing={1}
        direction="row"
        alignItems="center"
        justifyContent="center"
      >
        <CustomButtonSelect
          variant="outlined"
          role="button"
          onClick={() => modifyItem(item)}
          disabled={
            !item.consultationValue ||
            !item.building_floor ||
            !item.building_name ||
            !item.office_number ||
            !item.value ||
            (isItemModifying &&
              officeMedical.some((x) => x.id !== item.id && !item.state))
          }
        >
          {item.state ? (
            <>
              <SaveIcon /> &nbsp; Guardar
            </>
          ) : (
            <>
              <EditIcon /> &nbsp; Modificar
            </>
          )}
        </CustomButtonSelect>

        {!item.state && (
          <CustomButtonSelect
            variant="outlined"
            role="button"
            onClick={() => deleteItem(item)}
            disabled={isItemModifying}
          >
            <DeleteOutlineIcon />
            &nbsp; Eliminar
          </CustomButtonSelect>
        )}
      </StackWrapper>
    </>
  )
}
