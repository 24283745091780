import { TextFieldProps } from '@mui/material'

import { LabelInput } from '../StyledComponents/LabelInput'
import { TextAreaField } from '../StyledComponents/TextAreaField'

export interface TextArea {
  text: string
  error?: boolean
  helperText?: string
}

type AllProps = TextFieldProps & TextArea

const FormTextArea = (props: AllProps): JSX.Element => {
  const { text } = props
  return (
    <>
      <LabelInput sx={{ marginLeft: 0, fontWeight: 'normal' }}>
        {text}
      </LabelInput>

      <TextAreaField {...props} />
    </>
  )
}

export default FormTextArea
