import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import EditIcon from '@mui/icons-material/Edit'
import { styled } from '@mui/material'

import {
  ButtonWrapper,
  GridWrapper,
  IconButtonWrapper,
  InputWrapper,
  TypographyWrapper
} from '../../../../../../../components/wrapper'

import CardWrapper from '../../../../../../../components/wrapper/CardWrapper'


export const ActionsButton = styled(IconButtonWrapper)(({ theme }) => ({
  padding: '10px 0',
  minWidth: '45px',
  backgroundColor: theme.palette.common.white,
  '&:hover': {
    backgroundColor: '#efefef'
  }
}))

export const EditIconPrescription = styled( EditIcon )(( props ) => ({
  color: props.theme.palette.primary.main
}))

export const DeleteIconPrescription = styled( DeleteOutlineIcon )(( props ) => ({
  color: props.theme.palette.primary.main
}))

export const PrescriptionCard = styled(CardWrapper)(() => ({
  margin: '20px 0',
  borderRadius: '40px',
  padding: '15px 10px',
  boxShadow: '1px 1px 7px 7px rgba(0, 0, 0, 0.03)'
}))

export const GridTypo = styled(GridWrapper)(() => ({
  // margin: '10px'
  padding: 0
}))

export const PrescriptionTitle = styled( TypographyWrapper )(
  () => ({
    fontFamily: 'Helvetica',
    fontSize: '16px',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    marginBottom: '30px',
    color: 'black'
  })
)

export const QuantityButton = styled( ButtonWrapper )(() => ({
  borderRadius: 32,
  minWidth: '30px',
  width: '30px',
  height: '30px',
  boxShadow: '2px 2px 8px 4px rgba(0,0,0, 0.045099)'
}))

export const InputStyled = styled(InputWrapper)(({ theme }) => ({
  color: theme.palette.primary.dark,
  fontWeight: 'bold',
  'input': {
    '&::placeholder': {
      color: theme.palette.text.secondary,
      fontWeight: '400',
    },
    fontSize: '12px',
    '@media (min-width: 1600px)': {
      fontSize: '16px',
    },
  },
}))

export const InputNumber = styled(InputStyled)(() => ({
  '& input[type=number]': {
    'MozAppearance': 'textfield'
  },
  '& input[type=number]::-webkit-outer-spin-button': {
      'WebkitAppearance': 'none',
      margin: 0
  },
  '& input[type=number]::-webkit-inner-spin-button': {
      'WebkitAppearance': 'none',
      margin: 0
  }
}))

export const InputQuantity = styled(InputNumber)(() => ({
  '& .MuiInputBase-input': {
    textAlign: 'center'
  },
}))
