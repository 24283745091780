import CloseIcon from '@mui/icons-material/Close'
import { Backdrop } from '@mui/material'
import React from 'react'

import { useNextAppointment } from './NextAppointmentHook'
import { ModalPad, TextFielModal } from './styles'
import { CustomAppointmentType } from '../../../../../components/ModalDate/components/CustomAppointmentType/CustomAppointmentType'
import ModalDatePicker from '../../../../../components/ModalDate/components/ModalDatePicker'
import newDateText from '../../../../../components/ModalDate/components/ModalDatePicker/NewDateText.json'
import { SelectTypesList } from '../../../../../components/ModalDate/ModalDateStyles'
import SubmitButton from '../../../../../components/SubmitButton'
import {
  GridWrapper,
  MenuItemWrapper,
  ModalWrapper,
  SelectWrapper,
} from '../../../../../components/wrapper'
import { Appointment } from '../../../../../infrastructure/dtos/Appointments'
import { ICalendarData } from '../../../../../infrastructure/dtos/CalendarInfo'
import { OfficeList } from '../../../../Patient/ScheduleAppointment/components/OfficesList/OfficesList'
import { BoxTimeModal } from '../../../CalendarForm/Styles/HoursOfNonAttendanceModalStyles'
import {
  BottonGrid,
  BoxModal,
  CloseButton,
  FadeModal,
  ModalTitle,
  TypographyModalTittle,
} from '../RescheduleModal/styles'

interface IModalProps {
  handleClose: () => void
  open: boolean
  officeId: string
  appointment: Appointment | undefined
  handleSendNextAppointmentAfterSubmit: () => void
}

function NextAppointment({
  handleClose,
  handleSendNextAppointmentAfterSubmit,
  officeId,
  appointment,
  open,
}: IModalProps): React.JSX.Element {
  const {
    selectedOffice,
    selectedDate,
    daysAvailability,
    hourAvailavility,
    hour,
    comments,
    idDoctor,
    commentRequiredLength,
    validCommentsLength,
    commentsHelperText,
    disabledButton,
    onSubmit,
    handleChangeOffice,
    handleChangeDate,
    handleChangeComments,
    handleChangeHour,
    appointmentType,
    setAppointmentType,
    appointmentTypeList,
    customDuration,
    isCustomType,
    customType,
    setCustomType,
    setCustomDuration,
    calendarDisabled,
  } = useNextAppointment({
    appointment,
    officeId,
    handleSendNextAppointmentAfterSubmit,
  })

  return (
    <ModalWrapper
      sx={{ overflowY: 'auto' }}
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      data-testid="next-appointment"
      onClose={handleClose}
      open={open}
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <FadeModal in={open}>
        <BoxModal>
          <ModalPad>
            <CloseButton onClick={handleClose} role="close" id="close-button">
              <CloseIcon />
            </CloseButton>
            <ModalTitle id="transition-modal-title" variant="h6">
              Agenda la próxima cita de {`${appointment?.patient_name} ${appointment?.patient_last_name}`}
            </ModalTitle>
            <form onSubmit={onSubmit} data-testid="next-appointment-form">
              <GridWrapper container>
                <TypographyModalTittle>
                  Selecciona el lugar de atención
                </TypographyModalTittle>
                <OfficeList
                  handleChangeOffice={handleChangeOffice}
                  office={selectedOffice}
                  disabled={appointment?.doctor_type !== 'DOCTOR_CM' ? false : true}
                  idDoctor={idDoctor}
                  data-testid="office-select"
                  filterOffices={
                    appointment?.doctor_type !== 'DOCTOR_CM' && appointment
                      ? 'DOCTOR'
                      : appointment
                        ? 'DOCTOR_CM'
                        : ''
                  }
                />
              </GridWrapper>
              <GridWrapper container>
                <TypographyModalTittle>
                  {newDateText.appointment_type_label}
                </TypographyModalTittle>
                <SelectTypesList
                  value={appointmentType}
                  onChange={(event) => {
                    setAppointmentType(event.target.value as string)
                  }}
                  defaultValue=""
                  data-testid={'appointment-type-select'}
                  SelectDisplayProps={{
                    role: 'combobox',
                  }}
                  MenuProps={{
                    PaperProps: {
                      role: 'tab',
                    },
                  }}
                >
                  {appointmentTypeList.map((item: string, index: number) => {
                    return (
                      <MenuItemWrapper key={`specialist${index}`} value={item}>
                        {item}
                      </MenuItemWrapper>
                    )
                  })}
                </SelectTypesList>
              </GridWrapper>
              {isCustomType && (
                <GridWrapper container>
                  <TypographyModalTittle>
                    {newDateText.custom_appointment_type}
                  </TypographyModalTittle>
                  <CustomAppointmentType
                    customType={customType}
                    setCustomType={setCustomType}
                    duration={customDuration}
                    setDuration={setCustomDuration}
                  />
                </GridWrapper>
              )}
              <GridWrapper container spacing={2}>
                <GridWrapper item xs={12} sm={12} md={6} marginY={'14px'}>
                  <TypographyModalTittle>Fecha</TypographyModalTittle>
                  <BoxTimeModal data-testid="date-picker">
                    <ModalDatePicker
                      onChangeDate={handleChangeDate}
                      value={selectedDate}
                      daysAvailability={daysAvailability as ICalendarData}
                      disabled={calendarDisabled}
                    />
                  </BoxTimeModal>
                </GridWrapper>
                <GridWrapper item xs={12} sm={12} md={6} marginY={'14px'}>
                  <TypographyModalTittle>Hora</TypographyModalTittle>
                  <BoxTimeModal>
                    {hourAvailavility !== undefined && (
                      <SelectWrapper
                        sx={{
                          width: '100%',
                          '& .MuiSelect-select': {
                            fontSize: '12px',
                            '@media (min-width: 1600px)': {
                              fontSize: '16px',
                            },
                          },
                        }}
                        name="hour"
                        id="hour"
                        value={hour}
                        onChange={(event) =>
                          handleChangeHour(event.target?.value as string)
                        }
                        defaultValue=""
                      >
                        {hourAvailavility.length > 0 &&
                          hourAvailavility.map(
                            (item: string, index: number) => (
                              <MenuItemWrapper
                                key={index}
                                value={item}
                                data-testid={`hour-options`}
                              >
                                {item}
                              </MenuItemWrapper>
                            ),
                          )}
                      </SelectWrapper>
                    )}
                  </BoxTimeModal>
                </GridWrapper>
              </GridWrapper>
              <GridWrapper container>
                <GridWrapper item sm={12}>
                  <TypographyModalTittle>Comentarios</TypographyModalTittle>
                  <TextFielModal
                    value={comments}
                    placeholder="Deja un comentario"
                    data-testid="comments-input"
                    id="comments-input"
                    type="text"
                    fullWidth
                    name="cooments"
                    required
                    onChange={(event) =>
                      handleChangeComments(event.target?.value as string)
                    }
                    error={!validCommentsLength}
                    helperText={commentsHelperText}
                    inputProps={{
                      'aria-label': 'comments-input',
                      maxLength: commentRequiredLength + 1,
                    }}
                  />
                </GridWrapper>
              </GridWrapper>
              <BottonGrid display={'flex'} justifyContent={'center'}>
                <SubmitButton
                  data-testid={'send-next-appointment'}
                  id={'send-next-appointment'}
                  variant={'contained'}
                  fullWidth={false}
                  type={'submit'}
                  text={'Enviar'}
                  sx={{ width: '180px' }}
                  disabled={disabledButton()}
                  disableElevation
                />
              </BottonGrid>
            </form>
          </ModalPad>
        </BoxModal>
      </FadeModal>
    </ModalWrapper>
  )
}

export default NextAppointment
