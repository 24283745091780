import { Tooltip, TooltipProps } from '@mui/material'
import React from 'react'

const TooltipWrapper = React.forwardRef<HTMLDivElement, TooltipProps>(
  ({ children, ...props }, ref) => (
    <Tooltip {...props} ref={ref}>
      {children}
    </Tooltip>
  ),
)

export default TooltipWrapper
