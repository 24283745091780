import { useEffect, useState } from 'react'

import { Severity } from '../../../../../contexts/AuthReducer';
import { GetVisibilityIntervalsUtility } from '../../../../../services/Contracts/Utility/VisibilityIntervalsUtility';

interface IUseHook {
  amountRanges: string[];
}

export function useVisibilityIntervals(
  handleAlert?: (open: boolean, message: string, severity?: Severity) => void
): IUseHook {
  const [amountRanges, setAmountRanges] = useState<string[]>([])

  useEffect(() => {
    fetchVisibilityIntervals();
  }, []);

  const fetchVisibilityIntervals = async (): Promise<void> => {
    try {
      const { data, status } = await GetVisibilityIntervalsUtility()
      if (status) {
        setAmountRanges(data)
      } else {
        throw new Error('')
      }
    } catch ( error: unknown ) {
      handleAlert?.(true, 'Error al obtener listado de intervalos de meses', 'error')
    }
  };

  return {
    amountRanges
  };
}
