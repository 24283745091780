export const fileToBase64 = (blob: Blob): Promise<string | ArrayBuffer | null> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob)
    // Read file content on file loaded event
    reader.onload = function() {
      resolve(reader.result)
    };
    reader.onerror = function(error) {
      reject(error)
    }
  });
};
