import { GoogleMap, OverlayView } from '@react-google-maps/api'
import { useCallback, useContext } from 'react'

import { MarkerStyled } from './AutocompleteGoogleStyle'
import {
  AccountContext,
  AuthContextType,
} from '../../../../../../contexts/AccountContext'
import { ICoordinates } from '../../hooks/useRegisterConsultory'

const containerStyle = {
  width: '100%',
  height: '45vh',
}

interface IPropsMap {
  map: google.maps.Map | null
  markerPosition: google.maps.LatLng | null
  zoom: number
  address: string
  mapCenter: ICoordinates
  setMap: React.Dispatch<React.SetStateAction<google.maps.Map | null>>
  setMarkerPosition: React.Dispatch<
    React.SetStateAction<google.maps.LatLng | null>
  >
  setZoom: React.Dispatch<React.SetStateAction<number>>
  setAddress: React.Dispatch<React.SetStateAction<string>>
  setMapCenter: React.Dispatch<React.SetStateAction<ICoordinates>>
  setSelected: React.Dispatch<React.SetStateAction<boolean>>
  isLoaded: boolean
}

export const MapsGoogle: React.FC<IPropsMap> = ({
  address,
  isLoaded,
  map,
  mapCenter,
  setSelected,
  markerPosition,
  setAddress,
  setMap,
  setMapCenter,
  setMarkerPosition,
  setZoom,
  zoom,
}: IPropsMap) => {
  const { handleAlert } = useContext(AccountContext) as AuthContextType

  const handleMapDrag = (): void => {
    if (map) {
      const newCenter = {
        lat: map.getCenter()?.lat() as number,
        lng: map.getCenter()?.lng() as number,
      }

      setMapCenter(newCenter)
      setZoom(map.getZoom()!)
    }
  }

  const onLoad = (map: google.maps.Map): void => {
    setMap(map)
  }

  const onClick = (e: google.maps.MapMouseEvent): void => {
    setMarkerPosition(e.latLng!)
    getAddressFromLatLng(e.latLng!)
    setSelected(true)
  }

  const getAddressFromLatLng = useCallback(
    async (latLng: google.maps.LatLng) => {
      try {
        const geocoder = new google.maps.Geocoder()

        await new Promise<google.maps.GeocoderResult[]>((resolve, reject) => {
          geocoder.geocode({ location: latLng }, function (results, status) {
            if (status === 'OK') {
              setAddress((results && results[0].formatted_address) || '')
            } else {
              reject(new Error(`Couldnt't find the location ${address}`))
            }
          })
        })
      } catch {
        handleAlert(true, 'Error al obtener la dirección', 'error')
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setAddress],
  )

  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={mapCenter}
      zoom={zoom}
      onClick={onClick}
      onLoad={onLoad}
      onDragEnd={handleMapDrag}
      onZoomChanged={handleMapDrag}
      options={{
        mapTypeControl: false,
        streetViewControl: false,
      }}
    >
      {markerPosition && (
        <OverlayView mapPaneName={'markerLayer'} position={markerPosition}>
          <MarkerStyled />
        </OverlayView>
      )}
    </GoogleMap>
  ) : (
    <div>Cargando mapa...</div>
  )
}

export default Map
