import { Add, Remove } from '@mui/icons-material'
import { SelectChangeEvent } from '@mui/material'
import { ChangeEvent } from 'react'

import EditPrescription from './components/Edit'
import { usePrescription } from './hook'
import {
  ActionsButton,
  DeleteIconPrescription,
  EditIconPrescription,
  GridTypo,
  InputQuantity,
  PrescriptionCard,
  PrescriptionTitle,
  QuantityButton,
  InputStyled,
  InputNumber,
} from './styles'
import SubmitButton from '../../../../../../../components/SubmitButton'
import {
  GridWrapper,
  TypographyWrapper,
  FormHelperTextWrapper,
  FormControlWrapper,
  SelectWrapper,
  MenuItemWrapper,
} from '../../../../../../../components/wrapper'
import { ModalTitle } from '../../../../../CalendarForm/Components/RemoveEventCalendar/RemoveEventCalendarStyles'

import { IPrescription } from '../../types'
import { GridWrapperModal } from '../Diagnostic/styles'



interface IDiagnosticProps {
  onNext(): void
  onBack(): void
  prescription?: IPrescription
  onChangePrescription(value: IPrescription): void
}

function Prescription({
  onNext,
  onBack,
  prescription,
  onChangePrescription,
}: IDiagnosticProps): JSX.Element {
  const {
    quantity,
    medication,
    dose,
    presentation,
    prescriptions,
    recipes,
    indexToEdit,
    disable,
    addButtonText,
    mu,
    Units,
    addQuantity,
    substractQuantity,
    setQuantity,
    setMedication,
    setDose,
    setPresentation,
    setPrescriptions,
    setMu,
    setDisable,
    addList,
    handleNext,
    handleBack,
    handleEdit,
    deleteRecipe,
    handleSaveEditPrescription,
  } = usePrescription({
    prescription,
    onChangePrescription,
    onNext,
    onBack,
  })

  return (
    <>
      <GridWrapperModal
        width={'100%'}
        height={'auto'}
        display={'flex'}
        marginBottom={2}
      >
        <GridWrapper width={'100%'}>
          <form data-testid="prescription-step" onSubmit={addList}>
            <ModalTitle id="transition-modal-title" variant="h6">
              Completa la receta
            </ModalTitle>
            <GridWrapper
              container
              spacing={3}
              direction={'row'}
              marginTop={'10px'}
              marginBottom={'20px'}
            >
              <GridWrapper item xs={6} sm={4}>
                <InputStyled
                  type="text"
                  id="medication"
                  value={medication}
                  aria-describedby="medication-helper-text"
                  placeholder="Acetaminofen"
                  inputProps={{
                    'aria-label': 'medication',
                  }}
                  onChange={(event: ChangeEvent<HTMLInputElement>) =>
                    setMedication(event.target.value)
                  }
                  disabled={disable}
                  fullWidth
                />
                <FormHelperTextWrapper
                  id="filled-medication-helper-text"
                  sx={{
                    fontSize: '11px',
                    '@media (min-width: 1600px)': {
                      fontSize: '14px',
                    },
                  }}
                >
                  Medicamento
                </FormHelperTextWrapper>
              </GridWrapper>
              <GridWrapper item xs={6} sm={3}>
                <GridWrapper display={'flex'} flexDirection={'row'} container>
                  <GridWrapper item xs={3.5}>
                    <InputNumber
                      type="number"
                      id="dose"
                      value={dose}
                      aria-describedby="dose-helper-text"
                      placeholder="10 mg"
                      inputProps={{
                        'aria-label': 'dose',
                      }}
                      onChange={(event: ChangeEvent<HTMLInputElement>) =>
                        setDose(event.target.value)
                      }
                      disabled={disable}
                    />
                  </GridWrapper>
                  <GridWrapper item xs={8.5}>
                    <FormControlWrapper fullWidth variant="standard">
                      <SelectWrapper
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={mu}
                        label="Age"
                        placeholder="Ml"
                        sx={{
                          fontSize: '12px',
                          '@media (min-width: 1600px)': {
                            fontSize: '16px',
                          },
                        }}
                        onChange={(event: SelectChangeEvent<unknown>) =>
                          setMu(event.target.value as string)
                        }
                      >
                        {Units.map((u, i) => (
                          <MenuItemWrapper key={`units-${i}`} value={u.value}>
                            {u.text}
                          </MenuItemWrapper>
                        ))}
                      </SelectWrapper>
                    </FormControlWrapper>
                  </GridWrapper>
                </GridWrapper>
                <FormHelperTextWrapper id="filled-dose-helper-text">
                  Dosis
                </FormHelperTextWrapper>
              </GridWrapper>
              <GridWrapper item xs={6} sm={2}>
                <InputStyled
                  type="text"
                  id="presentation"
                  value={presentation}
                  aria-describedby="presentation-helper-text"
                  placeholder="Tabletas"
                  inputProps={{
                    'aria-label': 'presentation',
                  }}
                  onChange={(event: ChangeEvent<HTMLInputElement>) =>
                    setPresentation(event.target.value)
                  }
                  disabled={disable}
                />
                <FormHelperTextWrapper id="filled-presentation-helper-text">
                  Presentación
                </FormHelperTextWrapper>
              </GridWrapper>
              <GridWrapper
                item
                display={'flex'}
                flexDirection={'row'}
                xs={6}
                sm={2}
              >
                <QuantityButton
                  id="substrac-quantity"
                  variant="outlined"
                  size="small"
                  onClick={substractQuantity}
                  disabled={disable}
                >
                  <Remove />
                </QuantityButton>
                <GridWrapper>
                  <InputQuantity
                    value={quantity}
                    type="number"
                    id="number"
                    aria-describedby="number-helper-text"
                    inputProps={{
                      'aria-label': 'number',
                    }}
                    onChange={(event: ChangeEvent<HTMLInputElement>) =>
                      setQuantity(event.target.value)
                    }
                  />
                  <FormHelperTextWrapper id="filled-number-helper-text">
                    Cantidad
                  </FormHelperTextWrapper>
                </GridWrapper>
                <QuantityButton
                  id="add-quantity"
                  variant="outlined"
                  size="small"
                  onClick={addQuantity}
                  disabled={disable}
                >
                  <Add />
                </QuantityButton>
              </GridWrapper>
            </GridWrapper>
            <GridWrapper container spacing={{ xs: 1, sm: 3 }} direction={'row'}>
              <GridWrapper item xs={7} md={8}>
                <InputStyled
                  type="text"
                  id="prescriptions"
                  value={prescriptions}
                  aria-describedby="prescriptions-helper-text"
                  placeholder="Tomar cada 8 horas durante 3 días"
                  inputProps={{
                    'aria-label': 'prescriptions',
                    maxLength: 151,
                  }}
                  fullWidth
                  onChange={(event: ChangeEvent<HTMLInputElement>) =>
                    setPrescriptions(event.target.value)
                  }
                  error={prescriptions.length > 150}
                  disabled={disable}
                />
                {prescriptions.length > 150 && (
                  <FormHelperTextWrapper
                    id="error-prescriptions-helper-text"
                    sx={{ color: 'primary.dark' }}
                  >
                    Máximo número de caracteres 150
                  </FormHelperTextWrapper>
                )}
                <FormHelperTextWrapper id="prescriptions-helper-text">
                  Indicaciones
                </FormHelperTextWrapper>
              </GridWrapper>
              <GridWrapper item xs={5} sm={4} md={3} paddingLeft={10}>
                <SubmitButton
                  data-testid="add-button"
                  id="add-button"
                  type="submit"
                  variant="contained"
                  fullWidth
                  text={addButtonText}
                  sx={{ height: '6ch', backgroundColor: 'primary.main' }}
                  disabled={
                    medication?.length < 5 ||
                    !quantity ||
                    Number(quantity) < 1 ||
                    !dose ||
                    !mu ||
                    prescriptions?.length > 150 ||
                    disable
                  }
                />
              </GridWrapper>
            </GridWrapper>
          </form>
        </GridWrapper>
      </GridWrapperModal>
      <GridWrapper marginTop={'60px'} marginBottom={'30px'} width={'100%'}>
        <PrescriptionTitle id="transition-modal-title" variant="h6">
          RP.
        </PrescriptionTitle>
        <GridWrapper
          maxHeight="260px"
          sx={{ overflowY: 'scroll', height: 'auto' }}
          position={'relative'}
          paddingX={'8px'}
        >
          {recipes?.length > 0 && (
            <GridWrapper
              container
              alignItems={'center'}
              paddingX={'5px'}
              spacing={2}
            >
              <GridTypo item xs={2.5}>
                <TypographyWrapper
                  noWrap
                  sx={{
                    fontSize: '12px',
                    '@media (min-width: 1600px)': {
                      fontSize: '16px',
                    },
                  }}
                >
                  Medicamento
                </TypographyWrapper>
              </GridTypo>
              <GridTypo item xs={1}>
                <TypographyWrapper
                  noWrap
                  sx={{
                    fontSize: '12px',
                    '@media (min-width: 1600px)': {
                      fontSize: '16px',
                    },
                  }}
                >
                  Dosis
                </TypographyWrapper>
              </GridTypo>
              <GridTypo item xs={2}>
                <TypographyWrapper
                  noWrap
                  sx={{
                    fontSize: '12px',
                    '@media (min-width: 1600px)': {
                      fontSize: '16px',
                    },
                  }}
                >
                  Presentación
                </TypographyWrapper>
              </GridTypo>
              <GridTypo
                item
                xs={1.5}
                textAlign={'center'}
                sx={{ '&.MuiGrid-item': { paddingLeft: '0px' } }}
              >
                <TypographyWrapper
                  noWrap
                  sx={{
                    fontSize: '12px',
                    '@media (min-width: 1600px)': {
                      fontSize: '16px',
                    },
                  }}
                >
                  Cantidad
                </TypographyWrapper>
              </GridTypo>
              <GridTypo item xs={3}>
                <TypographyWrapper
                  noWrap
                  sx={{
                    fontSize: '12px',
                    '@media (min-width: 1600px)': {
                      fontSize: '16px',
                    },
                  }}
                >
                  Indicaciones
                </TypographyWrapper>
              </GridTypo>
            </GridWrapper>
          )}
          {recipes?.map((current, index) =>
            indexToEdit === index ? (
              <EditPrescription
                current={current}
                handleEditPrescription={handleSaveEditPrescription}
                setDisable={setDisable}
                key={`edit-prescription-${index}`}
                units={Units}
              />
            ) : (
              <PrescriptionCard
                key={`show-prescription-${index}`}
                data-testid="prescription-item"
              >
                <GridWrapper container alignItems={'center'} spacing={2}>
                  <GridTypo item xs={2.5}>
                    <TypographyWrapper
                      noWrap
                      sx={{
                        fontSize: '12px',
                        '@media (min-width: 1600px)': {
                          fontSize: '16px',
                        },
                      }}
                    >
                      {current?.medicine}
                    </TypographyWrapper>
                  </GridTypo>
                  <GridTypo item xs={1}>
                    <TypographyWrapper
                      noWrap
                      sx={{
                        fontSize: '12px',
                        '@media (min-width: 1600px)': {
                          fontSize: '16px',
                        },
                      }}
                    >
                      {current?.dose}
                    </TypographyWrapper>
                  </GridTypo>
                  <GridTypo item xs={2}>
                    <TypographyWrapper
                      noWrap
                      sx={{
                        fontSize: '12px',
                        '@media (min-width: 1600px)': {
                          fontSize: '16px',
                        },
                      }}
                    >
                      {current?.presentation}
                    </TypographyWrapper>
                  </GridTypo>
                  <GridTypo
                    item
                    xs={1.5}
                    textAlign={'center'}
                    sx={{ '&.MuiGrid-item': { paddingLeft: '0px' } }}
                  >
                    <TypographyWrapper
                      noWrap
                      sx={{
                        fontSize: '12px',
                        '@media (min-width: 1600px)': {
                          fontSize: '16px',
                        },
                      }}
                    >
                      {current?.quantity}
                    </TypographyWrapper>
                  </GridTypo>
                  <GridTypo item xs={3}>
                    <TypographyWrapper
                      noWrap
                      sx={{
                        fontSize: '12px',
                        '@media (min-width: 1600px)': {
                          fontSize: '16px',
                        },
                      }}
                    >
                      {current?.prescription}
                    </TypographyWrapper>
                  </GridTypo>
                  <GridWrapper item xs={2}>
                    <ActionsButton id="edit" onClick={() => handleEdit(index)}>
                      <EditIconPrescription />
                    </ActionsButton>
                    <ActionsButton
                      id="delete"
                      onClick={() => deleteRecipe(index)}
                    >
                      <DeleteIconPrescription />
                    </ActionsButton>
                  </GridWrapper>
                </GridWrapper>
              </PrescriptionCard>
            ),
          )}
        </GridWrapper>
      </GridWrapper>
      <GridWrapper
        sx={{
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <SubmitButton
          id={''}
          variant={'contained'}
          data-testid="back-button"
          fullWidth
          type={'button'}
          text={'Regresar'}
          sx={{ width: '25%', margin: '0 20px', backgroundColor: '#6191FE' }}
          onClick={handleBack}
          disableElevation
          disabled={disable}
        />
        <SubmitButton
          id={''}
          variant={'contained'}
          data-testid="next-button"
          fullWidth
          type={'button'}
          text={'Siguiente'}
          sx={{ width: '25%', margin: '0 20px' }}
          onClick={handleNext}
          disableElevation
          disabled={recipes.length === 0 || disable}
        />
      </GridWrapper>
    </>
  )
}

export default Prescription
