import ImportExportIcon from '@mui/icons-material/ImportExport'
import SearchIcon from '@mui/icons-material/Search'
import { linearProgressClasses, styled } from '@mui/material'

import {
  AvatarWrapper,
  BoxWrapper,
  ButtonWrapper,
  CardWrapper,
  LinearProgressWrapper,
  SelectWrapper,
  TextFieldWrapper,
  ToolbarWrapper,
} from '../../../../../components/wrapper'

export const DateRangeInput = styled(BoxWrapper)((props) => ({
  width: '220px',
  borderRadius: '42px',
  backgroundColor: props.theme.palette.background.default,
  height: '42px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  '& fieldset': { border: 'none' },
  '@media (min-width: 1601px)': {
    width: '240px',
  },
  '& svg': {
    color: props.theme?.palette?.tertiary?.main,
    fontSize: '14px',
  },
  '& .rs-picker-toggle-value, & input::placeholder , & input': {
    fontSize: '14px',
    color: props.theme?.palette?.tertiary?.main,
    '@media (min-width: 1601px)': {
      fontSize: '16px',
    },
  },
  '& .rs-picker-toggle-placeholder ': {
    fontSize: '14px',
    color: props.theme?.palette.text?.primary,
    '@media (min-width: 1601px)': {
      fontSize: '16px',
    },
  },

  '& .rs-picker-toggle-value': {
    color: `${props.theme?.palette?.tertiary?.main} !important`,
  },
}))

export const SearchInput = styled(TextFieldWrapper)((props) => ({
  width: '170px',
  borderRadius: '52px',
  backgroundColor: props.theme.palette.background.paper,
  height: '42px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  '& fieldset': { border: 'none' },
  '& input': {
    fontSize: '14px',
    '@media (min-width: 1601px)': {
      fontSize: '16px',
    },
    color: props.theme?.palette?.common?.black,
  },
}))

export const IconSearch = styled(SearchIcon)((props) => ({
  color: props.theme.palette.primary.dark,
}))

export const SelectInput = styled(SelectWrapper)((props) => ({
  minWidth: '170px',
  borderRadius: '52px',
  backgroundColor: props.theme.palette.background.paper,
  height: '36px',
  '& fieldset': { border: 'none' },
  padding: '21px 2px',
  fontSize: '14px',
  color: props.theme?.palette?.common?.black,
}))

export const SortIcon = styled(ImportExportIcon)((props) => ({
  color: props.theme.palette.primary.dark,
  zIndex: 1,
}))

export const ControlBar = styled(ToolbarWrapper)((props) => ({
  backgroundColor: props.theme.palette.background.default,
}))

export const ProgressRate = styled(LinearProgressWrapper)(({ theme }) => ({
  width: '100%',
  height: 10,
  borderRadius: 5,
  backgroundColor: theme.palette.grey[300],
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.primary,
  },
}))

export const CardQualification = styled(CardWrapper)(() => ({
  width: '100%',
  border: 'none',
  height: '100%',
  borderRadius: '32px',
  padding: '16px 24px',
  color: 'black',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
}))

export const AvatarQualification = styled(AvatarWrapper)(() => ({
  width: '100px',
  height: '100px',
  marginBottom: '5px',
}))

interface IButtonProps {
  status: string
}

export const HiddenCommentButton = styled(ButtonWrapper)<IButtonProps>(
  ({ theme, status }) => ({
    borderRadius: '24px',
    backgroundColor: ['NOT_VISIBLE'].includes(status)
      ? 'inherit'
      : theme.palette.primary.main,
    color: ['NOT_VISIBLE'].includes(status)
      ? theme.palette.primary.main
      : 'white',
    ':hover': {
      color: theme.palette.primary.main,
    },
    '&.Mui-disabled': {
      backgroundColor: theme.palette.common.white,
    },
  }),
)
