import {
  IApiResponseAppointment,
  IAppointmentDetails,
  IAppointmentInfoResponse,
  IAppointmentsByDoctorIdParams,
  IAppointmentsByDoctorIdResponse,
  IGetAppointmentProps,
  IResponseAppointment,
  ISaveNextAppointment,
  ISaveNextAppointmentParams,
} from '../../infrastructure/dtos/Appointments'
import { AppointmentRepository } from '../repository/AppointmentRepository'

export interface IGetAppointmentUseCase {
  invoke: (
    params: IGetAppointmentProps,
  ) => Promise<IApiResponseAppointment<IResponseAppointment>>
}

export interface IGetAppointmentDetailsUseCase {
  invoke: (
    appointmentId: string,
  ) => Promise<IApiResponseAppointment<IAppointmentDetails>>
}

export interface IGetAppointmentInfoUseCase {
  invoke: (
    appointmentId: string,
  ) => Promise<IApiResponseAppointment<IAppointmentInfoResponse>>
}

export interface ISaveNextAppointmentUseCase {
  invoke: (
    params: ISaveNextAppointmentParams,
  ) => Promise<IApiResponseAppointment<ISaveNextAppointment>>
}

interface IGetAppointmentsByDoctorIdUseCase {
  invoke: (
    params: IAppointmentsByDoctorIdParams
  ) => Promise<IApiResponseAppointment<IAppointmentsByDoctorIdResponse>>
}

export class GetAppointment implements IGetAppointmentUseCase {
  private objectUseCase: AppointmentRepository

  constructor(_objectUseCase: AppointmentRepository) {
    this.objectUseCase = _objectUseCase
  }

  async invoke(
    params: IGetAppointmentProps,
  ): Promise<IApiResponseAppointment<IResponseAppointment>> {
    return this.objectUseCase.getAppointment(params)
  }
}

export class GetAppointmentDetails implements IGetAppointmentDetailsUseCase {
  private objectUseCase: AppointmentRepository

  constructor(_objectUseCase: AppointmentRepository) {
    this.objectUseCase = _objectUseCase
  }

  async invoke(
    appointmentId: string,
  ): Promise<IApiResponseAppointment<IAppointmentDetails>> {
    return this.objectUseCase.getAppointmentDetails(appointmentId)
  }
}

export class GetAppointmentInfo implements IGetAppointmentInfoUseCase {
  private objectUseCase: AppointmentRepository

  constructor(_objectUseCase: AppointmentRepository) {
    this.objectUseCase = _objectUseCase
  }

  async invoke(
    appointmentId: string,
  ): Promise<IApiResponseAppointment<IAppointmentInfoResponse>> {
    return this.objectUseCase.getAppointmentInfo(appointmentId)
  }
}

export class SaveNextAppointment implements ISaveNextAppointmentUseCase {
  private objectUseCase: AppointmentRepository

  constructor(_objectUseCase: AppointmentRepository) {
    this.objectUseCase = _objectUseCase
  }

  async invoke(
    params: ISaveNextAppointmentParams,
  ): Promise<IApiResponseAppointment<ISaveNextAppointment>> {
    return this.objectUseCase.saveNextAppointment(params)
  }
}

export class GetAppointmentsByDoctorId implements IGetAppointmentsByDoctorIdUseCase {
  private objectUseCase: AppointmentRepository

  constructor(_objectUseCase: AppointmentRepository) {
    this.objectUseCase = _objectUseCase
  }

  async invoke(
    params: IAppointmentsByDoctorIdParams
  ): Promise<IApiResponseAppointment<IAppointmentsByDoctorIdResponse>> {
    return this.objectUseCase.getAppointmentsByDoctorId(params)
  }

}
