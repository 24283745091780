import { Close as CloseIcon } from '@mui/icons-material'

import React from 'react'

import { useResetAppointment } from './hooks/hook'
import {
  BoxResetModal,
  CloseButton,
  FadeResetModal,
  TypographyResetModal,
} from './ResetAppointmentStyles'
import {
  BackdropWrapper,
  GridWrapper,
  ModalWrapper,
} from '../../../../../components'
import { SecondaryMediumButton } from '../../../../../components/SecondaryMediumButton/SecondaryMediumButton'
import SubmitButton from '../../../../../components/SubmitButton'

interface IResetAppointmentsProps {
  handleClose: () => void
  open: boolean
  appointmentId: string
  handleAfterSubmit: () => void
}

export const ResetAppointmentModal = ({
  handleClose,
  open,
  appointmentId,
  handleAfterSubmit,
}: IResetAppointmentsProps): React.JSX.Element => {
  const { loading, fetchResetAssistance } = useResetAppointment({
    handleAfterSubmit,
    handleClose,
    appointmentId,
  })

  return (
    <ModalWrapper
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      data-testid="modal-cancel-appointment"
      open={open}
      onClose={handleClose}
      closeAfterTransition
      slots={{ backdrop: BackdropWrapper }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <FadeResetModal in={open}>
        <BoxResetModal width={{ xs: '90%', md: '600px' }}>
          <GridWrapper padding={5}>
            <CloseButton onClick={handleClose}>
              <CloseIcon />
            </CloseButton>
            <GridWrapper>
              <TypographyResetModal
                id="transition-modal-title"
                variant="h6"
                data-testid="modal-cancel-appointment-title"
              >
                ¿Deseas eliminar la marcación de esta cita?
              </TypographyResetModal>
            </GridWrapper>
            <GridWrapper
              container
              display={'flex'}
              justifyContent={'center'}
              flexDirection={'row'}
            >
              <SecondaryMediumButton
                id="keppAppointmentButton"
                data-testid="keppAppointmentButton"
                text={'Sí, eliminar'}
                type="button"
                variant="contained"
                sx={{ width: '35%', marginY: '45px', marginX: '15px' }}
                onClick={fetchResetAssistance}
                disableElevation
                disabled={loading}
              />
              <SubmitButton
                id="cancelAppointmentButton"
                text={'No, mantener'}
                type="button"
                variant="contained"
                fullWidth
                sx={{
                  width: '35%',
                  marginY: '45px',
                  marginX: '15px',
                  fontSize: '14px',
                }}
                disableElevation
                onClick={handleClose}
                disabled={loading}
              />
            </GridWrapper>
          </GridWrapper>
        </BoxResetModal>
      </FadeResetModal>
    </ModalWrapper>
  )
}
