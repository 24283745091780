import { styled } from '@mui/material'

import {
  TextFieldWrapper,
  TypographyWrapper,
} from '../../../../../../../components'

export const SubHeaderTitle = styled(TypographyWrapper)(({ theme }) => ({
  fontFamily: 'Helvetica',
  fontSize: '12px',
  fontWeight: 'bold',
  fontStretch: 'normal',
  fontStyle: 'normal',
  lineHeight: 'normal',
  letterSpacing: 'normal',
  textAlign: 'left',
  color: theme?.palette?.primary.dark,
  marginBottom: '10px',
  '@media (min-width: 1600px)': {
    fontSize: '16px',
  },
}))

export const TextAreaField = styled(TextFieldWrapper)(({ theme }) => ({
  width: '100%',
  '& .MuiInputBase-root': {
    height: '250px',
    width: '100%',
    borderRadius: '3.4375rem',
    backgroundColor: theme.palette.common.white,
    fontSize: '1.125rem',
    color: theme.palette?.text.primary,
    fontFamily: 'Helvetica',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    boxShadow: '1px 1px 7px 7px rgba(0, 0, 0, 0.03)',
  },
  '& .MuiInputBase-input': {
    height: '250px',
  },
  '& fieldset': { borderColor: theme.palette.common.white },
  '&::placeholder': {
    marginLeft: '1.875rem',
  },
  '& .MuiInputBase-root.Mui-disabled': {
    '& > fieldset': {
      borderColor: theme.palette.common.white,
    },
  },
}))
