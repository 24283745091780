import {
  GetSkillsUtilityResponse,
  IApiResponseGetSkills,
} from '../../../infrastructure/dtos/Skills'
import { getSkillsService } from '../Persistencia/SkillsService'

export const getSkillsUtility = async (
  query: string,
): Promise<GetSkillsUtilityResponse> => {
  try {
    const { data } = await getSkillsService(query)

    return {
      status: (data as IApiResponseGetSkills).statusCode === 200 ? 1 : 0,
      data: (data as IApiResponseGetSkills).body,
    }
  } catch (error: unknown) {
    return {
      status: 0,
      data: {
        message: (error as Error)?.message,
        data: [],
      },
    }
  }
}
