import { GetVisibilityAppointmentTime } from '../../../domain/useCase/GetVisibilityAppointmentTime'
import { IApiResponseVisibilityAppointmentTimeAWS } from '../../../infrastructure/dtos/VisibilityAppointmentTime'
import VisibilityAppointmentTimeMapper from '../../../infrastructure/http/mappers/VisibilityAppointmentTimeMapper/VisibilityAppointmentTimeMapper'
import { VisibilityAppointmentTimeRepositoryImpl } from '../../../infrastructure/RepositoryImpl/VisibilityAppointmentTimeRepositoryImpl'

interface IVisibilityAppointmentTimeData {
  status: number
  data: IApiResponseVisibilityAppointmentTimeAWS
}

export async function GetVisibilityAppointmentTimeService(): Promise<IVisibilityAppointmentTimeData> {
  try {
    const UseCaseRegistro: GetVisibilityAppointmentTime =
      new GetVisibilityAppointmentTime(
        new VisibilityAppointmentTimeRepositoryImpl(
          new VisibilityAppointmentTimeMapper(),
        ),
      )

    return {
      status: 1,
      data: await UseCaseRegistro.invoke(),
    }
  } catch (error) {
    return {
      status: 0,
      data: {
        statusCode: 503,
        body: (error as Error).message,
        headers: '',
      },
    }
  }
}
