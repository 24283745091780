import { useState } from 'react'

import {
  AutocompleteStyled,
  FormControlStyled,
  TextFieldAutocomplete,
} from './styles'
import { FormHelperTextWrapper } from '../wrapper'
interface IComponent {
  id: string
  label: string
  list: string[]
  value: string
  setValue: (valueData: string) => void
  error?: boolean
  message?: string
  disabled?: boolean
  datatestid?: string
  minWidth?: string
  placeholder?: string
  freeSolo?: boolean
}

const AutocompleteComponent = ({
  id,
  label,
  error,
  list,
  message,
  setValue,
  value,
  disabled = false,
  datatestid,
  placeholder,
  freeSolo = false,
}: IComponent): JSX.Element => {
  const [dataSelect, setDataSelect] = useState<string | null>(value)
  return (
    <FormControlStyled error={error}>
      <AutocompleteStyled
        freeSolo={freeSolo}
        forcePopupIcon={false}
        value={dataSelect || null}
        onChange={(
          event: React.SyntheticEvent<Element, Event>,
          value: unknown,
        ) => {
          setDataSelect(value as string)
        }}
        inputValue={value}
        onInputChange={(event, newInputValue) => {
          setValue(newInputValue)
        }}
        id={id}
        options={list}
        disabled={disabled}
        disableClearable
        renderInput={(params) => {
          return (
            <TextFieldAutocomplete
              {...params}
              label={label}
              variant="outlined"
              fullWidth
              data-testid={datatestid}
              placeholder={placeholder}
            />
          )
        }}
      />
      {error && <FormHelperTextWrapper>{message}</FormHelperTextWrapper>}
    </FormControlStyled>
  )
}

export default AutocompleteComponent
