import { YearsExperienceRepository } from '../../domain';
import { IApiResponseYearsExperience } from '../dtos';
import YearsExperienceDataSource from '../http/dataSource/YearsExperienceDataSource';


export class YearsExperienceRepositoryImpl implements YearsExperienceRepository {
  dataSource: YearsExperienceDataSource;
  
  constructor( _datasource: YearsExperienceDataSource ) {
    this.dataSource = _datasource;
  }
  
  async getYearsExperience(): Promise<IApiResponseYearsExperience> {
    return this.dataSource.getYearsExperience();
  }
}
  