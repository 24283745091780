import { AxiosError } from 'axios'

import {
  IApiResponseCatalog,
  IResponseNotification,
  IResponseParameters,
} from '../../../dtos/Catalog'
import CatalogDataSource from '../../dataSource/CatalogDataSource'
import { http } from '../../Http'

export default class CatalogMapper implements CatalogDataSource {
  async getCatalogNotification(
    item: string,
  ): Promise<IApiResponseCatalog<IResponseNotification>> {
    try {
      const { data } = await http.get<
        IApiResponseCatalog<IResponseNotification>
      >(`/catalog/notification/${item}`)

      return data
    } catch (error: unknown) {
      return {
        statusCode: (error as AxiosError)?.response?.status as number,
        body: (error as AxiosError)?.response?.statusText as string,
        headers: {
          'Access-Control-Allow-Headers': '',
          'Access-Control-Allow-Origin': '',
          'Access-Control-Allow-Methods': '',
        },
      }
    }
  }

  async getCatalogParameters(
    item: string,
  ): Promise<IApiResponseCatalog<IResponseParameters>> {
    try {
      const { data } = await http.get<IApiResponseCatalog<IResponseParameters>>(
        `/catalog/parameters/${item}`,
      )

      return data
    } catch (error: unknown) {
      return {
        statusCode: (error as AxiosError)?.response?.status as number,
        body: (error as AxiosError)?.response?.statusText as string,
        headers: {
          'Access-Control-Allow-Headers': '',
          'Access-Control-Allow-Origin': '',
          'Access-Control-Allow-Methods': '',
        },
      }
    }
  }
}
