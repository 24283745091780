import { useState } from 'react'

interface IAttendance {
  openAttendance: boolean
  handleCloseAttendance: () => void
  handleOpenAttendance: () => void
}

export function useAttendance(): IAttendance {
  const [openAttendance, setOpenAttendance] = useState<boolean>(false)

  const handleOpenAttendance = (): void => {
    setOpenAttendance(true)
  }

  const handleCloseAttendance = (): void => setOpenAttendance(false)

  return {
    handleOpenAttendance,
    handleCloseAttendance,
    openAttendance,
  }
}
