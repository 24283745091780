import { Fade, FadeProps } from '@mui/material'
import React from 'react'

type FadeWrapperProps = Omit<FadeProps, 'ref'> &
  React.RefAttributes<HTMLElement>

const FadeWrapper: React.ForwardRefExoticComponent<FadeWrapperProps> =
  React.forwardRef<HTMLElement, FadeProps>((props, ref) => (
    <Fade {...props} ref={ref} />
  ))

export default FadeWrapper
