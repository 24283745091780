import { styled } from '@mui/material/styles'

import {
  ButtonWrapper,
  CheckboxWrapper,
  DialogTitleWrapper,
  FormControlLabelWrapper,
  LinkWrapper,
  TextFieldWrapper,
  TypographyWrapper
} from '../../../components'

export const ButtonLogin = styled( ButtonWrapper )(( props ) => ({
  borderRadius: '2.625rem',
  backgroundColor: props.theme.palette.primary.dark,
  fontSize: '14px',
  height: '3.4375rem',
  textTransform: 'none'
}))

export const ButtonCorreo = styled( ButtonWrapper )(( props ) => ({
  width: '95%',
  borderRadius: '2.625rem',
  backgroundColor: props.theme.palette.primary.main,
  fontSize: '14px',
  height: '3.4375rem',
  textTransform: 'none'
}))

export const TextFielRegister = styled( TextFieldWrapper )(( props ) => ({
  '& .MuiInputLabel-root': {},
  '& .MuiInputBase-root': {
    borderRadius: '3.4375rem',
    backgroundColor: '#fff',
    fontSize: '1.125rem',
    color: props.theme.palette?.text.primary,
    fontFamily: 'Helvetica',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    height: '3.4375rem'
  },
  '& fieldset': { borderColor: 'white' },
  '&::placeholder': {
    marginLeft: '1.875rem'
  }
}))

export const CheckboxRegister = styled( CheckboxWrapper )(() => ({
  borderRadius: '0.5rem'
}))

export const LinkWrapperAuth = styled( LinkWrapper )(( props ) => ({
  color: props.theme.palette.primary.dark,
  fontSize: '14px',
  cursor: 'pointer'
}))

export const TypographyWrapperAuth = styled( TypographyWrapper )(( props ) => ({
  color: props.theme.palette.primary.dark,
  fontSize: '20px',
  fontFamily: 'Helvetica',
  fontWeight: 'normal',
  fontStretch: 'normal',
  fontStyle: 'normal',
  lineHeight: 'normal',
  letterSpacing: 'normal',
  textAlign: 'center'
}))

export const TypographyWrapperAuthTitulo = styled( TypographyWrapper )(() => ({
  color: ' #000',
  fontSize: '18px',
  fontFamily: 'Helvetica',
  fontWeight: 'bold',
  fontStretch: 'normal',
  fontStyle: 'normal',
  lineHeight: 'normal',
  letterSpacing: 'normal'
}))

export const TypographyWrapperAuthLabel = styled( TypographyWrapper )(() => ({
  textAlign: 'left',
  color: ' #000',
  fontSize: '14px',
  fontFamily: 'Helvetica',
  fontWeight: 'bold',
  fontStretch: 'normal',
  fontStyle: 'normal',
  lineHeight: 'normal',
  letterSpacing: 'normal',
  marginBottom: '0.6875rem',
  marginLeft: '2rem'
}))

export const TypographyWrapperValidationTrue = styled( TypographyWrapper )(
  () => ({
    color: ' #75c44c',
    fontSize: '0.8rem',
    fontFamily: 'Helvetica',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  })
)
export const TypographyWrapperValidationFalse = styled( TypographyWrapper )(
  () => ({
    color: ' #7d7d7d',
    fontSize: '0.8rem',
    fontFamily: 'Helvetica',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    textAlign: 'left',
    minWidth: '200px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  })
)

export const TypographyWrapperValidationEmailFalse = styled( TypographyWrapper )(
  () => ({
    color: '#d32f2f',
    fontSize: '0.75rem',
    fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    textAlign: 'left',
    marginLeft: '14px',
    marginTop: '3px',
    marginRight: '14px'
  })
)

export const TypographyWrapperValidationRed = styled( TypographyWrapper )(() => ({
  color: ' red',
  fontSize: '12px'
}))

export const FormControlLabelWrapperAuth = styled( FormControlLabelWrapper )(
  ( props ) => ({
    color: props.theme.palette?.text.primary,
    fontSize: '0.875rem',
    fontFamily: 'Helvetica',
    fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    textAlign: 'left'
  })
)

export const DialogTitleStyle = styled( DialogTitleWrapper )(() => ({
  cursor: 'move'
}))
