
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { SxProps } from '@mui/material'
interface IWarningIconProps {
  sx?: SxProps
}

const WarningIcon = ({sx={}}:IWarningIconProps): JSX.Element => <ErrorOutlineIcon sx={sx}/>;

export default WarningIcon;
