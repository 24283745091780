import { SearchCie10Repository } from '../../domain/repository/SearchCie10Repository';
import { IApiResponseSearchCie10 } from '../dtos/SearchCie10';
import SearchCie10DataSource from '../http/dataSource/SearchCie10DataSource';

export class SearchCie10RepositoryImpl implements SearchCie10Repository {
  dataSource: SearchCie10DataSource;

  constructor( _dataSource: SearchCie10DataSource ) {
    this.dataSource = _dataSource;
  }

  async getSearchCie10( query: string ): Promise<IApiResponseSearchCie10> {
    return this.dataSource.getSearchCie10( query );
  }
}
