import { AxiosError } from 'axios'

import { IPrescriptionsDataSource } from '../../..'
import {
  IApiPrescriptionInfo,
  IRecipePrescription,
  IResponseSavePrescription,
  IResponseSendPrescription,
  ISavePrescriptionProps,
  ISendPrescriptionProps,
} from '../../../dtos/Prescriptions'
import { http } from '../../Http'

export default class PrescriptionsMapper implements IPrescriptionsDataSource {
  async savePrescription(
    prescription: ISavePrescriptionProps,
  ): Promise<IResponseSavePrescription> {
    try {
      const { data } = await http.post<IResponseSavePrescription>(
        'save_data/prescription',
        {
          body: prescription,
        },
      )

      return data
    } catch (error: unknown) {
      const data: IResponseSavePrescription = {
        statusCode: (error as AxiosError).response?.status as number,
        body: { message: (error as AxiosError).response?.statusText as string },
        headers: {
          'Access-Control-Allow-Headers': '',
          'Access-Control-Allow-Origin': '',
          'Access-Control-Allow-Methods': '',
        },
      }

      return data
    }
  }

  async sendAppointmentRecipe(
    body: ISendPrescriptionProps,
  ): Promise<IResponseSendPrescription> {
    try {
      const { data } = await http.post<IResponseSendPrescription>(
        `notification/medical_prescription/${body.appointment_id}`,
        body,
      )
      return data
    } catch (error: unknown) {
      return {
        statusCode: (error as AxiosError).response?.status as number,
        body: (error as AxiosError).response?.statusText as string,
        headers: {
          'Access-Control-Allow-Headers': '',
          'Access-Control-Allow-Origin': '',
          'Access-Control-Allow-Methods': '',
        },
      }
    }
  }

  async getAppointmentRecipes(
    appointmentId: string,
  ): Promise<IApiPrescriptionInfo<IRecipePrescription | string>> {
    try {
      const { data } = await http.get<
        IApiPrescriptionInfo<IRecipePrescription>
      >(`send_data/prescription/${appointmentId}`)
      return data
    } catch (error: unknown) {
      return {
        statusCode: (error as AxiosError).response?.status as number,
        body: (error as AxiosError).response?.statusText as string,
        headers: {
          'Access-Control-Allow-Headers': '',
          'Access-Control-Allow-Origin': '',
          'Access-Control-Allow-Methods': '',
        },
      }
    }
  }
}
