import React from 'react'

import { GridWrapper, TypographyWrapper } from '../../../../../../../components'
import { GridItem } from '../../../../../../../components/GridItem/GridItem'
import { Title } from '../../../../../../../components/StyledComponents/Title'
import CircularProgressWrapper from '../../../../../../../components/wrapper/LoaderWrapper'
import { IAppointmentInfo } from '../../../../../../../infrastructure/dtos/Appointments'
import { IBodyPatientInfo } from '../../../../../../../infrastructure/dtos/Patient'
import { AvatarPatientProfile } from '../MedicalRecord/MedicalRecordStyle'

interface IPatientInfoProps {
  gridInfoBody: {
    value: string
    title: string
  }[]
  loadingPatient: boolean
  patient: IBodyPatientInfo | undefined
  nonRegistered: IAppointmentInfo | undefined
}

export const PatientInfo = ({
  patient,
  loadingPatient,
  gridInfoBody,
  nonRegistered,
}: IPatientInfoProps): JSX.Element => {
  return (
    <>
      {loadingPatient ? (
        <GridWrapper
          sx={{
            paddingTop: '50px',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <CircularProgressWrapper
            id="loading-spinner"
            size={'3rem'}
            data-testid="loading-spinner_patient"
          />
        </GridWrapper>
      ) : (
        <GridWrapper item xs={12} md={5} px={2}>
          <GridWrapper
            container
            display={'flex'}
            justifyContent={'center'}
            padding={4}
            sx={{
              minHeight: '300px',
              backgroundColor: 'Background',
              borderRadius: '42px',
            }}
          >
            <GridItem item xs={12} textAlign={'center'}>
              <Title
                sx={{ marginTop: 0, fontSize: '22px' }}
                data-testid="patient-name"
              >
                {patient?.age
                  ? `${patient?.name_patient} ${patient?.last_name_patient} - ${patient?.age} años`
                  : `${nonRegistered?.patient_name} ${nonRegistered?.patient_last_name}`}
              </Title>
            </GridItem>
            <GridItem item xs={12}>
              <AvatarPatientProfile src={patient?.photo_patient} />
            </GridItem>

            {gridInfoBody.map(
              (
                item: {
                  value: string
                  title: string
                },
                index: number,
              ) => (
                <GridWrapper item xs={12} md={6} key={`infoBody-${index}`}>
                  <Title
                    sx={{
                      marginBottom: 0,
                      fontSize: '13px',
                      '@media (min-width: 1600px)': {
                        fontSize: '17px',
                      },
                    }}
                    data-testid="dni-number"
                  >
                    {item.title}
                  </Title>
                  <TypographyWrapper
                    variant="subtitle2"
                    color={'lightgray'}
                    sx={{
                      fontSize: '12px',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      '@media (min-width: 1600px)': {
                        fontSize: '16px',
                      },
                    }}
                  >
                    {item.value}
                  </TypographyWrapper>
                </GridWrapper>
              ),
            )}
          </GridWrapper>
        </GridWrapper>
      )}
    </>
  )
}
