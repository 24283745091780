import { styled } from '@mui/material'

import { BoxWrapper, TextFieldWrapper } from '../../../wrapper'

export const SearchInput = styled(TextFieldWrapper)((props) => ({
  borderRadius: '24px',
  height: '50px',
  backgroundColor: props.theme.palette.background.default,
  display: 'flex',
  alignItems: 'left',
  justifyContent: 'center',
  width: '210px', // Valor por defecto
  '@media (min-width: 1601px)': {
    width: '280px',
  },
  '& fieldset': {
    border: 'none',
  },
  '& svg': {
    color: props.theme?.palette?.tertiary?.main,
    fontSize: '16px',
  },
  '& input ': {
    fontSize: '11px',
    color: props.theme?.palette?.tertiary?.main,
    '@media (min-width: 1601px)': {
      fontSize: '16px',
    },
  },
  '& input::placeholder': {
    fontSize: '11px', // Tamaño por defecto

    color: props.theme.palette.tertiary?.main,
    '@media (min-width: 1601px)': {
      fontSize: '16px',
    },
  },
}))

export const DateRangeInput = styled(BoxWrapper)((props) => ({
  borderRadius: '24px',
  height: '50px',
  backgroundColor: props.theme.palette.background.default,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '180px', // Valor por defecto
  marginRight: '35px',
  '@media (min-width: 1601px)': {
    width: '240px',
  },

  '& fieldset': {
    border: 'none',
  },
  '& svg': {
    color: props.theme?.palette?.tertiary?.main,
    fontSize: '12px',
  },
  '& .rs-picker-toggle-value, & input::placeholder , & input': {
    fontSize: '11px',
    color: props.theme?.palette?.tertiary?.main,
    '@media (min-width: 1601px)': {
      fontSize: '16px',
    },
  },
  '& .rs-picker-toggle-placeholder ': {
    fontSize: '11px',
    color: props.theme?.palette.text?.primary,
    '@media (min-width: 1601px)': {
      fontSize: '16px',
    },
  },

  '& .rs-picker-toggle-value': {
    color: `${props.theme?.palette?.tertiary?.main} !important`,
  },
}))
