import { styled } from '@mui/material'

import { TypographyWrapper } from '../../../../../../components'

export const TypographyName = styled( TypographyWrapper )(() => ({
  fontWeight: 700,
  fontSize: '35px',
  fontStyle: 'normal',
  letterSpacing: '0px',
  textAlign: 'left',
  lineHeight: '1'
}))

export const TypographyRecipeNumber = styled( TypographyWrapper )(() => ({
  fontSize: '20px',
  fontWeight: 700,
  fontStyle: 'normal',
  letterSpacing: '0px',
  textAlign: 'left',
}))

export const TypographyLabel = styled( TypographyWrapper )(() => ({
  fontSize: '15px',
  fontWeight: 400,
  fontStyle: 'normal',
  letterSpacing: '0px',
  textAlign: 'left',
}))

export const TypographyDoctorInfo = styled( TypographyWrapper )(() => ({
  fontSize: '15px',
  fontWeight: 400,
  fontStyle: 'normal',
  letterSpacing: '0px',
  textAlign: 'left',
  lineHeight: '1.2',
}))
