import { useJsApiLoader } from '@react-google-maps/api'

import React, { useEffect, useState } from 'react'

import { useCreateOfficeStore } from '../../../../../../../contexts/NewOfficeState'
import { ICoordinates } from '../../../../../../medical/ProfileMedical/RegisterConsultory/hooks/useRegisterConsultory'

declare type ReactStateType<T> = React.Dispatch<React.SetStateAction<T>>

export interface IUseEditOfficeHook {
  handleOpenCreateOffice: () => void
  handleCloseCreateOffice: () => void
  handleClickAutocomplete: () => void
  setMarkerPosition: ReactStateType<google.maps.LatLng | null>
  setZoom: ReactStateType<number>
  setAddress: ReactStateType<string>
  setMapCenter: ReactStateType<ICoordinates>
  setSelected: ReactStateType<boolean>
  setValue: ReactStateType<IPlaceType | null>
  setConsultingRoom: ReactStateType<string>
  setConsultingFloor: ReactStateType<string>
  setConsultingOffice: ReactStateType<string>
  handleSetAppointmentPrice: (value: string) => void
  setMap: ReactStateType<google.maps.Map | null>
  openCreateOffice: boolean
  disabledButton: boolean
  address: string
  isLoaded: boolean
  selected: boolean
  value: IPlaceType | null
  map: google.maps.Map | null
  markerPosition: google.maps.LatLng | null
  zoom: number
  mapCenter: ICoordinates
  consultingRoom: string
  consultingFloor: string
  consultingOffice: string
  appointmentPrice: string
  MAX_LENGTH: number,
}

type Libraries = (
  | 'drawing'
  | 'geometry'
  | 'localContext'
  | 'places'
  | 'visualization'
  )[]

const libraries = ['places'] satisfies Libraries

interface IMainTextMatchedSubstrings {
  offset: number
  length: number
}
interface IStructuredFormatting {
  main_text: string
  secondary_text: string
  main_text_matched_substrings?: readonly IMainTextMatchedSubstrings[]
}

interface IPlaceType {
  description: string
  structured_formatting: IStructuredFormatting
}

const center: ICoordinates = {
  lat: -0.0102496,
  lng: -78.4464668,
}

export function useCreateOffice(): IUseEditOfficeHook {
  const { office: officeStore } = useCreateOfficeStore()
  const [openCreateOffice, setOpenCreateOffice] = useState<boolean>(false)
  const [disabledButton, setDisabledButton] = useState<boolean>(false)
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY as string,
    libraries,
  })

  const [map, setMap] = useState<google.maps.Map | null>(null)
  const [MAX_LENGTH] = useState<number>(30)
  const [markerPosition, setMarkerPosition] =
    useState<google.maps.LatLng | null>(new google.maps.LatLng(
      Number(officeStore?.coordinates?.latitude),
      Number(officeStore?.coordinates?.longitude) ,
    ))
  const [selected, setSelected] = useState<boolean>(false)
  const [zoom, setZoom] = useState<number>(8)
  const [address, setAddress] = useState<string>(officeStore.address)
  const [value, setValue] = useState<IPlaceType | null>(officeStore.value || null)
  const [mapCenter, setMapCenter] = useState<ICoordinates>(center)

  const [consultingFloor, setConsultingFloor] = useState<string>(officeStore?.building_floor)
  const [consultingRoom, setConsultingRoom] = useState<string>(officeStore.building_name)
  const [appointmentPrice, setAppointmentPrice] = useState<string>(officeStore.consultationValue)
  const [consultingOffice, setConsultingOffice] = useState<string>(officeStore.office_number)
  const handleOpenCreateOffice = (): void => setOpenCreateOffice(true)
  const handleCloseCreateOffice = (): void => setOpenCreateOffice(false)

  const handleClickAutocomplete = (): void => {
    setAddress('')
  }
  const handleSetAppointmentPrice = (value: string): void => {
    setAppointmentPrice(
      value.length > MAX_LENGTH + 1 ? appointmentPrice : value,
    )
  }



  useEffect(() => {
    setDisabledButton(
      !(
        Boolean(consultingRoom) &&
        Boolean(consultingFloor) &&
        Boolean(consultingOffice) &&
        Boolean(value?.description) &&
        Boolean(appointmentPrice) &&
        consultingRoom.length <= MAX_LENGTH &&
        consultingFloor.length <= MAX_LENGTH &&
        consultingOffice.length <= MAX_LENGTH &&
        appointmentPrice.length <= MAX_LENGTH
      ),
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    consultingRoom,
    consultingFloor,
    consultingOffice,
    appointmentPrice,
    value,
  ])

  return {
    handleOpenCreateOffice,
    handleCloseCreateOffice,
    handleClickAutocomplete,
    setMarkerPosition,
    setZoom,
    setAddress,
    setMapCenter,
    setSelected,
    setValue,
    setMap,
    setConsultingRoom,
    setConsultingFloor,
    setConsultingOffice,
    handleSetAppointmentPrice,
    openCreateOffice,
    disabledButton,
    address,
    isLoaded,
    selected,
    value,
    map,
    markerPosition,
    zoom,
    mapCenter,
    consultingRoom,
    consultingFloor,
    consultingOffice,
    appointmentPrice,
    MAX_LENGTH
  }
}
