import { IAgreementRepository } from '../../domain/repository/AgreementRepository'
import { IApiResponseAgreement } from '../dtos/Agreements'
import { IApiResponseInvite } from '../dtos/AgreementsInvites'
import { Reply, IApiResponseReply } from '../dtos/ReplyInvites'
import AgreementsDataSource from '../http/dataSource/AgreementsDataSource'

export class AgreementRepositoryImpl implements IAgreementRepository {
  dataSource: AgreementsDataSource

  constructor(_datasource: AgreementsDataSource) {
    this.dataSource = _datasource
  }

  async getAgreements(idDoctor: string): Promise<IApiResponseAgreement> {
    return this.dataSource.getAgreements(idDoctor)
  }

  async sendReply(params: Reply): Promise<IApiResponseReply> {
    return this.dataSource.sendReply(params)
  }

  async getInvites(idDoctor: string): Promise<IApiResponseInvite> {
    return this.dataSource.getInvites(idDoctor)
  }
}
