import dayjs from 'dayjs'
import React, { useContext, useEffect, useState } from 'react'

import {
  AccountContext,
  AuthContextType,
} from '../../../../../contexts/AccountContext'
import { useDoctorInformationStore } from '../../../../../contexts/DoctorInformationState'
import { IAppointmentByDoctor } from '../../../../../infrastructure/dtos/Appointments'
import { BodyAvailableHours } from '../../../../../infrastructure/dtos/Calendar'
import {
  ICalendarData,
  IResponseGetCalendarEvent,
} from '../../../../../infrastructure/dtos/CalendarInfo'
import { getAvailableHoursInADay } from '../../../../../services/Contracts/Persistencia/Calendar'
import { GetDaysAvailabilityInAMonth } from '../../../../../services/Contracts/Persistencia/CalendarInfoService'

type OpeningHoursType = {
  [day: string]: {
    hour_open: string
    hour_close: string
  }
}

interface IUseNewDate {
  selectedOffice: string
  selectedDate: dayjs.Dayjs | null
  hourFrom: dayjs.Dayjs | null
  hourTo: string | null
  daysAvailability?: ICalendarData
  availableHours?: string[]
  able: boolean
  setAble: React.Dispatch<React.SetStateAction<boolean>>
  idDoctor: string
  setSelectedOffice: React.Dispatch<React.SetStateAction<string>>
  setSelectedDate: React.Dispatch<React.SetStateAction<dayjs.Dayjs | null>>
  setHourFrom: React.Dispatch<React.SetStateAction<dayjs.Dayjs | null>>
  setHourTo: React.Dispatch<React.SetStateAction<string | null>>
  fillHours: (
    date: string,
    schedule: OpeningHoursType,
  ) => { label: string; value: string }[]
  isTimeOverlapping: (
    appointments: IAppointmentByDoctor[],
    hourFrom: string,
    hourTo: string,
  ) => boolean
  appointmentTypeList: string[]
  setAppointmentTypeList: React.Dispatch<React.SetStateAction<string[]>>
  setDuration: React.Dispatch<React.SetStateAction<number>>
  duration: number
  appointmentType: string
  setAppointmentType: React.Dispatch<React.SetStateAction<string>>
  setCustomType: React.Dispatch<React.SetStateAction<string>>
  customType: string
  customDuration: CustomDuration
  setCustomDuration: React.Dispatch<React.SetStateAction<CustomDuration>>
  isCustomType: boolean
  setIsCustomType: React.Dispatch<React.SetStateAction<boolean>>
  setAvailableHours: React.Dispatch<React.SetStateAction<string[] | undefined>>
}

export interface CustomDuration {
  name: string
  value: number
}

export const useNewDate = (): IUseNewDate => {
  const { doctorInformation } = useDoctorInformationStore()
  const { handleAlert } = useContext(AccountContext) as AuthContextType
  const idDoctor: string = doctorInformation?.user_id as string
  const [daysAvailability, setDaysAvailability] = useState<ICalendarData>()
  const [availableHours, setAvailableHours] = useState<string[]>()
  const [able, setAble] = useState<boolean>(true)

  const [selectedOffice, setSelectedOffice] = useState<string>('')
  const [selectedDate, setSelectedDate] = useState<dayjs.Dayjs | null>(null)
  const [hourFrom, setHourFrom] = useState<dayjs.Dayjs | null>(null)
  const [hourTo, setHourTo] = useState<string | null>('')
  const [duration, setDuration] = useState<number>(0)
  const [appointmentTypeList, setAppointmentTypeList] = useState<string[]>([])
  const [appointmentType, setAppointmentType] = useState<string>('')
  const [customType, setCustomType] = useState<string>('')
  const [customDuration, setCustomDuration] = useState<CustomDuration>({
    name: '',
    value: 0,
  })
  const [isCustomType, setIsCustomType] = useState<boolean>(false)
  const fetchDaysAvailabilityInAMonth = async (): Promise<
    ICalendarData | undefined
  > => {
    try {
      const { data } = await GetDaysAvailabilityInAMonth(
        idDoctor as string,
        selectedOffice,
        isCustomType ? customDuration.value : duration,
        'OFFICE'
      )
      return (data as IResponseGetCalendarEvent).body
    } catch (error: unknown) {
      handleAlert(true, 'Error al obtener los días disponibles', 'error')
    }
  }

  const fetchAvailableHoursInADay = async (): Promise<void> => {
    try {
      const { body, status } = await getAvailableHoursInADay({
        user_id: doctorInformation?.user_id as string,
        date_search: selectedDate?.format('YYYY-MM-DD') as string,
        office_id: selectedOffice,
        duration: isCustomType ? customDuration.value : duration,
        officeType: 'OFFICE'
      })
      if (status) {
        setAvailableHours((body as BodyAvailableHours).data.intervals_available_hours)
      } else {
        throw new Error('')
      }
    } catch (error: unknown) {
      handleAlert(true, 'Error al obtener las horas disponibles', 'error')
    }
  }

  const fillHours = (
    date: string,
    schedule: OpeningHoursType,
  ): { label: string; value: string }[] => {
    const hours = []
    const daysOfWeek = [
      'sunday',
      'monday',
      'tuesday',
      'wednesday',
      'thursday',
      'friday',
      'saturday',
    ]
    const currentDate = dayjs(date)
    const currentDay = daysOfWeek[currentDate?.day()]

    const now = dayjs() // Obtén la hora actual

    for (let i = 0; i < 24; i++) {
      for (let j = 0; j < 60; j += 15) {
        const hour = (i < 10 ? '0' : '') + i + ':' + (j === 0 ? '00' : j)
        const label = (i < 10 ? '0' : '') + i + 'h' + (j === 0 ? '00' : j)
        const currentTime = dayjs(hour, 'HH:mm')
        if (schedule && schedule[currentDay]) {
          const openTime = dayjs(schedule[currentDay].hour_open, 'HH:mm')
          const closeTime = dayjs(schedule[currentDay].hour_close, 'HH:mm')

          if (
            currentTime.isAfter(openTime) &&
            currentTime.isBefore(closeTime)
          ) {
            continue
          }
        }
        if (currentDate.isSame(now, 'day') && currentTime.isBefore(now)) {
          // Si es la fecha actual y la hora es anterior a la hora actual, continua
          continue
        }

        hours.push({
          label,
          value: hour,
        })
      }
    }

    return hours
  }

  const isTimeOverlapping = (
    appointments: IAppointmentByDoctor[],
    hourFrom: string,
    hourTo: string,
  ): boolean => {
    for (const appointment of appointments) {
      const existingStart = dayjs(appointment.hour_from, 'HH:mm')
      const existingEnd = dayjs(appointment.hour_to, 'HH:mm')
      const newStart = dayjs(hourFrom, 'HH:mm')
      const newEnd = dayjs(hourTo, 'HH:mm')

      if (
        (existingStart.isBefore(newStart) && existingEnd.isAfter(newStart)) ||
        (existingStart.isBefore(newEnd) && existingEnd.isAfter(newEnd)) ||
        (existingStart.isSame(newStart) && existingEnd.isSame(newEnd)) ||
        (existingStart.isAfter(newStart) && existingEnd.isBefore(newEnd)) ||
        (existingStart.isAfter(newStart) && existingEnd.isSame(newEnd)) ||
        (existingStart.isSame(newStart) && existingEnd.isBefore(newEnd))
      ) {
        return true
      }
    }
    return false
  }

  useEffect(() => {
    if (isCustomType) {
      if (selectedOffice && customDuration.value) {
        fetchDaysAvailabilityInAMonth().then((result) => {
          setDaysAvailability(result)
          setAble(false)
        })
      }
    } else if (selectedOffice && duration) {
      fetchDaysAvailabilityInAMonth().then((result) => {
        setDaysAvailability(result)
        setAble(false)
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOffice, duration, customDuration, isCustomType])

  useEffect(() => {
    if (isCustomType) {
      if (selectedDate && customDuration.value) {
        fetchAvailableHoursInADay()
      }
    } else if (selectedDate && duration) {
      fetchAvailableHoursInADay()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDate, duration, customDuration, isCustomType])

  return {
    selectedOffice,
    selectedDate,
    hourFrom,
    hourTo,
    daysAvailability,
    availableHours,
    able,
    idDoctor,
    setSelectedOffice,
    setSelectedDate,
    setHourFrom,
    setHourTo,
    fillHours,
    isTimeOverlapping,
    appointmentTypeList,
    setAppointmentTypeList,
    setDuration,
    duration,
    appointmentType,
    setAppointmentType,
    customType,
    setCustomType,
    customDuration,
    setCustomDuration,
    isCustomType,
    setIsCustomType,
    setAble,
    setAvailableHours,
  }
}
