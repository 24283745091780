import { PatientInfoRepository } from '../../domain/repository/PatientInfoRepository'
import { IApiResponsePatientInfo } from '../dtos/Patient'
import PatientInfoDataSource from '../http/dataSource/PatientInfoDataSource'

export class PatientInfoRepositoryImpl implements PatientInfoRepository {
  dataSource: PatientInfoDataSource

  constructor( _dataSource: PatientInfoDataSource ) {
    this.dataSource = _dataSource
  }

  async getPatientInfo( idPatient: string ): Promise<IApiResponsePatientInfo> {
    return this.dataSource.getPatientInfo( idPatient )
  }

  async getPatientInfoByCi( ciPatient: string, isOnlyInfo? :boolean ): Promise<IApiResponsePatientInfo> {
    return this.dataSource.getPatientInfoByCi( ciPatient, isOnlyInfo )
  }
}
