import { ParametersNotificationRepository } from '../../domain/repository/ParametersNotificationRepository';
import { IApiResponseParametersNotificationLateAppointment } from '../dtos/ParametersNotification';
import ParametersNotificationDataSource from '../http/dataSource/ParametersNotificationDataSource';

export class ParametersNotificationRepositoryImpl
implements ParametersNotificationRepository
{
  dataSource: ParametersNotificationDataSource;

  constructor( _DataSource: ParametersNotificationDataSource ) {
    this.dataSource = _DataSource;
  }

  async getParametersNotificationLateAppointment(): Promise<IApiResponseParametersNotificationLateAppointment> {
    return this.dataSource.getParametersNotificationLateAppointment();
  }
}
