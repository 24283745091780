import { useEffect, useState } from 'react'

import { Severity } from '../../../../../contexts/AuthReducer'
import { GetVisibilityAppointmentTimeUtility } from '../../../../../services/Contracts/Utility/VisibilityAppointmentTimeUtility'

interface IUseHook {
  lists: string[]
}

export function useVisibilityAppointmentTime(
  handleAlert?: (open: boolean, message: string, severity?: Severity) => void
): IUseHook {
  const [lists, setLists] = useState<string[]>([])

  useEffect(() => {
    fetchVisibilityAppointmentTimes()
  }, [])

  const fetchVisibilityAppointmentTimes = async (): Promise<void> => {
    try {
      const { status, data } = await GetVisibilityAppointmentTimeUtility()
      if (status) {
        setLists(data)
      } else {
        throw new Error('')
      }
    } catch (error: unknown) {
      handleAlert?.(true, 'Error al obtener listado de intervalos de horas', 'error')
    }
  }

  return {
    lists,
  }
}
