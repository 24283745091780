import { Box } from '@mui/material'
import { styled } from '@mui/material/styles'

import {
  ButtonWrapper,
  BackdropWrapper,
  TypographyWrapper,
  ModalWrapper,
  FadeWrapper,
  GridWrapper,
  TextFieldWrapper
} from '../../../../components'

export const BackdropModal = styled( BackdropWrapper )(() => ({}))
export const ButtonModal = styled( ButtonWrapper )(( props ) => ({
  width: '95%',
  borderRadius: '42px',
  backgroundColor: props.theme.palette.primary.main,
  fontSize: '11px',
  height: '45px'
}))
export const TypographyModal = styled( TypographyWrapper )(( props ) => ({
  left: '50%',
  fontFamily: 'Helvetica',
  fontSize: '15px',
  fontWeight: 'bold',
  fontStretch: 'normal',
  fontStyle: 'normal',
  lineHeight: 'normal',
  letterSpacing: 'normal',
  textAlign: 'center',
  color: props.theme.palette.primary.dark
}))
export const TypographyTittle = styled( TypographyWrapper )(({ theme }) => ({
  marginTop: '10px',
  fontFamily: 'Helvetica',
  fontSize: '15px',
  fontWeight: 'normal',
  fontStretch: 'normal',
  fontStyle: 'normal',
  lineHeight: 'normal',
  letterSpacing: 'normal',
  textAlign: 'left',
  color: theme.palette.common.black
}))
export const BoxModal = styled( Box )(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  minWidth: '450px',
  minHeight: '400px',
  bgcolor: theme.palette.background.paper,
  background: theme.palette.common.white,
  borderRadius: '30px',
  p: 4,
  boxShadow: '2px 2px 18px 14px rgba(0, 0, 0, 0.03)'
}))
export const ModalComponent = styled( ModalWrapper )(() => ({
  height: 'auto',
  overflowY: 'auto'
}))
export const FadeModal = styled( FadeWrapper )(() => ({
  height: 'auto',
}))

export const GridModal = styled( GridWrapper )(() => ({
  padding: '60px'
}))

export const TextFielNameModal = styled( TextFieldWrapper )(( props ) => ({
  '& .MuiInputLabel-root': {},
  '& .MuiInputBase-root': {
    marginTop: '10px',
    borderRadius: '3.4375rem',
    backgroundColor: props.theme.palette.common.white,
    fontSize: '1.1rem',
    color: props.theme.palette?.text.primary,
    fontFamily: 'Helvetica',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    height: '3.3rem',
    boxShadow: '6px 6px 8px 7px rgba(0, 0, 0, 0.06)'
  },
  '& fieldset': { borderColor: props.theme.palette.common.white },
  '&::placeholder': {
    marginLeft: '1.875rem'
  },
  '& .MuiInputBase-root.Mui-disabled': {
    '& > fieldset': {
      borderColor: props.theme.palette.common.white,
    }
  }
}))
export const GridModalItem = styled( GridWrapper )(() => ({
  flexGrow: 1,
  flexBasis: '50%'
}))
export const GridModalItemRadio = styled( GridWrapper )(() => ({
  flexGrow: 1,
  flexBasis: '10%'
}))
export const GridModalItemTexto = styled( GridWrapper )(() => ({
  flexGrow: 1,
  flexBasis: '40%'
}))

export const GridModalContainerItem = styled( GridWrapper )(() => ({
  display: 'flex',
  flexDirection: 'row',
  height: '100%',
  marginTop: '10px'
}))

export const BoxTimeModal = styled( Box )(({ theme }) => ({
  '& .MuiInputBase-root': {
    marginRight: '10px',
    borderRadius: '3.4375rem',
    backgroundColor: theme.palette.common.white,
    fontSize: '1.1rem',
    color: theme.palette?.text.primary,
    fontFamily: 'Helvetica',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    height: '3.3rem',
    boxShadow: '1px 1px 7px 7px rgba(0, 0, 0, 0.03)'
  },
  '& fieldset': { borderColor: theme.palette.common.white },
  '& .MuiFormControl-root': {
    width: '100%'
  }
}))

export const ButtonRangoModal = styled( ButtonWrapper )(( props ) => ({
  width: '95%',
  borderRadius: '2.625rem',
  backgroundColor: props.theme.palette.primary.main,
  fontSize: '0.75rem',
  height: '45px',
  textTransform: 'none'
}))

export const ButtonSubmitModal = styled( ButtonWrapper )(( props ) => ({
  borderRadius: '2.625rem',
  backgroundColor: props.theme.palette.primary.dark,
  fontSize: '1.125rem',
  height: '4.0625rem',
  width: '60%',
  textTransform: 'none',
  left: '20%'
}))
