import { useNavigate } from 'react-router-dom'

import { useDoctorInformationStore } from '../../contexts/DoctorInformationState'
import SidebarLayout from '../../layouts/SidebarLayout'

const Dashboard: React.FunctionComponent = (): JSX.Element => {
  const navigate = useNavigate()
  const { doctorInformation } = useDoctorInformationStore()
  if (Object.keys(doctorInformation).length === 0) {
    return <></>
  }
  if (doctorInformation.complete_register === false) {
    navigate('/medico/register/profile')
    return <></>
  }
  return <SidebarLayout />
}

export default Dashboard
