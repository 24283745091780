import { styled } from '@mui/material'

import TypographyWrapper from '../wrapper/TypographyWrapper'

export const LabelInput = styled( TypographyWrapper )(({theme}) => ({
  textAlign: 'left',
  color: theme.palette.common.black,
  fontSize: '12px',
  fontFamily: 'Helvetica',
  fontWeight: 'bold',
  fontStretch: 'normal',
  fontStyle: 'normal',
  lineHeight: 'normal',
  letterSpacing: 'normal',
  marginBottom: '0.6875rem',
  marginLeft: '1.5rem',
  '@media (min-width: 1600px)': {
    fontSize: '16px',
  },
}))
