import { ShareOutlined } from '@mui/icons-material'
import React, {ChangeEvent, Dispatch, SetStateAction, useState} from 'react'

import { IDataDoctor } from './hooks/useDoctorInformation'
import { GridWrapper, StackWrapper } from '../../../../../components'
import FormTextInput from '../../../../../components/FormInputText'
import { List } from '../../../../../components/List/List'
import { LabelInput } from '../../../../../components/StyledComponents/LabelInput'
import { SecondLabelInput } from '../../../../../components/StyledComponents/SecondLabelInput'
import SubmitButton from '../../../../../components/SubmitButton'
import UploadPhoto from '../../../../../components/UploadPhoto'
import { Severity } from '../../../../../contexts/AuthReducer'
import { ISpecialty } from '../../../../../infrastructure/dtos'
import { requiredField } from '../../../../../utils/requiredField'
import { handleImageUpload } from '../../../../../utils/uploadImage'
import { validateInput } from '../../../../../utils/validateInput'
import { validatePhoneNumber } from '../../../../../utils/validatePhoneNumber'
import { SkillsAutocomplete } from '../../../../medical/ProfileMedical/Components/SkillsAutocomplete'
import { useYearsExperience } from '../../../../medical/ProfileMedical/ProfileMedicalForm1/Hooks/useYearsExperience'
import containerText from '../../../../medical/ProfileMedical/ProfileMedicalForm1/ProfileMedicalForm1Text.json'

interface IDoctorInformationHook {
  specialties: ISpecialty[]
  selectedImage: string
  setSelectedImage: Dispatch<SetStateAction<string>>
  specialty: string
  setSpecialty: Dispatch<SetStateAction<string>>
  dataDoctor: IDataDoctor
  setDataDoctor: Dispatch<SetStateAction<IDataDoctor>>
  handleAlert: (open: boolean, message: string, severity?: Severity) => void
  error: boolean
  message: string
  skills: string[]
  setSkills: Dispatch<SetStateAction<string[]>>
}

export function DoctorInformation({
  dataDoctor,
  selectedImage,
  setDataDoctor,
  setSelectedImage,
  setSpecialty,
  handleAlert,
  specialties,
  specialty,
  error,
  message,
  skills,
  setSkills,
}: IDoctorInformationHook): JSX.Element {
  const [ValidationFocus, setValidationFocus] = useState<boolean[]>([
    false,
    false,
    false,
    false,
    false,
    false,
  ])

  const requiredLength = 1000

  const validLength = dataDoctor?.about?.length >= requiredLength

  const { amountRanges } = useYearsExperience(handleAlert)

  const onValidationFocus = (index: number): void => {
    const newValidationFocus: boolean[] = [...ValidationFocus]

    newValidationFocus[index] = true
    setValidationFocus(newValidationFocus)
  }

  const validatorTextArea = (): string => {
    let textError = ''

    if (
      !validateInput(dataDoctor?.about) &&
      dataDoctor?.about?.length === 0 &&
      ValidationFocus[2]
    ) {
      textError = 'Campo requerido.'
    } else if (validLength) {
      textError = 'Ha excedido el número de caracteres permitidos.'
    }

    return textError
  }
  const validatorErrorTextArea: string = validatorTextArea()

  const validatorErrorPhone = (): string =>
    validatePhone
      ? 'Campo requerido.'
      : !validatePhoneNumber(dataDoctor?.phone)
        ? 'Por favor, ingresa un teléfono celular válido'
        : ''

  const validateName: boolean =
    !validateInput(dataDoctor?.name) &&
    dataDoctor?.name?.length === 0 &&
    ValidationFocus[0]
  const validateLastName: boolean =
    !validateInput(dataDoctor?.lastName) &&
    dataDoctor?.lastName?.length === 0 &&
    ValidationFocus[1]

  const validateDescription: boolean =
    (!validateInput(dataDoctor?.about) &&
      dataDoctor?.about?.length === 0 &&
      ValidationFocus[2]) ||
    validLength

  const validatePhone: boolean =
    !validateInput(dataDoctor?.phone) &&
    dataDoctor?.phone?.length === 0 &&
    ValidationFocus[3]

  const validateAcademyPlace: boolean =
    !validateInput(dataDoctor?.academyPlace) &&
    dataDoctor?.academyPlace?.length === 0 &&
    ValidationFocus[5]

  return (
    <GridWrapper container>
      <GridWrapper item sm={12} md={4}>
        <GridWrapper container px={1}>
          <GridWrapper item xs={12}>
            <UploadPhoto
              id="UploadPhotoId"
              selectedImage={selectedImage}
              tooltipText="Subir imagenes con formato jpg o png"
              buttonText={selectedImage ? 'Cambiar foto' : 'Subir foto'}
              buttonType="button"
              onChange={(event) => handleImageUpload(event, setSelectedImage)}
              xs={12}
              data-Testid="upload-file"
            />
          </GridWrapper>
          <GridWrapper item xs={12} mt={5} mx={2}>
            <SubmitButton
              fullWidth
              id="share-profile-button"
              text="Compartir perfil"
              type="button"
              variant="contained"
              startIcon={<ShareOutlined />}
            />
          </GridWrapper>
          <GridWrapper item xs={12} mt={5} mx={2}>
            <LabelInput sx={{ ml: 0, fontWeight: 'normal' }}>
              Reg. ACESS:
            </LabelInput>
            <FormTextInput
              placeholder="ingresa tu Reg. ACESS"
              id="regg_access"
              name="regg_access"
              type="text"
              fullWidth
              value={dataDoctor?.codeMsp}
              error={error}
              helperText={message}
              onChange={(
                event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
              ) =>
                setDataDoctor({ ...dataDoctor, codeMsp: event.target.value })
              }
            />
          </GridWrapper>
        </GridWrapper>
      </GridWrapper>
      <GridWrapper item sm={12} md={8}>
        <GridWrapper container px={4} py={2}>
          <GridWrapper item xs={12} sm={6} my={2}>
            <SecondLabelInput sx={{ ml: 0 }}>Nombre:</SecondLabelInput>
            <FormTextInput
              placeholder="ingresa tu Nombre"
              id="doctor_name"
              name="doctor_name"
              type="text"
              fullWidth
              value={dataDoctor?.name}
              disabled
              sx={{ paddingX: '4px' }}
              onChange={(
                event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
              ) => setDataDoctor({ ...dataDoctor, name: event.target.value })}
              onBlur={() => onValidationFocus(0)}
              required
              error={validateName}
              helperText={requiredField(validateName)}
            />
          </GridWrapper>
          <GridWrapper item xs={12} sm={6} my={2}>
            <SecondLabelInput sx={{ ml: 0 }}>Apellido:</SecondLabelInput>
            <FormTextInput
              placeholder="ingresa tu Apellido"
              id="doctor_last_name"
              name="doctor_last_name"
              type="text"
              fullWidth
              value={dataDoctor?.lastName}
              sx={{ paddingX: '4px' }}
              disabled
              onChange={(
                event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
              ) =>
                setDataDoctor({ ...dataDoctor, lastName: event.target.value })
              }
              onBlur={() => onValidationFocus(1)}
              required
              error={validateLastName}
              helperText={requiredField(validateLastName)}
            />
          </GridWrapper>
          <GridWrapper item xs={12} my={2}>
            <SecondLabelInput sx={{ ml: 0 }}>Tu descripción:</SecondLabelInput>
            <FormTextInput
              placeholder="ingresa tu descripción"
              id="doctor_about"
              name="doctor_about"
              type="text"
              fullWidth
              value={dataDoctor?.about}
              sx={{ paddingX: '4px' }}
              onChange={(
                event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
              ) => setDataDoctor({ ...dataDoctor, about: event.target.value })}
              onBlur={() => onValidationFocus(2)}
              error={validateDescription}
              helperText={validatorErrorTextArea}
            />
          </GridWrapper>
          <GridWrapper item xs={12} sm={6} my={2}>
            <SecondLabelInput sx={{ ml: 0 }}>
              Número de celular:
            </SecondLabelInput>
            <FormTextInput
              placeholder="ingresa tu Número de celular"
              id="doctor_phone"
              name="doctor_phone"
              type="text"
              fullWidth
              value={dataDoctor?.phone}
              sx={{ paddingX: '4px' }}
              onChange={(
                event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
              ) => setDataDoctor({ ...dataDoctor, phone: event.target.value })}
              onBlur={() => onValidationFocus(3)}
              error={validatePhone || !validatePhoneNumber(dataDoctor?.phone)}
              helperText={validatorErrorPhone()}
            />
          </GridWrapper>
          <GridWrapper item xs={12} sm={6} my={2}>
            <SecondLabelInput sx={{ ml: 0 }}>
              Número de cédula:
            </SecondLabelInput>
            <FormTextInput
              placeholder="ingresa tu Número de cédula"
              id="doctor_id_number"
              name="doctor_id_number"
              type="text"
              fullWidth
              value={dataDoctor?.doctorCi}
              sx={{ paddingX: '4px' }}
              onChange={(
                event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
              ) =>
                setDataDoctor({ ...dataDoctor, doctorCi: event.target.value })
              }
              disabled
            />
          </GridWrapper>

          <GridWrapper item xs={12} sm={6} my={2}>
            <SecondLabelInput sx={{ ml: 0 }}>
              Lugar de estudios
            </SecondLabelInput>
            <FormTextInput
              placeholder="ingresa tu Lugar de estudios"
              id="doctor_academy_place"
              name="doctor_academy_place"
              type="text"
              fullWidth
              value={dataDoctor?.academyPlace}
              sx={{ paddingX: '4px' }}
              onChange={(
                event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
              ) =>
                setDataDoctor({
                  ...dataDoctor,
                  academyPlace: event.target.value,
                })
              }
              onBlur={() => onValidationFocus(5)}
              error={validateAcademyPlace}
              helperText={requiredField(validateAcademyPlace)}
            />
          </GridWrapper>

          <GridWrapper item xs={12} sm={6} my={2}>
            <SecondLabelInput sx={{ ml: 0 }}>
              Años de experiencia
            </SecondLabelInput>
            <List
              id="selectExperience"
              lists={amountRanges?.map((s) => s)}
              onClick={(event: string) =>
                setDataDoctor({ ...dataDoctor, experience: event })
              }
              value={dataDoctor?.experience}
              minWidth="100%"
            />
          </GridWrapper>

          <GridWrapper item xs={12} my={2}>
            <SecondLabelInput sx={{ ml: 0 }}>Tu especialidad</SecondLabelInput>
            <List
              id="selectSpecialty"
              lists={specialties?.map((s: ISpecialty) => s?.option_value)}
              onClick={setSpecialty}
              value={specialty}
              minWidth="100%"
            />
          </GridWrapper>

          <GridWrapper item xs={12} my={2}>
            <LabelInput sx={{ mb: 1, marginLeft: 0, fontWeight: 'normal' }}>
              {containerText.select_skills}{' '}
              {containerText.select_skills_description}
            </LabelInput>
            <StackWrapper
              spacing={2}
              direction="row"
              alignItems="center"
              justifyContent="center"
              style={{ marginTop: '3%', marginBottom: '3%' }}
            >
              <SkillsAutocomplete skills={skills} setSkills={setSkills} />
            </StackWrapper>
          </GridWrapper>
        </GridWrapper>
      </GridWrapper>
    </GridWrapper>
  )
}
