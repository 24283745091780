import { useAuth0 } from '@auth0/auth0-react'
import { useContext, useState } from 'react'

import { useNavigate } from 'react-router-dom'

import { useDoctorInformationStore } from '../../../../contexts/DoctorInformationState'
import { RegisterMedicalContext } from '../../../../contexts/RegisterContext'
import { IDoctorInformation } from '../../../../infrastructure/dtos/DoctorInfo'
import {
  ISaveRegisterMedical,
  IMedicalOffice,
} from '../../../../infrastructure/dtos/MedicalRegister'
import { DoctorInfoUtility } from '../../../../services/Contracts/Utility/DoctorInfoUtility'
import {
  IMedicalRegisterData,
  IMedicalRegisterDataBody,
  PostMedicalRegisterUtility,
} from '../../../../services/Contracts/Utility/MedicalRegisterUtility'
import { formatHourToValidate } from '../../../../utils/FormatHour'

interface ISaveProfileMedicalHook {
  SaveData: () => Promise<void>
  message: IMessage
  openAlert: boolean
  loading: boolean
  onOption: () => void
}
export interface IMessage {
  title: string
  message: string
  navigate: boolean
}

interface IUserData {
  user_id: string
  email_doctor: string
}

export const DocumentType: { [key: string]: string } = {
  Pasaporte: 'PASSPORT',
  Cédula: 'CI',
}

export function useSaveProfileMedical(): ISaveProfileMedicalHook {
  const { doctorInformation } = useDoctorInformationStore()
  const { user } = useAuth0()
  const { setDoctorInformation } = useDoctorInformationStore()
  const { RegisterMedical } = useContext(RegisterMedicalContext)
  const [openAlert, setOpen] = useState<boolean>(false)
  const [loading, setloading] = useState<boolean>(false)
  const [message, setMessage] = useState<IMessage>({
    message: '',
    title: 'AVISO',
    navigate: false,
  })
  const navigate = useNavigate()
  const onOption = (): void => {
    if (message.navigate) {
      if (['AGREEMENT'].includes(doctorInformation.type_invitation as string)) {
        navigate('/dashboard/agreements')
      } else {
        navigate('/Dashboard/index')
      }
    } else {
      setloading(false)
      setOpen(false)
    }
  }
  /**
   * Structuring Data medical
   * @returns dataSaveProfile: ISaveRegisterMedical
   */
  const StructuringData = (): ISaveRegisterMedical => {
    const dataSaveMedicalOffice: IMedicalOffice[] = []
    for (let i = 0; i < RegisterMedical.medicalCare.length; i++) {
      const index: number = RegisterMedical.officeMedical.findIndex(
        (x) => x.id === RegisterMedical.medicalCare[i].id,
      )
      dataSaveMedicalOffice.push({
        consultation_time_minutes:
          RegisterMedical.medicalCare[i].durationMedicalAppointment,
        coordinates_medical_office: {
          latitude: RegisterMedical.officeMedical[index].coordinates.latitude,
          longitude: RegisterMedical.officeMedical[index].coordinates.longitude,
        },
        hours_of_non_attendance: [],
        name_medical_office: RegisterMedical.officeMedical[index].address,
        opening_hours: {
          monday: {
            hour_open:
              RegisterMedical.medicalCare[i].daysMedicalCare[0]?.active === true
                ? formatHourToValidate(
                    RegisterMedical.medicalCare[i].daysMedicalCare[0].from,
                  )
                : '',
            hour_close:
              RegisterMedical.medicalCare[i].daysMedicalCare[0]?.active === true
                ? formatHourToValidate(
                    RegisterMedical.medicalCare[i].daysMedicalCare[0].to,
                  )
                : '',
          },
          tuesday: {
            hour_open:
              RegisterMedical.medicalCare[i].daysMedicalCare[1]?.active === true
                ? formatHourToValidate(
                    RegisterMedical.medicalCare[i].daysMedicalCare[1].from,
                  )
                : '',
            hour_close:
              RegisterMedical.medicalCare[i].daysMedicalCare[1]?.active === true
                ? formatHourToValidate(
                    RegisterMedical.medicalCare[i].daysMedicalCare[1].to,
                  )
                : '',
          },
          wednesday: {
            hour_open:
              RegisterMedical.medicalCare[i].daysMedicalCare[2]?.active === true
                ? formatHourToValidate(
                    RegisterMedical.medicalCare[i].daysMedicalCare[2].from,
                  )
                : '',
            hour_close:
              RegisterMedical.medicalCare[i].daysMedicalCare[2]?.active === true
                ? formatHourToValidate(
                    RegisterMedical.medicalCare[i].daysMedicalCare[2].to,
                  )
                : '',
          },
          thursday: {
            hour_open:
              RegisterMedical.medicalCare[i].daysMedicalCare[3]?.active === true
                ? formatHourToValidate(
                    RegisterMedical.medicalCare[i].daysMedicalCare[3].from,
                  )
                : '',
            hour_close:
              RegisterMedical.medicalCare[i].daysMedicalCare[3]?.active === true
                ? formatHourToValidate(
                    RegisterMedical.medicalCare[i].daysMedicalCare[3].to,
                  )
                : '',
          },
          friday: {
            hour_open:
              RegisterMedical.medicalCare[i].daysMedicalCare[4]?.active === true
                ? formatHourToValidate(
                    RegisterMedical.medicalCare[i].daysMedicalCare[4].from,
                  )
                : '',
            hour_close:
              RegisterMedical.medicalCare[i].daysMedicalCare[4]?.active === true
                ? formatHourToValidate(
                    RegisterMedical.medicalCare[i].daysMedicalCare[4].to,
                  )
                : '',
          },
          saturday: {
            hour_open:
              RegisterMedical.medicalCare[i].daysMedicalCare[5]?.active === true
                ? formatHourToValidate(
                    RegisterMedical.medicalCare[i].daysMedicalCare[5].from,
                  )
                : '',
            hour_close:
              RegisterMedical.medicalCare[i].daysMedicalCare[5]?.active === true
                ? formatHourToValidate(
                    RegisterMedical.medicalCare[i].daysMedicalCare[5].to,
                  )
                : '',
          },
          sunday: {
            hour_open:
              RegisterMedical.medicalCare[i].daysMedicalCare[6]?.active === true
                ? formatHourToValidate(
                    RegisterMedical.medicalCare[i].daysMedicalCare[6].from,
                  )
                : '',
            hour_close:
              RegisterMedical.medicalCare[i].daysMedicalCare[6]?.active === true
                ? formatHourToValidate(
                    RegisterMedical.medicalCare[i].daysMedicalCare[6].to,
                  )
                : '',
          },
        },
        building_floor: RegisterMedical.officeMedical[index].building_floor,
        building_name: RegisterMedical.officeMedical[index].building_name,
        office_number: RegisterMedical.officeMedical[index].office_number,
        type_visibility: 'm',
        visibility_medical_office_days:
          RegisterMedical.medicalCare[i].visualisationAgenda,
        consultation_value:
          RegisterMedical.officeMedical[index].consultationValue,
        office_phone: RegisterMedical.medicalCare[i].office_phone_number,
        appointment_types: RegisterMedical.medicalCare[i].appointmentTypes,
      })
    }

    const userAuth0 = user?.sub as string
    const userId = userAuth0?.split('|')
    const userEmailAndUserId: IUserData = {
      user_id: userId[1],
      email_doctor: user?.email as string,
    }

    return {
      auth_id: userEmailAndUserId.user_id,
      email_doctor: userEmailAndUserId.email_doctor,
      description_doctor: RegisterMedical.description,
      last_name_doctor: RegisterMedical.lastName,
      name_doctor: RegisterMedical.name,
      photo_doctor: RegisterMedical.image,
      speciality_doctor: RegisterMedical.specialty,
      university_doctor: RegisterMedical.studies,
      years_experience_doctor: RegisterMedical.experience,
      medical_office: dataSaveMedicalOffice,
      doctor_phone: RegisterMedical.phone,
      doctor_ci: RegisterMedical.idNumber,
      document_type:
        RegisterMedical.idType === 'Cédula o RUC'
          ? 'CI'
          : DocumentType[RegisterMedical.idType],
      gender: RegisterMedical.gender,
      medical_skills: RegisterMedical.skills,
      user_id: doctorInformation.user_id ?? '0',
    }
  }
  /**
   *
   * @returns Result Data
   */
  const SaveData = async (): Promise<void> => {
    setloading(true)
    const dataSaveMedical: ISaveRegisterMedical = StructuringData()
    const ResultAPISaveMedical: IMedicalRegisterData =
      await PostMedicalRegisterUtility(dataSaveMedical)
    if (ResultAPISaveMedical.status === 1) {
      const { data } = await DoctorInfoUtility(
        (ResultAPISaveMedical.data as IMedicalRegisterDataBody).user_id,
      )
      setDoctorInformation(data as IDoctorInformation)
      setOpen(true)
      setMessage({
        title: 'AVISO',
        message: (ResultAPISaveMedical.data as IMedicalRegisterDataBody)
          .message,
        navigate: true,
      })
    } else {
      setOpen(true)
      setMessage({
        title: 'AVISO',
        message:
          (ResultAPISaveMedical.data as IMedicalRegisterDataBody)?.message ||
          (ResultAPISaveMedical.data as string),
        navigate: false,
      })
    }
  }

  return {
    SaveData,
    message,
    onOption,
    openAlert,
    loading,
  }
}
