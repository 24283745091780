import { debounce } from '@mui/material'
import dayjs from 'dayjs'
import React, { useContext, useEffect, useMemo, useState } from 'react'

import { CustomDuration, useNewDate } from './useNewDate'
import {
  AccountContext,
  AuthContextType,
} from '../../../../../contexts/AccountContext'
import { useSelectOffice } from '../../../../../contexts/SelectOfficeState'
import { IAppointmentsByDoctorIdResponse } from '../../../../../infrastructure/dtos/Appointments'
import { ICalendarData } from '../../../../../infrastructure/dtos/CalendarInfo'
import { IMedicalOffice } from '../../../../../infrastructure/dtos/Offices'
import { IBodyPatientInfo } from '../../../../../infrastructure/dtos/Patient'
import { getAppointmentsByDoctorIdUtility } from '../../../../../services/Contracts/Utility/AppointmentUtility'
import {
  INewDateData,
  PostNewDateUtility,
  PostNewOverbookingAppointmentUtility,
} from '../../../../../services/Contracts/Utility/NewDateUtility'
import { getPatientInfoByCiUtility } from '../../../../../services/Contracts/Utility/PatientInfoUtility'
import { validateEmail } from '../../../../../utils/validateEmail'
import {
  validateInput,
  validateNumberInput,
} from '../../../../../utils/validateInput'
import { validatePhoneNumber } from '../../../../../utils/validatePhoneNumber'
import {
  IErrorValidationIdDocument,
  useValidationComponent,
} from '../../../../../views/medical/ProfileMedical/ProfileMedicalForm/Hooks/ProfileMedicalHook'

import newDateText from '../NewDateText.json'

export interface IuseModalDatePicker {
  able: boolean
  availableHours: string[] | undefined
  daysAvailability: ICalendarData | undefined
  idTypes: string[]
  overbookingHours: {
    label: string
    value: string
  }[]
  setIdType: React.Dispatch<React.SetStateAction<string>>
  error: boolean
  message: string
  switchOverbooking: () => void
  isFoundPatient: boolean
  handleChangeHoursData: (event: string) => void
  saveNewDate: (event: { preventDefault: () => void }) => Promise<void>
  onValidationFocus: (index: number) => void
  handleChangeDate: (value: dayjs.Dayjs | null) => void
  handleChangeOffice: (office: IMedicalOffice | undefined) => void
  validateIdType: boolean
  textErrorIdNumber: () => string
  validateName: boolean
  validateLastName: boolean
  validatePhone: boolean
  isDisabled: boolean
  validateEmailText: () => string
  validatePhoneText: () => string
  validateComment: boolean
  validateEmailField: boolean
  idType: string
  idNumber: string
  setIdNumber: React.Dispatch<React.SetStateAction<string>>
  validateIdNumber: boolean
  validatorIdDocument: IErrorValidationIdDocument
  name: string
  setName: React.Dispatch<React.SetStateAction<string>>
  lastName: string
  setLastName: React.Dispatch<React.SetStateAction<string>>
  mobile: string
  setMobile: React.Dispatch<React.SetStateAction<string>>
  isAppointmentOverbooking: boolean
  hourFrom: dayjs.Dayjs | null
  setAppointmentOverbookingTo: React.Dispatch<React.SetStateAction<string>>
  appointmentOverbookingTo: string
  errorInOverbookingHours: string
  setAppointmentOverbookingFrom: React.Dispatch<React.SetStateAction<string>>
  appointmentOverbookingFrom: string
  selectedDate: dayjs.Dayjs | null
  idDoctor: string
  selectedOffice: string
  email: string
  setEmail: React.Dispatch<React.SetStateAction<string>>
  comment: string
  setComment: React.Dispatch<React.SetStateAction<string>>
  appointmentType: string
  setAppointmentType: React.Dispatch<React.SetStateAction<string>>
  appointmentTypeList: string[]
  isCustomType: boolean
  setCustomType: React.Dispatch<React.SetStateAction<string>>
  customType: string
  customDuration: CustomDuration
  setCustomDuration: React.Dispatch<React.SetStateAction<CustomDuration>>
  loading: boolean
}

type OpeningHoursType = {
  [day: string]: {
    hour_open: string
    hour_close: string
  }
}

export function useModalDatePicker(
  handleUpdateCalendar: (officeId: string) => void,
  handleClose: () => void,
): IuseModalDatePicker {
  const {
    able,
    availableHours,
    daysAvailability,
    selectedOffice,
    selectedDate,
    hourFrom,
    hourTo,
    idDoctor,
    setSelectedOffice,
    setSelectedDate,
    setHourFrom,
    setHourTo,
    fillHours,
    isTimeOverlapping,
    setAppointmentTypeList,
    appointmentTypeList,
    setDuration,
    duration,
    setAppointmentType,
    appointmentType,
    customType,
    setCustomType,
    customDuration,
    setCustomDuration,
    setIsCustomType,
    isCustomType,
    setAble,
    setAvailableHours,
  } = useNewDate()

  const { handleAlert } = useContext(AccountContext) as AuthContextType

  const { officeSelected } = useSelectOffice()

  const [idTypes] = useState<string[]>([newDateText.dni, newDateText.passport])
  const [isAppointmentOverbooking, setIsAppointmentOverbooking] =
    useState<boolean>(false)
  const [appointmentOverbookingFrom, setAppointmentOverbookingFrom] =
    useState<string>('')
  const [appointmentOverbookingTo, setAppointmentOverbookingTo] =
    useState<string>('')
  const [validatingOverbooking, setValidatingOverbooking] =
    useState<boolean>(false)
  const [errorInOverbookingHours, setErrorInOverbookingHours] =
    useState<string>('')
  const [overbookingHours, setOverbookingHours] = useState<
    { label: string; value: string }[]
  >([])
  const [name, setName] = useState<string>('')
  const [lastName, setLastName] = useState<string>('')
  const [loading, setLoading] = useState<boolean>(false)

  const [email, setEmail] = useState<string>('')
  const [mobile, setMobile] = useState<string>('')
  const [idNumber, setIdNumber] = useState<string>('')
  const [idType, setIdType] = useState<string>('Cédula')
  const [comment, setComment] = useState<string>('')
  const [consultationTimeMinutes, setConsultationTimeMinutes] =
    useState<number>(0)

  const [error, setError] = useState<boolean>(false)
  const [message, setMessage] = useState<string>('')

  const [isFoundPatient, setIsFoundPatient] = useState<boolean>(false)
  const [ValidationFocus, setValidationFocus] = useState<boolean[]>([
    false,
    false,
    false,
    false,
    false,
    false,
    false,
  ])

  const { fetchValidationIdDocument, validatorIdDocument } =
    useValidationComponent()

  const handleChangeHoursData = (event: string): void => {
    const hour: dayjs.Dayjs = dayjs(`${dayjs().format('YYYY-MM-DD')}T${event}`)

    setHourFrom(hour)
    setHourTo(
      hour.add(consultationTimeMinutes, 'minute').format('YYYY-MM-DDTHH:mm:00'),
    )
  }

  const formattedDate = dayjs(selectedDate).format('YYYY-MM-DD')

  const saveNewDate = async (event: {
    preventDefault: () => void
  }): Promise<void> => {
    event.preventDefault()
    setLoading(true)
    setError(false)
    setMessage('')
    if (!isAppointmentOverbooking) {
      const recall: INewDateData = await PostNewDateUtility({
        appointment_date: formattedDate,
        comment: comment,
        email: email,
        hour_from: dayjs(hourFrom).format('HH:mm'),
        hour_to: dayjs(hourTo).format('HH:mm'),
        patient_id_number: idNumber,
        office_id: selectedOffice,
        patient_last_name: lastName,
        patient_name: name,
        phone: mobile,
        user_id: idDoctor,
        duration: isCustomType ? customDuration.value : duration,
        appointment_reason: isCustomType ? customType : appointmentType,
        reason_type: isCustomType ? 'CUSTOM' : 'DEFAULT',
      })
      if (recall.status === 0) {
        setError(true)
        setMessage(recall.data)
        handleAlert(true, recall.data, 'error')
      }
      if (recall.status === 1) {
        handleUpdateCalendar(selectedOffice)
        handleAlert(true, 'Cita agendada con éxito.', 'success')
      }
    } else {
      const appointmentOverbookingResponse =
        await PostNewOverbookingAppointmentUtility({
          appointment_date: formattedDate,
          comment: comment,
          email: email,
          hour_from: dayjs(appointmentOverbookingFrom, 'HH:mm').format('HH:mm'),
          hour_to: dayjs(appointmentOverbookingTo, 'HH:mm').format('HH:mm'),
          patient_id_number: idNumber,
          office_id: selectedOffice,
          patient_last_name: lastName,
          patient_name: name,
          phone: mobile,
          user_id: idDoctor,
          appointment_reason: appointmentType,
          reason_type: 'DEFAULT'
        })
      if (appointmentOverbookingResponse.status === 0) {
        setError(true)
        setMessage(appointmentOverbookingResponse.data)
        handleAlert(true, appointmentOverbookingResponse.data, 'error')
      }
      if (appointmentOverbookingResponse.status === 1) {
        handleUpdateCalendar(selectedOffice)
        handleAlert(true, 'Cita agendada con éxito.', 'success')
      }
    }
    setName('')
    setLastName('')
    setSelectedOffice('')
    setMobile('')
    setEmail('')
    setIdNumber('')
    setSelectedDate(null)
    setHourFrom(null)
    setHourTo('')
    setComment('')
    setIsAppointmentOverbooking(false)
    setAppointmentOverbookingFrom('')
    setAppointmentOverbookingTo('')
    setErrorInOverbookingHours('')
    setAppointmentType('')
    setCustomType('')
    setCustomDuration({
      name: '',
      value: 0,
    })
    setIsCustomType(false)
    handleClose()

    setLoading(false)
  }

  const onValidationFocus = (index: number): void => {
    const newValidationFocus: boolean[] = [...ValidationFocus]

    newValidationFocus[index] = true
    setValidationFocus(newValidationFocus)
  }

  const handleChangeDate = (value: dayjs.Dayjs | null): void =>
    setSelectedDate(value)
  const handleChangeOffice = (office: IMedicalOffice | undefined): void => {
    setSelectedOffice(office?.office_id as string)
    setAppointmentTypeList(
      office?.appointment_types?.length
        ? [
            ...(office?.appointment_types?.map(
              (type) => type.name,
            ) as string[]),
            'Otro',
          ]
        : [],
    )
    setConsultationTimeMinutes(
      parseInt((office?.consultation_time_minutes as string).split(' ')[0]),
    )
    setAppointmentType('')
    setSelectedDate(null)
    setHourFrom(null)
    setAvailableHours(undefined)
    setIsCustomType(false)
    setCustomType('')
    setCustomDuration({ name: '', value: 0 })
    setIsAppointmentOverbooking(false)
    setAble(true)
  }

  const fetchPatientInfoByCiUtility = async (
    idNumberPatient: string,
  ): Promise<void> => {
    const { data, status } = await getPatientInfoByCiUtility(idNumberPatient)

    if (status) {
      setIsFoundPatient(true)
      const result: IBodyPatientInfo = data as IBodyPatientInfo

      setEmail(result.email_patient)
      setName(result.name_patient)
      setLastName(result.last_name_patient)
      setMobile(result.number_phone_patient)
    } else {
      setIsFoundPatient(false)
      setEmail('')
      setName('')
      setLastName('')
      setMobile('')
    }
  }

  const searchDelayed = useMemo(
    () => debounce(fetchPatientInfoByCiUtility, 600),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  )

  const validatorDelayed = useMemo(
    () => debounce(fetchValidationIdDocument, 600),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  )

  const validateIdType: boolean =
    !validateInput(idType) && idType?.length === 0 && ValidationFocus[0]

  const validateIdNumberCI: boolean =
    validateNumberInput(idNumber) && idNumber.length === 10
  const validateIdNumberPassport: boolean =
    validateInput(idNumber) && idNumber.length >= 3
  const validateIdNumber: boolean =
    (!validateInput(idNumber) &&
      idNumber?.length === 0 &&
      ValidationFocus[1]) ||
    idType === 'Cédula'
      ? !validateIdNumberCI
      : !validateIdNumberPassport

  const validateName: boolean =
    !validateInput(name) && name?.length === 0 && ValidationFocus[2]

  const validateLastName: boolean =
    !validateInput(lastName) && lastName?.length === 0 && ValidationFocus[3]

  const validatePhone: boolean =
    (!validateInput(mobile) && mobile?.length === 0 && ValidationFocus[4]) ||
    (!validatePhoneNumber(mobile) && mobile?.length > 0)

  const validateEmailField: boolean =
    (email?.length === 0 && ValidationFocus[5]) ||
    (!validateEmail(email) && email?.length > 0)

  const validateComment: boolean = comment?.length === 0 && ValidationFocus[6]

  const validatePhoneText = (): string =>
    !validateInput(mobile) && mobile?.length === 0 && ValidationFocus[4]
      ? newDateText.required_field
      : !validatePhoneNumber(mobile) && mobile?.length > 0
        ? newDateText.valid_phone_text
        : ''

  const validateEmailText = (): string =>
    email?.length === 0 && ValidationFocus[5]
      ? newDateText.required_field
      : !validateEmail(email) && email?.length > 0
        ? newDateText.valid_email_text
        : ''

  const validateIdErrorText = (): string => {
    let idErrorText = ''

    if (idNumber.length === 0) {
      idErrorText = newDateText.required_field

      return idErrorText
    }
    switch (idType) {
      case 'Cédula':
        if (!validateIdNumberCI) {
          idErrorText = newDateText.id_number_length
        } else {
          idErrorText = ''
        }
        break

      case 'Pasaporte':
        if (!validateIdNumberPassport) {
          idErrorText = newDateText.passport_length
        } else {
          idErrorText = ''
        }
        break

      default:
        idErrorText = ''
    }

    return idErrorText
  }

  const disabledButton = (): boolean => {
    return (
      loading ||
      (validatingOverbooking && isAppointmentOverbooking) ||
      name?.length === 0 ||
      lastName?.length === 0 ||
      mobile?.length === 0 ||
      email?.length === 0 ||
      idNumber?.length === 0 ||
      comment?.length === 0 ||
      (selectedDate === null && !isAppointmentOverbooking) ||
      (hourFrom === null && !isAppointmentOverbooking) ||
      !validatePhoneNumber(mobile) ||
      !validateEmail(email) ||
      !selectedDate?.isValid() ||
      (!hourFrom?.isValid() && !isAppointmentOverbooking) ||
      validatorIdDocument.status ||
      (appointmentOverbookingTo === '' && isAppointmentOverbooking) ||
      (appointmentOverbookingFrom === '' && isAppointmentOverbooking) ||
      (errorInOverbookingHours !== '' && isAppointmentOverbooking) ||
      (!appointmentType && !isCustomType) ||
      (!customType && isCustomType) ||
      (customDuration.name === '' && isCustomType)
    )
  }

  const isDisabled: boolean = disabledButton()

  useEffect(() => {
    setIsFoundPatient(validateIdNumber)
    setEmail('')
    setName('')
    setLastName('')
    setMobile('')
    if (!validateIdNumber) {
      const document_type: 'CI' | 'PASSPORT' =
        idType === 'Cédula' ? 'CI' : 'PASSPORT'

      if (
        (document_type === 'CI' && idNumber.length === 10) ||
        (document_type === 'PASSPORT' && idNumber.length > 2)
      ) {
        validatorDelayed({
          document: idNumber,
          document_type: document_type,
          type: 'APPOINTMENT',
        })
        searchDelayed(idNumber)
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idNumber, searchDelayed, validateIdNumber])

  useEffect(() => {
    setValidatingOverbooking(true)
    if (selectedOffice && selectedDate) {
      const office = officeSelected.find(
        (ofc: IMedicalOffice) => ofc.office_id === selectedOffice,
      ) as IMedicalOffice
      setOverbookingHours(
        fillHours(
          selectedDate.format('YYYY-MM-DD'),
          office?.opening_hours as OpeningHoursType,
        ),
      )
    }
    if (appointmentOverbookingFrom !== '' && appointmentOverbookingTo !== '') {
      if (
        dayjs(appointmentOverbookingFrom, 'HH:mm').isSame(
          dayjs(appointmentOverbookingTo, 'HH:mm'),
        ) ||
        dayjs(appointmentOverbookingFrom, 'HH:mm').isAfter(
          dayjs(appointmentOverbookingTo, 'HH:mm'),
        )
      ) {
        setErrorInOverbookingHours(
          'La hora de inicio no puede ser mayor o igual a la hora de fin',
        )
      } else {
        setErrorInOverbookingHours('')
        if (selectedOffice) {
          getAppointmentsByDoctorIdUtility({
            doctor_id: idDoctor,
            date: formattedDate,
          })
            .then((response) => {
              const appointments = (response.data as IAppointmentsByDoctorIdResponse).data
              const overlapping = isTimeOverlapping(
                appointments,
                appointmentOverbookingFrom,
                appointmentOverbookingTo,
              )
              if (overlapping) {
                setErrorInOverbookingHours(
                  'Ya existe una cita en este rango de horas',
                )
              } else {
                setErrorInOverbookingHours('')
              }
              setValidatingOverbooking(false)
            })
            .catch(() => {
              setValidatingOverbooking(false)
            })
        }
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    appointmentOverbookingTo,
    appointmentOverbookingFrom,
    officeSelected,
    selectedOffice,
    selectedDate,
  ])

  useEffect(() => {
    setAppointmentOverbookingFrom('')
    setAppointmentOverbookingTo('')
  }, [selectedDate])

  useEffect(() => {
    const type = officeSelected
      .find((ofc: IMedicalOffice) => ofc.office_id === selectedOffice)
      ?.appointment_types?.find((type) => type.name === appointmentType)
    if (type) {
      setIsCustomType(false)
      setDuration(type?.duration as number)
    } else if (appointmentType && !isAppointmentOverbooking) {
      setIsCustomType(true)
    }
  }, [appointmentType])

  const textErrorIdNumber = (): string => {
    if (!validateIdErrorText()) {
      return validatorIdDocument.message
    }

    return validateIdErrorText()
  }

  const switchOverbooking = (): void => {
    setIsAppointmentOverbooking(!isAppointmentOverbooking)
    setHourFrom(null)
    setHourTo('')
    setAppointmentOverbookingFrom('')
    setAppointmentOverbookingTo('')
    setErrorInOverbookingHours('')
  }

  useEffect(() => {
    setDuration(15)
    if (isAppointmentOverbooking) {
      setAppointmentTypeList([...appointmentTypeList, 'Fuera de horario'])
      setAppointmentType('Fuera de horario')
      setIsCustomType(false)
      setHourFrom(null)
      setHourTo('')
      setAble(false)
    } else {
      setAppointmentTypeList(
        appointmentTypeList.filter((type) => type !== 'Fuera de horario'),
      )
      setSelectedDate(null)
      setAble(true)
      setHourFrom(null)
      setHourTo('')
      setAppointmentType('')
    }
  }, [isAppointmentOverbooking])
  return {
    able,
    availableHours,
    daysAvailability,
    error,
    idTypes,
    message,
    overbookingHours,
    setIdType,
    switchOverbooking,
    handleChangeDate,
    handleChangeHoursData,
    handleChangeOffice,
    isFoundPatient,
    onValidationFocus,
    saveNewDate,
    validateIdType,
    textErrorIdNumber,
    validateLastName,
    validateName,
    validatePhone,
    isDisabled,
    validateEmailText,
    validatePhoneText,
    validateComment,
    validateEmailField,
    idType,
    idNumber,
    setIdNumber,
    validateIdNumber,
    validatorIdDocument,
    name,
    setName,
    lastName,
    setLastName,
    mobile,
    setMobile,
    isAppointmentOverbooking,
    hourFrom,
    setAppointmentOverbookingTo,
    appointmentOverbookingTo,
    errorInOverbookingHours,
    setAppointmentOverbookingFrom,
    appointmentOverbookingFrom,
    selectedDate,
    idDoctor,
    selectedOffice,
    email,
    setEmail,
    comment,
    setComment,
    appointmentType,
    setAppointmentType,
    appointmentTypeList,
    isCustomType,
    customType,
    setCustomType,
    customDuration,
    setCustomDuration,
    loading
  }
}
