import { styled } from '@mui/material'

import TextFieldWrapper from '../wrapper/TextFieldWrapper'

export const InputField = styled( TextFieldWrapper )(({theme}) => ({
  '& .MuiInputLabel-root': {},
  '& .MuiInputBase-root': {
    borderRadius: '52px',
    backgroundColor: theme.palette.common.white,
    fontSize: '12px',
    color: theme?.palette?.text.primary,
    fontFamily: 'Helvetica',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    boxShadow: '1px 1px 7px 7px rgba(0, 0, 0, 0.03)',
    paddingLeft: '20px',
    '@media (min-width: 1600px)': {
      fontSize: '16px',
    },
  },
  '& fieldset': { borderColor: theme.palette.common.white, fontSize: '16px', },
  '& .MuiOutlinedInput-root:hover': {
    '& > fieldset': {
      borderColor: theme?.palette?.primary?.main,

    }
  },
  '& .MuiOutlinedInput-root.Mui-disabled': {
    '& > fieldset': {
      border: 'none'
    }
  },
  '&::placeholder': {
    marginLeft: '1.875rem'
  }
}))
