import { GridProps } from '@mui/material'
import React from 'react'

import { GridWrapper } from '..';

export function GridItem( props: GridProps ): React.JSX.Element {
  return <GridWrapper
    item
    textAlign={'center'}
    display={'flex'}
    justifyContent={'center'}
    alignItems={'center'}
    {...props}
  />
}