import { MedicalExams } from '../../../domain/useCase/MedicalExamsUseCase'
import { IGetMedicalExamsResponse } from '../../../infrastructure/dtos/MedicalExamsItem'
import { MedicalExamsMapper } from '../../../infrastructure/http/mappers/MedicalExams/MedicalExamsMapper'
import { MedicalExamsRepositoryImpl } from '../../../infrastructure/RepositoryImpl/MedicalExamsRepositorImpl'

interface ServiceResponse {
  status: number
  data: Omit<IGetMedicalExamsResponse, 'headers'>
}

export async function getMedicalExamsService(
  patientId: string,
): Promise<ServiceResponse> {
  try {
    const useCase = new MedicalExams(
      new MedicalExamsRepositoryImpl(new MedicalExamsMapper()),
    )
    return {
      status: 1,
      data: await useCase.getMedicalExams(patientId),
    }
  } catch (error: unknown) {
    return {
      status: 0,
      data: {
        body: { message: (error as Error).message, data: [] },
        statusCode: 500,
      },
    }
  }
}
