import { ClickAwayListener, ClickAwayListenerProps } from '@mui/material'
import React from 'react'

type ClickAwayListenerWrapperProps = Omit<ClickAwayListenerProps, 'ref'> &
  React.RefAttributes<HTMLElement>

const ClickAwayListenerWrapper: React.ForwardRefExoticComponent<ClickAwayListenerWrapperProps> =
  React.forwardRef<HTMLElement, ClickAwayListenerProps>((props, ref) => (
    <ClickAwayListener {...props} ref={ref} />
  ))

export default ClickAwayListenerWrapper
