import { AppointmentsTypesRepository } from '../../domain/repository/AppointmentsTypesRepository'
import { IApiResponseGetAppointmentDurations } from '../dtos/AppointmentDurations'
import { IApiResponseGetAppointmentReasons } from '../dtos/AppointmentReasons'
import { IAppointmentTypesDataSource } from '../http/dataSource/AppointmentTypesDataSource'


export class AppointmentTypesRepositoryImpl implements AppointmentsTypesRepository {
  dataSource: IAppointmentTypesDataSource

  constructor(_datasource: IAppointmentTypesDataSource) {
    this.dataSource = _datasource
  }

  getAppointmentDurations(): Promise<IApiResponseGetAppointmentDurations> {
    return this.dataSource.getAppointmentDurations()
  }

  getAppointmentReasons(): Promise<IApiResponseGetAppointmentReasons> {
    return this.dataSource.getAppointmentReasons()
  }
}
