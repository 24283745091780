import { SelectChangeEvent } from '@mui/material'
import { useContext, useEffect, useState } from 'react'

import {
  AccountContext,
  AuthContextType,
} from '../../../contexts/AccountContext'
import { useDoctorInformationStore } from '../../../contexts/DoctorInformationState'
import { useFiltersStore } from '../../../contexts/FiltersState'
import { useSelectOffice } from '../../../contexts/SelectOfficeState'
import {
  IApiResponseOffices,
  IMedicalOffice,
} from '../../../infrastructure/dtos/Offices'

import {
  IOfficeService,
  OfficesService,
} from '../../../services/Contracts/Persistencia/OfficesService'

interface IUseSelectOfficeHook {
  handleChangeOffice: (event: SelectChangeEvent<unknown>) => void
  isInactive: boolean
  error: boolean
  message: string
  office: string
  list: IMedicalOffice[]
}

function useSelectOfficeHook(): IUseSelectOfficeHook {
  const { filtersSelected, setFiltersSelected } = useFiltersStore()
  const [list, setList] = useState<IMedicalOffice[]>([])
  const [office, setOffice] = useState<string>('')
  const [error, setError] = useState<boolean>(false)
  const [message, setMessage] = useState<string>('')

  const { handleAlert } = useContext(AccountContext) as AuthContextType

  const { setOfficeSelected } = useSelectOffice()

  const { doctorInformation } = useDoctorInformationStore()
  const [isInactive, setIsInactive] = useState<boolean>(false)
  useEffect(() => {
    const fetchOffices = async (): Promise<void> => {
      try {
        if (!doctorInformation.complete_register) {
          return
        }
        if (!doctorInformation.user_id) {
          setError(true)
          setMessage('Error al obtener el id del medico')

          return
        }
        const valores: IOfficeService = await OfficesService(
          doctorInformation?.user_id,
        )

        if ((valores.data as IApiResponseOffices)?.body) {
          // order list, in last position data with office_status = 'INACTIVE'
          const list = (valores.data as IApiResponseOffices)?.body
            .medical_offices
          const inactive = list.filter(
            (item: IMedicalOffice) => item.office_status === 'INACTIVE',
          )
          const active = list.filter(
            (item: IMedicalOffice) => item.office_status !== 'INACTIVE',
          )
          setList([...active, ...inactive])

          setOffice(
            (
              (valores.data as IApiResponseOffices)?.body
                .medical_offices[0] as IMedicalOffice
            ).office_id,
          )

          setIsInactive(
            (valores.data as IApiResponseOffices)?.body.medical_offices[0]
              .office_status === 'INACTIVE',
          )
        }
      } catch (error) {
        setError(true)
        setMessage('Error al obtener el listado de consultorios')
        handleAlert(true, 'Error al obtener los días disponibles', 'error')
      }
    }

    fetchOffices()
  }, [doctorInformation.user_id])

  const handleChangeOffice = (event: SelectChangeEvent<unknown>): void => {
    const office = list.find(
      (item: IMedicalOffice) => item.office_id === event.target.value,
    )
    if (office?.office_status === 'INACTIVE') {
      setIsInactive(true)
    } else {
      setIsInactive(false)
    }
    setOffice(event.target?.value as string)
  }

  useEffect(() => {
    if (list && list.length > 0) {
      const updatedInfo = list.map((ofc: IMedicalOffice) => {
        if (ofc.office_id === office && !ofc.selected) {
          ofc.selected = true
        } else if (ofc.selected) {
          delete ofc.selected
        }

        return ofc
      })

      setOfficeSelected(updatedInfo)

      setFiltersSelected({
        ...filtersSelected,
        officeId: office,
        isSubmitted: true,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [office])

  return {
    handleChangeOffice,
    error,
    isInactive,
    message,
    office,
    list,
  }
}

export default useSelectOfficeHook
