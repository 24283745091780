import { AxiosError } from 'axios'

import { IApiResponseGetAppointmentDurations } from '../../../dtos/AppointmentDurations'
import { IApiResponseGetAppointmentReasons } from '../../../dtos/AppointmentReasons'
import { IAppointmentTypesDataSource } from '../../dataSource/AppointmentTypesDataSource'
import { http } from '../../Http'


export class AppointmentTypesMapper implements IAppointmentTypesDataSource {


  async getAppointmentDurations(): Promise<IApiResponseGetAppointmentDurations> {
    try {
      const { data } = await http.get<IApiResponseGetAppointmentDurations>('send_data/appointment_durations')
      return data
    } catch (error: unknown) {
      return {
        statusCode: (error as AxiosError)?.response?.status as number,
        body: {
          message: (error as AxiosError)?.response?.statusText as string,
          data: [],
        },
        headers: {
          'Access-Control-Allow-Headers': '',
          'Access-Control-Allow-Origin': '',
          'Access-Control-Allow-Methods': '',
        },
      }
    }
  }

  async getAppointmentReasons(): Promise<IApiResponseGetAppointmentReasons> {
    try {
      const { data } = await http.get<IApiResponseGetAppointmentReasons>('send_data/appointment_reasons')
      return data
    } catch (error: unknown) {
      return {
        statusCode: (error as AxiosError)?.response?.status as number,
        body: {
          message: (error as AxiosError)?.response?.statusText as string,
          data: [],
        },
        headers: {
          'Access-Control-Allow-Headers': '',
          'Access-Control-Allow-Origin': '',
          'Access-Control-Allow-Methods': '',
        },
      }
    }
  }
}
