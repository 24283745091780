import { AxiosError, AxiosResponse } from 'axios'

import {
  IApiResponseSaveNewDate,
  IApiResponseSaveOverbooking,
  ISaveNewDate,
} from '../../../dtos/NewDate'
import NewDateDataSource from '../../dataSource/NewDateDataSource'
import { http } from '../../Http'

export default class NewDateMapper implements NewDateDataSource {
  async postNewDate(
    dataNewDate: ISaveNewDate,
  ): Promise<IApiResponseSaveNewDate> {
    const data = await http
      .post<IApiResponseSaveNewDate>(`save_data/save_appointment`, {
        body: dataNewDate,
      })
      .then((response: AxiosResponse) => {
        const { data } = response

        return data
      })
      .catch((error: AxiosError) => {
        const data = {
          statusCode: error.response?.status,
          body: error.response?.statusText,
          headers: {
            'Access-Control-Allow-Headers': '',
            'Access-Control-Allow-Origin': '',
            'Access-Control-Allow-Methods': '',
          },
        }

        return data
      })

    return data
  }

  async postNewOverbookingAppointment(
    appointmentData: ISaveNewDate,
  ): Promise<IApiResponseSaveOverbooking> {
    try {
      const { data } = await http.post<IApiResponseSaveOverbooking>(
        'save_data/appointment_overbooking',
        {
          body: appointmentData,
        },
      )
      return data
    } catch (error: unknown) {
      return {
        statusCode: (error as AxiosError).response?.status as number,
        body: (error as AxiosError).response?.statusText as string,
        headers: {
          'Access-Control-Allow-Headers': '',
          'Access-Control-Allow-Origin': '',
          'Access-Control-Allow-Methods': '',
        },
      }
    }
  }
}
