import CloseIcon from '@mui/icons-material/Close'
import { Backdrop } from '@mui/material'

import dayjs from 'dayjs'
import React, { useContext, useEffect, useState } from 'react'

import {
  BottonGrid,
  BoxModal,
  CloseButton,
  FadeModal,
  ModalTitle,
  TypographyModalTittle,
} from './styles'
import AutocompleteComponent from '../../../../../components/Autocomplete'
import ModalDatePicker from '../../../../../components/ModalDate/components/ModalDatePicker'
import { useNewDate } from '../../../../../components/ModalDate/components/ModalDatePicker/hook/useNewDate'
import { TextError } from '../../../../../components/ModalDate/ModalDateStyles'
import { DisableTextInput } from '../../../../../components/StyledComponents/DisableTextInput'
import SubmitButton from '../../../../../components/SubmitButton'
import {
  GridWrapper,
  MenuItemWrapper,
  ModalWrapper,
  SelectWrapper,
  TypographyWrapper,
} from '../../../../../components/wrapper'
import {
  AccountContext,
  AuthContextType,
} from '../../../../../contexts/AccountContext'
import { useDoctorInformationStore } from '../../../../../contexts/DoctorInformationState'
import { useSelectOffice } from '../../../../../contexts/SelectOfficeState'
import {
  IAppointmentInfo,
  IAppointmentInfoResponse,
  IAppointmentsByDoctorIdResponse,
} from '../../../../../infrastructure/dtos/Appointments'
import {
  BodyAvailableHours,
  IResponseAvailableHours,
} from '../../../../../infrastructure/dtos/Calendar'
import {
  ICalendarData,
  IResponseGetCalendarEvent,
} from '../../../../../infrastructure/dtos/CalendarInfo'
import {
  IMedicalOffice,
  OpeningHoursType,
} from '../../../../../infrastructure/dtos/Offices'
import { getAvailableHoursInADay } from '../../../../../services/Contracts/Persistencia/Calendar'
import { GetDaysAvailabilityInAMonth } from '../../../../../services/Contracts/Persistencia/CalendarInfoService'
import { ReschedulingAppointmentService } from '../../../../../services/Contracts/Persistencia/ReschedulingAppointmentService'
import {
  getAppointmentInfoUtility,
  getAppointmentsByDoctorIdUtility,
} from '../../../../../services/Contracts/Utility/AppointmentUtility'
import { OfficeList } from '../../../../Patient/ScheduleAppointment/components/OfficesList/OfficesList'
import { BoxTimeModal } from '../../../CalendarForm/Styles/HoursOfNonAttendanceModalStyles'
import { FormControlStyled } from '../../../Profile/components/UpdateSchedule/components/HourInput/HourInputStyle'
import { ModalPad } from '../NextAppointment/styles'

interface IModalProps {
  handleClose: () => void
  open: boolean
  officeId: string
  appointmentId: string
  handleSendRescheduleAfterSubmit: () => void
}

function RescheduleModal({
  handleClose,
  open,
  officeId,
  appointmentId,
  handleSendRescheduleAfterSubmit,
}: IModalProps): JSX.Element {
  const { doctorInformation } = useDoctorInformationStore()

  const [disableOffices, setDisableOffices] = useState(false)
  const { handleAlert } = useContext(AccountContext) as AuthContextType
  const idDoctor: string = doctorInformation?.user_id as string
  const [appointment, setAppointment] = useState<IAppointmentInfo>()
  const [selectedOffice, setSelectedOffice] = useState<string>(officeId)
  const [selectedDate, setSelectedDate] = useState<dayjs.Dayjs | null>(null)
  const [daysAvailability, setDaysAvailability] = useState<ICalendarData>()
  const [hourAvailavility, setHourAvailavility] = useState<string[]>([])
  const [hour, setHour] = useState<string>('')
  const [loading, setLoading] = useState<boolean>(false)
  const [officeType, setOfficeType] = useState<string>('')
  const [overbookingHours, setOverbookingHours] = useState<
    { label: string; value: string }[]
  >([])
  const [validatingOverbooking, setValidatingOverbooking] =
    useState<boolean>(false)

  const [appointmentOverbookingFrom, setAppointmentOverbookingFrom] =
    useState<string>('')

  const [appointmentOverbookingTo, setAppointmentOverbookingTo] =
    useState<string>('')

  const [errorInOverbookingHours, setErrorInOverbookingHours] =
    useState<string>('')

  const [appointmentDuration, setAppointmentDuration] = useState<number>(0)

  const [errorInOffices, setErrorInOffices] = useState<string>('')

  const { officeSelected } = useSelectOffice()

  const { fillHours, isTimeOverlapping, able, setAble } = useNewDate()

  const formattedDate = dayjs(selectedDate).format('YYYY-MM-DD')

  useEffect(() => {
    setErrorInOffices('')
    const types = officeSelected.filter(
      (office) => office.office_id === selectedOffice,
    )[0]?.appointment_types
    if (appointment?.doctor_type !== 'DOCTOR_CM') {
    }
    const getDuration = types?.filter(
      (type) => type.name === appointment?.appointment_reason,
    )

    const filterType = types?.some(
      (type) => type.name === appointment?.appointment_reason,
    )
    if (
      (!filterType &&
        appointment?.appointment_type !== 'OVERBOOKING' &&
        appointment?.reason_type === 'DEFAULT') ||
      (appointment?.office_id !== selectedOffice &&
        appointment?.reason_type === 'CUSTOM')
    ) {
      setSelectedDate(null)
      setAble(true)
      setHourAvailavility([])
      setErrorInOffices(
        'No existe el tipo de cita en el consultorio seleccionado',
      )
      return
    }

    if (!filterType || appointment?.doctor_type === 'DOCTOR_CM') {
      setAppointmentDuration(
        appointment?.consultation_time_minutes.split(
          ' ',
        )[0] as unknown as number,
      )
      setOfficeType('OFFICE_CM')
    } else {
      setAppointmentDuration(getDuration?.[0].duration as number)
      setOfficeType('OFFICE')
    }

    setAble(false)

    setValidatingOverbooking(true)
    if (selectedOffice && selectedDate) {
      const office = officeSelected.find(
        (ofc: IMedicalOffice) => ofc.office_id === selectedOffice,
      ) as IMedicalOffice
      setOverbookingHours(
        fillHours(
          selectedDate.format('YYYY-MM-DD'),
          office?.opening_hours as OpeningHoursType,
        ),
      )
    }
    if (appointmentOverbookingFrom !== '' && appointmentOverbookingTo !== '') {
      if (
        dayjs(appointmentOverbookingFrom, 'HH:mm').isSame(
          dayjs(appointmentOverbookingTo, 'HH:mm'),
        ) ||
        dayjs(appointmentOverbookingFrom, 'HH:mm').isAfter(
          dayjs(appointmentOverbookingTo, 'HH:mm'),
        )
      ) {
        setErrorInOverbookingHours(
          'La hora de inicio no puede ser mayor o igual a la hora de fin',
        )
      } else {
        setErrorInOverbookingHours('')
        if (selectedOffice) {
          getAppointmentsByDoctorIdUtility({
            date: formattedDate,
            doctor_id: idDoctor,
          })
            .then((response) => {
              const appointments = (
                response.data as IAppointmentsByDoctorIdResponse
              ).data
              const overlapping = isTimeOverlapping(
                appointments,
                appointmentOverbookingFrom,
                appointmentOverbookingTo,
              )
              if (overlapping) {
                setErrorInOverbookingHours(
                  'Ya existe una cita en este rango de horas',
                )
              } else {
                setErrorInOverbookingHours('')
              }
              setValidatingOverbooking(false)
            })
            .catch(() => {
              setValidatingOverbooking(false)
            })
        }
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    appointmentOverbookingTo,
    appointmentOverbookingFrom,
    officeSelected,
    selectedOffice,
    selectedDate,
  ])

  const fetchDaysAvailabilityInAMonth = async (): Promise<void> => {
    try {
      if (appointment) {
        const idUser = officeSelected.filter((office) => office.office_id === selectedOffice)[0].user_id
        const duration =
          appointment.appointment_type !== 'OVERBOOKING'
            ? appointmentDuration
            : 15
        const { status, data } = await GetDaysAvailabilityInAMonth(
          idUser,
          selectedOffice,
          Number(duration),
          officeType
        )
        if (status) {
          setDaysAvailability((data as IResponseGetCalendarEvent).body)
        }
      }
    } catch (error: unknown) {
      handleAlert(true, 'Error al obtener los días disponibles', 'error')
    }
  }

  const fetchAvailableHoursInADay = async (): Promise<void> => {
    try {
      setHour('')
      const duration =
        appointment?.appointment_type !== 'OVERBOOKING'
          ? appointmentDuration
          : 15
      const data_selected = selectedDate?.format('YYYY-MM-DD') as string
      const idUser = officeSelected.filter((office) => office.office_id === selectedOffice)[0].user_id
      const data: IResponseAvailableHours = await getAvailableHoursInADay({
        user_id: idUser,
        date_search: data_selected,
        office_id: selectedOffice,
        duration: Number(duration),
        officeType
      })
      let pushed = false
      const result: string[] = (data.body as BodyAvailableHours).data
        .intervals_available_hours.reduce((acc: string[], hour: string) => {
          if (hour === appointment?.hour_from) {
            pushed = true
          } else if (
            dayjs(`${selectedDate?.format('YYYY-MM-DD')}T${hour}`).isAfter(
              dayjs(
                `${selectedDate?.format('YYYY-MM-DD')}T${appointment?.hour_from}`,
              ),
            ) &&
            !pushed
          ) {
            if (data_selected === appointment?.appointment_date) {
              acc.push(appointment?.hour_from as string)
              pushed = true
            }
          }
          acc.push(hour)

          return acc
        }, [])

      setHourAvailavility(result)

      // getUnavailableRangeHours(data.body as IAvailableHours)
    } catch (error: unknown) { }
  }

  const fetchAppointmentInfoUtility = async (): Promise<void> => {
    try {
      const { data } = await getAppointmentInfoUtility(appointmentId)
      const appointmentDb = (data as IAppointmentInfoResponse)?.appointment

      if (appointmentDb?.doctor_type !== 'DOCTOR_CM') {
        setDisableOffices(false)
      } else {
        setDisableOffices(true)
      }
      setAppointment(appointmentDb)
      setSelectedDate(
        dayjs(
          (data as IAppointmentInfoResponse)?.appointment?.appointment_date,
        ),
      )
    } catch (error: unknown) {
      handleAlert(true, 'Error al obtener información de la cita', 'error')
    }
  }

  useEffect(() => {
    if (
      selectedDate?.format('YYYY-MM-DD') === appointment?.appointment_date &&
      appointment?.hour_from
    ) {
      setHour(appointment?.hour_from as string)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hourAvailavility])

  useEffect(() => {
    fetchAppointmentInfoUtility()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (selectedOffice && appointment) {
      setSelectedDate(null)
      if (selectedOffice !== officeId) {
        setHour('')
      }
      fetchDaysAvailabilityInAMonth()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOffice, appointment, appointmentDuration])

  useEffect(() => {
    if (selectedDate?.isValid() && daysAvailability) {
      fetchAvailableHoursInADay()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDate])

  const handleChangeOffice = (office: IMedicalOffice | undefined): void => {
    setHourAvailavility([])
    setSelectedOffice(office?.office_id as string)
  }

  const handleChangeDate = (value: dayjs.Dayjs | null): void =>
    setSelectedDate(value as dayjs.Dayjs)

  const disabledButton = (): boolean => {
    return (
      loading ||
      ((validatingOverbooking ||
        appointmentOverbookingTo === '' ||
        appointmentOverbookingFrom === '' ||
        errorInOverbookingHours !== '') &&
        appointment?.appointment_type === 'OVERBOOKING') ||
      !selectedDate ||
      !selectedDate.isValid() ||
      (!hour && appointment?.appointment_type !== 'OVERBOOKING') ||
      (selectedOffice === appointment?.office_id &&
        selectedDate.format('YYYY-MM-DD') === appointment?.appointment_date &&
        appointment?.hour_from === hour &&
        appointment?.appointment_type !== 'OVERBOOKING')
    )
  }

  const isValid: boolean = disabledButton()

  const ReschedulingAppointment = async (event: {
    preventDefault: () => void
  }): Promise<void> => {
    event.preventDefault()
    setLoading(true)
    try {
      const { data, status } = await ReschedulingAppointmentService({
        appointment_date: selectedDate?.format('YYYY-MM-DD') as string,
        hour_from:
          appointment?.appointment_type === 'OVERBOOKING'
            ? appointmentOverbookingFrom.replace('h', ':')
            : hour,
        hour_to:
          appointment?.appointment_type === 'OVERBOOKING'
            ? appointmentOverbookingTo.replace('h', ':')
            : '',
        office_id: selectedOffice,
        appointment_id: appointmentId,
        modified_by: 'doctor',
        duration: Number(appointmentDuration),
      })

      handleAlert(true, data, status ? 'success' : 'error')
      handleSendRescheduleAfterSubmit()
      setLoading(false)
    } catch (error) {
      setLoading(false)
      handleAlert(true, 'Error al reagendar la cita', 'error')
    }
  }

  const isHourOptionDisabled = (hour: string): boolean =>
    selectedOffice === appointment?.office_id &&
    selectedDate?.format('YYYY-MM-DD') === appointment?.appointment_date &&
    appointment?.hour_from === hour

  return (
    <ModalWrapper
      sx={{ overflowY: 'auto' }}
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      data-testid="modal-reschedule-appointment"
      onClose={handleClose}
      open={open}
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <FadeModal in={open}>
        <BoxModal>
          <ModalPad>
            <CloseButton data-testid="close-button" onClick={handleClose}>
              <CloseIcon />
            </CloseButton>
            <ModalTitle id="transition-modal-title" variant="h6">
              Reagenda la cita de{' '}
              {`${appointment?.patient_name} ${appointment?.patient_last_name}`}
            </ModalTitle>
            <form onSubmit={ReschedulingAppointment}>
              <GridWrapper container data-testid={'office-dropdown'}>
                <TypographyModalTittle>
                  Selecciona el lugar de atención
                </TypographyModalTittle>
                <OfficeList
                  handleChangeOffice={handleChangeOffice}
                  disabled={disableOffices}
                  office={selectedOffice}
                  idDoctor={idDoctor}
                  filterOffices={
                    appointment?.doctor_type != 'DOCTOR_CM' && appointment
                      ? 'DOCTOR'
                      : appointment
                        ? 'DOCTOR_CM'
                        : ''
                  }
                  data-testid="dropdown-item-newOffice123"
                />
                {errorInOffices && <TextError>{errorInOffices}</TextError>}
              </GridWrapper>
              <GridWrapper container>
                <TypographyModalTittle>Tipo de cita</TypographyModalTittle>
                <FormControlStyled>
                  <DisableTextInput>
                    <>
                      <TypographyWrapper data-testid="app-reason-label">
                        {errorInOffices ? '' : appointment?.appointment_reason}
                      </TypographyWrapper>
                    </>
                  </DisableTextInput>
                </FormControlStyled>
              </GridWrapper>
              <GridWrapper container spacing={2}>
                <GridWrapper item xs={12} sm={12} md={6}>
                  <TypographyModalTittle>Fecha</TypographyModalTittle>
                  <BoxTimeModal data-testid="date-picker">
                    <ModalDatePicker
                      onChangeDate={handleChangeDate}
                      value={selectedDate}
                      daysAvailability={
                        appointment?.appointment_type !== 'OVERBOOKING'
                          ? (daysAvailability as ICalendarData)
                          : (null as unknown as ICalendarData)
                      }
                      disabled={able}
                    />
                  </BoxTimeModal>
                </GridWrapper>
                {appointment?.appointment_type !== 'OVERBOOKING' ? (
                  <GridWrapper item xs={12} sm={12} md={6}>
                    <TypographyModalTittle>Hora</TypographyModalTittle>
                    <BoxTimeModal>
                      {hourAvailavility !== undefined && (
                        <SelectWrapper
                          sx={{
                            width: '100%',
                            '& .MuiSelect-select': {
                              fontSize: '12px',
                              '@media (min-width: 1600px)': {
                                fontSize: '16px',
                              },
                            },
                          }}
                          name="hour"
                          id="hour"
                          value={hour}
                          onChange={(event) =>
                            setHour(event.target?.value as string)
                          }
                          defaultValue=""
                        >
                          {hourAvailavility.length > 0 &&
                            hourAvailavility.map(
                              (item: string, index: number) => (
                                <MenuItemWrapper
                                  key={index}
                                  value={item}
                                  data-testid={`hour-options`}
                                  disabled={isHourOptionDisabled(item)}
                                >
                                  {item}
                                </MenuItemWrapper>
                              ),
                            )}
                        </SelectWrapper>
                      )}
                    </BoxTimeModal>
                  </GridWrapper>
                ) : (
                  <>
                    <GridWrapper item xs={12} md={3}>
                      <TypographyModalTittle>Hora desde</TypographyModalTittle>

                      <AutocompleteComponent
                        id="hour_from_list"
                        label=""
                        list={overbookingHours.map((hour) => hour.label)}
                        value={appointmentOverbookingFrom}
                        setValue={(value: string) => {
                          if (
                            overbookingHours
                              .map((hour) => hour.label)
                              .includes(value) ||
                            value.length < 5
                          ) {
                            setAppointmentOverbookingFrom(value)
                          }
                        }}
                        disabled={false}
                        placeholder={'hh:mm'}
                        datatestid={'hour_from_list'}
                      />
                      <TextError>{errorInOverbookingHours}</TextError>
                    </GridWrapper>
                    <GridWrapper item xs={12} md={3}>
                      <TypographyModalTittle>Hora hasta</TypographyModalTittle>
                      <AutocompleteComponent
                        id="hour_to_list"
                        label=""
                        list={overbookingHours.map((hour) => hour.label)}
                        value={appointmentOverbookingTo}
                        setValue={(value: string) => {
                          if (
                            overbookingHours
                              .map((hour) => hour.label)
                              .includes(value) ||
                            value.length < 5
                          ) {
                            setAppointmentOverbookingTo(value)
                          }
                        }}
                        placeholder={'hh:mm'}
                        disabled={false}
                        datatestid={'hour_to_list'}
                      />
                    </GridWrapper>
                  </>
                )}
              </GridWrapper>
              <GridWrapper container>
                <GridWrapper item sm={12}>
                  <TypographyModalTittle>Comentarios</TypographyModalTittle>
                  <DisableTextInput>
                    <>
                      <TypographyWrapper data-testid="comments-label">
                        {appointment?.comment}
                      </TypographyWrapper>
                    </>
                  </DisableTextInput>
                </GridWrapper>
              </GridWrapper>
              <BottonGrid display={'flex'} justifyContent={'center'}>
                <SubmitButton
                  data-testid={'send-reschedule-appointment'}
                  id={'send-reschedule-appointment'}
                  variant={'contained'}
                  fullWidth={false}
                  type={'submit'}
                  text={'Enviar'}
                  sx={{ width: '180px' }}
                  disabled={isValid}
                  disableElevation
                />
              </BottonGrid>
            </form>
          </ModalPad>
        </BoxModal>
      </FadeModal>
    </ModalWrapper>
  )
}

export default RescheduleModal
