import { ChangeEvent } from 'react'

export function handleImageUpload(
  event: ChangeEvent<HTMLInputElement>, setStringValue: ( value: string ) => void
): void {
  const file = event.target.files as FileList

  if ( file.length ) {
    const reader = new FileReader()

    reader.onload = function ( e: ProgressEvent<FileReader> ) {
      const content = e.target?.result as string

      setStringValue( content )
    }
    reader.readAsDataURL( file?.[0])
  }
}