import { IApiResponseGetSkills } from '../../infrastructure/dtos/Skills'
import { SkillsRepository } from '../repository/SkillsRepository'

export interface GetSkillsUseCase {
  invoke(query: string): Promise<IApiResponseGetSkills>
}

export class GetSkills implements GetSkillsUseCase {
  private objectUseCase: SkillsRepository

  constructor(_objectUseCase: SkillsRepository) {
    this.objectUseCase = _objectUseCase
  }

  invoke(query: string): Promise<IApiResponseGetSkills> {
    return this.objectUseCase.getSkills(query)
  }
}
