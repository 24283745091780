import { GetSearchCie10 } from '../../../domain/useCase/GetSearchCie10'
import {
  IServiceSearchCie10,
} from '../../../infrastructure/dtos/SearchCie10'
import SearchCie10Mapper from '../../../infrastructure/http/mappers/SearchCie10Mapper/SearchCie10Mapper'
import { SearchCie10RepositoryImpl } from '../../../infrastructure/RepositoryImpl/SearchCie10RepositoryImpl'

export async function GetSearchCie10Service(
  query: string,
): Promise<IServiceSearchCie10> {
  try {
    const DataUseCase: GetSearchCie10 = new GetSearchCie10(
      new SearchCie10RepositoryImpl(new SearchCie10Mapper()),
    )
    
    return {
      status: 1,
      data: await DataUseCase.invoke(query),
    }
  } catch (error) {
    return {
      status: 0,
      data: {
        body: [],
        headers: {
          'Access-Control-Allow-Headers': '',
          'Access-Control-Allow-Methods': '',
          'Access-Control-Allow-Origin': '',
        },
        statusCode: 500,
      },
    }
  }
}
