import { ReactNode, useEffect, useState } from 'react'

import SubmitButton from '../../../../../../../../components/SubmitButton'
import { MedicalExamsItem } from '../../../../../../../../infrastructure/dtos/MedicalExamsItem'
import { getMedicalExamsUtility } from '../../../../../../../../services/Contracts/Utility/MedicalExamsUtility'

interface MedicalExamsHook {
  fetchMedicalExams: () => Promise<void>
  medicalExams: MedicalExamsItem[]
  grid: (exam: MedicalExamsItem) => GridItem[]
  gridHeader: {
    title: string
    width: number
  }[]
  loadingList: boolean
}

interface GridItem {
  value: string | ReactNode
  width: number
}

export const useMedicalExams = (): MedicalExamsHook => {
  const [medicalExams, setMedicalExams] = useState<MedicalExamsItem[]>([])
  const [loadingList, setLoadingList] = useState(false)

  const fetchMedicalExams = async (): Promise<void> => {
    setLoadingList(true)
    const { data } = await getMedicalExamsUtility('1')
    setMedicalExams(data.data)
    setLoadingList(false)
  }

  const generateGridItem = (medicalExam: MedicalExamsItem): GridItem[] => {
    return [
      { value: medicalExam.date, width: 3 },
      {
        value:
          Array.isArray(medicalExam.exams) && medicalExam.exams.length > 0
            ? medicalExam.exams
                .map((item) => item.name)
                .join(', ')
                .slice(0, 30) + '...'
            : '',
        width: 5,
      },
      {
        value: (
          <SubmitButton
            id="datails-button"
            data-testid="datails-button"
            disableElevation
            variant="contained"
            fullWidth={false}
            type="button"
            text={'Detalles'}
            onClick={() => {}}
            sx={{
              fontSize: '12px',
              '@media (min-width: 1600px)': {
                fontSize: '16px',
              },
              '&:disabled': {
                backgroundColor: 'tertiary.light',
                flexShrink: 0,
                color: 'info.main',
              },
            }}
          />
        ),
        width: 3,
      },
    ]
  }

  const gridHeader: {
    title: string
    width: number
  }[] = [
    { title: 'Fecha', width: 3 },
    { title: 'Nombre', width: 5 },
    { title: '', width: 3 },
  ]

  useEffect(() => {
    fetchMedicalExams().then()
  }, [])

  return {
    fetchMedicalExams,
    medicalExams,
    grid: generateGridItem,
    gridHeader,
    loadingList,
  }
}
