import {
  IApiResponseModifiedDataOffice,
  IModifiedDataOffice,
} from '../../../infrastructure/dtos/UpdateOffices'
import {
  ISavePrescriptionService,
  UpdateOfficesService,
} from '../Persistencia/UpdateOfficesService'

interface IUpdateOfficesData {
  status: number
  data: string
}

export async function UpdateOfficesUtility(
  office: IModifiedDataOffice,
): Promise<IUpdateOfficesData> {
  try {
    const { data, status }: ISavePrescriptionService =
      await UpdateOfficesService(office)

    return {
      data: data.body,
      status: status === 1 && data.statusCode === 200 ? status : 0,
    }
  } catch (error: unknown) {
    return {
      data: (
        (error as ISavePrescriptionService)
          .data as IApiResponseModifiedDataOffice
      ).body as string,
      status: 0,
    }
  }
}
