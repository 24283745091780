import { styled } from '@mui/material';

import { BoxWrapper, FadeWrapper, IconButtonWrapper, TypographyWrapper } from '../../../../../../../components';

export const BoxPatientRecipeModal = styled( BoxWrapper )(({ theme }) => ({
  position: 'absolute',
  top: '2%',
  left: '50%',
  transform: 'translateX(-50%)',
  width: '750px',
  minHeight: '480px',
  bgcolor: theme.palette.background.paper,
  background: theme.palette.common.white,
  borderRadius: '30px',
  p: 4,
  boxShadow: '2px 2px 18px 14px rgba(0, 0, 0, 0.03)'
}))

export const FadePatientRecipeModal = styled( FadeWrapper )(() => ({
  height: 'auto',
  overflowY: 'auto'
}))

export const CloseButton = styled( IconButtonWrapper )(( props ) => ({
  position: 'absolute',
  right: 20,
  top: 20,
  color: props.theme.palette.primary.dark,
  marginBottom: '20px'
}))

export const TypographyModal = styled( TypographyWrapper )(( props ) => ({
  left: '50%',
  fontFamily: 'Helvetica',
  fontSize: '18px',
  fontWeight: 'bold',
  fontStretch: 'normal',
  fontStyle: 'normal',
  lineHeight: 'normal',
  letterSpacing: 'normal',
  textAlign: 'center',
  color: props.theme.palette.primary.dark
}))

export const TypographyTitleModal = styled( TypographyWrapper )(() => ({
  fontFamily: 'Helvetica',
  fontSize: '18px',
  fontWeight: '400',
  lineHeight: '23px',
  letterSpacing: '0em',
  textAlign: 'left',
}))
