import { styled } from '@mui/material'

import { TypographyWrapper } from '../wrapper'

export const TypographyLabel = styled(TypographyWrapper)(({ theme }) => ({
  color: theme.palette.common.black,
  fontSize: '12px',
  fontFamily: 'Helvetica',
  fontWeight: 'normal',
  fontStretch: 'normal',
  fontStyle: 'normal',
  lineHeight: 'normal',
  letterSpacing: 'normal',
  [theme.breakpoints.up('xl')]: {
    fontSize: '1rem',
  }
}))
