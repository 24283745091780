import { IResponseGetCalendarEvent } from '../../infrastructure/dtos/CalendarInfo'
import { CalendarInfoRepository } from '../repository/CalendarInfoRepository'

export interface GetCalendarInfoUseCase {
  invoke: (
    idDoctor: string,
    idOffice: string,
    duration: number,
    officeType: string,
  ) => Promise<IResponseGetCalendarEvent>
}

export class GetCalendarInfo implements GetCalendarInfoUseCase {
  private objectUseCase: CalendarInfoRepository

  constructor( _objectUseCase: CalendarInfoRepository ) {
    this.objectUseCase = _objectUseCase
  }

  async invoke(
    idDoctor: string, idOffice: string, duration: number, officeType: string
  ): Promise<IResponseGetCalendarEvent> {
    return this.objectUseCase.getCalendarInfo( idDoctor, idOffice, duration, officeType )
  }
}
