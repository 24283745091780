import { GetSkills } from '../../../domain/useCase/SkillsUseCase'
import { IResponseService } from '../../../infrastructure/dtos'
import { IApiResponseGetSkills } from '../../../infrastructure/dtos/Skills'
import { SkillsMapper } from '../../../infrastructure/http/mappers/SkillsMapper/SkillsMapper'
import { SkillsRepositoryImpl } from '../../../infrastructure/RepositoryImpl/SkillsRepositoryImpl'

export async function getSkillsService(
  query: string,
): Promise<IResponseService<IApiResponseGetSkills>> {
  try {
    const useCaseApi: GetSkills = new GetSkills(
      new SkillsRepositoryImpl(new SkillsMapper()),
    )
    return {
      status: 1,
      data: await useCaseApi.invoke(query),
    }
  } catch (error: unknown) {
    return {
      status: 0,
      data: {
        statusCode: 500,
        body: {
          message: 'Internal Server Error',
          data: [],
        },
        headers: {
          'Access-Control-Allow-Headers': '',
          'Access-Control-Allow-Origin': '',
          'Access-Control-Allow-Methods': '',
        },
      },
    }
  }
}
