import React, { useEffect, useState } from 'react'

import { FormControlStyled } from '../../../../views/Dashboard/Profile/components/UpdateSchedule/components/HourInput/HourInputStyle'
import { useAppointmentDurations } from '../../../../views/medical/ProfileMedical/ProfileMedicalScheduleSetting/Hooks/useAppointmentDurations'
import {
  AutocompleteStyled,
  TextFieldAutocomplete,
} from '../../../Autocomplete/styles'
import { InputField } from '../../../StyledComponents/InputField'
import {
  GridModalContainerItem,
  GridStyledItem,
  TextError,
} from '../../ModalDateStyles'
import { CustomDuration } from '../ModalDatePicker/hook/useNewDate'

interface ICustomAppointmentTypeProps {
  setCustomType: React.Dispatch<React.SetStateAction<string>>
  customType: string
  duration: CustomDuration
  setDuration: React.Dispatch<React.SetStateAction<CustomDuration>>
}

export const CustomAppointmentType = ({
  customType,
  setCustomType,
  setDuration,
  duration,
}: ICustomAppointmentTypeProps): JSX.Element => {
  const [errorInOverbookingHours, setErrorInOverbookingHours] =
    useState<string>('')
  const { durations } = useAppointmentDurations()

  useEffect(() => {
    if (customType === '') {
      setErrorInOverbookingHours('Campo requerido.')
    } else {
      setErrorInOverbookingHours('')
    }
  }, [customType])

  const handleDurationChange = (
    event: React.SyntheticEvent<Element, Event>,
    value: unknown,
  ): void => {
    const duration = durations.find((d) => d.name === value)
    if (duration) {
      setDuration({
        name: value as string,
        value: durations.find((d) => d.name === value)?.value as number,
      })
    } else {
      setDuration({
        name: '',
        value: 0,
      })
    }
  }

  return (
    <GridModalContainerItem
      container
      sx={{
        marginTop: '0',
      }}
    >
      <GridStyledItem
        item
        xs={12}
        md={6}
        sx={{
          paddingLeft: '0px',
        }}
      >
        <FormControlStyled>
          <InputField
            value={customType}
            data-testid={`appointment-type`}
            onChange={(event) => {
              setCustomType(event.target.value)
            }}
            sx={{
              width: '100%',
            }}
            placeholder={'Tipo de cita'}
          />
        <TextError sx={{
          marginLeft: '10px',
        }}>{errorInOverbookingHours}</TextError>
        </FormControlStyled>
      </GridStyledItem>
      <GridStyledItem item xs={12} md={6}>
        <FormControlStyled
          sx={{
            width: '100%',
          }}
        >
          <AutocompleteStyled
            id="duration-item"
            options={durations.map((duration) => duration.name)}
            value={
              durations.find((d) => d.name === duration.name)
                ? duration.name
                : ''
            }
            onChange={(
              event: React.SyntheticEvent<Element, Event>,
              value: unknown,
            ) => {
              handleDurationChange(event, value)
            }}
            onInputChange={(
              event: React.SyntheticEvent<Element, Event>,
              value: string,
            ) => {
              handleDurationChange(event, value)
            }}
            sx={{
              width: '100%',
            }}
            disabled={false}
            renderInput={(params) => {
              return (
                <TextFieldAutocomplete
                  {...params}
                  variant="outlined"
                  fullWidth
                  data-testid={'duration-item'}
                  placeholder={'Duración de cita'}
                />
              )
            }}
          />
        </FormControlStyled>
      </GridStyledItem>
    </GridModalContainerItem>
  )
}
