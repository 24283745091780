import {
  IApiResponseSaveNewDate,
  IApiResponseSaveOverbooking,
  ISaveNewDate,
} from '../../../infrastructure/dtos/NewDate'
import {
  IServiceSaveNewDate,
  IServiceSaveOverbooking,
  NewDateService,
  NewOverbookingAppointmentService,
} from '../Persistencia/NewDateService'

export interface INewDateData {
  status: number
  data: string
}
export async function PostNewDateUtility(
  dataNewDate: ISaveNewDate,
): Promise<INewDateData> {
  try {
    const {data, status} = await NewDateService(dataNewDate)
    return {
      data: (data as IApiResponseSaveNewDate).body as string,
      status: (
        status !== 1 ||
        (data as IApiResponseSaveNewDate).statusCode !== 200
      ) ? 0 : status,
    }
  } catch (error) {
    return {
      // data: 'Ups! Hubo complicaciones intente mas tarde',
      data: ((error as IServiceSaveNewDate).data as IApiResponseSaveNewDate).body as string,
      status: 0,
    }
  }
}

export async function PostNewOverbookingAppointmentUtility(
  dataNewDate: ISaveNewDate,
): Promise<INewDateData> {
  try {
    const { data, status } = await NewOverbookingAppointmentService(dataNewDate)
    return {
      status:
        status === 1 && (data as IApiResponseSaveOverbooking).statusCode === 200
          ? status
          : 0,
      data: (data as IApiResponseSaveOverbooking).body,
    }
  } catch (error: unknown) {
    return {
      status: 0,
      data: ((error as IServiceSaveOverbooking)?.data as IApiResponseSaveOverbooking).body,
    }
  }
}
