import dayjs from 'dayjs'
import { ReactNode, useContext, useEffect, useState } from 'react'

import {
  AccountContext,
  AuthContextType,
} from '../../../../../../contexts/AccountContext'
import { useDoctorInformationStore } from '../../../../../../contexts/DoctorInformationState'
import {
  Agreement,
  IBodyAgreements,
} from '../../../../../../infrastructure/dtos/Agreements'
import {
  Daum,
  IBodyInvite,
} from '../../../../../../infrastructure/dtos/AgreementsInvites'
import {
  GetAgreementUtility,
  InviteUtility,
  ReplyUtility,
} from '../../../../../../services/Contracts/Utility/AgreementUtility'

interface IUseInviteList {
  loadingInvitelist: boolean
  inviteList: IBodyInvite
  handleSubmit: (reply: boolean, data: Daum) => Promise<void>
  gridBody: (item: Agreement) => {
    value: string | ReactNode
    width: string
  }[]
  gridHeader: {
    title: string
    width: string
  }[]
  list: Agreement[]
  loadingList: boolean
  textColor: (item: Agreement, index: number, value: string) => string
}

export function useInviteList(): IUseInviteList {
  const { handleAlert } = useContext(AccountContext) as AuthContextType
  const { doctorInformation } = useDoctorInformationStore()
  const [loadingInvitelist, setLoadingInviteList] = useState<boolean>(false)
  const [inviteList, setInviteList] = useState<IBodyInvite>({
    data: [],
    message: '',
  })
  const [loadingList, setLoadingList] = useState<boolean>(false)
  const [list, setList] = useState<Agreement[]>([])

  const fetchIviteList = async (idDoctor: string): Promise<void> => {
    try {
      setLoadingInviteList(true)
      const { data, status } = await InviteUtility(idDoctor)

      if (status) {
        setInviteList(data as IBodyInvite)
      } else {
        setInviteList({
          data: [],
          message: (data as IBodyInvite).message,
        })
      }
      setLoadingInviteList(false)
    } catch (error: unknown) {
      handleAlert(true, 'Error al obtener lista de invitacion', 'error')
    }
  }

  const handleSubmit = async (reply: boolean, info: Daum): Promise<void> => {
    const { data, status } = await ReplyUtility({
      agreement_id: info.agreement_id,
      doctor_id: info.doctor_id,
      invitation_reply: reply,
      type: info.type,
    })

    const alertType = reply && status === 1 ? 'success' : 'error'

    fetchIviteList(doctorInformation?.user_id as string)
    handleAlert(true, data, alertType)
    fetchAgreement(doctorInformation?.user_id as string)
  }

  const fetchAgreement = async (idDoctor: string): Promise<void> => {
    setLoadingList(true)
    const { data } = await GetAgreementUtility(idDoctor)
    setList((data as IBodyAgreements)?.data)
    setLoadingList(false)
  }

  const gridHeader: {
    title: string
    width: string
  }[] = [
    { title: 'Fecha', width: '15%' },
    { title: 'Tipo', width: '15%' },
    { title: 'Nombre', width: '20%' },
    { title: 'Vigencia', width: '20%' },
    { title: 'Convenio', width: '15%' },
    { title: 'Invitación', width: '15%' },
  ]

  function capitalizeWords(word: string): string {
    if (word.length === 0) {
      return ''
    }
    // Convierte la primera letra a mayúscula y el resto a minúsculas
    return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
  }

  const gridBody = (
    item: Agreement,
  ): {
    value: string
    width: string
  }[] => [
    { value: dayjs(item.created_at).format('DD-MM-YYYY'), width: '15%' },
    { value: item.type == 'CM' ? 'Centro' : 'Aseguradora', width: '15%' },
    { value: item.cm.name_cm, width: '20%' },
    {
      value: `${dayjs(item.start_date).format('DD/MM/YYYY')} - ${dayjs(
        item.end_date,
      ).format('DD/MM/YYYY')}`,
      width: '20%',
    },
    { value: '● ' + capitalizeWords(item.contract_status), width: '15%' },
    { value: capitalizeWords(item.invitation_status), width: '15%' },
  ]

  const StatusColor: { [key: string]: string } = {
    Caducado: 'red',
    Vigente: 'lightgreen',
    'Por Vencer': '#EB9C02',
  }

  const InviteColor: { [key: string]: string } = {
    Rechazada: 'red',
    Aceptada: 'lightgreen',
  }

  const textColor = (item: Agreement, index: number, value: string): string => {
    let color = 'inherit'
    if (item.invitation_status !== 'RECHAZADA') {
      color = 'tertiary.main'
    }
    if (index === 4) {
      color = StatusColor[value.substring(2)]
    }

    if (index === 5) {
      color = InviteColor[value]
    }

    return color
  }

  useEffect(() => {
    fetchIviteList(doctorInformation?.user_id as string)
    fetchAgreement(doctorInformation?.user_id as string)
  }, [])

  return {
    inviteList,
    loadingInvitelist,
    handleSubmit,
    gridBody,
    gridHeader,
    list,
    loadingList,
    textColor,
  }
}
