import { create } from 'zustand'

import { IGetAppointmentProps } from '../infrastructure/dtos/Appointments'

interface IFiltersStore {
  filtersSelected: IGetAppointmentProps
  setFiltersSelected: (filtersSelected: IGetAppointmentProps) => void
}

export const useFiltersStore = create<IFiltersStore>((set) => ({
  filtersSelected: {} as IGetAppointmentProps,
  setFiltersSelected: (filtersSelected: IGetAppointmentProps) =>
    set({ filtersSelected }),
}))
