import {
  BodyCheckScheduleImpactRequest,
  IApiResponseCheckScheduleImpact, ParamsCheckModifyOfficeImpact,
} from '../../infrastructure/dtos/CheckScheduleImpact'
import { ScheduleRepository } from '../repository/ScheduleRepository'

export interface CheckScheduleImpactUseCase {
  invoke(
    body: BodyCheckScheduleImpactRequest,
  ): Promise<IApiResponseCheckScheduleImpact>
}

export interface CheckModifyOfficeImpactUseCase {
  invoke(
    params: ParamsCheckModifyOfficeImpact,
  ): Promise<IApiResponseCheckScheduleImpact>
}

export class CheckScheduleImpact implements CheckScheduleImpactUseCase {
  private objectUseCase: ScheduleRepository

  constructor(_objectUseCase: ScheduleRepository) {
    this.objectUseCase = _objectUseCase
  }

  invoke(
    body: BodyCheckScheduleImpactRequest,
  ): Promise<IApiResponseCheckScheduleImpact> {
    return this.objectUseCase.checkScheduleImpact(body)
  }
}

export class CheckModifyOfficeImpact implements CheckModifyOfficeImpactUseCase {
  private objectUseCase: ScheduleRepository

  constructor(_objectUseCase: ScheduleRepository) {
    this.objectUseCase = _objectUseCase
  }

  invoke(
    params: ParamsCheckModifyOfficeImpact,
  ): Promise<IApiResponseCheckScheduleImpact> {
    return this.objectUseCase.checkModifyOfficeImpact(params)
  }
}
