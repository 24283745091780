import { useContext, useEffect, useState } from 'react'

import { AccountContext, AuthContextType } from '../../../../../../../../contexts/AccountContext'
import { Appointment } from '../../../../../../../../infrastructure/dtos/Appointments'
import { cancelAppointmentUtility } from '../../../../../../../../services/Contracts/Utility/CancelAppointmentUtility'
import { GetCatalogParametersUtility } from '../../../../../../../../services/Contracts/Utility/CatalogUtility'

interface IUseCancelReasonHook {
  openCancelReasonModal: boolean
  customOption: string
  optionsList: string[]
  option: string
  error: boolean
  MAX_LENGTH: number
  loading: boolean
  handleOpenCancelReason: ( value: boolean ) => void
  handleChangeOption: (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement> | string) => void
  handleConfirmCancel: () => Promise<void>
}

interface IUseCancelReasonProps {
  isFetched: boolean
  appointment?: Appointment
  handleClose: () => void
  onCloseCancelReason: () => void
}

export function useCancelReason(props?: IUseCancelReasonProps): IUseCancelReasonHook {
  const { handleAlert } = useContext(AccountContext) as AuthContextType
  const [customOption, setCustomOption] = useState<string>('')
  const [optionsList, setOptionsList] = useState<string[]>([])
  const [option, setOption] = useState<string>('')
  const [error, setError] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [MAX_LENGTH] = useState<number>(100)

  const [openCancelReasonModal, setOpenCancelReasonModal] =
    useState<boolean>( false )

  const handleOpenCancelReason = ( value: boolean ): void =>
    setOpenCancelReasonModal( value )

  const fetchListReason = async (): Promise<void> => {
    try {
      const { data, status } = await GetCatalogParametersUtility(
        'REASON_CANCELLATION_APPOINTMENT_DOCTOR',
      )

      if (status) {
        if (typeof data != 'string') {
          setOptionsList(data)
        }
      }
      else {
        throw new Error(data as string)
      }
    } catch (error: unknown) {
      handleAlert(true, 'Error al obtener el listado de razones de cancelación', 'error')
    }
  }

  const handleChangeOption = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement> | string,
  ): void => {
    if (typeof event === 'string') {
      setOption(event)
      setCustomOption('')
    } else {
      setError(event.target.value.length > MAX_LENGTH)
      setCustomOption(event.target.value)
      setOption('')
    }
  }

  const handleConfirmCancel = async (): Promise<void> => {
    try {
      setLoading(true)
      const { data, status } = await cancelAppointmentUtility({
        appointmentId: props?.appointment?.appointment_id as string,
        reason: option || customOption,
      })

      if (status) {
        if (typeof data !== 'string') {
          const { message } = data

          handleAlert(true, message, 'success')
          props?.handleClose()
          props?.onCloseCancelReason()
        }
        setLoading(false)
      } else {
        setLoading(false)
        throw new Error(data as string)
      }
    } catch (error: unknown) {
      handleAlert(true, 'Error al confirmar la cancelación de la cita', 'error')
    }
  }

  useEffect(() => {
    if (props?.isFetched) {
      fetchListReason()
    }
  }, [])

  return {
    openCancelReasonModal,
    customOption,
    optionsList,
    option,
    error,
    MAX_LENGTH,
    loading,
    handleOpenCancelReason,
    handleChangeOption,
    handleConfirmCancel,
  }
}
