import { useContext, useState } from 'react'

import {
  AccountContext,
  AuthContextType,
} from '../../../../../../../contexts/AccountContext'
import { IAppointmentDetails } from '../../../../../../../infrastructure/dtos/Appointments'
import { IAppointment } from '../../../../../../../infrastructure/dtos/AttendanceSelectedPatient'
import {
  IPrescription,
  IRecipePrescription,
} from '../../../../../../../infrastructure/dtos/Prescriptions'
import { getAppointmentDetailsUtility } from '../../../../../../../services/Contracts/Utility/AppointmentUtility'
import { getAppointmentRecipesUtility } from '../../../../../../../services/Contracts/Utility/PrescriptionsUtility'

interface IUsePatientRecipesModalHook {
  handleChangeOpenPatientRecipesModal(value: boolean): void
  onOpenPatientRecipesModal(appointment: IAppointment): void
  fetchAppointmentDetailsUtility(appointment: string): Promise<void>
  fetchPrescriptionUtility(appointment: string): Promise<void>
  openPatientRecipesModal: boolean
  loadingAppointmentDetails: boolean
  loadingAppointmentRecipes: boolean
  appointmentDetails: IAppointmentDetails
  appointmentSelected: IAppointment
  appointmentRecipes: IRecipePrescription
  getOnlyPrescription(prescriptionId: string): IPrescription
}

export function usePatientRecipesModal(): IUsePatientRecipesModalHook {
  const { handleAlert } = useContext(AccountContext) as AuthContextType
  const [openPatientRecipesModal, setOpenPatientRecipesModal] =
    useState<boolean>(false)
  const [loadingAppointmentDetails, setLoadingAppointmentDetails] =
    useState<boolean>(false)
  const [loadingAppointmentRecipes, setLoadingAppointmentRecipes] =
    useState<boolean>(false)
  const [appointmentDetails, setAppointmentDetails] =
    useState<IAppointmentDetails>({
      diagnostic: '',
      documents: [],
      patient_diagnostic: [],
    })
  const [prescriptionInfo, setPrescriptionInfo] = useState<IRecipePrescription>(
    {} as IRecipePrescription,
  )
  const [appointmentSelected, setAppointmentSelected] = useState<IAppointment>({
    assistance: '',
    appointment_id: '',
    appointment_date: '',
    patient_diagnostic: {},
    status: '',
    user_id: '',
  })

  const handleChangeOpenPatientRecipesModal = (value: boolean): void =>
    setOpenPatientRecipesModal(value)
  const onOpenPatientRecipesModal = (appointment: IAppointment): void => {
    setAppointmentSelected(appointment)
    handleChangeOpenPatientRecipesModal(true)
  }

  const fetchAppointmentDetailsUtility = async (
    appointment: string,
  ): Promise<void> => {
    try {
      setLoadingAppointmentDetails(true)
      const { data, status } = await getAppointmentDetailsUtility(appointment)
      if (status) {
        setAppointmentDetails(data as IAppointmentDetails)
      }
      setLoadingAppointmentDetails(false)
    } catch (error: unknown) {
      handleAlert(true, 'Error al obtener detalles de la cita', 'error')
    }
  }

  const getOnlyPrescription = (prescriptionId: string): IPrescription => {
    const prescription = prescriptionInfo.prescription?.find(
      (prescription) => prescription.prescription_id === prescriptionId,
    )
    return prescription as IPrescription
  }

  const fetchPrescriptionUtility = async (
    appointment: string,
  ): Promise<void> => {
    try {
      setLoadingAppointmentRecipes(true)
      const { data, status } = await getAppointmentRecipesUtility(appointment)

      if (status) {
        setPrescriptionInfo(data as IRecipePrescription)
      }
      setLoadingAppointmentRecipes(false)
    } catch (error: unknown) {
      setLoadingAppointmentRecipes(false)
      handleAlert(
        true,
        'Error al obtener los datos de la prescripción',
        'error',
      )
    }
  }

  return {
    handleChangeOpenPatientRecipesModal,
    onOpenPatientRecipesModal,
    fetchAppointmentDetailsUtility,
    fetchPrescriptionUtility,
    openPatientRecipesModal,
    loadingAppointmentDetails,
    appointmentDetails,
    appointmentSelected,
    getOnlyPrescription,
    appointmentRecipes: prescriptionInfo,
    loadingAppointmentRecipes,
  }
}
