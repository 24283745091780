import { useProfileMedicalScheduleSetting } from './Hooks/useProfileMedicalScheduleSetting'
import text from './ProfileMedicalScheduleSettings.json'
import { GridForm, GridOfficeName } from './ProfileMedicalScheduleSettingStyle'
import { BackButton } from '../../../../components/BackButton/BackButton'
import FormTextInput from '../../../../components/FormInputText'
import { GridFullScreen } from '../../../../components/GridFullScreen/GridFullScreen'
import { List } from '../../../../components/List/List'
import { Loading } from '../../../../components/Loading/Loading'
import { Advert } from '../../../../components/Notification/Advert/Advert'
import SelectButtonList from '../../../../components/SelectButtonList/SelectButtonList'
import { GridPartialScreen } from '../../../../components/StyledComponents/GridPartialScreen'
import { LabelInput } from '../../../../components/StyledComponents/LabelInput'
import { Title } from '../../../../components/StyledComponents/Title'
import SubmitButton from '../../../../components/SubmitButton'
import GridWrapper from '../../../../components/wrapper/GridWrapper'
import { AppointmentTypesList } from '../Components/AppointmentTypesList'
import TimeSelector from '../Components/TimeSelector'

export const ProfileMedicalScheduleSetting = (): JSX.Element => {
  const {
    amountRanges,
    lists,
    loading,
    selectedExperience,
    phoneNumber,
    office,
    dataConsultory,
    disabledButton,
    openAlert,
    message,
    validatePhoneNumber,
    validatePhoneNumberText,
    isExtraSmallSize,
    submitButtonText,
    onBack,
    onSubmit,
    onChange,
    onOption,
    setDataConsultory,
    setTypesList,
    typesList,
    durationsDB,
    typesDB
  } = useProfileMedicalScheduleSetting()

  return (
    <>
      {(amountRanges?.length === 0 && lists?.length === 0) || loading ? (
        <Loading />
      ) : (
        <GridFullScreen id="ScreenLogin">
          <GridPartialScreen container flexDirection={'column'}>
            <BackButton id="backButton" text="Regresar" onClick={onBack} />
            <Title variant="h5">{text.create_profile}</Title>
            <GridForm item xs={12}>
              <form
                onSubmit={onSubmit}
                data-testid="profile-form3"
                style={{ width: '100%' }}
              >
                <GridWrapper container sx={{ mt: 2 }} spacing={2}>
                  <GridWrapper item xs={12} sm={6}>
                    <LabelInput>
                      {text.configure_office_availability}
                    </LabelInput>
                    <GridOfficeName>{office?.reference}</GridOfficeName>
                  </GridWrapper>
                  <GridWrapper item xs={12} sm={6}>
                    <FormTextInput
                      text={text.office_phone_number}
                      placeholder={text.office_phone_number_placeholder}
                      id="office_phone"
                      name="office_phone"
                      type="text"
                      fullWidth
                      value={phoneNumber}
                      onChange={(event) =>
                        onChange(event.target.value, 'phoneNumber')
                      }
                      error={validatePhoneNumber}
                      helperText={validatePhoneNumberText}
                    />
                  </GridWrapper>
                  <GridWrapper item xs={12} sx={{ mt: 2 }}>
                    <LabelInput>{text.show_agenda_text}</LabelInput>
                    {isExtraSmallSize ? (
                      <List
                        id="selectVisualizationAgenda"
                        lists={amountRanges}
                        onClick={(event: string) =>
                          onChange(event, 'selectedExperience')
                        }
                        value={selectedExperience}
                        minWidth="100%"
                      />
                    ) : (
                      <SelectButtonList
                        style={{ width: '100%' }}
                        amountRanges={amountRanges}
                        onAmountRangeChange={(value: string) =>
                          onChange(value, 'selectedExperience')
                        }
                        selectedAmountRange={selectedExperience}
                      />
                    )}
                  </GridWrapper>

                  <GridWrapper item xs={12} sx={{ mt: 2 }}>
                    <LabelInput>
                      {`${text.schedule_agenda_types} ${office?.reference}`}
                    </LabelInput>

                      <AppointmentTypesList
                        setTypesList={setTypesList}
                        typesList={typesList}
                        typesDB={typesDB}
                        durationsDB={durationsDB}/>
                  </GridWrapper>

                  <GridWrapper item xs={12} mt={2}>
                    <LabelInput>
                      {`${text.schedule_agenda_text} ${office?.reference}?`}
                    </LabelInput>
                    <TimeSelector
                      dataConsultory={dataConsultory}
                      setDataConsultory={setDataConsultory}
                    />
                  </GridWrapper>

                  <GridWrapper item xs={12} my={2}>
                    <SubmitButton
                      data-testid="begin-button"
                      id="button"
                      type="submit"
                      variant="contained"
                      fullWidth
                      text={submitButtonText}
                      disabled={disabledButton}
                    />
                  </GridWrapper>
                </GridWrapper>
              </form>
            </GridForm>
          </GridPartialScreen>
        </GridFullScreen>
      )}
      <Advert
        message={message?.message}
        onClose={onOption}
        open={openAlert}
        title={message?.title}
      />
    </>
  )
}
