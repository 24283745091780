import { GetPatientAppointment } from '../../../domain/useCase/AttendanceSelectedPatientUseCase'
import {
  IAppointmentsByDoctorParams,
  IAppointmentsByDoctorResponse,
} from '../../../infrastructure/dtos/AttendanceSelectedPatient'
import { AttendanceSelectedPatientRepositoryImpl } from './../../../infrastructure/RepositoryImpl/AttendanceSelectedPatientRepositoryImpl'
import AttendanceSelectedPatientMapper from '../../../infrastructure/http/mappers/AttendanceSelectedPatientMapper/AttendanceSelectedPatientMapper'

interface IAttendanceSelectedPatientService {
  status: number
  data: IAppointmentsByDoctorResponse | string
}

export async function AttendanceSelectedPatientService(
  params: IAppointmentsByDoctorParams,
): Promise<IAttendanceSelectedPatientService> {
  try {
    const UseCaseSelectedPatient = new GetPatientAppointment(
      new AttendanceSelectedPatientRepositoryImpl(
        new AttendanceSelectedPatientMapper(),
      ),
    )
    const { statusCode, body } = await UseCaseSelectedPatient.invoke(params)

    return {
      status: statusCode === 200 ? 1 : 0,
      data: body,
    }
  } catch (error: unknown) {
    return {
      status: 0,
      data: (error as Error)?.message,
    }
  }
}
