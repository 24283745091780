import { styled } from '@mui/material/styles';

import { ButtonWrapper } from '../wrapper';

export const ButtonGeneric = styled(ButtonWrapper)(({ theme }) => ({
  width: '100%',
  borderRadius: '2.625rem',
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.common.white,
  fontSize: '12px',
  height: '3.4375rem',
  textTransform: 'none',
  ':hover': {
    backgroundColor: theme.palette.primary.main,
  },
  '&.Mui-disabled': {
    backgroundColor: theme.palette.text.disabled,
  },
  [theme.breakpoints.up('xl')]: {
    fontSize: '16px',
  }
}))
