import 'dayjs/locale/es'
import ClearIcon from '@mui/icons-material/Clear'
import SearchIcon from '@mui/icons-material/Search'
import { IconButton, InputAdornment } from '@mui/material'

import { DateRangePicker, CustomProvider } from 'rsuite'
import './rsuite.css'
import esES from 'rsuite/locales/es_ES'

import { useSearchForm } from './hooks/useSearchForm'
import { DateRangeInput, SearchInput } from './styles'
import { StackWrapper } from '../../../wrapper'

function SearchForm(): JSX.Element {
  const {
    range,
    search,
    handleDateRangeChange,
    handleChange,
    handleBlur,
    handleKeyPress,
    handleClear,
  } = useSearchForm()
  return (
    <form action="" role="form">
      <StackWrapper spacing={2} direction="row">
        <DateRangeInput role="textbox">
          <CustomProvider locale={esES}>
            <DateRangePicker
              id={'filter-range'}
              value={range}
              placeholder="dd/mm - dd/mm"
              format="dd/MM/yyyy"
              appearance="subtle"
              onChange={(value: [Date, Date] | null) =>
                handleDateRangeChange(value)
              }
              style={{
                width: '100%',
                borderRadius: '42px',
              }}
              aria-label="dd/mm - dd/mm"
              data-testid="date-range-picker"
            />
          </CustomProvider>
        </DateRangeInput>
        <SearchInput
          data-testid="search-input"
          id="filter-text"
          value={search}
          onChange={handleChange}
          placeholder="Buscar por nombre o cédula"
          onBlur={handleBlur}
          onKeyDown={handleKeyPress}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
            endAdornment: search && (
              <InputAdornment position="end">
                <IconButton onClick={handleClear}>
                  <ClearIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </StackWrapper>
    </form>
  )
}

export default SearchForm
