import dayjs from 'dayjs'
import { FormEvent, useContext, useEffect, useState } from 'react'

import { CustomDuration } from '../../../../../components/ModalDate/components/ModalDatePicker/hook/useNewDate'
import {
  AccountContext,
  AuthContextType,
} from '../../../../../contexts/AccountContext'
import { useDoctorInformationStore } from '../../../../../contexts/DoctorInformationState'
import { useSelectOffice } from '../../../../../contexts/SelectOfficeState'
import { Appointment, ISaveNextAppointment } from '../../../../../infrastructure/dtos/Appointments'
import { BodyAvailableHours } from '../../../../../infrastructure/dtos/Calendar'
import {
  ICalendarData,
  IResponseGetCalendarEvent,
} from '../../../../../infrastructure/dtos/CalendarInfo'
import { IMedicalOffice } from '../../../../../infrastructure/dtos/Offices'
import { getAvailableHoursInADay } from '../../../../../services/Contracts/Persistencia/Calendar'
import { GetDaysAvailabilityInAMonth } from '../../../../../services/Contracts/Persistencia/CalendarInfoService'
import { saveNextAppointmentUtility } from '../../../../../services/Contracts/Utility/AppointmentUtility'

interface IUseNextAppointment {
  selectedOffice: string
  selectedDate: dayjs.Dayjs | null
  daysAvailability?: ICalendarData
  hourAvailavility: string[]
  hour: string
  comments: string
  idDoctor: string
  commentsHelperText: string
  commentRequiredLength: number
  validCommentsLength: boolean
  openNextAppointment: boolean
  handleOpenNextAppointment: () => void
  handleCloseNextAppointment: () => void
  onSubmit: (event: FormEvent<HTMLFormElement>) => void
  handleChangeOffice: (office?: IMedicalOffice) => void
  handleChangeDate: (value: dayjs.Dayjs | null) => void
  handleChangeComments: (value: string) => void
  handleChangeHour: (value: string) => void
  disabledButton: () => boolean
  appointmentType: string
  setAppointmentType: React.Dispatch<React.SetStateAction<string>>
  appointmentTypeList: string[]
  customDuration: CustomDuration
  setCustomDuration: React.Dispatch<React.SetStateAction<CustomDuration>>
  duration: number
  setDuration: React.Dispatch<React.SetStateAction<number>>
  isCustomType: boolean
  setIsCustomType: React.Dispatch<React.SetStateAction<boolean>>
  customType: string
  setCustomType: React.Dispatch<React.SetStateAction<string>>
  calendarDisabled: boolean
}

interface IUseNextAppointmentProps {
  officeId?: string
  appointment?: Appointment | undefined
  handleSendNextAppointmentAfterSubmit?: () => void
}

export function useNextAppointment({
  officeId = undefined,
  appointment,
  handleSendNextAppointmentAfterSubmit = () => { },
}: IUseNextAppointmentProps): IUseNextAppointment {
  const { doctorInformation } = useDoctorInformationStore()
  const { officeSelected } = useSelectOffice()

  const idDoctor: string = doctorInformation?.user_id as string
  const { handleAlert } = useContext(AccountContext) as AuthContextType

  const [selectedOffice, setSelectedOffice] = useState<string>(
    officeId as string,
  )
  const [selectedDate, setSelectedDate] = useState<dayjs.Dayjs | null>(null)
  const [daysAvailability, setDaysAvailability] = useState<ICalendarData>()
  const [hourAvailavility, setHourAvailavility] = useState<string[]>([])
  const [hour, setHour] = useState<string>('')
  const [comments, setComments] = useState<string>('')
  const [disabledToSend, setDisabledToSend] = useState<boolean>(false)
  const [commentRequiredLength] = useState<number>(40)
  const [openNextAppointment, setOpenNextAppointment] = useState<boolean>(false)
  const [appointmentType, setAppointmentType] = useState<string>('')

  const initOffice = officeSelected.find(
    (office) => office.office_id === selectedOffice,
  )

  const generateAppointmentTypeList = (): string[] => {
    if (appointment?.doctor_type !== 'DOCTOR_CM') {
      return initOffice
        ? [
          ...(initOffice?.appointment_types?.map(
            (type) => type.name,
          ) as string[]),
          'Otro',
        ]
        : []
    }

    return initOffice
      ? [
        ...(initOffice?.appointment_types?.map(
          (type) => type.name,
        ) as string[]),
      ]
      : []
  }

  const [appointmentTypeList, setAppointmentTypeList] = useState<string[]>(
    generateAppointmentTypeList()
  )

  const [customDuration, setCustomDuration] = useState<CustomDuration>({
    name: '',
    value: 0,
  })
  const [customType, setCustomType] = useState<string>('')
  const [duration, setDuration] = useState<number>(0)
  const [isCustomType, setIsCustomType] = useState<boolean>(false)
  const [calendarDisabled, setCalendarDisabled] = useState<boolean>(true)
  const fetchDaysAvailabilityInAMonth = async (): Promise<ICalendarData> => {
    setSelectedDate(null)
    setHour('')
    const { data } = await GetDaysAvailabilityInAMonth(
      initOffice?.user_id as string,
      selectedOffice,
      isCustomType ? customDuration.value : duration,
      appointment?.doctor_type !== 'DOCTOR_CM' ? 'OFFICE' : 'OFFICE_CM'
    )

    return (data as IResponseGetCalendarEvent).body
  }

  const fetchAvailableHoursInADay = async (): Promise<void> => {
    try {
      setHour('')
      const { body } = await getAvailableHoursInADay({
        user_id: initOffice?.user_id as string,
        date_search: selectedDate?.format('YYYY-MM-DD') as string,
        office_id: selectedOffice,
        duration: isCustomType ? customDuration.value : duration,
        officeType: appointment?.doctor_type !== 'DOCTOR_CM' ? 'OFFICE' : 'OFFICE_CM'
      })

      setHourAvailavility((body as BodyAvailableHours).data.intervals_available_hours)
    } catch (error: unknown) {
      handleAlert(true, 'Error al obtener las horas disponibles', 'error')
    }
  }

  const fetchSaveNextAppointmentUtility = async (): Promise<void> => {
    try {
      setDisabledToSend(true)
      const { data, status } = await saveNextAppointmentUtility({
        appointment_id: appointment?.appointment_id as string,
        appointment_comment: comments,
        appointment_date: selectedDate?.format('YYYY-MM-DD') as string,
        hour_from: hour,
        office_id: selectedOffice,
        duration: isCustomType ? customDuration.value : duration,
        appointment_reason: isCustomType ? customType : appointmentType,
        reason_type: isCustomType ? 'CUSTOM' : 'DEFAULT',
      })

      if (status && (data as ISaveNextAppointment).success) {
        handleAlert(true, (data as ISaveNextAppointment).message, 'success')
        handleSendNextAppointmentAfterSubmit()
      } else {
        handleAlert(
          true,
          (data as ISaveNextAppointment).message || (data as string),
          'error',
        )
      }
      setDisabledToSend(false)
    } catch (error: unknown) {
      handleAlert(true, 'Error al agendar la próxima cita.', 'error')
      setDisabledToSend(false)
    }
  }

  const onSubmit = (event: FormEvent<HTMLFormElement>): void => {
    event.preventDefault()
    fetchSaveNextAppointmentUtility()
  }

  const handleChangeOffice = (office?: IMedicalOffice): void => {
    setSelectedOffice(office?.office_id as string)
    setAppointmentTypeList(
      office?.appointment_types?.length
        ? [
          ...(office?.appointment_types?.map(
            (type) => type.name,
          ) as string[]),
          'Otro',
        ]
        : [],
    )

    setHour('')
    setSelectedDate(null)
    setHourAvailavility([])
    setAppointmentType('')
    setCalendarDisabled(true)
    setDuration(0)
    setIsCustomType(false)
    setCustomDuration({ name: '', value: 0 })
    setCustomType('')
  }

  const handleChangeDate = (value: dayjs.Dayjs | null): void =>
    setSelectedDate(value as dayjs.Dayjs)
  const handleChangeHour = (value: string): void => setHour(value)
  const handleChangeComments = (value: string): void => setComments(value)
  const handleOpenNextAppointment = (): void => setOpenNextAppointment(true)
  const handleCloseNextAppointment = (): void => setOpenNextAppointment(false)

  const disabledButton = (): boolean =>
    !selectedDate ||
    !selectedDate?.isValid() ||
    !hour ||
    !comments ||
    !validCommentsLength ||
    disabledToSend ||
    (isCustomType && !customType)

  const validCommentsLength: boolean = comments?.length <= commentRequiredLength
  const commentsHelperText: string = !validCommentsLength
    ? `Máximo número de caracteres ${commentRequiredLength}.`
    : ''

  useEffect(() => {
    if (isCustomType) {
      if (selectedOffice && customDuration.value) {
        fetchDaysAvailabilityInAMonth().then((result) => {
          setDaysAvailability(result)
          setCalendarDisabled(false)
        })
      } else {
        setHourAvailavility([])
        setCalendarDisabled(true)
        setSelectedDate(null)
        setHour('')
      }
    } else if (selectedOffice && duration) {
      fetchDaysAvailabilityInAMonth().then((result) => {
        setDaysAvailability(result)
        setCalendarDisabled(false)
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOffice, duration, customDuration, isCustomType])

  useEffect(() => {
    if (isCustomType) {
      if (selectedDate?.isValid() && customDuration.value) {
        fetchAvailableHoursInADay()
      }
    } else if (selectedDate?.isValid() && duration) {
      fetchAvailableHoursInADay()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDate, duration, customDuration])

  useEffect(() => {
    const type = officeSelected
      .find((ofc: IMedicalOffice) => ofc.office_id === selectedOffice)
      ?.appointment_types?.find((type) => type.name === appointmentType)
    if (type) {
      setIsCustomType(false)
      setDuration(type?.duration as number)
    } else if (appointmentType) {
      setIsCustomType(true)
    }
  }, [appointmentType])

  return {
    selectedOffice,
    selectedDate,
    daysAvailability,
    hourAvailavility,
    hour,
    comments,
    idDoctor,
    validCommentsLength,
    commentRequiredLength,
    commentsHelperText,
    openNextAppointment,
    handleOpenNextAppointment,
    handleCloseNextAppointment,
    onSubmit,
    handleChangeOffice,
    handleChangeDate,
    handleChangeComments,
    handleChangeHour,
    disabledButton,
    appointmentType,
    setAppointmentType,
    appointmentTypeList,
    setDuration,
    duration,
    isCustomType,
    customDuration,
    setCustomDuration,
    setIsCustomType,
    customType,
    setCustomType,
    calendarDisabled,
  }
}
