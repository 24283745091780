import { Add as AddIcon } from '@mui/icons-material'
import dayjs from 'dayjs'
import { useEffect, useState } from 'react'

import { ButtonWrapper, GridWrapper } from '../../../../../../../components'
import { Check } from '../../../../../../../components/Check/Check'
import { GridItem } from '../../../../../../../components/GridItem/GridItem'
import { List } from '../../../../../../../components/List/List'
import { TextError } from '../../../../../../../components/ModalDate/ModalDateStyles'
import { LabelInput } from '../../../../../../../components/StyledComponents/LabelInput'
import { SecondLabelInput } from '../../../../../../../components/StyledComponents/SecondLabelInput'
import { IDaysMedicalCare } from '../../../../../../../contexts/RegisterContext'
import { IDataOffice, daysToWeek } from '../../hooks/useUpdateSchedule'
import HourInput from '../HourInput/HourInput'

interface IScheduleProps {
  office: IDataOffice
  days: { [key: string]: IDaysMedicalCare }
  setDays: React.Dispatch<React.SetStateAction<{
    [key: string]: IDaysMedicalCare;
  }>>
}

const weekDays: { [key: string]: string } = {
  lunes: 'monday',
  martes: 'tuesday',
  miércoles: 'wednesday',
  jueves: 'thursday',
  viernes: 'friday',
  sábado: 'saturday',
  domingo: 'sunday'
}

export const Schedule = ({
  office, days, setDays
}: IScheduleProps): JSX.Element => {

  const [hours, setHours] = useState<string[]>([])
  const [selectAll, setSelectAll] = useState<boolean>(false)
  const [daysOfWeekToShow, setDaysOfWeekToShow] = useState<string[]>(
    Object.keys(daysToWeek)
      .filter((d) => !Boolean(days[d]))
      .map((d) => daysToWeek[d].day)
  )

  const handleSetHour = (valueData: string, type: string): void => {
    const daysModified: { [key: string]: IDaysMedicalCare } = Object.keys(days)
      .reduce((acc: { [key: string]: IDaysMedicalCare }, day: string) => {
        if (days[day].edit) {
          return {
            ...acc,
            [day]: {
              ...acc[day],
              [type]: valueData,
            }
          }
        }
        return { ...acc }
      }, days)
    setDays({ ...daysModified })
  }

  const onChangeSelectAllDay = (checked: boolean): void => {
    setSelectAll(checked)
    const daysToModify: { [key: string]: IDaysMedicalCare } = structuredClone(days)
    Object.keys(days).forEach((day: string) => {
      daysToModify[day].edit = checked
    })
    setDays(daysToModify)
  }

  const onDeleteSelection = (): void => {
    const daysToModify: { [key: string]: IDaysMedicalCare } = structuredClone(days)
    Object.keys(days).forEach((day: string) => {
      if (daysToModify[day].edit) {
        delete daysToModify[day]
      }
    })
    setDays(daysToModify)
  }

  const onAddDay = (): void => {
    const daysToModify: { [key: string]: IDaysMedicalCare } = structuredClone(days)
    daysToModify[''] = {
      edit: true,
      active: true,
      day: '',
      from: '00h00',
      to: '00h00',
    }
    setDays(daysToModify)
  }

  const onChangeSelectDay = (day: string, checked: boolean): void => {
    setDays((d) => ({
      ...d,
      [day]: {
        ...d[day],
        edit: checked,
      }
    }))
    if (!checked) {
      setSelectAll(false)
    }
  }

  const isDeleteButtonDisabled: boolean = Object.keys(days).every((d) => !days[d].edit)

  useEffect(() => {
    if (hours.length === 0) {
      const options: string[] = []
      for (let i = 0; i < 24; i++) {
        for (let j = 0; j < 2; j++) {
          options.push(
            dayjs()
              .hour(i)
              .minute(j * 30)
              .format('HH[h]mm'),
          )
        }
      }
      setHours(options)
    }
  }, [hours.length])

  useEffect(() => {
    setDaysOfWeekToShow(
      Object.keys(daysToWeek)
        .filter((d) => !Boolean(days[d]))
        .map((d) => daysToWeek[d].day)
    )
  }, [days])

  const onChangeDay = (value: string, day: string): void => {
    const daysTemp = structuredClone(days)
    daysTemp[weekDays[value]] = {
      ...daysTemp[day],
      day: value
    }
    delete daysTemp[day]
    setDays(daysTemp)
  }

  return (
    <>
      <SecondLabelInput sx={{ ml: 0 }}>
        {`¿Qué días y en qué horario quieres atender en ${office?.reference}?`}
      </SecondLabelInput>
      <GridWrapper container>
        <GridWrapper item xs={12} marginY={2}>
          <GridWrapper
            container
            display={'flex'}
          >
            <GridItem item xs={6} justifyContent={'flex-start'}>
              <Check
                label='Seleccionar todos'
                checked={selectAll}
                onChange={(event, checked) => {
                  onChangeSelectAllDay(checked)
                }}
              />
            </GridItem>
            <GridWrapper
              item
              xs={4}
              display={'flex'}
              justifyContent={'center'}
              mx={1}
            >
              <ButtonWrapper
                variant='outlined'
                sx={{
                  textTransform: 'none',
                  borderRadius: '30px'
                }}
                onClick={onDeleteSelection}
                disabled={isDeleteButtonDisabled}
              >
                Eliminar selección
              </ButtonWrapper>
            </GridWrapper>
            <GridWrapper
              item
              xs={2}
              display={'flex'}
              justifyContent={'center'}
              mx={1}
            />
          </GridWrapper>
        </GridWrapper>
        <GridWrapper item xs={12} marginTop={2}>
          <GridWrapper
            container
            display={'flex'}
            justifyContent={'space-between'}
          >
            <GridWrapper item xs={6} />
            <GridWrapper
              item
              xs={2}
              display={'flex'}
              justifyContent={'center'}
              mx={1}
            >
              <LabelInput sx={{ ml: 0, fontWeight: 'normal' }}>
                Desde
              </LabelInput>
            </GridWrapper>
            <GridWrapper
              item
              xs={2}
              display={'flex'}
              justifyContent={'center'}
              mx={1}
            >
              <LabelInput sx={{ ml: 0, fontWeight: 'normal' }}>
                Hasta
              </LabelInput>
            </GridWrapper>
          </GridWrapper>
        </GridWrapper>
        {Object.keys(days).length > 0 &&
          Object.keys(days)?.map((day: string, key: number) => {
            return (
              <GridWrapper item xs={12} key={day} marginY={2} marginTop={key === 0 ? 0 : 2}>
                <GridWrapper
                  container
                  display={'flex'}
                  justifyContent={'space-between'}
                >
                  <GridItem item xs={1}>
                    <Check
                      data-testid={`${day}-check`}
                      checked={days[day]?.edit}
                      onChange={(event, checked) => onChangeSelectDay(day, checked)}
                    />
                  </GridItem>
                  <GridWrapper item xs={5}>
                    <List
                      id={`day-${key}`}
                      lists={
                        days[day].day ? [days[day].day, ...daysOfWeekToShow] : daysOfWeekToShow
                      }
                      onClick={(value: string) => onChangeDay(value, day)}
                      value={days[day].day}
                      disabled={!Boolean(days[day]?.edit)}
                      minWidth="100%"
                    />
                  </GridWrapper>
                  <GridWrapper
                    item
                    xs={2}
                    display={'flex'}
                    justifyContent={'center'}
                    mx={1}
                  >
                    <HourInput
                      hour={days[day]?.from}
                      day={day}
                      disabled={!Boolean(days[day]?.edit)}
                      hours={hours}
                      type
                      setValue={(value: string) => handleSetHour(value, 'from')}
                    />
                  </GridWrapper>
                  <GridWrapper
                    item
                    xs={2}
                    display={'flex'}
                    justifyContent={'center'}
                    mx={1}
                  >
                    <GridWrapper container>
                      <GridWrapper item xs={12}>
                        <HourInput
                          hour={days[day]?.to}
                          day={day}
                          disabled={!Boolean(days[day]?.edit)}
                          hours={hours}
                          type={false}
                          setValue={(value: string) => handleSetHour(value, 'to')}
                        />
                        {days[day]?.from >= days[day]?.to && (
                          <TextError sx={{
                            width: '150px',
                          }}>
                            La hora de cierre debe ser mayor a la hora de apertura
                          </TextError>
                        )}
                      </GridWrapper>
                      {<GridWrapper item xs={12} />}
                    </GridWrapper>
                  </GridWrapper>
                </GridWrapper>
              </GridWrapper>
            )
          })
        }
        <GridWrapper item xs={12} marginY={2} display={'flex'} justifyContent={'flex-end'}>
          <ButtonWrapper
            variant='contained'
            onClick={onAddDay}
            sx={{
              textTransform: 'none',
              borderRadius: '30px'
            }}
            startIcon={<AddIcon />}
            size='large'
          >
            Agregar otro
          </ButtonWrapper>
        </GridWrapper>
      </GridWrapper>
    </>
  )
}
