import dayjs from 'dayjs'
import * as React from 'react'

import { useNavigate } from 'react-router-dom'

import { AppointmentDetailsModal } from './Components/AppointmentDetailsModal/AppointmentDetailsModal'
import { useAppointmentDetailsModal } from './Components/AppointmentDetailsModal/AppointmentDetailsModalHook'
import HoursOfNonAttendanceModal from './Components/HoursOfNonAttendanceModal/HoursOfNonAttendanceModal'
import MenuCalendar from './Components/MenuCalendar'
import { useHoursOfNonAttendanceModal } from './Hooks/useHoursOfNonAttendanceModal'
import { CustomCalendar } from '../../../components/Calendar/Calendar'
import { ICalendarEvent } from '../../../components/Calendar/types'
import NewDate from '../../../components/ModalDate/components/ModalDatePicker/NewDate'
import { CalendarContext } from '../../../contexts/CalendarContext'
import { useDoctorInformationStore } from '../../../contexts/DoctorInformationState'
import { useSelectOffice } from '../../../contexts/SelectOfficeState'
import { Appointment } from '../../../infrastructure/dtos/Appointments'
import { IMedicalOffice } from '../../../infrastructure/dtos/Offices'
import { useAppointments } from '../Appointments/Hooks/useAppointments'
import { useNotifyDelayHook } from '../Appointments/Hooks/useNotifyDelay'
import { useReschedule } from '../Appointments/Hooks/useReschedule'
import { AppointmentOptionsModals } from '../components/modals/AppointmentOptionsModals'
import { useCancelAppointment } from '../components/modals/CancelAppointment/hook/useCancelAppointment'
import { useCancelReason } from '../components/modals/CancelAppointment/modals/CancelReason/hooks/useCancelReason'
import { useNextAppointment } from '../components/modals/NextAppointment/NextAppointmentHook'
import { useRecipeModal } from '../components/modals/RecipeModal/hook'

const CalendarForm: React.FC = (): React.JSX.Element => {
  const { doctorInformation } = useDoctorInformationStore()
  const navigate = useNavigate()
  const {
    selectedDate,
    toolbarPosition,
    handleClose: handleCloseMenu,
    handleSaveSelectedDate,
    getEvents,
    currentCalendarDateToShow,
  } = React.useContext(CalendarContext)

  const { officeSelected } = useSelectOffice()

  const [openNewAppointmentModal, setOpenNewAppointmentModal] =
    React.useState<boolean>(false)
  const handleOpenNewAppointmentModal = (): void => {
    setOpenNewAppointmentModal(true)
  }
  const handleCloseNewAppointmentModal = (): void => {
    setOpenNewAppointmentModal(false)
  }

  const { handleClose, handleOpen, open } = useHoursOfNonAttendanceModal()
  const { openAppointmentDetailsModal, handleOpenAppointmentDetailsModal } =
    useAppointmentDetailsModal()

  const { openRecipeModal, handleCloseRecipeModal, handleOpenRecipeModal } =
    useRecipeModal()
  const { openReschedule, handleCloseReschedule, handleOpenReschedule } =
    useReschedule()
  const { handleOpenCancelAppointment, openCancelAppointmentModal } =
    useCancelAppointment()
  const { appointment, hamdleChangeAppointment } = useAppointments()
  const {
    handleSendNotification,
    handleOpenNotifyDelay,
    handleCloseNotifyDelay,
    openNotifyDelay,
    loading,
  } = useNotifyDelayHook()

  const { handleOpenCancelReason, openCancelReasonModal } = useCancelReason()

  const {
    handleCloseNextAppointment,
    handleOpenNextAppointment,
    openNextAppointment,
  } = useNextAppointment({})

  const onCloseRecipeModal = (): void => {
    // if (isSent) fetchApointment()
    handleCloseRecipeModal()
  }

  const [appointmentId, setAppointmentId] = React.useState<string>('')

  const [selectedOffice, setSelectedOffice] = React.useState<
    IMedicalOffice | undefined
  >(undefined)

  React.useEffect(() => {
    if (officeSelected) {
      setSelectedOffice(
        (officeSelected as IMedicalOffice[]).find(
          (ofc: IMedicalOffice) => ofc.selected,
        ),
      )
    }
  }, [officeSelected])

  React.useEffect(() => {
    if (selectedOffice) {
      getEvents(
        (currentCalendarDateToShow as dayjs.Dayjs).format('YYYY-MM-DD'),
        selectedOffice.office_id,
        doctorInformation?.user_id as string,
      )
    }
  }, [selectedOffice])
  React.useEffect(() => {
    const urlStorage = localStorage.getItem('url')
    if (
      urlStorage !== 'undefined' &&
      urlStorage !== undefined &&
      urlStorage !== null
    ) {
      localStorage.removeItem('url')
      navigate(urlStorage)
    }
  }, [])

  const onSaveSelectedDate = (data: ICalendarEvent): void => {
    handleSaveSelectedDate(data)
    if (selectedOffice) {
      getEvents(
        (currentCalendarDateToShow as dayjs.Dayjs)?.format('YYYY-MM-DD'),
        selectedOffice.office_id,
        doctorInformation?.user_id as string,
      )
    }
  }

  const onChangeAppointment = (data: Appointment): void => {
    handleOpenAppointmentDetailsModal(false)
    hamdleChangeAppointment(data)
  }
  const onOpenNotifyDelay = (eventId: string): void => {
    handleOpenAppointmentDetailsModal(false)
    handleOpenNotifyDelay(eventId)
  }
  const onOpenRecipeModal = (): void => {
    handleOpenAppointmentDetailsModal(false)
    handleOpenRecipeModal()
  }
  const onOpenReschedule = (): void => {
    handleOpenAppointmentDetailsModal(false)
    handleOpenReschedule()
  }
  const onOpenCancelAppointmentModal = (): void => {
    handleOpenAppointmentDetailsModal(false)
    handleOpenCancelAppointment(true)
  }

  const ocCloseReschedulingModal = (): void => {
    getEvents(
      (currentCalendarDateToShow as dayjs.Dayjs).format('YYYY-MM-DD'),
      selectedOffice?.office_id as string,
      doctorInformation?.user_id as string,
    )
    handleCloseReschedule()
  }

  const onCloseNextAppointmentModal = (): void => {
    getEvents(
      (currentCalendarDateToShow as dayjs.Dayjs).format('YYYY-MM-DD'),
      selectedOffice?.office_id as string,
      doctorInformation?.user_id as string,
    )

    handleCloseNextAppointment()
  }

  const onCloseCancelReason = (): void => {
    getEvents(
      (currentCalendarDateToShow as dayjs.Dayjs).format('YYYY-MM-DD'),
      selectedOffice?.office_id as string,
      doctorInformation?.user_id as string,
    )

    handleOpenCancelReason(false)
    handleOpenCancelAppointment(false)
  }

  const onOpenNextAppointment = (): void => {
    handleOpenAppointmentDetailsModal(false)
    handleOpenNextAppointment()
  }

  const handleUpdateCalendar = (officeId: string): void => {
    if (
      officeSelected.some(
        (of: IMedicalOffice) => of.office_id === officeId && of.selected,
      )
    ) {
      getEvents(
        (currentCalendarDateToShow as dayjs.Dayjs).format('YYYY-MM-DD'),
        officeId,
        doctorInformation?.user_id as string,
      )
    }
  }

  return (
    <>
      {selectedDate && toolbarPosition && (
        <MenuCalendar
          handleClose={handleCloseMenu}
          toolbarPosition={toolbarPosition}
          handleOpenNonAttendanceModal={handleOpen}
          handleOpenNewAppointmentModal={handleOpenNewAppointmentModal}
        />
      )}
      <CustomCalendar
        office={selectedOffice}
        userId={doctorInformation?.user_id as string}
        openAppointmentDetailsModal={openAppointmentDetailsModal}
        handleOpenAppointmentDetailsModal={handleOpenAppointmentDetailsModal}
        setAppointmentId={setAppointmentId}
      />
      {open && (
        <HoursOfNonAttendanceModal
          handleClose={handleClose}
          open={open}
          handleSaveSelectedDate={onSaveSelectedDate}
          selectedDate={selectedDate}
          selectedOffice={selectedOffice}
        />
      )}
      {openAppointmentDetailsModal && (
        <AppointmentDetailsModal
          openAppointmentDetailsModal={openAppointmentDetailsModal}
          handleOpenAppointmentDetailsModal={handleOpenAppointmentDetailsModal}
          appointmentId={appointmentId}
          hamdleChangeAppointment={onChangeAppointment}
          handleOpenNotifyDelay={onOpenNotifyDelay}
          handleOpenRecipeModal={onOpenRecipeModal}
          handleOpenReschedule={onOpenReschedule}
          handleOpenNextAppointment={onOpenNextAppointment}
          handleOpenCancelAppointment={onOpenCancelAppointmentModal}
        />
      )}
      <AppointmentOptionsModals
        openRecipeModal={openRecipeModal}
        openReschedule={openReschedule}
        openNotifyDelay={openNotifyDelay}
        loadingNotifyDelay={loading}
        officeId={appointment?.office_id as string}
        appointment={appointment}
        handleSendNotification={handleSendNotification}
        handleCloseNotifyDelay={handleCloseNotifyDelay}
        onCloseRecipeModal={onCloseRecipeModal}
        handleCloseReschedule={handleCloseReschedule}
        handleSendRescheduleAfterSubmit={ocCloseReschedulingModal}
        handleCloseNextAppointment={handleCloseNextAppointment}
        handleSendNextAppointmentAfterSubmit={onCloseNextAppointmentModal}
        openNextAppointment={openNextAppointment}
        handleOpenCancelAppointment={handleOpenCancelAppointment}
        openCancelAppointmentModal={openCancelAppointmentModal}
        handleOpenCancelReason={handleOpenCancelReason}
        openCancelReasonModal={openCancelReasonModal}
        onCloseCancelReason={onCloseCancelReason}
      />
      {openNewAppointmentModal && (
        <NewDate
          handleClose={handleCloseNewAppointmentModal}
          open={openNewAppointmentModal}
          handleUpdateCalendar={handleUpdateCalendar}
        />
      )}
    </>
  )
}

export default CalendarForm
