import { Theme, useMediaQuery, useTheme } from '@mui/material'
import dayjs from 'dayjs'
import React, { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { useAppointmentDurations } from './useAppointmentDurations'
import { useAppointmentReasons } from './useAppointmentReasons'
import { useVisibilityAppointmentTime } from './useVisibilityAppointmentTime'
import { useVisibilityIntervals } from './useVisibilityIntervals'
import {
  AccountContext,
  AuthContextType,
} from '../../../../../contexts/AccountContext'
import {
  ICareMedicalOffice,
  IDaysMedicalCare,
  IOfficeMedical,
  RegisterMedicalContext,
} from '../../../../../contexts/RegisterContext'
import { formatHourToValidate } from '../../../../../utils/FormatHour'
import { useForm } from '../../../../../utils/useForm'
import { validateOfficePhoneNumber } from '../../../../../utils/validatePhoneNumber'
import { Duration } from '../../Components/hooks/useAppointmentTypesList'
import {
  IMessage,
  useSaveProfileMedical,
} from '../../Hooks/useSaveProfileMedical'
import text from '../ProfileMedicalScheduleSettings.json'

interface IDataOffice {
  reference: string
  id: string
}

export interface AppointmentType {
  id: number
  name: string
  erasable: boolean
  duration?: string
  duration_value?: number
}

const initialDataConsultory: IDaysMedicalCare[] = [
  {
    day: 'Lunes',
    from: '00h00',
    to: '00h00',
    active: false,
  },
  {
    day: 'Martes',
    from: '00h00',
    to: '00h00',
    active: false,
  },
  {
    day: 'Miércoles',
    from: '00h00',
    to: '00h00',
    active: false,
  },
  {
    day: 'Jueves',
    from: '00h00',
    to: '00h00',
    active: false,
  },
  {
    day: 'Viernes',
    from: '00h00',
    to: '00h00',
    active: false,
  },
  {
    day: 'Sábado',
    from: '00h00',
    to: '00h00',
    active: false,
  },
  {
    day: 'Domingo',
    from: '00h00',
    to: '00h00',
    active: false,
  },
]

interface IUseProfileMedicalScheduleSettingHook {
  amountRanges: string[]
  lists: string[]
  loading: boolean
  selectedExperience: string
  selectTime: string
  phoneNumber: string
  office: IDataOffice
  dataConsultory: IDaysMedicalCare[]
  disabledButton?: boolean
  openAlert: boolean
  message: IMessage
  validatePhoneNumber: boolean
  validatePhoneNumberText: string
  isExtraSmallSize: boolean
  submitButtonText: string
  onBack: () => void
  onSubmit: (event: { preventDefault: () => void }) => void
  onChange: (
    value: string,
    field: 'selectedExperience' | 'selectTime' | 'phoneNumber',
  ) => void
  onOption: () => void
  setDataConsultory: React.Dispatch<React.SetStateAction<IDaysMedicalCare[]>>
  typesList: AppointmentType[]
  setTypesList: React.Dispatch<React.SetStateAction<AppointmentType[]>>
  durationsDB: Duration[]
  typesDB: string[]
}

const initialTypesList: AppointmentType[] = []


export function useProfileMedicalScheduleSetting(): IUseProfileMedicalScheduleSettingHook {
  const { handleAlert } = useContext(AccountContext) as AuthContextType
  const navigate = useNavigate()
  const { RegisterMedical, saveOfficeMedicalScreen } = useContext(
    RegisterMedicalContext,
  )
  const theme: Theme = useTheme()
  const isExtraSmallSize = !useMediaQuery(theme.breakpoints.up('sm'))
  const { SaveData, message, openAlert, onOption, loading } =
    useSaveProfileMedical()
  const { amountRanges } = useVisibilityIntervals(handleAlert)
  const { lists } = useVisibilityAppointmentTime(handleAlert)
  const { types: typesDB } = useAppointmentReasons()
  const { durations: durationsDB } = useAppointmentDurations()
  const [submit, setSubmit] = useState<boolean>(false)

  const [office, setOffice] = useState<IDataOffice>({
    reference: `${RegisterMedical?.officeMedical[0]?.building_name} ${RegisterMedical?.officeMedical[0]?.building_floor} ${RegisterMedical?.officeMedical[0]?.office_number}`,
    id: RegisterMedical?.officeMedical[0]?.id,
  })

  const [changeMedicalBool, setChangeMedicalBool] = useState(false)
  const dataReturnContext: ICareMedicalOffice = {
    daysMedicalCare: initialDataConsultory,
    durationMedicalAppointment: '15 minutos',
    reference: `${RegisterMedical?.officeMedical[0]?.building_name} ${RegisterMedical?.officeMedical[0]?.building_floor} ${RegisterMedical?.officeMedical[0]?.office_number}`,
    visualisationAgenda: '1 mes',
    id: RegisterMedical?.officeMedical[0]?.id,
    office_phone_number: RegisterMedical?.medicalCare[0]?.office_phone_number,
    appointmentTypes: initialTypesList
  }
  const [resultMedicalSearch, setResultMedicalSearch] =
    useState<ICareMedicalOffice>(dataReturnContext)
  const {
    selectedExperience,
    selectTime,
    phoneNumber,
    onChange,
    onChangeAllState,
  } = useForm({
    selectedExperience: amountRanges[0],
    selectTime: lists[0],
    phoneNumber: '',
  })

  const [typesList, setTypesList] = useState<AppointmentType[]>(
    initialTypesList,
  )
  const [validList, setValidList] = useState<boolean>(true)
  const [dataConsultory, setDataConsultory] = useState<IDaysMedicalCare[]>(
    initialDataConsultory,
  )
  const [disabledButton, setDisabledButton] = useState<boolean>(true)

  const dataOfficeObject: IDataOffice[] = RegisterMedical?.officeMedical.map(
    (item: IOfficeMedical) => {
      return {
        reference: `${item.building_name} ${item.building_floor} ${item.office_number}`,
        id: item.id,
      }
    },
  )

  const onchangeOfficeMedical = (value: string, id: string): void => {
    saveOfficeMedicalScreen({
      daysMedicalCare: dataConsultory,
      durationMedicalAppointment: selectTime,
      reference: office.reference,
      visualisationAgenda: selectedExperience,
      office_phone_number: phoneNumber,
      id: office.id,
      appointmentTypes: typesList,
    })
    setOffice((office) => ({ ...office, reference: value, id }))
    setChangeMedicalBool(true)
  }

  const onChangeDaysMedicalCare = (): void => {
    if (selectTime !== undefined && selectedExperience !== undefined) {
      if (!office.id) {
        return
      }
      saveOfficeMedicalScreen({
        daysMedicalCare: dataConsultory,
        durationMedicalAppointment: selectTime,
        reference: office.reference,
        visualisationAgenda: selectedExperience,
        office_phone_number: phoneNumber,
        id: office.id,
        appointmentTypes: typesList,
      })
    }
  }
  const currentIndex = (id: string): number =>
    dataOfficeObject.findIndex((x: IDataOffice) => x.id === id)

  const validatePhoneNumber: boolean =
    !validateOfficePhoneNumber(phoneNumber) && phoneNumber?.length > 0
  const validatePhoneNumberText: string = validatePhoneNumber
    ? text.validate_phone_number_text
    : ''
  const submitButtonText: string =
    currentIndex(office.id) + 1 === dataOfficeObject.length
      ? text.begin_button
      : text.next_button

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
    const searchReferenceTwo = office.id
    const careMedicalOfficeTwoIndex = RegisterMedical.medicalCare.findIndex(
      (care) => care.id === searchReferenceTwo,
    )
    const dataReturnDefault: ICareMedicalOffice = {
      daysMedicalCare: initialDataConsultory,
      durationMedicalAppointment: '15 minutos',
      reference: office.reference,
      visualisationAgenda: '1 mes',
      id: office.id,
      office_phone_number: '',
      appointmentTypes: initialTypesList,
    }

    if (careMedicalOfficeTwoIndex !== -1) {
      const dataReturn: ICareMedicalOffice =
        RegisterMedical.medicalCare[careMedicalOfficeTwoIndex]
      if (dataReturn === undefined) {
        setResultMedicalSearch(dataReturnDefault)
      }
      setResultMedicalSearch(dataReturn as ICareMedicalOffice)
    } else {
      setResultMedicalSearch(dataReturnDefault)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [office])

  useEffect(() => {
    onChangeAllState({
      phoneNumber: resultMedicalSearch?.office_phone_number,
      selectedExperience: resultMedicalSearch?.visualisationAgenda,
      selectTime: resultMedicalSearch?.durationMedicalAppointment,
    })
    setTypesList(resultMedicalSearch?.appointmentTypes)
    setDataConsultory(resultMedicalSearch?.daysMedicalCare)
    // eslint-disable-next-line
  }, [resultMedicalSearch])

  useEffect(() => {
    if (changeMedicalBool) {
      setTypesList(resultMedicalSearch?.appointmentTypes)
      setDataConsultory(resultMedicalSearch?.daysMedicalCare)
      setChangeMedicalBool(false)
    }
    // eslint-disable-next-line
  }, [changeMedicalBool, selectTime])

  useEffect(() => {
    onChangeDaysMedicalCare()
    validateTypeList()

    // eslint-disable-next-line
  }, [dataConsultory, typesList])

  useEffect(() => {
    const currentOffice: ICareMedicalOffice | undefined =
      RegisterMedical.medicalCare.find(
        (consultory) => consultory.id === office?.id,
      )

    const filteredDays: IDaysMedicalCare[] | undefined =
      currentOffice?.daysMedicalCare.filter((day) => !!day.active)
    const isDisabled: boolean =
      validatePhoneNumber ||
      !(
        filteredDays &&
        filteredDays?.every((day) => {
          const hourFrom: string = formatHourToValidate(day.from)
          const hourTo: string = formatHourToValidate(day.to)
          return (
            day?.active &&
            dayjs(`${dayjs().format('YYYY-MM-DD')}T${hourFrom}`).isBefore(
              dayjs(`${dayjs().format('YYYY-MM-DD')}T${hourTo}`),
            )
          )
        })
      )

    setDisabledButton(filteredDays?.length === 0 || isDisabled || validList)
    // eslint-disable-next-line
  }, [saveOfficeMedicalScreen, phoneNumber])

  useEffect(() => {
    if (submit) {
      SaveData()
      setSubmit(false)
    }
    // eslint-disable-next-line
  }, [submit])

  const onSubmit = (event: { preventDefault: () => void }): void => {
    event.preventDefault()
    const index: number = currentIndex(office.id)
    if (currentIndex(office.id) + 1 < dataOfficeObject.length) {
      if (index >= 0) {
        onchangeOfficeMedical(
          dataOfficeObject[index + 1].reference,
          dataOfficeObject[index + 1].id,
        )
      }
    } else if (currentIndex(office.id) + 1 === dataOfficeObject.length) {
      saveOfficeMedicalScreen({
        daysMedicalCare: dataConsultory,
        durationMedicalAppointment: selectTime,
        reference: office.reference,
        visualisationAgenda: selectedExperience,
        office_phone_number: phoneNumber,
        id: office.id,
        appointmentTypes: typesList,
      })
      setSubmit(true)
    }
  }
  const validateTypeList = (): void => {
    // validate if all types have duration and have name
    if (typesList?.length === 0) {
      setValidList(true)
      return
    }
    setValidList(!typesList?.every((type) => type.duration && type.name))
  }

  const onBack = (): void => {
    const index: number = currentIndex(office.id)
    if (index === 0) {
      saveOfficeMedicalScreen({
        daysMedicalCare: dataConsultory,
        durationMedicalAppointment: selectTime,
        reference: office.reference,
        visualisationAgenda: selectedExperience,
        office_phone_number: phoneNumber,
        id: office.id,
        appointmentTypes: typesList,
      })
      navigate('/medico/register/registerConsultory')
    } else if (index > 0) {
      onchangeOfficeMedical(
        dataOfficeObject[index - 1].reference,
        dataOfficeObject[index - 1].id,
      )
    }
  }

  return {
    amountRanges,
    lists,
    loading,
    selectedExperience,
    selectTime,
    phoneNumber,
    office,
    dataConsultory,
    disabledButton,
    openAlert,
    message,
    validatePhoneNumber,
    validatePhoneNumberText,
    isExtraSmallSize,
    submitButtonText,
    onBack,
    onSubmit,
    onChange,
    onOption,
    setDataConsultory,
    setTypesList,
    typesList,
    durationsDB,
    typesDB
  }
}
