import { SubHeaderTitle } from './PatientPictureMedicalStyle'
import { usePatientPictureMedical } from './usePatientPictureMedical'
import { GridWrapper } from '../../../../../../../components'
import FormTextArea from '../../../../../../../components/FormTextArea'
import { SecondaryMediumButton } from '../../../../../../../components/SecondaryMediumButton/SecondaryMediumButton'
import { LabelInput } from '../../../../../../../components/StyledComponents/LabelInput'
import { Appointment } from '../../../../../../../infrastructure/dtos/Appointments'
import { DiagnosticBody } from '../../../../../components/DiagnosticBody/DiagnosticBody'
import { RecipeModal } from '../../../../../components/modals/RecipeModal'

interface IPictureClinicMedical {
  appointmentInfo?: Appointment
}

export default function PictureClinicMedical({
  appointmentInfo,
}: IPictureClinicMedical): JSX.Element {
  const {
    isDisabledSecondary,
    loadingAppointmentDetails,
    openRecipeModal,
    diagnosticSelected,
    fetchMessage,
    MAX_LENGTH,
    isLEThanMaxValue,
    isDisabledPrimary,
    diagnostics,
    appointmentDetails,
    onAddDiagnostics,
    onChangeDiagnosticSelected,
    onCloseRecipeModal,
    onDeleteDiagnostics,
    handleMessageOnChange,
    handleOpenRecipeModal,
    handlePictureClinic,
  } = usePatientPictureMedical()  

  return (
    <GridWrapper container px={2} pt={2} data-testid="clinical-picture">
      <GridWrapper
        container
        spacing={2}
        columns={8}
        display={'flex'}
        justifyContent={'flex-end'}
      >
        <GridWrapper item xs={8}>
          <SubHeaderTitle id="transition-modal-title" variant="h6">
            Cuadro clínico de esta cita
          </SubHeaderTitle>
        </GridWrapper>
        <GridWrapper item xs={8}>
          <GridWrapper container spacing={4}>
            <GridWrapper item xs={12} md={6}>
              <LabelInput sx={{ fontWeight: 'normal', margin: 0 }}>
                Ingresa comentarios internos sobre esta cita (el paciente no
                verá esto)
              </LabelInput>
              <FormTextArea
                text=""
                value={fetchMessage}
                onChange={handleMessageOnChange}
                placeholder={
                  loadingAppointmentDetails
                    ? ''
                    : 'Acude por segunda vez presentando el mismo síntoma.'
                }
                fullWidth
                multiline
                rows={12}
                inputProps={{ maxLength: MAX_LENGTH + 1 }}
                data-testid="input-clinical-picture-notes"
                error={!isLEThanMaxValue}
                helperText={
                  !isLEThanMaxValue
                    ? `Máximo número de caracteres ${MAX_LENGTH}`
                    : ''
                }
              />
            </GridWrapper>
            <GridWrapper item xs={12} md={6}>
              <DiagnosticBody
                diagnostics={diagnostics}
                diagnosticSelected={diagnosticSelected}
                onAddDiagnostics={onAddDiagnostics}
                onDeleteDiagnostics={onDeleteDiagnostics}
                onChangeDiagnosticSelected={onChangeDiagnosticSelected}
              />
            </GridWrapper>
          </GridWrapper>
        </GridWrapper>
        <GridWrapper item xs={6} md={3} marginTop={2}>
          <GridWrapper
            container
            spacing={4}
            display={'flex'}
            justifyContent={{ xs: 'center', md: 'flex-end' }}
          >
            <GridWrapper item xs={6}>
              <SecondaryMediumButton
                id="save-clinical-notes-button"
                data-testid="save-clinical-notes-button"
                text="Guardar"
                type="submit"
                fullWidth={false}
                onClick={handlePictureClinic}
                disabled={isDisabledPrimary}
                disableElevation
                sx={{
                  fontSize: '12px',
                  '@media (min-width: 1600px)': {
                    fontSize: '16px',
                  },
                  '&.Mui-disabled': {
                    backgroundColor: 'info.main',
                    color: 'common.white',
                  },
                  backgroundColor: 'primary.dark',
                }}
              />
            </GridWrapper>
            {appointmentDetails.documents.length === 0 && (
              <GridWrapper item xs={6}>
                <SecondaryMediumButton
                  id="recipe-button"
                  data-testid="recipe-button"
                  text="Avanza a receta"
                  type="submit"
                  fullWidth={false}
                  onClick={handleOpenRecipeModal}
                  disabled={isDisabledSecondary}
                  disableElevation
                  sx={{
                    fontSize: '12px',
                    '@media (min-width: 1600px)': {
                      fontSize: '16px',
                    },
                    '&.Mui-disabled': {
                      backgroundColor: 'info.main',
                      color: 'common.white',
                    },
                    backgroundColor: 'primary.dark',
                  }}
                />
              </GridWrapper>
            )}
          </GridWrapper>
        </GridWrapper>
      </GridWrapper>
      {openRecipeModal && (
        <RecipeModal
          openModal={openRecipeModal}
          handleCloseModal={onCloseRecipeModal}
          appointmentSelected={appointmentInfo}
        />
      )}
    </GridWrapper>
  )
}
