import {
  GetAvailableHoursInADay,
  GetCalendarEvent,
  PostCalendarEvent,
  DeleteNonAttendanceHours,
} from '../../../domain/useCase/Calendar'
import {
  ICalendarEvent,
  IParamsGetCalendar,
  IResponseAvailableHours,
  IResponseCalendarEvent,
} from '../../../infrastructure/dtos/Calendar'
import CalendarMapper from '../../../infrastructure/http/mappers/CalendarMapper/CalendarMapper'
import { CalendarRepositoryImpl } from '../../../infrastructure/RepositoryImpl/CalendarRepositoryImpl'

export async function PostAddCalendarEvent(
  event: ICalendarEvent,
): Promise<IResponseCalendarEvent> {
  try {
    const UseCaseApi: PostCalendarEvent = new PostCalendarEvent(
      new CalendarRepositoryImpl(new CalendarMapper()),
    )
    const { statusCode, body } = await UseCaseApi.invoke(event)

    return {
      status: statusCode === 200 ? 1 : 0,
      body,
    }
  } catch (Error: unknown) {
    return {
      status: 0,
      body: (Error as Error)?.message,
    }
  }
}

export async function GetCalendarEvents(
  params: IParamsGetCalendar,
): Promise<IResponseCalendarEvent> {
  try {
    const UseCaseApi: GetCalendarEvent = new GetCalendarEvent(
      new CalendarRepositoryImpl(new CalendarMapper()),
    )
    const { body } = await UseCaseApi.invoke(params)

    return {
      status: 1,
      body,
    }
  } catch (Error: unknown) {
    return {
      status: 0,
      body: (Error as Error)?.message,
    }
  }
}

export async function getAvailableHoursInADay(
  params: IParamsGetCalendar,
): Promise<IResponseAvailableHours> {
  try {
    const UseCaseApi: GetAvailableHoursInADay = new GetAvailableHoursInADay(
      new CalendarRepositoryImpl(new CalendarMapper()),
    )
    const { body } = await UseCaseApi.invoke(params)

    return {
      status: 1,
      body,
    }
  } catch (Error: unknown) {
    return {
      status: 0,
      body: (Error as Error)?.message,
    }
  }
}

export async function deleteNonAttendanceHours(
  office_id: string,
  idInterval: string,
): Promise<IResponseCalendarEvent> {
  try {
    const UseCaseApi: DeleteNonAttendanceHours = new DeleteNonAttendanceHours(
      new CalendarRepositoryImpl(new CalendarMapper()),
    )
    const { body, statusCode } = await UseCaseApi.invoke(office_id, idInterval)

    return {
      status: statusCode === 200 ? 1 : 0,
      body,
    }
  } catch (error: unknown) {
    return {
      status: 0,
      body: (error as Error)?.message,
    }
  }
}
