import {
  IReschedulingAppointment,
  IResponseReschedulingAppointment,
} from '../../infrastructure/dtos/ReschedulingAppointment'
import { IReschedulingAppointmentRepository } from '../repository/ReschedulingAppointmentRepository'

export interface IReschedulingAppointmentUseCase {
  invoke: (
    datadataReschedulingAppointment: IReschedulingAppointment,
  ) => Promise<IResponseReschedulingAppointment>
}

export class RescheduleAppointment implements IReschedulingAppointmentUseCase {
  private objectUseCase: IReschedulingAppointmentRepository

  constructor(_objectUseCase: IReschedulingAppointmentRepository) {
    this.objectUseCase = _objectUseCase
  }

  invoke(
    datadataReschedulingAppointment: IReschedulingAppointment,
  ): Promise<IResponseReschedulingAppointment> {
    return this.objectUseCase.saveReschedulingAppointment(
      datadataReschedulingAppointment,
    )
  }
}
