import { ScheduleRepository } from '../../domain/repository/ScheduleRepository'
import {
  BodyCheckScheduleImpactRequest,
  IApiResponseCheckScheduleImpact,
} from '../dtos/CheckScheduleImpact'
import { IScheduleDataSource } from '../http/dataSource/ScheduleDataSource'

export class ScheduleRepositoryImpl implements ScheduleRepository {
  private readonly dataSource: IScheduleDataSource

  constructor(dataSource: IScheduleDataSource) {
    this.dataSource = dataSource
  }

  async checkScheduleImpact(
    body: BodyCheckScheduleImpactRequest,
  ): Promise<IApiResponseCheckScheduleImpact> {
    return await this.dataSource.checkScheduleImpact(body)
  }

  async checkModifyOfficeImpact(
    params: { office_id: string, user_id: string },
  ): Promise<IApiResponseCheckScheduleImpact> {
    return await this.dataSource.checkModifyOfficeImpact(params)
  }
}
