import { useProfileMedicalForm1 } from './Hooks/useProfileMedicalForm1'
import containerText from './ProfileMedicalForm1Text.json'
import { GridWrapper, StackWrapper } from '../../../../components'
import AutocompleteComponent from '../../../../components/Autocomplete'
import { BackButton } from '../../../../components/BackButton/BackButton'
import FormTextArea from '../../../../components/FormTextArea'
import { GridFullScreen } from '../../../../components/GridFullScreen/GridFullScreen'
import { List } from '../../../../components/List/List'
import { Loading } from '../../../../components/Loading/Loading'
import SelectButtonList from '../../../../components/SelectButtonList/SelectButtonList'
import { GridPartialScreen } from '../../../../components/StyledComponents/GridPartialScreen'
import { LabelInput } from '../../../../components/StyledComponents/LabelInput'
import { Title } from '../../../../components/StyledComponents/Title'
import SubmitButton from '../../../../components/SubmitButton'
import { ISpecialty } from '../../../../infrastructure/dtos'
import { requiredField } from '../../../../utils/requiredField'
import { SkillsAutocomplete } from '../Components/SkillsAutocomplete'
import { GridForm } from '../ProfileMedicalScheduleSetting/ProfileMedicalScheduleSettingStyle'

const ProfileMedicalForm1 = (): JSX.Element => {
  const {
    errorTextArea,
    isExtraSmallSize,
    lists,
    amountRanges,
    studies,
    selectedExperience,
    specialty,
    skills,
    setSkills,
    setSpecialty,
    onChange,
    setStudies,
    onBack,
    onSubmit,
    setValidationFocus,
  } = useProfileMedicalForm1()

  return (
    <>
      {amountRanges?.length === 0 && lists?.length === 0 ? (
        <Loading />
      ) : (
        <GridFullScreen id="ProfileMedicalForm1">
          <GridPartialScreen container flexDirection={'column'}>
            <BackButton id="backButton" text="Regresar" onClick={onBack} />
            <Title variant="h5">{containerText.create_your_profile}</Title>
            <GridForm item xs={12}>
              <form
                onSubmit={onSubmit}
                data-testid="profile-form1"
                style={{ width: '100%' }}
              >
                <GridWrapper container sx={{ mt: 2 }} spacing={2}>
                  <GridWrapper item xs={12} mt={2}>
                    <FormTextArea
                      text={containerText.where_did_you_study}
                      placeholder={
                        containerText.where_did_you_study_placeholder
                      }
                      id="studies"
                      name="studies"
                      data-testid="studies"
                      type="text"
                      value={studies}
                      onChange={(event) => setStudies(event.target.value)}
                      fullWidth
                      multiline
                      maxRows={4}
                      onBlur={() => setValidationFocus(true)}
                      error={errorTextArea}
                      helperText={requiredField(errorTextArea)}
                    />
                  </GridWrapper>

                  <GridWrapper item xs={12} mt={2}>
                    <LabelInput
                      sx={{ mb: 1, marginLeft: 0, fontWeight: 'normal' }}
                    >
                      {containerText.years_experience}
                    </LabelInput>
                    {!isExtraSmallSize ? (
                      <SelectButtonList
                        style={{ width: '100%' }}
                        amountRanges={amountRanges}
                        onAmountRangeChange={(value: string) =>
                          onChange(value, 'selectedExperience')
                        }
                        selectedAmountRange={selectedExperience}
                      />
                    ) : (
                      <List
                        id="selectIdType"
                        lists={amountRanges}
                        onClick={(event: string) =>
                          onChange(event, 'selectedExperience')
                        }
                        value={selectedExperience}
                        minWidth="100%"
                      />
                    )}
                  </GridWrapper>

                  <GridWrapper item xs={12} mt={2}>
                    <LabelInput
                      sx={{ mb: 1, marginLeft: 0, fontWeight: 'normal' }}
                    >
                      {containerText.select_specialty}
                    </LabelInput>
                    <StackWrapper
                      spacing={2}
                      direction="row"
                      alignItems="center"
                      justifyContent="center"
                      style={{ marginTop: '3%', marginBottom: '3%' }}
                    >
                      <AutocompleteComponent
                        id="selectSpecialty"
                        list={lists?.map((s: ISpecialty) => s.option_value)}
                        label=""
                        setValue={setSpecialty}
                        value={specialty}
                        disabled={lists?.length === 0}
                      />
                    </StackWrapper>
                  </GridWrapper>

                  <GridWrapper item xs={12} mt={2}>
                    <LabelInput
                      sx={{ mb: 1, marginLeft: 0, fontWeight: 'normal' }}
                    >
                      {containerText.select_skills}{' '}
                      {containerText.select_skills_description}
                    </LabelInput>
                    <StackWrapper
                      spacing={2}
                      direction="row"
                      alignItems="center"
                      justifyContent="center"
                      style={{ marginTop: '3%', marginBottom: '3%' }}
                    >
                      <SkillsAutocomplete
                        skills={skills}
                        setSkills={setSkills}
                      />
                    </StackWrapper>
                  </GridWrapper>
                  <GridWrapper item xs={12} sx={{ my: 2 }}>
                    <SubmitButton
                      data-testid="ok-button1"
                      id="button"
                      type="submit"
                      variant="contained"
                      fullWidth
                      text={containerText.next_button}
                      disabled={
                        studies?.length === 0 ||
                        specialty?.length === 0 ||
                        selectedExperience?.length === 0
                      }
                    />
                  </GridWrapper>
                </GridWrapper>
              </form>
            </GridForm>
          </GridPartialScreen>
        </GridFullScreen>
      )}
    </>
  )
}

export default ProfileMedicalForm1
