import {
  IApiResponseSaveCodeDoctor,
  ISaveCodeDoctor,
} from '../../../dtos/SaveCode'
import SaveCodeDataSource from '../../dataSource/SaveCodeDataSource'
import { http } from '../../Http'

export default class SaveCodeMapper implements SaveCodeDataSource {
  async saveCode(
    dataSaveCode: ISaveCodeDoctor,
  ): Promise<IApiResponseSaveCodeDoctor> {
    const data = await http
      .post<IApiResponseSaveCodeDoctor>(`medical/save_code_doctor`, {
        body: dataSaveCode,
      })
      .then((response) => {
        const { data } = response

        return data
      })
      .catch((error) => {
        const data = {
          statusCode: error.response.status,
          body: error.response.body,
          headers: {
            'Access-Control-Allow-Headers': '',
            'Access-Control-Allow-Origin': '',
            'Access-Control-Allow-Methods': '',
          },
        }

        return data
      })

    return data
  }
}
