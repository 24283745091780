import { IServiceYearsExperience } from './InterfaceServiceResponse'
import { GetYearsExperience } from '../../../domain'
import YearsExperienceMapper from '../../../infrastructure/http/mappers/YearsExperienceMapper/YearsExperienceMapper'
import { YearsExperienceRepositoryImpl } from '../../../infrastructure/RepositoryImpl/YearsExperienceRepositoryImpl'

export async function YearsExperienceService(): Promise<IServiceYearsExperience> {
  try {
    const UseCaseRegistro = new GetYearsExperience(
      new YearsExperienceRepositoryImpl(new YearsExperienceMapper()),
    )

    return {
      status: 1,
      data: await UseCaseRegistro.invoke(),
    }
  } catch (error: unknown) {
    return {
      status: 0,
      data: (error as Error).message,
    }
  }
}
