import { SendDelayNotification } from '../../../domain/useCase/Notifications'
import {
  IApiSendNotificationResponseService,
  ISendNotificationService,
} from '../../../infrastructure/dtos/Notifications'
import NotificationsMapper from '../../../infrastructure/http/mappers/NotificationsMapper/NotificationsMapper'
import { NotificationsRepositoryImpl } from '../../../infrastructure/RepositoryImpl/NotificationsRepositoryImpl'

export async function sendDelayNotificationService(
  user_id: string,
  office_id: string,
  appointment_id: string,
  message: string,
): Promise<ISendNotificationService> {
  try {
    const UseCaseNotification = new SendDelayNotification(
      new NotificationsRepositoryImpl(new NotificationsMapper()),
    )

    return {
      status: 1,
      data: await UseCaseNotification.invoke(
        user_id,
        office_id,
        appointment_id,
        message,
      ),
    }
  } catch (error) {
    return {
      status: 0,
      data: error as IApiSendNotificationResponseService,
    }
  }
}
