import React, { SyntheticEvent, useEffect, useState } from 'react'

import { NavigateFunction, useNavigate, useParams } from 'react-router-dom'

import MedicalExams from './components/MedicalExams/MedicalExams'
import MedicalRecord from './components/MedicalRecord/MedicalRecord'
import PictureClinicMedical from './components/PatientPictureMedical/PatientPictureMedical'
import { BoxWrapper, TabsWrapper } from '../../../../../components'
import { CustomTabPanel } from '../../../../../components/CustomTabPanel/CustomTabPanel'
import { StyledTab } from '../../../../../components/StyledComponents/StyledTab'
import { TabBox } from '../../../../../components/StyledComponents/TabBox'
import { Appointment } from '../../../../../infrastructure/dtos/Appointments'

function a11yProps(index: number): { [key: string]: string } {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}

interface IPatientDataProps {
  appointment?: Appointment
}

export function PatientData({ appointment }: IPatientDataProps): JSX.Element {
  const { idScreen, idNumber, idAppointment, idPatient } = useParams()
  const navigate: NavigateFunction = useNavigate()

  const [value, setValue] = useState<number>(0)

  useEffect(() => {
    setValue(parseInt(idScreen as string))
  }, [idScreen])

  const handleChange = (event: SyntheticEvent, newValue: number): void => {
    navigate(
      `/dashboard/appointments/data/${newValue}/${idNumber}/${idAppointment}/${
        idPatient ?? ''
      }`,
    )
  }

  return (
    <BoxWrapper sx={{ width: '100%' }}>
      <TabBox sx={{ backgroundColor: 'tertiary.light' }}>
        <TabsWrapper
          value={value}
          onChange={handleChange}
          aria-label="Profile tab"
          TabIndicatorProps={{ style: { backgroundColor: 'transparent' } }}
        >
          <StyledTab label="Ficha médica" {...a11yProps(0)} role="tab" />
          <StyledTab label="Historia clínica" {...a11yProps(1)} role="tab" />
          <StyledTab label="Exámenes" {...a11yProps(2)} role="tab" />
        </TabsWrapper>
      </TabBox>
      <CustomTabPanel value={value} index={0}>
        <MedicalRecord />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <PictureClinicMedical appointmentInfo={appointment} />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={2}>
        <MedicalExams />
      </CustomTabPanel>
    </BoxWrapper>
  )
}
