export function validatePhoneNumber(number: string): boolean {
  const regex = /^09\d{8}$/;
  return regex.test(number);
}

export function validateLocalPhoneNumber(number: string): boolean {
  const regex = /^(02|09)[0-9]{7,8}$/;
  return regex.test(number);
}

export function validateLocalPhoneAndCellPhone(number: string): boolean {
  const regex = /^(02[0-9]{7}|09[0-9]{8})$/;
  return regex.test(number);
}

export function validateOfficePhoneNumber(number: string): boolean {
  if (isNaN(Number(number))){
    return false;
  }
  if (number.length > 0 && number.length <= 10) {
    if (number.charAt(0) === '0') {
      return true;
    }
  }
  return false;
}
