import { Add, Remove } from '@mui/icons-material'
import { SelectChangeEvent } from '@mui/material'
import { ChangeEvent, Dispatch, useState } from 'react'

import SubmitButton from '../../../../../../../../../components/SubmitButton'
import {
  GridWrapper,
  FormHelperTextWrapper,
  FormControlWrapper,
  SelectWrapper,
  MenuItemWrapper,
} from '../../../../../../../../../components/wrapper'
import { IMedication, IUnits } from '../../../../types'
import {
  InputQuantity,
  InputStyled,
  PrescriptionCard,
  QuantityButton,
} from '../../styles'

interface IEditProps {
  current: IMedication
  units: IUnits[]
  handleEditPrescription(data: IMedication): void
  setDisable: Dispatch<React.SetStateAction<boolean>>
}

function EditPrescription({
  current,
  units,
  handleEditPrescription,
  setDisable,
}: IEditProps): JSX.Element {
  const [counter, setCounter] = useState<string>(String(current.quantity))
  const [medicine, setMedicine] = useState<string>(current.medicine)
  const [dose, setDose] = useState<string>(current.dose.split(' ')[0])
  const [mu, setMu] = useState<string>(current.dose.split(' ')[1])
  const [presentation, setPresentation] = useState<string>(current.presentation)
  const [prescriptions, setPrescriptions] = useState(current.prescription)

  const handleEditItem = (): void => {
    handleEditPrescription({
      medicine,
      dose: `${dose} ${mu}`,
      presentation,
      prescription: prescriptions,
      quantity: Number(counter),
    })
    setDisable(false)
  }

  return (
    <PrescriptionCard key={current.id}>
      <GridWrapper
        container
        spacing={3}
        direction={'row'}
        marginTop={'10px'}
        marginBottom={'20px'}
      >
        <GridWrapper item xs={6} sm={4}>
          <InputStyled
            type="text"
            id="medication"
            value={medicine}
            name="medicine"
            aria-describedby="medication-helper-text"
            inputProps={{
              'aria-label': 'medication',
            }}
            fullWidth
            onChange={(event) => setMedicine(event.target.value)}
          />
          <FormHelperTextWrapper id="filled-medication-helper-text">
            Medicamento
          </FormHelperTextWrapper>
        </GridWrapper>

        <GridWrapper item xs={6} sm={3}>
          <GridWrapper display={'flex'} flexDirection={'row'} container>
            <GridWrapper item xs={3.5}>
              <InputStyled
                type="text"
                id="dose"
                value={dose}
                name="dose"
                aria-describedby="dose-helper-text"
                inputProps={{
                  'aria-label': 'dose',
                }}
                onChange={(event) => setDose(event.target.value)}
                fullWidth
              />
            </GridWrapper>
            <GridWrapper item xs={8.5}>
              <FormControlWrapper fullWidth variant="standard">
                <SelectWrapper
                  labelId="units"
                  id="units"
                  data-testid="units"
                  value={mu}
                  label="Age"
                  placeholder="Ml"
                  onChange={(event: SelectChangeEvent<unknown>) =>
                    setMu(event.target.value as string)
                  }
                >
                  {units.map((u, i) => (
                    <MenuItemWrapper key={`units-${i}`} value={u.value}>
                      {u.text}
                    </MenuItemWrapper>
                  ))}
                </SelectWrapper>
              </FormControlWrapper>
            </GridWrapper>
          </GridWrapper>
          <FormHelperTextWrapper id="filled-dose-helper-text">
            Dosis
          </FormHelperTextWrapper>
        </GridWrapper>

        <GridWrapper item xs={6} sm={2}>
          <InputStyled
            type="text"
            id="presentation"
            value={presentation}
            name="timeDose"
            aria-describedby="presentation-helper-text"
            inputProps={{
              'aria-label': 'presentation',
            }}
            onChange={(event) => setPresentation(event.target.value)}
          />
          <FormHelperTextWrapper id="filled-presentation-helper-text">
            Presentación
          </FormHelperTextWrapper>
        </GridWrapper>

        <GridWrapper item display={'flex'} flexDirection={'row'} xs={6} sm={2}>
          <QuantityButton
            variant="outlined"
            size="small"
            data-testid="substract-button"
            onClick={() => {
              setCounter(
                !counter || Number(counter) - 1 < 0
                  ? counter
                  : String(Number(counter) - 1),
              )
            }}
          >
            <Remove />
          </QuantityButton>
          <GridWrapper>
            <InputQuantity
              value={counter}
              type="number"
              id="number"
              aria-describedby="number-helper-text"
              inputProps={{
                'aria-label': 'number',
              }}
              onChange={(event: ChangeEvent<HTMLInputElement>) =>
                setCounter(event.target.value)
              }
            />
            <FormHelperTextWrapper id="filled-number-helper-text">
              Cantidad
            </FormHelperTextWrapper>
          </GridWrapper>
          <QuantityButton
            variant="outlined"
            size="small"
            data-testid="add-button"
            onClick={() => {
              setCounter(String(Number(counter) + 1))
            }}
          >
            <Add />
          </QuantityButton>
        </GridWrapper>
      </GridWrapper>
      <GridWrapper container spacing={{ xs: 1, sm: 3 }} direction={'row'}>
        <GridWrapper item xs={7} md={8}>
          <InputStyled
            type="text"
            id="remarks"
            data-testid="remarks"
            value={prescriptions}
            name="observations"
            aria-describedby="remarks-helper-text"
            inputProps={{
              'aria-label': 'remarks',
              maxLength: 151,
            }}
            fullWidth
            onChange={(event) => setPrescriptions(event.target.value)}
            error={prescriptions.length > 150}
          />
          {prescriptions.length > 150 && (
            <FormHelperTextWrapper
              id="error-remarks-helper-text"
              sx={{ color: 'primary.dark' }}
            >
              Máximo número de caracteres 150
            </FormHelperTextWrapper>
          )}
          <FormHelperTextWrapper id="filled-remarks-helper-text">
            Prescripción
          </FormHelperTextWrapper>
        </GridWrapper>
        <GridWrapper item xs={5} sm={4} md={3} paddingLeft={10}>
          <SubmitButton
            data-testid="edit-button"
            id="button"
            type="submit"
            variant="contained"
            fullWidth
            text="Guardar"
            sx={{
              width: '15ch',
              height: '5ch',
              backgroundColor: 'primary.main',
            }}
            disabled={
              medicine.length < 5 ||
              !counter ||
              Number(counter) < 1 ||
              !dose ||
              prescriptions.length > 150
            }
            onClick={handleEditItem}
          />
        </GridWrapper>
      </GridWrapper>
    </PrescriptionCard>
  )
}

export default EditPrescription
