import { useState } from 'react'

import {
  Appointment,
  IAppointmentsByDate,
} from '../../../../infrastructure/dtos/Appointments'

interface IUseAppointment {
  handleChangeLoadingList(state: boolean): void
  handleChangeList(data: IAppointmentsByDate[]): void
  hamdleChangeAppointment(data: Appointment): void
  loadingList: boolean
  list: IAppointmentsByDate[]
  appointment?: Appointment
}

export function useAppointments(): IUseAppointment {
  const [loadingList, setLoadingList] = useState<boolean>(false)
  const [list, setList] = useState<IAppointmentsByDate[]>([])
  const [appointment, setAppointment] = useState<Appointment>()

  const handleChangeLoadingList = (state: boolean): void =>
    setLoadingList(state)
  const handleChangeList = (data: IAppointmentsByDate[]): void => setList(data)
  const hamdleChangeAppointment = (data: Appointment): void => {
    return setAppointment(data)
  }

  return {
    handleChangeLoadingList,
    handleChangeList,
    hamdleChangeAppointment,
    appointment,
    loadingList,
    list,
  }
}
