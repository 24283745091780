import { styled } from '@mui/material'

import { ButtonWrapper } from '../../../components'

export const SaveButton = styled( ButtonWrapper )(( props ) => ({
  margin: '0 5px',
  textTransform: 'capitalize',
  borderRadius: '50px',
  backgroundColor: props.theme.palette.primary.main,
  color: '#ffffff',
  padding: '9px 22px',
  border: '1px solid ' + props.theme.palette.primary.main,
  '&:hover': {
    backgroundColor: '#ffffff',
    color: props.theme.palette.primary.main
  },
  '&.Mui-disabled': {
    backgroundColor: 'rgba(0, 0, 0, 0.12)',
    color: 'text.primary',
    border: 'none'
  }
}))
