import { GetCalendarInfo } from '../../../domain/useCase/GetCalendarInfo'
import { IResponseGetCalendarEvent } from '../../../infrastructure/dtos/CalendarInfo'
import CalendarInfoMapper from '../../../infrastructure/http/mappers/CalendarInfoMapper/CalendarInfoMapper'
import { CalendarInfoRepositoryImpl } from '../../../infrastructure/RepositoryImpl/CalendarInfoRepositoryImpl'

interface IGetDaysAvailabilityInAMonth {
  status: number
  data: IResponseGetCalendarEvent | string
}

export async function GetDaysAvailabilityInAMonth(
  idDoctor: string,
  idOffice: string,
  duration: number,
  officeType: string,
): Promise<IGetDaysAvailabilityInAMonth> {
  try {
    const UseCaseCalendar = new GetCalendarInfo(
      new CalendarInfoRepositoryImpl(new CalendarInfoMapper()),
    )

    return {
      status: 1,
      data: await UseCaseCalendar.invoke(idDoctor, idOffice, duration, officeType),
    }
  } catch (error: unknown) {
    return {
      status: 0,
      data: (error as Error).message,
    }
  }
}
