import { FormHelperText } from '@mui/material'
import dayjs from 'dayjs'
import { ChangeEvent } from 'react'

import { useSummary } from './useSummary'
import {
  DividerWrapper,
  GridWrapper,
  TypographyWrapper,
} from '../../../../../../../components'
import FormTextArea from '../../../../../../../components/FormTextArea'
import { DisableTextInput } from '../../../../../../../components/StyledComponents/DisableTextInput'
import { LabelInput } from '../../../../../../../components/StyledComponents/LabelInput'
import SubmitButton from '../../../../../../../components/SubmitButton'
import { IDoctorPrescription } from '../../../../../../../infrastructure/dtos/DoctorInfo'
import { RecipeHeader } from '../../components/RecipeHeader'
import {IMedication, IPrescription, IResendPrescription} from '../../types'


interface ISummaryProps {
  onBack?:() => void
  onChangePrescription?:(value: IPrescription) => void
  onChangeIsSubmit?:(value: boolean) => void
  prescription: IPrescription
  doctorAppointmentInfo: IDoctorPrescription
  resendData?: IResendPrescription
  isSubmit?: boolean
  isSent?: boolean
  isView?: boolean
  setShowModal?: (value : boolean) => void
}

export function Summary({
  onBack,
  onChangePrescription,
  onChangeIsSubmit,
  prescription,
  doctorAppointmentInfo,
  isSubmit,
  isSent,
  isView,
  resendData,
  setShowModal
}: ISummaryProps): JSX.Element {

  const {
    handleBack,
    handleSubmit,
    handleResend,
    printPdf,
    setRemarks,
    remarks,
    items,
  } = useSummary({
    onChangePrescription,
    onBack,
    onChangeIsSubmit,
    doctorAppointmentInfo,
    prescription,
    resendData
  })

  const resendPrescription = (): void => {
    handleResend()
    if (setShowModal) {
      setShowModal(false)
    }
  }

  return (
    <GridWrapper>
      <RecipeHeader doctorAppointmentInfo={doctorAppointmentInfo} />
      <DividerWrapper variant="fullWidth" flexItem />
      <GridWrapper container display={'flex'} flexDirection={'row'}>
        <GridWrapper item xs={5.9}>
          <GridWrapper container display={'flex'} flexDirection={'row'} paddingTop={2}>
            <GridWrapper item xs={6} paddingX={2}>
              <LabelInput sx={{ marginLeft: 0, fontWeight: 'normal' }}>
                Nombre del paciente
              </LabelInput>
              <GridWrapper
                padding={2}
                display={'flex'}
                justifyContent={'center'}
                sx={{
                  backgroundColor: 'info.light',
                  borderRadius: '52px',
                  fontSize:'12px',
                  '@media (min-width: 1600px)': {
                    fontSize: '16px',
                  },
                }}
              >
                {`${doctorAppointmentInfo?.patient?.name} ${doctorAppointmentInfo?.patient?.last_name}`}
              </GridWrapper>
            </GridWrapper>
            <GridWrapper item xs={6} paddingX={2}>
              <LabelInput sx={{ marginLeft: 0, fontWeight: 'normal' }}>Fecha</LabelInput>
              <GridWrapper
                padding={2}
                display={'flex'}
                justifyContent={'center'}
                sx={{
                  backgroundColor: 'info.light',
                  borderRadius: '52px',
                  fontSize:'12px',
                  '@media (min-width: 1600px)': {
                    fontSize: '16px',
                  },
                }}
                data-testid={'prescription-date'}
              >
                {!isView ? dayjs(new Date(Date.now())).format('DD/MM/YYYY') : dayjs(prescription?.created_at).format('DD/MM/YYYY') }
              </GridWrapper>
            </GridWrapper>
          </GridWrapper>

          <GridWrapper marginY={3} paddingX={2}>
            <LabelInput sx={{ marginLeft: 0, fontWeight: 'normal' }}>Diagnóstico</LabelInput>
            <DisableTextInput>{items}</DisableTextInput>
          </GridWrapper>

          <GridWrapper marginY={1} paddingX={2}>
            <LabelInput sx={{ marginLeft: 0, fontWeight: 'normal' }}>RP.</LabelInput>
            <DisableTextInput>
              {prescription?.recipe.map(( item: IMedication, index: number ) =>
                item.medicine ? (
                  <TypographyWrapper key={`recipe-${index}`}>
                    {`${item.quantity} ${item.medicine} (${item.dose})`}
                  </TypographyWrapper>
                ) : (
                  <TypographyWrapper>{`Sin receta`}</TypographyWrapper>
                )
              )}
            </DisableTextInput>
          </GridWrapper>
        </GridWrapper>

        <GridWrapper item xs={0.2} display={'flex'} justifyContent={'center'} py={2}>
          <DividerWrapper
            orientation='vertical'
            flexItem
          />
        </GridWrapper>

        <GridWrapper item xs={5.9}>
          <GridWrapper marginY={2} paddingX={2}>
            <LabelInput sx={{ marginLeft: 0, fontWeight: 'normal' }}>Indicaciones</LabelInput>
            <DisableTextInput>
              {prescription?.recipe.map(( item: IMedication, index: number ) =>
                item.medicine ? (
                  <TypographyWrapper key={`prescription-${index}`}>
                    <b>{`${item.medicine}`}:</b>
                    <br />
                    {item.prescription}
                  </TypographyWrapper>
                ) : (
                  <TypographyWrapper>{`Sin indicaciones`}</TypographyWrapper>
                )
              )}
            </DisableTextInput>
          </GridWrapper>
          {
            !isView ? <GridWrapper padding={2} >
              <FormTextArea
                text='Recomendaciones no Farmacológicas:'
                type='text'
                id="remarks-input"
                inputProps={{ maxLength: 501 }}
                name="Observaciones"
                value={remarks}
                autoFocus
                onChange={(
                  event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
                ) => setRemarks(event.target.value)}
                multiline
                rows={5}
                maxRows={5}
                fullWidth
                error={remarks.length > 500}
                disabled={isSent || isSubmit}
              />
              {remarks.length > 500 && (
                <FormHelperText
                  id="error-remarks-helper-text"
                  sx={{ color: 'primary.dark' }}
                >
                  Máximo número de caracteres 500
                </FormHelperText>
              )}
            </GridWrapper> : <GridWrapper padding={2} >
              <LabelInput sx={{ marginLeft: 0, fontWeight: 'normal' }}>
                Recomendaciones no Farmacológicas:
              </LabelInput>
              <DisableTextInput>
                {remarks}
              </DisableTextInput>
            </GridWrapper>
          }


        </GridWrapper>
      </GridWrapper>
      {!isView ? <GridWrapper display="flex" justifyContent="center" marginTop={3}>
        <SubmitButton
          id={''}
          variant={'contained'}
          data-testid="back-button"
          fullWidth
          type={'button'}
          text={'Regresar'}
          sx={{ width: '25%', margin: '0 20px', backgroundColor: '#6191FE' }}
          disabled={isSent}
          onClick={handleBack}
          disableElevation
        />
        <SubmitButton
          id={''}
          variant={'contained'}
          data-testid="send-button"
          fullWidth
          type={'button'}
          text={'Enviar'}
          sx={{ width: '25%', margin: '0 20px' }}
          onClick={handleSubmit}
          disabled={isSent || isSubmit}
          disableElevation
        />
        <SubmitButton
          id={''}
          variant={'contained'}
          data-testid="print-button"
          fullWidth
          type={'button'}
          text={'Imprimir'}
          sx={{ width: '25%', margin: '0 20px' }}
          onClick={printPdf}
          disabled={!isSent}
          disableElevation
        />
      </GridWrapper>: <GridWrapper display="flex" justifyContent="center" marginTop={3}>
        <SubmitButton
          id={''}
          variant={'contained'}
          data-testid="print-button"
          fullWidth
          type={'button'}
          text={'Enviar'}
          sx={{ width: '25%', margin: '0 20px' }}
          onClick={resendPrescription}
          disableElevation
        />
      </GridWrapper>
      }

    </GridWrapper>
  )
}
