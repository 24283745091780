import { styled } from '@mui/material'

import ButtonWrapper from '../wrapper/ButtonWrapper'

export const PrimaryButton = styled(ButtonWrapper)((props) => ({
    borderRadius: '2.625rem',
    backgroundColor: props.theme?.palette.primary.dark,
    fontSize: '12px',
    '@media (min-width: 1600px)': {
      fontSize: '16px',
    },
    height: '3.4375rem',
    textTransform: 'none',
    width: props.datatype !== 'loginButton' ? '88%' : '100%',
    '&:disabled': {
        backgroundColor: props.theme?.palette?.tertiary?.light,
        color: props.theme?.palette?.info?.main,
    },
  }))
