import {
  IApiResponseSaveCodeDoctor,
  ISaveCodeDoctor,
} from '../../infrastructure/dtos/SaveCode'
import { ISaveCodeRepository } from '../repository/SaveCodeRepository'

export interface ISaveCodeUseCase {
  invoke: (dataSaveCode: ISaveCodeDoctor) => Promise<IApiResponseSaveCodeDoctor>
}

export class SaveCode implements ISaveCodeUseCase {
  private objectUseCase: ISaveCodeRepository

  constructor(_objectUseCase: ISaveCodeRepository) {
    this.objectUseCase = _objectUseCase
  }

  invoke(dataSaveCode: ISaveCodeDoctor): Promise<IApiResponseSaveCodeDoctor> {
    return this.objectUseCase.saveCode(dataSaveCode)
  }
}
