// eslint-disable-next-line import/no-extraneous-dependencies
import { create } from 'zustand'

import { IMedicalOffice } from '../infrastructure/dtos/Offices'

interface IOfficeStore {
  officeSelected: IMedicalOffice[]
  setOfficeSelected: (officeSelected: IMedicalOffice[]) => void
}

export const useSelectOffice = create<IOfficeStore>((set) => ({
  officeSelected: [] as IMedicalOffice[],
  setOfficeSelected: (officeSelected) => set(() => ({ officeSelected })),
}))
